import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../../../api_v2/non_admin/users';

import { Button } from '../../../../components/Button';
import { Icon } from '../../../../components/Icon';
import { TopTitleBar } from './conveyance/components/TopTitleBar';

import { BottomNavBar } from './components/BottomNavBar';

export const MyPage = () => {
  const handleLogout = () => {
    logout();
    window.location.href = '/v2/camping';
  };
  return (
    <>
      <TopTitleBar userAuthIcon={false} logo={true} home={false} />
      <h2 className="pb-8 pt-4 side-padding">마이페이지</h2>
      <div className="max-w-md w-screen side-padding bg-[#FFFFFF] divide-y">
        <div className="flex justify-between leading-10 mb-10 items-center">
          <div>예약내역 확인하기</div>
          <Link className="flex justify-center items-center" to="/v2/camping/reservation/history">
            <Icon.ChevronRight />
          </Link>
        </div>
        <div className="flex justify-between leading-10 mb-10 items-center">
          <div>등록한 양도 확인하기</div>
          <button
            onClick={() => {
              window.location.href = '/v2/camping/conveyance/my-conveyanceinfo-list';
            }}
          >
            <Icon.ChevronRight />
          </button>
        </div>
        <div className="flex justify-center">
          <Button
            className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                    flex-1 flex justify-center items-center max-w-sm"
            onClick={() => handleLogout()}
          >
            로그아웃
          </Button>
        </div>
      </div>
      <BottomNavBar />
    </>
  );
};
