import { ClayfulCustomerType } from './hooks/customer/type';

export interface Paginated<T> {
  items: T[];
  total: number;
}

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface User {
  id: number;
  createdAt: string;
  updatedAt: string;
  email: string;
  role: Role;
  name: string;
}

export interface Tab {
  name: string;
  pathname: string;
}

export interface Category {
  id: number;
  name: string;
}

export enum DiscountType {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
}

export interface Review {
  _id: string;
  helped?: {
    up: {
      raw: number;
      formatted: string;
      converted: string;
    };
    down: {
      raw: number;
      formatted: string;
      converted: string;
    };
    value: {
      raw: number;
      formatted: string;
      converted: string;
    };
    total: {
      raw: number;
      formatted: string;
      converted: string;
    };
  };
  flagged?: {
    value: {
      raw: number;
      formatted: string;
      converted: string;
    };
  };
  body: string;
  images: Array<{ _id: string; url: string }>;
  rating: {
    raw: number;
    formatted: string;
    converted: string;
  };
  totalComment: {
    raw: number;
    formatted: string;
    converted: string;
  };
  assignees?: any[];
  commentedAt?: null;
  publishedAt: {
    raw: string;
    formatted: string;
    ago: string;
  };
  product: {
    _id: string;
    name?: string;
  };
  title: string;
  meta?: {
    optionText?: string;
  };
  customer: ClayfulCustomerType;
  createdAt: {
    raw: string;
    formatted: string;
    ago: string;
  };
  updatedAt: {
    raw: string;
    formatted: string;
    ago: string;
  };
  vendor: {
    _id: string;
    name: string;
    slug: string;
  };
}

export const PRODUCT_MAX_QUANTITY = 2147483647;
export interface Product {
  officialLink: string;
  retailPrice: number;
  supplyName: string;
  id: number;
  productId: string;
  productName: string;
  brandName?: string;
  price: number;
  priceMap: any[];
  thumbnail?: string;
  rate: number;
  discountType?: DiscountType;
  discountPrice: number;
  available: boolean;
  collections?: string[];
  isBestWeekProduct: boolean;
  isSpecialPriceProduct: boolean; // 이번주 베스트 상품
  weight: number;
  specialPriceWeight: number;
  bestWeekWeight: number;
  summary: string;
  createdAt: string;
  quantity: number;
  referenceLink: string;
  usedSellerName: string;
  usedPrice: number;
  usedSellerLink: string;
}

export interface VariantType {
  option: { _id: string; name: string };
  variation: { _id: string; value: string };
}

// 전체상품
export enum ProductType {
  TYPE_0 = '전체상품',
  TYPE_1 = '텐트/타프',
  TYPE_2 = '퍼니처',
  TYPE_3 = '캠핑요리',
  TYPE_4 = '화기',
  TYPE_5 = '조명/랜턴',
  TYPE_6 = '수납/정리',
  TYPE_7 = '기타',
}

export enum HandleQuantityType {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}

export enum AccordianDataType {
  SHIPPING_POLICY = 'SHIPPING_POLICY',
  OTHER = 'OTHER',
}

export enum GetReviewsBy {
  PRODUCT = 'PRODUCT',
  CUSTOMER = 'CUSTOMER',
}

export const CLAYFUL_BASE_URL = 'https://api.clayful.io/v1';

export const Urls = {
  PUBLIC_V1_ORDERS: `${CLAYFUL_BASE_URL}/orders`,
  PUBLIC_V1_ME_ORDERS: `${CLAYFUL_BASE_URL}/me/orders`,
  PUBLIC_V1_ME_ORDERS_COUNT: `${CLAYFUL_BASE_URL}/me/orders/count`,
  PUBLIC_V1_PRODUCTS: `${CLAYFUL_BASE_URL}/products`,
  PUBLIC_V1_PRODUCTS_COUNT: `${CLAYFUL_BASE_URL}/products/count`,
  PUBLIC_V1_PRODUCTS_REVIEWS_PUBLISHED: `${CLAYFUL_BASE_URL}/products/reviews/published`,
  PUBLIC_V1_PRODUCTS_REVIEWS_PUBLISHED_ID: `${CLAYFUL_BASE_URL}/products/reviews/published/`,
  PUBLIC_V1_SHIPPING_METHODS: `${CLAYFUL_BASE_URL}/shipping/methods`,
  PUBLIC_V1_COLLECTIONS: `${CLAYFUL_BASE_URL}/collections`,
  PUBLIC_V1_BRANDS: `${CLAYFUL_BASE_URL}/brands`,
  PUBLIC_V1_BRANDS_ID: `${CLAYFUL_BASE_URL}/brands/`,
  PUBLIC_V1_ME_IMAGES: `${CLAYFUL_BASE_URL}/me/images`,
  PUBLIC_V1_ME_WISHLISTS: `${CLAYFUL_BASE_URL}/me/wishlists`,
  PUBLIC_V1_ME_WISHLISTS_COUNT: `${CLAYFUL_BASE_URL}/me/wishlists/count`,
  PUBLIC_V1_ME_CART: `${CLAYFUL_BASE_URL}/me/cart`,
  PUBLIC_V1_ME_CART_COUNT: `${CLAYFUL_BASE_URL}/me/cart/items/count`,
  PUBLIC_V1_ME_CART_ITEMS: `${CLAYFUL_BASE_URL}/me/cart/items`,
  PUBLIC_V1_ME_PRODUCTS_REVIEWS: `${CLAYFUL_BASE_URL}/me/products/reviews`,
  PUBLIC_V1_ME: `${CLAYFUL_BASE_URL}/me`,
  PUBLIC_V1_CUSTOMERS: `${CLAYFUL_BASE_URL}/customers`,
  PUBLIC_V1_CUSTOMERS_AUTH: `${CLAYFUL_BASE_URL}/customers/auth`,
  PUBLIC_V1_CUSTOMERS_AUTH_VENDOR: `${CLAYFUL_BASE_URL}/customers/auth/`,
  PUBLIC_V1_CUSTOMERS_VERIFICATION_EMAILS: `${CLAYFUL_BASE_URL}/customers/verifications/emails`,
  PUBLIC_V1_SHIPPING_POLICIES: `${CLAYFUL_BASE_URL}/shipping/policies`,
  PUBLIC_V1_VENDORS: `${CLAYFUL_BASE_URL}/vendors`,
  PUBLIC_V1_ME_NON_REGISTERED_CART: `${CLAYFUL_BASE_URL}/me/non-registered/cart`,
  PUBLIC_V1_ME_NON_REGISTERED_CART_CHECKOUT: `${CLAYFUL_BASE_URL}/me/non-registered/cart/checkout/order`,
  PUBLIC_V1_ME_CART_CHECKOUT: `${CLAYFUL_BASE_URL}/me/cart/checkout/order`,
  PUBLIC_V1_ME_CART_EMPTY: `${CLAYFUL_BASE_URL}/me/cart/items`,
  /**
   * Coupon
   */
  PUBLIC_V1_COUPONS: `${CLAYFUL_BASE_URL}/coupons`,
  PUBLIC_V1_ME_COUPONS: `${CLAYFUL_BASE_URL}/me/coupons`,
  PUBLIC_V1_ME_COUPONS_COUNT: `${CLAYFUL_BASE_URL}/me/coupons/count`,

  /**
   * Kakao
   */
  KAKAO_OAUTH_TOKEN: 'https://kauth.kakao.com/oauth/token',

  /* Generate auth url by naver SDK */
  // /**
  //  * Naver
  //  */
  // NAVER_OAUTH_AUTHORIZE = "https://nid.naver.com/oauth2.0/authorize",
};
