import { useHistory } from 'react-router';
import { config } from '../../actions';
import { ICreateCustomerMe, ILoginCustomer } from '../../actions/customer';
import { getErrorMessage as getErrMsgSignUp } from './types/signUp';
import { getErrorMessage as getErrMsgAuth } from './types/authenticate';
import { getErrorMessage as getErrMsg3rdAuth, thirdAuthAction } from './types/3rdAuth';
import { api } from '../../plugins/axios';
import { tokenState, cartCountState } from '../../ridge/ridge';
import { Urls } from '../../types';
import { useEffect, useState } from 'react';
import { authTypes } from './types/3rdAuth';
import { toast } from 'react-toastify';

export const useAuth = () => {
  const { push } = useHistory();
  const [token, setToken] = tokenState.use();

  const [authenticated, setAuthenticated] = useState(token !== null);

  useEffect(() => {
    setAuthenticated(token ? true : false);
  }, [token]);

  const signup = async (data: ICreateCustomerMe) => {
    return await api.post(Urls.PUBLIC_V1_ME, data, config).catch((err) => {
      const { data: responseData } = err.response;
      const errMsg = getErrMsgSignUp(responseData.errorCode, {
        validation: responseData.validation,
      });
      return {
        ...err.response,
        customErrMsg: errMsg,
        alertErrMsg: () => {
          toast.error(errMsg);
        },
      };
    });
  };

  const login = (data: ILoginCustomer) =>
    api
      .post(Urls.PUBLIC_V1_CUSTOMERS_AUTH, data, config)
      .then(({ data: { token } }) => setToken(token))
      .catch((err) => ({
        ...err.response,
        customErrMsg: getErrMsgAuth(err.response.data.errorCode, err.response.data.validation),
        alertErrMsg: () => {
          toast.error(getErrMsgAuth(err.response.data.errorCode, err.response.data.validation));
        },
      }));

  const logout = () => {
    tokenState.reset();
    cartCountState.reset();
    push('/mypage');
  };

  const socialLogin = async (type: authTypes, token: string) =>
    await api
      .post(`${Urls.PUBLIC_V1_CUSTOMERS_AUTH_VENDOR}${type}`, { token }, config)
      .then((response) => {
        const {
          data: { action },
        } = response;
        setToken(response.data.token);
        if (action === 'register') {
          push(`/signup?loginBy=${type}`);
        }
        return response;
      })
      .catch((err) => {
        const errMsg = getErrMsg3rdAuth(err.response.data.errorCode);
        toast.error(errMsg);
        push('/login');
        return {
          ...err.response,
          customErrMsg: errMsg,
          alertErrMsg: () => {
            toast.error(errMsg);
          },
        };
      });

  return { token, authenticated, signup, login, socialLogin, logout };
};
