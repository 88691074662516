import { useHistory, useLocation } from 'react-router-dom';
import { sortBy } from 'lodash';

import { Section } from '../components/Section';
import { Product } from '../types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper';

import { Banner } from '../hooks/banner/type';
import { useEffect, useRef, useState } from 'react';

import { getExhibitions } from '../api_v2/non_admin/exhibitions';

import _ from 'lodash';

import { Icon } from '../components/Icon';
import { KeyWord } from '../components/KeyWord';
import { useInView } from 'react-intersection-observer';

import { EventProducts } from '../components/EventProducts';
import { useInfiniteQuery } from 'react-query';

import { ScrollHandler } from '../components/ScrollHandler';

SwiperCore.use([Pagination, Autoplay]);

export const HomePage = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [page, setPage] = useState<number>(1);
  const [allEvents, setAllEvents] = useState<any[]>([]);
  const [allBanners, setAllBanners] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  const [ref, inView] = useInView();
  const observer = useRef<IntersectionObserver>();

  // const getEventsPage = useCallback(async () => {
  //   // 서버에서 기획전 목록 가져오기
  //   setLoading(true);
  //   await getExhibitions(page, 3).then((result) => {
  //     if (result?.success) {
  //       setAllEvents((prev: any) => prev.concat(result.data.items));
  //       if (result.data.items.length < 3) {
  //         setDone(true);
  //       }
  //     } else {
  //       // toast.error('Something went wrong getting events');
  //     }
  //     setLoading(false);
  //   });
  // }, [page]);

  // useEffect(() => {
  //   getEventsPage();
  // }, [getEventsPage]);

  const {
    data: returnedExhibitions,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    error,
  } = useInfiniteQuery(['HOME_PAGE_INFINITE_EXHIBITIONS', { limit: 3 }], getExhibitions, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage && pages.length < Math.ceil(lastPage.data.total / 3)) return pages.length + 1;
      return false;
    },
    keepPreviousData: true,
  });

  useEffect(() => {
    if (inView && !isLoading && !isFetching) {
      fetchNextPage();
      // setPage((prev) => prev + 1);
    }
  }, [inView, isLoading, isFetching]);

  useEffect(() => {
    if (returnedExhibitions) {
      let dataHolder: any[] = [];
      for (let i = 0; i < returnedExhibitions.pages.length; i++) {
        if (returnedExhibitions.pages[i]) {
          dataHolder = [...dataHolder, ...returnedExhibitions.pages[i]!.data.items];
        }
      }
      setAllEvents(dataHolder);
    }
  }, [returnedExhibitions]);

  // useEffect(() => {
  //   getBanners().then((result) => {
  //     if (result?.success) {
  //       setAllBanners(_.sortBy(result.data, 'weight').reverse());
  //     } else {
  //       // toast.error('Something went wrong getting banners');
  //     }
  //   });
  // }, []);

  // const { data: returnedBanners, status: returnedBannersStatus } = useQuery<any, any>(
  //   ['HOME_PAGE_BANNERS'],
  //   getBanners
  // );

  // useEffect(() => {
  //   if (returnedBanners?.success && returnedBanners?.data) {
  //     setAllBanners(_.sortBy(returnedBanners.data, 'weight').reverse());
  //   }
  // }, [returnedBanners]);

  const containerRef = useRef<any>();

  return (
    <>
      {containerRef && <ScrollHandler pathname={pathname} containerRef={containerRef} />}
      <div
        ref={containerRef}
        className={`w-screen sm:w-full md:w-full lg:w-full bg-gray-100 pb-20`}
      >
        {/* <TopNavBar logo={false} title={'모든 캠핑용품\n최저가 찾기'} /> */}
        <div className="pt-12 pb-6 bg-white side-padding">
          <h1 className="mb-2">모든 아웃도어</h1>
          <h1>최저가 찾기</h1>
        </div>
        {/* <HomeSearchPage /> */}
        {/* <iframe src="https://m.dayout.store/" width={'100%'} height={'100%'} /> */}
        <Section
          className="pb-6 bg-white side-padding"
          // className="sm:w-full md:w-full lg:w-full w-screen mb-6 side-padding"
        >
          <div
            onClick={() => history.push('/search')}
            className="flex flex-row-reverse w-full items-center px-4 h-12 border rounded bg-gray-100 border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
          >
            <div className="flex justify-center items-center -ml-2 wh-10">
              <Icon.Search />
            </div>
            <input className="flex-1 text-sm bg-gray-100" placeholder="무엇을 찾으시나요?" />
          </div>
          <KeyWord />
        </Section>

        <Section className="max-w-md w-full bg-white side-padding pb-4">
          <Swiper
            pagination={true}
            autoplay={{
              delay: 3000,
            }}
            loop={true}
            className="mySwiper"
          >
            {allBanners.map((banner: Banner) => {
              return (
                <SwiperSlide key={banner?.id?.toString()}>
                  <a href={`${banner.url}`}>
                    <img src={`${banner.thumbnail}`} alt="" />
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Section>
        {sortBy(allEvents || [], 'weight')
          .reverse()
          .map((event: any, idxEvent: number) => {
            const eventAllProducts: Product[] = [];
            const productFamilies: any[] = [];
            let family: any[] = [];
            let lastPushed = false;

            sortBy(event.exhibitionProductMaps, 'order').forEach((prd: any, idx: number) => {
              if (family.length === 4) {
                productFamilies.push(family);
                family = [];
              }
              family.push(prd);
              eventAllProducts.push(prd);
              if (idx === 10) {
                return;
              }
            });
            productFamilies.push(family);

            const lines: any[][] = [[], []];
            productFamilies.forEach((prdFam: any[]) => {
              lines[0].push(prdFam[0]);
              if (prdFam.length > 1) {
                lines[0].push(prdFam[1]);
                if (prdFam.length > 2) {
                  lines[1].push(prdFam[2]);
                  if (prdFam.length > 3) {
                    lines[1].push(prdFam[3]);
                  }
                }
              }
            });

            return (
              <>
                {event.isVisible ? (
                  idxEvent === allEvents.length - 1 ? (
                    <div key={event?.id} className="mt-2 py-4 w-full bg-white pl-4" ref={ref}>
                      <Section>
                        <EventProducts productFamilies={productFamilies} event={event} />
                      </Section>
                    </div>
                  ) : (
                    <div key={event?.id}>
                      <Section className="mt-2 py-4 w-full bg-white pl-4">
                        <EventProducts productFamilies={productFamilies} event={event} />
                      </Section>
                    </div>
                  )
                ) : (
                  <>{/* <div ref={ref} /> */}</>
                )}
              </>
            );
          })}
      </div>
    </>
  );
};
