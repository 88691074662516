import { parse } from 'qs';

import { useLocation, Redirect, useHistory } from 'react-router-dom';

import { TopTitleBar } from './components/TopTitleBar';
import { authUserTokenObjState } from '../../../ridge/ridge';

import ChatBalloon from '../../../assets/img/icon-chat-balloon.png';
import LogoDayoutPNG3 from '../../../assets/img/icon-logo-dayout3.png';
import IconLoginImgPNG from '../../../assets/img/icon-login-img.jpg';

const loginTypeObj: { [key: string]: any } = {
  KAKAO: '카카오',
};

export enum LoginType {
  KAKAO = 'KAKAO',
}

export function LoginPage() {
  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const history = useHistory();

  const authUserTokenObj = authUserTokenObjState.useValue();

  const handleLoginButton = (loginType: string) => {
    if (loginType == LoginType.KAKAO) {
      handleKakaoAuthorization();
    }
  };

  const handleKakaoAuthorization = () => {
    localStorage.setItem(`projectForLogin`, `community`);

    if (process.env.REACT_APP_KAKAO_REDIRECT_URI) {
      let kakaoAuthObj: { [key: string]: any } = {
        redirectUri: process.env.REACT_APP_KAKAO_REDIRECT_URI
          ? process.env.REACT_APP_KAKAO_REDIRECT_URI
          : '/community',
      };
      // prob2
      // if (parsedSearch?.login_error && parsedSearch?.login_error == 'no_registered_user') {
      //   kakaoAuthObj.prompts = 'login';
      // }
      window?.Kakao?.Auth?.authorize(kakaoAuthObj);
    } else {
      console.log('no kakao redirect uri');
    }
  };

  // prob1
  // useEffect(() => {
  //   if (parsedSearch?.login_error && parsedSearch?.login_error == 'no_registered_user') {
  //     toast.error('회원가입후 이용해주시기 바랍니다.');
  //   }
  // }, [search]);

  const handleBookingForNonMember = () => {
    let redirectUrl = localStorage.getItem('redirectPageUrl');

    if (redirectUrl) {
      history.push(redirectUrl);
      localStorage.removeItem('redirectPageUrl');
    } else {
      history.push('/community');
    }
  };

  return (
    <>
      <TopTitleBar home={false} />
      {authUserTokenObj ? (
        <>
          <Redirect to="/community" />
        </>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center side-padding h-full">
            <div>
              <div className="flex justify-center w-full pb-[65px]">
                <img className="max-w-[213px] h-[45px]" src={LogoDayoutPNG3} />
              </div>
              {/* <div className="text-center text-[25px] pb-[20px]">
                <p>
                  캠핑용품 <span className="font-[700]">첫 구매 15% 쿠폰</span>받고
                </p>
                <p>
                  <span className="font-[700]">캠핑장 예약</span>까지 한번에!
                </p>
              </div> */}
              <div className="text-center text-[15px]">
                {parsedSearch?.prev_page == 'campingroomtype' ? (
                  <>
                    {/* <p className="text-lg">
                      <span className="font-[800]">로그인 후</span> 예약을 진행하시면
                    </p>
                    <p className="text-lg">
                      <span className="font-[800]">다양한 혜택</span>을 받으실 수 있습니다
                    </p> */}
                    {/* <ul className="list-none pt-4">
                    <li>상품권 증정 이벤트</li>
                    <li>숙박권 추첨 이벤트</li>
                  </ul> */}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <img src={IconLoginImgPNG} className="pb-[15px]" />
              {Object.keys(loginTypeObj).map((loginType) => (
                <>
                  <button
                    type="button"
                    className={`w-full h-[45px] inline-flex justify-between 
          rounded-[12px] border border-transparent shadow-sm px-4 py-2 
          ${loginType == 'KAKAO' ? 'bg-[#FEE500]' : 'bg-[#FEE500]'} 
          text-base font-medium  text-[#000000]
          hover:bg-primary-1 focus:outline-none 
          focus:ring-2 focus:ring-offset-2 focus:ring-primary-1 
          `}
                    onClick={() => handleLoginButton(loginType)}
                  >
                    <div className="grid grid-cols-5 place-items-center w-full">
                      {loginType === LoginType.KAKAO && (
                        <div className="col-span-1">
                          <img className="w-[20px] mr-[5px]" src={ChatBalloon} />
                        </div>
                      )}

                      <div className="col-span-3">
                        {loginType === LoginType.KAKAO
                          ? '카카오톡으로 계속하기'
                          : (loginTypeObj[loginType] ?? '') + ' 로그인'}
                      </div>
                    </div>
                  </button>
                </>
              ))}
              {parsedSearch?.prev_page == 'campingroomtype' && (
                <button
                  type="button"
                  className={`h-[45px] w-full inline-flex justify-center 
          rounded-[12px] border border-transparent shadow-sm px-4 py-2 mt-[10px]  
          bg-[#dbd9d9]
          text-base font-medium  text-[#000000]
          hover:bg-primary-1 focus:outline-none 
          focus:ring-2 focus:ring-offset-2 focus:ring-primary-1 
     
          `}
                  onClick={() => handleBookingForNonMember()}
                >
                  비회원으로 예약
                </button>
              )}
              {/* <a
                className="flex justify-center items-center text-[#5986f7] mt-[10px]"
                onClick={() => {
                  window.location.href = 'https://m.dayout.store/member/login.html';
                }}
                // id="booking-page-button"
                // className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                //         flex-1 flex justify-center items-center"
              >
                회원 가입
              </a> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}
