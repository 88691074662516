import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import CommunityPostDetail from './CommunityPostDetail';
import { v4 as uuid } from 'uuid';

const Modal = ({ resetKey, visible, setModalVisible, currModalItem }: any) => {
  //
  return (
    <div className={`modal fixed inset-0 z-50 ${visible ? '' : 'hidden'}`}>
      <style>
        {`
        ::-webkit-scrollbar {
            width: 10px; 
          }
          
          ::-webkit-scrollbar-track {
            background: #f1f1f1; 
            border-radius: 0.5rem; 
          }
          
          ::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius: 0.5rem; 
          }
          
          ::-webkit-scrollbar-thumb:hover {
            background: #555; 
          }
          
        `}
      </style>
      <div className="modal-overlay bg-gray-900 opacity-50 absolute inset-0"></div>
      <div className="modal-content relative z-[100] bg-white max-w-[40%] max-h-[70%] overflow-auto mx-auto mt-20 p-6 rounded-lg shadow-lg">
        <div className="flex justify-end">
          <div
            onClick={() => {
              setModalVisible(false);
            }}
            className="font-[700] text-[30px] py-[5px] px-[15px] cursor-pointer"
          >
            X
          </div>
        </div>
        <CommunityPostDetail key={resetKey} item={currModalItem} />
      </div>
    </div>
  );
};

const ChartType1 = ({ data }: any) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;
  const [selectedColumn, setSelectedColumn] = useState<string>('users');
  const [dataForGraph, setDataForGraph] = useState<any[]>(data);

  const [modalVisible, setModalVisible] = useState(false);

  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
    try {
      renderChart();
    } catch (e) {}
  }, [currentPage, selectedColumn, dataForGraph]);

  const sortByColumn = (column: string) => {
    try {
      const sortedData = [...dataForGraph].sort((a: any, b: any) => b[column] - a[column]);

      return sortedData;
    } catch (e) {
      return null;
    }
  };

  const paginate = (data: any) => {
    try {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return data.slice(startIndex, endIndex);
    } catch (e) {
      return null;
    }
  };

  const [currModalItem, setCurrModalItem] = useState<any>(null);

  const [resetKey, setResetKey] = useState(uuid());

  const renderChart = () => {
    try {
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      const sortedData = sortByColumn(selectedColumn);
      const paginatedData = paginate(sortedData ?? []) as any;

      const labels = paginatedData.map((item: any) => item['communityPostId']);
      const values = paginatedData.map((item: any) => item[selectedColumn]);

      const ctx = document.getElementById('myChart') as HTMLCanvasElement | null;
      if (ctx) {
        chartRef.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [
              {
                label: `Data for column ${selectedColumn}`,
                data: values,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'id',
                },
              },
              y: {
                beginAtZero: true,
                // title: {
                //   display: true,
                //   text: '',
                // },
              },
            },
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  label: function (context) {
                    let label = [];
                    label.push(`Label: ${context.dataset.label}`);
                    label.push(`id: ${labels[context.dataIndex]}`);
                    label.push(`Value: ${context.parsed.y}`);

                    return label;
                  },
                },
              },
            },
            onClick: function (event, elements, chart) {
              if (elements.length > 0) {
                const firstElement = elements[0] as any;
                if (dataForGraph?.length > 0) {
                  let found = dataForGraph.find(
                    (item) => item?.communityPostDetail?.id == labels[firstElement?.index]
                  );

                  if (found) {
                    setCurrModalItem(found?.communityPostDetail);
                    setResetKey(uuid());
                    setModalVisible(true);
                  }
                }
              }
            },
          },
        });
      }
    } catch (e) {}
  };

  const handleColumnSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedColumn(e.target.value);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(dataForGraph.length / itemsPerPage);
    const pageNumbers = [];

    if (currentPage > 1) {
      pageNumbers.push(
        <button
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-3 py-1 mr-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
        >
          Previous
        </button>
      );
    }

    pageNumbers.push(
      <button
        key={1}
        onClick={() => handlePageChange(1)}
        className={`px-3 py-1 mr-2 ${
          currentPage === 1 ? 'bg-blue-500 text-white font-bold' : 'bg-white text-blue-500'
        } rounded hover:bg-blue-200 focus:outline-none focus:ring focus:ring-blue-200`}
      >
        1
      </button>
    );

    if (totalPages > 5 && currentPage > 3) {
      pageNumbers.push(<span key="startEllipsis">...</span>);
    }

    const start = Math.max(2, currentPage - 2);
    const end = Math.min(totalPages - 1, currentPage + 2);
    for (let i = start; i <= end; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 mr-2 ${
            i === currentPage ? 'bg-blue-500 text-white font-bold' : 'bg-white text-blue-500'
          } rounded hover:bg-blue-200 focus:outline-none focus:ring focus:ring-blue-200`}
        >
          {i}
        </button>
      );
    }

    if (totalPages > 5 && currentPage < totalPages - 2) {
      pageNumbers.push(<span key="endEllipsis">...</span>);
    }

    if (totalPages > 1) {
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={`px-3 py-1 mr-2 ${
            currentPage === totalPages
              ? 'bg-blue-500 text-white font-bold'
              : 'bg-white text-blue-500'
          } rounded hover:bg-blue-200 focus:outline-none focus:ring focus:ring-blue-200`}
        >
          {totalPages}
        </button>
      );
    }

    if (currentPage < totalPages) {
      pageNumbers.push(
        <button
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
        >
          Next
        </button>
      );
    }

    return pageNumbers;
  };

  const renderColumnSelect = () => {
    let columns = Object.keys(dataForGraph[0]);
    columns = columns.filter((item: any) => ['users', 'views']?.includes(item));
    return (
      <>
        <p className="font-[700]">Select column</p>
        <select value={selectedColumn} onChange={handleColumnSelect}>
          {columns.map((column) => (
            <option key={column} value={column}>
              {column}
            </option>
          ))}
        </select>
      </>
    );
  };

  return (
    <div>
      <Modal
        resetKey={resetKey}
        currModalItem={currModalItem}
        visible={modalVisible}
        setModalVisible={setModalVisible}
      />
      {renderColumnSelect()}
      <canvas id="myChart"></canvas>
      <div className="flex justify-center">{renderPagination()}</div>
    </div>
  );
};

export default ChartType1;
