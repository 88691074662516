import { useEffect, useState, useRef, useCallback } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import { parse, stringify } from 'qs';

import { default as htmlReactParser, attributesToProps } from 'html-react-parser';

import dayjs from 'dayjs';

import { TopTitleBar } from './components/TopTitleBar';
import { Section } from '../../../../../components/Section';

import { campingDummyData, campingImagesDummyData } from './dummayData';
import { Icon } from '../../../../../components/Icon';
import { Button } from '../../../../../components/Button';
import { CustomDrawer } from './CustomDrawer';
import { Calendar } from './Calendar';
import { toast } from 'react-toastify';
import { getCampingZoneSiteInventories } from '../../../../../api_v2/non_admin/camping';

import {
  getCampingAvailRoomTypesV2,
  getCampingInfoV2,
  getCampingRoomTypesV2,
} from '../../../../../api_v2/non_admin/onda';

import { ReactComponent as IconLocation } from '../../../../../assets/icons/icon-location.svg';
import { getBanners } from '../../../../../api_v2/non_admin/banners';
import axios from 'axios';
import { api } from '../../../../../plugins/axios';
import { popupType1State } from '../../../../../ridge/ridge';
import { PopupType1 } from '../../components/PopupType1';

SwiperCore.use([Pagination, Autoplay]);

const propDetailInfoNavObj: { [key: string]: any } = {
  campingPagePropIntro: '소개',
  campingPagePropBookingInfo: '예약정보',
  campingPageNotice: '공지사항',
  campingPageRefundPolicyInfo: '취환불 정책정보',
};

const propNavLv1Obj: { [key: string]: any } = {
  propNavLv1PropShortInfo: '기본정보',
  propNavLv1PropCalendar: '일정선택',
  propNavLv1PropRoomtypes: '객실선택',
  propNavLv1PropDetailInfo: '숙소소개',
  propNavLv1PropRefundPolicyInfo: '환불정책표',
};

export const CampingPage = (props: any) => {
  const history = useHistory();
  const { search } = useLocation();

  const { campingId } = useParams<{ campingId: string }>();
  const [campingInfo, setCampingInfo] = useState<any>(null);

  const [expandCampingIntro, setExpandCampingIntro] = useState(false);
  const [expandCampingZoneList, setExpandCampingZoneList] = useState(false);

  const [expandRoomTypeList, setExpandRoomTypeList] = useState(false);

  const campingRefundPolicyDrawerRef = useRef<HTMLDivElement | null>(null);

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [calendarState, setCalendarState] = useState<any>([
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: new Date(parsedSearch?.start_date as string),
          endDate: new Date(parsedSearch?.end_date as string),
          key: 'selection',
        }
      : {
          startDate: null,
          endDate: new Date(''),
          key: 'selection',
        },
  ]);

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const [locale, setLocale] = useState<any>('ko');

  const [campingInfoDesc, setCampingInfoDesc] = useState('');
  const [campingInfoPolicy, setCampingInfoPolicy] = useState('');

  const [disabledDates, setDisabledDates] = useState<any[]>([]);
  const [initialInventoryData, setInitialInventoryData] = useState<any[]>([]);

  const [campingZones, setCampingZones] = useState<any[]>([]);

  const [roomTypes, setRoomTypes] = useState<any[]>([]);
  const [initialRoomTypes, setInitialRoomTypes] = useState<any[]>([]);

  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [initialLoadingForCampingInfo, setInitialLoadingForCampingInfo] = useState<boolean>(true);
  const [initialLoadingForRoomtypes, setInitialLoadingForRoomtypes] = useState<boolean>(true);

  useEffect(() => {
    Promise.all([
      getCampingInfoV2(campingId, '').then((res) => {
        if (res?.success && res?.data) {
          if (res?.data?.detail) {
            setCampingInfo(res.data.detail);
          }
        }
        setInitialLoadingForCampingInfo(false);
      }),
      getCampingRoomTypesV2(campingId, '').then((res) => {
        if (res?.success && res?.data) {
          if (res?.data?.roomTypes) {
            let modifiedRTs = res.data.roomTypes.map((item: any) => {
              return { ...item };
            });
            setInitialRoomTypes(modifiedRTs);
            setRoomTypes(modifiedRTs);
          }
        }
        setInitialLoadingForRoomtypes(false);
      }),
    ]).then((data) => {
      // setInitialLoading(false);
    });
  }, []);

  useEffect(() => {
    if (campingInfo?.description) {
      modifyBodyEle(campingInfo.description, setCampingInfoDesc);
    }
    if (campingInfo?.policy) {
      modifyBodyEle(campingInfo.policy, setCampingInfoPolicy);
    }
  }, [campingInfo]);

  const [getAvailRoomtypesStatus, setGetAvailRoomtypesStatus] = useState(false);

  const cancelTokenRefForAvailRoomtypes = useRef();

  useEffect(() => {
    const repeatedFunc = () => {
      let queryStrObj: { [key: string]: any } = {};
      queryStrObj.checkin = convertDateForInventoriesQS(calendarState[0]?.startDate);
      queryStrObj.checkout = convertDateForInventoriesQS(calendarState[0]?.endDate);
      setGetAvailRoomtypesStatus(true);
      loadingDots(true, 'roomtypelist-loading-container');

      (cancelTokenRefForAvailRoomtypes as any).current = axios.CancelToken.source();

      api
        .get(
          `/properties/onda-api/${campingId}/availroomtypes` +
            stringify(queryStrObj, { addQueryPrefix: true, encode: false }),
          { cancelToken: (cancelTokenRefForAvailRoomtypes as any).current.token }
        )
        .then((res) => {
          let possibleStatus = [200, 201];

          if (possibleStatus.includes(res?.status)) {
            if (res?.data) {
              if (res?.data?.availRoomTypes) {
                setRoomTypes(
                  res.data.availRoomTypes.map((item: any) => {
                    let foundInitRTObj = null;
                    if (initialRoomTypes) {
                      foundInitRTObj = initialRoomTypes.find(
                        (initRTObj: any) => initRTObj?.id == item?.roomtype_id
                      );
                    }
                    return {
                      id: item?.roomtype_id,
                      name: item?.roomtype_name,
                      detailData: foundInitRTObj?.detailData ?? null, //item?.detailData,
                      rateplans: item?.rateplans,
                      minPricePerDay: item?.minPricePerDay,
                      minTotal: item?.minTotal,
                      local_images: foundInitRTObj?.local_images ?? null,
                      vacancyData: item?.vacancyData,
                      inventoryData: item?.inventoryData,
                    };
                  })
                );
              }
              setGetAvailRoomtypesStatus(false);
              loadingDots(false, 'roomtypelist-loading-container');
            } else {
              setRoomTypes([]);
              setGetAvailRoomtypesStatus(false);
              loadingDots(false, 'roomtypelist-loading-container');
            }
          } else {
            setRoomTypes([]);
            setGetAvailRoomtypesStatus(false);
            loadingDots(false, 'roomtypelist-loading-container');
          }
        })
        .catch(async (e) => {
          console.log('e', e);
          let tokenPromiseAllRes = await Promise.all([
            (cancelTokenRefForAvailRoomtypes as any)?.current?.token?.promise,
          ]);
          if (
            tokenPromiseAllRes[0] &&
            tokenPromiseAllRes[0]?.message &&
            tokenPromiseAllRes[0]?.message?.includes('Canceled api request due to new request')
          ) {
          } else {
            setRoomTypes([]);
            setGetAvailRoomtypesStatus(false);
            loadingDots(false, 'roomtypelist-loading-container');
          }
        });
    };

    if (cancelTokenRefForAvailRoomtypes?.current) {
      (cancelTokenRefForAvailRoomtypes as any).current.cancel(
        'Canceled api request due to new request.'
      );
      setGetAvailRoomtypesStatus(false);
      loadingDots(false, 'roomtypelist-loading-container');
    }

    if (
      convertDateForInventoriesQS(calendarState[0]?.startDate) ==
      convertDateForInventoriesQS(calendarState[0]?.endDate)
    ) {
      // repeatedFunc();
      setRoomTypes([]);
    } else if (calendarState[0]?.startDate && calendarState[0]?.endDate) {
      const diffDays = dayjs(calendarState[0]?.endDate).diff(calendarState[0]?.startDate, 'day');
      if (diffDays + 1 >= 11) {
        toast.error('최대 10일까지 선택 가능합니다');
        setRoomTypes([]);
      } else {
        repeatedFunc();
      }
    }
  }, [calendarState]);

  const [banners, setBanners] = useState(null);

  useEffect(() => {
    getBanners(
      stringify(
        {
          where: { type: 'detailProperty' },
        },
        { addQueryPrefix: true, encode: false }
      )
    )
      .then((res) => {
        if (res?.success == true && res?.data?.length > 0) {
          setBanners(res?.data);
        }
      })
      .catch((e) => {
        console.log('e', e);
      });
  }, []);

  const openCampingRefundPolicyDrawer = () => {
    if (campingRefundPolicyDrawerRef.current) {
      campingRefundPolicyDrawerRef.current.style.width = '100%';
      campingRefundPolicyDrawerRef.current.style.height = '80%';
    }
  };

  const handleCampingRoomType = (campingId: any, roomTypeId: any) => {
    if (calendarState[0]?.startDate && calendarState[0]?.endDate) {
      const queryString = stringify(
        // {
        //   start_date: calendarState[0]?.startDate
        //     ? calendarState[0]?.startDate?.toLocaleDateString()
        //     : '',
        //   end_date: calendarState[0]?.endDate
        //     ? calendarState[0]?.endDate?.toLocaleDateString()
        //     : '',
        // },
        {
          start_date: calendarState[0]?.startDate
            ? convertDateForInventoriesQS(calendarState[0]?.startDate)
            : '',
          end_date: calendarState[0]?.endDate
            ? convertDateForInventoriesQS(calendarState[0]?.endDate)
            : '',
        },
        { addQueryPrefix: true, encode: false }
      );
      history.push(`/v2/camping/campinginfo/${campingId}/roomtypes/${roomTypeId}` + queryString);
    } else {
      toast.error('일정을 선택해주세요');
    }
  };

  const getTags = (dataObj: any) => {
    try {
      let tempArr: any[] = [];
      for (let i = 0; i < Object.keys(dataObj).length; i++) {
        let key = Object.keys(dataObj)[i];

        if (key == 'properties') {
          tempArr = [...tempArr, ...dataObj[key]];
        }
      }
      return tempArr;
    } catch (e) {
      console.log('e', e);
      return [];
    }
  };

  const modifyBodyEle = (htmlStr: any, setStateFn: any) => {
    let doc = new DOMParser().parseFromString(htmlStr, 'text/html');
    let bodyElement: any = doc?.querySelector('body');
    if (bodyElement) {
      bodyElement.style.display = 'flex';
      bodyElement.style['flex-direction'] = 'column';
      bodyElement.style['align-items'] = 'center';
      setStateFn(doc.documentElement.innerHTML);
    }
  };

  const getDaysInMonth = (month: any, year: any) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const convertDateForInventoriesQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const getElementChildrenLen = (htmlStr: any, elementType: any) => {
    let lenRes = 0;
    try {
      let doc = new DOMParser().parseFromString(htmlStr, 'text/html');
      let chosenElement: any = doc?.querySelector(elementType);
      lenRes = chosenElement?.children?.length ?? 0;
      return lenRes;
    } catch (e) {
      console.log('e', e);
      return lenRes;
    }
  };

  const calculateMinAvailForRoomType = (roomTypeObj: any) => {
    let final = 0;
    if (roomTypeObj?.rateplans) {
      roomTypeObj?.rateplans.forEach((ratePlanObj: any) => {
        final = final + (ratePlanObj?.minAvail ?? 0);
      });
    }
    return final;
  };

  const getClassifications = (classificationsArr: any) => {
    let finalStr = '';

    classificationsArr.forEach((cf: any, index: any) => {
      let addStr = index == 0 ? cf : ` | ` + cf;
      finalStr = finalStr + addStr;
    });
    return finalStr;
  };

  const handleAddress = (addressData: any) => {
    let searchQ = '';
    let add1 = addressData?.address1 ?? '';
    let add2 = (add1 ? ' ' : '') + (addressData?.address2 ?? '');
    let pc = (add2 ? ' ' : '') + addressData?.postal_code;
    searchQ = searchQ + add1 + add2; //+ pc;
    let queryObj = { q: searchQ };
    window.open(
      `https://map.kakao.com/${stringify(queryObj, {
        addQueryPrefix: true,
        encode: false,
      })}`
    );
  };

  const checkStrLen = (strData: any) => {
    return strData.length;
  };

  const loadingDots = (statusBool: boolean, className: string) => {
    let loadingContEle = document.querySelector('.' + className);
    if (loadingContEle) {
      if (statusBool) {
        (loadingContEle as any).style.display = 'block';
      } else {
        (loadingContEle as any).style.display = 'none';
      }
    }
  };

  const findMainImage = (arrData: any) => {
    let mainImgObj = null;
    if (arrData) {
      let foundImgObj = arrData.find((item: any) => item?.order == 0 || item?.order == 1);
      if (foundImgObj) {
        mainImgObj = foundImgObj;
      } else {
        mainImgObj = arrData[0];
      }
    }

    return mainImgObj;
  };

  const chooseImage = (imgObj: any) => {
    const tempImgObj = {
      _250: '250px',
      _500: '500px',
      _1000: '1000px',
      _original: 'original',
    };
    let final = imgObj[tempImgObj?._1000]
      ? imgObj[tempImgObj?._1000]
      : imgObj[tempImgObj?._500]
      ? imgObj[tempImgObj?._500]
      : imgObj[tempImgObj?._original]
      ? imgObj[tempImgObj?._original]
      : imgObj[tempImgObj?._250];

    return final;
  };

  const handleDaydiff = (startData: any, endData: any) => {
    let final = 0;
    if (dayjs(endData).diff(startData, 'day')) {
      final = dayjs(endData).diff(startData, 'day');
    }
    return final;
  };

  const [selectedPropDetailInfoNav, setSelectedPropDetailInfoNav] = useState<any>(null);

  const handlePropDetailInfoNav = async (data: any) => {
    try {
      const findEle = (idData: any) => {
        return document.querySelector('#' + idData);
      };

      if (findEle(data)) {
        await setExpandCampingIntro(true);
        const yOffset = -80;
        const calculatedTop =
          (findEle(data) as any).getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: calculatedTop, behavior: 'smooth' });

        setSelectedPropDetailInfoNav(data);
        // (document.querySelector('#' + data + 'SlideEle') as any).style['font-weight'] = '800';
      }
    } catch (e) {}
  };

  const [selectedPropNavLv1, setSelectedPropNavLv1] = useState<any>(null);

  const handlePropNavLv1 = async (data: any) => {
    try {
      if (data == 'propNavLv1PropRefundPolicyInfo') {
        openCampingRefundPolicyDrawer();
        setSelectedPropNavLv1(data);
      } else {
        const findEle = (idData: any) => {
          return document.querySelector('#' + idData);
        };

        if (findEle(data)) {
          const yOffset = -60;
          const calculatedTop =
            (findEle(data) as any).getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: calculatedTop, behavior: 'smooth' });

          setSelectedPropNavLv1(data);
          // (document.querySelector('#' + data + 'SlideEle') as any).style['font-weight'] = '800';
        }
      }
    } catch (e) {}
  };

  const handleBannerSwiperSlide = (bannerObj: any) => {
    window.location.href = bannerObj?.url ?? '/v2/camping';
  };

  const stopPopupType1 = () => {
    localStorage.setItem('stopPopupType1', JSON.stringify(true));
  };

  const nextPopupType1 = () => {
    let nextTime = dayjs().add(2, 'day');
    localStorage.setItem('nextPopupType1', JSON.stringify(nextTime));
  };

  const removeNextPopupType1 = () => {
    localStorage.removeItem('nextPopupType1');
  };

  const checkPopupType1 = () => {
    let lsNextPopupType1 = localStorage.getItem('nextPopupType1') as any;
    let lsStopPopupType1 = localStorage.getItem('stopPopupType1') as any;

    return { lsNextPopupType1, lsStopPopupType1 };
  };
  const jsonParseFunc = (jsonStrData: any) => {
    try {
      let parsed = JSON.parse(jsonStrData);
      return parsed;
    } catch (e) {
      console.log('e', e);
      return null;
    }
  };

  useEffect(() => {
    if (props.popupType1) {
      const onClose = () => {
        popupType1State.set({ ...popupType1State.get(), open: false });
        nextPopupType1();
      };

      if (
        checkPopupType1()?.lsStopPopupType1?.length > 0 &&
        checkPopupType1()?.lsStopPopupType1 == 'true'
      ) {
      } else {
        let setObj: any = { open: true, onClose };
        if (checkPopupType1()?.lsNextPopupType1) {
          let parsedTime = jsonParseFunc(checkPopupType1()?.lsNextPopupType1);
          if (parsedTime) {
            let diffTime = dayjs(parsedTime).diff(dayjs(), 'day');

            if (diffTime <= 0) {
              setObj.onClick = async () => {
                stopPopupType1();
                removeNextPopupType1();
                window.location.href = 'https://open.kakao.com/o/gSomAGjf';
              };
              popupType1State.set(setObj);
            }
          }
        } else {
          setObj.onClick = async () => {
            stopPopupType1();
            window.location.href = 'https://open.kakao.com/o/gSomAGjf';
          };
          popupType1State.set(setObj);
        }
      }
    }
  }, [props.popupType1]);

  const popupType1StateValue = popupType1State.useValue();

  return (
    <>
      {!initialLoadingForCampingInfo && popupType1StateValue && (
        <PopupType1 {...popupType1StateValue} />
      )}
      <TopTitleBar logo={true} home={false} />

      {
        <>
          <div className="max-w-md w-screen bg-[#F6F6F6]">
            {initialLoadingForCampingInfo ? (
              <>
                <div className="flex justify-center bg-[white] pt-[100px]">
                  <div className="initial-loading-container-for-campinginfo text-[50px]">
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                  </div>
                </div>
              </>
            ) : !initialLoadingForCampingInfo && campingInfo ? (
              <>
                <Section className="max-w-md bg-white">
                  <Swiper
                    pagination={{
                      dynamicBullets: true,
                    }}
                    // autoplay={{
                    //   delay: 3000,
                    // }}
                    className="mySwiper bg-white"
                    onAfterInit={(swiper) => {
                      if (swiper?.el?.style) {
                        (swiper.el.style as any)['padding-bottom'] = '0px';
                        (swiper.el.style as any)['height'] = '300px';
                      }
                    }}
                    onPaginationRender={(swiper, paginationEl) => {
                      if (paginationEl) {
                        paginationEl.style.position = 'absolute';
                        paginationEl.style.bottom = '0';
                        (paginationEl as any).style['padding-bottom'] = '15px';
                      }
                    }}
                  >
                    {campingInfo?.local_images &&
                      campingInfo.local_images.map((imgObj: any, index2: any) => (
                        <div key={(chooseImage(imgObj) ?? '') + campingInfo?.id + index2}>
                          <SwiperSlide className="h-full">
                            <img
                              className="object-cover w-full h-full"
                              src={chooseImage(imgObj) ?? ''}
                            />
                          </SwiperSlide>
                        </div>
                      ))}
                  </Swiper>
                </Section>
                <Section className="max-w-md side-padding pb-[20px] py-3 bg-[#FFFFFF]">
                  <div className="text-[#6F6F6F] text-[12px]">
                    {/* {campingInfo?.address && parseStr(campingInfo.address)?.region} */}
                    {/* {campingInfo?.address?.region} */}
                    {campingInfo?.classifications &&
                      getClassifications(campingInfo?.classifications)}
                  </div>
                  <div className="flex justify-between">
                    <div className="font-[700] text-[19px]">{campingInfo?.name}</div>
                    <div className="font-[700] text-[19px] text-[#FF6400]">
                      {campingInfo?.retail_price >= 0 ? (
                        campingInfo?.retail_price?.toLocaleString() + '원~'
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {
                    <div className="flex flex-wrap mt-3">
                      {campingInfo?.tags &&
                        getTags(campingInfo.tags).map((tag: any) => (
                          <div
                            className="flex justify-center items-center 
                  border border-[#D0D0D0] rounded p-1 text-[10px] w-fit mr-1 mb-1"
                          >
                            {tag}
                          </div>
                        ))}
                    </div>
                  }
                </Section>
                {/* <div className="bg-gray-100 w-full h-2" /> */}
                <div className="sticky top-[0px] z-30  ">
                  <Swiper
                    slidesPerView={5}
                    // autoplay={{
                    //   delay: 3000,
                    // }}

                    // slidesOffsetAfter={40}
                    // slidesOffsetBefore={40}
                    className="mySwiper bg-white"
                    onAfterInit={(swiper) => {
                      if (swiper?.el?.children[0] && (swiper?.el?.children[0] as any)?.style) {
                        // (swiper.el.children[0] as any).style['margin-left'] = '-40px';
                      }
                      if (swiper?.el?.style) {
                        (swiper.el.style as any)['padding-bottom'] = '0px';
                        // (swiper.el.style as any)['padding-top'] = '15px';
                        (swiper.el.style as any)['background-color'] = '#FFEADC';
                      }
                    }}
                    // breakpoints={{
                    //   500: {
                    //     slidesPerView: 5,
                    //   },
                    // }}
                  >
                    {/* border-bottom: 3px solid #ff6400 */}
                    {Object.keys(propNavLv1Obj).map((key: any, index2: any) => (
                      <SwiperSlide
                        key={key}
                        className={`h-full grid place-items-center ${
                          selectedPropNavLv1 == key ? 'border-b-[#ff6400] border-b-[3px]' : ''
                        } `}
                        onClick={() => {
                          handlePropNavLv1(key);
                        }}
                      >
                        <div
                          className={`w-max py-[15px] ${
                            selectedPropNavLv1 == key ? 'font-[700]' : ''
                          } cursor-pointer`}
                        >
                          {propNavLv1Obj[key]}
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <Section className="max-w-md side-padding pt-[20px] py-3 bg-[#FFFFFF]">
                  <div id="propNavLv1PropShortInfo" className="font-[700] text-[15px] mb-3">
                    기본정보
                  </div>

                  {campingInfo?.tags?.surroundings && (
                    <div className="flex">
                      <div className="text-[#FF6400] text-[13px] mr-1 items-center">환경:</div>
                      <div className="flex">
                        {campingInfo?.tags?.surroundings.map((tag: any) => (
                          <div
                            className="flex justify-center items-center 
                      border border-[#D0D0D0] rounded p-1 text-[10px] mr-1"
                          >
                            {tag}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="grid grid-cols-9">
                    <div className="col-span-2 text-[#FF6400] text-[13px] items-center">
                      대표번호
                    </div>
                    <div className="col-span-7">{campingInfo?.phone}</div>
                  </div>
                  {/* <div className="grid grid-cols-9">
                <div className="col-span-2 text-[#FF6400] text-[13px] items-center">이메일</div>
                <div className="col-span-7">{campingInfo?.email}</div>
              </div> */}

                  <div className="grid grid-cols-9">
                    <div className="col-span-2 text-[#FF6400] text-[13px] mr-1">주소</div>
                    {campingInfo?.address && (
                      <>
                        <div className="col-span-7 flex-col">
                          <div className="flex">
                            <p
                              className="cursor-pointer"
                              onClick={() => {
                                handleAddress(campingInfo?.address);
                              }}
                            >
                              {campingInfo?.address?.address1} {campingInfo?.address?.address2}
                            </p>
                          </div>
                          <div className="flex">
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={() => {
                                handleAddress(campingInfo?.address);
                              }}
                            >
                              <p className="mr-[2px]">{campingInfo?.address?.postal_code}</p>
                              <div className="flex items-center">
                                <IconLocation />
                                <p className="text-[10px] ml-[2px]">위치보기</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Section>

                <div className="bg-gray-100 w-full h-2" />
                <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
                  <div className="font-[700] text-[15px] mb-3">시설 및 주변환경</div>
                  <div className="grid grid-cols-9">
                    <div className="col-span-2 text-[#FF6400] text-[13px] mr-1">시설</div>

                    {campingInfo?.tags?.facilities && (
                      <div className="col-span-7 flex flex-wrap">
                        {campingInfo.tags.facilities.map((tag: any) => (
                          <div className="flex justify-center items-center border border-[#D0D0D0] rounded p-1 text-[10px] mr-1 mb-1">
                            {tag}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-9 mt-1">
                    <div className="col-span-2 text-[#FF6400] text-[13px] mr-1">제공 서비스</div>
                    {campingInfo?.tags?.services && (
                      <div className="col-span-7 flex flex-wrap">
                        {campingInfo.tags.services.map((tag: any) => (
                          <div className="flex justify-center items-center border border-[#D0D0D0] rounded p-1 text-[10px] mr-1 mb-1">
                            {tag}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-9 mt-1">
                    <div className="col-span-2 text-[#FF6400] text-[13px] mr-1">주변 정보</div>

                    {campingInfo?.tags?.attractions && (
                      <div className="col-span-7 flex flex-wrap">
                        {campingInfo.tags.attractions.map((tag: any) => (
                          <div className="flex justify-center items-center border border-[#D0D0D0] rounded p-1 text-[10px] mr-1 mb-1">
                            {tag}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Section>
                {(banners as any)?.length > 0 && (
                  <>
                    {/* <div className="bg-gray-100 w-full h-2" /> */}
                    <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
                      {
                        <div
                          className="w-full h-full rounded aspect-[1000/200]"
                          onClick={() => {
                            handleBannerSwiperSlide((banners as any)[0]);
                          }}
                        >
                          <img
                            className="object-cover w-full h-full max-h-[200px] rounded"
                            src={(banners as any)[0]?.thumbnail ?? ''}
                          />
                        </div>
                      }
                      {/* <Swiper
                        pagination={{ dynamicBullets: true }}
                        // autoplay={{
                        //   delay: 3000,
                        // }}
                        slidesPerView={1.2}
                        spaceBetween={5}
                        onPaginationRender={(swiper, paginationEl) => {
                          if (paginationEl) {
                            paginationEl.style.cssText =
                              'position: absolute; bottom: 10px!important;';
                          }
                        }}
                        loop={false}
                        className="mySwiper z-[0] max-h-[150px] rounded"
                      >
                        {(banners as any).map((item: any) => (
                          <div key={item?.id}>
                            <SwiperSlide
                              className="w-full h-full rounded aspect-[1000/200]"
                              onClick={() => {
                                handleBannerSwiperSlide(item);
                              }}
                            >
                              <img
                                className="object-cover w-full h-full max-h-[150px] rounded"
                                src={item?.thumbnail ?? ''}
                              />
                            </SwiperSlide>
                          </div>
                        ))}
                      </Swiper> */}
                    </Section>
                  </>
                )}

                <div className="bg-gray-100 w-full h-2" />
                <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
                  <div className="font-[700] text-[15px] mb-3"></div>
                  <div
                    className="grid grid-cols-2 place-items-center
               w-full bg-[#FFEADC] p-4 mb-3"
                  >
                    <div className="flex flex-col items-center text-[#FF6400]">
                      <div className="font-[700] text-[16px]">
                        {campingInfo?.checkin ? campingInfo?.checkin.slice(0, 5) : null}
                      </div>
                      <div className="text-[12px]">체크인</div>
                    </div>
                    <div className="flex flex-col items-center text-[#FF6400]">
                      <div className="font-[700] text-[16px]">
                        {campingInfo?.checkout ? campingInfo?.checkout.slice(0, 5) : null}
                      </div>
                      <div className="text-[12px]">체크아웃</div>
                    </div>
                  </div>
                  <div className="text-[12px] mt-1"></div>
                </Section>

                <div className="bg-gray-100 w-full h-2" />
                <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
                  {initialLoadingForRoomtypes ? (
                    <>
                      <div className="flex justify-center">
                        <div className="initial-loading-container-for-roomtypes text-[50px]">
                          <span className="loading-dot">.</span>
                          <span className="loading-dot">.</span>
                          <span className="loading-dot">.</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div id="propNavLv1PropCalendar" className="font-[700] text-[15px]">
                        일정 선택하기
                      </div>
                      <Calendar
                        locale={locale}
                        state={calendarState}
                        setState={setCalendarState}
                        setChosenDates={setChosenDates}
                        // drawerRef={drawerRef}
                        confirmButton={false}
                        disabledDates={disabledDates}
                      />
                    </>
                  )}
                </Section>

                <div className="bg-gray-100 w-full h-2" />
                <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
                  <div id="propNavLv1PropRoomtypes" className="font-[700] text-[15px] mb-3">
                    객실 선택하기
                  </div>
                  {initialLoadingForRoomtypes ? (
                    <>
                      <div className="flex justify-center">
                        <div className="initial-loading-container-for-roomtypes text-[50px]">
                          <span className="loading-dot">.</span>
                          <span className="loading-dot">.</span>
                          <span className="loading-dot">.</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {getAvailRoomtypesStatus == true ? (
                        <div className="flex justify-center">
                          <div className="roomtypelist-loading-container text-[50px]">
                            <span className="loading-dot">.</span>
                            <span className="loading-dot">.</span>
                            <span className="loading-dot">.</span>
                          </div>
                        </div>
                      ) : roomTypes.length == 0 ? (
                        <div className="flex justify-center items-center">
                          선택하신 조건의 객실이 존재하지않습니다
                        </div>
                      ) : (
                        <>
                          <div
                            className={`${
                              expandRoomTypeList ? 'h-fit' : roomTypes.length >= 3 ? 'h-36' : 'h-16'
                            } relative overflow-hidden pt-4`}
                          >
                            {!expandRoomTypeList && (
                              <div className="bottom-0 z-10 absolute bg-gradient-to-t from-black/10 to bg-transparent w-full h-1/2" />
                            )}

                            {roomTypes &&
                              roomTypes.map((roomTypeObj: any) => (
                                <div
                                  className={`grid grid-cols-10 place-items-center border border-[#D9D9D9] 
                      mb-3 cursor-pointer max-h-[150px]`}
                                  style={{ borderRadius: '5px 5px 5px 5px' }}
                                  onClick={() => {
                                    if (calendarState[0]?.startDate && calendarState[0]?.endDate) {
                                      if (
                                        convertDateForInventoriesQS(calendarState[0]?.startDate) ==
                                        convertDateForInventoriesQS(calendarState[0]?.endDate)
                                      ) {
                                        toast.error('당일예약은 불가능합니다.');
                                      } else {
                                        if (
                                          roomTypeObj?.vacancyData?.vacancy ==
                                            roomTypeObj?.inventoryData?.vacancy &&
                                          roomTypeObj?.vacancyData?.vacancy >= 1
                                        ) {
                                          handleCampingRoomType(campingId, roomTypeObj?.id);
                                        } else {
                                          toast.error('예약이 마감된 객실입니다.');
                                        }
                                      }
                                    } else {
                                      toast.error('일정을 선택해주세요');
                                    }
                                  }}
                                >
                                  <div className="col-span-5 place-self-start pt-2 pb-2 pl-2 max-h-[150px]">
                                    <div className="font-[500] text-[13px]">
                                      {roomTypeObj?.name}
                                    </div>
                                    <div className="font-[500] text-[#FF6400] text-[16px]">
                                      {roomTypeObj?.minPricePerDay > 0
                                        ? roomTypeObj?.minPricePerDay?.toLocaleString() +
                                          '원 ~ / 박'
                                        : null}
                                    </div>
                                    <div className="font-[500] text-[#656565] text-[10px]">
                                      {roomTypeObj?.minTotal > 0
                                        ? `${
                                            calendarState[0]?.startDate && calendarState[0]?.endDate
                                              ? `${
                                                  handleDaydiff(
                                                    calendarState[0]?.startDate,
                                                    calendarState[0]?.endDate
                                                  ) > 0
                                                    ? `${handleDaydiff(
                                                        calendarState[0]?.startDate,
                                                        calendarState[0]?.endDate
                                                      )}박 `
                                                    : ''
                                                }`
                                              : ''
                                          }총액 ` +
                                          roomTypeObj?.minTotal?.toLocaleString() +
                                          '원 ~'
                                        : null}
                                    </div>

                                    {roomTypeObj?.detailData?.capacity?.standard >= 0 ? (
                                      <div className="text-[10px] text-[#9A9A9A] text-[10px]">
                                        {'- 기준인원 ' +
                                          (roomTypeObj?.detailData?.capacity?.standard + '인')}
                                      </div>
                                    ) : null}
                                    {roomTypeObj?.detailData?.capacity?.max >= 0 ? (
                                      <div className="text-[10px] text-[#9A9A9A] text-[10px]">
                                        {'- 최대인원 ' +
                                          (roomTypeObj?.detailData?.capacity?.max + '인')}
                                      </div>
                                    ) : null}
                                  </div>

                                  <div className="col-span-5 h-full w-full max-h-[150px] relative">
                                    {calendarState[0]?.startDate && calendarState[0]?.endDate ? (
                                      <>
                                        <div
                                          className={`absolute right-2 top-2 h-fit w-fit ${
                                            roomTypeObj?.vacancyData?.vacancy ==
                                              roomTypeObj?.inventoryData?.vacancy &&
                                            roomTypeObj?.vacancyData?.vacancy > 0
                                              ? 'bg-[#FF6400]'
                                              : 'bg-[#f24c3f]'
                                          } text-[#FFFFFF] p-1 rounded`}
                                        >
                                          {roomTypeObj?.vacancyData?.vacancy ==
                                            roomTypeObj?.inventoryData?.vacancy &&
                                          roomTypeObj?.vacancyData?.vacancy > 0 ? (
                                            <>예약 가능 {roomTypeObj?.vacancyData?.vacancy}</>
                                          ) : (
                                            <>예약 마감</>
                                          )}
                                        </div>
                                      </>
                                    ) : null}

                                    {roomTypeObj?.local_images &&
                                    roomTypeObj?.local_images?.length > 0 ? (
                                      <>
                                        {findMainImage(roomTypeObj?.local_images) ? (
                                          <>
                                            <img
                                              className="object-cover h-full w-full"
                                              style={{
                                                borderRadius: '0px 5px 5px 0px',
                                              }}
                                              src={
                                                chooseImage(
                                                  findMainImage(roomTypeObj?.local_images)
                                                ) ?? ''
                                              }
                                            />
                                          </>
                                        ) : (
                                          <div
                                            className="h-full w-full bg-gray-50"
                                            style={{
                                              borderRadius: '0px 5px 5px 0px',
                                            }}
                                          ></div>
                                        )}
                                      </>
                                    ) : (
                                      <div
                                        className="h-full w-full bg-gray-50"
                                        style={{
                                          borderRadius: '0px 5px 5px 0px',
                                        }}
                                      ></div>
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                          <Button
                            onClick={() => setExpandRoomTypeList(!expandRoomTypeList)}
                            className="flex space-x-2 items-center justify-center w-full border"
                          >
                            <p> {expandRoomTypeList ? '더보기 접기' : '더보기'}</p>
                            <Icon.ChevronDown
                              className={`${
                                expandRoomTypeList ? 'rotate-180' : ''
                              } transition-transform`}
                            />
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Section>

                <div className="bg-gray-100 w-full h-2" />
                {/* <div className="sticky top-[0px] z-30">
              <Swiper
                slidesPerView={3.5}
                // autoplay={{
                //   delay: 3000,
                // }}

                slidesOffsetAfter={50}
                slidesOffsetBefore={50}
                className="mySwiper bg-white"
                onAfterInit={(swiper) => {
                  if (swiper?.el?.children[0] && (swiper?.el?.children[0] as any)?.style) {
                    (swiper.el.children[0] as any).style['margin-left'] = '-40px';
                  }
                  if (swiper?.el?.style) {
                    (swiper.el.style as any)['padding-bottom'] = '10px';
                    (swiper.el.style as any)['padding-top'] = '10px';
                    (swiper.el.style as any)['background-color'] = '#FFEADC';
                  }
                }}
                // breakpoints={{
                //   500: {
                //     slidesPerView: 5,
                //   },
                // }}
              >
                {Object.keys(propDetailInfoNavObj).map((key: any, index2: any) => (
                  <SwiperSlide
                    key={key}
                    className="h-full grid place-items-center"
                    onClick={() => {
                      handlePropDetailInfoNav(key);
                    }}
                  >
                    <div
                      className={`w-max  p-[10px] ${
                        selectedPropDetailInfoNav == key ? 'font-[800]' : ''
                      } cursor-pointer`}
                    >
                      {propDetailInfoNavObj[key]}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div> */}
                <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
                  <div
                    id="propNavLv1PropDetailInfo"
                    className={`${
                      expandCampingIntro
                        ? 'h-fit'
                        : checkStrLen(
                            (campingInfo?.descriptions?.property ?? '') +
                              (campingInfo?.descriptions?.reservation ?? '') +
                              (campingInfo?.descriptions?.notice ?? '') +
                              (campingInfo?.descriptions?.refunds ?? '')
                          ) >= 200
                        ? 'h-52'
                        : 'h-12'
                    } relative overflow-hidden pt-1 -mx-4 side-padding`}
                  >
                    {!expandCampingIntro && (
                      <div className="bottom-0 z-10 absolute bg-gradient-to-t from-black/10 to bg-transparent w-full h-1/2" />
                    )}

                    <div className="relative">
                      <div id="campingPagePropIntro" className="font-[700] text-[15px]">
                        숙소 소개
                      </div>
                      <div style={{ whiteSpace: 'pre-line', wordBreak: 'keep-all' }}>
                        {htmlReactParser(campingInfo?.descriptions?.property ?? '')}
                      </div>
                      <div id="campingPagePropBookingInfo" className="font-[700] text-[15px] mt-2">
                        숙소 예약정보
                      </div>
                      <pre className="whitespace-pre-wrap">
                        {htmlReactParser(campingInfo?.descriptions?.reservation ?? '')}
                      </pre>
                      <div id="campingPageNotice" className="font-[700] text-[15px] mt-2">
                        숙소 별도 공지사항
                      </div>
                      <pre className="whitespace-pre-wrap">
                        {htmlReactParser(campingInfo?.descriptions?.notice ?? '')}
                      </pre>
                      <div id="campingPageRefundPolicyInfo" className="font-[700] text-[15px] mt-2">
                        숙소 취환불 정책정보
                      </div>
                      <pre className="whitespace-pre-wrap">
                        {htmlReactParser(campingInfo?.descriptions?.refunds ?? '')}
                      </pre>
                    </div>
                  </div>

                  <Button
                    onClick={() => setExpandCampingIntro(!expandCampingIntro)}
                    className="flex space-x-2 items-center justify-center w-full border"
                  >
                    <p> {expandCampingIntro ? '접기' : '더보기'}</p>
                    <Icon.ChevronDown
                      className={`${expandCampingIntro ? 'rotate-180' : ''} transition-transform`}
                    />
                  </Button>
                </Section>

                <div className="bg-gray-100 w-full h-2" />
                <Section
                  id="propNavLv1PropRefundPolicyInfo"
                  className="max-w-md side-padding py-3 bg-[#FFFFFF]"
                >
                  <div className="flex justify-between">
                    <div className="font-[700] text-[15px]">환불 정책표</div>
                    <Icon.ChevronRight onClick={() => openCampingRefundPolicyDrawer()} />
                  </div>
                </Section>
                <Section className="bg-gray-100 side-padding">
                  <div className="py-[5px]">
                    <div>
                      <div className="border-solid rounded border border-stone-300 float-right p-0.5 text-xs">
                        <a href="/v2/camping/faq">자주묻는질문</a>
                      </div>
                      <p className="font-[700] text-[15px]">고객센터</p>
                      <p>010-5288-4333</p>
                      <p>평일 10:30 ~ 17:00 점심 12:30 ~ 14:00 </p>
                      <p>토,일요일 및 공휴일 휴무</p>
                    </div>
                    <div className="w-full border my-3" />
                    <div>
                      <div className="font-[700] text-[15px] mb-3">주식회사 보딩패스</div>
                      <div className="grid grid-cols-10">
                        <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">
                          주소
                        </div>
                        <div className="col-span-7">
                          서울특별시 성동구 아차산로7나길 18 (성수동 2가) 대선 APEXCENTER 905호
                        </div>
                      </div>
                      <div className="grid grid-cols-10">
                        <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">
                          대표
                        </div>
                        <div className="col-span-7">조건형</div>
                      </div>
                      <div className="grid grid-cols-10">
                        <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">
                          사업자등록번호
                        </div>
                        <div className="col-span-7">323-88-02118</div>
                      </div>
                      <div className="grid grid-cols-10">
                        <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">
                          통신판매업신고
                        </div>
                        <div className="col-span-7">2022-서울성동-00191</div>
                      </div>
                    </div>
                    <p className="my-3">
                      보딩패스는 통신판매중개자로서 통신판매의 당사자가 아니며, 상품의 예약, 이용 및
                      환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.
                    </p>
                  </div>

                  {/* <div className="pb-[100px]" /> */}
                </Section>
              </>
            ) : null}
          </div>
        </>
      }
      <div>
        <CustomDrawer drawerRef={campingRefundPolicyDrawerRef}>
          <div className="side-padding mt-[75px]">
            <div className="grid grid-cols-[auto_auto] border-t-[1px] pb-[1px]">
              <div className="py-[2px] text-center py-[8px]">기간</div>
              <div className="py-[2px] text-center text-[#FF6400] py-[8px]">환불 비율</div>
              <div className="col-span-2 border-b-[1px]"></div>
              {campingInfo?.property_refunds &&
                Object.keys(campingInfo?.property_refunds).map((key) => (
                  <>
                    <div className="border-b-[1px] py-[8px] text-center">
                      {key && key.replace('d', ' 일전')}
                    </div>
                    <div className="border-b-[1px] py-[8px] text-center text-[#FF6400]">
                      {campingInfo?.property_refunds[key]}%
                    </div>
                  </>
                ))}
            </div>
            <p className="text-[#FF6400] m-[7px]">
              * 결제전 약관 동의 취소/환불정책 항목 [약관보기] 한번 더 확인 부탁드립니다.
            </p>
          </div>
        </CustomDrawer>
      </div>
    </>
  );
};
