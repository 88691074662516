import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useInViewport } from 'react-in-viewport';

import { ReactComponent as IconEdit } from '../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconTrash } from '../../../../../assets/icons/icon-trash.svg';

import './style.css';

import PostEdit from '../../post/PostEdit';
import {
  deleteCommunityPost,
  deleteCommunityPostComment,
} from '../../../../../api_v2/non_admin/community';
import { toast } from 'react-toastify';

interface CardType2 {
  item?: any;
  lastElement?: any;
  lowestPricesObj?: any;
  pageInd?: any;
  openDrawer?: any;
  closeDrawer?: any;
  DrawerType?: any;
  setChosenEditPostComment?: any;
  refetchMyPagePostsComments?: any;
}

export const CardType2: FC<CardType2> = ({
  item,
  lastElement,
  lowestPricesObj,
  pageInd,
  openDrawer,
  closeDrawer,
  DrawerType,
  setChosenEditPostComment,
  refetchMyPagePostsComments,
}) => {
  const cardObserver = useRef<IntersectionObserver>();
  const cardImgRef = useRef<any>(null);
  const [imgInViewport, setImgInViewport] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  const viewportRef = useRef<any>(null);
  const { inViewport, enterCount, leaveCount } = useInViewport(
    viewportRef as any,
    { threshold: 0.1 },
    { disconnectOnLeave: false }
  );

  const [emptyBoxHeight, setEmptyBoxHeight] = useState('360px');

  const [lowestPricesForGivenPage, setLowestPricesForGivenPage] = useState<any>(null);

  const cardImgContRef = useCallback((target) => {
    if (cardObserver.current) cardObserver.current.disconnect();

    cardObserver.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setImgInViewport(true);
        } else {
        }
      },
      {
        // rootMargin: '400px',
        threshold: 0.1,
      }
    );
    if (target) cardObserver.current.observe(target);
  }, []);

  useEffect(() => {
    const handleEvent = () => {
      let imgEle = cardImgRef?.current?.querySelector('img');
      if (imgEle?.complete == true && imgInViewport == true) {
        setImgLoaded(true);
      }
    };
    handleEvent();
    window.addEventListener('DOMSubtreeModified', handleEvent);
    return () => window.removeEventListener('DOMSubtreeModified', handleEvent);
  }, [imgInViewport, cardImgRef?.current?.querySelector('img')]);

  const handleEditPostComment = async () => {
    closeDrawer();
    setChosenEditPostComment(item);
    openDrawer(DrawerType.EDIT_POST_COMMENT);
  };

  const [deleteCommunityPostCommentStatus, setDeleteCommunityPostComment] = useState(false);

  const handleDeletePostComment = async () => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteCommunityPostComment(true);
      let deleteCommunityPostCommentRes = await deleteCommunityPostComment(item);
      if (deleteCommunityPostCommentRes?.success == true) {
        refetchMyPagePostsComments();
        toast.success('성공적으로 삭제하였습니다.');
      } else {
        toast.error('오류가 발생하였습니다.');
      }
      setDeleteCommunityPostComment(false);
    }
  };

  const [contentTimePassed, setContentTimePassed] = useState<any>(null);

  useEffect(() => {
    if (item) {
      const calculateTimeAgo = (timeData: any) => {
        const textWrittenTime = timeData as any;
        const currentTime = new Date() as any;
        const timeDifference = currentTime - textWrittenTime;

        const secondsPassed = Math.floor(timeDifference / 1000);
        const minutesPassed = Math.floor(secondsPassed / 60);
        const hoursPassed = Math.floor(minutesPassed / 60);
        const daysPassed = Math.floor(hoursPassed / 24);
        const yearsPassed = Math.floor(daysPassed / 365);

        if (yearsPassed > 0) {
          return `${yearsPassed} 년${yearsPassed > 1 ? '' : ''} 전`;
        } else if (daysPassed > 0) {
          return `${daysPassed} 일${daysPassed > 1 ? '' : ''} 전`;
        } else if (hoursPassed > 0) {
          return `${hoursPassed} 시간${hoursPassed > 1 ? '' : ''} 전`;
        } else if (minutesPassed > 0) {
          return `${minutesPassed} 분${minutesPassed > 1 ? '' : ''} 전`;
        } else {
          return `${secondsPassed} 초${secondsPassed > 1 ? '' : ''} 전`;
        }
      };

      const dateString = item.createdAt;
      const parts = dateString.split(/[-T:.]/);
      const year = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Months are 0-indexed
      const day = parseInt(parts[2]);
      const hour = parseInt(parts[3]);
      const minute = parseInt(parts[4]);
      const second = parseInt(parts[5]);
      const millisecond = parseInt(parts[6]);

      const localDate = new Date(year, month, day, hour, minute, second, millisecond);

      // setContentTimePassed(calculateTimeAgo(localDate));
      setContentTimePassed(calculateTimeAgo(new Date(item.createdAt)));
    }
  }, [item]);

  const checkContentLength = (text: any) => {
    try {
      let lineBreakCount = 0;
      for (let i = 0; i < text.length; i++) {
        if (text[i] == '\n') {
          lineBreakCount++;
        }
      }
      if (lineBreakCount > 0) {
        if (lineBreakCount >= 2) {
          return {
            count: lineBreakCount,
            modifiedText: text?.split('\n')[0]?.substring(0, 45) + '...',
          };
        } else {
          if (text?.length >= 50) {
            return { count: lineBreakCount, modifiedText: text?.substring(0, 45) + '...' };
          } else {
            return { count: lineBreakCount, modifiedText: text };
          }
        }
      } else {
        if (text?.length >= 50) {
          return { count: lineBreakCount, modifiedText: text?.substring(0, 45) + '...' };
        } else {
          return { count: lineBreakCount, modifiedText: text };
        }
      }
    } catch (e) {
      return null;
    }
  };

  return (
    <div
      ref={viewportRef}
      className="border border-[#D9D9D9] mb-5 card-type-2"
      style={{ borderRadius: '5px 5px 5px 5px' }}
      /* top-left | top-right | bottom-right | bottom-left */
    >
      {inViewport ? (
        <>
          <div ref={cardImgContRef} key={item?.id} style={{ height: '100px' }} className="p-[15px]">
            <div className="flex justify-between">
              <pre
                className="text-[15px]"
                style={{
                  overflow: 'hidden',
                  // textOverflow: 'ellipsis',
                  // display: '-webkit-box',
                  // WebkitLineClamp: 3,
                  // WebkitBoxOrient: 'vertical',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {item?.content ? checkContentLength(item?.content)?.modifiedText ?? null : null}
              </pre>
            </div>
            <div className="mt-[5px] text-[12px] text-[#878787]">{contentTimePassed ?? null}</div>
          </div>
        </>
      ) : (
        <div
          className="bg-[#dedbd9] blur-[4px]"
          style={{
            height: '100px', //viewportRef?.current?.clientHeight,
            width: '100%',
          }}
        ></div>
      )}
      <div className="flex justify-end mb-[5px]">
        {deleteCommunityPostCommentStatus ? (
          <div className="w-[13px] h-[13px] border-t-4 border-blue-500 border-solid rounded-full animate-spin mr-[5px] p-[13px]"></div>
        ) : (
          <div
            className="mr-[5px] p-[13px]"
            onClick={(e) => {
              e.preventDefault();
              handleEditPostComment();
            }}
          >
            <IconEdit width={'20px'} height={'20px'} />
          </div>
        )}
        {deleteCommunityPostCommentStatus ? (
          <div className="w-[13px] h-[13px] border-t-4 border-blue-500 border-solid rounded-full animate-spin mr-[5px] p-[13px]"></div>
        ) : (
          <div
            className="mr-[5px] p-[13px]"
            onClick={(e) => {
              e.preventDefault();
              handleDeletePostComment();
            }}
          >
            <IconTrash width={'20px'} height={'20px'} />
          </div>
        )}
      </div>
    </div>
  );
};
