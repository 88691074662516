import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { default as htmlReactParser, attributesToProps } from 'html-react-parser';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useInViewport } from 'react-in-viewport';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as regularBookmark } from '@fortawesome/free-regular-svg-icons';

import { ReactComponent as IconNoPicture } from '../../../../../../assets/icons/icon-no-picture.svg';
import { ReactComponent as IconComment } from '../../../../../../assets/icons/icon-comment.svg';

// import './style.css';

import { toast } from 'react-toastify';
import { authUserTokenObjState } from '../../../../../../ridge/ridge';
import { api } from '../../../../../../plugins/axios';

const categoryNameObj: { [key: string]: any } = {
  domesticTravel: '국내여행',
  internationalTravel: '해외여행',
  domesticFestival: '국내축제',
  internationalFestival: '해외축제',
  etc: '기타',
};

interface CardType1 {
  item?: any;
  handlePostinfoSwiperSlide?: any;
  pageInd?: any;
  reFetchBookmarkPagination?: any;
}

export const CardType1: FC<CardType1> = ({
  item,
  handlePostinfoSwiperSlide,
  pageInd,
  reFetchBookmarkPagination,
}) => {
  const cardObserver = useRef<IntersectionObserver>();
  const cardImgRef = useRef<any>(null);
  const [imgInViewport, setImgInViewport] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  const viewportRef = useRef<any>(null);
  const { inViewport, enterCount, leaveCount } = useInViewport(
    viewportRef as any,
    { threshold: 0.1 },
    { disconnectOnLeave: false }
  );

  const [emptyBoxHeight, setEmptyBoxHeight] = useState('360px');

  const [lowestPricesForGivenPage, setLowestPricesForGivenPage] = useState<any>(null);

  const cardImgContRef = useCallback((target) => {
    if (cardObserver.current) cardObserver.current.disconnect();

    cardObserver.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setImgInViewport(true);
        } else {
        }
      },
      {
        // rootMargin: '400px',
        threshold: 0.1,
      }
    );

    if (target) cardObserver.current.observe(target);
  }, []);

  useEffect(() => {
    const handleEvent = () => {
      let imgEle = cardImgRef?.current?.querySelector('img');
      if (imgEle?.complete == true && imgInViewport == true) {
        setImgLoaded(true);
      }
    };
    handleEvent();
    window.addEventListener('DOMSubtreeModified', handleEvent);
    return () => window.removeEventListener('DOMSubtreeModified', handleEvent);
  }, [imgInViewport, cardImgRef?.current?.querySelector('img')]);

  const [contentTimePassed, setContentTimePassed] = useState<any>(null);

  useEffect(() => {
    if (item?.communityPost) {
      const calculateTimeAgo = (timeData: any) => {
        const textWrittenTime = timeData as any;
        const currentTime = new Date() as any;
        const timeDifference = currentTime - textWrittenTime;

        const secondsPassed = Math.floor(timeDifference / 1000);
        const minutesPassed = Math.floor(secondsPassed / 60);
        const hoursPassed = Math.floor(minutesPassed / 60);
        const daysPassed = Math.floor(hoursPassed / 24);
        const yearsPassed = Math.floor(daysPassed / 365);

        if (yearsPassed > 0) {
          return `${yearsPassed} 년${yearsPassed > 1 ? '' : ''} 전`;
        } else if (daysPassed > 0) {
          return `${daysPassed} 일${daysPassed > 1 ? '' : ''} 전`;
        } else if (hoursPassed > 0) {
          return `${hoursPassed} 시간${hoursPassed > 1 ? '' : ''} 전`;
        } else if (minutesPassed > 0) {
          return `${minutesPassed} 분${minutesPassed > 1 ? '' : ''} 전`;
        } else {
          return `${secondsPassed} 초${secondsPassed > 1 ? '' : ''} 전`;
        }
      };

      const dateString = item?.communityPost?.createdAt;
      const parts = dateString.split(/[-T:.]/);
      const year = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Months are 0-indexed
      const day = parseInt(parts[2]);
      const hour = parseInt(parts[3]);
      const minute = parseInt(parts[4]);
      const second = parseInt(parts[5]);
      const millisecond = parseInt(parts[6]);

      const localDate = new Date(year, month, day, hour, minute, second, millisecond);

      // setContentTimePassed(calculateTimeAgo(localDate));
      setContentTimePassed(calculateTimeAgo(new Date(item?.communityPost?.createdAt)));
    }
  }, [item?.communityPost]);

  const changeImgObjectFit = (slideImage: any) => {
    try {
      if (slideImage) {
        const imgElement = slideImage as any;
        const width = imgElement?.naturalWidth as any;
        const height = imgElement?.naturalHeight as any;

        if (imgElement && width >= 0 && height > 0) {
          // Calculate the aspect ratio
          const aspectRatio = width / height;

          // Set the object-fit property based on the aspect ratio
          if (aspectRatio > 1) {
            // Landscape image
            imgElement.style['object-fit'] = 'cover'; // Or any other object-fit value you prefer
          } else {
            // Portrait image or square image
            imgElement.style['object-fit'] = 'contain'; // Or any other object-fit value you prefer
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [postBookmarked, setPostBookmarked] = useState(true);
  const [changeBookmarkStatus, setChangeBookmarkStatus] = useState(false);

  const authUserTokenObj = authUserTokenObjState.useValue();

  const handleBookmark = async (typeData: any, postData: any) => {
    try {
      if (authUserTokenObj?.token) {
        setChangeBookmarkStatus(true);
        let actionData = postBookmarked ? 'remove' : 'add';
        let patchCommunityBookmarkRes = await api.patch('/community/bookmark', {
          type: typeData,
          action: actionData,
          data: { communityPostId: postData?.id },
        });
        if ([200, 201].includes(patchCommunityBookmarkRes?.status)) {
          setPostBookmarked(!postBookmarked);
          let successMsg =
            actionData == 'add' ? '관심목록에 저장하였습니다' : '관심목록에서 삭제하였습니다';
          toast.success(successMsg);
        } else if ([401].includes(patchCommunityBookmarkRes?.status)) {
          toast.error('로그인 후 이용 가능합니다');
        } else {
          reFetchBookmarkPagination();
          toast.error('오류가 발생하였습니다');
        }
        setChangeBookmarkStatus(false);
      } else {
        if (window.confirm('로그인 후 이용 가능합니다')) {
        }
      }
    } catch (e: any) {
      if ([e?.status, e?.response?.status].includes(401)) {
        toast.error('로그인 후 이용 가능합니다');
      }
      reFetchBookmarkPagination();
      setChangeBookmarkStatus(false);
    }
  };

  const checkOldBookmarked = (createdAtData: any) => {
    try {
      const calculateTimeAgo = (timeData: any) => {
        const textWrittenTime = timeData as any;
        const currentTime = new Date() as any;
        const timeDifference = currentTime - textWrittenTime;

        const secondsPassed = Math.floor(timeDifference / 1000);
        const minutesPassed = Math.floor(secondsPassed / 60);
        const hoursPassed = Math.floor(minutesPassed / 60);
        const daysPassed = Math.floor(hoursPassed / 24);
        const yearsPassed = Math.floor(daysPassed / 365);

        // if (yearsPassed > 0) {
        //   return `${yearsPassed} 년${yearsPassed > 1 ? '' : ''} 전`;
        // } else if (daysPassed > 0) {
        //   return `${daysPassed} 일${daysPassed > 1 ? '' : ''} 전`;
        // } else if (hoursPassed > 0) {
        //   return `${hoursPassed} 시간${hoursPassed > 1 ? '' : ''} 전`;
        // } else if (minutesPassed > 0) {
        //   return `${minutesPassed} 분${minutesPassed > 1 ? '' : ''} 전`;
        // } else {
        //   return `${secondsPassed} 초${secondsPassed > 1 ? '' : ''} 전`;
        // }

        if (daysPassed >= 30) {
          return true;
        } else return false;
      };

      // const dateString = item?.communityPost?.createdAt;
      // const parts = dateString.split(/[-T:.]/);
      // const year = parseInt(parts[0]);
      // const month = parseInt(parts[1]) - 1;
      // const day = parseInt(parts[2]);
      // const hour = parseInt(parts[3]);
      // const minute = parseInt(parts[4]);
      // const second = parseInt(parts[5]);
      // const millisecond = parseInt(parts[6]);

      // const localDate = new Date(year, month, day, hour, minute, second, millisecond);

      let result = calculateTimeAgo(new Date(createdAtData));

      return result;
    } catch (e) {
      return false;
    }
  };

  return (
    <div
      ref={viewportRef}
      className="relative border border-[#D9D9D9] mb-5 card-type-1"
      style={{ borderRadius: '5px 5px 5px 5px' }}
      /* top-left | top-right | bottom-right | bottom-left */
    >
      {inViewport ? (
        <>
          <div
            ref={cardImgContRef}
            key={item?.communityPost?.id}
            style={{ height: '380px', position: 'relative' }}
            onClick={(e) => {
              try {
                handlePostinfoSwiperSlide(item?.communityPost);
              } catch (e) {}
            }}
          >
            <div className="absolute z-[2]">
              {item?.createdAt && checkOldBookmarked(item?.createdAt) == true && (
                <>
                  <div
                    className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-[2px]"
                    role="alert"
                  >
                    <div className="flex gap-[6px]">
                      <p className="font-bold">알림:</p>
                      <p>30일 이상 전에 저장된 글입니다.</p>
                    </div>
                  </div>
                </>
              )}
            </div>
            {item?.communityPost?.images?.length > 0 ? (
              <Swiper
                pagination={false}
                // autoplay={{
                //   delay: 3000,
                // }}
                loop={false}
                className="mySwiper mb-3 cursor-pointer"
                style={{ borderRadius: '5px 5px 0px 0px' }}
                onAfterInit={(swiper) => {
                  if (swiper?.el?.style) {
                    (swiper.el.style as any)['padding-bottom'] = '0px';
                    (swiper.el.style as any)['height'] = '230px';
                  }
                  setCurrentImageIndex(swiper?.activeIndex ?? 0);
                }}
                onPaginationRender={(swiper, paginationEl) => {
                  if (paginationEl) {
                    paginationEl.style.position = 'absolute';
                    paginationEl.style.bottom = '0';
                    (paginationEl as any).style['padding-bottom'] = '15px';
                  }
                }}
                onSlideChange={(swiper) => {
                  setCurrentImageIndex(swiper?.activeIndex ?? 0);
                }}
              >
                {item?.communityPost?.images &&
                  item?.communityPost?.images.map((imgObj: any, index2: any) => (
                    <div key={imgObj?.url + item?.communityPost?.id + index2}>
                      <SwiperSlide className="h-48 w-auto">
                        <div className="w-full h-full" ref={cardImgRef}>
                          {imgInViewport ? (
                            <>
                              <img
                                ref={(el) => {
                                  if (el) {
                                    changeImgObjectFit(el);
                                  }
                                }}
                                className="w-full h-full object-cover"
                                src={imgObj?.url}
                                style={{}}
                                // src={chooseImage(imgObj) ?? ''}
                              />
                            </>
                          ) : (
                            <div className="w-full h-full bg-[#dedbd9] blur-[4px]"></div>
                          )}
                        </div>
                      </SwiperSlide>
                    </div>
                  ))}
                <div
                  className="absolute bottom-[5px] right-[10px] w-fit z-[1500] px-[8px] py-[1px] text-[white] rounded-[10px]"
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                >
                  {(currentImageIndex ?? 0) + 1} / {item?.communityPost?.images?.length ?? 0}
                </div>
              </Swiper>
            ) : (
              <div className="h-[230px] w-full flex justify-center items-center">
                <IconNoPicture width={'60px'} height={'60px'} />
              </div>
            )}

            <div className="side-padding mb-3">
              <div className="flex items-center justify-between text-[12px] text-[#878787]">
                <div className="flex gap-[7px]">
                  <div className="">
                    {item?.communityPost?.username?.substring(0, 10)
                      ? item?.communityPost?.username?.substring(0, 10) + '...'
                      : null}
                  </div>

                  {/* <div>{contentTimePassed ?? null}</div> */}
                </div>
              </div>
              <div className="flex justify-between max-h-[45px]">
                <div
                  className="text-[15px] break-all whitespace-pre-wrap"
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {item?.communityPost?.content &&
                    htmlReactParser(item?.communityPost?.content ?? '')}
                </div>
              </div>
              <div className="mt-2">
                {
                  <div className="flex flex-wrap">
                    {item?.communityPost?.categories && (
                      <>
                        {(item?.communityPost?.categories?.length <= 5
                          ? item?.communityPost?.categories
                          : item?.communityPost?.categories.slice(0, 5)
                        )?.map((category: any) => (
                          <div
                            className="flex justify-center items-center 
                      border border-[#D0D0D0] rounded p-1 text-[10px] w-fit mr-1 mb-1"
                          >
                            {categoryNameObj[category]}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                }
              </div>
              <div className="absolute bottom-[0px] right-[0px]">
                <div className="flex items-center gap-[10px]">
                  <div className="flex items-center gap-[6px] pt-[14px] pb-[8px] px-[15px]">
                    <IconComment width={'30px'} height={'30px'} />
                    {item?.communityPost?.commentsCount > 0 ? (
                      <div className="text-[#b8b6b6]">{item?.communityPost?.commentsCount}</div>
                    ) : null}
                  </div>
                  {/* <FontAwesomeIcon icon={solidBookmark} /> */}

                  <button
                    disabled={changeBookmarkStatus}
                    className="pt-[14px] pb-[8px] px-[15px]"
                    onClick={(e) => {
                      try {
                        e.stopPropagation();
                        handleBookmark('communityPost', item?.communityPost);
                      } catch (e) {}
                    }}
                  >
                    {changeBookmarkStatus ? (
                      <p>...</p>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={!postBookmarked ? regularBookmark : solidBookmark}
                          size={'2x'}
                          color={!postBookmarked ? '' : '#56d8fc'}
                        />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className="bg-[#dedbd9] blur-[4px]"
          style={{
            height: '380px', //viewportRef?.current?.clientHeight,
            width: '100%',
          }}
        ></div>
      )}
    </div>
  );
};
