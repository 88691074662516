import { Button } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { parse, stringify } from 'qs';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { map } from 'lodash';
import { api } from '../../../../../plugins/axios';
import { CardType1 } from './components/CardType1';
import { authUserTokenObjState } from '../../../../../ridge/ridge';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconAngleDoubleTop } from '../../../../../assets/icons/icon-angle-double-top.svg';

export default function Posts() {
  const [loadDataStatus, setLoadDataStatus] = useState(true);
  const queryClient = useQueryClient();
  const [pageLimit, setPageLimit] = useState(7);
  const [initialStatus, setInitialStatus] = useState(true);

  const fetchBookmarkPagination = async (dataObj: any) => {
    try {
      setLoadDataStatus(true);
      let currentQueryStringObj: { [key: string]: any } = {
        page: dataObj?.pageParam ?? 1,
        limit: pageLimit,
        type: 'communityPost',
      };

      const currentQueryString = stringify(currentQueryStringObj, {
        addQueryPrefix: true,
        encode: false,
        arrayFormat: 'brackets',
      });

      // if (pageParam == 1) {
      //   setInitialLoading(true);
      //   loadingDots(true, 'initial-loading-container');
      // } else {
      //   loadingDots(true, 'more-loading-container');
      // }

      let result = await api.get(`/community/bookmark/pagination${currentQueryString}`);

      if (currentQueryStringObj?.page == 1) {
        setInitialStatus(false);
      }

      // if (pageParam == 1) {
      //   setInitialLoading(false);
      //   loadingDots(false, 'initial-loading-container');
      // } else {
      //   loadingDots(false, 'more-loading-container');
      // }

      setLoadDataStatus(false);

      if ([200, 201].includes(result?.status)) {
        return result;
      } else return null;
    } catch (e) {
      setLoadDataStatus(false);
      return null;
    }
  };

  const {
    data: bookmarkedData,
    fetchNextPage,
    isLoading: isBookmarkedDataLoading,
    refetch: reFetchBookmarkPagination,
  } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`fa7c7f2e-3a62-40e4-aafd-e5aa15a2a842`],
    fetchBookmarkPagination as any,
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        if (lastPage?.data?.items?.length > 0) {
          return nextPage;
        } else if (lastPage?.data?.items?.length == 0) {
          return undefined;
        } else return undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  const observer = useRef<IntersectionObserver>();

  const lastElement = useCallback(
    (target) => {
      if (isBookmarkedDataLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && fetchNextPage) {
            fetchNextPage();
          } else {
          }
        },
        // {
        //   rootMargin: '500px',
        //   threshold: 0.1,
        // }
        {
          threshold: 1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [bookmarkedData, isBookmarkedDataLoading]
  );

  const drawerRef = useRef<HTMLDivElement | null>(null);

  const [drawerType, setDrawerType] = useState(null);

  const openDrawer = (drawerTypeData: any, heightData?: any) => {
    setDrawerType(drawerTypeData);
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `none`;

      (drawerRef.current as any).style['pointer-events'] = 'auto';

      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = heightData ?? '100%';
    }
  };

  const closeDrawer = () => {
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `auto`;

      drawerRef.current.style.width = '0';
      drawerRef.current.style.height = '0';
    }
  };

  const [chosenVKTourDestForDetail, setChosenVKTourDestForDetail] = useState(null);

  const topHeaderRef = useRef<any>(null);
  const detectHeaderRef = useRef<any>(null);

  const handleScroll = () => {
    try {
      if (topHeaderRef?.current && detectHeaderRef?.current) {
        let mainEle = document.querySelector('main');
        let detectHeaderTop = (detectHeaderRef?.current as any)?.getBoundingClientRect().top;

        if (detectHeaderTop <= 0) {
          if (topHeaderRef.current && mainEle) {
            (topHeaderRef.current as any).style.position = 'fixed';
            (topHeaderRef.current as any).style.top = '0px';
            (topHeaderRef.current as any).style.left = mainEle.style.left;
          }
        } else {
          if (topHeaderRef.current && mainEle) {
            (topHeaderRef.current as any).style.position = '';
          }
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    try {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } catch (e) {}
  }, []);

  const authUserTokenObj = authUserTokenObjState.useValue();

  const history = useHistory();

  const handlePostinfoSwiperSlide = (postInfoObj: any) => {
    try {
      history.push(`/community/posts/${postInfoObj.id}`);
    } catch (e) {}
  };

  return (
    <div>
      {/* <div className="max-w-md w-screen bg-white side-padding"> */}
      <div className="w-screen sm:w-full md:w-full lg:w-full side-padding bg-white">
        <>
          {initialStatus ? (
            <div className="flex justify-center">
              <div className="w-[23px] h-[23px] border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
            </div>
          ) : (
            <></>
          )}

          {!(
            (bookmarkedData as any)?.pages[0] && (bookmarkedData as any)?.pages[0]?.data?.count > 0
          ) &&
          !isBookmarkedDataLoading &&
          !loadDataStatus &&
          !initialStatus ? (
            <>
              <p className="grid place-content-center h-44 gap-[5px]">
                저장된 관심 목록이 없습니다
              </p>
            </>
          ) : bookmarkedData?.pages && bookmarkedData?.pages?.length > 0 ? (
            <div className="">
              {map(bookmarkedData?.pages ?? [], (page, pageInd) => {
                return map(page?.data?.items ?? [], (objData, objInd: number) => {
                  return (
                    <>
                      <div
                        key={objData?.id}
                        ref={
                          bookmarkedData?.pages?.length - 1 == pageInd &&
                          page?.data?.items?.length - 1 == objInd
                            ? lastElement
                            : null
                        }
                      >
                        <CardType1
                          pageInd={pageInd}
                          item={objData}
                          handlePostinfoSwiperSlide={handlePostinfoSwiperSlide}
                          reFetchBookmarkPagination={reFetchBookmarkPagination}
                        />
                      </div>
                    </>
                  );
                });
              })}

              {loadDataStatus ? (
                <div className="flex justify-center">
                  <div className="w-[23px] h-[23px] border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : null}
        </>
      </div>
      <div className="absolute bottom-10 right-0 w-[30px] h-[30px] z-[1000]">
        <div className="flex justify-end max-w-md">
          <div
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="fixed bottom-10 w-[60px] h-[60px] mr-5 rounded-[50px] bg-[#06A54A] text-[white] font-[300] text-[1.5em] 
        flex justify-center items-center cursor-pointer"
          >
            <IconAngleDoubleTop fill="#FFFFFF" className="w-[35px] h-[35px]" />
          </div>
        </div>
      </div>
    </div>
  );
}
