import { useEffect, useState, useRef, useCallback } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import { parse, stringify } from 'qs';

import { default as htmlReactParser, attributesToProps } from 'html-react-parser';

import { Section } from '../../../../../components/Section';
import { TopTitleBar } from './components/TopTitleBar';
import { campingDummyData, campingImagesDummyData } from './dummayData';
import { CustomDrawer } from './CustomDrawer';
import { toast } from 'react-toastify';
import { getBooking } from '../../../../../api_v2/non_admin/booking';
import { authUserTokenObjState, modalState } from '../../../../../ridge/ridge';
import {
  cancelReservationForMemberV2,
  getBookingV2,
  getCampingRefundPolicyBeforeReservationV2,
} from '../../../../../api_v2/non_admin/onda';
import dayjs from 'dayjs';
import { Button } from '../../../../../components/Button';

SwiperCore.use([Pagination, Autoplay]);

enum PaymentMethod {
  CARD = 'card',
  VBANK = 'vbank',
}

enum DrawerType {
  REFUND_POLICY = 'REFUND_POLICY',
  CANCEL_RESERVATION = 'CANCEL_RESERVATION',
}

const cancelResErroMsgObj: { [key: string]: any } = {
  'check in date is past': '체크인 날짜가 지난 예약입니다',
  'Already canceled': '이미 취소된 예약입니다',
};

const paymentMethodObj: { [key: string]: any } = {
  card: '신용카드',
  vbank: '무통장 입금',
  point: '포인트결제',
};

const paymentStatusObj: { [key: string]: any } = {
  ready: '가상계좌 발급 완료',
  paid: '결제 성공',
  cancelled: '취소',
  fail: '결제 실패',
};

const bookingStatusObj: { [key: string]: any } = {
  waiting: '결제 대기중',
  ready: '입금 대기중',
  pending: '예약 요청중(입금완료)',
  confirmed: '확정',
  cancelled: '취소',
  canceled: '취소', // data from onda db
  failed: '실패',
};

export const ReservationDetailPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const { campingId, zoneId, reservationId } = useParams<{
    campingId: string;
    zoneId: string;
    reservationId: string;
  }>();
  const [campingSite, setCampingSite] = useState<any>(null);
  const [campingSiteZone, setCampingSiteZone] = useState<any>(null);

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const { imp_uid, merchant_uid, imp_success, error_msg } = parsedSearch;

  const [drawerType, setDrawerType] = useState<any>(null);
  const [cancelReason, setCancelReason] = useState<string>('');

  const openDrawer = (drawerTypeData: string) => {
    setCancelReason('');
    setDrawerType(drawerTypeData);
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '80%';
    }
  };

  const closeDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '0';
      drawerRef.current.style.height = '0';
    }
  };

  useEffect(() => {
    // if (error_msg === '[결제포기] 사용자가 결제를 취소하셨습니다') {
    //   toast.error('[결제포기] 사용자가 결제를 취소하셨습니다');
    //   if (localStorage.getItem('bookingPageUrlForErrorCampingBooking')) {
    //     window.location.href = localStorage.getItem('bookingPageUrlForErrorCampingBooking') as string;
    //   }
    // }
  }, [error_msg]);

  const [chosenPeople, setChosenPeople] = useState(0);
  const authUserTokenObj = authUserTokenObjState.useValue();
  const [booking, setBooking] = useState<{ [key: string]: any } | null>(null);

  useEffect(() => {
    if (authUserTokenObj) {
      getBookingV2(reservationId, '').then((res) => {
        if (res?.success == true && res?.data) {
          setBooking(res?.data ?? null);
        }
      });
    }
  }, [authUserTokenObj]);

  const convertDate = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const calculateTotalCanceledAmt = (dataArr: any) => {
    let finalAmt = 0;
    if (dataArr) {
      dataArr.map((chObj: any) => {
        finalAmt = finalAmt + chObj?.amount ?? 0;
      });
    }
    return finalAmt;
  };

  const [campingRefundPolicyBeforeReservation, setCampingRefundPolicyBeforeReservation] =
    useState<any>(null);

  useEffect(() => {
    if (booking) {
      getCampingRefundPolicyBeforeReservationV2(
        booking?.property_id,
        booking?.rateplans[0]?.roomtype_id,
        booking?.rateplans[0]?.rateplan_id,
        `?checkin=${dayjs(booking?.checkin).format('YYYY-MM-DD')}&checkout=${dayjs(
          booking?.checkout
        ).format('YYYY-MM-DD')}`
      ).then((res) => {
        if (res?.success && res?.data) {
          if (res?.data?.refundPolicyBeforeReservation) {
            setCampingRefundPolicyBeforeReservation(res?.data?.refundPolicyBeforeReservation);
          }
        }
      });
    }
  }, [booking]);

  const modifyCampingRefundPolicyBeforeReservation = () => {
    let refundTypeObj: { [key: string]: any } = {
      nights: '연박 예약 (전체 예약 요금에 대해 체크인 날짜를 기준으로 취소환불정책을 일괄 적용)',
    };

    let refundPolicyObj;
    if (campingRefundPolicyBeforeReservation) {
      refundPolicyObj = campingRefundPolicyBeforeReservation;
    }

    if (refundPolicyObj) {
      let refundPolicy = '';
      if (refundPolicyObj?.refund_policy) {
        refundPolicy =
          refundPolicy +
          `<div style="display: grid; grid-template-columns: auto auto; border-top: 1px solid #E9ECEF; padding-bottom: 3px">
         <div style="padding-top: 8px; padding-bottom: 8px;"></div><div style="font-weight: 600; padding-top: 8px; padding-bottom: 8px; text-align: center;">환불요금</div>
         <div style="grid-column: span 2; border-bottom: 1px solid #E9ECEF; margin-bottom: 3px"></div>
        `;

        refundPolicyObj?.refund_policy.forEach((item: any) => {
          refundPolicy =
            refundPolicy +
            `
          <div style="border-bottom: 1px solid #E9ECEF; padding-top: 8px; padding-bottom: 8px; text-align: center; margin-left: 3px">${
            item?.until ? new Date(item.until)?.toLocaleString() : ''
          }</div>
          <div style="border-bottom: 1px solid #E9ECEF; padding-top: 8px; padding-bottom: 8px; text-align: center;">
            ${item?.refund_amount?.toLocaleString()} (${item?.percent}%)
          </div>
          `;
        });

        refundPolicy = refundPolicy + '</div>';
      }
      return `
    <div style="margin-top: 75px">
      <div style="margin-bottom: 5px">
        <div style="font-weight: 600; font-size: 18px">취환불 적용 타입:</div>
        <div>${refundTypeObj[refundPolicyObj?.refund_type ?? ''] ?? ''}</div>
      </div>
      <div style="margin-bottom: 5px">
        <div style="font-weight: 600; font-size: 18px; margin-bottom: 5px">확정 취환불정책:</div>
        ${refundPolicy}
      </div>
    </div>
    `;
    } else return '';
  };

  const [cancelResStatus, setCancelResStatus] = useState(false);

  const cancelRes = (resData: any) => {
    if (cancelReason?.trim()?.length > 0) {
      const onClose = () => modalState.set({ ...modalState.get(), open: false });
      modalState.set({
        title: '예약을 취소하시겠습니까?',
        subtitle: '(예약 취소후 환불처리는 1~3일 소요될 수 있습니다.)',
        subtitleColor: '#FF3D00',
        open: true,
        onClose,
        onClick: async () => {
          setCancelResStatus(true);
          createCancelResOverlay(true);
          loadingDots(true, 'cancelres-loading-container');
          cancelReservationForMemberV2({
            bookingNo: resData?.booking_number,
            channelBookingNo: resData?.channel_booking_number,
            cancelReason: cancelReason?.trim(),
            cancelTime: new Date(),
          })
            .then((res: any) => {
              setCancelResStatus(false);
              createCancelResOverlay(false);
              loadingDots(false, 'cancelres-loading-container');

              if (res?.success == true && res?.message == 'successfully cancelled') {
                toast.success('예약이 취소되었습니다.');
                cancelResResultModal(
                  '예약 취소가 완료되었습니다',
                  `(예약 취소후 환불처리는 1~3일 소요될 수 있습니다.)`,
                  '#FF3D00'
                );
              } else if (res?.success == false && res?.message) {
                let errorMessage = cancelResErroMsgObj[res?.message] ?? 'error';
                toast.error(errorMessage);
                cancelResResultModal(
                  '예약 취소가 실패했습니다',
                  `${errorMessage} (일정 확인 후 문의사항은 상담 부탁드립니다)`,
                  '#FF3D00'
                );
              } else {
                toast.error('error');
                cancelResResultModal('error', ``, '');
              }

              closeDrawer();

              getBookingV2(reservationId, '').then((res) => {
                if (res?.success == true && res?.data) {
                  setBooking(res?.data ?? null);
                }
              });
            })
            .catch((e: any) => {
              setCancelResStatus(false);
              createCancelResOverlay(false);
              loadingDots(false, 'cancelres-loading-container');

              console.log('e', e);
              toast.error('error');
            });
          onClose();
        },
      });
    } else {
      toast.error('취소사유를 입력해주세요');
    }
  };

  const cancelResResultModal = (title: string, subtitle: string, subtitleColor: string) => {
    let onClose = () => modalState.set({ ...modalState.get(), open: false });
    modalState.set({
      title,
      subtitle,
      subtitleColor,
      open: true,
      onClose,
      onClick: async () => {
        onClose();
      },
    });
  };

  const loadingDots = (statusBool: boolean, className: string) => {
    let loadingContEle = document.querySelector('.' + className);
    if (loadingContEle) {
      if (statusBool) {
        (loadingContEle as any).style.display = 'block';
      } else {
        (loadingContEle as any).style.display = 'none';
      }
    }
  };

  const handleCancelReason = (evt: any) => {
    setCancelReason(evt.target.value);
  };

  const createCancelResOverlay = (status: boolean) => {
    let bodyEle = document.querySelector('body') as any;
    let cancelresOverlayEle = document.querySelector('#cancelres-overlay');
    if (status == true) {
      bodyEle.style.cssText = `pointer-events: none`;

      if (cancelresOverlayEle) {
      } else {
        let newOverlayEle = document.createElement('div');
        newOverlayEle.id = 'cancelres-overlay';
        newOverlayEle.style.cssText = ` 
        position: absolute;
        top:0; left:0;
        width: 100%; height:100%;
        background:rgba(0,0,0,0.6);
        z-index: 1000
        ;
        z
        `;
        bodyEle.append(newOverlayEle);
      }
    } else {
      if (cancelresOverlayEle) {
        bodyEle.style.cssText = `pointer-events: auto`;
        cancelresOverlayEle.remove();
      }
    }
  };

  return (
    <>
      <TopTitleBar logo={true} home={false} />
      {
        <>
          {booking ? (
            <>
              <div className="max-w-md w-screen side-padding bg-[#FFFFFF]">
                <Section className="max-w-md py-3 bg-[#FFFFFF]">
                  <div className="font-[700] text-[15px] mb-3">예약 정보</div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">이름</div>
                    <div className="col-span-4">{booking?.booker?.name}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">연락처</div>
                    <div className="col-span-4">{booking?.booker?.phone}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">예약 상태</div>
                    <div className="col-span-4 font-[700] text-[#FF6400]">
                      {booking?.status ? bookingStatusObj[booking?.status] : null}
                    </div>
                  </div>
                </Section>
                <div className="bg-gray-100 w-full border my-3" />
                <Section className="max-w-md py-3 bg-[#FFFFFF]">
                  <div className="font-[700] text-[15px] mb-3">예약 상품 정보</div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">예약번호</div>
                    <div className="col-span-4">{booking?.channel_booking_number}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">숙소</div>
                    <div className="col-span-4">{booking?.property_name}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">객실</div>
                    <div className="col-span-4">{booking?.rateplans[0]?.roomtype_name}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">패키지</div>
                    <div className="col-span-4">{booking?.rateplans[0]?.rateplan_name}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">일정</div>
                    <div className="col-span-4">
                      {booking?.checkin && booking?.checkout
                        ? dayjs(booking?.checkin).format('YYYY-MM-DD') +
                          ' ~ ' +
                          dayjs(booking?.checkout).format('YYYY-MM-DD')
                        : null}
                    </div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">예약인원</div>
                    <div className="col-span-4">
                      <div>{booking?.rateplans[0]?.number_of_guest?.adult ?? 0}명</div>
                      {/* <div>미성년: {booking?.rateplans[0]?.number_of_guest?.child ?? 0}명</div> */}
                      {/* <div>청소년: {reservationData?.people_info?.minor ?? 0}명</div>
                  <div>아동: {reservationData?.people_info?.child ?? 0}명</div> */}
                    </div>
                  </div>
                  {/* <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">예약차량</div>
                <div className="col-span-4">{reservationData?.car_info?.length ?? 0}대</div>
              </div> */}
                </Section>
                <div className="bg-gray-100 w-full border my-3" />
                <Section className="max-w-md py-3 bg-[#FFFFFF]">
                  <div className="font-[700] text-[15px] mb-3">결제 정보</div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">총 결제금액</div>
                    <div className="col-span-4 text-[#FF6400] font-[700]">
                      {booking?.paymentInfo?.amount?.toLocaleString() ?? 0}원
                    </div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">결제수단</div>
                    <div className="col-span-4">
                      {booking?.paymentInfo?.payMethod
                        ? paymentMethodObj[booking?.paymentInfo?.payMethod]
                        : null}
                    </div>
                  </div>
                  {PaymentMethod?.VBANK === booking?.paymentInfo?.payMethod ? (
                    <>
                      <div className="grid grid-cols-5">
                        <div className="text-[#5C5C5C] text-[13px] place-self-start">은행명</div>
                        <div className="col-span-4">{booking?.paymentInfo?.vbankName}</div>
                      </div>
                      <div className="grid grid-cols-5">
                        <div className="text-[#5C5C5C] text-[13px] place-self-start">
                          입금계좌번호
                        </div>
                        <div className="col-span-4">{booking?.paymentInfo?.vbankNum}</div>
                      </div>
                      <div className="grid grid-cols-5">
                        <div className="text-[#5C5C5C] text-[13px] place-self-start">
                          입금 마감일
                        </div>
                        <div className="col-span-4">
                          {booking?.paymentInfo?.vbankDate
                            ? new Date(booking?.paymentInfo?.vbankDate * 1000)?.toLocaleString()
                            : null}
                        </div>
                      </div>
                    </>
                  ) : PaymentMethod?.CARD === booking?.paymentInfo?.payMethod ? (
                    <></>
                  ) : null}
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">결제 상태</div>
                    <div className="col-span-4 font-[700] text-[#FF6400]">
                      {booking?.paymentInfo?.status === 'cancelled'
                        ? booking?.paymentInfo?.cancelHistory &&
                          (calculateTotalCanceledAmt(booking?.paymentInfo?.cancelHistory) ?? 0) > 0
                          ? '환불'
                          : paymentStatusObj[booking?.paymentInfo?.status]
                        : booking?.paymentInfo?.status
                        ? paymentStatusObj[booking?.paymentInfo?.status]
                        : null}
                    </div>
                  </div>
                </Section>
                {booking?.paymentInfo?.cancelHistory &&
                (calculateTotalCanceledAmt(booking?.paymentInfo?.cancelHistory) ?? 0) > 0 ? (
                  <>
                    <div className="bg-gray-100 w-full border my-3" />
                    <Section className="max-w-md py-3 bg-[#FFFFFF]">
                      <div className="font-[700] text-[15px] mb-3">환불 정보</div>
                      <div className="grid grid-cols-5">
                        <div className="text-[#5C5C5C] text-[13px] place-self-start">
                          총 환불된 금액
                        </div>
                        <div className="col-span-4 text-[#FF6400] font-[700]">
                          {booking?.paymentInfo?.cancelHistory &&
                            (
                              calculateTotalCanceledAmt(booking?.paymentInfo?.cancelHistory) ?? 0
                            ).toLocaleString()}
                          원
                        </div>
                      </div>
                    </Section>
                  </>
                ) : null}

                <div className="bg-gray-100 w-full border my-3" />
                <Section className="mb-4 max-w-md">
                  <div className={`bg-white z-20 w-full max-w-md flex space-x-2 px-4 h-16`}>
                    <button
                      onClick={() => openDrawer(DrawerType.REFUND_POLICY)}
                      className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                    border border-[#D0D0D0]
                    flex-1 flex justify-center items-center"
                    >
                      예약 환불 규정
                    </button>
                    {/* {window.location.origin.includes('localhost') ||
                    window.location.origin.includes('stage') ||
                    window.location.origin.includes('ngrok') ? (
                      <>
                        <button
                          className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                  border border-[#D0D0D0]
                  flex-1 flex justify-center items-center"
                          onClick={() => {
                            openDrawer(DrawerType.CANCEL_RESERVATION);
                          }}
                        >
                          예약 취소
                        </button>
                      </>
                    ) : (
                      <></>
                    )} */}
                    <button
                      className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                    border border-[#D0D0D0]
                    flex-1 flex justify-center items-center"
                      onClick={() => {
                        openDrawer(DrawerType.CANCEL_RESERVATION);
                      }}
                    >
                      예약 취소
                    </button>
                  </div>
                </Section>
              </div>
            </>
          ) : null}
        </>
      }

      <div>
        <CustomDrawer drawerRef={drawerRef} confirmButton={false}>
          <div className="mt-[75px] side-padding">
            {drawerType == DrawerType.REFUND_POLICY ? (
              <>
                {campingRefundPolicyBeforeReservation &&
                  htmlReactParser(modifyCampingRefundPolicyBeforeReservation() ?? '')}
              </>
            ) : drawerType == DrawerType.CANCEL_RESERVATION ? (
              <div className="flex flex-col">
                <div className="flex justify-center">
                  <div className="cancelres-loading-container text-[50px] hidden">
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                  </div>
                </div>
                <label htmlFor="cancelReason" className="mb-3">
                  취소 사유:
                </label>
                <textarea
                  id="cancelReason"
                  name="cancelReason"
                  rows={6}
                  placeholder="취소사유를 입력해주세요"
                  value={cancelReason}
                  onChange={(evt) => {
                    handleCancelReason(evt);
                  }}
                ></textarea>
                <Button
                  className="my-2 py-3 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                    flex-1 flex justify-center items-center"
                  onClick={() => cancelRes(booking)}
                >
                  예약 취소
                </Button>
              </div>
            ) : null}
          </div>
        </CustomDrawer>
      </div>
    </>
  );
};
