import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSwipeable } from 'react-swipeable';
import { ReactComponent as IconNoPicture } from '../../../../../assets/icons/icon-no-picture.svg';

function Modal({ showModal, setShowModal, toggleModal, images }: any) {
  const [currentIndexForImg, setCurrentIndexForImg] = useState(0);

  const nextImage = () => {
    setCurrentIndexForImg((prevIndex) => (prevIndex === images?.length - 1 ? 0 : prevIndex + 1));
  };

  const prevImage = () => {
    setCurrentIndexForImg((prevIndex) => (prevIndex === 0 ? images?.length - 1 : prevIndex - 1));
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {},
  });

  const [loadedImages, setLoadedImages] = useState<any>([]);

  const handleImageLoad = (url: any) => {
    if (loadedImages?.includes(url)) {
    } else {
      setLoadedImages((prev: any) => {
        return [...prev, url];
      });
    }
  };

  const changeImgObjectFit = (slideImage: any) => {
    try {
      if (slideImage) {
        const imgElement = slideImage as any;
        const width = imgElement?.naturalWidth as any;
        const height = imgElement?.naturalHeight as any;

        if (imgElement && width >= 0 && height > 0) {
          const aspectRatio = width / height;
          if (aspectRatio > 1) {
            imgElement.style['object-fit'] = 'cover';
          } else {
            imgElement.style['object-fit'] = 'contain';
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  return (
    <div className="flex items-center justify-center">
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black opacity-50" onClick={toggleModal}></div>

          <div className="min-w-[60vw] bg-white p-4 rounded shadow-lg z-50">
            <button
              className="bg-red-500 text-white px-4 py-2 rounded mt-4 float-right mb-4"
              onClick={toggleModal}
            >
              Close Modal
            </button>
            <p className="text-gray-600">
              <div className="max-h-[300px] w-full flex justify-center items-center mb-[15px] relative">
                {images?.length > 0 ? (
                  <>
                    <div {...handlers} className="h-[300px] w-full">
                      <>
                        <img
                          src={images[currentIndexForImg]?.url}
                          className="object-contain h-[300px] w-full"
                          ref={(el) => {
                            if (el) {
                              changeImgObjectFit(el);
                            }
                          }}
                          onLoad={() => handleImageLoad(images[currentIndexForImg]?.url)}
                        />

                        <button
                          onClick={prevImage}
                          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                          className="absolute top-[50%] left-[0px] p-[20px] text-[white] text-[20px] font-[900]"
                        >
                          &larr;
                        </button>
                        <button
                          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                          onClick={nextImage}
                          className="absolute top-[50%] right-[0px] p-[20px] text-[white] text-[20px] font-[900]"
                        >
                          &rarr;
                        </button>
                        {loadedImages.includes(images[currentIndexForImg]?.url) ? (
                          <></>
                        ) : (
                          <>
                            <div className="absolute top-[0px] animate-pulse w-full h-[300px] bg-gradient-to-br from-blue-200 via-purple-300 to-red-200"></div>
                          </>
                        )}
                      </>
                    </div>
                    <div
                      className="absolute bottom-[5px] right-[10px] w-fit z-[1500] px-[8px] py-[1px] text-[white] rounded-[10px]"
                      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                    >
                      {(currentIndexForImg ?? 0) + 1} / {images?.length ?? 0}
                    </div>
                  </>
                ) : (
                  <div className="h-[300px] w-full flex justify-center items-center">
                    <IconNoPicture width={'60px'} height={'60px'} />
                  </div>
                )}
              </div>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

const CardType1 = ({ item, addObject }: any) => {
  const [selectedImages, setSelectedImages] = useState<any>([]);

  const handleImageToggle = (imageObj: any) => {
    const isSelected = selectedImages.find((sImgObj: any) => sImgObj?.url == imageObj?.url);
    if (isSelected) {
      setSelectedImages(
        selectedImages.filter((selectedImage: any) => selectedImage?.url !== imageObj?.url)
      );
    } else {
      setSelectedImages((prev: any) => {
        return [...prev, imageObj];
      });
    }
  };

  const [loadingState, setLoadingState] = useState(
    item?.images?.map(() => ({
      isLoading: true,
      loadError: false,
    }))
  );

  const handleLoad = (index: any) => {
    setLoadingState((prevLoadingState: any) => {
      const newState = [...prevLoadingState];
      newState[index].isLoading = false;
      return newState;
    });
  };

  const handleError = (index: any) => {
    setLoadingState((prevLoadingState: any) => {
      const newState = [...prevLoadingState];
      newState[index].isLoading = false;
      newState[index].loadError = true;
      return newState;
    });
  };

  // Use state to control the visibility of the modal
  const [showModal, setShowModal] = useState(false);

  // Define a function to toggle the modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <p>ID: {item?.id}</p>
          <p>Title: {item?.title}</p>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            addObject({ ...item, selectedImages });
            toast.success('Added!');
          }}
        >
          add to list
        </div>
      </div>
      <div className="flex justify-between items-center">
        <h2>Choose Images</h2>
        <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={toggleModal}>
          큰 이미지 보기
        </button>
      </div>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        toggleModal={toggleModal}
        images={item?.images}
        handleImageToggle={handleImageToggle}
        selectedImages={selectedImages}
      />

      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
        {item?.images.map((imageObj: any, index: any) => (
          <>
            {loadingState[index].isLoading && (
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background:
                    'linear-gradient(45deg, #eee 25%, transparent 25%, transparent 50%, #eee 50%, #eee 75%, transparent 75%, transparent)',
                  backgroundSize: '20px 20px',
                  animation: 'spin 1s linear infinite',
                }}
              />
            )}

            <img
              key={imageObj?.url}
              src={imageObj?.url}
              alt={`Image ${index + 1}`}
              onLoad={() => handleLoad(index)}
              onError={() => handleError(index)}
              style={{
                width: '100px',
                height: '100px',
                margin: '5px',
                cursor: 'pointer',
                objectFit: 'cover',
                border: selectedImages.find((sImgObj: any) => sImgObj?.url == imageObj?.url)
                  ? '4px solid blue'
                  : 'none',
                display: loadingState[index].isLoading ? 'none' : 'block',
              }}
              onClick={() => handleImageToggle(imageObj)}
            />

            {loadingState[index].loadError && <p>Error loading image {index + 1}</p>}
          </>
        ))}
      </div>
      {/* <button onClick={() => onImagesSelect(selectedImages)}>Select Images</button> */}
    </div>
  );
};

export default CardType1;
