import React, { useState } from 'react';
import { Button } from '../../../../../../../../components/Button';
import { Calendar } from './Calendar';
import { toast } from 'react-toastify';
import { api } from '../../../../../../../../plugins/axios';

const CreateData = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [currFile, setCurrFile] = useState<any>(null);

  const [locale, setLocale] = useState<any>('ko');

  const [calendarState, setCalendarState] = useState<any>([
    {
      startDate: null,
      endDate: new Date(''),
      key: 'selection',
    },
  ]);

  const handleCsvFile = (event: any) => {
    try {
      const file = event.target.files[0];

      setCurrFile(file);

      const reader = new FileReader();

      reader.onload = () => {
        const csvData = reader.result as any;
        let modifiedRows = [] as any;
        csvData.split('\n').forEach((row: any) => {
          const regex = /\/community\/posts\/\d+/;
          if (row?.startsWith('# ')) {
          } else if (regex.test(row) || row?.startsWith('페이지 경로 및 화면 클래스')) {
            modifiedRows.push(row.split(','));
          }
        });

        setTableData(modifiedRows);
      };

      reader.readAsText(file);
      event.target.value = null;
    } catch (e) {}
  };

  const [modifyDBStatus, setModifyDBStatus] = useState(false);

  const modifyDB = async () => {
    try {
      setModifyDBStatus(true);

      if (
        !(
          calendarState &&
          calendarState[0] &&
          calendarState[0]?.startDate &&
          calendarState[0]?.endDate
        )
      ) {
        toast.warn('업로드한 데이터의 기간을 선택해주세요');
      } else if (!(tableData?.length > 0)) {
        toast.warn('데이터를 업로드해주세요');
      } else {
        const regex = /^\/community\/posts\/\d+$/;

        let modifiedTableData = [] as any;
        for (let i = 0; i < tableData?.length; i++) {
          let eachData = tableData[i];
          if (eachData && eachData[0]) {
            if (regex.test(eachData[0])) {
              let chosenColumns = [
                'pagePath',
                'views',
                'users',
                'viewsPerUser',
                'averageEngagementTime',
                'numberOfEvents',
              ];
              let convertToObj = {} as any;
              for (let j = 0; j < chosenColumns?.length; j++) {
                convertToObj[chosenColumns[j]] = eachData[j];
              }
              modifiedTableData.push(convertToObj);
            } else {
            }
          }
        }

        if (
          calendarState[0]?.startDate &&
          calendarState[0]?.endDate &&
          modifiedTableData?.length > 0
        ) {
          const convertDate = (givenDate: any) => {
            try {
              const year = givenDate.getFullYear();
              const month = String(givenDate.getMonth() + 1).padStart(2, '0');
              const day = String(givenDate.getDate()).padStart(2, '0');
              const formattedDate = `${year}-${month}-${day}`;
              return formattedDate;
            } catch (e) {
              return null;
            }
          };

          const convertedSD = convertDate(calendarState[0]?.startDate);
          const convertedED = convertDate(calendarState[0]?.endDate);
          if (convertedSD && convertedED) {
            let createDataRes = await api.patch(
              '/admin/ai/community/ga/data-process/community-post/create-data',
              {
                startDate: convertedSD,
                endDate: convertedED,
                tableData: modifiedTableData,
              }
            );
            if ([200, 201]?.includes(createDataRes?.status)) {
              toast.success('Successfully modified data');
            } else toast.error('Something went wrong creating/updating data');
          }
        }
      }
      setModifyDBStatus(false);
    } catch (e) {
      setModifyDBStatus(false);
    }
  };

  return (
    <div>
      <div className="max-w-lg mx-auto mt-8 p-6 bg-white rounded-lg shadow-lg">
        <div className="mb-[45px]">
          <label htmlFor="fileInput" className="block text-center">
            <span className="text-lg font-semibold text-gray-800">Click to upload CSV file</span>
            <input
              type="file"
              id="fileInput"
              accept=".csv"
              className="hidden"
              onChange={(e) => handleCsvFile(e)}
            />
          </label>
          <p className="text-center mt-[10px]">{currFile?.name && currFile?.name}</p>
        </div>
      </div>
      <div className="my-[15px]">
        <p className="font-[700] text-center">데이터 기간</p>
        <Calendar
          locale={locale}
          state={calendarState}
          setState={setCalendarState}
          // setChosenDates={setChosenDates}
          // drawerRef={drawerRef}
          // confirmButton={chosenTab === TabType?.CALENDAR ? true : false}
          // handleDate={true}
          // handleDateFunc={handleDateFunc}
        />
      </div>

      <div className="flex justify-end my-[10px]">
        <Button
          disabled={modifyDBStatus}
          className={`border ${modifyDBStatus ? 'bg-gray-300 cursor-not-allowed' : ''}`}
          onClick={() => {
            modifyDB();
          }}
        >
          {!modifyDBStatus ? (
            'Create/update Data'
          ) : (
            <div className="flex justify-center items-center">
              <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
            </div>
          )}
        </Button>
      </div>
      {tableData.length > 0 && (
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-200 border-b border-gray-300">
                {tableData[0]?.map((header: any, index: any) => (
                  <th
                    key={index}
                    className="py-3 px-4 font-semibold text-left text-gray-700 uppercase border-r border-gray-300"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.slice(1).map((row: any, rowIndex: any) => (
                <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-gray-100' : ''}>
                  {row.map((cell: any, cellIndex: any) => (
                    <td
                      key={cellIndex}
                      className="py-3 px-4 border-b border-gray-300 whitespace-nowrap"
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CreateData;
