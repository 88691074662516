import { addDays, isAfter } from 'date-fns';
import { chain, get, map, sumBy, trim } from 'lodash';
import { CartItem } from './api/cart/types';
import {
  ClayfulFulfillment,
  FullfillmentStatus,
} from './hooks/arrived-fullfillment/type';
import { OrderRefund } from './hooks/order/type';
import { ProductType } from './hooks/product/type';

export function range(length: number, start = 0) {
  return Array.from({ length }, (_, i) => i + start);
}

export const getRefundStatus = (orderRefunds: OrderRefund[]) => {
  return chain(orderRefunds)
    .map((orderRefund) => {
      const { status, items } = orderRefund;
      return map(items, (refund) => {
        return {
          id: refund.item._id,
          status,
        };
      });
    })
    .flatten()
    .groupBy('id')
    .value();
};

export const getFulfillmentStatus = (fulfillments: ClayfulFulfillment[]) => {
  return chain(fulfillments)
    .map((fulfillment) => {
      const {
        status,
        items: fulfillmentItems,
        updatedAt: { raw },
      } = fulfillment;
      const isAfter7DaysArrived = isAfter(
        new Date(),
        addDays(new Date(raw), 7)
      );
      return map(fulfillmentItems, (fulfillmentItem) => {
        return {
          id: fulfillmentItem.item._id,
          status:
            status === FullfillmentStatus.ARRIVED && isAfter7DaysArrived
              ? FullfillmentStatus.ARRIVED_AFTER_7DAY
              : status,
        };
      });
    })
    .flatten()
    .groupBy('id')
    .value();
};

export const getVariantText = (types: ProductType[]) => {
  return map(types, (type) => {
    const {
      option: { name },
      variation: { value },
    } = type;
    return `${name}: ${value}`;
  }).join(' / ');
};

export function getHyphonePhoneNumber(mobile: string) {
  return trim(mobile).replace(
    /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
    '$1-$2-$3'
  );
}

export function regexPhoneNumber(mobile: string | null) {
  if (!mobile) {
    return false;
  }
  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  return regPhone.test(mobile);
}

export const getCouponDiscountPrice = (cartItems: CartItem[]) => {
  return sumBy(cartItems, (item) => {
    const { discounts } = item;
    return sumBy(discounts, (discount) => {
      const coupon = get(discount, 'coupon');
      if (coupon) {
        return discount.discounted.raw;
      }
      return 0;
    });
  });
};

export const getOriginalDiscountPrice = (cartItems: CartItem[]) => {
  return sumBy(cartItems, (item) => {
    const { discounts } = item;
    return sumBy(discounts, (discount) => {
      const coupon = get(discount, 'coupon');
      if (coupon) {
        return 0;
      }
      return discount.discounted.raw;
    });
  });
};
