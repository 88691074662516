import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { Button } from '../../../../../components/Button';
import { toast } from 'react-toastify';
import {
  createTDCAccommodations,
  getTDCAccommodation,
  updateTDCAccommodation,
} from '../../../../../api_v2/admin/community/tdc-accommodations';
import { useParams } from 'react-router-dom';
import { TextField } from '../../../../../components/TextField';

const TDCAccommodationDetail = () => {
  const { accommId } = useParams<{ accommId: string }>();

  const [formState, setFormState] = useState<any>({
    adId: null,
    siteId: null,
    name: null,
    link: null,
    mainImageUrl: null,
    maxDiscountRate: null,
    city: null,
    addr: null,
    shortAddr: null,
  });

  useEffect(() => {
    getTDCAccommodation(accommId).then((res: any) => {
      try {
        setFormState(res?.data ?? null);
      } catch (e) {}
    });
  }, []);

  const _onChangeText = (e: any) => {
    const { name, value } = e.target;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: name === 'maxDiscountRate' ? parseFloat(value) : value,
    }));
  };

  const [updateStatus, setUpdateStatus] = useState<boolean>(false);

  const _handleUpdateButton = async () => {
    setUpdateStatus(true);

    let updateResult = await updateTDCAccommodation(parseInt(accommId), {
      ...formState,
    });
    if (updateResult) {
      toast.success('성공적으로 수정하였습니다');
      setUpdateStatus(false);
    } else {
      toast.error('Something went wrong updating');
    }
  };

  return (
    <div>
      {['id'].map((item) => (
        <div className="flex space-x-3">
          <p className="font-[700]">{item}:</p>
          <p>{accommId}</p>
        </div>
      ))}

      {[
        'adId',
        'siteId',
        'name',
        'link',
        'mainImageUrl',
        'maxDiscountRate',
        'city',
        'addr',
        'shortAddr',
      ].map((item) => (
        <div key={item}>
          <p className="font-[700]">{item + ':'}</p>
          <TextField name={item} value={formState[item]} onChange={_onChangeText} />
        </div>
      ))}
      <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
        <Button
          text="수정하기"
          className="h-10 text-sm -black"
          onClick={() => _handleUpdateButton()}
          disabled={updateStatus}
        />
      </div>
    </div>
  );
};

export default TDCAccommodationDetail;
