import React, { useState } from 'react';
import { Button } from '../../../../../components/Button';
import { Card } from '../../../../../components/Card';
import { Select } from '../../../../../components/Select';
import { TextField } from '../../../../../components/TextField';
import { AdminH1 } from '../../../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../../../assets/svg/file-upload.svg';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { BannerText, BannerType } from './repeatCode';
import { map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { CreateBannerParams } from '../../../../../api_v2/admin/banners/types';
import { createBanner } from '../../../../../api_v2/admin/banners';
import { uploadImage } from '../../../../../api_v2/admin/photo';
import { Toggle } from '../../../../../components/Toggle';
import FroalaEditor from './components/FroalaEditor';
import { createCommunityBanner } from '../../../../../api_v2/admin/community';

export const BannerAdd = () => {
  const history = useHistory();
  const [formState, setFormState] = useState<any>({
    type: null,
    isVisible: false,
    data: '',
  });

  const [createBannerStatus, setCreateBannerStatus] = useState<boolean>(false);

  const _onChangeType = (e: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      type: e.target.value,
    }));
  };

  const _handleSubmitButton = async () => {
    // if (!displayPhoto) {
    //   toast.error('사진을 업로드해주세요');
    // } else

    // if (!formState.type) {
    //   toast.error('노출 위치를 선택해주세요');
    // } else {
    setCreateBannerStatus(true);

    // if (uploadImageResult?.success) {
    let createBannerResult = await createCommunityBanner({
      ...formState,
    });
    if (createBannerResult) {
      toast.success('성공적으로 배너를 등록하였습니다');
      setCreateBannerStatus(false);
    } else {
      toast.error('Something went wrong creating a banner');
    }
    // } else {
    //   toast.error('Something went wrong uploading an image');
    // }
    // }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>배너 추가</AdminH1>
      </div>
      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-start-1 col-span-2">
            <div className="flex justify-between">
              <div className="flex space-x-3">
                <p>진열</p>
                <Toggle
                  checked={formState.isVisible}
                  onChange={(e) =>
                    setFormState((prevState: any) => ({
                      ...prevState,
                      isVisible: e,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          {/* <Select label="노출 위치 선택" onChange={_onChangeType}>
            <option disabled selected hidden>
              노출 위치를 선택해주세요
            </option>
            {map(Object.values(BannerType), (banner) => (
              <option key={banner} value={banner}>
                {BannerText[banner]}
              </option>
            ))}
          </Select> */}
          <div className="col-start-1 col-span-2">
            <FroalaEditor
              defaultValue={''}
              onChange={(dataModel) => {
                setFormState((prevState: any) => ({
                  ...prevState,
                  data: dataModel,
                }));
              }}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="취소"
            className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
            to="/admin/community/banners"
          />
          <Button
            onClick={() => _handleSubmitButton()}
            text="추가하기"
            className="h-10 text-sm filled-black"
            disabled={createBannerStatus}
          />
        </div>
      </Card>
    </>
  );
};
