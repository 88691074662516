import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { AdminH1 } from '../../components/AdminH1';
import { Button } from '../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { useExhibitions } from '../../../hooks/exhibition';
import { useQueryString } from '../../../hooks_v2/hooks';
import { map } from 'lodash';
import { ExhibitionText } from '../../../hooks/exhibition/type';
import { parse, stringify } from 'qs';
import { api } from '../../../plugins/axios';
import { getProducts } from '../../../api_v2/admin/products';
import { toast } from 'react-toastify';
import { TextField } from '../../../components/TextField';
import { CreateNotificationParams, OSType } from '../../../api_v2/admin/notification/types';
import { sendNotificationForAll } from '../../../api_v2/admin/notification';
import { Toggle } from '../../../components/Toggle';

export const NotificationForAll = () => {
  const [formState, setFormState] = useState<CreateNotificationParams>({
    title: '',
    body: '',
    redirect: false,
    redirectPath: '',
  });

  const [notificationStatus, setNotificationStatus] = useState(false);

  const _onChangeText = (e: any) => {
    let { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const _handleSubmitButton = async (osTypes: string[]) => {
    const { redirectPath, ...rest } = formState;
    let formStateCopy: { [key: string]: any } = { ...rest };
    if (formState.redirect) {
      formStateCopy.redirectPath = redirectPath;
    }

    let redTextFlag = false;
    for (let i = 0; i < Object.keys(formStateCopy).length; i++) {
      let key = Object.keys(formStateCopy)[i];
      if (formStateCopy[key as keyof CreateNotificationParams] != null) {
        let inputValue = formStateCopy[key as keyof CreateNotificationParams]!.toString().trim();

        if (inputValue.length === 0) {
          redTextFlag = true;
          break;
        }
      }
    }
    if (redTextFlag) {
      toast.error('모든항목을 작성해주세요');
    } else {
      setNotificationStatus(true);
      let sendNotificationForAllResult = await sendNotificationForAll(
        {
          ...formStateCopy,
        },
        osTypes
      );
      if (sendNotificationForAllResult?.successStatus) {
        toast.success(
          `성공적으로 알림을 보냈습니다 - 성공: ${sendNotificationForAllResult?.success}, 실패: ${sendNotificationForAllResult?.failure}`
        );
        setNotificationStatus(false);
      } else {
        toast.error(
          sendNotificationForAllResult?.reason ?? 'Something went wrong sending a notification'
        );
        setNotificationStatus(false);
      }
    }
  };

  return (
    <>
      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-start-1 col-span-1">
            <div className="font-bold text-lg mb-4">전체 알림</div>
            <TextField label="제목" name="title" value={formState.title} onChange={_onChangeText} />
            <TextField label="내용" name="body" value={formState.body} onChange={_onChangeText} />
            <div className="flex justify-between mt-4 mb-4">
              <div className="flex items-center space-x-3">
                <p className="text-sm">페이지이동</p>
                <Toggle
                  checked={formState.redirect}
                  onChange={(e) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      redirect: e,
                    }))
                  }
                />
              </div>
            </div>
            {formState.redirect ? (
              <>
                <TextField
                  label="페이지경로"
                  name="redirectPath"
                  value={formState.redirectPath}
                  onChange={_onChangeText}
                />
                <p>예) </p>
                <p>/products/productId</p>
                <p>/events/id</p>
              </>
            ) : null}
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {/* <Button
            onClick={() => _handleSubmitButton(OSType.IOS)}
            text="iOS기기로 보내기"
            className="h-10 text-sm filled-black"
            disabled={notificationStatus}
          /> */}
          <Button
            onClick={() => _handleSubmitButton([OSType.IOS, OSType.ANDROID])}
            text="보내기"
            className="h-10 text-sm filled-black"
            disabled={notificationStatus}
          />
        </div>
      </Card>
    </>
  );
};
