export enum BannerType {
  MAIN_TOP = 'mainTop',
  MAIN_BOTTOM = 'mainBottom',
  PC_LEFT = 'pcLeft',
  PITCHING_SPECIAL = 'pitchingSpecial',
  MAIN_TOP_CAMPING = 'mainTopCamping',
  MAIN_1_CAMPING = 'main1Camping',
  MAIN_2_CAMPING = 'main2Camping',
  MAIN_3_CAMPING = 'main3Camping',
  MAIN_4_CAMPING = 'main4Camping',
  DETAIL_PROPERTY = 'detailProperty',
}

export const BannerText = {
  [BannerType.MAIN_TOP]: '스토어홈 메인',
  [BannerType.MAIN_BOTTOM]: '스토어홈 하단',
  [BannerType.PC_LEFT]: 'PC버전 좌측',
  [BannerType.PITCHING_SPECIAL]: '피칭 특가',
  [BannerType.MAIN_TOP_CAMPING]: '캠핑 메인 상단',
  [BannerType.MAIN_1_CAMPING]: '캠핑 메인 1',
  [BannerType.MAIN_2_CAMPING]: '캠핑 메인 2',
  [BannerType.MAIN_3_CAMPING]: '캠핑 메인 3',
  [BannerType.MAIN_4_CAMPING]: '캠핑 메인 4',
  [BannerType.DETAIL_PROPERTY]: '숙소 상세',
};

export interface Banner {
  id: number;
  type: BannerType;
  title: string;
  subTitle: string;
  thumbnail: string;
  url: string;
  weight: number;
  createdAt: string;
}
