import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useInViewport } from 'react-in-viewport';

import './style.css';

interface CardType1 {
  item?: any;
  lastElement?: any;
  handleCampingInfoSwiperSlide?: any;
  lowestPricesObj?: any;
  pageInd?: any;
}

export const CardType1: FC<CardType1> = ({
  item,
  lastElement,
  handleCampingInfoSwiperSlide,
  lowestPricesObj,
  pageInd,
}) => {
  const cardObserver = useRef<IntersectionObserver>();
  const cardImgRef = useRef<any>(null);
  const [imgInViewport, setImgInViewport] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  const viewportRef = useRef<any>(null);
  const { inViewport, enterCount, leaveCount } = useInViewport(
    viewportRef as any,
    { threshold: 0.1 },
    { disconnectOnLeave: false }
  );

  const [emptyBoxHeight, setEmptyBoxHeight] = useState('360px');

  const [lowestPricesForGivenPage, setLowestPricesForGivenPage] = useState<any>(null);

  const cardImgContRef = useCallback((target) => {
    if (cardObserver.current) cardObserver.current.disconnect();

    cardObserver.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setImgInViewport(true);
        } else {
        }
      },
      {
        // rootMargin: '400px',
        threshold: 0.1,
      }
    );
    if (target) cardObserver.current.observe(target);
  }, []);

  useEffect(() => {
    const handleEvent = () => {
      let imgEle = cardImgRef?.current?.querySelector('img');
      if (imgEle?.complete == true && imgInViewport == true) {
        setImgLoaded(true);
      }
    };
    handleEvent();
    window.addEventListener('DOMSubtreeModified', handleEvent);
    return () => window.removeEventListener('DOMSubtreeModified', handleEvent);
  }, [imgInViewport, cardImgRef?.current?.querySelector('img')]);

  const getTags = (item: any) => {
    try {
      let tempArr: any[] = [];

      for (let i = 0; i < Object.keys(item).length; i++) {
        let key = Object.keys(item)[i];
        tempArr = [...tempArr, ...item[key]];
      }

      return tempArr;
    } catch (e) {
      console.log('e', e);
      return [];
    }
  };

  const getClassifications = (classArr: any) => {
    let finalStr = '';
    for (let i = 0; i < classArr.length; i++) {
      let classStr = classArr[i] == '캠핑' ? '캠핑장' : classArr[i];
      if (i == 0) {
        finalStr = classStr;
      } else {
        finalStr = finalStr + ' | ' + classStr;
      }
    }
    return finalStr;
  };

  useEffect(() => {
    if (lowestPricesObj && lowestPricesObj[pageInd] && !lowestPricesForGivenPage) {
      Promise.all([lowestPricesObj[pageInd]]).then((data: any) => {
        // lowestPricesObjForGivenPageRef.current = data[0]?.data ?? null;

        setLowestPricesForGivenPage(data[0]?.data ?? null);
      });
    }
  }, [lowestPricesObj]);

  const chooseImage = (imgObj: any) => {
    const tempImgObj = {
      _250: '250px',
      _500: '500px',
      _1000: '1000px',
      _original: 'original',
    };
    let final = imgObj[tempImgObj?._500]
      ? imgObj[tempImgObj?._500]
      : imgObj[tempImgObj?._1000]
      ? imgObj[tempImgObj?._1000]
      : imgObj[tempImgObj?._250]
      ? imgObj[tempImgObj?._250]
      : imgObj[tempImgObj?._original];

    return final;
  };

  return (
    <div
      ref={viewportRef}
      className="border border-[#D9D9D9] mb-5 card-type-1"
      style={{ borderRadius: '5px 5px 5px 5px' }}
      /* top-left | top-right | bottom-right | bottom-left */
    >
      {inViewport ? (
        <>
          <div ref={cardImgContRef} key={item?.id} style={{ height: '380px' }}>
            <Swiper
              onClick={() => handleCampingInfoSwiperSlide(item)}
              pagination={{
                dynamicBullets: true,
              }}
              // autoplay={{
              //   delay: 3000,
              // }}
              loop={false}
              className="mySwiper mb-3 cursor-pointer"
              style={{ borderRadius: '5px 5px 0px 0px' }}
              onAfterInit={(swiper) => {
                if (swiper?.el?.style) {
                  (swiper.el.style as any)['padding-bottom'] = '0px';
                  (swiper.el.style as any)['height'] = '230px';
                }
              }}
              onPaginationRender={(swiper, paginationEl) => {
                if (paginationEl) {
                  paginationEl.style.position = 'absolute';
                  paginationEl.style.bottom = '0';
                  (paginationEl as any).style['padding-bottom'] = '15px';
                }
              }}
            >
              {item?.local_images &&
                item.local_images.map((imgObj: any, index2: any) => (
                  <div key={(chooseImage(imgObj) ?? '') + item?.id + index2}>
                    <SwiperSlide className="h-48 w-auto object-cover">
                      <div className="w-full h-full" ref={cardImgRef}>
                        {imgInViewport ? (
                          <>
                            <LazyLoadImage
                              className="w-full h-full"
                              src={chooseImage(imgObj) ?? ''}
                            />
                          </>
                        ) : (
                          <div className="w-full h-full bg-[#dedbd9] blur-[4px]"></div>
                        )}
                      </div>
                    </SwiperSlide>
                  </div>
                ))}
            </Swiper>

            <div className="side-padding mb-3">
              <div className="flex justify-between">
                <div className="text-[#6F6F6F] text-[12px]">
                  {/* {dataObj?.address && parseStr(dataObj.address)?.region} */}
                  <div>{item?.classifications && getClassifications(item?.classifications)}</div>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-[700] text-[15px]">
                  {item?.name
                    ? item?.name.length <= 11
                      ? item?.name
                      : item?.name?.substring(0, 11) + '...'
                    : null}
                </div>
                <div className="font-[700] text-[15px] text-[#FF6400]">
                  {/* {findLowestPrice(item?.id) && findLowestPrice(item?.id) >= 0 ? (
                    findLowestPrice(item?.id)?.toLocaleString() + '원~/박'
                  ) : (
                    <></>
                  )} */}
                  {lowestPricesForGivenPage?.data?.find((obj: any) => obj?.propertyId == item?.id)
                    ?.minPricePerDay > 0 ? (
                    lowestPricesForGivenPage?.data
                      ?.find((obj: any) => obj?.propertyId == item?.id)
                      ?.minPricePerDay?.toLocaleString() + '원~/박'
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="text-[#6F6F6F] text-[12px]">{item?.address?.address1}</div>
              <div className="mt-2">
                {
                  <div className="flex flex-wrap">
                    {item?.tags && Array.isArray(getTags(item.tags)) && (
                      <>
                        {(item?.tags?.length <= 10
                          ? getTags(item?.tags)
                          : getTags(item?.tags).slice(0, 10)
                        )?.map((tag: any) => (
                          <div
                            className="flex justify-center items-center 
                      border border-[#D0D0D0] rounded p-1 text-[10px] w-fit mr-1 mb-1"
                          >
                            {tag}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                }
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className="bg-[#dedbd9] blur-[4px]"
          style={{
            height: '380px', //viewportRef?.current?.clientHeight,
            width: '100%',
          }}
        ></div>
      )}
    </div>
  );
};
