import { AxiosError, AxiosResponse } from 'axios';
import { map, slice, sortBy } from 'lodash';
import { stringify } from 'qs';
import { useEffect, useRef } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Icon } from '../components/Icon';
import { Section } from '../components/Section';
import { TopNavBar } from '../components/TopNavBar';
import { Exhibition, ExhibitionType } from '../hooks/exhibition/type';
import { EXHIBITION_PATH } from '../paths/exhibition';
import { api } from '../plugins/axios';
import { Paginated } from '../types';
import { range } from '../utils';
import { PAGE_LIMIT } from './products/constants';

export const EventsPage = () => {
  const lastRef = useRef<HTMLDivElement>(null);
  const { push } = useHistory();
  const fetchExhibitions = ({ pageParam = 1 }) => {
    const queryString = stringify(
      {
        page: pageParam,
        limit: PAGE_LIMIT,
        sort: {
          weight: 'DESC',
        },
        filter: {
          type: ExhibitionType.NORMAL,
          isVisible: true,
        },
      },
      { addQueryPrefix: true, encode: false }
    );
    return api.get(`${EXHIBITION_PATH.ROOT}${queryString}`);
  };

  const { data: exhibitionData, fetchNextPage } = useInfiniteQuery<
    AxiosResponse<Paginated<Exhibition>>,
    AxiosError
  >([`${EXHIBITION_PATH.ROOT}`], fetchExhibitions, {
    getNextPageParam: (lastPage, allPages) => {
      const maxPages = Math.ceil(lastPage.data.total / PAGE_LIMIT);
      const nextPages = allPages.length + 1;
      return nextPages <= maxPages ? nextPages : undefined;
    },
    keepPreviousData: true,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.isIntersecting && fetchNextPage();
        });
      },
      { threshold: 1.0 }
    );
    if (lastRef.current) {
      observer.observe(lastRef.current);
    }
  }, [exhibitionData]);

  if (!exhibitionData) {
    return null;
  }

  return (
    <>
      <TopNavBar />
      <div className="pb-20 mt-3">
        {map(exhibitionData.pages, (page) =>
          map(page.data.items, (item) => (
            <div
              className="cursor-pointer mb-4"
              onClick={() => push('/events/' + item.id)}
            >
              <Section className="mx-4 grid grid-cols-4 space-x-2">
                <div
                  className={`relative col bg-gray-500 w-full h-full aspect-1 rounded overflow-hidden ${
                    item.exhibitionProductMaps.length === 0
                      ? 'col-span-4 row-span-4'
                      : 'col-span-3 row-span-3'
                  }`}
                >
                  <img src={item.thumbnail} alt="" className="relative img" />
                </div>

                <div
                  className={`flex flex-col space-y-2 h-full  ${
                    item.exhibitionProductMaps.length === 0 ? 'hidden' : ''
                  } `}
                >
                  {map(
                    sortBy(slice(item.exhibitionProductMaps, 0, 3), 'order'),
                    (item, i) => (
                      <div className="relative w-full h-full rounded overflow-hidden">
                        {range.length - 1 === i - 2 && (
                          <div className="absolute z-10 w-full h-full bg-black/40 grid place-content-center">
                            <Icon.Plus className="text-gray-100 fill-current wh-8" />
                          </div>
                        )}
                        <img
                          src={item.product.thumbnail}
                          alt=""
                          className="relative img"
                        />
                      </div>
                    )
                  )}
                </div>
              </Section>
              <Section className="grid place-content-center text-center space-y-1 py-4">
                <h2>{item.title}</h2>
                <p className="text-12">{item.subTitle}</p>
              </Section>
            </div>
          ))
        )}
      </div>
      <div ref={lastRef} />
    </>
  );
};
