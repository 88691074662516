import { api } from '../../../plugins/axios';
import { CreateProductParams, UpdateProductParams } from './types';

export const getCampingInfoList = async (queryString: string) => {
  try {
    let result = await api.get('/admin/campings' + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCampingInfo = async (id: any, queryString?: string) => {
  try {
    let result = await api.get(`/admin/campings/${id}` + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const createCampingInfo = async (createCampingInfoParams: any) => {
  try {
    let result = await api.post('/admin/campings', createCampingInfoParams);

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const updateCampingInfo = async (id: number, updateCampingInfoParams: any) => {
  try {
    let result = await api.patch(`/admin/campings/${id}`, updateCampingInfoParams);

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteCampingInfo = async (id: number) => {
  let result = await api.delete(`/admin/campings/${id}`);
  let possibleStatus = [200, 201];
  if (possibleStatus.includes(result.status)) {
    return true;
  } else return false;
};

export const getCampingZones = async (campingId: any, queryString: string) => {
  try {
    let result = await api.get(`/admin/campings/${campingId}/camping-zones` + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const createCampingZone = async (campingId: any, createCampingZoneParams: any) => {
  try {
    let result = await api.post(
      `/admin/campings/${campingId}/camping-zones`,
      createCampingZoneParams
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const getCampingZone = async (campingId: any, zoneId: any) => {
  try {
    let result = await api.get(`/admin/campings/${campingId}/camping-zones/${zoneId}`);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const updateCampingZone = async (
  campingId: number,
  zoneId: number,
  updateCampingZoneParams: any
) => {
  try {
    let result = await api.patch(
      `/admin/campings/${campingId}/camping-zones/${zoneId}`,
      updateCampingZoneParams
    );

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const createCampingZoneSite = async (
  campingId: any,
  campingZoneId: any,
  createCampingZoneSiteParams: any
) => {
  try {
    let result = await api.post(
      `/admin/campings/${campingId}/camping-zones/${campingZoneId}/sites`,
      createCampingZoneSiteParams
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

// admin/campings/{campingId}/camping-zones/{campingZoneId}/sites/{id}

export const updateCampingZoneSite = async (
  campingId: any,
  campingZoneId: any,
  campingZoneSiteId: any,
  createCampingZoneSiteParams: any
) => {
  try {
    let result = await api.patch(
      `/admin/campings/${campingId}/camping-zones/${campingZoneId}/sites/${campingZoneSiteId}`,
      createCampingZoneSiteParams
    );

    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const getCampingZoneSite = async (
  campingId: any,
  campingZoneId: any,
  campingZoneSiteId: any
) => {
  try {
    let result = await api.get(
      `/admin/campings/${campingId}/camping-zones/${campingZoneId}/sites/${campingZoneSiteId}`
    );
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCampingZoneSites = async (
  campingId: any,
  campingZoneId: any,
  queryString: string
) => {
  try {
    let result = await api.get(
      `/admin/campings/${campingId}/camping-zones/${campingZoneId}/sites` + queryString
    );
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCampingZoneSiteInventories = async (campingZoneSiteId: any, dateData: any) => {
  try {
    let result = await api.get(
      `/admin/camping-inventories/${campingZoneSiteId}` + `?yyyymm=${dateData}`
    );
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const createCampingZoneSiteInventories = async (
  campingZoneSiteId: any,
  createCampingZoneSiteInventoriesParams: any
) => {
  try {
    let result = await api.post(
      `/admin/camping-inventories/${campingZoneSiteId}`,
      createCampingZoneSiteInventoriesParams
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const updateCampingZoneSiteInventories = async (
  campingZoneSiteId: any,
  updateCampingZoneSiteInventoriesParams: any
) => {
  try {
    let result = await api.patch(
      `/admin/camping-inventories/${campingZoneSiteId}`,
      updateCampingZoneSiteInventoriesParams
    );

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};
