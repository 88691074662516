import { useEffect, useState, useRef, useCallback } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import {
  Select as AntdSelect,
  Button as AntdButton,
  Checkbox,
  Form,
  Input,
  Radio,
  Col,
  Row,
  Button,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import 'antd/dist/antd.css';

import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { parse, stringify } from 'qs';
import _ from 'lodash';

import { Section } from '../../../../../components/Section';
import { TopTitleBar } from './components/TopTitleBar';
import { TermButton } from './TermButton';

import {
  getCampingInfo,
  getCampingZone,
  getCampingZoneSiteInventories,
} from '../../../../../api_v2/non_admin/camping';
import {
  checkCampingAvailBeforeReservationV2,
  createBookingV2,
  getCampingAvailRoomTypesV2,
  getCampingAvailRoomTypeV2,
  getCampingInfoV2,
  getCampingRefundPolicyBeforeReservationV2,
  updateBookingV2,
} from '../../../../../api_v2/non_admin/onda';
import { createBooking } from '../../../../../api_v2/non_admin/booking';
import { authUserTokenObjState, meState } from '../../../../../ridge/ridge';
import { api } from '../../../../../plugins/axios';
import dayjs from 'dayjs';
// testing
SwiperCore.use([Pagination, Autoplay]);

const { Option: AntdOption } = AntdSelect;

// testing
enum PG {
  KCP = 'kcp',
  TOSSPAY = 'tosspay',
}

enum PaymentMethod {
  CARD = 'card',
  VBANK = 'vbank',
}

const paymentMethodObj: { [key: string]: any } = {
  card: '신용카드',
  // vbank: '무통장 입금',
};

enum VehicleInfoType {
  RENTED = 'rented',
  TRAILER = 'trailer',
  VEHICLE_REG_PLATE = 'vehicleRegPlate',
}

enum AgeGroup {
  ADULTS = 'ADULTS',
  UNDER_AGE_TYPE1 = 'UNDER_AGE_TYPE1',
  UNDER_AGE_TYPE2 = 'UNDER_AGE_TYPE2',
}

const AgeGroupObj = {
  ADULTS: '성인',
  UNDER_AGE_TYPE1: '청소년 (미성년)',
  UNDER_AGE_TYPE2: '미취학 아동 (미성년)',
};

const ratePlanAdditionalFeeObj: { [key: string]: any } = {
  tax_and_service_fee: '세금 및 서비스 요금',
  resort_fee: '리조트 수수료',
  mandatory_fee: '체크인/체크아웃 시 발생하는 수수료',
  mandatory_tax: '체크인/체크아웃 시 발생하는 세금',
  sale_tax: '기타 이용 세금',
};

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 10,
  },
};

enum ConditionsType {
  // OPERATION_POLICY = '캠핑장 운영정책 동의',
  CANCEL_REFUND_POLICY = '취소/환불정책 동의',
  PERSONAL_INFO_POLICY = '개인정보 수집 및 제 3자 제공 동의',
  OVER_14_POLICY = '만 14세 이상 이용동의',
}

const checkboxOptions = [
  // ConditionsType.OPERATION_POLICY,
  ConditionsType.CANCEL_REFUND_POLICY,
  ConditionsType.PERSONAL_INFO_POLICY,
  // '만 14세 이상 이용 동의',
  ConditionsType.OVER_14_POLICY,
];

const checkboxOptionsObj: { [key: string]: any } = {
  [ConditionsType.PERSONAL_INFO_POLICY]: `
  <div style="margin-top: 75px">
    <div style="margin-bottom: 15px">     
      <p style="margin-bottom: 7px">
      회사는 회원의 개인정보를 제3조(개인정보수집∙이용 목적 및 수집항목 등)에
      고지한 범위 내에서 사용하며,
      동 범위를 초과하여 이용하거나 타인 또는
      타기업, 기관에 제공하지 않습니다.
      </p>
      <p>다만, 아래와 같이 양질의 서비스 제공을 위해 상품 예약∙구매 단계에서
      개인정보를 제공 받는 자,
      제공목적, 제공 항목, 이용 및 보유기간을 회원에게
      고지하여 동의를 구한 후 제3자에게 제공합니다.</p>
    </div>
    <div style="margin-bottom: 15px;">
      <div style="display: grid; grid-template-columns: 3fr 7fr; border: 1px solid; border-radius: 5px; padding: 5px;">
        <div style="color: #5C5C5C">제공받는자</div>
        <div>숙박서비스 제공업체</div>
        <div style="color: #5C5C5C">목적</div>
        <div>숙소 예약 또는 취소, 환불처리 및 고객상담, 맞춤형 
        컨텐츠 및 서비스제공</div>
        <div style="color: #5C5C5C">항목</div>
        <div>예약자정보 (이름, 휴대전화번호, 이메일, 차량번호)
        환불시 계좌은행, 계좌번호, 예금주명</div>
        <div style="color: #5C5C5C">보유기간</div>
        <div>서비스 제공 완료후 5년까지</div>
      </div>
    </div>
    <div style="margin-bottom: 15px">
      <div>동의를 거부할 수 있으며, 거부할 경우 서비스 이용에 제한됩니다.</div>
    </div>
  </div>
  `,
  [ConditionsType.OVER_14_POLICY]: `
  <div style="margin-top: 75px">
    <div style="margin-bottom: 5px">
      <div style="font-weight: 600; font-size: 18px">데이아웃은 만 14세 미만 아동의 서비스 이용을 제한하고 있습니다.</div><br />
      <div>정보통신망 이용촉진 및 정보보호 등에 관한 법률에는 만 14세 미만 아동의 개인정보 수집 시 법정대리인 동의를 받도록 규정하고 있으며, 만 14세 미만 아동이 법정대리인 동의없이 서비스 이용이 확인된 경우 서비스 이용이 제한될 수 있음을 알려드립니다.</div>
    </div>
  </div>
  `,
};

const nationalities: { [key: string]: any } = {
  KR: '한국',
};

//@ts-ignore
const { IMP } = window;
IMP.init(process.env.REACT_APP_IAMPORT_CODE);

export const BookingPage = () => {
  const me = meState.useValue();
  const authUserTokenObj = authUserTokenObjState.useValue();

  const drawerCalendarRef = useRef<HTMLDivElement | null>(null);
  const [form] = Form.useForm();

  const history = useHistory();
  const { search } = useLocation();

  const { campingId, roomTypeId, ratePlanId, zoneId, siteId } = useParams<{
    campingId: string;
    roomTypeId: string;
    ratePlanId: string;
    zoneId: string;
    siteId: string;
  }>();
  const [campingInfo, setCampingInfo] = useState<any>(null);
  const [campingZone, setCampingZone] = useState<any>(null);

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const [calendarState, setCalendarState] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [locale, setLocale] = useState<any>('ko');

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [vehicleInfoList, setVehicleInfoList] = useState([]);
  const [chosenPeople, setChosenPeople] = useState<{ [key: string]: any }>({});

  const [chosenPaymentMethod, setChosenPaymentMethod] = useState(null);

  const [initialInventoryData, setInitialInventoryData] = useState<any[]>([]);

  const [payStatus, setPayStatus] = useState(false);

  const onChangeCheckboxGroup = (list: any) => {
    setCheckedList(list);
    // setIndeterminate(!!list.length && list.length < checkboxOptions.length);
    setCheckAll(list.length === checkboxOptions.length);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? (checkboxOptions as any) : []);
    // setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const openDrawerCalendar = () => {
    if (drawerCalendarRef.current) {
      drawerCalendarRef.current.style.width = '100%';
      drawerCalendarRef.current.style.height = '80%';
    }
  };

  const impRequestPay = (dataObj: any) => {
    const { name, email, phone, nationality, request, chosenPaymentMethod, totalPrice, password } =
      dataObj;

    let inventoriesQS: { [key: string]: any } = {};
    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      inventoriesQS.checkin = convertDateForInventoriesQS(parsedSearch?.start_date);
      inventoriesQS.checkout = convertDateForInventoriesQS(parsedSearch?.end_date);
    } else {
    }

    checkCampingAvailBeforeReservationV2(
      campingId,
      roomTypeId,
      ratePlanId,
      stringify(inventoriesQS, { addQueryPrefix: true, encode: false })
    )
      .then(async (res) => {
        if (res?.success && res?.data) {
          if (res?.data?.checkAvailBeforeReservation?.availability == true) {
            // console.log('available');
            // To check member or non-member

            let initialDataToSave: { [key: string]: any } = {
              // password,
              // name,
              // email,
              // phone,
              propertyId: campingId,
              roomtypeId: roomTypeId,
              rateplanId: ratePlanId,
              checkinDate: convertDateForInventoriesQS(parsedSearch?.start_date),
              checkoutDate: convertDateForInventoriesQS(parsedSearch?.end_date),
              amount: totalPrice,
              // peopleInfo: {},
              // carInfo: [],
            };

            if (me && authUserTokenObj) {
            } else {
              initialDataToSave.password = password;
            }

            let createdBooking = await createBookingV2(initialDataToSave);
            if (
              createdBooking?.success &&
              createdBooking?.data?.merchantUid &&
              createdBooking?.data?.token
            ) {
              let modifiedChosenPeople: { [key: string]: any } = {};
              let translatedChosenPeople: { [key: string]: any } = {};
              for (let i = 0; i < Object.keys(chosenPeople).length; i++) {
                let key = Object.keys(chosenPeople)[i];
                if (key === AgeGroup?.ADULTS) {
                  modifiedChosenPeople.adult = chosenPeople[key];
                  translatedChosenPeople['성인'] = chosenPeople[key];
                } else if (key === AgeGroup?.UNDER_AGE_TYPE1) {
                  modifiedChosenPeople.minor = chosenPeople[key];
                  translatedChosenPeople['청소년(미성년)'] = chosenPeople[key];
                } else if (key === AgeGroup?.UNDER_AGE_TYPE2) {
                  modifiedChosenPeople.child = chosenPeople[key];
                  translatedChosenPeople['미취학아동(미성년)'] = chosenPeople[key];
                }
              }
              // initialDataToSave.peopleInfo = modifiedChosenPeople;

              let modifiedVehicleInfoList: any[] = [];
              let translatedVehicleInfoList: any[] = [];
              for (let i = 0; i < vehicleInfoList.length; i++) {
                let tempVehicleInfoObj: { [key: string]: any } = { ...(vehicleInfoList as any)[i] };
                modifiedVehicleInfoList.push({
                  isRent: tempVehicleInfoObj?.rented,
                  isTrailer: tempVehicleInfoObj?.trailer,
                  carNumber: tempVehicleInfoObj?.vehicleRegPlate ?? '',
                });

                translatedVehicleInfoList.push({
                  렌트차량: tempVehicleInfoObj?.rented,
                  트레일러: tempVehicleInfoObj?.trailer,
                  차량번호: tempVehicleInfoObj?.vehicleRegPlate ?? '',
                });
              }
              // initialDataToSave.carInfo = modifiedVehicleInfoList;

              let translatedUnderAgeInfoList: any[] = [];
              for (let i = 0; i < underAgeInfoList.length; i++) {
                let tempUnderAgeInfoObj: { [key: string]: any } = {
                  ...(underAgeInfoList as any)[i],
                };

                translatedUnderAgeInfoList.push({
                  아이디: tempUnderAgeInfoObj?.id,
                  나이: tempUnderAgeInfoObj?.age,
                });
              }

              localStorage.setItem('tokenCampingBooking', createdBooking?.data?.token);
              localStorage.setItem('bookingPageUrlForErrorCampingBooking', window.location.href);

              let numOfAdults = parseInt(chosenPeople.ADULTS) ?? 0;
              let numOfChildren =
                parseInt(chosenPeople.UNDER_AGE_TYPE1) + parseInt(chosenPeople.UNDER_AGE_TYPE2) ??
                0;
              let underAgeArr = underAgeInfoList.map((underAgeInfo: any) => {
                return underAgeInfo.age;
              });

              let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Asia/Seoul';

              let reservationInfoCampingBooking: { [key: string]: any } = {
                currency: 'KRW',
                channel_booking_number: createdBooking?.data?.merchantUid,
                checkin: convertDateForInventoriesQS(parsedSearch?.start_date),
                checkout: convertDateForInventoriesQS(parsedSearch?.end_date),
                booker: {
                  name,
                  email,
                  phone: convertNumbOnlyPhone(phone),
                  nationality,
                  timezone,
                },
                rateplans: [
                  {
                    rateplan_id: ratePlanId,
                    amount: totalPrice,
                    // number_of_guest: { adult: numOfAdults, child_age: underAgeArr },
                    number_of_guest: {
                      adult: numOfAdults + numOfChildren,
                      child_age: [],
                    },
                    guests: [
                      {
                        name,
                        email,
                        phone: convertNumbOnlyPhone(phone),
                        nationality,
                      },
                    ],
                  },
                ],
              };
              localStorage.setItem(
                'reservationInfoCampingBooking',
                JSON.stringify(reservationInfoCampingBooking)
              );

              let updatedBooking = await updateBookingV2(createdBooking?.data?.merchantUid, {
                ondaData: reservationInfoCampingBooking,
              });

              if (updatedBooking?.success == true && updatedBooking?.data?.affected) {
                // Test: REACT_APP_IAMPORT_PG_OLD_TOSSPAYMENTS_WO_CONTRACT
                // Live: REACT_APP_IAMPORT_PG_OLD_TOSSPAYMENTS_W_CONTRACT
                let pgData = process.env.REACT_APP_IAMPORT_PG_OLD_TOSSPAYMENTS_W_CONTRACT;
                // if (
                //   window.location.origin.includes('localhost') ||
                //   window.location.origin.includes('ngrok')
                // ) {
                //   pgData = process.env.REACT_APP_IAMPORT_PG_OLD_TOSSPAYMENTS_WO_CONTRACT;
                // }

                IMP.request_pay(
                  {
                    company: '데이아웃',
                    pg: `uplus.${pgData}`,
                    pay_method: chosenPaymentMethod,
                    merchant_uid: createdBooking?.data?.merchantUid,
                    name: campingInfo?.name ?? '',
                    amount: totalPrice,
                    currency: 'KRW',
                    buyer_name: name,
                    buyer_tel: phone,
                    buyer_email: email,
                    custom_data: {
                      dev: {
                        propertyId: campingId,
                        roomtypeId: roomTypeId,
                        rateplanId: ratePlanId,
                        checkinDate: convertDateForInventoriesQS(parsedSearch?.start_date),
                        checkoutDate: convertDateForInventoriesQS(parsedSearch?.end_date),
                        propertyName: campingInfo?.name,
                        roomtypeName: campingRoomType?.name,
                        rateplanName: campingRoomTypeRatePlan?.rateplan_name,
                        peopleInfo: modifiedChosenPeople,
                        underAgeInfo: underAgeInfoList,
                        carInfo: modifiedVehicleInfoList,
                        name: name,
                        email: email,
                        phone: phone,
                        userId: me?.userInfo?.user_id ?? null,
                        // ondaData: JSON.stringify(reservationInfoCampingBooking),
                      },
                      translated: {
                        숙소아이디: campingId,
                        객실타입아이디: roomTypeId,
                        요금제아이디: ratePlanId,
                        입실일: convertDateForInventoriesQS(parsedSearch?.start_date),
                        퇴실일: convertDateForInventoriesQS(parsedSearch?.end_date),
                        숙소이름: campingInfo?.name,
                        객실타입이름: campingRoomType?.name,
                        요금제이름: campingRoomTypeRatePlan?.rateplan_name,
                        인원정보: translatedChosenPeople,
                        미성년자연령정보: translatedUnderAgeInfoList,
                        예약차량: translatedVehicleInfoList,
                        이름: name,
                        이메일: email,
                        전화번호: phone,
                        유저아이디: me?.userInfo?.user_id ?? null,
                      },
                    },

                    m_redirect_url: `${window.location.origin}/v2/camping/campinginfo/${campingId}/roomtypes/${roomTypeId}/rateplans/${ratePlanId}/booking/result`,
                  },
                  (rsp: any) => {
                    setPayStatus(false);
                    if (rsp.success) {
                      if (rsp?.pay_method == PaymentMethod.VBANK && rsp?.status === 'ready') {
                      } else {
                      }
                      window.location.href = `/v2/camping/campinginfo/${campingId}/roomtypes/${roomTypeId}/rateplans/${ratePlanId}/booking/result?imp_uid=${rsp?.imp_uid}&merchant_uid=${rsp?.merchant_uid}&imp_success=${rsp?.success}`;
                    } else {
                      console.log('error_msg: ' + rsp?.error_msg);
                      toast.error(rsp?.error_msg ?? 'error');
                      window.location.href = `/v2/camping/campinginfo/${campingId}/roomtypes/${roomTypeId}/rateplans/${ratePlanId}/booking/result?imp_uid=${rsp?.imp_uid}&merchant_uid=${rsp?.merchant_uid}&imp_success=${rsp?.success}&error_msg=${rsp?.error_msg}`;
                      if (rsp?.error_msg === '[결제포기] 사용자가 결제를 취소하셨습니다') {
                      } else {
                      }
                    }
                  }
                );
              } else {
                setPayStatus(false);
              }
            } else {
              setPayStatus(false);
              toast.error('결제 에러가 발생하였습니다.');
            }
          } else {
            setPayStatus(false);
            toast.error('예약 가능한 날짜를 확인해주세요');
          }
        } else {
          setPayStatus(false);
          let finalErrorMessage = '예약 가능한 날짜를 확인해주세요';
          if (res?.success == false && res?.message == 'roomtype not found') {
            finalErrorMessage = '선택하신 객실이 존재하지 않습니다';
          }
          toast.error(finalErrorMessage);
        }
      })
      .catch((e) => {
        setPayStatus(false);
        console.log('e', e);
        toast.error('예약 가능한 날짜를 확인해주세요');
      });
  };

  const checkboxOptionsRequiredObjGenerator = () => {
    let finalObj: { [key: string]: any } = {};
    checkboxOptions.map((checkboxOption, index) => {
      finalObj[checkboxOption] = true;
    });
    return finalObj;
  };

  const checkPhoneNumb = (_: any, value: any) => {
    let flag = true;

    if (value?.trim().length == 11) {
      for (let i = 0; i < value.length; i++) {
        if (isNaN(parseInt(value[i]))) {
          flag = false;
          break;
        }
      }
    } else {
      flag = false;
    }

    if (flag) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('전화번호를 입력해주세요. 예) 01012345678'));
  };

  const convertNumbOnlyPhone = (phoneStr: any) => {
    let finalStr = '';
    for (let i = 0; i < phoneStr.length; i++) {
      if (i == 3 || i == 7) {
        finalStr = finalStr + '-' + phoneStr[i];
      } else {
        finalStr = finalStr + phoneStr[i];
      }
    }
    return finalStr;
  };

  const onFinish = (values: any) => {
    if (!checkAll) {
      toast.error('약관에 동의해주세요');
    } else if (!chosenPaymentMethod) {
      toast.error('결제수단을 선택해주세요');
    } else if (typeof calculateTotalPrice() !== 'number') {
      console.log('e', 'calculateTotalPrice');
    } else {
      // To check member or non-member
      let checkRequiredObj: { [key: string]: any } = {
        name: '이름',
        email: '이메일',
        phone: '전화번호',
        nationality: '국적',
        // password: '비밀번호',
      };
      if (me && authUserTokenObj) {
      } else {
        checkRequiredObj.password = '비밀번호';
      }
      let checkRequiredProblem = false;
      for (let i = 0; i < Object.keys(checkRequiredObj).length; i++) {
        let key = Object.keys(checkRequiredObj)[i];

        if (values[key]?.trim()?.length > 0) {
          continue;
        } else {
          checkRequiredProblem = true;
          toast.error(checkRequiredObj[key] + '을/를 입력해주세요');
          break;
        }
      }
      if (!checkRequiredProblem) {
        // console.log('passed');

        const { terms, ...rest } = values;
        setPayStatus(true);
        impRequestPay({ ...rest, chosenPaymentMethod, totalPrice: calculateTotalPrice() });
      }
    }
  };

  const calculateNumOfPeople = (objData: any) => {
    let adult = 0;
    let children = 0;
    adult = objData[AgeGroup.ADULTS] ?? 0;
    children = (objData[AgeGroup.UNDER_AGE_TYPE1] ?? 0) + (objData[AgeGroup.UNDER_AGE_TYPE2] ?? 0);
    return { adult, children };
  };

  const handlePaymentMethod = (keyData: any) => {
    setChosenPaymentMethod(keyData);
  };

  const convertDateForInventoriesQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const calculateTotalPrice = () => {
    // let campingPrice = calculatePrice('camping');
    // let campingZonePrice = calculatePrice('campingZone');
    // let campingZoneSitePrice = calculatePrice('campingZoneSite');

    // return campingPrice + campingZonePrice + campingZoneSitePrice;
    let totalSalePrice = campingRoomTypeRatePlan?.total?.sale_price;
    let additionalFees = 0;
    Object.keys(campingRoomTypeRatePlan?.fees)?.forEach((feeKey: any) => {
      additionalFees = additionalFees + campingRoomTypeRatePlan?.fees[feeKey];
    });
    return totalSalePrice + additionalFees;
  };

  const [underAgeInfoList, setUnderAgeInfoList] = useState<any>([]);

  useEffect(() => {
    const localStorageVehicleInfoList = localStorage.getItem('vehicleInfoListCampingBooking');
    if (localStorageVehicleInfoList) {
      let parsedVehicleInfoList = JSON.parse(localStorageVehicleInfoList);
      if (parsedVehicleInfoList && parsedVehicleInfoList.length > 0) {
        setVehicleInfoList(parsedVehicleInfoList);
      }
    }
    const localStorageChosenPeople = localStorage.getItem('chosenPeopleCampingBooking');
    if (localStorageChosenPeople) {
      let parsedChosenPeople = JSON.parse(localStorageChosenPeople);
      if (parsedChosenPeople && Object.keys(parsedChosenPeople).length > 0) {
        setChosenPeople(parsedChosenPeople);
      }
    }

    const localStorageUnderAgeInfoList = localStorage.getItem('underAgeInfoListCampingBooking');
    if (localStorageUnderAgeInfoList) {
      let parsedUnderAgeInfoList = JSON.parse(localStorageUnderAgeInfoList);
      if (parsedUnderAgeInfoList && Object.keys(parsedUnderAgeInfoList).length > 0) {
        setUnderAgeInfoList(parsedUnderAgeInfoList);
      }
    }
  }, []);

  const [campingRoomType, setCampingRoomType] = useState<{ [key: string]: any } | null>(null);
  const [campingRoomTypeRatePlan, setCampingRoomTypeRatePlan] = useState<{
    [key: string]: any;
  } | null>(null);

  const [getRoomtypeRateplanStatus, setGetRoomtypeRateplanStatus] = useState(false);

  // apis to monitor
  useEffect(() => {
    getCampingInfoV2(campingId, '').then((res) => {
      if (res?.success && res?.data) {
        if (res?.data?.detail) {
          setCampingInfo(res.data.detail);
        }
      }
    });
    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      let queryStrObj: { [key: string]: any } = {};
      queryStrObj.checkin = convertDateForInventoriesQS(parsedSearch?.start_date);
      queryStrObj.checkout = convertDateForInventoriesQS(parsedSearch?.end_date);

      setGetRoomtypeRateplanStatus(true);

      getCampingAvailRoomTypeV2(
        campingId,
        roomTypeId,
        stringify(queryStrObj, { addQueryPrefix: true, encode: false })
      )
        .then((res) => {
          if (res?.success && res?.data) {
            if (res?.data?.availRoomTypes) {
              let foundRoomType = res.data.availRoomTypes.find(
                (item: any) => item?.roomtype_id == roomTypeId
              );

              if (foundRoomType) {
                setCampingRoomType({
                  id: foundRoomType?.roomtype_id,
                  name: foundRoomType?.roomtype_name,
                });
              }

              if (foundRoomType?.rateplans) {
                let foundRatePlan = foundRoomType.rateplans.find(
                  (item: any) => item?.rateplan_id == ratePlanId
                );
                if (foundRatePlan) {
                  setCampingRoomTypeRatePlan(foundRatePlan);
                }

                // setCampingRoomTypeRatePlans(foundRoomType?.rateplans);
              }
            }
          } else {
            // setCampingRoomTypeRatePlans([]);
          }

          setGetRoomtypeRateplanStatus(false);
        })
        .catch((e) => {
          console.log('e', e);
          // setCampingRoomTypeRatePlans([]);
          setGetRoomtypeRateplanStatus(false);
        });
    } else {
    }
  }, []);

  const [campingRefundPolicyBeforeReservation, setCampingRefundPolicyBeforeReservation] =
    useState<any>(null);

  useEffect(() => {
    getCampingRefundPolicyBeforeReservationV2(
      campingId,
      roomTypeId,
      ratePlanId,
      `?checkin=${dayjs(parsedSearch?.start_date as any).format('YYYY-MM-DD')}&checkout=${dayjs(
        parsedSearch?.end_date as any
      ).format('YYYY-MM-DD')}`
    ).then((res) => {
      if (res?.success && res?.data) {
        if (res?.data?.refundPolicyBeforeReservation) {
          setCampingRefundPolicyBeforeReservation(res?.data?.refundPolicyBeforeReservation);
        }
      }
    });
  }, []);

  const modifyCampingRefundPolicyBeforeReservation = () => {
    let refundTypeObj: { [key: string]: any } = {
      nights: '연박 예약 (전체 예약 요금에 대해 체크인 날짜를 기준으로 취소환불정책을 일괄 적용)',
    };

    let refundPolicyObj = campingRoomTypeRatePlan;
    if (campingRefundPolicyBeforeReservation) {
      refundPolicyObj = campingRefundPolicyBeforeReservation;
    }

    if (refundPolicyObj) {
      let refundPolicy = '';
      if (refundPolicyObj?.refund_policy) {
        refundPolicy =
          refundPolicy +
          `<div style="display: grid; grid-template-columns: auto auto; border-top: 1px solid #E9ECEF; padding-bottom: 3px">
           <div style=""></div><div style="font-weight: 600; padding-top: 8px; padding-bottom: 8px; text-align: center">환불요금</div>
           <div style="grid-column: span 2; border-bottom: 1px solid #E9ECEF; margin-bottom: 3px"></div>
          `;

        refundPolicyObj?.refund_policy.forEach((item: any) => {
          refundPolicy =
            refundPolicy +
            `
            <div style="border-bottom: 1px solid #E9ECEF; margin-left: 3px; padding-top: 8px; padding-bottom: 8px; text-align: center">${
              item?.until ? new Date(item.until)?.toLocaleString() : ''
            }</div>
            <div style="border-bottom: 1px solid #E9ECEF; padding-top: 8px; padding-bottom: 8px; text-align: center">
              ${item?.refund_amount?.toLocaleString()} (${item?.percent}%)
            </div>
            `;
        });

        refundPolicy = refundPolicy + '</div>';
      }
      return `
      <div style="margin-top: 75px">
        <div style="margin-bottom: 5px">
          <div style="font-weight: 600; font-size: 18px">취환불 적용 타입:</div>
          <div>${refundTypeObj[refundPolicyObj?.refund_type ?? ''] ?? ''}</div>
        </div>
        <div style="margin-bottom: 5px">
          <div style="font-weight: 600; font-size: 18px; margin-bottom: 5px">확정 취환불정책:</div>
          ${refundPolicy}
        </div>
      </div>
      `;
    } else return '';
  };

  useEffect(() => {
    if (me?.userInfo && authUserTokenObj) {
      let name = me?.userInfo?.name;
      let email = me?.userInfo?.email;
      let cellphone = me?.userInfo?.cellphone;

      if (cellphone && cellphone.includes('-')) {
        cellphone = cellphone.replaceAll('-', '');
      }

      let tempObj = { name: name ?? '', email: email ?? '', phone: cellphone ?? '' };
      form.setFieldsValue(tempObj);
    }
  }, [me, authUserTokenObj]);

  return (
    <>
      <TopTitleBar logo={true} home={false} />
      <div className={`w-screen sm:w-full md:w-full lg:w-full bg-white`}>
        <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
          <div className="font-[700] text-[15px] mb-3">예약 상품 정보</div>
          <div className="mb-3">
            <div className="flex justify-between">
              <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">숙소</div>
              <div className="text-[#000000] text-[13px]">{campingInfo?.name ?? ''}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">객실</div>
              <div className="text-[#000000] text-[13px]">{campingRoomType?.name ?? ''}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">패키지</div>
              <div className="text-[#000000] text-[13px]">
                {campingRoomTypeRatePlan?.rateplan_name ?? ''}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">일정</div>
              {parsedSearch?.start_date && parsedSearch?.end_date && (
                <div className="text-[#000000] text-[13px]">
                  {parsedSearch?.start_date + ' ~ ' + parsedSearch?.end_date}
                </div>
              )}
            </div>
            <div className="flex justify-between">
              <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">기본인원</div>
              <div className="text-[#000000] text-[13px]">
                {calculateNumOfPeople(chosenPeople) ? (
                  <>
                    <span>성인 {calculateNumOfPeople(chosenPeople)?.adult + '명, '}</span>
                    <span>미성년 {calculateNumOfPeople(chosenPeople)?.children + '명'}</span>
                  </>
                ) : null}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">미성년자 연령</div>
              <div className="flex text-[#000000] text-[13px]">
                {underAgeInfoList
                  ? underAgeInfoList.map((underAgeInfoObj: any, underAgeInfoObjInd: any) => (
                      <>
                        <span>
                          {underAgeInfoObjInd > 0
                            ? ', ' + underAgeInfoObj.age
                            : underAgeInfoObj.age}
                        </span>
                      </>
                    ))
                  : null}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">예약차량</div>
              <div className="text-[#000000] text-[13px]">{vehicleInfoList?.length}대</div>
            </div>
          </div>
        </Section>
        <div className="bg-gray-100 w-full h-2" />
        <Section className="max-w-md py-3 bg-[#FFFFFF]">
          <div className="flex items-center side-padding mb-3">
            <div className="font-[700] text-[15px] mr-1">예약자 정보</div>
            <p className="text-[#FF6400] text-[12px]">*필수입력</p>
          </div>
          <Form form={form} name="dynamic_rule" onFinish={onFinish}>
            <div style={{ padding: '0 1rem 0 1rem' }}>
              <div className="grid grid-cols-3 mb-1">
                <div className="text-[13px]">
                  이름<span className="text-[#FF6400]">*</span>
                </div>
                <Form.Item
                  className="col-span-2 m-0"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: '이름을 입력해주세요',
                    },
                  ]}
                >
                  <Input style={{ maxHeight: '30px' }} />
                </Form.Item>
              </div>
              <div className="grid grid-cols-3 mb-1">
                <div className="text-[13px]">
                  이메일<span className="text-[#FF6400]">*</span>
                </div>
                <Form.Item
                  className="col-span-2 m-0"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: '이메일을 입력해주세요',
                    },
                    {
                      required: true,
                      message: '이메일을 입력해주세요',
                    },
                  ]}
                >
                  <Input style={{ maxHeight: '30px' }} />
                </Form.Item>
              </div>
              <div className="grid grid-cols-3 mb-1">
                <div className="text-[13px]">
                  전화번호<span className="text-[#FF6400]">*</span>
                </div>
                <Form.Item
                  className="col-span-2 m-0"
                  name="phone"
                  rules={[
                    // {
                    //   required: true,
                    //   message: '전화번호를 입력해주세요',
                    // },
                    {
                      validator: checkPhoneNumb,
                      message: '전화번호를 입력해주세요. 예) 01012345678',
                    },
                  ]}
                >
                  <Input style={{ maxHeight: '30px' }} placeholder="예) 01012345678" />
                </Form.Item>
              </div>
              <div className="grid grid-cols-3 mb-1">
                <div className="text-[13px]">
                  국적<span className="text-[#FF6400]">*</span>
                </div>
                <Form.Item
                  className="col-span-2 m-0"
                  name="nationality"
                  rules={[
                    {
                      required: true,
                      message: '국적을 선택해주세요',
                    },
                  ]}
                >
                  <AntdSelect
                    placeholder="국적을 선택해주세요"
                    //  value={month}
                    //  onChange={(e: any) => handleMonth(e)}
                  >
                    {nationalities &&
                      Object.keys(nationalities).length > 0 &&
                      Object.keys(nationalities).map((item) => (
                        <AntdOption key={item}>{nationalities[item]}</AntdOption>
                      ))}
                  </AntdSelect>
                </Form.Item>
              </div>
              {/* // To check member or non-member */}
              {me && authUserTokenObj ? null : (
                <>
                  <div className="grid grid-cols-3 mb-1">
                    <div className="text-[13px]">
                      비밀번호<span className="text-[#FF6400]">*</span>
                    </div>
                    <Form.Item
                      className="col-span-2 m-0"
                      name="password"
                      rules={[{ required: true, message: '비밀번호를 입력해주세요' }]}
                    >
                      <Input.Password style={{ maxHeight: '30px' }} />
                    </Form.Item>
                  </div>
                  <p className="text-[#FF6400] text-[11px] mt-2">
                    * 입력하신 비밀번호는 예약조회를 위해 사용됩니다.
                  </p>
                </>
              )}
            </div>
            <div className="bg-gray-100 w-full h-2 mt-8" />
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <div className="side-padding mb-3">
                <div className="font-[700] text-[15px] mb-3">결제 수단</div>
                <div className="flex w-full">
                  {Object.keys(paymentMethodObj).map((key) => (
                    <div key={key}>
                      <button
                        type="button"
                        className={`border border-[#D0D0D0] px-6 py-1 mr-2 text-[13px] ${
                          chosenPaymentMethod == key
                            ? 'bg-[#FF6400] text-[white]'
                            : 'bg-[white] text-[black]'
                        }`}
                        //disabled={true}
                        onClick={() => handlePaymentMethod(key)}
                      >
                        {paymentMethodObj[key]}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </Section>
            <div className="bg-gray-100 w-full h-2 mt-1" />
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <div className="side-padding mb-3">
                <div className="font-[700] text-[15px] mb-3">결제 금액</div>
                {getRoomtypeRateplanStatus ? (
                  <>
                    <div className="flex justify-center">
                      <div className="loading-container-roomtypeRateplan text-[50px]">
                        <span className="loading-dot">.</span>
                        <span className="loading-dot">.</span>
                        <span className="loading-dot">.</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-between">
                      <div>숙박요금</div>
                    </div>
                    <div className="mb-1 ml-4">
                      {campingRoomTypeRatePlan?.nights.map((nightObj: any) => (
                        <>
                          <div className="flex justify-between">
                            <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">
                              {nightObj?.date}
                            </div>
                            <div className="flex flex-col">
                              <div>{nightObj?.sale_price?.toLocaleString() ?? 0}원</div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                    {campingRoomTypeRatePlan?.fees && (
                      <>
                        <div className="flex justify-between">
                          <div>기타 추가금액</div>
                        </div>
                        <div className="mb-1 ml-4">
                          {Object.keys(campingRoomTypeRatePlan?.fees).map((feeKey: any) => (
                            <>
                              <div className="flex justify-between">
                                <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">
                                  {ratePlanAdditionalFeeObj[feeKey]}
                                </div>
                                <div className="flex flex-col">
                                  <div>
                                    {campingRoomTypeRatePlan?.fees[feeKey]?.toLocaleString()}원
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    )}

                    <div className="bg-gray-100 w-full border my-3" />
                    <div className="flex justify-between font-[700] text-[18px]">
                      <div>총 결제 금액</div>
                      {campingRoomTypeRatePlan && (
                        <div className="text-[#FF6400]">
                          {calculateTotalPrice()?.toLocaleString()}원
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </Section>
            <div className="bg-gray-100 w-full h-2 mt-8" />
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <div className="mb-3">
                <div className="side-padding font-[700] text-[15px] mb-3">약관 동의</div>
                <Form.Item noStyle name="terms" className="w-full">
                  <div className="side-padding">
                    <Checkbox
                      // indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    >
                      전체 동의하기
                    </Checkbox>
                    <div className="bg-gray-100 w-full border my-3" />
                  </div>
                  <Checkbox.Group
                    value={checkedList}
                    onChange={onChangeCheckboxGroup}
                    className="w-full"
                  >
                    {checkboxOptions &&
                      checkboxOptions.map((checkboxOption) => (
                        <div key={checkboxOption} className="flex justify-between items-center">
                          <Checkbox
                            value={checkboxOption}
                            style={{ lineHeight: '32px' }}
                            className="pl-4"
                          >
                            {checkboxOption} {'['}
                            {checkboxOptionsRequiredObjGenerator()[checkboxOption] == true
                              ? '필수'
                              : checkboxOptionsRequiredObjGenerator()[checkboxOption] == false
                              ? '선택'
                              : null}
                            {']'}
                          </Checkbox>

                          {/* campingRoomTypeRatePlan campingRefundPolicyBeforeReservation */}
                          <TermButton
                            termContent={
                              checkboxOption === ConditionsType?.PERSONAL_INFO_POLICY
                                ? checkboxOptionsObj[ConditionsType?.PERSONAL_INFO_POLICY] ?? ''
                                : checkboxOption === ConditionsType?.OVER_14_POLICY
                                ? checkboxOptionsObj[ConditionsType?.OVER_14_POLICY] ?? ''
                                : checkboxOption === ConditionsType?.CANCEL_REFUND_POLICY &&
                                  (campingRoomTypeRatePlan || campingRefundPolicyBeforeReservation)
                                ? modifyCampingRefundPolicyBeforeReservation() ?? ''
                                : ''
                            }
                          />
                        </div>
                      ))}
                  </Checkbox.Group>
                  <p className="text-[#FF6400] text-[12px] side-padding mt-3">
                    * 만 19세 이하 미성년자는 법정대리인 동행 없이 캠핑장 투숙이 불가합니다.
                  </p>
                </Form.Item>
              </div>
            </Section>
            <Section className="bg-gray-100 side-padding">
              <div className="py-[5px]">
                <div>
                  <div className="border-solid rounded border border-stone-300 float-right p-0.5 text-xs">
                    <a href="/v2/camping/faq">자주묻는질문</a>
                  </div>
                  <p className="font-[700] text-[15px]">고객센터</p>
                  <p>010-5288-4333</p>
                  <p>평일 10:30 ~ 17:00 점심 12:30 ~ 14:00 </p>
                  <p>토,일요일 및 공휴일 휴무</p>
                </div>
                <div className="w-full border my-3" />
                <div>
                  <div className="font-[700] text-[15px] mb-3">주식회사 보딩패스</div>
                  <div className="grid grid-cols-10">
                    <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">
                      주소
                    </div>
                    <div className="col-span-7">
                      서울특별시 성동구 아차산로7나길 18 (성수동 2가) 대선 APEXCENTER 905호
                    </div>
                  </div>
                  <div className="grid grid-cols-10">
                    <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">
                      대표
                    </div>
                    <div className="col-span-7">조건형</div>
                  </div>
                  <div className="grid grid-cols-10">
                    <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">
                      사업자등록번호
                    </div>
                    <div className="col-span-7">323-88-02118</div>
                  </div>
                  <div className="grid grid-cols-10">
                    <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">
                      통신판매업신고
                    </div>
                    <div className="col-span-7">2022-서울성동-00191</div>
                  </div>
                </div>
                <p className="my-3">
                  보딩패스는 통신판매중개자로서 통신판매의 당사자가 아니며, 상품의 예약, 이용 및
                  환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.
                </p>
              </div>

              {/* <div className="pb-[100px]" /> */}
            </Section>
            <div
              className={`fixed bottom-0 bg-white z-20 w-full max-w-md 
                flex space-x-2 px-4 h-16 flex justify-center items-center`}
            >
              <Form.Item noStyle>
                <Button
                  // type="text"
                  id="booking-page-button"
                  className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                    flex-1 flex justify-center items-center"
                  htmlType="submit"
                  disabled={payStatus}
                >
                  {payStatus ? '결제중...' : '결제'}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Section>
        <div className="pb-20" />
      </div>
    </>
  );
};
