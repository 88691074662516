import { useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { Toggle } from '../../../components/Toggle';
import { Search } from '../../../components/Search';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { useHistory, useParams } from 'react-router-dom';
import useDebounce from '../../../hooks/common';
// import { DEBOUNCE_THRESHOLD_MS } from './constants';
import { find, map, uniq } from 'lodash';
import { format } from 'date-fns';
import FroalaEditor from './components/FroalaEditor';
import { stringify } from 'qs';
import { useProducts } from '../../../hooks/product';
import ExhibitionProductDnd from './components/ExhibitionProductDnd';
import { useMutation } from 'react-query';

import { toast } from 'react-toastify';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button as AntdButton,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select as AntdSelect,
  Slider,
  Switch as AntdSwitch,
  Upload,
  Input,
  TimePicker,
  DatePicker,
} from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import 'antd/dist/antd.css';

import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';

import { uploadImage } from '../../../api/photo';
import { CreateExhibitionParams, ExhibitionParams } from '../../../api_v2/admin/exhibitions/types';
import { getProduct, getProducts } from '../../../api_v2/admin/products';
import { createExhibition } from '../../../api_v2/admin/exhibitions';
import { ImagesUpload } from './components/ImagesUpload';
import { api } from '../../../plugins/axios';
import {
  regions,
  cities,
  campingTypes,
  facilities,
  addFacilities,
  surroundings,
  regionsObj,
  regionsObj3,
} from './constants';

import './style.css';
import {
  deleteCampingInfo,
  getCampingInfo,
  updateCampingInfo,
} from '../../../api_v2/admin/camping';

const { Option: AntdOption } = AntdSelect;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 50 },
};

const timePickerFormat = 'HH:mm';

export const CampingDetail = () => {
  const form = Form.useForm()[0];

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  // const editorContentRef = useRef('');
  const [editorDetailDesc, setEditorDetailDesc] = useState('');
  const [editorOperPolicy, setEditorOperPolicy] = useState('');
  const [editorCancelRefundPolicy, setEditorCancelRefundPolicy] = useState('');

  const editorDetailDescImagesObjRef = useRef<{ [key: string]: any }>({});
  const editorOperPolicyImagesObjRef = useRef<{ [key: string]: any }>({});
  const editorCancelRefundPolicyImagesObjRef = useRef<{ [key: string]: any }>({});

  const [campingInfo, setCampingInfo] = useState<{ [key: string]: any } | null>(null);

  const [campingEditStatus, setCampingEditStatus] = useState(false);

  useEffect(() => {
    getCampingInfo(id, '').then((res) => {
      if (res?.success && res?.data) {
        setCampingInfo(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (campingInfo !== null) {
      const {
        name,
        phone,
        address,
        images,
        policy,
        refund_policy,
        description,

        is_visible,
        status,
        tags,
        checkin,
        checkout,
        fee,
        manner_start_time,
        manner_end_time,
        retail_price,
        // code,
      } = campingInfo;

      let mainImagesFileList: UploadFile[] = [];
      if (images && images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          const rand = uuidv4();
          mainImagesFileList.push({
            uid: rand,
            name: rand,
            status: 'done',
            url: images[i],
            thumbUrl: images[i],
          });
        }
      }

      setEditorDetailDesc(description);
      setEditorOperPolicy(policy);
      setEditorCancelRefundPolicy(refund_policy);

      let prevDataObj: { [key: string]: any } = {
        'campinginfo-switch1': is_visible !== undefined ? is_visible : false, // 진열상태
        'campinginfo-switch2': status !== undefined ? status : false, // 판매상태
        'campinginfo-name': name ?? '',
        'campinginfo-types': tags?.campingTypes ?? [],
        facilities: tags?.facilities ?? [],
        'add-facilities': tags?.addFacilities ?? [],
        surroundings: tags?.surroundings ?? [],
        regions: address?.region ?? '',
        cities: address?.city ?? '',
        address: address?.address1 ?? '',
        'detail-address': address?.address2 ?? '',
        phone: phone ?? '',
        price: retail_price ?? 0,
        // commission: fee / (retail_price * 0.01) ?? 0,
        commission: fee ?? 0,
        // code: code ?? '',
        'opening-hours-checkin':
          checkin?.trim()?.length > 0 ? moment(checkin?.trim(), 'HH:mm') : null,
        'opening-hours-checkout':
          checkout?.trim()?.length > 0 ? moment(checkout?.trim(), 'HH:mm') : null,
        'manner-hours-start':
          manner_start_time?.trim()?.length > 0 ? moment(manner_start_time?.trim(), 'HH:mm') : null,
        'manner-hours-end':
          manner_end_time?.trim()?.length > 0 ? moment(manner_end_time?.trim(), 'HH:mm') : null,
        'main-images': mainImagesFileList,
      };

      setChosenRegion(address?.region ?? null);
      setChosenCity(address?.city ?? null);

      form.setFieldsValue(prevDataObj);
    }
  }, [campingInfo]);

  const onFinish = async (values: any) => {
    const mainImages = values['main-images'];
    if (!checkNumb(values.phone)) {
      toast.error('전화번호를 입력해주세요. 예) 01012345678');
    } else {
      setCampingEditStatus(true);
      let mainImagesPromisesResult: any[] = [];
      if (mainImages && mainImages.length > 0) {
        let mainImagesPromises = mainImages.map(async (mainImageObj: any) => {
          if (mainImageObj?.status === 'done') {
            return mainImageObj?.url;
          } else {
            const headers = {
              headers: {
                accept: '*/*',
                'Content-Type': 'multipart/form-data',
              },
            };
            const mainImageFormData = new FormData();
            mainImageFormData.append('file', mainImageObj.originFileObj);
            let mainImagePhotoRes = await api.post('/photo', mainImageFormData, headers);
            return mainImagePhotoRes?.data?.url ?? '';
          }
        });
        mainImagesPromisesResult = await Promise.all(mainImagesPromises);
      }
      const modifyEditorContent = async (imagesRef: any, editorContent: any) => {
        let editorImagesPromises = Object.keys(imagesRef.current).map(async (editorImageKey) => {
          const headers = {
            headers: {
              accept: '*/*',
              'Content-Type': 'multipart/form-data',
            },
          };
          const imageFormData = new FormData();
          imageFormData.append('file', imagesRef.current[editorImageKey]);
          let photoRes = await api.post('/photo', imageFormData, headers);
          return {
            editorImageKey,
            res: photoRes,
          };
        });
        let editorImagesPromisesResult = await Promise.all(editorImagesPromises);
        let docEditorContent = new DOMParser().parseFromString(editorContent, 'text/html');
        let editorImgElements = docEditorContent.querySelectorAll('img');
        for (let i = 0; i < editorImgElements.length; i++) {
          let tempSrcArr = editorImgElements[i].src.split('/');
          let tempUrlName = tempSrcArr.pop();
          let foundItemObj = editorImagesPromisesResult.find(
            (itemObj) => itemObj?.editorImageKey === tempUrlName
          );
          if (foundItemObj) {
            editorImgElements[i].src = foundItemObj?.res?.data?.url ?? '';
          }
        }
        return docEditorContent.documentElement.innerHTML;
      };
      let modifiedEditorDetailDescContent = await modifyEditorContent(
        editorDetailDescImagesObjRef,
        editorDetailDesc
      );
      let modifiedEditorOperPolicyContent = await modifyEditorContent(
        editorOperPolicyImagesObjRef,
        editorOperPolicy
      );
      let modifiedEditorCancelRefundPolicyContent = await modifyEditorContent(
        editorCancelRefundPolicyImagesObjRef,
        editorCancelRefundPolicy
      );

      const openingHoursCheckIn = values['opening-hours-checkin']?.format('HH:mm');
      const openingHoursCheckOut = values['opening-hours-checkout']?.format('HH:mm');
      const mannerHoursStart = values['manner-hours-start']?.format('HH:mm');
      const mannerHoursEnd = values['manner-hours-end']?.format('HH:mm');
      // setCampingEditStatus(true);
      let updateCampingInfoResult = await updateCampingInfo(parseInt(id), {
        id: parseInt(id),
        // code: campingInfo?.code ?? '',
        name: values['campinginfo-name'] ?? '',
        isVisible:
          values['campinginfo-switch1'] !== undefined ? values['campinginfo-switch1'] : false,
        status: values['campinginfo-switch2'] !== undefined ? values['campinginfo-switch2'] : false,
        retailPrice: values?.price ?? 0,
        // fee: values?.price * (values?.commission * 0.01) ?? 0,
        fee: values?.commission ?? 0,
        address: {
          city: values?.cities ?? '',
          region: values?.regions ?? '',
          address1: values?.address ?? '',
          address2: values['detail-address'] ?? '',
          zipcode: '',
          location: {
            latitude: '',
            longitude: '',
          },
        },
        phone: values?.phone ?? '',
        checkin: openingHoursCheckIn ?? '',
        checkout: openingHoursCheckOut ?? '',
        mannerStartTime: mannerHoursStart ?? '',
        mannerEndTime: mannerHoursEnd ?? '',
        tags: {
          campingTypes: values['campinginfo-types'] ?? [],
          facilities: values['facilities'] ?? [],
          addFacilities: values['add-facilities'] ?? [],
          surroundings: values['surroundings'] ?? [],
        },
        description: modifiedEditorDetailDescContent ?? '',
        policy: modifiedEditorOperPolicyContent ?? '',
        refundPolicy: modifiedEditorCancelRefundPolicyContent ?? '',
        images: mainImagesPromisesResult ?? [],
      });
      if (updateCampingInfoResult) {
        toast.success('성공적으로 캠핑장을 수정하였습니다.');
      }
      setCampingEditStatus(false);
    }
  };

  const normFile = (e: any) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const checkNumb = (checkStr: string) => {
    let flag = true;
    for (let i = 0; i < checkStr.length; i++) {
      if (isNaN(parseInt(checkStr[i]))) {
        flag = false;
        break;
      }
    }
    return flag;
  };

  const handleDeleteCampingInfo = (campingInfoId: any) => {
    if (window.confirm('캠핑장정보를 삭제하시겠습니까?')) {
      deleteCampingInfo(campingInfoId).then((res) => {
        if (res) {
          toast.success('성공적으로 캠핑장정보를 삭제하였습니다.');
          history.push('/admin/camping/');
        }
      });
    }
  };

  const [chosenRegion, setChosenRegion] = useState<any | null>(null);
  const [chosenCity, setChosenCity] = useState<any | null>(null);

  const handleRegions = (regionData: any) => {
    setChosenRegion(regionData);
    setChosenCity(null);
  };

  const handleCities = (cityData: any) => {
    setChosenRegion(cityData);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>캠핑장 수정</AdminH1>
      </div>

      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:px-8">
          <Form.Provider onFormFinish={(name, { values, forms }) => {}}>
            <Form form={form} name="validate_other" {...formItemLayout} onFinish={onFinish}>
              <div>
                <Form.Item>
                  <h3>상태구분</h3>
                </Form.Item>
                <Form.Item name="campinginfo-switch1" valuePropName="checked" label="진열상태">
                  <AntdSwitch />
                </Form.Item>
                <Form.Item name="campinginfo-switch2" valuePropName="checked" label="판매상태">
                  <AntdSwitch />
                </Form.Item>
                <Form.Item>
                  <h3>기본 정보</h3>
                </Form.Item>
                <Form.Item
                  name="campinginfo-name"
                  label="캠핑장명"
                  rules={[{ required: true, message: '캠핑장명' }]}
                >
                  <Input placeholder="캠핑장명" />
                </Form.Item>
                <Form.Item name="campinginfo-types" label="분류" rules={[{ type: 'array' }]}>
                  <AntdSelect mode="multiple" placeholder="분류">
                    {campingTypes.map((item) => {
                      return (
                        <AntdOption key={item} value={item}>
                          {item}
                        </AntdOption>
                      );
                    })}
                  </AntdSelect>
                </Form.Item>
                <Form.Item name="facilities" label="기본시설" rules={[{ type: 'array' }]}>
                  <AntdSelect mode="multiple" placeholder="기본시설">
                    {facilities.map((item) => {
                      return (
                        <AntdOption key={item} value={item}>
                          {item}
                        </AntdOption>
                      );
                    })}
                  </AntdSelect>
                </Form.Item>
                <Form.Item name="add-facilities" label="부가시설" rules={[{ type: 'array' }]}>
                  <AntdSelect mode="multiple" placeholder="부가시설">
                    {addFacilities.map((item) => {
                      return (
                        <AntdOption key={item} value={item}>
                          {item}
                        </AntdOption>
                      );
                    })}
                  </AntdSelect>
                </Form.Item>
                <Form.Item name="surroundings" label="환경" rules={[{ type: 'array' }]}>
                  <AntdSelect mode="multiple" placeholder="환경">
                    {surroundings.map((item) => {
                      return (
                        <AntdOption key={item} value={item}>
                          {item}
                        </AntdOption>
                      );
                    })}
                  </AntdSelect>
                </Form.Item>
                <Form.Item
                  name="regions"
                  label="지역"
                  rules={[{ required: true, message: '지역' }]}
                >
                  <AntdSelect
                    placeholder="지역"
                    onChange={(e) => handleRegions(e)}
                    value={chosenRegion}
                  >
                    {regions.map((item) => (
                      <AntdOption key={item} value={item}>
                        {item}
                      </AntdOption>
                    ))}
                  </AntdSelect>
                </Form.Item>
                <Form.Item
                  name="cities"
                  label="상세지역"
                  // rules={[{ required: true, message: '상세지역' }]}
                >
                  <AntdSelect
                    placeholder="상세지역"
                    onChange={(e) => handleCities(e)}
                    value={chosenCity}
                  >
                    {((chosenRegion ? regionsObj3[chosenRegion] : []) ?? []).map((item: any) => (
                      <AntdOption key={item} value={item}>
                        {item}
                      </AntdOption>
                    ))}
                  </AntdSelect>
                </Form.Item>
                <Form.Item
                  name="address"
                  label="영업장주소"
                  rules={[{ required: true, message: '영업장 주소' }]}
                >
                  <Input placeholder="영업장주소" />
                </Form.Item>
                <Form.Item name="detail-address" label="상세주소">
                  <Input placeholder="상세주소" />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="대표 전화번호"
                  rules={[{ required: true, message: '대표 전화번호' }]}
                >
                  <Input style={{ width: '100%' }} placeholder="예) 01012345678" />
                </Form.Item>
                <Form.Item
                  name="price"
                  label="대표 예약가"
                  rules={[{ required: true, message: '대표 예약가' }]}
                >
                  <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                  label="수수료율 (%)"
                  name="commission"
                  rules={[{ required: true, message: '수수료율' }]}
                >
                  <InputNumber min={0} max={100} />
                </Form.Item>

                {/* <Form.Item name="code" label="코드"  >
                  <Input placeholder="코드" />
                </Form.Item> */}
                <Form.Item name="detail-description" label="상세설명">
                  <FroalaEditor
                    defaultValue={editorDetailDesc}
                    onChange={(detailDescModel) => {
                      setEditorDetailDesc(detailDescModel);
                    }}
                    editorImagesObjRef={editorDetailDescImagesObjRef}
                  />
                </Form.Item>

                {/* <Form.Item name="opening-hours" label="이용시간(체크인, 체크아웃)">
                  <TimePicker.RangePicker format={timePickerFormat} />
                </Form.Item>
                <Form.Item name="manner-hours" label="매너타임">
                  <TimePicker.RangePicker format={timePickerFormat} />
                </Form.Item> */}

                <Form.Item label="이용시간">
                  <div className="flex">
                    <Form.Item
                      name="opening-hours-checkin"
                      label="체크인"
                      className="mr-3"
                      rules={[{ required: true, message: '체크인' }]}
                    >
                      <TimePicker format={timePickerFormat} />
                    </Form.Item>
                    <Form.Item
                      name="opening-hours-checkout"
                      label="체크아웃"
                      rules={[{ required: true, message: '체크아웃' }]}
                    >
                      <TimePicker format={timePickerFormat} />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item label="매너타임">
                  <div className="flex">
                    <Form.Item name="manner-hours-start" label="Start" className="mr-3">
                      <TimePicker format={timePickerFormat} />
                    </Form.Item>
                    <Form.Item name="manner-hours-end" label="End">
                      <TimePicker format={timePickerFormat} />
                    </Form.Item>
                  </div>
                </Form.Item>

                <Form.Item name="campinginfo-operation-policy" label="캠핑장 운영정책">
                  <FroalaEditor
                    defaultValue={editorOperPolicy}
                    onChange={(operPolicyModel) => {
                      setEditorOperPolicy(operPolicyModel);
                    }}
                    editorImagesObjRef={editorOperPolicyImagesObjRef}
                  />
                </Form.Item>
                <Form.Item name="campinginfo-cancel-refund-policy" label="캠핑장 취소/환불정책">
                  <FroalaEditor
                    defaultValue={editorCancelRefundPolicy}
                    onChange={(cancelRefundPolicyModel) => {
                      setEditorCancelRefundPolicy(cancelRefundPolicyModel);
                    }}
                    editorImagesObjRef={editorCancelRefundPolicyImagesObjRef}
                  />
                </Form.Item>
              </div>

              <Form.Item label="메인 이미지 정보">
                <Form.Item
                  name="main-images"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  noStyle
                >
                  <Upload.Dragger
                    listType="picture"
                    name="files"
                    // action="/upload.do"
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 18 }}>
                <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
                  {campingEditStatus ? (
                    <div>캠핑장 수정중...</div>
                  ) : (
                    <>
                      <Button
                        type="button"
                        text="취소"
                        className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
                        to="/admin/camping"
                        disabled={campingEditStatus}
                      />
                      <AntdButton
                        className="add-submit-button button md:max-w-md h-10 text-sm filled-black"
                        htmlType="submit"
                        disabled={campingEditStatus}
                      >
                        수정
                      </AntdButton>
                      <Button
                        type="button"
                        text="삭제"
                        className="h-10 text-sm outlined-gray-600 bg-red-500 text-white"
                        to=""
                        disabled={campingEditStatus}
                        onClick={() => handleDeleteCampingInfo(id)}
                      />
                    </>
                  )}
                </div>
              </Form.Item>
            </Form>
          </Form.Provider>
        </div>
      </Card>
    </>
  );
};
