import React, { useState } from 'react';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { BannerText, BannerType } from '../../../hooks/banner/type';
import { map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { CreateBannerParams } from '../../../api_v2/admin/banners/types';
import { createBanner } from '../../../api_v2/admin/banners';
import { uploadImage } from '../../../api_v2/admin/photo';

export const BannerAdd = () => {
  const history = useHistory();
  const [formState, setFormState] = useState<any>({
    type: null,
    title: '',

    // subTitle: '',
    thumbnail: '',
    url: '',
    weight: 0,
  });
  const [bannerPhoto, setBannerPhoto] = useState<File | null | undefined>();

  const [displayPhoto, setDisplayPhoto] = useState<File | null | undefined>();

  const [createBannerStatus, setCreateBannerStatus] = useState<boolean>(false);

  const _onChangeText = (e: any) => {
    const { name, value } = e.target;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: name === 'weight' ? parseInt(value) : value,
    }));
  };

  const _onChangeType = (e: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      type: e.target.value,
    }));
  };

  const _handleSubmitButton = async () => {
    // if (!displayPhoto) {
    //   toast.error('사진을 업로드해주세요');
    // } else

    if (!formState.type) {
      toast.error('노출 위치를 선택해주세요');
    } else if (!(formState.weight >= 0)) {
      toast.error('0이상 숫자를 입력해주세요');
    } else {
      let redTextFlag = false;
      for (let i = 0; i < Object.keys(formState).length; i++) {
        let key = Object.keys(formState)[i];
        if (key !== 'thumbnail') {
          if (formState[key as keyof CreateBannerParams]) {
            let inputValue = formState[key as keyof CreateBannerParams]!.toString().trim();
            if (inputValue.length === 0) {
              redTextFlag = true;
              break;
            }
          }
        }
      }
      if (redTextFlag) {
        toast.error('모든항목을 작성해주세요');
      } else {
        setCreateBannerStatus(true);
        let uploadImageResult = displayPhoto ? await uploadImage(displayPhoto) : null;
        // if (uploadImageResult?.success) {
        let createBannerResult = await createBanner({
          ...formState,
          thumbnail: uploadImageResult?.data ?? '',
        });
        if (createBannerResult) {
          toast.success('성공적으로 배너를 등록하였습니다');
          setCreateBannerStatus(false);
        } else {
          toast.error('Something went wrong creating a banner');
        }
        // } else {
        //   toast.error('Something went wrong uploading an image');
        // }
      }
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>배너 추가</AdminH1>
      </div>
      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-start-1 col-span-2">
            <TextField label="제목" name="title" value={formState.title} onChange={_onChangeText} />
            <TextField label="url" name="url" value={formState.url} onChange={_onChangeText} />
            <TextField
              label="진열순서 (높을수록 우선 노출됩니다)"
              type="number"
              name="weight"
              value={formState.weight}
              onChange={_onChangeText}
            />
          </div>

          {/* <TextField
            label="부제목"
            name="subTitle"
            value={bannerState.subTitle}
            onChange={_onChangeText}
          /> */}

          <Select label="노출 위치 선택" onChange={_onChangeType}>
            <option disabled selected hidden>
              노출 위치를 선택해주세요
            </option>
            {map(Object.values(BannerType), (banner) => (
              <option key={banner} value={banner}>
                {BannerText[banner]}
              </option>
            ))}
          </Select>
          <div className="col-start-1 col-span-1">
            <div className="font-bold text-lg mb-4">대표 이미지</div>
            <label htmlFor="displayPhoto">
              <div className="relative aspect-1 rounded-md border-2 border-grey-5">
                {displayPhoto || formState.thumbnail ? (
                  <>
                    <img
                      className="absolute w-full h-full rounded object-cover"
                      src={displayPhoto ? URL.createObjectURL(displayPhoto) : formState.thumbnail}
                      alt=""
                    />
                    <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                      사진 수정하기
                    </div>
                  </>
                ) : (
                  <div className="absolute w-full h-full rounded object-cover bg-white">
                    <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                      <FileUploadImg />
                      <div className="text-sm text-gray-500 text-center pt-1">
                        이미지를 업로드해주세요.
                        <br />
                        (최대 1장)
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </label>
            <input
              type="file"
              id="displayPhoto"
              className="hidden"
              accept="image/*"
              onChange={(e) => {
                if (e.target.validity.valid && !createBannerStatus) {
                  setDisplayPhoto(e.target.files?.item(0));
                }
              }}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="취소"
            className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
            to="/admin/banner"
          />
          <Button
            onClick={() => _handleSubmitButton()}
            text="추가하기"
            className="h-10 text-sm filled-black"
            disabled={createBannerStatus}
          />
        </div>
      </Card>
    </>
  );
};
