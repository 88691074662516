import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper';

SwiperCore.use([Pagination, Autoplay]);

enum DrawerType {
  POST_COMMENT_DETAIL = 'post_comment_detail',
  SEARCH_HISTORY_COMMUNITY_POSTS_RECOMMEND_VK_TOUR_DESTS = 'search_history_community_posts_recommend_vk_tour_dests',
}

const RecommendVKTourDests = ({
  recommVkTourDests,
  openDrawer,
  setChosenRecommendVkTourDest,
}: any) => {
  const [currentIndexForImg, setCurrentIndexForImg] = useState(0);
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    try {
      if (recommVkTourDests) {
        let tempImgs = recommVkTourDests?.map((destObj: any) => {
          let addr1 = '';
          if (destObj?.data?.addr1) {
            let splitData = destObj?.data?.addr1?.split(' ');
            if (splitData) {
              addr1 = splitData[0] ?? '';
            }
          }
          if (destObj?.data?.images) {
            return {
              ...destObj?.data?.images[0],
              vkTourDestTitle: destObj?.data?.title,
              vkTourDestAddr1: addr1,
              vkTourDestId: destObj?.data?.id,
            };
          } else {
            return null;
          }
        });
        setImages(tempImgs);
      }
    } catch (e) {}
  }, [recommVkTourDests]);

  const changeImgObjectFit = (slideImage: any) => {
    try {
      if (slideImage) {
        const imgElement = slideImage as any;
        const width = imgElement?.naturalWidth as any;
        const height = imgElement?.naturalHeight as any;

        if (imgElement && width >= 0 && height > 0) {
          const aspectRatio = width / height;
          if (aspectRatio > 1) {
            imgElement.style['object-fit'] = 'cover';
          } else {
            imgElement.style['object-fit'] = 'contain';
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  const handleImageLoad = (imageUrl: string) => {
    try {
      setLoadedImages((prevLoadedImages) => [...prevLoadedImages, imageUrl]);
    } catch (e) {}
  };

  const isImageLoaded = (imageUrl: string) => {
    try {
      return loadedImages.includes(imageUrl);
    } catch (e) {
      return false;
    }
  };

  return (
    <div className="mt-[25px]">
      <style>{`
      .aa37c62b-2b06-4bb6-bc8d-7990516a2e0d img {
        max-width: 150px;
        height: 5rem;
        padding: 0 15px;
        object-fit: cover;
      }
      
      .aa37c62b-2b06-4bb6-bc8d-7990516a2e0d img:last-of-type {
        padding-left: 0;
      }

      .aa37c62b-2b06-4bb6-bc8d-7990516a2e0d .title {
        width: max-content;
        max-width: 150px;
        padding: 0 15px;
        cursor: pointer
      }

      .aa37c62b-2b06-4bb6-bc8d-7990516a2e0d .title:last-of-type {
        padding-left: 0;
      }
      
      .aa37c62b-2b06-4bb6-bc8d-7990516a2e0d {
        position: relative;
        width: 100%;
        overflow: hidden;
      }
      
      .aa37c62b-2b06-4bb6-bc8d-7990516a2e0d .container {
        display: flex;
      }
      
      .aa37c62b-2b06-4bb6-bc8d-7990516a2e0d .item {
        display: flex;
        animation: horizontalScroll 24s linear infinite backwards;
      }
      
      @keyframes horizontalScroll {
        0% {
          transform: translate(0);
        }
      
        100% {
          transform: translate(-100%);
        }
      }
      
      `}</style>
      <div className="aa37c62b-2b06-4bb6-bc8d-7990516a2e0d">
        <div className="container">
          {[0, 0, 0].map((obj) => (
            <div className="item">
              {recommVkTourDests.map((destObj: any) => (
                <div key={destObj?.data?.title}>
                  {/* <img src={data?.images[0]?.url} alt={data?.title} /> */}
                  <p
                    className="title"
                    onClick={() => {
                      openDrawer(
                        DrawerType?.SEARCH_HISTORY_COMMUNITY_POSTS_RECOMMEND_VK_TOUR_DESTS
                      );

                      setChosenRecommendVkTourDest(destObj?.data);

                      //   window.location.href = `/community/posts/${image?.vkTourDestId}`;
                    }}
                  >
                    {destObj?.data?.title}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecommendVKTourDests;
