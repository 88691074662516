import { result } from 'lodash';
import { api } from '../../../plugins/axios';

export const getExhibition = async (id: number) => {
  try {
    let result = await api.get(`/exhibitions/${id}`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getExhibitions = async (dataObject: any) => {
  try {
    if (dataObject?.queryKey[1]) {
      const { limit } = dataObject.queryKey[1];
      let page: number = dataObject.pageParam ? dataObject.pageParam : 1;
      return await api.get(`/exhibitions?page=${page}&limit=${limit}&sort[weight]=DESC`);
    }
    // let apiResult = await api.get(`/exhibitions?page=${page}&limit=${limit}&sort[weight]=DESC`);
    // let possibleStatus = [200, 201];
    // if (possibleStatus.includes(apiResult.status)) {
    //   return {success: true, data: apiResult.data};
    // } else {
    //   return { success: false };
    // }
  } catch (e) {
    console.error('e', e);
  }
};
