import { useEffect, useRef, useState } from 'react';
import {
  Button as AntdButton,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select as AntdSelect,
  Slider,
  Switch as AntdSwitch,
  Upload,
  Input,
  TimePicker,
  DatePicker,
  Modal,
} from 'antd';
import 'antd/dist/antd.css';
import { calendarYears } from '../constants';

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 50 },
};

export const AntdCalendarModal1 = (props: any) => {
  const form = Form.useForm()[0];
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFinish = (values: any) => {
    let modified = props.currInventories.map((item: any) => {
      if (props.dateStr === item?.date) {
        return {
          ...item,
          extraPrice: values['additional-fee'],
          status: values['campinginfo-switch2'],
        };
      } else return item;
    });
    props.setCurrInventories(modified);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (props.dateData) {
      const { booking_id, date, extraPrice, status } = props.dateData;
      form.setFieldsValue({ 'additional-fee': extraPrice, 'campinginfo-switch2': status });
    }
  }, []);

  return (
    <div>
      <div
        className="flex justify-center border-b-2 cursor-pointer"
        // onClick={() => handleDateData(dateStr)}
        onClick={showModal}
      >
        {new Date(props.dateStr).toDateString()}
      </div>
      <Modal
        title="수정"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} name="validate_other" {...formItemLayout} onFinish={onFinish}>
          {/* <Form.Item name="available" label="재고" rules={[{ required: true, message: '재고' }]}>
            <InputNumber min={1} />
          </Form.Item> */}
          <Form.Item name="campinginfo-switch2" valuePropName="checked" label="판매상태">
            <AntdSwitch />
          </Form.Item>
          <Form.Item
            name="additional-fee"
            label="추가금액"
            rules={[{ required: true, message: '추가금액' }]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 18 }}>
            <AntdButton
              className="add-submit-button button md:max-w-md h-10 text-sm filled-black"
              htmlType="submit"
            >
              수정
            </AntdButton>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
