import { api } from '../../../plugins/axios';
import { CreateNotificationParams } from './types';

export const getProduction = async (id: number, queryString?: string) => {
  let result = await api.get(`/admin/production/${id}` + queryString);
  let possibleStatus = [200, 201];
  if (possibleStatus.includes(result.status)) {
    return { success: true, data: result.data };
  } else {
    return { success: false };
  }
};

export const getProductionList = async (queryString: string) => {
  try {
    let result = await api.get('/admin/production' + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const createProduction = async (createProductionParams: any) => {
  try {
    let result = await api.post('/admin/production', createProductionParams);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const updateProduction = (id: number, updateProductParams: any) => {
  return api.patch(`/admin/production/${id}`, updateProductParams);
};
