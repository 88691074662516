import { Button } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { parse, stringify } from 'qs';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { map } from 'lodash';
import { api } from '../../../../../plugins/axios';
import { CardType1 } from './components/CardType1';
import { TopTitleBar } from './components/TopTitleBar';
import { CustomDrawer } from './components/CustomDrawer';
import TourDestDetailModal from './TourDestDetailModal';
import { ReactComponent as IconAngleDoubleTop } from '../../../../../assets/icons/icon-angle-double-top.svg';

enum DrawerType {
  VK_TOUR_DEST_DETAIL = 'vk_tour_dest_detail',
}

export default function FindNearby() {
  const [position, setPosition] = useState<any>(null);
  const [loadDataStatus, setLoadDataStatus] = useState(true);
  const [geolocationErr, setGeolocationErr] = useState(false);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (location: any) => {
          setPosition(location?.coords);
        },
        (error) => {
          setGeolocationErr(true);
        }
      );
    } else {
      console.error('Geolocation is not available in your browser.');
    }
  }, []);

  const [distance, setDistance] = useState(10);

  const handleDistanceChange = (event: any) => {
    let newDistance = event.target.value;

    newDistance = parseInt(newDistance);
    if (isNaN(newDistance) || newDistance < 0) {
      newDistance = 0;
    } else if (newDistance > 150) {
      newDistance = 150;
    }

    setDistance(newDistance);
  };

  const queryClient = useQueryClient();

  const handleFindNearbyPlaces = () => {
    if (typeof distance == 'number') {
      if (distance == 0) {
        alert('0보다 큰 수를 입력해주세요');
      } else {
        queryClient.resetQueries('findNearByPage');
        fetchNextPage();
      }
    }
  };

  const [pageLimit, setPageLimitƒ] = useState(20);
  const [initialStatus, setInitialStatus] = useState(true);

  const fetchPlacesByDistance = async (dataObj: any) => {
    if (position && distance > 0) {
      setLoadDataStatus(true);
      let currentQueryStringObj: { [key: string]: any } = {
        page: dataObj?.pageParam ?? 1,
        limit: pageLimit,
        originLat: position?.latitude,
        originLng: position?.longitude,
        maxDistance: distance,
        sort: {},
      };

      const currentQueryString = stringify(currentQueryStringObj, {
        addQueryPrefix: true,
        encode: false,
        arrayFormat: 'brackets',
      });

      // if (pageParam == 1) {
      //   setInitialLoading(true);
      //   loadingDots(true, 'initial-loading-container');
      // } else {
      //   loadingDots(true, 'more-loading-container');
      // }

      let result = await api.get(`/community/vk-tour-dests/find-nearby${currentQueryString}`);

      if (currentQueryStringObj?.page == 1) {
        setInitialStatus(false);
      }

      // if (pageParam == 1) {
      //   setInitialLoading(false);
      //   loadingDots(false, 'initial-loading-container');
      // } else {
      //   loadingDots(false, 'more-loading-container');
      // }

      setLoadDataStatus(false);

      if ([200].includes(result?.status)) {
        return result;
      } else return null;
    }
  };

  const {
    data: searchResultData,
    fetchNextPage,
    isLoading: isSearchResultDataLoading,
  } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`findNearByPage`, position],
    fetchPlacesByDistance as any,
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        if (lastPage?.data?.items?.length > 0) {
          return nextPage;
        } else if (lastPage?.data?.items?.length == 0) {
          return undefined;
        } else return allPages?.length;
      },
      refetchOnWindowFocus: false,
    }
  );

  const observer = useRef<IntersectionObserver>();

  const lastElement = useCallback(
    (target) => {
      if (isSearchResultDataLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && fetchNextPage) {
            fetchNextPage();
          } else {
          }
        },
        // {
        //   rootMargin: '500px',
        //   threshold: 0.1,
        // }
        {
          threshold: 1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [searchResultData, isSearchResultDataLoading]
  );

  const drawerRef = useRef<HTMLDivElement | null>(null);

  const [drawerType, setDrawerType] = useState(null);

  const openDrawer = (drawerTypeData: any, heightData?: any) => {
    setDrawerType(drawerTypeData);
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `none`;

      (drawerRef.current as any).style['pointer-events'] = 'auto';

      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = heightData ?? '100%';
    }
  };

  const closeDrawer = () => {
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `auto`;

      drawerRef.current.style.width = '0';
      drawerRef.current.style.height = '0';
    }
  };

  const [chosenVKTourDestForDetail, setChosenVKTourDestForDetail] = useState(null);

  const topHeaderRef = useRef<any>(null);
  const detectHeaderRef = useRef<any>(null);

  const handleScroll = () => {
    if (topHeaderRef?.current && detectHeaderRef?.current) {
      let mainEle = document.querySelector('main');
      let detectHeaderTop = (detectHeaderRef?.current as any)?.getBoundingClientRect().top;

      if (detectHeaderTop <= 0) {
        if (topHeaderRef.current && mainEle) {
          (topHeaderRef.current as any).style.position = 'fixed';
          (topHeaderRef.current as any).style.top = '0px';
          (topHeaderRef.current as any).style.left = mainEle.style.left;
        }
      } else {
        if (topHeaderRef.current && mainEle) {
          (topHeaderRef.current as any).style.position = '';
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <TopTitleBar backButton={true} home={false} logo={true} />
      {/* <div className="max-w-md w-screen bg-white side-padding"> */}
      <div className="w-screen sm:w-full md:w-full lg:w-full side-padding bg-white">
        {position ? (
          <>
            <div ref={detectHeaderRef}></div>
            <div ref={topHeaderRef} className="z-[100] pt-[15px] bg-[white] w-full max-w-md">
              <p className="text-center text-[15px] font-[700] mb-[5px]">
                거리를 입력해주세요 (km)
              </p>
              <div className="flex gap-[20px] justify-center items-center mb-[25px]">
                <input
                  type="number"
                  id="distanceInput"
                  value={distance}
                  onChange={handleDistanceChange}
                  min="0"
                  step="1"
                  className="border-[#d9d9d9] rounded-[7px] max-w-[30%]"
                />
                <Button
                  className="rounded-[7px]"
                  onClick={() => {
                    handleFindNearbyPlaces();
                  }}
                >
                  검색
                </Button>
              </div>
            </div>

            {!((searchResultData as any)?.pages[0]?.data?.items?.length > 0) &&
            !isSearchResultDataLoading &&
            !initialStatus ? (
              <>
                <p className="grid place-content-center h-44 gap-[5px]">검색결과가 없습니다</p>
              </>
            ) : searchResultData?.pages && searchResultData?.pages?.length > 0 ? (
              <>
                {map(searchResultData?.pages ?? [], (page, pageInd) => {
                  return map(page?.data?.items ?? [], (placeObj, postObjInd: number) => {
                    return (
                      <>
                        <div
                          key={placeObj?.id}
                          ref={
                            searchResultData?.pages?.length - 1 == pageInd &&
                            page?.data?.items?.length - 1 == postObjInd
                              ? lastElement
                              : null
                          }
                        >
                          <CardType1
                            pageInd={pageInd}
                            item={placeObj}
                            openDrawer={openDrawer}
                            setChosenVKTourDestForDetail={setChosenVKTourDestForDetail}
                          />
                        </div>
                      </>
                    );
                  });
                })}
                {/* <div ref={lastElement}></div> */}

                {loadDataStatus ? (
                  <div className="flex justify-center">
                    <div className="w-[23px] h-[23px] border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : null}
          </>
        ) : (
          <>
            <div className="flex justify-center items-center mt-[5px]">
              <div className="w-[25px] h-[25px] border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
            </div>
          </>
        )}
      </div>
      <div className="absolute bottom-10 right-0 w-[30px] h-[30px] z-[1000]">
        <div className="flex justify-end max-w-md">
          <div
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="fixed bottom-10 w-[60px] h-[60px] mr-5 rounded-[50px] bg-[#06A54A] text-[white] font-[300] text-[1.5em] 
        flex justify-center items-center cursor-pointer"
          >
            <IconAngleDoubleTop fill="#FFFFFF" className="w-[35px] h-[35px]" />
          </div>
        </div>
      </div>
      <CustomDrawer drawerRef={drawerRef} contentMt={55}>
        <div className="mt-[50px] p-[15px]">
          {drawerType == DrawerType.VK_TOUR_DEST_DETAIL ? (
            <TourDestDetailModal item={chosenVKTourDestForDetail} />
          ) : null}
        </div>
      </CustomDrawer>
    </div>
  );
}
