import { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as LogoTransferSVG } from '../../../../../assets/icons/icon-transfer-new.svg';
import { ReactComponent as LogoCheckSVG } from '../../../../../assets/icons/icon-check-new.svg';
import { ReactComponent as LogoHomeSVG } from '../../../../../assets/icons/icon-home-new.svg';
import { ReactComponent as LogoMySVG } from '../../../../../assets/icons/icon-my-new.svg';
import { ReactComponent as LogoStoreSVG } from '../../../../../assets/icons/icon-store-new.svg';
import { ReactComponent as LogoTransferSVG2 } from '../../../../../assets/icons/icon-transfer-new-green.svg';
import { ReactComponent as LogoHomeSVG2 } from '../../../../../assets/icons/icon-home-new-green.svg';
import { ReactComponent as LogoMySVG2 } from '../../../../../assets/icons/icon-my-new-green.svg';

import { ReactComponent as LogoComment2SVG } from '../../../../../assets/icons/icon-comment2.svg';

import { authUserTokenObjState, modalState } from '../../../../../ridge/ridge';

export const BottomNavBar = () => {
  let authUserTokenObj = authUserTokenObjState.useValue();

  const history = useHistory();

  const tabsRef = useRef<any | null>(null);

  const checkResHistory = () => {
    history.push('/v2/camping/reservation/check-w-on');
  };

  const transferHistory = () => {
    history.push({
      pathname: '/v2/camping',
      search: '?chosen_tab=conveyance',
    });
  };

  const homeHistory = () => {
    history.push({
      pathname: '/v2/camping',
      search: '',
    });
  };

  const handleUserAuthIcon = (loginStatus: string) => {
    if (loginStatus == 'LOGGED_IN') {
      modalTypes('MY_PAGE');
    } else if (loginStatus == 'LOGGED_OUT') {
      modalTypes('LOGIN');
    }
  };

  const modalTypes = (modalType: string) => {
    if (modalType == 'LOGIN') {
      history.push('/v2/login');
    } else if (modalType == 'MY_PAGE') {
      const onClose = () => modalState.set({ ...modalState.get(), open: false });
      modalState.set({
        title: '마이페이지로 이동하시겠습니까?',
        subtitle: '',
        open: true,
        onClose,
        onClick: async () => {
          window.location.href = '/v2/camping/mypage';
          onClose();
        },
      });
    }
  };

  // const [color, setColor] = useState('#888888');
  // const handleColor = () => {
  //   setColor('#ff6400');
  // };

  return (
    <>
      <div>
        <div
          className="fixed bottom-0 w-full max-w-md bg-white grid grid-cols-6 place-items-center 
            pb-4 z-10 border-t border-gray-150
            rounded-t-[10px]
            "
        >
          <div>
            <div
              className="flex flex-col items-center mt-4 cursor-pointer"
              onClick={() => {
                checkResHistory();
              }}
            >
              <div className="w-auto">
                <LogoCheckSVG className="w-full" />
              </div>
              <p className="text-[#888888] text-[9px]">예약조회</p>
            </div>
          </div>
          <div>
            <div
              className="flex flex-col items-center mt-4 cursor-pointer"
              onClick={(e) => {
                transferHistory();
              }}
            >
              <div className="w-auto">
                {window.location.search == '?chosen_tab=conveyance' ? (
                  <LogoTransferSVG2 className="w-full" />
                ) : (
                  <LogoTransferSVG className="w-full" />
                )}
              </div>
              {window.location.search == '?chosen_tab=conveyance' ? (
                <p className="text-[#06A54A] text-[9px]">양도</p>
              ) : (
                <p className="text-[#888888] text-[9px]">양도</p>
              )}
            </div>
          </div>
          <div>
            <div
              className="flex flex-col items-center mt-4 cursor-pointer"
              onClick={() => {
                window.location.href = '/v2/camping';
              }}
            >
              <div className="w-auto">
                {window.location.href == 'https://pridge.kr/v2/camping' ? (
                  <LogoHomeSVG2 className="w-full" />
                ) : (
                  <LogoHomeSVG className="w-full" />
                )}
              </div>
              {window.location.href == 'https://pridge.kr/v2/camping' ? (
                <p className="text-[#06A54A] text-[9px]">홈</p>
              ) : (
                <p className="text-[#888888] text-[9px]">홈</p>
              )}
            </div>
          </div>
          {authUserTokenObj ? (
            // <div
            //   onClick={() => handleUserAuthIcon('LOGGED_IN')}
            //   className="rounded-[50px] w-[30px] h-[30px] border-[1px]
            //   font-[300] text-[1.0em] flex justify-center items-center cursor-pointer"
            // >
            //   {me?.userInfo?.name ? me?.userInfo?.name[0] : null}
            // </div>
            <div
              onClick={() => handleUserAuthIcon('LOGGED_IN')}
              className="flex flex-col items-center mt-4 cursor-pointer"
            >
              {window.location.pathname == '/v2/camping/mypage' ? (
                <LogoMySVG2 className="w-full" />
              ) : (
                <LogoMySVG className="w-full" />
              )}
              {window.location.pathname == '/v2/camping/mypage' ? (
                <div className="text-[#06A54A] text-[9px]">마이</div>
              ) : (
                <div className="text-[#888888] text-[9px]">마이</div>
              )}
            </div>
          ) : (
            <div
              className="flex flex-col items-center mt-4 cursor-pointer"
              onClick={() => handleUserAuthIcon('LOGGED_OUT')}
            >
              {window.location.pathname == '/v2/camping/mypage' ? (
                <LogoMySVG2 className="w-full" />
              ) : (
                <LogoMySVG className="w-full" />
              )}
              {window.location.pathname == '/v2/camping/mypage' ? (
                <div className="text-[#06A54A] text-[9px]">마이</div>
              ) : (
                <div className="text-[#888888] text-[9px]">마이</div>
              )}
            </div>
          )}
          <div>
            <div
              className="flex flex-col items-center mt-4 cursor-pointer"
              onClick={() => {
                window.location.href = 'https://m.dayout.store';
              }}
            >
              <div className="w-auto">
                <LogoStoreSVG className="w-full" />
              </div>
              <p className="text-[#888888] text-[9px]">스토어</p>
            </div>
          </div>
          <div>
            <div
              className="flex flex-col items-center mt-4 cursor-pointer"
              onClick={() => {
                window.location.href = '/community';
              }}
            >
              <div className="w-auto">
                <LogoComment2SVG width={'35px'} height={'35px'} fill="#888888" />
              </div>
              <p className="text-[#888888] text-[9px]">커뮤니티</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
