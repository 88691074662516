import React, { useEffect, useReducer, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { default as htmlReactParser } from 'html-react-parser';
import { ReactComponent as IconNoPicture } from '../../../../../assets/icons/icon-no-picture.svg';
import { stringify } from 'qs';

export default function TravelDestDetail(props: any) {
  const [currentIndexForImg, setCurrentIndexForImg] = useState(0);

  const nextImage = () => {
    setCurrentIndexForImg((prevIndex) => (prevIndex === images?.length - 1 ? 0 : prevIndex + 1));
  };

  const prevImage = () => {
    setCurrentIndexForImg((prevIndex) => (prevIndex === 0 ? images?.length - 1 : prevIndex - 1));
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {},
  });

  const handleAddress = (addressData: any) => {
    let searchQ = '';
    let add1 = addressData?.addr1 ?? '';
    let add2 = (add1 ? ' ' : '') + (addressData?.addr2 ?? '');
    let pc = (add1 || add2 ? ' ' : '') + (addressData?.zipCode ?? '');
    searchQ = searchQ + add1 + add2 + pc;
    let queryObj = { q: searchQ };
    window.open(
      `https://map.kakao.com/${stringify(queryObj, {
        addQueryPrefix: true,
        encode: false,
      })}`
    );
  };

  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    setImages(props?.item?.images ?? []);
    setCurrentIndexForImg(0);
  }, [props?.item]);

  const changeImgObjectFit = (slideImage: any) => {
    try {
      if (slideImage) {
        const imgElement = slideImage as any;
        const width = imgElement?.naturalWidth as any;
        const height = imgElement?.naturalHeight as any;

        if (imgElement && width >= 0 && height > 0) {
          const aspectRatio = width / height;
          if (aspectRatio > 1) {
            imgElement.style['object-fit'] = 'cover';
          } else {
            imgElement.style['object-fit'] = 'contain';
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const [loadedImages, setLoadedImages] = useState<any>([]);

  const handleImageLoad = (url: any) => {
    if (loadedImages?.includes(url)) {
    } else {
      setLoadedImages((prev: any) => {
        return [...prev, url];
      });
    }
  };

  useEffect(() => {
    // Function to check and respond to style changes
    const handleStyleChanges = () => {
      let generalDrawerEle = document.querySelector('#general-drawer') as any;
      if (generalDrawerEle?.style?.height == '100%') {
        // setImages(props?.item?.images ?? []);
        // setCurrentIndexForImg(0);
      } else if (generalDrawerEle?.style?.height == '0px') {
        // setImages([]);
        // setCurrentIndexForImg(0);
      }
    };

    // Attach a MutationObserver to monitor changes in the element's attributes (which include style changes)
    const observer = new MutationObserver(handleStyleChanges);

    // Start observing the element
    if (document.querySelector('#general-drawer')) {
      observer.observe(document.querySelector('#general-drawer') as any, {
        attributes: true, // Watch for attribute changes
        attributeFilter: ['style'], // Specify the 'style' attribute to watch
      });
    }

    // Cleanup when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div>
      <div className="max-w-md w-full bg-[#FFFFFF]">
        <div className="max-h-[300px] w-full flex justify-center items-center mb-[15px] relative">
          {images?.length > 0 ? (
            <>
              <div {...handlers} className="h-[300px] w-full">
                <>
                  <img
                    src={images[currentIndexForImg]?.url}
                    className="object-contain h-[300px] w-full"
                    ref={(el) => {
                      if (el) {
                        changeImgObjectFit(el);
                      }
                    }}
                    onLoad={() => handleImageLoad(images[currentIndexForImg]?.url)}
                  />

                  <button
                    onClick={prevImage}
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                    className="absolute top-[50%] left-[0px] p-[20px] text-[white] text-[20px] font-[900]"
                  >
                    &larr;
                  </button>
                  <button
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                    onClick={nextImage}
                    className="absolute top-[50%] right-[0px] p-[20px] text-[white] text-[20px] font-[900]"
                  >
                    &rarr;
                  </button>
                  {loadedImages.includes(images[currentIndexForImg]?.url) ? (
                    <></>
                  ) : (
                    <>
                      <div className="absolute top-[0px] animate-pulse w-full h-[300px] bg-gradient-to-br from-blue-200 via-purple-300 to-red-200"></div>
                    </>
                  )}
                </>
              </div>
              <div
                className="absolute bottom-[5px] right-[10px] w-fit z-[1500] px-[8px] py-[1px] text-[white] rounded-[10px]"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
              >
                {(currentIndexForImg ?? 0) + 1} / {images?.length ?? 0}
              </div>
            </>
          ) : (
            <div className="h-[300px] w-full flex justify-center items-center">
              <IconNoPicture width={'60px'} height={'60px'} />
            </div>
          )}
        </div>
        <div className="side-padding mb-3">
          <div className="flex justify-between">
            <div className="text-[12px]">
              {(props?.item?.distance?.toFixed(2) ?? 0) + 'km' ?? null}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="font-[700] text-[15px]">
              {props?.item?.title ? props?.item?.title : null}
            </div>
          </div>
          <div
            className="text-[#6F6F6F] text-[12px]"
            onClick={() => {
              // handleAddress({ addr1: props?.item?.addr1 });
            }}
          >
            {props?.item?.addr1}
          </div>
          <div className="flex justify-between">
            <div>
              <div>총거리: {props?.item?.totalDistance}</div>
              <div>소요시간: {props?.item?.totalTime}</div>
            </div>

            <div
              onClick={() => {
                handleAddress({ addr1: props?.item?.addr1 });
              }}
              className="flex justify-center items-center w-fit mb-[8px] text-[white] rounded-[12px] border bg-[#1cca40] px-[10px] cursor-pointer"
            >
              지도
            </div>
          </div>
          <div className="h-[2px] w-full bg-[#f0f0f0] my-[10px]"></div>
          <div className="mt-[5px] text-[13px]">
            <pre className="w-full mb-[5px] whitespace-pre-wrap break-all">
              {props?.item?.overview ? htmlReactParser(props?.item?.overview) : null}
            </pre>
          </div>
          {props?.item?.attractions?.length > 0 && (
            <>
              {props?.item?.attractions.map((attr: any) => (
                <div key={attr?.id}>
                  {attr?.images?.length > 0 ? (
                    <>
                      <div {...handlers} className="h-[300px] w-full relative">
                        <>
                          <img
                            src={attr?.images[0]?.url}
                            className="object-contain h-[300px] w-full"
                            ref={(el) => {
                              if (el) {
                                changeImgObjectFit(el);
                              }
                            }}
                            onLoad={() => handleImageLoad(attr?.images[0]?.url)}
                          />

                          {loadedImages.includes(attr?.images[0]?.url) ? (
                            <></>
                          ) : (
                            <>
                              <div className="absolute top-[0px] animate-pulse w-full h-[300px] bg-gradient-to-br from-blue-200 via-purple-300 to-red-200"></div>
                            </>
                          )}
                        </>
                      </div>
                    </>
                  ) : (
                    <div className="h-[300px] w-full flex justify-center items-center">
                      <IconNoPicture width={'60px'} height={'60px'} />
                    </div>
                  )}
                  <div className="my-[7px]">
                    <div className="font-[700] text-[15px]">{attr?.subName}</div>
                    <div>{attr?.subDetailOverview}</div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
