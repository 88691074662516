import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { TopTitleBar } from '../components/TopTitleBar';
import { getCommunityBanners } from '../../../../api_v2/non_admin/community';
import { Button } from '../../../../components/Button';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as IconAngleDoubleTop } from '../../../../assets/icons/icon-angle-double-top.svg';
import { toast } from 'react-toastify';
import { getTDCAccommCampaigns } from '../../../../api_v2/non_admin/community/tdc-accommodations';
import { Icon } from '../../../../components/Icon';

export default function AccomodationsWDiscount() {
  const [eventList, setEventList] = useState<any>([]);
  const [initEventList, setInitEventList] = useState<any>([]);
  const [currEvent, setCurrEvent] = useState<any>(null);
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    getTDCAccommCampaigns(`?isVisible=true`).then((res: any) => {
      if (res?.success == true) {
        if (res?.data[0]?.length > 0) {
          for (let i = 0; i < res?.data[0]?.length; i++) {
            let campaignData = res?.data[0][i];
            let isVisible = res?.data[0][i]?.isVisible;

            if (isVisible && campaignData?.endDate) {
              const targetDate = dayjs(campaignData?.endDate);

              const daysRemaining = targetDate.diff(dayjs(), 's');

              if (daysRemaining > 0) {
                if (campaignData?.accommCampaignToAccomms?.length > 0) {
                  let accomList = campaignData?.accommCampaignToAccomms.map((joinObj: any) => {
                    return joinObj?.accomm;
                  });
                  const organizedData: any = accomList.reduce((acc: any, obj: any) => {
                    const { city } = obj;
                    if (!acc[city]) {
                      acc[city] = [];
                    }
                    acc[city].push({ ...obj });
                    return acc;
                  }, {});
                  let modifiedEventList: any = [];

                  modifiedEventList.push({
                    uniqueId: campaignData?.uniqueId ?? '',
                    event: campaignData?.source ?? '',
                    renamedEvent: campaignData?.name ?? '',
                    data: organizedData,
                  });

                  setEventList((prev: any) => {
                    return [...prev, ...modifiedEventList];
                  });
                  setInitEventList((prev: any) => {
                    return [...prev, ...modifiedEventList];
                  });
                }
              }
            }
          }
        }
      }
    });
  }, []);

  const filterWrapRef = useRef<any | null>(null);
  const filterRef = useRef<any | null>(null);

  useEffect(() => {
    const handleScrollEvent = () => {
      try {
        if (filterRef?.current?.getBoundingClientRect()?.top <= 0) {
          filterRef.current.style.position = 'fixed';
          filterRef.current.style.top = 0;
        } else {
          // filterWrapRef.current.style.position = '';
          // filterWrapRef.current.style.top = '';
        }
        if (document.documentElement.scrollTop == 0) {
          filterRef.current.style.position = '';
          filterRef.current.style.top = '';
        }
      } catch (e) {}
    };
    window.addEventListener('scroll', handleScrollEvent);
    return () => window.removeEventListener('scroll', handleScrollEvent);
  }, []);

  useEffect(() => {
    try {
      if (window.location.hash && eventList?.length > 0) {
        let decodedHashData = decodeURIComponent(window.location.hash);

        let hashArr = decodedHashData?.split('#');

        let foundEvtObj = eventList.find((evtObj: any) => evtObj?.uniqueId == hashArr[1]);
        if (foundEvtObj) {
          setCurrEvent(foundEvtObj);
        }

        const targetElement = document.getElementById(hashArr[1] ?? '');

        if (targetElement) {
          smoothScrollTo(targetElement);
        }
      }
    } catch (e) {}
  }, [window.location.hash, eventList]);

  const smoothScrollTo = (targetElement: any) => {
    try {
      setTimeout(() => {
        const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
        const startPosition = window.scrollY;
        const distance = targetPosition - startPosition;
        const duration = 1000; // Adjust the duration as needed

        let startTime: any;

        const animation = (currentTime: any) => {
          if (!startTime) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const progress = Math.min(timeElapsed / duration, 1);
          window.scrollTo(0, startPosition + distance * easeInOutCubic(progress) - 100);

          if (timeElapsed < duration) {
            requestAnimationFrame(animation);
          }
        };

        requestAnimationFrame(animation);
      }, 300);

      // setTimeout(() => {
      //   if (targetElement) {
      //     targetElement.scrollIntoView({ behavior: 'smooth' });
      //   }
      // }, 300);
    } catch (e) {}
  };

  const easeInOutCubic = (t: any) => {
    try {
      return t < 0.5 ? 4 * t ** 3 : 1 - Math.pow(-2 * t + 2, 3) / 2;
    } catch (e) {
      return 0;
    }
  };

  const handleButtonClick = (sectionId: any) => {
    try {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        smoothScrollTo(targetElement);

        window.history.pushState({}, '', `#${sectionId}`);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // Dynamically add styles
    const styleSheet = document.styleSheets[0];

    // Add keyframes
    styleSheet.insertRule(
      `
      @keyframes slide {
        to {
          background-position: 200% center;
        }
      }
    `,
      styleSheet.cssRules.length
    );

    // Add animation class
    styleSheet.insertRule(
      `
      .animate-slide-infinite {
        background-size: auto auto;
        background-size: 200% auto;
        animation: slide 2s linear infinite;
      }
    `,
      styleSheet.cssRules.length
    );

    // Add styles for .animate-character1
    styleSheet.insertRule(
      `
      .animate-character1 {
        text-transform: uppercase;
        background-image: linear-gradient(
          -225deg,
          #08aeea 0%, /* Light Blue */
          #2af598 29%, /* Turquoise Green */
          #ff5c8a 67%, /* Light Red */
          #fff800 100% /* Yellow */
        );
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        color: #fff;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: slide 2s linear infinite;
        display: inline-block;
        font-size: 190px;
      }
    `,
      styleSheet.cssRules.length
    );
  }, []);

  const swiperRef = React.useRef(null);

  const handlePrev = () => {
    if (swiperRef.current) {
      (swiperRef.current as any).slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      (swiperRef.current as any).slideNext();
    }
  };

  const [accommSearchInput, setAccommSearchInput] = useState('');

  const filterEvents = (eventList: any, searchTerm: any) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const filteredList = eventList.map((event: any) => {
      const filteredData: any = {};

      if (event?.data) {
        for (const city in event.data) {
          const filteredCityItems = event.data[city].filter((item: any) => {
            for (const value of Object.values(item)) {
              if (typeof value === 'string' && value.toLowerCase().includes(lowerCaseSearchTerm)) {
                return true;
              }
            }
            return false;
          });

          if (filteredCityItems.length > 0) {
            filteredData[city] = filteredCityItems;
          }
        }
      }

      return { ...event, data: filteredData };
    });

    return filteredList;
  };

  // prob2
  const handleAccommSearchButton = (evt: any) => {
    if ((accommSearchInput as string)?.trim()?.length > 0) {
      let filterList = filterEvents(initEventList, (accommSearchInput as string)?.trim());
      setEventList(filterList);
    } else {
      setEventList(initEventList);
    }
  };

  // prob3
  const handleSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      if ((accommSearchInput as string)?.trim()?.length > 0) {
        let filterList = filterEvents(initEventList, (accommSearchInput as string)?.trim());
        setEventList(filterList);
      } else {
        setEventList(initEventList);
      }
    }
  };

  const handleAccommSearchInput = (e: any) => {
    setAccommSearchInput(e.target.value);
  };

  const checkAccommsNumb = (eventsData: any) => {
    try {
      let status = false;
      for (let i = 0; i < eventsData?.length; i++) {
        let eventObj = eventsData[i];
        if (Object.keys(eventObj?.data)?.length > 0) {
          status = true;
        }
      }
      return status;
    } catch (e) {
      return false;
    }
  };

  return (
    <div>
      <TopTitleBar logo={true} />
      {eventList?.length > 0 ? (
        <>
          {eventList?.length > 0 && (
            <div ref={filterWrapRef} className="absolute right-0 w-full max-w-md bg-[white] z-[2]">
              <div
                ref={filterRef}
                className="flex flex-col justify-center w-full max-w-md side-padding bg-[white] py-[7px]"
              >
                <Swiper
                  onSwiper={(swiper) => {
                    // Capture the Swiper instance using a callback ref
                    swiperRef.current = swiper as any;
                  }}
                  className={`mySwiper w-full pt-4 z-[1] relative`}
                  slidesPerView="auto"
                  spaceBetween={6}
                  slidesOffsetBefore={0}
                  slidesOffsetAfter={0}
                  freeMode={true}
                >
                  {eventList.map((evtObj: any, index: any) => (
                    <SwiperSlide key={evtObj?.event ?? ''} className="w-auto">
                      <Button
                        className="border-2 rounded-[12px] bg-[white]"
                        onClick={(e) => {
                          setCurrEvent(eventList[index]);
                          handleButtonClick(evtObj?.uniqueId ?? '');
                        }}
                        key={evtObj?.event ?? ''}
                        style={{
                          backgroundColor: evtObj?.event === currEvent?.event ? '#FF6D00' : 'white',
                          color: evtObj?.event === currEvent?.event ? 'white' : 'black',
                        }}
                      >
                        <div className="flex gap-[5px] items-center w-fit">
                          <div>{evtObj?.renamedEvent ?? ''}</div>
                        </div>
                      </Button>
                    </SwiperSlide>
                  ))}
                </Swiper>

                <button
                  className="absolute left-[0px] z-[2] px-[5px] py-[20px] bg-[black] bg-opacity-30 rounded-br-[20px] rounded-tr-[20px]"
                  onClick={handlePrev}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="h-8 w-8 text-white"
                  >
                    <path d="M15 19l-7-7 7-7z" />
                  </svg>
                </button>
                <button
                  className="absolute right-[0px] z-[2] px-[5px] py-[20px] bg-[black] bg-opacity-30 rounded-bl-[20px] rounded-tl-[20px]"
                  onClick={handleNext}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    className="h-8 w-8 text-white"
                  >
                    <path d="M9 5l7 7-7 7z" />
                  </svg>
                </button>
              </div>
            </div>
          )}
          <div className="flex flex-col justify-center w-full max-w-md side-padding bg-[white] mt-[100px] pb-[7px]">
            <>
              <div
                className="flex flex-row-reverse w-full items-center px-4 h-12 mt-2
                border border-[#FF6400] rounded bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
              >
                <Button
                  className="bg-[#FF6400] text-[#FFFFFF] rounded-[12px]"
                  style={{ height: '80%' }}
                  onClick={(e) => handleAccommSearchButton(e)}
                >
                  검색
                </Button>
                {accommSearchInput ? (
                  <>
                    <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                      <Icon.X
                        className="wh-3"
                        onClick={() => {
                          setAccommSearchInput('');
                        }}
                      />
                    </div>
                  </>
                ) : null}
                <input
                  className="flex-1 text-sm bg-white"
                  placeholder="검색하기"
                  onChange={(e) => handleAccommSearchInput(e)}
                  value={accommSearchInput as string}
                  onKeyDown={(e) => handleSearchKeyDown(e)}
                />
              </div>
            </>
          </div>
          <div>
            <>
              {checkAccommsNumb(eventList) ? (
                <>
                  {eventList.map((evtObj: any) => (
                    <div key={evtObj?.uniqueId} id={evtObj?.uniqueId}>
                      {Object.keys(evtObj?.data)?.length > 0 ? (
                        <div>
                          <p className="w-full text-center text-[25px] font-[700] mb-[5px] animate-character1">
                            {evtObj?.renamedEvent ?? ''}
                          </p>
                          {/* <div className="animate-character1 max-w-md">YOUR_TEXT_HERE</div> */}
                          {evtObj?.data && Object.keys(evtObj?.data) ? (
                            <>
                              {Object.keys(evtObj?.data)?.map((key) => (
                                <div key={evtObj?.event + key}>
                                  <p className="text-center text-[20px] font-[500] mb-[5px]">
                                    {key && key != 'null' && key}
                                  </p>
                                  {evtObj?.data[key] ? (
                                    <>
                                      {evtObj?.data[key]?.map((accObj: any) => (
                                        <div
                                          id={
                                            evtObj?.uniqueId +
                                            '-' +
                                            accObj?.name.replace(/\s/g, '').trim()
                                          }
                                          className="side-padding"
                                          key={evtObj?.event + key + accObj?.name}
                                        >
                                          <div className="relative">
                                            <img
                                              className="w-full max-h-[300px] object-cover"
                                              src={accObj?.mainImageUrl ?? ''}
                                              onClick={() => {
                                                window.open(accObj?.link ?? '', '_blank');
                                              }}
                                            />
                                            {accObj?.maxDiscountRate > 0 && (
                                              <>
                                                <div className="absolute top-[5px] right-[5px] px-[13px] py-[10px] h-fit w-fit rounded-[5px] bg-[#f94c86] text-[white]">
                                                  {'~' + accObj?.maxDiscountRate + '%' + ''}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                          <div className="my-[10px]">
                                            <div>{accObj?.shortAddr}</div>
                                            <div className="font-[700] text-[17px]">
                                              {accObj?.name}
                                            </div>
                                          </div>
                                          <div className="flex gap-[5px] mb-[25px]">
                                            <div
                                              className="w-fit px-[5px] py-[2px] border-[1px] rounded-[9px]"
                                              onClick={() => {
                                                window.open(accObj?.link ?? '', '_blank');
                                              }}
                                            >
                                              바로가기
                                            </div>
                                            <CopyToClipboard
                                              text={
                                                window.location.origin +
                                                '/community/accomms-w-disc#' +
                                                evtObj?.uniqueId +
                                                '-' +
                                                accObj?.name.replace(/\s/g, '').trim()
                                              }
                                              onCopy={() => {
                                                toast.success('링크가 복사되었습니다');
                                              }}
                                            >
                                              <div className="w-fit px-[5px] py-[2px] border-[1px] rounded-[9px]">
                                                숙소 공유
                                              </div>
                                            </CopyToClipboard>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <div className="flex justify-center items-center"></div>
                      )}
                    </div>
                  ))}
                  <div className="px-[10px] text-[black] text-[10px]" style={{}}>
                    해당 게시물은 트립닷컴 제휴마케팅의 일환으로, 이에 따른 일정 수수료를
                    트립닷컴으로 제공받고 있습니다.
                  </div>
                </>
              ) : (
                <div className="text-center text-[25px]">검색결과가 없습니다</div>
              )}
            </>
          </div>
          <div className="absolute bottom-10 right-0 w-[30px] h-[30px] z-[1000]">
            <div className="flex justify-end max-w-md">
              <div
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                className="fixed bottom-10 w-[60px] h-[60px] mr-5 rounded-[50px] bg-[#06A54A] text-[white] font-[300] text-[1.5em] 
        flex justify-center items-center cursor-pointer"
              >
                <IconAngleDoubleTop fill="#FFFFFF" className="w-[35px] h-[35px]" />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center font-[700] text-[25px]">페이지 준비중입니다</div>
      )}
    </div>
  );
}
