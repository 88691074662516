import { logout } from '../../../../api_v2/non_admin/users';

import { Button } from '../../../../components/Button';
import { Icon } from '../../../../components/Icon';
import { TopTitleBar } from './components/TopTitleBar';

export const MyPage = () => {
  const handleLogout = () => {
    logout();
    window.location.href = '/community';
  };
  return (
    <>
      <TopTitleBar backButton={true} logo={true} home={false} />
      <h2 className="pb-8 pt-4 side-padding">마이페이지</h2>
      <div className="max-w-md w-screen side-padding bg-[#FFFFFF] divide-y">
        <div className="flex justify-between leading-10 mb-10 items-center">
          <div>게시글</div>
          <button
            onClick={() => {
              window.location.href = '/community/mypage/posts';
            }}
          >
            <Icon.ChevronRight />
          </button>
        </div>
        <div className="flex justify-between leading-10 mb-10 items-center">
          <div>댓글</div>
          <button
            onClick={() => {
              window.location.href = '/community/mypage/posts-comments';
            }}
          >
            <Icon.ChevronRight />
          </button>
        </div>
        <div className="flex justify-center">
          <Button
            className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                    flex-1 flex justify-center items-center max-w-sm"
            onClick={() => handleLogout()}
          >
            로그아웃
          </Button>
        </div>
      </div>
    </>
  );
};
