import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

export const CustomDrawer = (props: any) => {
  function closeDrawer() {
    if (props.drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `auto`;

      props.drawerRef.current.style.width = '0';
      props.drawerRef.current.style.height = '0';
    }
  }

  return (
    <>
      <div
        id="general-drawer"
        className="drawer max-w-md border border-[#EEEEEE]"
        ref={props.drawerRef}
      >
        <div
          className="drawerHeader 
        grid grid-cols-3 place-items-center 
        border-b-2 border-[#EEEEEE]
        fixed max-w-md w-full bg-[white]
        "
        >
          {props.centerText ? (
            <div className="drawerCenterText col-start-2 text-[25px]">{props.centerText}</div>
          ) : null}
          {props.refreshButton ? (
            <a
              className="drawerRefreshbtn place-self-start self-center ml-4 text-[25px]"
              onClick={() => {
                props.handleRefreshButton();
              }}
            >
              &#x21bb;
            </a>
          ) : null}
          <a
            className="drawerClosebtn col-start-3 place-self-end mr-4 text-[35px]"
            onClick={() => closeDrawer()}
          >
            &times;
          </a>
        </div>
        <div>{props.children}</div>
        {/* {props.confirmButton && (
          <div className="flex justify-center bg-gray-100" onClick={() => closeDrawer()}>
            확인
          </div>
        )} */}
      </div>
    </>
  );
};
