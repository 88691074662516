import { Button as AntdButton, Checkbox, Form, Input, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { parse, stringify } from 'qs';

import moment from 'moment';

import { ReactComponent as ThreeDots } from '../../../../../assets/icons/icon-3-dots.svg';

import { CustomDrawer } from './CustomDrawer';
import { Section } from '../../../../../components/Section';
import { Calendar } from './Calendar';
import { Icon } from '../../../../../components/Icon';
import { TopTitleBar } from './components/TopTitleBar';
import { Button } from '../../../../../components/Button';
import {
  checkPasswordToEditConveyanceInfo,
  getConveyanceInfo,
} from '../../../../../api_v2/non_admin/conveyance';
import { EditConveyanceInfo } from './EditConveyanceInfo';
import { authUserTokenObjState, meState } from '../../../../../ridge/ridge';

const conveyancePriceTypeObjFromApi: { [key: string]: any } = {
  regular: '정가',
  discount: '할인',
  premium: '프리미엄',
};

const conveyancePriceTypeObjForApi: { [key: string]: any } = {
  REGULAR: 'regular',
  DISCOUNTED: 'discount',
  PREMIUM: 'premium',
};

export const ConveyancePage = (props: any) => {
  const me = meState.useValue();
  const authUserTokenObj = authUserTokenObjState.useValue();

  const drawerRef = useRef<HTMLDivElement | null>(null);
  const termDrawerRef = useRef<HTMLDivElement | null>(null);

  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [passwordForm] = Form.useForm();
  const history = useHistory();

  const { conveyanceId } = useParams<{ conveyanceId: string }>();

  const [termCheckbox, setTermCheckbox] = useState(false);

  const [calendarState, setCalendarState] = useState<any>([
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: new Date(parsedSearch?.start_date as string),
          endDate: new Date(parsedSearch?.end_date as string),
          key: 'selection',
        }
      : {
          startDate: null,
          endDate: new Date(''),
          key: 'selection',
        },
  ]);

  const [locale, setLocale] = useState<any>('ko');

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const [checkPasswordStatus, setCheckPasswordStatus] = useState(false);

  const closeDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '0';
      drawerRef.current.style.height = '0';
    }
  };

  const [chosenPassword, setChosenPassword] = useState<any | null>(null);

  const passwordOnFinish = async (values: any) => {
    if (values?.password?.trim()?.length > 0) {
      let checkPasswordRes = await checkPasswordToEditConveyanceInfo(conveyanceInfo?.id, {
        password: values?.password,
      });
      if (checkPasswordRes == true) {
        setCheckPasswordStatus(checkPasswordRes);
        setChosenPassword(values?.password);
        passwordForm.resetFields();
        closeDrawer();
      } else {
        toast('비밀번호를 확인해주세요');
      }
    } else {
      toast('비밀번호를 입력해주세요');
    }
  };

  const [conveyanceInfo, setConveyanceInfo] = useState<any | null>(null);

  useEffect(() => {
    getConveyanceInfo(parseInt(conveyanceId), '').then((res) => {
      if (res?.success && res?.data) {
        setConveyanceInfo(res.data);
      }
    });
  }, [checkPasswordStatus]);

  const [calculatedTime, setCalculatedTime] = useState<any | null>(null);

  useEffect(() => {
    if (conveyanceInfo?.createdAt) {
      let tSplit = conveyanceInfo?.createdAt?.split('T');
      let yrMoDtArr = tSplit[0];
      let yr,
        mon,
        dt = null;
      if (yrMoDtArr) {
        yr = new Date(yrMoDtArr).getFullYear();
        mon = new Date(yrMoDtArr).getMonth();
        dt = new Date(yrMoDtArr).getDate();
      }

      let tSplit2nd = tSplit[1];

      let hr,
        min,
        sec = null;

      if (tSplit2nd) {
        let tSplit2ndSplit = tSplit2nd?.split('.');

        if (tSplit2ndSplit[0]) {
          let hrMiSeStrSplit = tSplit2ndSplit[0]?.split(':');

          hr = hrMiSeStrSplit[0];
          min = hrMiSeStrSplit[1];
          sec = hrMiSeStrSplit[2];
        }
      }

      if (yr && mon && dt && hr && min && sec) {
        // year, monthIndex, day, hours, minutes, seconds, milliseconds
        let convertedDate = moment(new Date(yr, mon, dt, hr, min, sec))?.fromNow();

        if (convertedDate) {
          setCalculatedTime(convertedDate);
        }
      }
    }
  }, [conveyanceInfo]);

  const dataToSend = () => {
    if (conveyanceInfo) {
      let finalStr =
        (conveyanceInfo?.gocamping?.doName ?? '') +
        ' ' +
        (conveyanceInfo?.gocamping?.facilityName ?? '') +
        ' ' +
        (conveyanceInfo?.checkinDate && conveyanceInfo?.checkoutDate
          ? conveyanceInfo?.checkinDate + ' ~ ' + conveyanceInfo?.checkoutDate
          : '') +
        ' ' +
        (conveyanceInfo?.transferAmount?.toLocaleString()
          ? conveyanceInfo?.transferAmount?.toLocaleString() + '원'
          : '');
      return finalStr;
    } else return '';
  };

  const openDrawer = () => {
    passwordForm.resetFields();
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '50%';
    }
  };

  const openTermDrawer = () => {
    if (termDrawerRef.current) {
      termDrawerRef.current.style.width = '100%';
      termDrawerRef.current.style.height = '80%';
    }
  };

  const checkPasswordToEdit = (conveyanceInfoData: any) => {
    openDrawer();
  };

  const calculateDDay = (endDate: any) => {
    let today = new Date();
    let yr = new Date(today).getFullYear();
    let mon = new Date(today).getMonth() + 1;
    let dt = new Date(today).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    let todayDate = yr + '-' + modifiedMon + '-' + modifiedDt;
    let days = [];

    if (new Date(todayDate) <= new Date(endDate)) {
      let date = new Date(todayDate);
      while (date <= new Date(endDate)) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return {
        status: true,
        days: typeof days?.length == 'number' && days?.length - 1 >= 0 ? days?.length - 1 : 0,
      };
    } else return { status: false };
  };

  const handleTextMessage = (conveyanceInfoData: any) => {
    if (termCheckbox) {
      if (conveyanceInfoData?.status == true) {
        toast.error('양도가 완료된 양도정보입니다');
      } else {
        window.location.href = `sms:${conveyanceInfoData?.tel};?&body=${dataToSend()}`;
      }
    } else {
      toast.error('양도거래 유의사항에 동의후 이용가능합니다');
    }
  };

  const handleLoggedInUserConveyanceInfo = () => {
    history.push(`/v2/camping/conveyance/conveyanceinfo/${conveyanceInfo?.id}/edit/member`);
  };

  const handleTermCheckbox = (evt: any) => {
    setTermCheckbox(evt?.target?.checked);
  };

  return (
    <>
      <TopTitleBar userAuthIcon={true} logo={true} home={false} />
      <div className="max-w-md w-screen side-padding bg-[#FFFFFF]">
        {conveyanceInfo && !checkPasswordStatus ? (
          <>
            {conveyanceInfo?.gocamping?.firstImageUrl ? (
              <div>
                <img className="w-full h-[270px]" src={conveyanceInfo?.gocamping?.firstImageUrl} />
              </div>
            ) : null}
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              {/* <div className="font-[700] text-[15px] mb-3">양도 정보</div> */}
              <div className="flex justify-end">
                <div className="cursor-pointer w-[25px] h-[35px]">
                  <ThreeDots
                    className="w-full h-full"
                    fill="black"
                    onClick={() => {
                      checkPasswordToEdit(conveyanceInfo);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="font-[700] text-[18px]">{conveyanceInfo?.nickname}</div>
                <div className="text-[#6F6F6F] text-[12px]">
                  {calculatedTime}
                  {/* {moment(new Date(2022, 9, 18, 21, 14, 12, 12))?.fromNow() ?? null} */}
                </div>
              </div>
            </Section>

            <div className="bg-gray-100 w-full border my-3" />
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <div className="font-[700] text-[15px] mb-3">양도 정보</div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">캠핑장명</div>
                <div className="col-span-4">{conveyanceInfo?.gocamping?.facilityName}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">구역명</div>
                <div className="col-span-4">{conveyanceInfo?.zoneName}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">사이트명</div>
                <div className="col-span-4">{conveyanceInfo?.siteName}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">캠핑장 지역</div>
                <div className="col-span-4 flex">
                  {conveyanceInfo?.gocamping?.doName ? (
                    <div className="mr-1">{conveyanceInfo?.gocamping?.doName}</div>
                  ) : null}
                  {conveyanceInfo?.gocamping?.sigunguName ? (
                    <div>{conveyanceInfo?.gocamping?.sigunguName}</div>
                  ) : null}
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">일정</div>
                <div className="col-span-4">
                  {conveyanceInfo?.checkinDate && conveyanceInfo?.checkoutDate
                    ? conveyanceInfo?.checkinDate + ' ~ ' + conveyanceInfo?.checkoutDate
                    : null}
                </div>
              </div>

              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">양도비용</div>
                <div className="col-span-4 flex items-center">
                  <div className="mr-2">
                    {conveyanceInfo?.transferAmount?.toLocaleString() ?? 0}원
                  </div>

                  {conveyanceInfo?.amountTag &&
                    conveyancePriceTypeObjFromApi[conveyanceInfo.amountTag] && (
                      <div className="bg-[#c7fc86] px-2 py-1 rounded-[5px]">
                        {conveyancePriceTypeObjFromApi[conveyanceInfo.amountTag]}
                      </div>
                    )}
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">특이사항</div>
                <div className="col-span-4 pt-1">
                  <textarea readOnly rows={5} className="w-full">
                    {conveyanceInfo?.description}
                  </textarea>
                </div>
              </div>
            </Section>
            <a className="flex justify-center text-[#5986f7]" onClick={() => openTermDrawer()}>
              양도거래 유의사항
            </a>
            <a
              onClick={() => {
                handleTextMessage(conveyanceInfo);
              }}
              // href={`sms:${conveyanceInfo?.tel};?&body=${dataToSend()}`}
            >
              <Button
                className="w-full my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                    flex-1 flex justify-center items-center"
              >
                문자보내기
              </Button>
            </a>
          </>
        ) : null}
        {checkPasswordStatus && (
          <>
            <EditConveyanceInfo
              conveyanceInfo={conveyanceInfo}
              chosenPassword={chosenPassword}
              setCheckPasswordStatus={setCheckPasswordStatus}
            />
          </>
        )}
      </div>
      <div>
        <CustomDrawer drawerRef={drawerRef} confirmButton={false}>
          {/* <div>{htmlReactParser(reservationData?.bookingInfo?.camping?.refund_policy ?? '')}</div> */}
          <Section className="max-w-md py-3 bg-[#FFFFFF] flex justify-center items-center mt-[75px]">
            {authUserTokenObj && me?.userInfo?.user_id == conveyanceInfo?.userId ? (
              <div className="flex flex-col items-center">
                <AntdButton
                  // type="text"
                  id="booking-page-button"
                  className="w-full my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
          flex-1 flex justify-center items-center"
                  // disabled={addConveyanceInfoStatus}
                  onClick={() => handleLoggedInUserConveyanceInfo()}
                >
                  수정하기
                </AntdButton>
              </div>
            ) : (
              <>
                <Form
                  form={passwordForm}
                  className="flex flex-col items-center"
                  name="dynamic_rule"
                  onFinish={passwordOnFinish}
                >
                  <div className="text-[16px]">비밀번호를 입력해야 수정하실수있습니다</div>
                  <div className="mt-3">
                    <Form.Item
                      className=""
                      name="password"
                      rules={[{ required: true, message: '비밀번호를 입력해주세요' }]}
                    >
                      <Input.Password style={{ maxHeight: '30px' }} />
                    </Form.Item>
                    <Form.Item noStyle>
                      <AntdButton
                        // type="text"
                        id="booking-page-button"
                        className="w-full my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                    flex-1 flex justify-center items-center"
                        htmlType="submit"
                        // disabled={addConveyanceInfoStatus}
                      >
                        수정하기
                      </AntdButton>
                    </Form.Item>
                  </div>
                </Form>
              </>
            )}
          </Section>
        </CustomDrawer>
        <CustomDrawer drawerRef={termDrawerRef} confirmButton={false}>
          <div className="mt-[80px] side-padding">
            <h1 className="mt-[80px] side-padding">양도거래 유의사항</h1>
            <div className="border rounded-[20px] p-[15px] mt-[5px]">
              <div className="mt-[5px] grid grid-cols-10">
                <div className="col-span-1">-</div>
                <div className="col-span-9">
                  양도자의 사기거래로 인하여 캠핑장에 입실을 못하시는 경우, 해당여부를 파악하여
                  예약양도 결제금액에 한하여 전액취소처리가 가능합니다.
                </div>
              </div>
              <div className="mt-[5px] grid grid-cols-10">
                <div className="col-span-1">-</div>
                <div className="col-span-9">
                  <p>
                    캠핑장의 공지사항/유의사항 등을 숙지하지 않고 캠핑장에 입실하여 발생되는 사고 및
                    분쟁에 대하여 데이아웃은 책임사유가 없음을 알려 드립니다.
                  </p>
                  <p>(예 : 반려견 입장 불가/최대인원 제한/입,퇴실시간 준수 등)</p>
                </div>
              </div>
            </div>
            <div className="mt-[7px] flex">
              <input
                type="checkbox"
                name="termCheckbox"
                checked={termCheckbox}
                onChange={(e) => handleTermCheckbox(e)}
                className="mr-[6px] mt-[5px]"
              />
              <div>캠핑장 공지사항을 확인하였으며, 양도거래 유의사항에 동의합니다.</div>
            </div>
          </div>
        </CustomDrawer>
      </div>
    </>
  );
};
