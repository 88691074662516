import { useEffect, useState, useRef, useCallback } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import { parse, stringify } from 'qs';

import { default as htmlReactParser, attributesToProps } from 'html-react-parser';

import dayjs from 'dayjs';

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import { TopTitleBar } from './components/TopTitleBar';
import { Section } from '../../../../components/Section';

import { Icon } from '../../../../components/Icon';
import { Button } from '../../../../components/Button';
import { CustomDrawer } from './components/CustomDrawer';

import { toast } from 'react-toastify';

import { ReactComponent as IconLocation } from '../../../../assets/icons/icon-location.svg';
import { ReactComponent as IconSend } from '../../../../assets/icons/icon-send.svg';
import { ReactComponent as IconNoPicture } from '../../../../assets/icons/icon-no-picture.svg';
import { ReactComponent as IconEnlarge } from '../../../../assets/icons/icon-enlarge.svg';
import { ReactComponent as CopySVG } from '../../../../assets/icons/icon-copy.svg';

import axios, { AxiosError, AxiosResponse } from 'axios';
import { api } from '../../../../plugins/axios';
import { authUserTokenObjState, popupType1State, userObjState } from '../../../../ridge/ridge';
import {
  createCommunityPostComment,
  getCommunityPost,
} from '../../../../api_v2/non_admin/community';
import { useInfiniteQuery, useQuery } from 'react-query';
import { map } from 'lodash';
import { CardType2 } from './components/CardType2';
import PostComment from './PostComment';

import * as linkify from 'linkifyjs';
import Linkify from 'linkify-react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as regularBookmark } from '@fortawesome/free-regular-svg-icons';
import RecommendVKTourDests from './RecommendVKTourDests';
import RecommendVKTourDest from './RecommendVKTourDest';

SwiperCore.use([Pagination, Autoplay]);

enum DrawerType {
  POST_COMMENT_DETAIL = 'post_comment_detail',
  POST_RECOMMEND_VK_TOUR_DESTS = 'post_recommend_vk_tour_dests',
}

const categoryNameObj: { [key: string]: any } = {
  domesticTravel: '국내여행',
  internationalTravel: '해외여행',
  domesticFestival: '국내축제',
  internationalFestival: '해외축제',
  etc: '기타',
};

export const Post = (props: any) => {
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const [drawerType, setDrawerType] = useState<any>(null);
  const history = useHistory();
  const { search } = useLocation();

  const { postId } = useParams<{ postId: string }>();
  const [campingInfo, setCampingInfo] = useState<any>(null);

  const [expandCampingIntro, setExpandCampingIntro] = useState(false);

  const campingRefundPolicyDrawerRef = useRef<HTMLDivElement | null>(null);

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [calendarState, setCalendarState] = useState<any>([
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: new Date(parsedSearch?.start_date as string),
          endDate: new Date(parsedSearch?.end_date as string),
          key: 'selection',
        }
      : {
          startDate: null,
          endDate: new Date(''),
          key: 'selection',
        },
  ]);

  const [initialLoadingForCampingInfo, setInitialLoadingForCampingInfo] = useState<boolean>(true);
  const [initialLoadingForRoomtypes, setInitialLoadingForRoomtypes] = useState<boolean>(true);

  // const [communityPost, setCommunityPost] = useState<any>(null);

  const [addCommunityPostCommentStatus, setAddCommunityPostCommentStatus] = useState(false);

  // useEffect(() => {
  //   getCommunityPost(postId)
  //     .then((res: any) => {
  //       if (res.success == true) {
  //         setCommunityPost(res?.data);
  //       }
  //     })
  //     .catch((e: any) => {});
  //   setCampingInfo({});
  //   setInitialLoadingForCampingInfo(false);
  //   setInitialLoadingForRoomtypes(false);
  // }, []);

  const fetchCommunityPost = async () => {
    try {
      let result = await api.get(`/community/posts/${postId}`);
      let finalRes;
      if ([200, 201].includes(result?.status)) {
        finalRes = result?.data;
      } else {
        finalRes = null;
      }
      setCampingInfo({});
      setInitialLoadingForCampingInfo(false);
      setInitialLoadingForRoomtypes(false);
      return finalRes;
    } catch (e) {
      return null;
    }
  };

  const {
    data: communityPost,
    isLoading: isCommunityPostLoading,
    refetch: reFetchCommunityPost,
  } = useQuery(
    // to identify each case
    'd8353fd9-2ff3-4d9a-b69b-83c32f2f8f8c',
    fetchCommunityPost,
    {
      refetchOnWindowFocus: false,
    }
  );

  // export const getCommunityPost = async (postId: any) => {
  //   try {
  //     let result = await api.get(`/community/posts/${postId}`);
  //     let possibleStatus = [200, 201];
  //     if (possibleStatus.includes(result?.status)) {
  //       return { success: true, data: result.data };
  //     } else return { success: false };
  //   } catch (e) {
  //     console.log('e', e);
  //     return null;
  //   }
  // };

  const getClassifications = (classificationsArr: any) => {
    let finalStr = '';

    classificationsArr.forEach((cf: any, index: any) => {
      let addStr = index == 0 ? cf : ` | ` + cf;
      finalStr = finalStr + addStr;
    });
    return finalStr;
  };

  const [selectedPropDetailInfoNav, setSelectedPropDetailInfoNav] = useState<any>(null);

  const [selectedPropNavLv1, setSelectedPropNavLv1] = useState<any>(null);

  const stopPopupType1 = () => {
    localStorage.setItem('stopPopupType1', JSON.stringify(true));
  };

  const nextPopupType1 = () => {
    let nextTime = dayjs().add(2, 'day');
    localStorage.setItem('nextPopupType1', JSON.stringify(nextTime));
  };

  const removeNextPopupType1 = () => {
    localStorage.removeItem('nextPopupType1');
  };

  const checkPopupType1 = () => {
    let lsNextPopupType1 = localStorage.getItem('nextPopupType1') as any;
    let lsStopPopupType1 = localStorage.getItem('stopPopupType1') as any;

    return { lsNextPopupType1, lsStopPopupType1 };
  };
  const jsonParseFunc = (jsonStrData: any) => {
    try {
      let parsed = JSON.parse(jsonStrData);
      return parsed;
    } catch (e) {
      console.log('e', e);
      return null;
    }
  };

  useEffect(() => {
    if (props.popupType1) {
      const onClose = () => {
        popupType1State.set({ ...popupType1State.get(), open: false });
        nextPopupType1();
      };

      if (
        checkPopupType1()?.lsStopPopupType1?.length > 0 &&
        checkPopupType1()?.lsStopPopupType1 == 'true'
      ) {
      } else {
        let setObj: any = { open: true, onClose };
        if (checkPopupType1()?.lsNextPopupType1) {
          let parsedTime = jsonParseFunc(checkPopupType1()?.lsNextPopupType1);
          if (parsedTime) {
            let diffTime = dayjs(parsedTime).diff(dayjs(), 'day');

            if (diffTime <= 0) {
              setObj.onClick = async () => {
                stopPopupType1();
                removeNextPopupType1();
                window.location.href = 'https://open.kakao.com/o/gSomAGjf';
              };
              popupType1State.set(setObj);
            }
          }
        } else {
          setObj.onClick = async () => {
            stopPopupType1();
            window.location.href = 'https://open.kakao.com/o/gSomAGjf';
          };
          popupType1State.set(setObj);
        }
      }
    }
  }, [props.popupType1]);

  const [communityPostComment, setCommunityPostComment] = useState<any>('');

  const handleCommunityPostCommentChange = (e: any) => {
    setCommunityPostComment(e.target.value);
  };

  const handleCommunityPostComment = async () => {
    setAddCommunityPostCommentStatus(true);
    if (communityPostComment.trim()) {
      if (window.confirm('댓글을 등록하시겠습니까?')) {
        let createCommunityPostCommentRes = await createCommunityPostComment(postId, {
          content: communityPostComment,
        });
        if (createCommunityPostCommentRes?.success == true) {
          refetchCommunityPostComments();
          toast.success('성공적으로 등록하였습니다');
        } else {
          toast.error('오류가 발생하였습니다');
        }
      }
    } else {
      window.confirm('댓글을 작성해주세요');
    }
    setAddCommunityPostCommentStatus(false);
  };

  const [pageLimit, setPageLimit] = useState(5);

  const fetchCommunityPostComments = async ({ pageParam = 1 }) => {
    let currentQueryStringObj: { [key: string]: any } = {
      page: pageParam,
      limit: pageLimit,
      filter: { status: true, isVisible: true },
      sort: { createdAt: 'DESC' },
    };

    for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
      let key = Object.keys(parsedSearch)[i];
      if (key === 'search_query') {
        currentQueryStringObj.filter['searchQuery'] = parsedSearch[key];
      } else if (key === 'category') {
        currentQueryStringObj.filter['category'] = parsedSearch[key];
      }
    }

    // if (parsedSearch?.start_date && parsedSearch?.end_date) {
    //   currentQueryStringObj.filter['checkin'] = convertDateForQS(parsedSearch?.start_date);
    //   currentQueryStringObj.filter['checkout'] = convertDateForQS(parsedSearch?.end_date);
    // }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
      arrayFormat: 'brackets',
    });

    // if (pageParam == 1) {
    //   setInitialLoading(true);
    //   loadingDots(true, 'initial-loading-container');
    // } else {
    //   loadingDots(true, 'more-loading-container');
    // }

    let result = await api.get(`/community/posts/${postId}/comments` + currentQueryString);

    // if (pageParam == 1) {
    //   setInitialLoading(false);
    //   loadingDots(false, 'initial-loading-container');
    // } else {
    //   loadingDots(false, 'more-loading-container');
    // }

    if ([200].includes(result?.status)) {
      return result;
    } else return null;
  };

  const {
    data: communityPostCommentsData,
    fetchNextPage,
    isLoading: isCommunityPostCommentsDataLoading,
    refetch: refetchCommunityPostComments,
  } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`communityPostCommentsPage`],
    fetchCommunityPostComments as any,
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        if (lastPage?.data?.items?.length > 0) {
          return nextPage;
        } else if (lastPage?.data?.items?.length == 0) {
          return undefined;
        } else return allPages?.length;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const observer = useRef<IntersectionObserver>();

  const lastElement = useCallback(
    (target) => {
      if (isCommunityPostCommentsDataLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && fetchNextPage) {
            fetchNextPage();
          } else {
          }
        },
        {
          rootMargin: '500px',
          threshold: 0.1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [isCommunityPostCommentsDataLoading]
  );

  const openDrawer = (drawerTypeData: any, heightData?: any) => {
    try {
      setDrawerType(drawerTypeData);
      if (drawerRef.current) {
        let bodyEle = document.querySelector('body') as any;
        bodyEle.style['pointer-events'] = `none`;

        (drawerRef.current as any).style['pointer-events'] = 'auto';

        drawerRef.current.style.width = '100%';
        drawerRef.current.style.height = heightData ?? '100%';
      }
    } catch (e) {}
  };

  const closeDrawer = () => {
    try {
      if (drawerRef.current) {
        let bodyEle = document.querySelector('body') as any;
        bodyEle.style['pointer-events'] = `auto`;

        drawerRef.current.style.width = '0';
        drawerRef.current.style.height = '0';
      }
    } catch (e) {}
  };

  const [chosenPostCommentDetail, setChosenPostCommentDetail] = useState<any>(null);

  const [contentTimePassed, setContentTimePassed] = useState<any>(null);

  useEffect(() => {
    if (communityPost) {
      const calculateTimeAgo = (timeData: any) => {
        const textWrittenTime = timeData as any;
        const currentTime = new Date() as any;
        const timeDifference = currentTime - textWrittenTime;

        const secondsPassed = Math.floor(timeDifference / 1000);
        const minutesPassed = Math.floor(secondsPassed / 60);
        const hoursPassed = Math.floor(minutesPassed / 60);
        const daysPassed = Math.floor(hoursPassed / 24);
        const yearsPassed = Math.floor(daysPassed / 365);

        if (yearsPassed > 0) {
          return `${yearsPassed} 년${yearsPassed > 1 ? '' : ''} 전`;
        } else if (daysPassed > 0) {
          return `${daysPassed} 일${daysPassed > 1 ? '' : ''} 전`;
        } else if (hoursPassed > 0) {
          return `${hoursPassed} 시간${hoursPassed > 1 ? '' : ''} 전`;
        } else if (minutesPassed > 0) {
          return `${minutesPassed} 분${minutesPassed > 1 ? '' : ''} 전`;
        } else {
          return `${secondsPassed} 초${secondsPassed > 1 ? '' : ''} 전`;
        }
      };

      const dateString = communityPost.createdAt;
      const parts = dateString.split(/[-T:.]/);
      const year = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Months are 0-indexed
      const day = parseInt(parts[2]);
      const hour = parseInt(parts[3]);
      const minute = parseInt(parts[4]);
      const second = parseInt(parts[5]);
      const millisecond = parseInt(parts[6]);

      const localDate = new Date(year, month, day, hour, minute, second, millisecond);

      // setContentTimePassed(calculateTimeAgo(localDate));
      setContentTimePassed(calculateTimeAgo(new Date(communityPost.createdAt)));
    }
  }, [communityPost]);

  const authUserTokenObj = authUserTokenObjState.useValue();

  const changeImgObjectFit = (slideImage: any) => {
    try {
      if (slideImage) {
        const imgElement = slideImage as any;
        const width = imgElement?.naturalWidth as any;
        const height = imgElement?.naturalHeight as any;

        if (imgElement && width >= 0 && height > 0) {
          // Calculate the aspect ratio
          const aspectRatio = width / height;

          // Set the object-fit property based on the aspect ratio
          if (aspectRatio > 1) {
            // Landscape image
            imgElement.style['object-fit'] = 'cover'; // Or any other object-fit value you prefer
          } else {
            // Portrait image or square image
            imgElement.style['object-fit'] = 'contain'; // Or any other object-fit value you prefer
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const slideImagesRef = useRef<any>([]);

  useEffect(() => {}, [slideImagesRef?.current]);

  const [isEnlargeOpen, setIsEnlargeOpen] = useState(false);

  const openEnlargeModal = () => {
    setIsEnlargeOpen(true);
  };

  const closeEnlargeModal = () => {
    try {
      let spEle = document?.querySelector('.mySwiper') as any;
      if (spEle) {
        spEle?.swiper?.slideTo(currentEnlargeImageIndex);
      }
      setIsEnlargeOpen(false);
    } catch (e) {}
  };

  const [currentEnlargeImageIndex, setCurrentEnlargeImageIndex] = useState(0);

  const nextEnlargeImage = () => {
    if (currentEnlargeImageIndex < communityPost?.images.length - 1) {
      setCurrentEnlargeImageIndex(currentEnlargeImageIndex + 1);
    }
  };

  const prevEnlargeImage = () => {
    if (currentEnlargeImageIndex > 0) {
      setCurrentEnlargeImageIndex(currentEnlargeImageIndex - 1);
    }
  };

  const [loadingProgress, setLoadingProgress] = useState<any>({});
  const [parsedContent, setParsedContent] = useState<any>(null);

  useEffect(() => {
    try {
      if (Object.keys(loadingProgress)?.length > 0) {
        for (let i = 0; i < Object.keys(loadingProgress)?.length; i++) {
          let key = Object.keys(loadingProgress)[i] as any;
          if (loadingProgress[key].progress == 100 && !loadingProgress[key].applied) {
            let imgEle = document.querySelector(`#image-${key}`) as any;
            let loadEle = document.querySelector(`#loader-${key}`) as any;
            imgEle.style.display = 'block';
            loadEle.style.display = 'none';
            loadingProgress[key].applied = true;
          }
        }
      }
    } catch (e) {}
  }, [loadingProgress]);

  useEffect(() => {
    try {
      if (communityPost?.content) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(communityPost?.content, 'text/html') as any;

        const loadImage = (image: any, index: any, loader: any) => {
          const img = new Image();
          img.src = image.src;

          img.onload = () => {
            setLoadingProgress((prevProgress: any) => ({
              ...prevProgress,
              [index]: { progress: 100, applied: false },
            }));
          };

          img.onerror = () => {
            console.error(`Error loading image ${index + 1}`);
          };
        };

        const images = Array.from(doc.querySelectorAll('img'));

        images.forEach((image: any, index: any) => {
          image.style.display = 'none';
          image.id = `image-${index}`;
          const loader = document.createElement('div');
          loader.id = `loader-${index}`;
          loader.style.width = '20px';
          loader.style.height = '20px';
          loader.style.display = 'flex';
          loader.style.justifyContent = 'center';
          loader.style.alignItems = 'center';
          loader.style.background =
            'linear-gradient(45deg, #eee 25%, transparent 25%, transparent 50%, #eee 50%, #eee 75%, transparent 75%, transparent)';
          loader.style.backgroundSize = '20px 20px';
          loader.style.animation = 'spin 1s linear infinite';

          image.parentNode.insertBefore(loader, image);
          loadImage(image, index, loader);
        });

        if (doc?.documentElement?.outerHTML) {
          setParsedContent(doc.documentElement.outerHTML);
        }
      }
    } catch (e) {}
  }, [communityPost?.content]);

  const [contentAccorButtonStatus, setContentAccorButtonStatus] = useState<boolean>(false);
  const [contentExpandStatus, setContentExpandStatus] = useState<boolean>(false);

  useEffect(() => {
    try {
      let communityPostContentEle = document.querySelector('#communityPostContent');
      if (communityPost?.content && parsedContent) {
        if (communityPostContentEle) {
          const height = (communityPostContentEle as any)?.offsetHeight;
          if (height >= 300) {
            (communityPostContentEle as any).style.height = '300px';
            (communityPostContentEle as any).style.overflow = 'hidden';
            (communityPostContentEle as any).style['border-bottom-radius'] = '7px';
            setContentAccorButtonStatus(true);
          }
        }
      }
    } catch (e) {}
  }, [communityPost?.content, parsedContent]);

  const handleContentExpand = async () => {
    try {
      let communityPostContentEle = document.querySelector('#communityPostContent');
      if (communityPost?.content) {
        if (communityPostContentEle) {
          if (!contentExpandStatus) {
            (communityPostContentEle as any).style.height = '100%';
            (communityPostContentEle as any).style.overflow = 'hidden';
          } else {
            (communityPostContentEle as any).style.height = '300px';
            (communityPostContentEle as any).style.overflow = 'hidden';
          }
        }
      }

      setContentExpandStatus(!contentExpandStatus);
    } catch (e) {}
  };

  const [postBookmarked, setPostBookmarked] = useState(false);

  const [changeBookmarkStatus, setChangeBookmarkStatus] = useState(false);

  const [processBookmarkData, setProcessBookmarkData] = useState<boolean>(false);

  const userObj = userObjState.useValue();

  const handleBookmark = async (typeData: any, postData: any) => {
    try {
      if (authUserTokenObj?.token) {
        setChangeBookmarkStatus(true);
        let actionData = postBookmarked ? 'remove' : 'add';
        let patchCommunityBookmarkRes = await api.patch('/community/bookmark', {
          type: typeData,
          action: actionData,
          data: { communityPostId: postData?.id },
        });
        if ([200, 201].includes(patchCommunityBookmarkRes?.status)) {
          setPostBookmarked(!postBookmarked);
          let successMsg =
            actionData == 'add' ? '관심목록에 저장하였습니다' : '관심목록에서 삭제하였습니다';
          toast.success(successMsg);
        } else if ([401].includes(patchCommunityBookmarkRes?.status)) {
          toast.error('로그인 후 이용 가능합니다');
        } else {
          reFetchCommunityPost();
          toast.error('오류가 발생하였습니다');
        }
        setChangeBookmarkStatus(false);
      } else {
        if (window.confirm('로그인 후 이용 가능합니다')) {
        }
      }
    } catch (e: any) {
      if ([e?.status, e?.response?.status].includes(401)) {
        toast.error('로그인 후 이용 가능합니다');
      }

      reFetchCommunityPost();
      setChangeBookmarkStatus(false);
    }
  };

  // const [getCommunityBookmarkForPostStatus, setGetCommunityBookmarkForPostStatus] =
  //   useState<boolean>(true);
  // const [bookmarkUsable, setBookmarkUsable] = useState<boolean>(false);

  // const getCommunityBookmarkForPost = async () => {
  //   setGetCommunityBookmarkForPostStatus(true);
  //   try {
  //     if (authUserTokenObj?.token) {
  //       let getCommunityBookmarkResp = await api.get('/community/bookmark?type=communityPost');

  //       if ([200, 201].includes(getCommunityBookmarkResp?.status)) {
  //         // setCommunityBookmarkForPost(getCommunityBookmarkResp?.data?.communityPosts);
  //         setGetCommunityBookmarkForPostStatus(false);
  //         setBookmarkUsable(true);

  //         return getCommunityBookmarkResp?.data?.communityPosts;
  //       } else if ([401].includes(getCommunityBookmarkResp?.status)) {
  //         // unauthorized
  //         setGetCommunityBookmarkForPostStatus(false);
  //         setBookmarkUsable(true);

  //         return null;
  //       } else {
  //         setGetCommunityBookmarkForPostStatus(false);

  //         return null;
  //       }
  //     } else {
  //       setGetCommunityBookmarkForPostStatus(false);
  //       setBookmarkUsable(true);
  //       return null;
  //     }
  //   } catch (e) {
  //     setGetCommunityBookmarkForPostStatus(false);
  //     return null;
  //   }
  // };

  // const { data: communityBookmarkForPost, isLoading: isCommunityBookmarkForPostLoading } = useQuery(
  //   // to identify each case
  //   '4f88307a-6306-484d-8d85-91ac2592c2cb',
  //   getCommunityBookmarkForPost,
  //   {
  //     // enabled: authUserTokenObj?.token ? true : false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  useEffect(() => {
    try {
      if (communityPost?.userBookmarksCommunityPostCommunityPosts?.length >= 0) {
        let foundData = communityPost?.userBookmarksCommunityPostCommunityPosts?.find(
          (bm: any) =>
            bm?.userId?.toString() == userObj?.userInfo?.user_id?.toString() &&
            bm?.communityPostId?.toString() == communityPost?.id?.toString()
        );
        if (foundData) {
          setPostBookmarked(true);
        }
      }
      setProcessBookmarkData(true);
    } catch (e) {}
  }, [communityPost?.userBookmarksCommunityPostCommunityPosts, userObj]);

  const [chosenRecommendVkTourDest, setChosenRecommendVkTourDest] = useState<any>(null);
  const [cpRecommVkTourDests, setCpRecommVkTourDests] = useState<any>(null);

  useEffect(() => {
    try {
      if (communityPost) {
        api
          .get(`/community/bookmark/post/check/member?communityPostId=${communityPost?.id}`)
          .then((res) => {
            if (res?.data?.recommVkTourDests) {
              setCpRecommVkTourDests(res.data?.recommVkTourDests ?? null);
            }
          })
          .catch((e: any) => {});
      }
    } catch (e) {}
  }, [communityPost]);

  return (
    <>
      <TopTitleBar backButton={true} logo={true} home={false} />

      {
        <>
          <div className="max-w-md w-screen bg-[#F6F6F6]">
            {initialLoadingForCampingInfo ? (
              <>
                <div className="flex justify-center bg-[white] pt-[100px]">
                  <div className="initial-loading-container-for-campinginfo text-[50px]">
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                  </div>
                </div>
              </>
            ) : !initialLoadingForCampingInfo && communityPost ? (
              <>
                <Section className="max-w-md bg-white">
                  {communityPost?.images?.length > 0 ? (
                    <Swiper
                      className="mySwiper bg-white"
                      pagination={false}
                      // autoplay={{
                      //   delay: 3000,
                      // }}
                      onAfterInit={(swiper) => {
                        try {
                          if (swiper?.el?.style) {
                            (swiper.el.style as any)['padding-bottom'] = '0px';
                            (swiper.el.style as any)['height'] = '300px';
                          }
                          setCurrentEnlargeImageIndex(swiper?.activeIndex ?? 0);
                        } catch (e) {}
                      }}
                      onPaginationRender={(swiper, paginationEl) => {
                        try {
                          if (paginationEl) {
                            paginationEl.style.position = 'absolute';
                            paginationEl.style.bottom = '0';
                            (paginationEl as any).style['padding-bottom'] = '15px';
                          }
                        } catch (e) {}
                      }}
                      onSlideChange={(swiper) => {
                        try {
                          setCurrentEnlargeImageIndex(swiper?.activeIndex ?? 0);
                        } catch (e) {}
                      }}
                    >
                      {communityPost?.images &&
                        communityPost?.images.map((imgObj: any, index2: any) => (
                          <div key={imgObj?.url + index2}>
                            <SwiperSlide className="h-full">
                              <img
                                ref={(el) => {
                                  if (el) {
                                    changeImgObjectFit(el);
                                  }
                                }}
                                className="object-cover w-full h-full max-h-[500px]"
                                src={imgObj.url}
                                // src={chooseImage(imgObj) ?? ''}
                              />
                            </SwiperSlide>
                          </div>
                        ))}
                      <div
                        className="absolute bottom-[5px] right-[10px] w-fit z-[1500] px-[8px] py-[1px] text-[white] rounded-[10px]"
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                      >
                        {(currentEnlargeImageIndex ?? 0) + 1} / {communityPost?.images?.length}
                      </div>
                    </Swiper>
                  ) : (
                    <div className="h-[230px] w-full flex justify-center items-center">
                      <IconNoPicture width={'60px'} height={'60px'} />
                    </div>
                  )}
                  {communityPost?.images?.length > 0 ? (
                    <button
                      className="
                    absolute top-[75px] right-[10px]
                    p-[5px]
                    rounded-[5px]            
                    z-[1000]"
                      onClick={openEnlargeModal}
                      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                    >
                      <IconEnlarge width={'20px'} height={'20px'} />
                    </button>
                  ) : null}

                  {isEnlargeOpen && (
                    <div className="absolute top-0 left-0 w-full h-screen flex items-center justify-center z-[2000]">
                      {/* Dark Overlay */}
                      <div
                        className="fixed top-0 left-0 w-full h-full bg-black opacity-50"
                        onClick={closeEnlargeModal}
                      ></div>
                      <div
                        className="modal-content max-w-md w-full h-[80vh] z-50"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        {communityPost?.images?.length > 0 ? (
                          <>
                            <div className="image-slider w-full">
                              <button
                                onClick={prevEnlargeImage}
                                className="
                                absolute top-[50%] left-[20px] 
                                bg-[white] opacity-[.9] 
                                w-[30px] h-[30px]
                                rounded-[17px]
                                text-[black]"
                              >
                                {'<'}
                              </button>
                              <img
                                className="w-full object-cover"
                                src={communityPost?.images[currentEnlargeImageIndex]?.url}
                                alt={`Image ${currentEnlargeImageIndex}`}
                              />
                              <button
                                onClick={nextEnlargeImage}
                                className="
                                absolute top-[50%] right-[20px]
                                bg-[white] opacity-[.9] 
                                w-[30px] h-[30px]
                                rounded-[17px]
                                text-[black]
                                "
                              >
                                {'>'}
                              </button>
                              <button
                                className="
                                absolute top-[20px] right-[10px]                                 
                                p-[5px]
                                w-[30px] h-[30px]
                                text-white font-bold rounded-[15px]"
                                onClick={closeEnlargeModal}
                                style={{ backgroundColor: 'rgba(255, 100, 0)' }}
                              >
                                X
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="h-[230px] w-full flex justify-center items-center">
                            <IconNoPicture width={'60px'} height={'60px'} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Section>

                <Section className="max-w-md side-padding pb-[20px] py-3 bg-[#FFFFFF]">
                  <div className="flex flex-wrap mt-3">
                    {communityPost?.categories &&
                      communityPost?.categories.map((category: any) => (
                        <div
                          className="flex justify-center items-center 
                  border border-[#D0D0D0] rounded p-1 text-[10px] w-fit mr-1 mb-1"
                        >
                          {categoryNameObj[category]}
                        </div>
                      ))}
                  </div>
                </Section>

                <div className="flex justify-end w-full max-w-md side-padding bg-[#FFFFFF]">
                  <div className="flex justify-between items-center gap-[15px]">
                    <CopyToClipboard
                      text={window.location.href}
                      onCopy={() => {
                        toast.success('링크가 복사되었습니다');
                      }}
                    >
                      <div className="col-span-3 flex items-center rounded px-[7px] py-[2px] border border-[#3680f7] cursor-pointer">
                        <div className="w-[15px] h-[15px] mr-2">
                          <CopySVG className="w-full h-full" fill="#3680f7" />
                        </div>
                        <p className="text-[#3680f7] font-[700]">링크 복사</p>
                      </div>
                    </CopyToClipboard>

                    {processBookmarkData ? (
                      <>
                        <button
                          disabled={changeBookmarkStatus}
                          className="pt-[14px] pb-[8px] px-[15px]"
                          onClick={(e) => {
                            try {
                              e.stopPropagation();
                              handleBookmark('communityPost', communityPost);
                            } catch (e) {}
                          }}
                        >
                          {changeBookmarkStatus ? (
                            <p>...</p>
                          ) : (
                            <>
                              <FontAwesomeIcon
                                icon={!postBookmarked ? regularBookmark : solidBookmark}
                                size={'2x'}
                                color={!postBookmarked ? '' : '#56d8fc'}
                              />
                            </>
                          )}
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <Section className="max-w-md side-padding pt-[20px] py-3 bg-[#FFFFFF]">
                  <div className="">
                    <pre
                      id="communityPostContent"
                      className="relative text-[15px] mb-3 break-all whitespace-pre-wrap"
                    >
                      {/* <Linkify>{communityPost?.content}</Linkify> */}
                      <Linkify>
                        {/* {communityPost?.content && htmlReactParser(communityPost?.content ?? '')} */}
                        {parsedContent && htmlReactParser(parsedContent ?? '')}
                      </Linkify>
                      {contentAccorButtonStatus && (
                        <>
                          {contentExpandStatus ? (
                            <></>
                          ) : (
                            <>
                              <div className="absolute bottom-0 left-0 w-full h-[80px] bg-gradient-to-b from-transparent to-black opacity-30"></div>
                            </>
                          )}
                        </>
                      )}
                    </pre>
                    {contentAccorButtonStatus && (
                      <div className="flex justify-center w-full my-[3px]">
                        <button
                          className="border-[2px] border-[black] px-[20px] py-[2px] rounded-[8px] font-[700]"
                          onClick={() => {
                            handleContentExpand();
                          }}
                        >
                          {contentExpandStatus ? <>접기</> : <>더보기</>}
                        </button>
                      </div>
                    )}
                  </div>
                  <div>
                    {cpRecommVkTourDests?.length > 0 && (
                      <RecommendVKTourDests
                        recommVkTourDests={cpRecommVkTourDests}
                        openDrawer={openDrawer}
                        setChosenRecommendVkTourDest={setChosenRecommendVkTourDest}
                      />
                    )}
                  </div>
                  <div className="flex text-[12px] text-[#878787]">
                    <div className="mr-[5px]">
                      {communityPost?.user?.username?.substring(0, 10)
                        ? communityPost?.user?.username?.substring(0, 10) + '...'
                        : null}
                    </div>
                    <div>{contentTimePassed ?? null}</div>
                  </div>
                </Section>
                <Section className="max-w-md side-padding pt-[20px] py-3 bg-[#FFFFFF]">
                  <div className="flex justify-between mb-[5px]">
                    <div className="font-[700] text-[#FF6400] text-[13px]">
                      댓글 ({(communityPostCommentsData as any)?.pages[0]?.data?.total ?? 0})
                    </div>
                  </div>
                  <div className="sticky top-[0px] z-30 rounded-[10px] w-full bg-[#dcdcde]">
                    <div className="flex items-center p-[5px]">
                      <textarea
                        className="w-full mr-[4px] rounded-[10px] resize-none border-[#dcdcde]"
                        style={{ maxHeight: '300px' }}
                        placeholder="댓글을 작성해주세요"
                        value={communityPostComment}
                        onChange={(e) => {
                          handleCommunityPostCommentChange(e);
                        }}
                      />
                      {addCommunityPostCommentStatus ? (
                        <div className="w-[13px] h-[13px] border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
                      ) : (
                        <Button
                          disabled={addCommunityPostCommentStatus}
                          className="px-[0px]"
                          onClick={() => {
                            if (authUserTokenObj) {
                              handleCommunityPostComment();
                            } else {
                              window.confirm('로그인후 등록해주세요.');
                            }
                          }}
                        >
                          <IconSend width={'25px'} height={'25px'} />
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="mt-[10px]">
                    {!((communityPostCommentsData as any)?.pages[0]?.data?.items?.length > 0) &&
                    !isCommunityPostCommentsDataLoading ? (
                      <>
                        <p className="grid place-content-center h-44 gap-[5px]"></p>
                      </>
                    ) : communityPostCommentsData?.pages &&
                      communityPostCommentsData?.pages?.length > 0 ? (
                      <>
                        {map(communityPostCommentsData?.pages ?? [], (page, pageInd) => {
                          return map(page?.data?.items ?? [], (cmtObj, cmtObjInd: number) => {
                            return (
                              <>
                                <div
                                  key={cmtObj?.id}
                                  ref={
                                    communityPostCommentsData?.pages?.length - 1 == pageInd &&
                                    page?.data?.items?.length - 1 == cmtObjInd
                                      ? lastElement
                                      : null
                                  }
                                >
                                  <CardType2
                                    pageInd={pageInd}
                                    item={cmtObj}
                                    // handlePostinfoSwiperSlide={handlePostinfoSwiperSlide}
                                    openDrawer={openDrawer}
                                    closeDrawer={closeDrawer}
                                    setChosenPostCommentDetail={setChosenPostCommentDetail}
                                  />
                                </div>
                              </>
                            );
                          });
                        })}
                        {/* <div ref={lastElement}></div> */}
                      </>
                    ) : null}
                  </div>
                </Section>
              </>
            ) : null}
          </div>
        </>
      }

      <div>
        <CustomDrawer drawerRef={drawerRef} contentMt={55}>
          <div className="mt-[50px] p-[15px]">
            {drawerType == DrawerType.POST_COMMENT_DETAIL ? (
              <PostComment item={chosenPostCommentDetail} />
            ) : drawerType == DrawerType.POST_RECOMMEND_VK_TOUR_DESTS ? (
              <>
                <RecommendVKTourDest item={chosenRecommendVkTourDest} />
              </>
            ) : null}
          </div>
        </CustomDrawer>
      </div>
    </>
  );
};
