import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { Toggle } from '../../../components/Toggle';
import { Search } from '../../../components/Search';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { useHistory, useParams } from 'react-router-dom';
import { useExhibition } from '../../../hooks/exhibition';
import { format } from 'date-fns';
import { chain, find, map, uniq } from 'lodash';
import useDebounce from '../../../hooks/common';
import { useProducts } from '../../../hooks/product';
import { stringify } from 'qs';
import FroalaEditor from '../../components/FroalaEditor';
import { useMutation } from 'react-query';

import { toast } from 'react-toastify';

import { DEBOUNCE_THRESHOLD_MS } from './constants';
import ExhibitionProductDnd from './components/ExhibitionProductDnd';
import { uploadImage } from '../../../api/photo';
import { useRef } from 'react';

import { Product } from '../../../types';

import { getProducts } from '../../../api_v2/admin/products';
import { ExhibitionParams, UpdateExhibitionParams } from '../../../api_v2/admin/exhibitions/types';
import {
  deleteExhibition,
  getExhibition,
  updateExhibition,
} from '../../../api_v2/admin/exhibitions';

export const DisplayDetail = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [formState, setFormState] = useState<ExhibitionParams>({
    id: null,
    type: null,
    title: '',
    weight: 0,
    isVisible: false,
    content: '',
    products: [],
  });

  const editorContentRef = useRef('');

  const [searchProductKeyword, setSearchProductKeyword] = useState('');

  const debouncedSearchProduct = useDebounce({
    value: searchProductKeyword,
    delay: DEBOUNCE_THRESHOLD_MS,
  });

  const [searchProducts, setSearchProducts] = useState([]);

  const [exhibition, setExhibition] = useState({});

  const [updateExhibitionStatus, setUpdateExhibitionStatus] = useState<boolean>(false);

  const [deleteExhibitionStatus, setDeleteExhibitionStatus] = useState<boolean>(false);

  useEffect(() => {
    getExhibition(+id).then((resultData) => {
      if (resultData?.success) {
        if (resultData.data) {
          const { type, title, weight, isVisible, content, exhibitionProductMaps } =
            resultData.data;

          setFormState((prev) => ({
            ...prev,
            type,
            title,
            weight,
            isVisible,
            content,
            products: chain(exhibitionProductMaps).sortBy('order').map('product').value(),
          }));
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!!searchProductKeyword && searchProductKeyword.length !== 0) {
      const searchProductQueryString = stringify(
        {
          limit: 100,
          filter: {
            word: searchProductKeyword,
          },
        },
        { addQueryPrefix: true, encode: false }
      );
      getProducts(searchProductQueryString).then((resultData) => {
        if (resultData?.success) {
          setSearchProducts(resultData.data.items);
        }
      });
    } else {
      setSearchProducts([]);
    }
  }, [searchProductKeyword]);

  const _onChangeText = (e: any) => {
    const { name, value } = e.target;
    setFormState((prev: any) => {
      return {
        ...prev,
        [name]: name === 'weight' ? parseInt(value) : value,
      };
    });
  };

  const _onChangeType = (e: any) => {
    setFormState((prev: any) => ({
      ...prev,
      type: e.target.value,
    }));
  };

  const _onChangeProductKeyword = (e: any) => {
    setSearchProductKeyword(e.target.value);
  };

  const _onChangeDetailList = (e: any) => {
    let chosenProductId = e.target.value;
    if (!chosenProductId) {
      return;
    }
    const findProduct = find(
      searchProducts,
      (product: any) => product.productId === chosenProductId
    );
    if (findProduct) {
      setFormState((prevState) => {
        let tempHolder: any[] = [];
        if (prevState.products) {
          tempHolder = [...prevState.products];
        }
        return {
          ...prevState,
          products: uniq([...tempHolder, findProduct]),
        };
      });
    }
  };

  const _handleUpdateButton = async () => {
    if (!formState.type) {
      toast.error('타입을 선택해주세요.');
      return;
    } else if (!(formState.weight! >= 0)) {
      toast.error('0이상 숫자를 입력해주세요');
    } else {
      let redTextFlag = false;
      for (let i = 0; i < Object.keys(formState).length; i++) {
        let key = Object.keys(formState)[i];
        if (!['thumbnail', 'products'].includes(key)) {
          if (formState[key as keyof UpdateExhibitionParams]) {
            let inputValue = formState[key as keyof UpdateExhibitionParams]!.toString().trim();
            if (inputValue.length === 0) {
              redTextFlag = true;
              break;
            }
          }
        }
      }
      if (redTextFlag) {
        toast.error('모든항목을 작성해주세요');
      } else {
        setUpdateExhibitionStatus(true);
        let updateExhibitionResult = await updateExhibition(+id, {
          ...formState,
          id: +id,
          content: editorContentRef.current,
        });
        if (updateExhibitionResult) {
          toast.success('성공적으로 기획을 수정하였습니다');
          setUpdateExhibitionStatus(false);
        } else {
          toast.error('Something went wrong updating a exhibition');
        }
      }
    }
  };

  const _handleDeleteButton = async () => {
    setDeleteExhibitionStatus(true);
    let deleteExhibitionStatus = await deleteExhibition(+id);
    if (deleteExhibitionStatus) {
      toast.success('성공적으로 기획을 삭제하였습니다');
      setDeleteExhibitionStatus(false);
      history.push('/admin/display');
    } else {
      toast.error('Something went wrong deleting a exhibition');
    }
  };

  if (!exhibition) {
    return null;
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>기획전 수정</AdminH1>
      </div>

      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-start-1 col-span-1">
            <div className="font-bold text-lg mb-4">기본 정보</div>
            <div className="flex items-center space-x-3">
              <p>기획전 숨김 / 노출</p>
              <Toggle
                checked={formState.isVisible}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    isVisible: e,
                  }))
                }
              />
            </div>
          </div>
          <div className="col-start-1 col-span-1">
            <Select label="타입" onChange={_onChangeType}>
              <option disabled selected hidden>
                타입을 선택해주세요
              </option>
              {map(Object.values(ExhibitionType), (type) => (
                <option key={type} value={type} selected={type === formState.type}>
                  {ExhibitionText[type]}
                </option>
              ))}
            </Select>
          </div>
          <div className="col-start-1 col-span-1">
            <TextField label="제목" name="title" value={formState.title} onChange={_onChangeText} />
            <TextField
              label="진열순서 (높을수록 우선 노출됩니다)"
              type="number"
              name="weight"
              value={formState.weight}
              onChange={_onChangeText}
            />
          </div>
          {/* <TextField
            label="*부제목"
            name="subTitle"
            value={formState.subTitle}
            onChange={_onChangeText}
          />
          <TextField
            label="타이머 설정(마감 날짜)"
            type="date"
            name="endTime"
            value={
              formState.endTime &&
              format(new Date(formState.endTime), 'yyyy-MM-dd')
            }
            onChange={_onChangeText}
          /> */}

          {/* <div className="col-start-1">
            <p className="label">*썸네일 업로드</p>
            <label htmlFor="displayPhoto">
              <div className="relative aspect-1 rounded-md border-2 border-grey-5">
                {displayPhoto || formState.thumbnail ? (
                  <>
                    <img
                      className="absolute w-full h-full rounded object-cover"
                      src={
                        displayPhoto
                          ? URL.createObjectURL(displayPhoto)
                          : formState.thumbnail
                      }
                      alt=""
                    />
                    <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                      사진 수정하기
                    </div>
                  </>
                ) : (
                  <div className="absolute w-full h-full rounded object-cover bg-white">
                    <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                      <FileUploadImg />
                      <div className="text-sm text-gray-500 text-center pt-1">
                        이미지를 업로드해주세요.
                        <br />
                        (최대 1장)
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </label>
            <input
              type="file"
              id="displayPhoto"
              className="hidden"
              accept="image/*"
              onChange={(e) => {
                e.target.validity.valid &&
                  setDisplayPhoto(e.target.files?.item(0));
              }}
            />
          </div> */}
          {/* <div className="md:col-span-2 col-start-1">
            <p className="label">*내용(Editor)</p>
            <FroalaEditor
              defaultValue={exhibition.content}
              onChange={(model) => {
                editorContentRef.current = model;
              }}
            />
          </div> */}
          <div className="md:col-span-2 col-start-1">
            <p className="label">내용(Editor)</p>
            <FroalaEditor
              defaultValue={formState.content}
              onChange={(model) => {
                editorContentRef.current = model;
              }}
            />
          </div>

          <div className="flex flex-col space-y-4 w-full col-span-2 overflow-x-auto">
            <div className="font-bold text-lg mb-4">노출 상품 리스트</div>
            <div className="flex flex-col col-span-1">
              <Search
                placeholder="상품을 검색해주세요."
                value={searchProductKeyword}
                onChange={_onChangeProductKeyword}
                // list="products"
              />
              <div>검색결과: {searchProducts && searchProducts.length}개</div>
              {searchProducts && searchProducts.length > 0 ? (
                <Select label="노출 상품 선택" onChange={_onChangeDetailList}>
                  <option disabled selected hidden>
                    상품을 선택해주세요
                  </option>
                  {map(searchProducts, (product: any) => (
                    <option key={product.productId} value={product.productId}>
                      {product.productName}
                    </option>
                  ))}
                </Select>
              ) : null}
            </div>

            <div className="flex flex-col">
              <p>선택한 상품 리스트</p>
              <ExhibitionProductDnd exhibitionState={formState} setExhibitionState={setFormState} />
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            onClick={() => _handleDeleteButton()}
            text="삭제하기"
            className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
            disabled={updateExhibitionStatus || deleteExhibitionStatus}
          />
          <Button
            onClick={() => _handleUpdateButton()}
            text="수정하기"
            className="h-10 text-sm filled-black"
            disabled={updateExhibitionStatus || deleteExhibitionStatus}
          />
        </div>
      </Card>
    </>
  );
};
