import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { default as htmlReactParser, attributesToProps } from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

const Test2 = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    axios
      .get(`https://techcrunch.com/wp-json/wp/v2/categories?per_page=20&cachePrevention=0`)
      .then((res: any) => {
        setCategories(res?.data ?? []);
      });
  }, []);

  const getCategories = (cateData: any) => {
    try {
      let tempArr = [];
      if (cateData?.length > 0) {
        for (let i = 0; i < cateData?.length; i++) {
          let idData = cateData[i];
          let foundCate = categories.find((cateObj: any) => idData == cateObj?.id) as any;
          foundCate?.name ? tempArr.push(foundCate?.name) : null;
        }
      }
      return tempArr.join(', ');
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    let pageData = parsed?.page ?? 1;
    setIsLoading(true);
    fetchData(pageData);
  }, [search]);

  const fetchData = async (pageData: any) => {
    try {
      const response = await axios.get(
        `https://techcrunch.com/wp-json/tc/v1/magazine?page=${pageData}&_embed=true&_envelope=true&categories=577047203&cachePrevention=0`
      );

      setData(response?.data?.body);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const handleNextPage = () => {
    let pageData = (parsed?.page ? parseInt(parsed?.page as any) : 1) + 1;
    window.location.href = `/admin/ai/test2?page=${pageData}`;
  };

  const handlePrevPage = () => {
    if ((parsed?.page as any) > 1) {
      let pageData = (parsed?.page ? parseInt(parsed?.page as any) : 1) - 1;
      window.location.href = `/admin/ai/test2?page=${pageData}`;
    }
  };

  const [inputPage, setInputPage] = useState(parsed?.page ?? 1);

  const handleInputChange = (eData: any) => {
    try {
      setInputPage(eData?.target?.value);
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleInputPage = () => {
    try {
      window.location.href = `/admin/ai/test2?page=${inputPage}`;
    } catch (e) {
      console.log('e', e);
    }
  };

  return (
    <div className="test2-container max-w-md">
      <h1 className="mb-[10px]">TC data test</h1>

      {!isLoading ? (
        data.map((item: any) => (
          <div key={item?.id} className="border-b border-solid border-gray-300 py-[10px]">
            <p>{item?.categories ? getCategories(item?.categories) : null}</p>
            <p className="font-[700]">
              {item?.title?.rendered ? htmlReactParser(item?.title?.rendered) : null}
            </p>

            <p className="pt-[5px]">{item?.date}</p>
            <div className="pt-[5px]">
              {item?.excerpt?.rendered ? htmlReactParser(item?.excerpt?.rendered) : null}
            </div>
            <div className="flex gap-[5px] pt-[5px]">
              <p>Link:</p>
              <a href={item?.canonical_url} target="_blank" rel="noreferrer">
                바로가기
              </a>
            </div>
            <style>
              {`
         test2-container div,
         test2-container img,
         test2-container video,
         test2-container iframe {
           max-width: 100%;
         }
        `}
            </style>
          </div>
        ))
      ) : (
        <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
      )}
      <div className="flex items-center justify-center space-x-4 py-[20px]">
        <button
          onClick={handlePrevPage}
          disabled={(parsed?.page as any) === 1}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
        >
          Previous Page
        </button>
        <div className="flex gap-[5px] items-center">
          <span className="text-gray-700 font-semibold">Page</span>
          <input
            type="number"
            value={inputPage as any}
            onChange={(e) => handleInputChange(e)}
            //   onKeyPress={handleKeyPress}
            className="border border-gray-400 px-2 py-1 rounded focus:outline-none"
            placeholder="Enter page number"
          />
          <button
            onClick={handleInputPage}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Go
          </button>
        </div>
        <button
          onClick={handleNextPage}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
        >
          Next Page
        </button>
      </div>
    </div>
  );
};

export default Test2;
