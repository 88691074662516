import { useEffect, useState, useRef, useCallback } from 'react';
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import { parse, stringify } from 'qs';

import { toast } from 'react-toastify';

import { default as htmlReactParser, attributesToProps } from 'html-react-parser';

import { Section } from '../../../../../components/Section';
import { TopTitleBar } from './components/TopTitleBar';

import { CustomDrawer } from './CustomDrawer';

import { Form, Input, Button as AntdButton } from 'antd';
import { checkBooking } from '../../../../../api_v2/non_admin/booking';
import {
  cancelReservationForNonmemberV2,
  checkBookingV2,
  getCampingRefundPolicyBeforeReservationV2,
} from '../../../../../api_v2/non_admin/onda';
import dayjs from 'dayjs';
import { Button } from '../../../../../components/Button';
import { modalState } from '../../../../../ridge/ridge';

// testing
SwiperCore.use([Pagination, Autoplay]);

enum PaymentMethod {
  CARD = 'card',
  VBANK = 'vbank',
}

enum DrawerType {
  REFUND_POLICY = 'REFUND_POLICY',
  CANCEL_RESERVATION = 'CANCEL_RESERVATION',
}

const paymentMethodObj: { [key: string]: any } = {
  card: '신용카드',
  vbank: '무통장 입금',
  point: '포인트결제',
};

const paymentStatusObj: { [key: string]: any } = {
  ready: '가상계좌 발급 완료',
  paid: '결제 성공',
  cancelled: '취소',
  fail: '결제 실패',
};

const bookingStatusObj: { [key: string]: any } = {
  waiting: '결제 대기중',
  ready: '입금 대기중',
  pending: '예약 요청중(입금완료)',
  confirmed: '확정',
  cancelled: '취소',
  canceled: '취소', // data from onda db
  failed: '실패',
};

const cancelResErroMsgObj: { [key: string]: any } = {
  'check in date is past': '체크인 날짜가 지난 예약입니다',
  'Already canceled': '이미 취소된 예약입니다',
};

export const ReservationCheckWONPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [form] = Form.useForm();
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const { campingId, zoneId } = useParams<{
    campingId: string;
    zoneId: string;
  }>();
  const [campingSite, setCampingSite] = useState<any>(null);
  const [campingSiteZone, setCampingSiteZone] = useState<any>(null);

  const [reservationData, setReservationData] = useState<any>(null);

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const { imp_uid, merchant_uid, imp_success, error_msg } = parsedSearch;

  const [drawerType, setDrawerType] = useState<any>(null);
  const [cancelReason, setCancelReason] = useState<string>('');

  const openDrawer = (drawerTypeData: string) => {
    setCancelReason('');
    setDrawerType(drawerTypeData);
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '80%';
    }
  };

  const closeDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '0';
      drawerRef.current.style.height = '0';
    }
  };

  const [passwordData, setPasswordData] = useState<any>(null);

  const onFinish = (values: any) => {
    if (values['order-number']?.trim()?.length > 0 && values['password']?.trim()?.length > 0) {
      setPasswordData(values['password']?.trim());
      checkBookingV2({
        bookingNo: values['order-number']?.trim(),
        password: values['password']?.trim(),
      }).then((res) => {
        if (res?.success && res?.data?.booking_number) {
          setReservationData(res.data);
        } else {
          toast.error('예약번호 또는 비밀번호를 확인해주세요.');
        }
      });
    } else if (values['order-number']?.trim()?.length == 0) {
      toast.error('예약번호를 확인해주세요.');
    } else if (values['password']?.trim()?.length == 0) {
      toast.error('비밀번호를 확인해주세요.');
    }
  };

  const convertDate = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const [campingRefundPolicyBeforeReservation, setCampingRefundPolicyBeforeReservation] =
    useState<any>(null);

  useEffect(() => {
    if (reservationData) {
      if (
        reservationData?.property_id &&
        reservationData?.rateplans[0]?.roomtype_id &&
        reservationData?.rateplans[0]?.rateplan_id
      ) {
        getCampingRefundPolicyBeforeReservationV2(
          reservationData?.property_id,
          reservationData?.rateplans[0]?.roomtype_id,
          reservationData?.rateplans[0]?.rateplan_id,
          `?checkin=${dayjs(reservationData?.checkin).format('YYYY-MM-DD')}&checkout=${dayjs(
            reservationData?.checkout
          ).format('YYYY-MM-DD')}`
        ).then((res) => {
          if (res?.success && res?.data) {
            if (res?.data?.refundPolicyBeforeReservation) {
              setCampingRefundPolicyBeforeReservation(res?.data?.refundPolicyBeforeReservation);
            }
          } else {
          }
        });
      }
    }
  }, [reservationData]);

  useEffect(() => {
    modifyCampingRefundPolicyBeforeReservation();
  }, [campingRefundPolicyBeforeReservation, reservationData]);

  const modifyCampingRefundPolicyBeforeReservation = () => {
    let refundTypeObj: { [key: string]: any } = {
      nights: '연박 예약 (전체 예약 요금에 대해 체크인 날짜를 기준으로 취소환불정책을 일괄 적용)',
    };

    let refundPolicyObj;
    if (campingRefundPolicyBeforeReservation) {
      refundPolicyObj = campingRefundPolicyBeforeReservation;
    }

    if (refundPolicyObj) {
      let refundPolicy = '';
      if (refundPolicyObj?.refund_policy) {
        refundPolicy =
          refundPolicy +
          `<div style="display: grid; grid-template-columns: auto auto; border-top: 1px solid #E9ECEF; padding-bottom: 3px">
           <div style="padding-top: 8px; padding-bottom: 8px;"></div><div style="padding-top: 8px; padding-bottom: 8px; font-weight: 600; text-align: center;">환불요금</div>
           <div style="grid-column: span 2; border-bottom: 1px solid #E9ECEF; margin-bottom: 3px"></div>
          `;

        refundPolicyObj?.refund_policy.forEach((item: any) => {
          refundPolicy =
            refundPolicy +
            `
            <div style="border-bottom: 1px solid #E9ECEF; padding-top: 8px; padding-bottom: 8px; text-align: center; margin-left: 3px">${
              item?.until ? new Date(item.until)?.toLocaleString() : ''
            }</div>
            <div style="border-bottom: 1px solid #E9ECEF; padding-top: 8px; padding-bottom: 8px; text-align: center">
              ${item?.refund_amount?.toLocaleString()} (${item?.percent}%)
            </div>
            `;
        });

        refundPolicy = refundPolicy + '</div>';
      }

      return `
      <div style="margin-top: 75px">
        <div style="margin-bottom: 5px">
          <div style="font-weight: 600; font-size: 18px">취환불 적용 타입:</div>
          <div>${refundTypeObj[refundPolicyObj?.refund_type ?? ''] ?? ''}</div>
        </div>
        <div style="margin-bottom: 5px">
          <div style="font-weight: 600; font-size: 18px; margin-bottom: 5px">확정 취환불정책:</div>
          ${refundPolicy}
        </div>
      </div>
      `;
    } else return '';
  };

  const calculateTotalCanceledAmt = (dataArr: any) => {
    let finalAmt = 0;
    if (dataArr) {
      dataArr.map((chObj: any) => {
        finalAmt = finalAmt + chObj?.amount ?? 0;
      });
    }
    return finalAmt;
  };

  const [cancelResStatus, setCancelResStatus] = useState(false);

  const cancelResResultModal = (
    title: string,
    subtitle: string,
    subtitleColor: string,
    redirectUrlAfterOnclick?: any
  ) => {
    const onClose = () => modalState.set({ ...modalState.get(), open: false });
    modalState.set({
      title,
      subtitle,
      subtitleColor,
      open: true,
      onClose,
      onClick: async () => {
        onClose();
        if (redirectUrlAfterOnclick) {
          window.location.href = redirectUrlAfterOnclick;
        }
      },
    });
  };

  const cancelRes = (resData: any) => {
    if (cancelReason?.trim()?.length > 0) {
      const onClose = () => modalState.set({ ...modalState.get(), open: false });
      modalState.set({
        title: '예약을 취소하시겠습니까?',
        subtitle: '(예약 취소후 환불처리는 1~3일 소요될 수 있습니다.)',
        subtitleColor: '#FF3D00',
        open: true,
        onClose,
        onClick: async () => {
          setCancelResStatus(true);
          createCancelResOverlay(true);
          loadingDots(true, 'cancelres-loading-container');

          cancelReservationForNonmemberV2({
            bookingNo: resData?.booking_number,
            channelBookingNo: resData?.channel_booking_number,
            password: passwordData?.trim(),
            cancelReason: cancelReason?.trim(),
            cancelTime: new Date(),
          })
            .then((res) => {
              setCancelResStatus(false);
              createCancelResOverlay(false);
              loadingDots(false, 'cancelres-loading-container');

              if (res?.success == true && res?.message == 'successfully cancelled') {
                toast.success('예약이 취소되었습니다.');
                cancelResResultModal(
                  '예약 취소가 완료되었습니다',
                  `(예약 취소후 환불처리는 1~3일 소요될 수 있습니다.)`,
                  '#FF3D00'
                );
              } else if (res?.success == false && res?.message) {
                let errorMessage = cancelResErroMsgObj[res?.message] ?? 'error';
                toast.error(errorMessage);
                cancelResResultModal(
                  '예약 취소가 실패했습니다',
                  `${errorMessage} (일정 확인 후 문의사항은 상담 부탁드립니다)`,
                  '#FF3D00'
                );
              } else {
                toast.error('error');
                cancelResResultModal('error', ``, '');
              }

              closeDrawer();

              checkBookingV2({
                bookingNo: resData?.channel_booking_number,
                password: passwordData,
              }).then((res) => {
                if (res?.success && res?.data?.booking_number) {
                  setReservationData(res.data);
                } else {
                  toast.error('예약번호 또는 비밀번호를 확인해주세요.');
                }
              });
            })
            .catch((e) => {
              setCancelResStatus(false);
              createCancelResOverlay(false);
              loadingDots(false, 'cancelres-loading-container');

              console.log('e', e);
              toast.error('error');
            });
          onClose();
        },
      });
    } else {
      toast.error('취소사유를 입력해주세요');
    }
  };

  const loadingDots = (statusBool: boolean, className: string) => {
    let loadingContEle = document.querySelector('.' + className);
    if (loadingContEle) {
      if (statusBool) {
        (loadingContEle as any).style.display = 'block';
      } else {
        (loadingContEle as any).style.display = 'none';
      }
    }
  };

  const handleCancelReason = (evt: any) => {
    setCancelReason(evt.target.value);
  };

  const createCancelResOverlay = (status: boolean) => {
    let bodyEle = document.querySelector('body') as any;
    let cancelresOverlayEle = document.querySelector('#cancelres-overlay');
    if (status == true) {
      bodyEle.style.cssText = `pointer-events: none`;

      if (cancelresOverlayEle) {
      } else {
        let newOverlayEle = document.createElement('div');
        newOverlayEle.id = 'cancelres-overlay';
        newOverlayEle.style.cssText = ` 
        position: absolute;
        top:0; left:0;
        width: 100%; height:100%;
        background:rgba(0,0,0,0.6);
        z-index: 1000
        ;
        z
        `;
        bodyEle.append(newOverlayEle);
      }
    } else {
      if (cancelresOverlayEle) {
        bodyEle.style.cssText = `pointer-events: auto`;
        cancelresOverlayEle.remove();
      }
    }
  };

  return (
    <>
      <TopTitleBar logo={true} home={false} />
      {!reservationData && (
        <>
          <div className="max-w-md w-screen side-padding bg-[#FFFFFF]">
            <Section className="max-w-md py-3 mt-[100px] bg-[#FFFFFF]">
              <div className="flex flex-col justify-center items-center">
                <div className="text-[18px]">
                  <span className="font-[700]">예약번호</span>와 예약 시 입력한
                  <span className="font-[700] ml-1"> 비밀번호</span>를 입력해주세요
                </div>
              </div>
            </Section>
            <Section className="max-w-md py-3 mt-[40px] bg-[#FFFFFF]">
              <Form form={form} name="dynamic_rule" onFinish={onFinish}>
                <div className="grid grid-cols-3 mb-3">
                  <Form.Item
                    className="col-span-3 m-0"
                    name="order-number"
                    rules={[
                      {
                        required: true,
                        message: '예약번호를 입력해주세요',
                      },
                    ]}
                  >
                    <Input style={{ maxHeight: '35px' }} placeholder="예약번호를 입력해주세요" />
                  </Form.Item>
                </div>
                <div className="grid grid-cols-3 mb-1">
                  {/* <div className="text-[13px]">
                비밀번호<span className="text-[#FF6400]">*</span>
              </div> */}
                  <Form.Item
                    className="col-span-3 m-0"
                    name="password"
                    rules={[{ required: true, message: '비밀번호를 입력해주세요' }]}
                  >
                    <Input.Password
                      style={{ maxHeight: '35px' }}
                      placeholder="비밀번호를 입력해주세요"
                    />
                  </Form.Item>
                </div>
                <div className={`bg-white z-20 w-full max-w-md flex space-x-2 px-4 mt-2 h-16`}>
                  {/* <button
                onClick={() => handleCheckResButton()}
                className="my-2 rounded bg-[#FF6400] text-[#FFFFFF] font-bold 
                    border border-[#D0D0D0]
                    flex-1 flex justify-center items-center"
              >
                예약조회
              </button> */}
                  <Form.Item noStyle>
                    <AntdButton
                      // type="text"
                      id="booking-page-button"
                      className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                    flex-1 flex justify-center items-center"
                      htmlType="submit"
                    >
                      예약조회
                    </AntdButton>
                  </Form.Item>
                </div>
              </Form>
            </Section>
            {/* <Section className="mb-4 max-w-md">
            <Link className="flex justify-center items-center" to="/v2/camping/reservation/check-w-pn">
              휴대폰번호로 조회하기
            </Link>
          </Section> */}

            {/* {window.location.origin.includes('localhost') ||
            window.location.origin.includes('ngrok') ||
            window.location.origin.includes('stage') ? (
              <>
                <Link
                  className="flex justify-center items-center text-[#5986f7] mb-3"
                  to="/v2/camping/reservation/request-reset-password/nonmember"
                  // id="booking-page-button"
                  // className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                  //         flex-1 flex justify-center items-center"
                >
                  이메일로 예약 비밀번호 찾기
                </Link>
              </>
            ) : (
              <></>
            )} */}
            <Link
              className="flex justify-center items-center text-[#5986f7] mb-3"
              to="/v2/camping/reservation/request-reset-password/nonmember"
              // id="booking-page-button"
              // className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
              //         flex-1 flex justify-center items-center"
            >
              이메일로 예약 비밀번호 찾기
            </Link>

            <Link
              className="flex justify-center items-center text-[#5986f7]"
              to="/v2/camping/reservation/history"
              // id="booking-page-button"
              // className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
              //         flex-1 flex justify-center items-center"
            >
              회원 조회
            </Link>
          </div>
        </>
      )}

      {reservationData ? (
        <>
          <div className="max-w-md w-screen side-padding bg-[#FFFFFF]">
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <div className="font-[700] text-[15px] mb-3">예약 정보</div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">이름</div>
                <div className="col-span-4">{reservationData?.booker?.name}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">연락처</div>
                <div className="col-span-4">{reservationData?.booker?.phone}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">예약 상태</div>
                <div className="col-span-4 font-[700] text-[#FF6400]">
                  {reservationData?.status ? bookingStatusObj[reservationData?.status] : null}
                </div>
              </div>
            </Section>
            <div className="bg-gray-100 w-full border my-3" />
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <div className="font-[700] text-[15px] mb-3">예약 상품 정보</div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">예약번호</div>
                <div className="col-span-4">{reservationData?.channel_booking_number}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">숙소</div>
                <div className="col-span-4">{reservationData?.property_name}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">객실</div>
                <div className="col-span-4">{reservationData?.rateplans[0]?.roomtype_name}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">패키지</div>
                <div className="col-span-4">{reservationData?.rateplans[0]?.rateplan_name}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">일정</div>
                <div className="col-span-4">
                  {reservationData?.checkin && reservationData?.checkout
                    ? dayjs(reservationData?.checkin).format('YYYY-MM-DD') +
                      ' ~ ' +
                      dayjs(reservationData?.checkout).format('YYYY-MM-DD')
                    : null}
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">예약인원</div>
                <div className="col-span-4">
                  <div>{reservationData?.rateplans[0]?.number_of_guest?.adult ?? 0}명</div>
                  {/* <div>미성년: {reservationData?.rateplans[0]?.number_of_guest?.child ?? 0}명</div> */}
                  {/* <div>청소년: {reservationData?.people_info?.minor ?? 0}명</div>
                  <div>아동: {reservationData?.people_info?.child ?? 0}명</div> */}
                </div>
              </div>
              {/* <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">예약차량</div>
                <div className="col-span-4">{reservationData?.car_info?.length ?? 0}대</div>
              </div> */}
            </Section>
            <div className="bg-gray-100 w-full border my-3" />
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <div className="font-[700] text-[15px] mb-3">결제 정보</div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">총 결제금액</div>
                <div className="col-span-4 text-[#FF6400] font-[700]">
                  {reservationData?.paymentInfo?.amount?.toLocaleString() ?? 0}원
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">결제수단</div>
                <div className="col-span-4">
                  {reservationData?.paymentInfo?.payMethod
                    ? paymentMethodObj[reservationData?.paymentInfo?.payMethod]
                    : null}
                </div>
              </div>
              {PaymentMethod?.VBANK === reservationData?.paymentInfo?.payMethod ? (
                <>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">은행명</div>
                    <div className="col-span-4">{reservationData?.paymentInfo?.vbankName}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">입금계좌번호</div>
                    <div className="col-span-4">{reservationData?.paymentInfo?.vbankNum}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">입금 마감일</div>
                    <div className="col-span-4">
                      {reservationData?.paymentInfo?.vbankDate
                        ? new Date(reservationData?.paymentInfo?.vbankDate * 1000)?.toLocaleString()
                        : null}
                    </div>
                  </div>
                </>
              ) : PaymentMethod?.CARD === reservationData?.paymentInfo?.payMethod ? (
                <></>
              ) : null}
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">결제 상태</div>
                <div className="col-span-4 font-[700] text-[#FF6400]">
                  {reservationData?.paymentInfo?.status === 'cancelled'
                    ? reservationData?.paymentInfo?.cancelHistory &&
                      (calculateTotalCanceledAmt(reservationData?.paymentInfo?.cancelHistory) ??
                        0) > 0
                      ? '환불'
                      : paymentStatusObj[reservationData?.paymentInfo?.status]
                    : reservationData?.paymentInfo?.status
                    ? paymentStatusObj[reservationData?.paymentInfo?.status]
                    : null}
                </div>
              </div>
            </Section>
            {reservationData?.paymentInfo?.cancelHistory &&
            (calculateTotalCanceledAmt(reservationData?.paymentInfo?.cancelHistory) ?? 0) > 0 ? (
              <>
                <div className="bg-gray-100 w-full border my-3" />
                <Section className="max-w-md py-3 bg-[#FFFFFF]">
                  <div className="font-[700] text-[15px] mb-3">환불 정보</div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">
                      총 환불된 금액
                    </div>
                    <div className="col-span-4 text-[#FF6400] font-[700]">
                      {reservationData?.paymentInfo?.cancelHistory &&
                        (
                          calculateTotalCanceledAmt(reservationData?.paymentInfo?.cancelHistory) ??
                          0
                        ).toLocaleString()}
                      원
                    </div>
                  </div>
                </Section>
              </>
            ) : null}

            <div className="bg-gray-100 w-full border my-3" />
            <Section className="mb-4 max-w-md">
              <div className={`bg-white z-20 w-full max-w-md flex space-x-2 px-4 h-16`}>
                <button
                  onClick={() => openDrawer(DrawerType.REFUND_POLICY)}
                  className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                    border border-[#D0D0D0]
                    flex-1 flex justify-center items-center"
                >
                  예약 환불 규정
                </button>
                {/* {window.location.origin.includes('localhost') ||
                window.location.origin.includes('ngrok') ||
                window.location.origin.includes('stage') ? (
                  <>
                    <button
                      className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                    border border-[#D0D0D0]
                    flex-1 flex justify-center items-center"
                      onClick={() => {
                        openDrawer(DrawerType.CANCEL_RESERVATION);
                      }}
                    >
                      예약 취소
                    </button>
                  </>
                ) : null} */}
                <button
                  className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                    border border-[#D0D0D0]
                    flex-1 flex justify-center items-center"
                  onClick={() => {
                    openDrawer(DrawerType.CANCEL_RESERVATION);
                  }}
                >
                  예약 취소
                </button>
              </div>
            </Section>
          </div>
        </>
      ) : null}

      <div>
        <CustomDrawer drawerRef={drawerRef} confirmButton={false}>
          <div className="mt-[75px] side-padding">
            {drawerType == DrawerType.REFUND_POLICY ? (
              <>
                {campingRefundPolicyBeforeReservation &&
                  htmlReactParser(modifyCampingRefundPolicyBeforeReservation() ?? '')}
              </>
            ) : drawerType == DrawerType.CANCEL_RESERVATION ? (
              <div className="flex flex-col">
                <div className="flex justify-center">
                  <div className="cancelres-loading-container text-[50px] hidden">
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                  </div>
                </div>
                <label htmlFor="cancelReason" className="mb-3">
                  취소 사유:
                </label>
                <textarea
                  id="cancelReason"
                  name="cancelReason"
                  rows={6}
                  placeholder="취소사유를 입력해주세요"
                  value={cancelReason}
                  onChange={(evt) => {
                    handleCancelReason(evt);
                  }}
                ></textarea>
                <Button
                  className="my-2 py-3 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                    flex-1 flex justify-center items-center"
                  onClick={() => cancelRes(reservationData)}
                >
                  예약 취소
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </CustomDrawer>
      </div>
    </>
  );
};
