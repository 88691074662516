import { useState, useEffect } from 'react';
import { TopTitleBar } from '../onda/booking/components/TopTitleBar';

export const FaqPage = () => {
  const faqList = [
    {
      Q: '숙소 부대시설을 알고 싶어요.',
      A: '부대시설을 상세페이지에서 확인가능하며, 자세한 내용은 해당 숙소에 문의해주세요. (페이지 기본정보에서 확인가능)',
    },
    {
      Q: '원하는 날짜와 객실을 선택했는데 "선택하신 조건의 객실이 존재하지 않습니다."라는 문구가 떠요.',
      A: '선택하신 숙소의 객실의 마감되어 예약이 불가하오니 다른 숙소 혹은 다른 날짜를 선택해주세요.',
    },
    {
      Q: '숙박인원에 대해서 궁금해요.',
      A: '객실마다 최대인원이 다르기 때문에 일정선택후 객실선택하기에서 기준인원 및 최대인원 확인이 가능합니다. 최대인원 초과시 추가금액이 발생할 수 있으니 페이지 확인부탁드립니다. ',
    },
    {
      Q: '예약시 패키지 선택이 란 항목은 무엇인가요?',
      A: '숙소만 이용할 것인지, 조식과 수영장 등을 함께 사용할 것인지와 같이 여러 옵션을 선택하는 항목입니다. 대부분의 숙소는 room only 혹은 standalone만 선택할 수 있게 되어 있는데, 이것은 숙소만 이용하겠다는 의미입니다. ',
    },
    {
      Q: '"미성년자 연령정보를 입력해주세요,"라는 문구가 떠요.',
      A: '미성년 연령 정보 옆에 있는 추가 버튼을 미성년의 인원수만큼 클릭한 후에, 미성년 연령 정보를 각각 입력해주세요.',
    },
    {
      Q: '결제가 안돼요.',
      A: '카드사 혹은 PG사마다 결제시 안전결제 프로그램 설치 혹은 업데이트가 필요할 수 있어요. 설치 및 업데이트 후에 재결제 진행해주세요.',
    },
    {
      Q: '예약정보를 알고 싶어요.',
      A: '결제후 예약이 완료되면 입력했던 이메일로 예약정보가 전송돼요. 이메일 확인부탁드립니다.',
    },
    {
      Q: '예약을 취소하고 싶어요.',
      A: '비회원 예약은 아래 예약조회에서 예약정보를 조회한 후에 취소 가능하며, 회원 예약은 마이페이지에서 취소가 가능합니다. 예약한 숙소의 환불 규정에 따라 환불진행되며, 환불은 카드사마다 영업일 기준 1~3일정도 소요될 수 있습니다. ',
    },
  ];
  return (
    <>
      <TopTitleBar logo={true} home={false} />
      <div className="bg-stone-100 text-center text-xl font-bold h-10 leading-10">자주묻는질문</div>
      <div className="bg-white flex flex-col divide-y-8 divide-stone-100">
        {faqList?.length > 0 &&
          faqList.map((faqObj) => (
            <>
              <div className="p-4">
                <p className="flex text-base font-semibold pb-2">
                  <p className="pr-2">Q.</p>
                  <p>{faqObj?.Q}</p>
                </p>
                <div className="flex">
                  <p className="pr-2">A.</p>
                  <p>{faqObj?.A}</p>
                </div>
              </div>
            </>
          ))}

        {/* <div className="h-[8px] bg-stone-100"></div> */}
      </div>
    </>
  );
};
