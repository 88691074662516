import { useEffect, useRef, useState } from 'react';
import {
  Button as AntdButton,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select as AntdSelect,
  Slider,
  Switch as AntdSwitch,
  Upload,
  Input,
  TimePicker,
  DatePicker,
  Modal,
} from 'antd';
import 'antd/dist/antd.css';
import { calendarYears } from '../constants';
import { AntdCalendarModal1 } from './AntdCalendar\bModal1';

const { Option: AntdOption } = AntdSelect;

export const AntdCalendar = (props: any) => {
  // const [month, setMonth] = useState(new Date().getMonth() + 1);
  // const [year, setYear] = useState(new Date().getFullYear());
  // const [calMatchingObj, setCalMatchingObj] = useState<{ [key: string]: any }>({
  //   // 2022: {
  //   //   9: { 5: { available: 2, additionalFee: 1000 }, 6: { available: 4, additionalFee: 3000 } },
  //   //   10: { 1: { available: 3, additionalFee: 3000 }, 28: { available: 6, additionalFee: 6000 } },
  //   // },
  // });
  // const [changedCalObj, setChangedCalObj] = useState<{ [key: string]: any }>({});
  const [currentCalendar, setCurrentCalendar] = useState<any>([]);
  const {
    calMatchingObj,
    setCalMatchingObj,
    changedCalObj,
    setChangedCalObj,
    month,
    setMonth,
    year,
    setYear,

    currInventories,
    setCurrInventories,
    modifiedInventories,
    setModifiedInventories,
  } = props.stateObj;

  const convertToDateObj = (dateStr: string) => {
    const mon = new Date(dateStr).getMonth() + 1;
    const yr = new Date(dateStr).getFullYear();
    const dt = new Date(dateStr).getDate();

    return { yr, mon, dt };
  };

  // const getDaysInMonth = (month: any, year: any) => {
  //   var date = new Date(year, month, 1);
  //   var days = [];
  //   while (date.getMonth() === month) {
  //     days.push({ [new Date(date).toDateString()]: { available: 1, additionalFee: 0 } });
  //     date.setDate(date.getDate() + 1);
  //   }
  //   return days;
  // };

  const handleMonth = (monthData: any) => {
    setMonth(monthData);
  };

  const handleYear = (yearData: any) => {
    setYear(yearData);
  };

  // useEffect(() => {
  //   setModifiedInventories(getDaysInMonth(month - 1, year));
  //   // setCurrentCalendar(getDaysInMonth(month - 1, year));
  // }, [month, year]);

  return (
    <div>
      <Form.Item label="사이트별 재고 및 추가금액"></Form.Item>
      <div style={{ display: 'flex' }}>
        <div className="flex items-center mr-4">
          <AntdSelect placeholder="월" value={month} onChange={(e: any) => handleMonth(e)}>
            {Array.from(new Array(12), (x, i) => {
              return i + 1;
            }).map((item) => (
              <AntdOption key={item}>{item}</AntdOption>
            ))}
          </AntdSelect>
          <span>월</span>
        </div>
        <div className="flex items-center">
          <AntdSelect placeholder="년도" value={year} onChange={(e: any) => handleYear(e)}>
            {calendarYears.map((item) => (
              <AntdOption key={item}>{item}</AntdOption>
            ))}
          </AntdSelect>
          <span>년</span>
        </div>
      </div>
      <p className="mt-4 text-[red]">
        *월과 년을 선택후 수정해주세요. 복수 적용 불가능 (eg) 9월 2022년 & 10월 2023년 동시 수정
        불가능)
      </p>
      <div className="grid gap-10 grid-cols-5 mt-3">
        {currInventories &&
          currInventories.map((item: any) => {
            return (
              <div key={item?.date}>
                <AntdCalendarModal1
                  dateStr={item.date}
                  dateData={item}
                  // setCalMatchingObj={setCalMatchingObj}
                  // setChangedCalObj={setChangedCalObj}
                  currInventories={currInventories}
                  setCurrInventories={setCurrInventories}
                  convertToDateObj={convertToDateObj}
                />

                <div className="flex items-center flex-col">
                  <div>판매상태: {item?.status ? 'O' : 'X'}</div>
                  <div>재고: {item?.booking_id ? 0 : 1}</div>
                  <div>추가금액: {item?.extraPrice}</div>
                </div>
              </div>
            );
          })}
        {/* {currentCalendar.map((dateStr: string) => {
          if (
            calMatchingObj[convertToDateObj(dateStr).yr] &&
            calMatchingObj[convertToDateObj(dateStr).yr][convertToDateObj(dateStr).mon] &&
            calMatchingObj[convertToDateObj(dateStr).yr][convertToDateObj(dateStr).mon][
              convertToDateObj(dateStr).dt
            ]
          ) {
            const dateDataObj =
              calMatchingObj[convertToDateObj(dateStr).yr][convertToDateObj(dateStr).mon][
                convertToDateObj(dateStr).dt
              ];
            return (
              <div key={dateStr}>
                <AntdCalendarModal1
                  dateStr={dateStr}
                  dateData={dateDataObj}
                  setCalMatchingObj={setCalMatchingObj}
                  setChangedCalObj={setChangedCalObj}
                  convertToDateObj={convertToDateObj}
                />

                <div className="flex items-center flex-col">
                  <div>재고: {dateDataObj?.available}</div>
                  <div>추가금액: {dateDataObj?.additionalFee}</div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={dateStr}>
                <AntdCalendarModal1
                  dateStr={dateStr}
                  dateData={null}
                  setCalMatchingObj={setCalMatchingObj}
                  setChangedCalObj={setChangedCalObj}
                  convertToDateObj={convertToDateObj}
                />
              </div>
            );
          }
        })} */}
      </div>
    </div>
  );
};
