import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { AdminH1 } from '../../components/AdminH1';
import { Button } from '../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { useQueryString } from '../../../hooks/hooks';
import { useAdminPopularKeywords } from '../../../hooks/popular-keyword';
import { map } from 'lodash';
import { getPopularKeywords } from '../../../api_v2/admin/popularKeywords';
import { toast } from 'react-toastify';
import { SearchWordWeightEditor } from './components/SearchWordWeightEditor';

export const SearchWord = () => {
  const queryString = useQueryString({ sort: { weight: 'DESC' } });

  const [currPopularKeywords, setCurrPopularKeywords] = useState<{ [key: string]: any }[]>([]);
  const [totalPopularKeywordsCount, setTotalPopularKeywordsCount] = useState<number>(0);

  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const refreshPopularKeywords = (qs: string) => {
    getPopularKeywords(qs).then((resultData) => {
      if (resultData?.success) {
        setCurrPopularKeywords(resultData.data.items);
        setTotalPopularKeywordsCount(resultData.data.total);
      } else {
        // toast.error('Something went wrong getting popular keywords');
      }
    });
  };

  useEffect(() => {
    refreshPopularKeywords(queryString);
  }, [queryString]);

  const onEditWeight = () => {
    refreshPopularKeywords(queryString);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>인기 검색어 관리</AdminH1>
        <Button text="인기 검색어 추가" className="outlined-black text-sm" to="/admin/word/add" />
      </div>
      <div className="flex justify-end items-center">
        <div className="flex space-x-2 items-center">
          <div>전체 인기 검색어 수</div>
          <div className="text-green-500">{totalPopularKeywordsCount}</div>
        </div>
        <div>개</div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>진열 순서</Table.Th>
              <Table.Th>ID</Table.Th>
              <Table.Th>생성일</Table.Th>
              <Table.Th>검색어 명</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(currPopularKeywords, (word) => (
              <Table.Row key={word.id}>
                <Table.Td>
                  <SearchWordWeightEditor weight={word.weight} word={word} onEdit={onEditWeight} />
                </Table.Td>
                <Table.Td>{word.id}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(word.createdAt.toLocaleString(), MomentFormat.YYYYMMDDHmm)}
                </Table.Td>
                <Table.Td>{word.keyword}</Table.Td>
                <Table.Td className="text-right">
                  <Link className="font-normal text-blue-500" to={`/admin/word/${word.id}`}>
                    수정하기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          {/* <Pagination.Label
            count={popularKeywords.items.length}
            total={popularKeywords.total}
          /> */}
          <Pagination.Nav basePath="/admin/word" total={totalPopularKeywordsCount} />
        </Pagination>
      </Card>
    </>
  );
};
