import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper';

SwiperCore.use([Pagination, Autoplay]);

enum DrawerType {
  POST_COMMENT_DETAIL = 'post_comment_detail',
  POST_RECOMMEND_VK_TOUR_DESTS = 'post_recommend_vk_tour_dests',
}

const RecommendVKTourDests = ({
  recommVkTourDests,
  openDrawer,
  setChosenRecommendVkTourDest,
}: any) => {
  const [currentIndexForImg, setCurrentIndexForImg] = useState(0);
  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    try {
      if (recommVkTourDests) {
        let tempImgs = recommVkTourDests?.map((destObj: any) => {
          let addr1 = '';
          if (destObj?.data?.addr1) {
            let splitData = destObj?.data?.addr1?.split(' ');
            if (splitData) {
              addr1 = splitData[0] ?? '';
            }
          }
          if (destObj?.data?.images) {
            return {
              ...destObj?.data?.images[0],
              vkTourDestTitle: destObj?.data?.title,
              vkTourDestAddr1: addr1,
              vkTourDestId: destObj?.data?.id,
            };
          } else {
            return null;
          }
        });
        setImages(tempImgs);
      }
    } catch (e) {}
  }, [recommVkTourDests]);

  const changeImgObjectFit = (slideImage: any) => {
    try {
      if (slideImage) {
        const imgElement = slideImage as any;
        const width = imgElement?.naturalWidth as any;
        const height = imgElement?.naturalHeight as any;

        if (imgElement && width >= 0 && height > 0) {
          const aspectRatio = width / height;
          if (aspectRatio > 1) {
            imgElement.style['object-fit'] = 'cover';
          } else {
            imgElement.style['object-fit'] = 'contain';
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  const handleImageLoad = (imageUrl: string) => {
    try {
      setLoadedImages((prevLoadedImages) => [...prevLoadedImages, imageUrl]);
    } catch (e) {}
  };

  const isImageLoaded = (imageUrl: string) => {
    try {
      return loadedImages.includes(imageUrl);
    } catch (e) {
      return false;
    }
  };

  return (
    <div className="mt-[25px]">
      <p className="font-[700] my-[14px]">추천 여행 장소</p>
      <div className="max-h-[120px] w-full mb-[15px] relative">
        {images?.length > 0 && (
          <Swiper
            // onSwiper={(swiper) => {
            //   swiperRef.current = swiper;
            // }}
            spaceBetween={20}
            slidesPerView={3.3}
            // loop={true}
            // autoplay={{
            //   delay: 500,
            //   disableOnInteraction: false,
            // }}
          >
            {images.map((image: any, index: any) => (
              <SwiperSlide
                key={image?.url}
                style={{ height: '120px', cursor: 'pointer', position: 'relative' }}
                onClick={() => {
                  openDrawer(DrawerType?.POST_RECOMMEND_VK_TOUR_DESTS);

                  let found = recommVkTourDests.find(
                    (destObj: any) => destObj?.data?.id == image?.vkTourDestId
                  );
                  if (found?.data) {
                    setChosenRecommendVkTourDest(found?.data);
                  }
                  //   window.location.href = `/community/posts/${image?.vkTourDestId}`;
                }}
              >
                {!isImageLoaded(image?.url) && (
                  <div className="loading-placeholder">
                    <div className="absolute top-[0px] animate-pulse w-full h-full max-h-[120px] bg-gradient-to-br from-blue-200 via-purple-300 to-red-200"></div>
                  </div>
                )}
                <img
                  src={image?.url}
                  alt={image?.title}
                  style={{
                    width: '100%',
                    height: '80%',
                    display: isImageLoaded(image?.url) ? 'inline' : 'none',
                  }}
                  // ref={(el) => {
                  //   if (el) {
                  //     changeImgObjectFit(el);
                  //   }
                  // }}
                  onLoad={() => handleImageLoad(image?.url)}
                />

                <div className="mt-[5px] break-all text-center text-[10px] font-[700]">
                  {image?.vkTourDestAddr1}
                </div>
                <div className="mt-[5px] break-all text-center text-[10px] font-[500]">
                  {image?.vkTourDestTitle}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default RecommendVKTourDests;
