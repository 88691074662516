import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useInViewport } from 'react-in-viewport';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import moment from 'moment';

import { format, formatDistanceToNow } from 'date-fns';

import './style.css';

enum DrawerType {
  POST_COMMENT_DETAIL = 'post_comment_detail',
}

interface CardType2 {
  item?: any;
  lastElement?: any;
  handlePostinfoSwiperSlide?: any;
  lowestPricesObj?: any;
  pageInd?: any;
  openDrawer?: any;
  closeDrawer?: any;
  setChosenPostCommentDetail?: any;
}
// Card type for post comments
export const CardType2: FC<CardType2> = ({
  item,
  lastElement,
  handlePostinfoSwiperSlide,
  lowestPricesObj,
  pageInd,
  openDrawer,
  closeDrawer,
  setChosenPostCommentDetail,
}) => {
  const cardObserver = useRef<IntersectionObserver>();
  const cardImgRef = useRef<any>(null);
  const [imgInViewport, setImgInViewport] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  const viewportRef = useRef<any>(null);
  const { inViewport, enterCount, leaveCount } = useInViewport(
    viewportRef as any,
    { threshold: 0.1 },
    { disconnectOnLeave: false }
  );

  const [emptyBoxHeight, setEmptyBoxHeight] = useState('360px');

  const [lowestPricesForGivenPage, setLowestPricesForGivenPage] = useState<any>(null);

  const cardImgContRef = useCallback((target) => {
    if (cardObserver.current) cardObserver.current.disconnect();

    cardObserver.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setImgInViewport(true);
        } else {
        }
      },
      {
        // rootMargin: '400px',
        threshold: 0.1,
      }
    );
    if (target) cardObserver.current.observe(target);
  }, []);

  useEffect(() => {
    const handleEvent = () => {
      let imgEle = cardImgRef?.current?.querySelector('img');
      if (imgEle?.complete == true && imgInViewport == true) {
        setImgLoaded(true);
      }
    };
    handleEvent();
    window.addEventListener('DOMSubtreeModified', handleEvent);
    return () => window.removeEventListener('DOMSubtreeModified', handleEvent);
  }, [imgInViewport, cardImgRef?.current?.querySelector('img')]);

  const handlePostCommentDetail = (item: any) => {
    closeDrawer();
    setChosenPostCommentDetail(item);
    openDrawer(DrawerType.POST_COMMENT_DETAIL);
  };

  const [contentTimePassed, setContentTimePassed] = useState<any>(null);

  useEffect(() => {
    try {
      const calculateTimeAgo = (timeData: any) => {
        const textWrittenTime = timeData as any;
        const currentTime = new Date() as any;
        const timeDifference = currentTime - textWrittenTime;

        const secondsPassed = Math.floor(timeDifference / 1000);
        const minutesPassed = Math.floor(secondsPassed / 60);
        const hoursPassed = Math.floor(minutesPassed / 60);
        const daysPassed = Math.floor(hoursPassed / 24);
        const yearsPassed = Math.floor(daysPassed / 365);

        if (yearsPassed > 0) {
          return `${yearsPassed} 년${yearsPassed > 1 ? '' : ''} 전`;
        } else if (daysPassed > 0) {
          return `${daysPassed} 일${daysPassed > 1 ? '' : ''} 전`;
        } else if (hoursPassed > 0) {
          return `${hoursPassed} 시간${hoursPassed > 1 ? '' : ''} 전`;
        } else if (minutesPassed > 0) {
          return `${minutesPassed} 분${minutesPassed > 1 ? '' : ''} 전`;
        } else {
          return `${secondsPassed} 초${secondsPassed > 1 ? '' : ''} 전`;
        }
      };

      const dateString = item.createdAt;
      const parts = dateString.split(/[-T:.]/);
      const year = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Months are 0-indexed
      const day = parseInt(parts[2]);
      const hour = parseInt(parts[3]);
      const minute = parseInt(parts[4]);
      const second = parseInt(parts[5]);
      const millisecond = parseInt(parts[6]);

      const localDate = new Date(year, month, day, hour, minute, second, millisecond);

      // setContentTimePassed(calculateTimeAgo(localDate));
      setContentTimePassed(calculateTimeAgo(new Date(item.createdAt)));
    } catch (e) {}
  }, []);

  const checkContentLength = (text: any) => {
    try {
      let lineBreakCount = 0;
      for (let i = 0; i < text.length; i++) {
        if (text[i] == '\n') {
          lineBreakCount++;
        }
      }
      if (lineBreakCount > 0) {
        if (lineBreakCount >= 2) {
          return {
            count: lineBreakCount,
            modifiedText: text?.split('\n')[0]?.substring(0, 45) + '...',
          };
        } else {
          if (text?.length >= 50) {
            return { count: lineBreakCount, modifiedText: text?.substring(0, 45) + '...' };
          } else {
            return { count: lineBreakCount, modifiedText: text };
          }
        }
      } else {
        if (text?.length >= 50) {
          return { count: lineBreakCount, modifiedText: text?.substring(0, 45) + '...' };
        } else {
          return { count: lineBreakCount, modifiedText: text };
        }
      }
    } catch (e) {
      return null;
    }
  };

  return (
    <div
      ref={viewportRef}
      className="border border-[#D9D9D9] mb-5 card-type-2"
      style={{ borderRadius: '5px 5px 5px 5px' }}
      /* top-left | top-right | bottom-right | bottom-left */
    >
      {inViewport ? (
        <>
          <div
            ref={cardImgContRef}
            key={item?.id}
            style={{ height: '100px' }}
            className="px-[15px] py-[8px]"
            onClick={() => handlePostCommentDetail(item)}
          >
            <div className="flex text-[12px] text-[#878787]">
              <div className="mr-[5px]">
                {item?.user?.username?.substring(0, 10)
                  ? item?.user?.username?.substring(0, 10) + '...'
                  : null}
              </div>
              <div>{contentTimePassed ?? null}</div>
            </div>
            <div className="flex justify-between">
              <pre
                className="text-[15px]"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  // WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-all',
                }}
              >
                {item?.content ? checkContentLength(item?.content)?.modifiedText ?? null : null}
                {/* {item?.content ?? null} */}
              </pre>
            </div>
          </div>
        </>
      ) : (
        <div
          className="bg-[#dedbd9] blur-[4px]"
          style={{
            height: '100px', //viewportRef?.current?.clientHeight,
            width: '100%',
          }}
        ></div>
      )}
    </div>
  );
};
