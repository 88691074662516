import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination2 } from '../../../components/Pagination2';
import { Table } from '../../../components/Table';
import { AdminH1 } from '../../components/AdminH1';
import { Button } from '../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { useExhibitions } from '../../../hooks/exhibition';
import { useQueryString } from '../../../hooks_v2/hooks';
import { map } from 'lodash';
import { ExhibitionText } from '../../../hooks/exhibition/type';
import { parse, stringify } from 'qs';
import { api } from '../../../plugins/axios';
import { getProducts } from '../../../api_v2/admin/products';
import { toast } from 'react-toastify';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Dropdown2 } from '../../../components/Dropdown2';
import fi from 'date-fns/esm/locale/fi/index.js';
import { useInView } from 'react-intersection-observer';

export const ProductsList = () => {
  const [queryString, setQueryString] = useState<string>(useQueryString());
  const [currProducts, setCurrentProducts] = useState<{ [key: string]: any }[]>([]);
  const [totalProductsCount, setTotalProductsCount] = useState<number>(0);
  const [selected, setSelected] = useState<string>('상품명');
  const [searchVal, setSearchVal] = useState<string>('');

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    getProducts(queryString).then((resultData) => {
      if (resultData?.success) {
        setCurrentProducts(resultData.data.items);
        setTotalProductsCount(resultData.data.total);
      } else {
        toast.error('Something went wrong getting products');
      }
    });
  }, [queryString]);

  useEffect(() => {
    let str = '?page=' + page + '&limit=10&sort[id]=DESC';
    if (searchVal) {
      str = str + '&filter[word]=' + searchVal;
    }
    setQueryString(str);
  }, [page]);

  const [tableHeaders, setTableHeaders] = useState([
    '상품 ID',
    '진열',
    '대표이미지',
    '상품명',
    '소속',
    '가격',
    '상품코드',
  ]);

  const [testProductsList, setTestProductsList] = useState<any[]>([]);

  useEffect(() => {
    let holder: any[] = [];
    for (let i = 0; i < 20; i++) {
      holder.push({
        isVisible: i % 2 == 0 ? true : false,
        image:
          'https://upload.wikimedia.org/wikipedia/commons/7/73/Tent_camping_along_the_Sulayr_trail_in_La_Taha%2C_Sierra_Nevada_National_Park_%28DSCF5147%29.jpg',
        productName: 'test_product_name',
        vendorName: 'test_vendor_name',
        brandName: 'test_brand_name',
        price: 0,
        discountedPrice: 0,
        productCode: 'test_code' + i,
      });
      setTestProductsList(holder);
    }
  }, []);

  const useOnSearch = () => {
    setPage(1);
    let str = '?page=1&limit=10&sort[id]=DESC&filter[word]=' + searchVal;
    setQueryString(str);
    // if (selected === '브랜드명') {
    //   setQueryString(str + '&filter[brandName]=' + searchVal);
    // } else {
    //   setQueryString(str + '&filter[productName]=' + searchVal);
    // }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>상품 목록</AdminH1>
        <Button text="상품 등록" className="outlined-black text-sm" to="/admin/products/add" />
      </div>
      <div className="flex justify-start">
        <h2 className="w-2/12">상품 검색</h2>
        {/* <Dropdown2
          selected={selected}
          childrenList={['상품명', '브랜드명']}
          onSelect={setSelected}
        ></Dropdown2> */}
        <div className="flex justify-center flex-row">
          <div className="mb-3 xl:w-96">
            <div className="input-group relative flex flex-row flex-wrap items-stretch w-full mb-4 rounded">
              <input
                type="search"
                className="form-control relative flex-auto w-9/12 min-w-0 block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchInput"
                onChange={(e) => setSearchVal(e.target.value)}
              />
              <button
                className="input-group-text flex items-center px-3 py-1.5 text-base font-normal text-gray-700 text-center whitespace-nowrap rounded"
                id="basic-addon2"
                onClick={useOnSearch}
                type="button"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="search"
                  className="w-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <h2>선택항목</h2>
        <div className="flex space-x-2 justify-end items-center">
          <div>전체상품 수</div>
          <div className="text-green-500">{totalProductsCount}</div>
          <div>개</div>
        </div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              {tableHeaders &&
                tableHeaders.map((tableHeader) => (
                  <Table.Th key={tableHeader}>{tableHeader}</Table.Th>
                ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(currProducts, (product) => (
              <Table.Row key={product.productId}>
                <Table.Td>{product.productId}</Table.Td>
                <Table.Td>{product.isVisible ? '진열' : '진열안함'}</Table.Td>
                <Table.Td>
                  <img src={product.thumbnail} />
                </Table.Td>
                <Table.Td>{product.productName}</Table.Td>
                <Table.Td>
                  <div className="flex flex-col">
                    {/* <div>{product.vendorName}</div> */}
                    <div>{product.brandName}</div>
                  </div>
                </Table.Td>
                <Table.Td>
                  <div className="flex flex-col">
                    <div>{product.price}</div>
                    <div>{product.discountPrice}</div>
                  </div>
                </Table.Td>
                {/* <Table.Td>{product.productCode}</Table.Td> */}
                <Table.Td className="text-right">
                  <Link className="font-normal text-blue-500" to={`/admin/products/${product.id}`}>
                    수정/삭제하기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pagination2>
          {/* <Pagination.Label
            count={exhibitions.items.length}
            total={exhibitions.total}
          /> */}
          <Pagination2.Nav onMove={setPage} total={totalProductsCount} here={page} />
        </Pagination2>
      </Card>
    </>
  );
};
