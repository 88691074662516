import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { parse, stringify } from 'qs';

import _ from 'lodash';
import { sortBy } from 'lodash';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper as JSSwiper } from 'swiper';

import { default as htmlReactParser, attributesToProps } from 'html-react-parser';

import dayjs from 'dayjs';

import { Button } from '../../../../components/Button';
import { Section } from '../../../../components/Section';

import LogoDayoutPNG3 from '../../../../assets/img/icon-logo-dayout3.png';

import IconKakaotalkRegularPNG from '../../../../assets/img/icon-kakaotalk-regular.png';

import { campingTypesObj2 } from './booking/constants';

import { getBanners } from '../../../../api_v2/non_admin/banners';

import { ConveyanceMainPage } from './conveyance/ConveyanceMainPage';
import { Icon } from '../../../../components/Icon';
import {
  authUserTokenObjState,
  meState,
  modalState,
  popupType1State,
} from '../../../../ridge/ridge';
import { logout } from '../../../../api_v2/non_admin/users';

import { BottomNavBar } from './components/BottomNavBar';
import { getCampingEditorByType } from '../../../../api_v2/non_admin/onda';
import { PopupType1 } from '../components/PopupType1';

SwiperCore.use([Pagination, Autoplay]);

enum TabType {
  BOOKING = 'BOOKING',
  CONVEYANCE = 'CONVEYANCE',
}

enum BannerType {
  MAIN_1_CAMPING = 'main1Camping',
  MAIN_2_CAMPING = 'main2Camping',
  MAIN_3_CAMPING = 'main3Camping',
  MAIN_4_CAMPING = 'main4Camping',
}

const TabTypeObj: { [key: string]: any } = {
  BOOKING: '캠핑장 예약',
  CONVEYANCE: '캠핑장 양도',
};

const tabs = [TabType?.BOOKING, TabType?.CONVEYANCE];

export const MainPage = (props: any) => {
  let authUserTokenObj = authUserTokenObjState.useValue();
  const me = meState.useValue();

  const { search } = useLocation();

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const history = useHistory();
  const [campingSearchInput, setCampingSearchInput] = useState('');
  const [chosenTab, setChosenTab] = useState<any | null>(TabType?.BOOKING);

  const tabsRef = useRef<any | null>(null);

  const handleCampingType = (campingTypeName: string) => {
    let tempObj: { [key: string]: any } = {};
    if (campingTypeName) {
      tempObj['camping_type'] = campingTypeName ?? '';
    }
    handleSearch(tempObj);
  };

  const handleCampingSearchInput = (e: any) => {
    setCampingSearchInput(e.target.value);
  };

  const handleSearch = (queryStrObj: { [key: string]: any }) => {
    const queryString = stringify(queryStrObj, { addQueryPrefix: true, encode: false });
    history.push('/v2/camping/search/result' + queryString);
  };

  const [mainTopCampingBanners, setMainTopCampingBanners] = useState([]);

  useEffect(() => {
    if (window?.Kakao?.isInitialized() == false) {
      if (process.env.REACT_APP_KAKAO_DEVELOPERS_JAVASCRIPT_KEY) {
        window?.Kakao?.init(process.env.REACT_APP_KAKAO_DEVELOPERS_JAVASCRIPT_KEY);
      } else {
        console.log('no kakao js key');
      }
    }
  }, []);

  const handleKakaoAuthorization = () => {
    if (process.env.REACT_APP_KAKAO_REDIRECT_URI) {
      window?.Kakao?.Auth?.authorize({
        redirectUri: process.env.REACT_APP_KAKAO_REDIRECT_URI ?? '/v2/camping',
        // throughTalk: true,
      });
    } else {
      console.log('no kakao redirect uri');
    }
  };

  useEffect(() => {
    if (document?.referrer) {
      const referrerUrl = document?.referrer ?? 'https://www.google.com/';
      let newUrl = new URL(referrerUrl);
      let hostname = newUrl?.hostname;
      if (hostname) {
        let referrerList: any[] = ['dayout', 'pitching'];
        let found = referrerList.find((item: any) => hostname.includes(item));
        if (found) {
          if (parsedSearch?.referrer_login_type) {
            if (parsedSearch?.referrer_login_type === 'kakao') {
              console.log('kakao');
              let lsAuthUserTokenObj = localStorage.getItem('authUserTokenObj');
              if (lsAuthUserTokenObj) {
                if (JSON.parse(lsAuthUserTokenObj)) {
                  let parsedLsAuthUserTokenObj = JSON.parse(lsAuthUserTokenObj);
                } else {
                  logout();
                  handleKakaoAuthorization();
                }
              } else {
                handleKakaoAuthorization();
              }
            } else {
              logout();
            }
          } else {
            logout();
            console.log('no referrer_login_type');
          }
        } else {
          console.log('not found');
        }
      }
    }
  }, [document?.referrer]);

  const [addCampingBanners, setAddCampingBanners] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (![(parsedSearch?.chosen_tab as string)?.toUpperCase()].includes(TabType.CONVEYANCE)) {
      let tempObj = {
        where: { type: 'mainTopCamping' },
      };
      getBanners(stringify(tempObj, { addQueryPrefix: true, encode: false })).then((res) => {
        if (res?.success && res?.data) {
          setMainTopCampingBanners(res.data);
        }
      });
      let addBannersPromises = ['main1Camping', 'main2Camping', 'main3Camping', 'main4Camping'].map(
        async (bannerType) => {
          return getBanners(
            stringify(
              {
                where: { type: bannerType },
              },
              { addQueryPrefix: true, encode: false }
            )
          );
        }
      );
      Promise.all(addBannersPromises).then((data) => {
        loop1: for (let i = 0; i < data.length; i++) {
          if (data[i]?.data?.length > 0) {
            loop2: for (let j = 0; j < data[i]?.data.length; j++) {
              let bannerType = data[i]?.data[j]?.type;
              // if (addCampingBanners?.hasOwnPropert(bannerType)) {
              //   setAddCampingBanners((prev) => {return {...prev, [bannerType]: [...addCampingBanners[bannerType], data[i]?.data[j]] }})
              // } else {
              //   setAddCampingBanners((prev) => {return {...prev, [bannerType]: data[i]?.data }})
              // }
              setAddCampingBanners((prev) => {
                return { ...prev, [bannerType]: data[i]?.data };
              });
              break loop2;
            }
          }
        }
      });
    }
  }, []);

  let createSwiperForAddCampingBanner = (slidesData: any, swiperOption: any) => {
    return (
      <Swiper
        pagination={false}
        // autoplay={{
        //   delay: 3000,
        // }}
        onPaginationRender={(swiper, paginationEl) => {
          if (paginationEl) {
            paginationEl.style.cssText = ' padding-bottom: 5px';
          }
        }}
        slidesPerView={swiperOption?.slidesPerView ?? 'auto'}
        spaceBetween={swiperOption?.spaceBetween ?? 4}
        slidesOffsetBefore={swiperOption?.slidesOffsetBefore ?? 20}
        slidesOffsetAfter={swiperOption?.slidesOffsetAfter ?? 20}
        loop={false}
        className="mySwiper z-[0]"
      >
        {slidesData?.length > 0 &&
          slidesData.map((item: any) => (
            <div key={item?.id}>
              <SwiperSlide
                className="h-auto max-w-full"
                onClick={() => {
                  handleCampingBannerSwiperSlide(item);
                }}
              >
                <img className="object-cover" src={item?.thumbnail ?? ''} />
              </SwiperSlide>
            </div>
          ))}
      </Swiper>
    );
  };

  const handleCampingSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      let tempObj: { [key: string]: any } = {};
      if (campingSearchInput) {
        tempObj['search_query'] = (campingSearchInput as string)?.trim() ?? '';
      }
      handleSearch(tempObj);
    }
  };

  const handleCampingSearchButton = (evt: any) => {
    let tempObj: { [key: string]: any } = {};
    if (campingSearchInput) {
      tempObj['search_query'] = (campingSearchInput as string)?.trim() ?? '';
    }
    handleSearch(tempObj);
  };

  const checkResHistory = () => {
    history.push('/v2/camping/reservation/check-w-on');
  };

  const handleCampingBannerSwiperSlide = (bannerData: any) => {
    // history.push(bannerData?.url ?? '/v2/camping');
    window.location.href = bannerData?.url ?? '/v2/camping';
  };

  const handleChosenTab = (tabData: any) => {
    history.push(
      tabData == TabType?.BOOKING
        ? '/v2/camping?chosen_tab=booking'
        : tabData == TabType?.CONVEYANCE
        ? '/v2/camping?chosen_tab=conveyance'
        : '/v2/camping'
    );
  };

  useEffect(() => {
    if (parsedSearch?.chosen_tab) {
      setChosenTab((parsedSearch?.chosen_tab as string)?.toUpperCase());
    }
  }, [parsedSearch?.chosen_tab]);

  const modalTypes = (modalType: string) => {
    if (modalType == 'LOGIN') {
      history.push('/v2/login');
    } else if (modalType == 'MY_PAGE') {
      const onClose = () => modalState.set({ ...modalState.get(), open: false });
      modalState.set({
        title: '마이페이지로 이동하시겠습니까?',
        subtitle: '',
        open: true,
        onClose,
        onClick: async () => {
          window.location.href = '/v2/camping/mypage';
          onClose();
        },
      });
    }
  };

  const handleUserAuthIcon = (loginStatus: string) => {
    if (loginStatus == 'LOGGED_IN') {
      modalTypes('MY_PAGE');
    } else if (loginStatus == 'LOGGED_OUT') {
      modalTypes('LOGIN');
    }
  };
  const [mainEditorHtml, setMainEditorHtml] = useState<any>(null);

  useEffect(() => {
    getCampingEditorByType('main', '').then((res) => {
      if (res?.success && res?.data) {
        setMainEditorHtml(res?.data?.content ?? '');
      }
    });
  }, []);

  useLayoutEffect(() => {
    if (mainEditorHtml) {
      const parser = new DOMParser();
      const document = parser.parseFromString(mainEditorHtml, 'text/html');

      let swiperContainerEles = document.querySelectorAll('.swiper-container');

      if (swiperContainerEles) {
        for (let i = 0; i < swiperContainerEles.length; i++) {
          let slidesPerView = (swiperContainerEles[i] as any)?.dataset?.slidesperview;
          let spaceBetween = (swiperContainerEles[i] as any)?.dataset?.spacebetween;
          let slidesOffsetBefore = (swiperContainerEles[i] as any)?.dataset?.slidesoffsetbefore;
          let slidesOffsetAfter = (swiperContainerEles[i] as any)?.dataset?.slidesoffsetafter;

          let swiper = new JSSwiper('#' + swiperContainerEles[i]?.id, {
            slidesPerView: slidesPerView ?? 2.5,
            spaceBetween: spaceBetween ? parseInt(spaceBetween) : 5,
            slidesOffsetBefore: slidesOffsetBefore ? parseInt(slidesOffsetBefore) : 10,
            slidesOffsetAfter: slidesOffsetAfter ? parseInt(slidesOffsetAfter) : 10,
            on: {
              afterInit: function (swiper) {},
            },
          });
        }
      }
    }
  }, [mainEditorHtml]);

  const stopPopupType1 = () => {
    localStorage.setItem('stopPopupType1', JSON.stringify(true));
  };

  const nextPopupType1 = () => {
    let nextTime = dayjs().add(2, 'day');
    localStorage.setItem('nextPopupType1', JSON.stringify(nextTime));
  };

  const removeNextPopupType1 = () => {
    localStorage.removeItem('nextPopupType1');
  };

  const checkPopupType1 = () => {
    let lsNextPopupType1 = localStorage.getItem('nextPopupType1') as any;
    let lsStopPopupType1 = localStorage.getItem('stopPopupType1') as any;

    return { lsNextPopupType1, lsStopPopupType1 };
  };
  const jsonParseFunc = (jsonStrData: any) => {
    try {
      let parsed = JSON.parse(jsonStrData);
      return parsed;
    } catch (e) {
      console.log('e', e);
      return null;
    }
  };

  useEffect(() => {
    if (props.popupType1) {
      const onClose = () => {
        popupType1State.set({ ...popupType1State.get(), open: false });
        nextPopupType1();
      };

      if (
        checkPopupType1()?.lsStopPopupType1?.length > 0 &&
        checkPopupType1()?.lsStopPopupType1 == 'true'
      ) {
      } else {
        let setObj: any = { open: true, onClose };
        if (checkPopupType1()?.lsNextPopupType1) {
          let parsedTime = jsonParseFunc(checkPopupType1()?.lsNextPopupType1);
          if (parsedTime) {
            let diffTime = dayjs(parsedTime).diff(dayjs(), 'day');

            if (diffTime <= 0) {
              setObj.onClick = async () => {
                stopPopupType1();
                removeNextPopupType1();
                window.location.href = 'https://open.kakao.com/o/gSomAGjf';
              };
              popupType1State.set(setObj);
            }
          }
        } else {
          setObj.onClick = async () => {
            stopPopupType1();
            window.location.href = 'https://open.kakao.com/o/gSomAGjf';
          };
          popupType1State.set(setObj);
        }
      }
    }
  }, [props.popupType1]);

  const popupType1StateValue = popupType1State.useValue();

  return (
    <>
      {popupType1StateValue && <PopupType1 {...popupType1StateValue} />}
      <div className={`w-screen sm:w-full md:w-full lg:w-full bg-white relative`}>
        <div className="fixed top-0 bg-[white] z-[1] max-w-md w-screen">
          <div className="grid grid-cols-6 place-items-center pt-6 pb-6 bg-white">
            <div></div>
            <div
              className="col-start-1 col-span-2 flex items-center cursor-pointer"
              onClick={() => {
                window.location.href = '/v2/camping';
              }}
            >
              {/* <LogoDayout className="mr-2" /> */}
              <div className="w-[100px] mr-2">
                <img src={LogoDayoutPNG3} className="w-full" />
              </div>
            </div>
            {/* {authUserTokenObj ? (
              <div
                onClick={() => handleUserAuthIcon('LOGGED_IN')}
                className="rounded-[50px] w-[30px] h-[30px] border-[1px] 
              font-[300] text-[1.0em] flex justify-center items-center cursor-pointer"
              >
                {me?.userInfo?.name ? me?.userInfo?.name[0] : null}
              </div>
            ) : (
              <div
                className="w-[25px] flex flex-col justify-center items-center cursor-pointer"
                onClick={() => handleUserAuthIcon('LOGGED_OUT')}
              >
                <img src={IconCafe24MyPNG} className="w-full" />
                <div className="text-[9px]">MY</div>
              </div>
            )} */}
          </div>
          {/* <div ref={tabsRef}>
            <Swiper
              className="mySwiper max-w-md w-screen side-padding"
              slidesPerView="auto"
              spaceBetween={6}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
              freeMode={true}
            >
              {tabs.map((item) => (
                <SwiperSlide className="w-auto">
                  <Button
                    onClick={() => {
                      handleChosenTab(item);
                    }}
                    key={item}
                    className={`${
                      item === chosenTab
                        ? 'border-b-4 border-black text-black-50 font-[700] text-[18px]'
                        : 'bg-white'
                    } w-max font-semibold text-13 px-3 h-10`}
                  >
                    {TabTypeObj[item]}
                  </Button>
                </SwiperSlide>
              ))}
            </Swiper>
          </div> */}
        </div>
        {chosenTab === TabType?.BOOKING ? (
          <>
            <div className="side-padding mt-[80px] ">
              <div className="flex flex-row-reverse w-full items-center px-4 h-12 border border-[#FF6400] rounded bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4">
                <div className="flex justify-center items-center">{/* <Icon.Search /> */}</div>
                <Button
                  className="bg-[#FF6400] text-[#FFFFFF] rounded-[12px]"
                  style={{ height: '80%' }}
                  onClick={(e) => handleCampingSearchButton(e)}
                >
                  검색
                </Button>
                {campingSearchInput ? (
                  <>
                    <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                      <Icon.X
                        className="wh-3"
                        onClick={() => {
                          setCampingSearchInput('');
                        }}
                      />
                    </div>
                  </>
                ) : null}
                <input
                  className="flex-1 text-sm bg-white"
                  placeholder="검색하기"
                  onChange={(e) => handleCampingSearchInput(e)}
                  value={campingSearchInput}
                  onKeyDown={(e) => handleCampingSearchKeyDown(e)}
                />
              </div>
              <Section className="bg-white mb-4">
                <Swiper
                  pagination={{ dynamicBullets: true }}
                  // autoplay={{
                  //   delay: 3000,
                  // }}
                  onPaginationRender={(swiper, paginationEl) => {
                    if (paginationEl) {
                      paginationEl.style.cssText =
                        'position: absolute; bottom: 20px!important; padding-bottom: 15px';
                    }
                  }}
                  loop={false}
                  className="mySwiper z-[0]"
                >
                  {mainTopCampingBanners &&
                    mainTopCampingBanners.map((item: any, index2) => (
                      <div key={item?.id}>
                        <SwiperSlide
                          className="h-auto max-w-full"
                          onClick={() => {
                            handleCampingBannerSwiperSlide(item);
                          }}
                        >
                          <img className="object-cover" src={item?.thumbnail ?? ''} />
                        </SwiperSlide>
                      </div>
                    ))}
                </Swiper>
              </Section>
              <Section className="bg-white">
                <div className="grid grid-cols-4 gap-2">
                  {campingTypesObj2.map((campingTypeObj: any) => (
                    <div
                      className={`flex flex-col justify-center items-center cursor-pointer`}
                      key={campingTypeObj.name}
                      onClick={() => handleCampingType(campingTypeObj.name)}
                    >
                      <div>{<campingTypeObj.icon className="col-start-2 cursor-pointer" />}</div>
                      <div>{campingTypeObj.name == '캠핑' ? '캠핑장' : campingTypeObj.name}</div>
                    </div>
                  ))}
                </div>
              </Section>
            </div>
            {mainEditorHtml && htmlReactParser(mainEditorHtml)}
            {/* {window.location.origin.includes('localhost') ||
            window.location.origin.includes('ngrok') ||
            window.location.origin.includes('stage') ? (
              mainEditorHtml && htmlReactParser(mainEditorHtml)
            ) : (
              <>
                {(addCampingBanners[BannerType?.MAIN_1_CAMPING]?.length > 0 ||
                  addCampingBanners[BannerType?.MAIN_2_CAMPING]?.length > 0) && (
                  <div className="bg-gray-100 w-full h-2 mt-[20px]" />
                )}
                <Section>
                  {addCampingBanners[BannerType?.MAIN_1_CAMPING]?.length > 0 && (
                    <div className="mt-[20px]">
                      <p className="ml-[20px] mb-[10px]">
                        {addCampingBanners[BannerType?.MAIN_1_CAMPING][0]?.title}
                      </p>
                      {createSwiperForAddCampingBanner(
                        addCampingBanners[BannerType?.MAIN_1_CAMPING],
                        {
                          slidesPerView: 2.7,
                          spaceBetween: 6,
                          slidesOffsetBefore: 20,
                          slidesOffsetAfter: 20,
                        }
                      )}
                    </div>
                  )}
                  {addCampingBanners[BannerType?.MAIN_2_CAMPING]?.length > 0 && (
                    <div className="mt-[20px]">
                      <p className="ml-[20px] mb-[10px]">
                        {addCampingBanners[BannerType?.MAIN_2_CAMPING][0]?.title}
                      </p>
                      {createSwiperForAddCampingBanner(
                        addCampingBanners[BannerType?.MAIN_2_CAMPING],
                        {
                          slidesPerView: 2.7,
                          spaceBetween: 6,
                          slidesOffsetBefore: 20,
                          slidesOffsetAfter: 20,
                        }
                      )}
                    </div>
                  )}
                </Section>
                {addCampingBanners[BannerType?.MAIN_3_CAMPING]?.length > 0 && (
                  <div className="bg-gray-100 w-full h-2 mt-[20px]" />
                )}
                <Section>
                  {addCampingBanners[BannerType?.MAIN_3_CAMPING]?.length > 0 && (
                    <>
                      <div className="mt-[20px]">
                        <p className="ml-[20px] mb-[10px]">
                          {addCampingBanners[BannerType?.MAIN_3_CAMPING][0]?.title}
                        </p>
                        {createSwiperForAddCampingBanner(
                          addCampingBanners[BannerType?.MAIN_3_CAMPING],
                          {
                            slidesPerView: 1.3,
                            spaceBetween: 6,
                            slidesOffsetBefore: 20,
                            slidesOffsetAfter: 20,
                          }
                        )}
                      </div>
                    </>
                  )}
                </Section>
                {addCampingBanners[BannerType?.MAIN_4_CAMPING]?.length > 0 && (
                  <div className="bg-gray-100 w-full h-2 mt-[20px]" />
                )}
                <Section>
                  {addCampingBanners[BannerType?.MAIN_4_CAMPING]?.length > 0 && (
                    <>
                      <div className="mt-[20px]">
                        <p className="ml-[20px] mb-[10px]">
                          {addCampingBanners[BannerType?.MAIN_4_CAMPING][0]?.title}
                        </p>
                        {createSwiperForAddCampingBanner(
                          addCampingBanners[BannerType?.MAIN_4_CAMPING],
                          {
                            slidesPerView: 2.7,
                            spaceBetween: 6,
                            slidesOffsetBefore: 20,
                            slidesOffsetAfter: 20,
                          }
                        )}
                      </div>
                    </>
                  )}
                </Section>
              </>
            )} */}

            {/* <div className="pb-[100px]" />
            <div
              className="fixed bottom-0 w-full max-w-md bg-white grid grid-cols-1 place-items-center 
            pb-4 z-10 border-t border-gray-150
            rounded-t-[10px] 
            "
            >
              <div className="">
                <div className={`w-full h-0.5 mx-auto`} />

                <div
                  className="flex items-center mt-4 cursor-pointer"
                  onClick={() => {
                    window.location.href = 'https://m.dayout.store/';
                  }}
                >
                  <div className="w-[100px] mr-2">
                    <img src={LogoDayoutPNG2} className="w-full" />
                  </div>
                  <p className="text-[#FF6400]">쇼핑하기</p>
                </div>
              </div>
            </div> */}
            <BottomNavBar />
          </>
        ) : chosenTab === TabType?.CONVEYANCE &&
          (parsedSearch?.chosen_tab as string)?.toUpperCase() == TabType.CONVEYANCE ? (
          <>
            <ConveyanceMainPage mainPageTabsRef={tabsRef} />
          </>
        ) : null}
        <Section className="bg-gray-100 side-padding mt-[20px]">
          <div className="pt-[20px]">
            <div>
              <div className="border-solid rounded border border-stone-300 float-right p-0.5 text-xs">
                <a href="/v2/camping/faq">자주묻는질문</a>
              </div>
              <p className="font-[700] text-[15px]">고객센터</p>
              <p>010-5288-4333</p>
              <p>평일 10:30 ~ 17:00 점심 12:30 ~ 14:00 </p>
              <p>토,일요일 및 공휴일 휴무</p>
            </div>
            <div className="w-full border my-3" />
            <div>
              <div className="font-[700] text-[15px] mb-3">주식회사 보딩패스</div>
              <div className="grid grid-cols-10">
                <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">주소</div>
                <div className="col-span-7">
                  서울특별시 성동구 아차산로7나길 18 (성수동 2가) 대선 APEXCENTER 905호
                </div>
              </div>
              <div className="grid grid-cols-10">
                <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">대표</div>
                <div className="col-span-7">조건형</div>
              </div>
              <div className="grid grid-cols-10">
                <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">
                  사업자등록번호
                </div>
                <div className="col-span-7">323-88-02118</div>
              </div>
              <div className="grid grid-cols-10">
                <div className="col-span-3 text-[#5C5C5C] text-[13px] place-self-start">
                  통신판매업신고
                </div>
                <div className="col-span-7">2022-서울성동-00191</div>
              </div>
            </div>
            <p className="my-3">
              보딩패스는 통신판매중개자로서 통신판매의 당사자가 아니며, 상품의 예약, 이용 및 환불
              등과 관련한 의무와 책임은 각 판매자에게 있습니다.
            </p>
          </div>

          <div className="pb-[100px]" />
        </Section>
        <div className="absolute bottom-[330px] right-0 w-[30px] h-[30px]">
          <div className="flex justify-end max-w-md">
            {/* <div
              onClick={() => {
                // handleAddConveyanceInfoButton();
              }}
              className="fixed w-[60px] h-[60px] mr-5 rounded-[50px] bg-[#50d71e] text-[white] font-[300] text-[1.5em] 
        flex justify-center items-center cursor-pointer"
            >
              +
            </div> */}
            <div
              className="fixed bottom-[94px] w-[50px] h-[50px] mr-5 font-[300] text-[1.5em] 
        flex justify-center items-center cursor-pointer"
            >
              <img
                src={IconKakaotalkRegularPNG}
                className="w-full rounded-[30px]"
                onClick={() => {
                  // window.location.href = 'https://pf.kakao.com/_LZsJs/chat';
                  window.open('https://pf.kakao.com/_LZsJs/chat');
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
