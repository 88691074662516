import { api } from '../../../plugins/axios';

export const uploadCafe24ProductDetailVideo = async (videoFile: any) => {
  try {
    const formData = new FormData();
    formData.append('videoFiles', videoFile);

    let result = await api.post(
      `/admin/cafe24/dayout/commerce/product-detail/videos/upload-video`,
      formData,
      {
        headers: {
          accept: '*/*',
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const replaceCafe24ProductDetailVideo = async (id: any, videoFile: any) => {
  try {
    const formData = new FormData();
    formData.append('videoFiles', videoFile);

    let result = await api.patch(
      `/admin/cafe24/dayout/commerce/product-detail/videos/replace-video/${id}`,
      formData,
      {
        headers: {
          accept: '*/*',
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCafe24ProductDetailVideoDataList = async (queryString: string) => {
  try {
    let result = await api.get('/admin/cafe24/dayout/commerce/product-detail/videos' + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCafe24ProductDetailVideoData = async (id: any, queryString: string) => {
  try {
    let result = await api.get(
      `/admin/cafe24/dayout/commerce/product-detail/videos/${id}` + queryString
    );
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const createCafe24ProductDetailVideoData = async (param: any, queryString: any) => {
  try {
    let result = await api.post(
      `/admin/cafe24/dayout/commerce/product-detail/videos` + queryString,
      param
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const updateCafe24ProductDetailVideoData = async (param: any, queryString: any) => {
  try {
    let result = await api.patch(
      `/admin/cafe24/dayout/commerce/product-detail/videos/${param?.id}` + queryString,
      param
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteCafe24ProductDetailVideoData = async (id: any) => {
  try {
    let result = await api.delete(`/admin/cafe24/dayout/commerce/product-detail/videos/${id}`);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};
