import { api } from '../../../plugins/axios';

export const getBanners = async (queryString: any = '') => {
  try {
    let apiResult = await api.get('/banners' + `${queryString}`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(apiResult.status)) {
      return { success: true, data: apiResult.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.error('e', e);
  }
};
