import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from './Icon';
import { Product } from '../types';

import { productPageLinkState } from '../ridge/products/index';

interface ProductCardProps {
  product: Product;
  ranking?: number;
  className?: string;
  to?: string;
  bookmark?: boolean;
  removeBookmarked?: any;
  bookMarkedInfo?: any;
  hasPadding?: boolean;
  isBigSize?: boolean;
  key?: string | number;
}

export const ProductCard: FC<ProductCardProps> = ({
  ranking,
  product,
  className,
  to,
  bookmark,
  removeBookmarked,
  hasPadding = true,
  isBigSize = false,
  key,
}) => {
  const [productPageLink, setProductPageLink] = productPageLinkState.use();
  const history = useHistory();
  if (!product) return null;
  const {
    discountPrice,
    price,
    thumbnail,
    brandName,
    productName,
    rate,
    summary,
    productId,
    referenceLink,
  } = product;

  // const isStockProd = checkIsInStockProd(product.quantity);

  return (
    <div
      className={`${className} relative cursor-pointer`}
      onClick={() => {
        setProductPageLink(referenceLink);
        history.push(`${to}`);
      }}
    >
      {typeof ranking === 'number' && ranking <= 10 && (
        <div className="absolute z-10 top-0 left-0 bg-black text-white text-center p-1 w-8 h-8 inline-block">
          {ranking}
        </div>
      )}
      <div className="aspect-w-1 aspect-h-1">
        <img
          src={thumbnail ? thumbnail : `https://source.unsplash.com/random`}
          alt=""
          className="w-full h-full object-cover rounded-md"
        />
        {/* {!isStockProd && (
          <div className='absolute flex items-center top-0 left-0 bg-black/[.7] justify-center'>
            <p className={`text-brand-1 font-bold ${isBigSize ? 'text-3xl' : 'text-lg'}`}> SOLD OUT </p>
          </div>
        )} */}
      </div>
      {bookmark ? (
        <button
          className="absolute z-10 right-1 top-1 p-3"
          onClick={(e) => {
            removeBookmarked(productId);
            e.stopPropagation();
          }}
        >
          <Icon.X className="wh-6 p-1 border border-gray-100 bg-white rounded-full" />
        </button>
      ) : null}
      <div className={`${hasPadding ? 'px-4 py-3' : 'py-3'} `}>
        <span className="text-gray-600 text-12 font-semibold">{brandName}</span>
        <h3 className={`truncate-2-lines font-normal ${isBigSize && 'text-18'}`}>{productName}</h3>
        <div className="flex space-x-2 items-center my-0.5">
          {/* <div className="flex items-center space-x-1">
            <Icon.Star className="text-[#FFD600] fill-current wh-4" />
            <span className="text-12 font-bold">{rate}</span>
          </div> */}

          {100 - (discountPrice / price) * 100 === 0 ? null : (
            <h3 className={`text-brand-1 font-semibold ${isBigSize && 'text-18'}`}>
              {(100 - (discountPrice / price) * 100).toFixed(0)}%{' '}
            </h3>
          )}

          <h3 className={`flex justify-between font-semibold ${isBigSize && 'text-18'}`}>
            <p className="">{discountPrice && discountPrice.toLocaleString()}원</p>
          </h3>
        </div>
        {/* <div className="flex justify-between items-center">
          <p className="text-12 text-gray-500 font-semibold">{summary}</p>
          <h3
            className={`flex justify-between font-semibold ${
              isBigSize && 'text-18'
            }`}
          >
            <p className="">{discountPrice.toLocaleString()}원</p>
          </h3>
        </div> */}
      </div>
    </div>
  );
};
