import { stringify } from 'qs';
import { api } from '../../../plugins/axios';
import { apiForTempSol } from '../../../tempSolution/apiForTempSol';

export const getCampingInfoListV2 = async (queryString: any) => {
  try {
    let result = await api.get('/properties' + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return result.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCampingInfoV2 = async (id: any, queryString?: string) => {
  try {
    let result = await api.get(`/properties/onda-api/${id}` + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCampingRoomTypesV2 = async (id: any, queryString?: string) => {
  try {
    let result = await api.get(`/properties/onda-api/${id}/roomtypes` + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCampingRoomTypeV2 = async (
  campingId: any,
  roomTypeId: any,
  queryString?: string
) => {
  try {
    let result = await api.get(
      `/properties/onda-api/${campingId}/roomtypes/${roomTypeId}` + queryString
    );
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e: any) {
    console.log('e', e);
    return { success: false, message: e?.response?.data?.data?.message };
    // if (e?.response?.data?.data?.message) {
    //   return { success: false, message: e?.response?.data?.data?.message };
    // } else {
    //   return { success: false };
    // }
  }
};

export const getCampingAvailRoomTypeV2 = async (
  propertyId: any,
  roomtypeId: any,
  queryString?: string
) => {
  try {
    let result = await api.get(
      `/properties/onda-api/${propertyId}/availroomtypes/${roomtypeId}` + queryString
    );
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
    return null;
  }
};

export const getCampingAvailRoomTypesV2 = async (id: any, queryString?: string) => {
  try {
    let result = await api.get(`/properties/onda-api/${id}/availroomtypes` + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCampingRefundPolicyBeforeReservationV2 = async (
  campingId: any,
  roomTypeId: any,
  ratePlanId: any,
  queryString?: string
) => {
  try {
    let result = await api.get(
      `/properties/onda-api/${campingId}/roomtypes/${roomTypeId}/rateplans/${ratePlanId}/refundpolicy` +
        queryString
    );
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const checkCampingAvailBeforeReservationV2 = async (
  campingId: any,
  roomTypeId: any,
  ratePlanId: any,
  queryString?: string
) => {
  try {
    let result = await api.get(
      `/properties/onda-api/${campingId}/roomtypes/${roomTypeId}/rateplans/${ratePlanId}/checkavail` +
        queryString
    );
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e: any) {
    console.log('e', e);

    if (e?.response?.status == 403) {
      return { success: false, message: e?.response?.data?.data?.message };
    } else {
      return { success: false };
    }
  }
};

export const createBookingV2 = async (createBookingParams: any) => {
  try {
    // let result = await api.post(apiForTempSol + '/bookings', createBookingParams);
    let result = await api.post('/onda-bookings', createBookingParams);

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
  }
};

export const updateBookingV2 = async (mUid: any, updateBookingParams: any) => {
  try {
    // let result = await api.post(apiForTempSol + '/bookings', createBookingParams);
    let result = await api.patch(`/onda-bookings/${mUid}`, updateBookingParams);

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
  }
};

export const cancelReservationForNonmemberV2 = async (cancelBookingParams: any) => {
  try {
    // let result = await api.post(apiForTempSol + '/bookings/check', checkBookingParams);

    let result = await api.patch(
      `/onda-bookings/cancel/${cancelBookingParams?.bookingNo}`,
      cancelBookingParams
    );

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data, message: result?.data?.message };
    } else return { success: false };
  } catch (e: any) {
    console.log('e', e);
    if (e?.response?.data?.message) {
      return { success: false, message: e?.response?.data?.message };
    }
  }
};

export const cancelReservationForMemberV2 = async (cancelBookingParams: any) => {
  try {
    let result = await api.patch(
      `/onda-bookings/cancel/member/${cancelBookingParams?.bookingNo}`,
      cancelBookingParams
    );

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data, message: result?.data?.message };
    } else return { success: false };
  } catch (e: any) {
    console.log('e', e);
    if (e?.response?.data?.message) {
      return { success: false, message: e?.response?.data?.message };
    }
  }
};

export const checkPaymentV2 = async (createPaymentParams: any) => {
  try {
    // let result = await api.post(apiForTempSol + '/payments', createPaymentParams);
    let result = await api.post(
      `/onda-payments/check/${createPaymentParams?.merchantUid}`,
      createPaymentParams
    );

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
    return { success: false, error: e };
  }
};

export const checkBookingV2 = async (checkBookingParams: any) => {
  try {
    // let result = await api.post(apiForTempSol + '/bookings/check', checkBookingParams);
    let result = await api.post('/onda-bookings/check', checkBookingParams);

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
  }
};

export const getBookingsV2 = async (queryString: any) => {
  try {
    // let result = await api.get(apiForTempSol + '/bookings' + queryString);
    let result = await api.get('/onda-bookings' + queryString);

    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
  }
};

export const getBookingV2 = async (id: any, queryString?: string) => {
  try {
    // let result = await api.get(apiForTempSol + `/bookings/${id}` + queryString);
    let result = await api.get(`/onda-bookings/${id}` + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const requestResetBookingPasswordForNonmeberV2 = async (resetParams: any) => {
  try {
    // let result = await api.get(apiForTempSol + `/bookings/${id}` + queryString);
    let result = await api.post(`/onda-bookings/request-reset-password/nonmember`, resetParams);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e: any) {
    console.log('e', e);
    if (e?.response?.data?.message) {
      return { success: false, message: e?.response?.data?.message };
    } else {
      return { success: false };
    }
  }
};

export const resetBookingPasswordForNonmeberV2 = async (resetParams: any) => {
  try {
    // let result = await api.get(apiForTempSol + `/bookings/${id}` + queryString);
    let result = await api.post(`/onda-bookings/reset/password/nonmember`, resetParams);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e: any) {
    console.log('e', e);
    if (e?.response?.data?.message) {
      return { success: false, message: e?.response?.data?.message };
    } else {
      return { success: false };
    }
  }
};

export const getCampingEditorByType = async (type: any, queryString?: string) => {
  try {
    let result = await api.get(`/onda-editors?type=${type}` + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getPropertiesLowestPrices = async (queryString?: string) => {
  try {
    let result = await api.get(`/properties/price/lowest` + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
    return null;
  }
};
