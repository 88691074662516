import { api } from '../../../plugins/axios';
import { CreateExhibitionParams, UpdateExhibitionParams } from './types';

export const getExhibition = async (id: number) => {
  try {
    let result = await api.get(`/admin/exhibitions/${id}`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getExhibitions = async (queryString: string) => {
  try {
    let result = await api.get('/admin/exhibitions' + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const createExhibition = async (createExhibitionParams: {
  [key: string]: any;
}) => {
  try {
    let result = await api.post('/admin/exhibitions', createExhibitionParams);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const updateExhibition = async (
  id: number,
  updateExhibitionParams: {
    [key: string]: any;
  }
) => {
  try {
    let result = await api.patch(
      `/admin/exhibitions/${id}`,
      updateExhibitionParams
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteExhibition = async (id: number) => {
  let result = await api.delete(`/admin/exhibitions/${id}`);
  let possibleStatus = [200, 201];
  if (possibleStatus.includes(result.status)) {
    return true;
  } else return false;
};
