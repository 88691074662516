import { useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { Toggle } from '../../../components/Toggle';
import { Search } from '../../../components/Search';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { useHistory, useParams } from 'react-router-dom';
import useDebounce from '../../../hooks/common';
// import { DEBOUNCE_THRESHOLD_MS } from './constants';
import { find, map, uniq } from 'lodash';
import { format } from 'date-fns';
import FroalaEditor from './components/FroalaEditor';
import { stringify } from 'qs';
import { useProducts } from '../../../hooks/product';
import ExhibitionProductDnd from './components/ExhibitionProductDnd';
import { useMutation } from 'react-query';

import { toast } from 'react-toastify';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button as AntdButton,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select as AntdSelect,
  Slider,
  Switch as AntdSwitch,
  Upload,
  Input,
  TimePicker,
} from 'antd';
import 'antd/dist/antd.css';
import type { UploadFile } from 'antd/es/upload/interface';

import { uploadImage } from '../../../api/photo';
import { CreateExhibitionParams, ExhibitionParams } from '../../../api_v2/admin/exhibitions/types';
import { getProduct, getProducts } from '../../../api_v2/admin/products';
import { createExhibition } from '../../../api_v2/admin/exhibitions';
import { ImagesUpload } from './components/ImagesUpload';
import { api } from '../../../plugins/axios';

import './style.css';
import { Calendar } from './components/Calendar';
import { AntdCalendar } from './components/AntdCalendar';
import { createCampingZone } from '../../../api_v2/admin/camping';

enum ETCType {
  TRAILER = '트레일러 진입',
  CAMPING_VEHICLE = '캠핑카 진입',
  LONG_TERM = '장박가능 여부',
}

const { TextArea } = Input;

const { Option: AntdOption } = AntdSelect;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 50 },
};

export const CampingZoneAdd = () => {
  const history = useHistory();
  const { campingId } = useParams<{ campingId: string }>();

  const editorContentRef = useRef('');
  const [editorContent, setEditorContent] = useState('');
  const [calendarState, setCalendarState] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [locale, setLocale] = useState<any>('ko');

  // const [editorImagesObj, setEditorImagesObj] = useState<{ [key: string]: any }>({});

  const editorImagesObjRef = useRef<{ [key: string]: any }>({});

  const [calMatchingObj, setCalMatchingObj] = useState<{ [key: string]: any }>({
    // 2022: {
    //   9: { 5: { available: 2, additionalFee: 1000 }, 6: { available: 4, additionalFee: 3000 } },
    //   10: { 1: { available: 3, additionalFee: 3000 }, 28: { available: 6, additionalFee: 6000 } },
    // },
  });
  const [changedCalObj, setChangedCalObj] = useState<{ [key: string]: any }>({});

  const [campingZoneAddStatus, setCampingZoneAddStatus] = useState(false);

  const onFinish = async (values: any) => {
    setCampingZoneAddStatus(true);
    const dataToSend: { [key: string]: any } = {
      campingId: parseInt(campingId),
      siteCount: 0,
      siteWidth: 0,
      siteDepth: 0,
      description: values['zone-description'],
      name: values['zone-name'],
      extraPrice: values['zone-additional-fee'],
      adultNum: values['max-adult'],
      minorNum: values['max-children'],
      peopleAmount: values['max-guests'],
      carNum: values['max-parking'],
      parkingType: '주차장',
      isCampingCar: false,
      isTrailer: false,
      isLongTerm: false,
      isVisible: values['campinginfo-switch1'] == undefined ? false : values['campinginfo-switch1'],
    };

    let { etc } = values;
    if (etc && etc.length > 0) {
      for (let i = 0; i < etc.length; i++) {
        if (etc[i] === ETCType.CAMPING_VEHICLE) {
          dataToSend.isCampingCar = true;
        } else if (etc[i] === ETCType.TRAILER) {
          dataToSend.isTrailer = true;
        } else if (etc[i] === ETCType.LONG_TERM) {
          dataToSend.isLongTerm = true;
        }
      }
    }

    let mainImagesPromisesResult: any[] = [];
    if (values['main-images'] && values['main-images'].length > 0) {
      let mainImagesPromises = values['main-images'].map(async (mainImageObj: any) => {
        const headers = {
          headers: {
            accept: '*/*',
            'Content-Type': 'multipart/form-data',
          },
        };
        const mainImageFormData = new FormData();
        mainImageFormData.append('file', mainImageObj.originFileObj);
        let mainImagePhotoRes = await api.post('/photo', mainImageFormData, headers);
        return mainImagePhotoRes?.data?.url ?? '';
      });

      mainImagesPromisesResult = await Promise.all(mainImagesPromises);
    }
    dataToSend.images = mainImagesPromisesResult;

    // setCampingZoneAddStatus(true);
    let createCampingZoneResult = await createCampingZone(parseInt(campingId), dataToSend);
    if (createCampingZoneResult) {
      toast.success('성공적으로 캠핑존을 등록하였습니다.');
    }
    setCampingZoneAddStatus(false);
  };

  const normFile = (e: any) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <>
      <AdminH1>캠핑장 구역 추가</AdminH1>
      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:px-8">
          <Form name="validate_other" {...formItemLayout} onFinish={onFinish}>
            <div>
              <Form.Item name="campinginfo-switch1" valuePropName="checked" label="진열상태">
                <AntdSwitch />
              </Form.Item>
              <Form.Item
                name="zone-name"
                label="구역 명"
                rules={[{ required: true, message: '구역명' }]}
              >
                <Input placeholder="구역 명" />
              </Form.Item>
              <Form.Item
                name="zone-additional-fee"
                label="구역 추가 금액"
                rules={[{ required: true, message: '구역 추가 금액' }]}
              >
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item name="zone-description" label="구역 세부 정보">
                <TextArea rows={4} placeholder="구역 세부 정보" />
              </Form.Item>

              <Form.Item label="최대인원">
                <Form.Item
                  name="max-adult"
                  label="성인"
                  rules={[{ required: true, message: '성인' }]}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  name="max-children"
                  label="미성년"
                  rules={[{ required: true, message: '미성년' }]}
                >
                  <InputNumber min={0} />
                </Form.Item>
              </Form.Item>
              <Form.Item
                name="max-guests"
                label="인원제한"
                rules={[{ required: true, message: '인원제한' }]}
              >
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item
                name="max-parking"
                label="최대주차"
                rules={[{ required: true, message: '최대주차' }]}
              >
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item name="etc" label="기타">
                <Checkbox.Group>
                  <Row>
                    {['트레일러 진입', '캠핑카 진입', '장박가능 여부'].map((item) => (
                      <Col span={16} key={item}>
                        <Checkbox value={item} style={{ lineHeight: '32px' }}>
                          {item}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </div>
            <Form.Item label="메인 이미지 정보">
              <Form.Item
                name="main-images"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
              >
                <Upload.Dragger
                  // defaultFileList={[...fileList]}
                  listType="picture"
                  name="files"
                  action="/upload.do"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
            {/* <Form.Item>
              <AntdCalendar
                stateObj={{ calMatchingObj, setCalMatchingObj, changedCalObj, setChangedCalObj }}
              />
            </Form.Item> */}
            <Form.Item wrapperCol={{ offset: 18 }}>
              <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
                {campingZoneAddStatus ? (
                  <div>캠핑존 등록중...</div>
                ) : (
                  <>
                    <Button
                      text="취소"
                      className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
                      to={`/admin/camping/${campingId}/zones`}
                      disabled={campingZoneAddStatus}
                    />
                    <AntdButton
                      className="add-submit-button button md:max-w-md h-10 text-sm filled-black"
                      htmlType="submit"
                      disabled={campingZoneAddStatus}
                    >
                      추가하기
                    </AntdButton>
                  </>
                )}
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </>
  );
};
