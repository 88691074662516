import { api } from '../../../../plugins/axios';

export const getTDCAccommCampaigns = async (queryString: string) => {
  try {
    let result = await api.get('/community/tdc-accommodations/data/campaigns' + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};
