import { api } from './axios';
import {
  currentDeviceInfoFromDeviceState,
  deviceInfoState,
  deviceInfoTokenState,
  historyState,
  osState,
  tokenState,
} from '../ridge/ridge';
import { createDeviceInfo, editDeviceInfo, getDeviceInfo } from '../api_v2/non_admin/device-info';
import { addBookmarkedProduct, removeBookmarkedProduct } from '../api_v2/non_admin/bookmarks';
import { productScrapState } from '../ridge/products';

enum OSType {
  IOS = 'ios',
  ANDROID = 'android',
}

export enum Action {
  ERROR = 'error',
  LOG = 'log',
  SET_APPLE_TOKEN = 'setAppleToken',
  SET_FCM_TOKEN = 'setFcmToken',
  REDIRECT_URL = 'redirectUrl',
  OPEN_SETTINGS = 'openSettings',
  CHANGE_PUSH_NOTIFICATION_SETTINGS = 'changePushNotificationSettings',
  OPEN_URL = 'openUrl',
  GET_CURRENT_DEVICE_INFO = 'getCurrentDeviceInfo',
  WEBVIEW_GO_BACK = 'webviewGoBack',
  WEBVIEW_GO_FORWARD = 'webviewGoForward',
  OPEN_MODAL_WITH_URL = 'openModalWithUrl',
  ADD_BOOKMARKED_PRODUCT = 'addBookmarkedProduct',
  REMOVE_BOOKMARKED_PRODUCT = 'removeBookmarkedProduct',
  CHANGE_SCRAP_STATUS = 'changeScrapStatus',
}

interface Command {
  kind?: 'webview';
  action: Exclude<keyof typeof Bridge, 'prototype' | 'os' | 'postMessage' | 'handleMessageEvent'>;
  value?: any;
}

function tryParseJSON(jsonString: any) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
}

export class Bridge {
  static os: string;

  static postMessage(action: Action, value?: any) {
    const data = JSON.stringify({ action, value });
    (window as any).ReactNativeWebView?.postMessage(data);
  }

  static handleMessageEvent({ data }: MessageEvent) {
    const command = tryParseJSON(data) as Command;
    if (command?.kind !== 'webview') return;
    if (Bridge[command.action]) {
      Bridge[command.action](command.value);
    } else {
      Bridge.postMessage(Action.ERROR, `(RN -> WebView) Invalid action: ${data}`);
    }
  }

  static async setFcmToken(receivedData: any) {
    try {
      const { fcmToken, deviceUniqueId, os } = receivedData;

      let deviceInfoTokenLocal = deviceInfoTokenState.get();

      if (deviceInfoTokenLocal) {
        let deviceInfoResult = await getDeviceInfo();
        if (deviceInfoResult?.success) {
          if (deviceInfoResult?.data) {
            const { fcmToken: fcmTokenFromDB } = deviceInfoResult.data;

            if (!fcmTokenFromDB || fcmTokenFromDB !== fcmToken) {
              let editDeviceInfoResult = await editDeviceInfo({ fcmToken: fcmToken });
              if (editDeviceInfoResult?.success && editDeviceInfoResult.data) {
                if (editDeviceInfoResult?.data?.fcmToken) {
                }
              }
            } else {
            }
          }
        }
      } else {
        let createDeviceInfoResult = await createDeviceInfo({
          deviceUniqueId,
          fcmToken,
          os,
          marketingAgreement: true,
          eventPushNotification: true,
        });
        if (createDeviceInfoResult?.success && createDeviceInfoResult.data) {
          if (createDeviceInfoResult?.data?.deviceInfoToken) {
            deviceInfoTokenState.set(createDeviceInfoResult.data.deviceInfoToken);
          }
          if (createDeviceInfoResult?.data?.fcmToken) {
          }
        }
      }
    } catch (e) {
      console.log('e', e);
    }
  }

  static async changePushNotificationSettings(settingData: any) {
    try {
      const { deviceUniqueId, fcmToken, os, enabledStatus } = settingData;

      if (typeof enabledStatus === 'boolean') {
        let deviceInfoResult = await getDeviceInfo();

        if (deviceInfoResult) {
          if (deviceInfoResult?.success) {
            if (deviceInfoResult?.data) {
              const { eventPushNotification, marketingAgreement } = deviceInfoResult.data;
              if (eventPushNotification !== enabledStatus) {
                const editDeviceInfoResult = await editDeviceInfo({
                  eventPushNotification: enabledStatus,
                  marketingAgreement: enabledStatus,
                });
                if (editDeviceInfoResult?.success && editDeviceInfoResult?.data) {
                  deviceInfoState.set({
                    eventPushNotification: editDeviceInfoResult.data?.eventPushNotification,
                    marketingAgreement: editDeviceInfoResult.data?.marketingAgreement,
                  });
                }
              }
            }
          }
        } else {
          if (enabledStatus) {
            let createDeviceInfoResult = await createDeviceInfo({
              deviceUniqueId,
              fcmToken,
              os,
              marketingAgreement: enabledStatus,
              eventPushNotification: enabledStatus,
            });
            if (createDeviceInfoResult?.success && createDeviceInfoResult?.data) {
              if (createDeviceInfoResult?.data?.deviceInfoToken) {
                deviceInfoTokenState.set(createDeviceInfoResult.data.deviceInfoToken);
              }
              if (createDeviceInfoResult?.data?.fcmToken) {
              }
              deviceInfoState.set({
                eventPushNotification: enabledStatus,
                marketingAgreement: enabledStatus,
              });
            }
          }
        }
      }
      // }
    } catch (e) {
      console.log('e', e);
    }
  }

  // Current device info from user's device (not from DB)
  static async getCurrentDeviceInfo(currDeviceInfo: any) {
    try {
      if (currDeviceInfo) {
        const { version: currVersion, buildNumber: currBuildNumber } = currDeviceInfo;
        if (currVersion && currBuildNumber) {
          currentDeviceInfoFromDeviceState.set({
            version: currVersion,
            buildNumber: currBuildNumber,
          });
        }
      }

      // let productionInfoFromDB = await getProductionByOS(
      //   isAndroid ? OSType.ANDROID : isIOS ? OSType.IOS : ''
      // );

      // if (productionInfoFromDB && currDeviceInfo) {
      //   if (productionInfoFromDB?.success && productionInfoFromDB?.data) {
      //     const {
      //       version: versionFromDB,
      //       buildNumber: buildNumberFromDB,
      //       isVisible,
      //     } = productionInfoFromDB.data;

      //     if (
      //       versionFromDB !== currVersion ||
      //       buildNumberFromDB.toString() !== currBuildNumber.toString()
      //     ) {
      //       productionInfoFromDBState.set(productionInfoFromDB.data);
      //       // const onClose = () => modalState.set({ ...modalState.get(), open: false });
      //       // modalState.set({
      //       //   title: '업데이트',
      //       //   subtitle: `${versionFromDB} - ${buildNumberFromDB}`,
      //       //   open: true,
      //       //   onClose,
      //       //   onClick: async () => {
      //       //     Bridge.postMessage(
      //       //       Action.OPEN_URL,
      //       //       isAndroid
      //       //         ? 'https://play.google.com/store/apps/details?id=com.pridge'
      //       //         : isIOS
      //       //         ? 'https://apps.apple.com/kr/app/%ED%94%84%EB%A6%BF%EC%A7%80/id1615311070'
      //       //         : ''
      //       //     );
      //       //     onClose();
      //       //   },
      //       // });
      //     } else {
      //       productionInfoFromDBState.reset();
      //     }
      //   }
      // }
    } catch (e) {
      console.log('e', e);
    }
  }

  static async addBookmarkedProduct(receivedData: any) {
    try {
      const { productId } = receivedData;
      let addBookmarkedProductResult = await addBookmarkedProduct(productId);
      if (addBookmarkedProductResult?.success) {
        productScrapState.set(true);
        Bridge.postMessage(Action.CHANGE_SCRAP_STATUS, JSON.stringify({ scrapStatus: true }));
      } else {
      }
    } catch (e) {
      console.log('e', e);
    }
  }

  static async removeBookmarkedProduct(receivedData: any) {
    try {
      const { productId } = receivedData;

      let removeBookmarkedProductResult = await removeBookmarkedProduct(productId);
      if (removeBookmarkedProductResult?.success) {
        productScrapState.set(false);
        Bridge.postMessage(Action.CHANGE_SCRAP_STATUS, JSON.stringify({ scrapStatus: false }));
      } else {
      }
    } catch (e) {
      console.log('e', e);
    }
  }

  static async setAppleToken(userInfo: string) {
    const { user: appleId, email } = JSON.parse(userInfo);
    const { data: user } = await api.get(`user/apple/${appleId}`);
    if (user) {
      api.post('/auth/appleLogin', { appleId }).then(({ data: { token } }) => {
        tokenState.set(token);
      });
    } else {
      localStorage.setItem('signUpInfo', JSON.stringify({ appleId, email }));
      const history = historyState.get();
      if (history && history?.push) {
        history.push('/signup');
        historyState.set(history);
      } else {
        window.location.href = '/signup';
      }
    }
  }

  static redirectUrl(value: string) {
    if (value) {
      const history = historyState.get();
      if (history && history?.push) {
        history.push(value);
        historyState.set(history);
      } else {
        window.location.href = value;
      }
    }
  }

  static init({ os }: any) {
    osState.set(os ?? 'webView');
  }
}

// @ts-ignore
document.addEventListener('message', Bridge.handleMessageEvent);
window.addEventListener('message', Bridge.handleMessageEvent);
