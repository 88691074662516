import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';

import { toast } from 'react-toastify';

import { Button } from '../../../../components/Button';
import FroalaEditor from './components/FroalaEditor';

import { authUserTokenObjState } from '../../../../ridge/ridge';

import { ReactComponent as IconTrashImg } from '../../../../assets/icons/icon-trash.svg';
import { ReactComponent as IconUpdateImg } from '../../../../assets/icons/icon-update.svg';
import { ReactComponent as IconImageUploadImg } from '../../../../assets/icons/icon-image-upload.svg';
import { Icon } from '../../../../components/Icon';

import { editCommunityPost, uploadImages } from '../../../../api_v2/non_admin/community';
enum CategoryType {
  DOMESTIC_TRAVEL = 'domesticTravel',
  INTERNATIONAL_TRAVEL = 'internationalTravel',
  DOMESTIC_FESTIVAL = 'domesticFestival',
  INTERNATIONAL_FESTIVAL = 'internationalFestival',
  ETC = 'etc',
}

const categoryNameObj: { [key: string]: any } = {
  [CategoryType.DOMESTIC_TRAVEL]: '국내여행',
  [CategoryType.INTERNATIONAL_TRAVEL]: '해외여행',
  [CategoryType.DOMESTIC_FESTIVAL]: '국내축제',
  [CategoryType.INTERNATIONAL_FESTIVAL]: '해외축제',
  [CategoryType.ETC]: '기타',
};

export default function PostEdit(props: any) {
  const authUserTokenObj = authUserTokenObjState.useValue();
  const [open, setOpen] = useState(true);
  const [images, setImages] = useState<any>([]);
  const maxNumber = 5;
  const [categories, setCategories] = useState<any>({
    [CategoryType.DOMESTIC_TRAVEL]: false,
    [CategoryType.INTERNATIONAL_TRAVEL]: false,
    [CategoryType.DOMESTIC_FESTIVAL]: false,
    [CategoryType.INTERNATIONAL_FESTIVAL]: false,
    [CategoryType.ETC]: false,
  });
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [content, setContent] = useState<any>('');
  const [editPostStatus, setEditPostStatus] = useState<any>(false);

  const handleEditPost = async () => {
    try {
      setEditPostStatus(true);
      let postId = props?.item?.uniqueId;
      if (selectedCategories?.length >= 1) {
        let deleteImagesList = [];
        let uploadNewImagesList = [];
        let finalImagesList = [];
        // delete images
        for (let i = 0; i < props?.item?.images.length; i++) {
          let givenImageUrl = props?.item?.images[i]?.url;
          let foundImage = images.find((imgObj: any) => imgObj['data_url'] == givenImageUrl);
          if (!foundImage) {
            deleteImagesList.push(givenImageUrl);
          }
        }
        let tempIndices = [];
        // upload new images
        for (let j = 0; j < images.length; j++) {
          let imageObj = images[j];
          if (imageObj?.file) {
            uploadNewImagesList.push({ ...imageObj, finalInd: j });
            finalImagesList[j] = null;
            tempIndices.push(j);
          } else {
            finalImagesList[j] = { url: imageObj['data_url'] };
          }
        }

        let uploadImagesRes;
        if (uploadNewImagesList?.length >= 1) {
          uploadImagesRes = await uploadImages(postId, uploadNewImagesList);
        }
        if (uploadImagesRes && uploadImagesRes?.length >= 1) {
          for (let l = 0; l < uploadImagesRes?.length; l++) {
            finalImagesList[uploadNewImagesList[l].finalInd] = {
              url: uploadImagesRes[l]?.data?.url,
            };
          }
        } else {
        }

        let editCommunityPostRes = await editCommunityPost({
          id: props?.item?.id,
          uniqueId: postId,
          images: finalImagesList,
          categories: selectedCategories,
          content: editorDetail?.trim(), //content?.trim(),
          deleteImagesList,
        });

        if (editCommunityPostRes?.success == true) {
          props.refetchMyPagePosts();
          toast.success('성공적으로 수정하였습니다.');
          props?.closeDrawer();
        } else {
          toast.error('오류가 발생하였습니다.');
        }
      } else {
        window.confirm('카테고리를 선택해주세요');
      }
      setEditPostStatus(false);
    } catch (e) {}
  };

  const onChange = (imageList: any, addUpdateIndex: any) => {
    setImages(imageList);
  };

  const onError = (errors: any, files: any) => {
    if (errors?.maxNumber == true) {
      window.confirm('사진은 최대 5장까지 등록이 가능합니다.');
    }
  };

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;

    setCategories({
      ...categories,
      [name]: checked,
    });
  };

  useEffect(() => {
    const newSelectedCategories = Object.keys(categories).filter(
      (category: any) => categories[category]
    );
    setSelectedCategories(newSelectedCategories);
  }, [categories]);

  const handleContentChange = (e: any) => {
    setContent(e?.target?.value);
  };

  useEffect(() => {
    if (props?.item) {
      // setContent(props?.item?.content ?? '');
      setEditorDetail(props?.item?.content ?? '');

      if (props?.item?.categories?.length > 0) {
        let givenCategories: { [key: string]: any } = {
          [CategoryType.DOMESTIC_TRAVEL]: false,
          [CategoryType.INTERNATIONAL_TRAVEL]: false,
          [CategoryType.DOMESTIC_FESTIVAL]: false,
          [CategoryType.INTERNATIONAL_FESTIVAL]: false,
          [CategoryType.ETC]: false,
        };

        for (let i = 0; i < props?.item?.categories.length; i++) {
          let givenCate = props?.item?.categories[i];
          givenCategories[givenCate] = true;
        }
        setCategories((prev: any) => {
          return { ...givenCategories };
        });
      }
      // if (props?.item?.images?.length > 0) {
      let givenImages = [];
      for (let i = 0; i < props?.item?.images?.length; i++) {
        let imageUrl = props?.item?.images[i]?.url;
        givenImages.push({ data_url: imageUrl });
      }
      setImages(givenImages);
      // }
    }
  }, [props?.item]);

  const [editorDetail, setEditorDetail] = useState('');

  return (
    <div>
      <div className="mb-[10px]">
        <div onClick={() => setOpen(!open)} className="flex justify-between pb-4 cursor-pointer">
          <h4>카테고리</h4>
          <Icon.ChevronDown className={`${open ? 'rotate-180' : ''} transition-transform z-[0]`} />
        </div>
        {open && (
          <div className="flex flex-wrap">
            {Object.keys(categories).map((category) => (
              <>
                <label className="mr-[7px] my-[5px]">
                  <input
                    className="mr-[7px]"
                    type="checkbox"
                    name={category}
                    checked={categories[category]}
                    onChange={handleCheckboxChange}
                  />
                  {categoryNameObj[category]}
                </label>
              </>
            ))}
          </div>
        )}
      </div>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        onError={onError}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          <div className="upload__image-wrapper mb-[10px]">
            <div className="flex items-center justify-between mb-[0px]">
              <button
                className="border-[2px] border-[#dcdcde] rounded-[10px] p-[10px]"
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <IconImageUploadImg width={'30px'} />
              </button>
            </div>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            <div className="flex flex-wrap mb-[5px]">
              {imageList.map((image, index) => (
                <div
                  key={index}
                  className="image-item relative w-[80px] aspect-[1/1] mr-[5px] mb-[5px]"
                >
                  <img
                    src={image['data_url']}
                    alt=""
                    className="w-full h-full rounded-[8px] object-cover"
                  />
                  <div className="image-item__btn-wrapper absolute top-[0px] flex justify-between w-full rounded-t-[8px] bg-[black] opacity-[0.65] p-[2px]">
                    <button onClick={() => onImageUpdate(index)}>
                      <IconUpdateImg width={'20px'} height={'20px'} />
                    </button>
                    <button onClick={() => onImageRemove(index)}>
                      <IconTrashImg fill="white" width={'20px'} height={'20px'} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>
      {/* <textarea
        className="w-full mb-[5px] rounded-[10px] resize-none border-[#dcdcde]"
        style={{ height: '30vh', maxHeight: '300px' }}
        placeholder=""
        value={content}
        onChange={(e) => {
          handleContentChange(e);
        }}
      /> */}
      <div className="mb-[10px]">
        <FroalaEditor
          defaultValue={editorDetail}
          onChange={(detailModel) => {
            setEditorDetail(detailModel);
          }}
        />
      </div>
      <Button
        disabled={editPostStatus}
        onClick={() => {
          handleEditPost();
        }}
        className="w-full h-[50px] mb-[25px] rounded font-bold text-[#FFFFFF] bg-[#FF6400] flex justify-center items-center"
      >
        {editPostStatus ? '수정중...' : '수정'}
      </Button>
    </div>
  );
}
