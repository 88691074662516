import { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TopNavBar } from '../../components/TopNavBar';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import Iframe from 'react-iframe';
import { productPageLinkState } from '../../ridge/products/index';
import {
  addBookmarkedProduct,
  checkBookmarkedProduct,
  removeBookmarkedProduct,
} from '../../api_v2/non_admin/bookmarks';

import { getProduct, getProductPageLink } from '../../api_v2/non_admin/products';
import { BottomNavBar2 } from '../../components/BottomNavBar2';
import axios from 'axios';
import { api } from '../../plugins/axios';
import { Markup } from 'interweave';
import parse from 'html-react-parser';
import { Action, Bridge } from '../../plugins/bridge';

SwiperCore.use([Pagination, Autoplay]);

enum SectionTarget {
  TYPE_0 = '상품리뷰',
  TYPE_1 = '상세정보',
  TYPE_2 = '안내/문의',
}

export const ProductPageLink = () => {
  const [scrap, setScrap] = useState(false);
  const history = useHistory();
  const { productId, linkType } = useParams<{ productId: string; linkType: string }>();
  // const [productPageLink, setProductPageLink] = productPageLinkState.use();
  const [bookmarkedProductStatus, setBookmarkedProductStatus] = useState(false);
  const [product, setProduct] = useState<{ [key: string]: any } | null>(null);

  const bottomNavBar2Ref = useRef<any>();
  const topNavBarRef = useRef<any>();

  const [init, setInit] = useState(true);
  useEffect(() => {
    setInit(false);
  }, []);

  useEffect(() => {
    getProduct(productId).then((resultData) => {
      if (resultData.success) {
        if (resultData.data && resultData.data.length > 0) {
        }
        setProduct(resultData.data[0]);
      }
    });
  }, []);

  const _checkUrl = (urlStr: string) => {
    let url;
    try {
      url = new URL(urlStr);
    } catch (e) {
      console.log('checkUrl error: ', e);
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  useEffect(() => {
    checkBookmarkedProduct(productId).then((resultData) => {
      if (resultData) {
        setScrap(true);
      } else {
        setScrap(false);
      }
    });
  }, []);

  return (
    <div className="w-full overflow-y-hidden">
      <div ref={topNavBarRef}>
        <TopNavBar
          nav={false}
          logo={false}
          title=""
          backButton={false}
          homeButton={true}
          xButton={true}
        />
      </div>

      <div
        style={{
          width: '100%',
          height: `${
            topNavBarRef?.current?.getBoundingClientRect()?.height &&
            bottomNavBar2Ref?.current?.getBoundingClientRect()?.y
              ? window.innerHeight -
                (topNavBarRef?.current?.getBoundingClientRect()?.height +
                  bottomNavBar2Ref?.current?.getBoundingClientRect()?.y +
                  5) +
                'px'
              : '0px'
          }`,
        }}
      >
        {product && product[linkType] && _checkUrl(product[linkType]) ? (
          <>
            <Iframe
              url={product[linkType]}
              width="100%"
              height="100%"
              // id="myId"
              // className="myClassname"
              // display="initial"
            />
            {/* <a href={product[linkType]} /> */}
            {/* <embed
              type="text/html"
              src={'https://m.google.com/search?q=%' + product[linkType]}
              width="100%"
              height="100%"
            /> */}
            {/* <object width="100%" height="100%" data={product[linkType]}></object> */}
            {/* <iframe width="100%" height="100%" src={product[linkType]} /> */}
          </>
        ) : (
          <></>
        )}
        <div ref={bottomNavBar2Ref}>
          <BottomNavBar2 productId={productId} />
        </div>
      </div>
    </div>
  );
};
