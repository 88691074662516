import { ComponentType, FC, useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { authUserTokenObjState } from '../../../../ridge/ridge';

export interface ProtectedRouteProps {
  path: string | string[];
  component: ComponentType;
  redirectTo?: string;
  exact?: boolean;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  path,
  component: Component,
  redirectTo = '/community/login',
  exact = true,
}) => {
  const authUserTokenObj = authUserTokenObjState.useValue();

  return (
    <Route
      exact
      path={path}
      render={() => {
        if (!authUserTokenObj) {
          return <Redirect to={redirectTo} />;
        }
        return <Component />;
      }}
    />
  );
};
