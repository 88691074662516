import { parse, stringify } from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { addDays } from 'date-fns';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { Button } from '../../../../components/Button';
import { getCampingBookingList } from '../../../../api_v2/admin/onda';
import moment from 'moment';

import { Calendar } from './Calendar';
import { Link, useLocation } from 'react-router-dom';
import { CustomDrawer } from './CustomDrawer';
import { Table } from '../../../../components/Table';
import { map } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { api } from '../../../../plugins/axios';

const { RangePicker } = DatePicker;

const propertyDescriptionObj: { [key: string]: any } = {
  booking_number: '온다 예약번호',
  channel_booking_number: '판매사 예약번호',
  status: '예약 상태',
  refund_status_iamport: '환불여부 (아임포트)',
  total_amount: '총 예약금액',
  refund_amount: 'refund_amount',
  charged_amount: 'charged_amount',
  confirmed_at: '예약 확정일시',
  canceled_at: '예약 취소일시',
  checkin: '체크인 시각',
  checkout: '체크아웃 시각',
  buyer_name: '이름',
  buyer_email: '이메일',
  // refund_status_iamport: '환불여부 (아임포트)',
};

enum BookingStatus {
  canceled = 'canceled',
  confirmed = 'confirmed',
}

export const Bookings = () => {
  const [downloadStatus, setDownloadStatus] = useState(false);

  const [infoList, setInfoList] = useState<{ [key: string]: any } | null>({});
  const [infoListTotal, setInfoListTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [infoListLoading, setInfoListLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();

  const convertDateForQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const drawerRef = useRef<HTMLDivElement | null>(null);

  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [defaultDates, setDefaultDates] = useState([new Date(), addDays(new Date(), 30)]);

  const [calendarState, setCalendarState] = useState<any>([
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: new Date(parsedSearch?.start_date as string),
          endDate: new Date(parsedSearch?.end_date as string),
          key: 'selection',
        }
      : {
          startDate: defaultDates[0],
          endDate: defaultDates[1],
          key: 'selection',
        },
  ]);

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const [locale, setLocale] = useState<any>('ko');

  const [option, setOption] = useState<any>(
    parsedSearch?.option ? parsedSearch?.option : 'checkout'
  );

  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '80%';
    }
  };

  const [pageLimit, setPageLimit] = useState(10);

  const fetchBookings = async ({ pageParam = 1 }) => {
    let currentQueryStringObj: { [key: string]: any } = {
      offset: (pageParam - 1) * pageLimit,
      limit: pageLimit,
      option: option,
      from: convertDateForQS(defaultDates[0]),
      to: convertDateForQS(defaultDates[1]),
    };

    for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
      let key = Object.keys(parsedSearch)[i];
      if (key === 'option') {
        currentQueryStringObj['option'] = parsedSearch[key];
      }
    }

    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      currentQueryStringObj['from'] = convertDateForQS(parsedSearch?.start_date);
      currentQueryStringObj['to'] = convertDateForQS(parsedSearch?.end_date);
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    // setInfoListLoading(true);

    // getCampingBookingList(currentQueryString).then((res) => {
    //   if (res?.success == true && res?.data) {
    //     setInfoList((prev) => {
    //       return { ...prev, [page]: res?.data?.ondaBookings?.reservations };
    //     });
    //     if (typeof res?.data?.ondaBookings?.count == 'number') {
    //       setInfoListTotal(res?.data?.ondaBookings?.count);
    //     }
    //     setPage(page + 1);
    //   }
    //   setInfoListLoading(false);
    // });
    setBookingsDataStatus(true);
    try {
      let result = await api.get(`/admin/onda-properties/onda-api/bookings${currentQueryString}`);
      setBookingsDataStatus(false);
      if ([200].includes(result?.status)) {
        return result;
      } else return null;
    } catch (e) {
      console.log('e', e);
      setBookingsDataStatus(false);
      return null;
    }
  };

  const [bookingsDataStatus, setBookingsDataStatus] = useState(false);

  const { data: bookingsData, fetchNextPage } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`adminOndaCampingBookingsPage`],
    fetchBookings,
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPage = Math.ceil((lastPage?.data?.ondaBookings?.count ?? 0) / pageLimit);
        const nextPage = allPages?.length + 1;
        return nextPage <= maxPage ? nextPage : undefined;
      },
      keepPreviousData: true,
    }
  );

  const lastElement = useCallback((target) => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && fetchNextPage) {
          fetchNextPage();
        } else {
        }
      },
      {
        threshold: 1,
      }
    );
    if (target) observer.current.observe(target);
  }, []);

  const calculateCurrListLen = (objData: any) => {
    let finalCount = 0;
    for (let i = 0; i < Object.keys(objData).length; i++) {
      let key = Object.keys(objData)[i];
      finalCount = finalCount + objData[key]?.length ?? 0;
    }
    return finalCount;
  };

  const handleSearch = () => {
    let currentQueryStringObj: { [key: string]: any } = {
      option: option,
      start_date: convertDateForQS(defaultDates[0]),
      end_date: convertDateForQS(defaultDates[1]),
    };

    if (calendarState[0]?.startDate && calendarState[0]?.endDate) {
      currentQueryStringObj['start_date'] = convertDateForQS(calendarState[0]?.startDate);
      currentQueryStringObj['end_date'] = convertDateForQS(calendarState[0]?.endDate);
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    window.location.href = '/admin/v2/camping/bookings' + currentQueryString;
  };

  const handleOptionChange = (e: any) => {
    setOption(e?.target?.value);
  };

  const calCancelAmt = (cancelData: any) => {
    let total = 0;
    if (cancelData) {
      cancelData.map((item: any) => {
        total = total + (item?.amount ?? 0);
      });
    }
    return total;
  };

  const handleInputFile = async (evt: any) => {
    let excelFile = evt.target.files[0];
    let reader = new FileReader();
    reader.readAsArrayBuffer(excelFile);
    reader.onload = function (event: any) {
      let tempObj: { [key: string]: any } = {};
      let result = event.target.result;
      const wb = XLSX.read(result, { type: 'buffer' });
      wb.SheetNames.forEach((sn) => {
        let data: any = XLSX.utils.sheet_to_json(wb.Sheets[sn]);
        for (let i = 0; i < data.length; i++) {
          let region = data[i]?.region;
          let city = region == '세종' ? '세종' : data[i]?.city;
          if (!tempObj.hasOwnProperty(region)) {
            tempObj[region] = [city];
          } else {
            tempObj[region] = [...tempObj[region], city];
          }
        }
      });
    };
  };
  // test
  const handleMore = () => {
    fetchNextPage();
  };

  return (
    <div className="">
      {/* <input type="file" onChange={(e) => handleInputFile(e)} /> */}
      <div className="flex flex-col mb-[15px]">
        <div className="flex justify-between mb-[5px]">
          <div className="flex items-center">
            {calendarState[0]?.startDate && calendarState[0]?.endDate ? (
              <div className="mr-[5px]">
                {convertDateForQS(calendarState[0]?.startDate) +
                  ' ~ ' +
                  convertDateForQS(calendarState[0]?.endDate)}
              </div>
            ) : null}
            <Button
              onClick={() => {
                openDrawer();
              }}
              className="h-[42px] text-sm text-[black] font-[400] border border-[#ADB5BD] mr-[5px]"
            >
              기간설정
            </Button>
            <div>
              <select
                id="options"
                name="options"
                placeholder="choose an option"
                value={option}
                onChange={(e) => handleOptionChange(e)}
              >
                <option value="checkout">checkout</option>
                <option value="checkin">checkin</option>
                <option value="confirmed">confirmed</option>
                <option value="canceled">canceled</option>
              </select>
            </div>
          </div>

          <Button
            className="h-[42px] text-sm text-[black] font-[400] border border-[#ADB5BD]"
            onClick={() => {
              handleSearch();
            }}
          >
            검색
          </Button>
        </div>

        <div className="flex justify-between mb-[5px]">
          <div></div>
          {/* <Button
            disabled={downloadStatus}
            onClick={() => {
              handleDownload();
            }}
            className="h-10 text-sm text-[black] outlined-gray-600"
          >
            다운로드
          </Button> */}
        </div>

        <div className="flex justify-between">
          <div>예약 목록</div>
          <div>
            전체 수:{' '}
            {bookingsDataStatus ? '...' : bookingsData?.pages[0]?.data?.ondaBookings?.count ?? 0}개
          </div>
        </div>
      </div>
      <Table className="absolute">
        <Table.Head className="sticky top-[-25px] bg-[aliceblue]">
          <Table.Row>
            <Table.Th></Table.Th>
            {Object.keys(propertyDescriptionObj).map((key) => (
              <Table.Th key={key}>{propertyDescriptionObj[key]}</Table.Th>
            ))}
            <Table.Th></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {bookingsData?.pages && bookingsData?.pages[0]?.data?.ondaBookings?.count > 0
            ? bookingsData?.pages.map(
                (page, groupInd) =>
                  page?.data?.ondaBookings?.reservations &&
                  page?.data?.ondaBookings?.reservations.map((item: any, itemInd: number) => (
                    <>
                      <Table.Row key={item?.booking_number}>
                        <Table.Td>{groupInd * pageLimit + (itemInd + 1)}</Table.Td>
                        <Table.Td>{item?.booking_number}</Table.Td>
                        <Table.Td>{item?.channel_booking_number}</Table.Td>
                        <Table.Td>
                          <p
                            className={`${
                              item?.status == BookingStatus.confirmed
                                ? 'text-[#1ff018]'
                                : item?.status == BookingStatus.canceled
                                ? 'text-[#f2303d]'
                                : ''
                            }`}
                          >
                            {item?.status}
                          </p>
                        </Table.Td>
                        <Table.Td>
                          <div>
                            {item?.paymentInfoFromIamport?.cancel_history?.length > 0 &&
                              calCancelAmt(
                                item?.paymentInfoFromIamport?.cancel_history
                              )?.toLocaleString()}
                          </div>
                          <div>
                            {item?.paymentInfoFromIamport?.cancel_history?.length > 0 ? (
                              <>
                                {item?.total_amount -
                                  calCancelAmt(item?.paymentInfoFromIamport?.cancel_history) ==
                                0 ? (
                                  <p className="text-[#1ff018]">전체 환불</p>
                                ) : item?.total_amount -
                                    calCancelAmt(item?.paymentInfoFromIamport?.cancel_history) >
                                  0 ? (
                                  <p className="text-[#e6d115]">부분환불</p>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {item?.status == 'canceled' &&
                                item?.paymentInfoFromIamport?.cancel_history.length <= 0 ? (
                                  <p className="text-[#f2303d]">환불진행중</p>
                                ) : null}
                              </>
                            )}
                          </div>
                        </Table.Td>
                        <Table.Td> {item?.total_amount?.toLocaleString()}</Table.Td>
                        <Table.Td>{item?.refund_amount?.toLocaleString()}</Table.Td>
                        <Table.Td>{item?.charged_amount?.toLocaleString()}</Table.Td>
                        <Table.Td>
                          {item?.confirmed_at &&
                            dayjs(item?.confirmed_at).format('YYYY-MM-DD HH:mm:ss')}
                        </Table.Td>
                        <Table.Td className="text-right">
                          {item?.canceled_at &&
                            dayjs(item?.canceled_at).format('YYYY-MM-DD HH:mm:ss')}
                        </Table.Td>
                        <Table.Td>{item?.checkin}</Table.Td>
                        <Table.Td>{item?.checkout}</Table.Td>
                        <Table.Td>{item?.paymentInfoFromIamport?.buyer_name}</Table.Td>
                        <Table.Td>{item?.paymentInfoFromIamport?.buyer_email}</Table.Td>

                        <Table.Td>
                          <Link to={`/admin/v2/camping/bookings/${item?.channel_booking_number}`}>
                            자세히 보기
                          </Link>
                        </Table.Td>
                      </Table.Row>
                    </>
                  ))
              )
            : null}

          {/* <div ref={lastElement}></div> */}
        </Table.Body>
        <Button
          className="h-10 w-max text-sm filled-black mt-[5px]"
          onClick={() => handleMore()}
          disabled={bookingsDataStatus}
        >
          {bookingsDataStatus ? <>...</> : <>더보기</>}
        </Button>
      </Table>

      <div>
        <CustomDrawer drawerRef={drawerRef}>
          <div className="side-padding mt-[75px]">
            <Calendar
              locale={locale}
              state={calendarState}
              setState={setCalendarState}
              setChosenDates={setChosenDates}
              // drawerRef={drawerRef}
              confirmButton={false}
            />
          </div>
        </CustomDrawer>
      </div>
    </div>
  );
};
