import { stringify } from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';

import { getCafe24UserDataCollection1List } from '../../../../api_v2/admin/cafe24-user-data/collection1';
import { Button } from '../../../../components/Button';

export const Collection1 = () => {
  const [downloadStatus, setDownloadStatus] = useState(false);

  const [infoList, setInfoList] = useState<{ [key: string]: any } | null>({});
  const [infoListTotal, setInfoListTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [infoListLoading, setInfoListLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();

  // prob1
  useEffect(() => {
    let currentQueryStringObj: { [key: string]: any } = {
      page: page,
      limit: 100,
    };

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    setInfoListLoading(true);

    getCafe24UserDataCollection1List(currentQueryString).then((res) => {
      if (res?.success == true && res?.data) {
        setInfoList((prev) => {
          return { ...prev, [page]: res?.data?.items };
        });
        if (typeof res?.data?.total == 'number') {
          setInfoListTotal(res?.data?.total);
        }
        setPage(page + 1);
      }
      setInfoListLoading(false);
    });
  }, []);

  const calculateCurrListLen = (objData: any) => {
    let finalCount = 0;
    for (let i = 0; i < Object.keys(objData).length; i++) {
      let key = Object.keys(objData)[i];
      finalCount = finalCount + objData[key]?.length ?? 0;
    }
    return finalCount;
  };

  const lastElement = useCallback(
    (target) => {
      if (infoListLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            infoList &&
            calculateCurrListLen(infoList) < infoListTotal &&
            !infoListLoading
          ) {
            let currentQueryStringObj: { [key: string]: any } = {
              page: page,
              limit: 100,
            };

            const currentQueryString = stringify(currentQueryStringObj, {
              addQueryPrefix: true,
              encode: false,
            });

            setInfoListLoading(true);

            getCafe24UserDataCollection1List(currentQueryString).then((res) => {
              if (res?.success == true && res?.data) {
                setInfoList((prev) => {
                  return { ...prev, [page]: res?.data?.items };
                });
                if (typeof res?.data?.total == 'number') {
                  setInfoListTotal(res?.data?.total);
                }
                setPage(page + 1);
              }
              setInfoListLoading(false);
            });
          } else {
          }
        },
        {
          threshold: 1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [infoListLoading, infoList, infoListTotal]
  );

  const handleDownload = () => {
    setDownloadStatus(true);
    getCafe24UserDataCollection1List('?limit=0')
      .then((res) => {
        if (res?.success == true && res?.data) {
          downloadExcelFile(res?.data, new Date().toLocaleDateString().replaceAll('/', '-'));
          setDownloadStatus(false);
        } else {
          setDownloadStatus(false);
        }
      })
      .catch((e) => {
        console.log('e', e);
        setDownloadStatus(false);
      });
  };

  const downloadExcelFile = (rawData: any, dataName: any) => {
    const newBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newBook, XLSX.utils.json_to_sheet(rawData, dataName));
    XLSX.writeFile(newBook, `${dataName}.xlsx`);
  };
  return (
    <div>
      <div className="flex flex-col mb-[15px]">
        <div className="flex justify-between mb-[5px]">
          <div></div>
          <Button
            disabled={downloadStatus}
            onClick={() => {
              handleDownload();
            }}
            className="h-10 text-sm text-[black] outlined-gray-600"
          >
            다운로드
          </Button>
        </div>
        <div className="flex justify-between">
          <div>User data collection 1</div>
          <div>전체 수: {infoListTotal}개</div>
        </div>
      </div>

      {infoList && (
        <div className="grid grid-cols-3 gap-1">
          <div>Member ID</div>
          <div>휴대폰</div>
          <div>타입</div>
          <div className="col-span-3 border-b"></div>
          {/* {cafe24UserDataCollection1List?.map((item) => (
            <div className="col-span-2 grid grid-cols-2">
              <div className="">{item?.memberId}</div>
              <div>{item?.cellphone}</div>
            </div>
          ))} */}
          {infoList && Object.keys(infoList as any)?.length > 0
            ? Object.keys(infoList as any).map(
                (page, groupInd) =>
                  infoList[page] &&
                  infoList[page].map((item: any, itemInd: number) => (
                    <>
                      {Object.keys(infoList as any)?.length == groupInd + 1 &&
                      infoList[page].length == itemInd + 1 ? (
                        <>
                          <div
                            ref={lastElement}
                            key={item?.id}
                            className="col-span-3 grid grid-cols-3"
                          >
                            <div>{item?.memberId}</div>
                            <div>{item?.cellphone}</div>
                            <div>{item?.type}</div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div ref={null} key={item?.id} className="col-span-3 grid grid-cols-3">
                            <div>{item?.memberId}</div>
                            <div>{item?.cellphone}</div>
                            <div>{item?.type}</div>
                          </div>
                        </>
                      )}
                    </>
                  ))
              )
            : null}
        </div>
      )}
    </div>
  );
};
