import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../../../../../../../components/Button';
import { Calendar } from './Calendar';
import { toast } from 'react-toastify';
import { api } from '../../../../../../../../plugins/axios';
import { Table } from '../../../../../../../../components/Table';
import Chart from 'chart.js/auto';
import ChartType1 from './ChartType1';

const ViewData = () => {
  const [tableData, setTableData] = useState<any>([]);

  const [getDataStatus, setGetDataStatus] = useState<any>(false);

  const [dateData, setDateData] = useState<any>(null);

  const [getDataError, setGetDataError] = useState<any>(null);

  useEffect(() => {
    setGetDataStatus(true);
    api
      .get('/admin/ai/community/ga/data-process/community-post/view-data')
      .then((res) => {
        if (res?.data?.date && res?.data?.data?.length >= 0) {
          setTableData(res?.data?.data);
          setDateData(res?.data?.date);
        }
        setGetDataStatus(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          setGetDataError(err?.response?.data?.message);
        }

        setGetDataStatus(false);
      });
  }, []);

  const convertDate = (dateData: any) => {
    const yr = new Date(dateData).getFullYear();
    const mon = new Date(dateData).getMonth() + 1;
    const dt = new Date(dateData).getDate();
    const day = new Date(dateData).getDay();
    const dayList = ['일', '월', '화', '수', '목', '금', '토'];
    const convertedDay = dayList[day] ? '(' + dayList[day] + ')' : '';
    const finalStr = yr + '.' + mon + '.' + dt + convertedDay;
    return finalStr;
  };

  const camelCaseToTitle = (str: any) => {
    try {
      return str
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        .replace(/^./, function (match: any) {
          return match.toUpperCase();
        });
    } catch (e) {
      return null;
    }
  };

  return (
    <div>
      {getDataError ? (
        <p className="text-[red] text-center">{getDataError}</p>
      ) : (
        <>
          {getDataStatus ? (
            <>
              <div className="flex justify-center items-center">
                <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
              </div>
            </>
          ) : (
            <>
              <div className="my-[15px]">
                <p className="font-[700] text-center">데이터 기간</p>
                <div className="grid grid-cols-2 place-items-center mt-3">
                  <div className="flex flex-col items-center">
                    <div className="text-[#FF6400] text-[15px]">start date</div>
                    <div className="text-[20px]">
                      {dateData?.startDate && convertDate(dateData.startDate)}
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="text-[#FF6400] text-[15px]">end date</div>
                    <div className="text-[20px]">
                      {dateData?.endDate && convertDate(dateData.endDate)}
                    </div>
                  </div>
                </div>
              </div>
              {tableData.length > 0 && (
                <div className="my-[15px]">
                  <ChartType1 data={tableData} />
                </div>
              )}

              {tableData.length > 0 && (
                <>
                  <div
                    style={{
                      position: 'sticky',
                      top: '-25px',
                      background: '#33FFF0',
                      // zIndex: 999,
                      // overflowX: 'scroll',
                    }}
                    className="grid grid-cols-6"
                  >
                    {[
                      'pagePath',
                      'views',
                      'users',
                      'viewsPerUser',
                      'averageEngagementTime',
                      'numberOfEvents',
                    ]?.map((header: any, index: any) => (
                      <div
                        key={header}
                        className="break-all flex-1 p-2 uppercase font-semibold text-left text-gray-700 border border-gray-300"
                      >
                        {header && camelCaseToTitle(header)}
                      </div>
                    ))}
                  </div>
                  <div>
                    {tableData.map((row: any, rowIndex: any) => (
                      <div key={rowIndex} className="grid grid-cols-6">
                        {[
                          'pagePath',
                          'views',
                          'users',
                          'viewsPerUser',
                          'averageEngagementTime',
                          'numberOfEvents',
                        ].map((cell: any, cellIndex: any) => (
                          <div
                            key={cellIndex}
                            className="break-all flex-1 p-2 text-left text-gray-700 border border-gray-300"
                          >
                            {row[cell]}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ViewData;
