import { useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { Toggle } from '../../../components/Toggle';
import { Search } from '../../../components/Search';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { useHistory } from 'react-router-dom';
import useDebounce from '../../../hooks/common';

import { find, map, uniq } from 'lodash';
import { format } from 'date-fns';
import FroalaEditor from '../../components/FroalaEditor';
import { stringify } from 'qs';
import { useProducts } from '../../../hooks/product';

import { useMutation } from 'react-query';
import { CreateExhibitionParams } from '../../../api/exhibition/type';
import { createExhibition } from '../../../api/exhibition';
import { toast } from 'react-toastify';
import { uploadImage } from '../../../api_v2/admin/photo';
import { CreateProductParams } from '../../../api_v2/admin/products/types';
import { createProduct } from '../../../api_v2/admin/products';
import { ToastEditor } from '../../components/ToastEditor';
import { Icon } from '../../../components/Icon';

export const ProductAdd = () => {
  const history = useHistory();
  const [formState, setFormState] = useState<CreateProductParams>({
    productName: '',
    brandName: '',
    supplyName: '',
    referenceLink: '',
    officialLink: '',
    price: 0,
    priceMap: [{ link: '', name: '', price: 0 }],
    discountPrice: 0,
    thumbnail: '',
    isVisible: true,
    content: '',
    retailPrice: 0,
    usedSellerName: '',
    usedPrice: 0,
    usedSellerLink: '',
  });

  const [createProductStatus, setCreateProductStatus] = useState<boolean>(false);

  const [displayPhoto, setDisplayPhoto] = useState<File | null | undefined>();

  const editorContentRef = useRef('');

  const _onChangeText = (e: any) => {
    let { name, value } = e.target;
    if (name.startsWith('map')) {
      const regex = /[^0-9]/g;
      const idx = parseInt(name.replace(regex, ''));
      const priceMap = JSON.parse(JSON.stringify(formState.priceMap));
      const mapName = name.replace(/[0-9]/g, '').substring(3).toLowerCase();
      priceMap[idx - 1][mapName] = mapName === 'price' ? parseInt(value) : value;
      setFormState((prevState) => ({
        ...prevState,
        priceMap: priceMap,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]:
          name === 'price' || name === 'discountPrice' || name === 'usedPrice'
            ? parseInt(value)
            : value,
      }));
    }
  };

  const _handleDeleteClick = (idx: number) => {
    const priceMap = JSON.parse(JSON.stringify(formState.priceMap));
    priceMap.splice(idx, 1);
    setFormState((prevState) => ({
      ...prevState,
      priceMap: priceMap,
    }));
  };

  const _handlePlusClick = (idx: number) => {
    const priceMap = JSON.parse(JSON.stringify(formState.priceMap));
    if (idx < priceMap.length - 1) {
      priceMap.splice(idx, 0, { link: '', name: '', price: 0 });
    } else {
      priceMap.push({ link: '', name: '', price: 0 });
    }
    setFormState((prevState) => ({
      ...prevState,
      priceMap: priceMap,
    }));
  };

  const _handleSubmitButton = async () => {
    if (!displayPhoto) {
      toast.error('사진을 업로드해주세요');
    } else if (!(formState.price >= 0) || !(formState.discountPrice >= 0)) {
      toast.error('0이상 숫자를 입력해주세요');
    } else if (!((formState.price - formState.discountPrice) / formState.price >= 0)) {
      toast.error('할인률을 확인해주세요');
    } else {
      let redTextFlag = false;
      let isError = false;
      formState.priceMap.forEach((priceElem: any) => {
        if (!priceElem.name) {
          return;
        }
        if (priceElem.price < 0) {
          toast.error('0 이상의 숫자를 입력해주세요');
          isError = true;
          return;
        }
        if (!priceElem.link) {
          toast.error('가격 비교 링크를 입력하세요');
          isError = true;
          return;
        }
      });
      if (isError) {
        return;
      }
      if (redTextFlag) {
        toast.error('모든항목을 작성해주세요');
      } else {
        for (let i = 0; i < Object.keys(formState).length; i++) {
          let key = Object.keys(formState)[i];
          if (key === 'priceMap' || key === 'usedSellerName' || key === 'usedSellerLink') {
            continue;
          }
          if (key !== 'thumbnail') {
            if (formState[key as keyof CreateProductParams]) {
              let inputValue = formState[key as keyof CreateProductParams]!.toString().trim();
              if (inputValue.length === 0) {
                redTextFlag = true;
                break;
              }
            }
          }
        }
        if (redTextFlag) {
          toast.error('모든항목을 작성해주세요');
        } else {
          setCreateProductStatus(true);
          let uploadImageResult = await uploadImage(displayPhoto);
          if (uploadImageResult?.success) {
            // Need to change 가격 비교 code
            const { priceMap, ...rest } = formState;
            let createProductResult = await createProduct({
              ...rest,
              priceMap: priceMap,
              thumbnail: uploadImageResult.data,
              content: editorContentRef.current,
            });
            if (createProductResult) {
              toast.success('성공적으로 상품을 등록하였습니다');
              setCreateProductStatus(false);
            } else {
              toast.error('Something went wrong creating a product');
            }
          } else {
            toast.error('Something went wrong uploading an image');
          }
        }
      }
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>상품 등록</AdminH1>
      </div>
      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-start-1 col-span-2">
            <div className="font-bold text-lg mb-4">상태 구분</div>
            <div className="flex justify-between">
              <div className="flex space-x-3">
                <p>진열</p>
                <Toggle
                  checked={formState.isVisible}
                  onChange={(e) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      isVisible: e,
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-start-1 col-span-2">
            <div className="font-bold text-lg mb-4">기본 정보</div>
            <TextField
              label="공급사"
              name="supplyName"
              value={formState.supplyName}
              onChange={_onChangeText}
            />
            <TextField
              label="브랜드"
              name="brandName"
              value={formState.brandName}
              onChange={_onChangeText}
            />
            <TextField
              label="상품명"
              name="productName"
              value={formState.productName}
              onChange={_onChangeText}
            />
            <TextField
              label="아웃링크"
              name="referenceLink"
              value={formState.referenceLink}
              onChange={_onChangeText}
            />
          </div>
          <div className="col-start-1 col-span-2">
            <div className="font-bold text-lg mb-4">가격비교 정보</div>
            {formState.priceMap?.map((priceInfo: any, idx: number) => {
              return (
                <div className="flex flex-row w-full">
                  <div className="inline-block w-3/12">
                    <TextField
                      label={'판매처명' + (idx + 1).toString()}
                      name={'mapName' + (idx + 1).toString()}
                      value={formState.priceMap[idx].name}
                      onChange={_onChangeText}
                    />
                  </div>
                  <div className="inline-block w-3/12">
                    <TextField
                      label={'지도가' + (idx + 1).toString()}
                      type="number"
                      name={'mapPrice' + (idx + 1).toString()}
                      value={formState.priceMap[idx].price}
                      onChange={_onChangeText}
                    />
                  </div>
                  <div className="inline-block w-3/12">
                    <TextField
                      label={'공식 판매처' + (idx + 1).toString()}
                      name={'mapLink' + (idx + 1).toString()}
                      value={formState.priceMap[idx].link}
                      onChange={_onChangeText}
                    />
                  </div>
                  {formState.priceMap && formState.priceMap.length < 3 && (
                    <button
                      onClick={() => {
                        _handlePlusClick(idx);
                      }}
                      type="button"
                      className="p-1 mx-1 my-4 h-1/2 bg-black rounded-md border-0"
                    >
                      <Icon.Plus className="text-white" />
                    </button>
                  )}

                  {idx > 0 && (
                    <button
                      onClick={() => {
                        _handleDeleteClick(idx);
                      }}
                      type="button"
                      className="p-1 mx-1 my-4 h-1/2 bg-black rounded-md border-0"
                    >
                      <Icon.Trash className="text-white" />
                    </button>
                  )}
                </div>
              );
            })}
            <TextField
              label="중고 판매처명"
              name="usedSellerName"
              value={formState.usedSellerName}
              onChange={_onChangeText}
            />
            <TextField
              label="중고가"
              type="number"
              name="usedPrice"
              value={formState.usedPrice}
              onChange={_onChangeText}
            />
            <TextField
              label="중고 판매처"
              name="usedSellerLink"
              value={formState.usedSellerLink}
              onChange={_onChangeText}
            />
          </div>
          <div className="col-start-1 col-span-1">
            <div className="font-bold text-lg mb-4">가격 정보</div>
            <TextField
              label="상시 정가"
              type="number"
              name="price"
              value={formState.price}
              onChange={_onChangeText}
            />
            <TextField
              label="상시 판매가"
              type="number"
              name="discountPrice"
              value={formState.discountPrice}
              onChange={_onChangeText}
            />
            <div className="flex space-x-4">
              <div>할인률</div>
              <div>
                {formState.price != null && formState.discountPrice != null && formState.price > 0
                  ? (((formState.price - formState.discountPrice) / formState.price) * 100).toFixed(
                      2
                    ) + '%'
                  : 'Invalid Number'}
              </div>
            </div>
          </div>

          <div className="md:col-span-2 col-start-1">
            {/* <p className="label">*내용(Editor)</p> */}
            <div className="font-bold text-lg mb-4">내용(Editor)</div>
            {/* <ToastEditor /> */}
            <FroalaEditor
              defaultValue={formState.content}
              onChange={(model) => {
                editorContentRef.current = model;
              }}
            />
          </div>

          <div className="col-start-1 col-span-1">
            <div className="font-bold text-lg mb-4">대표 이미지</div>
            <label htmlFor="displayPhoto">
              <div className="relative aspect-1 rounded-md border-2 border-grey-5">
                {displayPhoto || formState.thumbnail ? (
                  <>
                    <img
                      className="absolute w-full h-full rounded object-cover"
                      src={displayPhoto ? URL.createObjectURL(displayPhoto) : formState.thumbnail}
                      alt=""
                    />
                    <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                      사진 수정하기
                    </div>
                  </>
                ) : (
                  <div className="absolute w-full h-full rounded object-cover bg-white">
                    <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                      <FileUploadImg />
                      <div className="text-sm text-gray-500 text-center pt-1">
                        이미지를 업로드해주세요.
                        <br />
                        (최대 1장)
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </label>
            <input
              type="file"
              id="displayPhoto"
              className="hidden"
              accept="image/*"
              onChange={(e) => {
                if (e.target.validity.valid && !createProductStatus) {
                  setDisplayPhoto(e.target.files?.item(0));
                }
              }}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="취소"
            className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
            to="/admin/products"
            disabled={createProductStatus}
          />
          <Button
            onClick={() => _handleSubmitButton()}
            text="추가하기"
            className="h-10 text-sm filled-black"
            disabled={createProductStatus}
          />
        </div>
      </Card>
    </>
  );
};
