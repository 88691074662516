import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from './Icon';
import { ReactComponent as Home } from '../assets/svg/home.svg';

type hideContents = {
  leftBtn: boolean;
};

interface TopTitleBarProps {
  title?: string;
  home?: boolean;
  button?: JSX.Element;
  hideContents?: hideContents;
}

export const TopTitleBar: FC<TopTitleBarProps> = ({ title, home = true, button, hideContents }) => {
  const history = useHistory();
  return (
    <>
      <div className="p-4">
        {!hideContents?.leftBtn && (
          <button
            className="absolute left-4 top-4"
            onClick={() => {
              history.goBack();
            }}
          >
            <Icon.ChevronLeft />
          </button>
        )}

        <p className="text-center mx-auto">{title}</p>
        {button && button}
        {home && (
          <button
            className="absolute right-4 top-4"
            onClick={() => {
              history.push('/home');
            }}
          >
            <Home className="wh-6" />
          </button>
        )}
      </div>
    </>
  );
};
