export interface Product {
  brandName: string;
  productName: string;
  thumbnail: string;
  variantText: string;
  quantity: number;
}

export interface LocalStorageProduct {
  product: string;
  quantity: number;
  shippingMethod: string;
  variant: string;
  _id: string;
}

export interface LocalStorageProducts {
  bundleItems: LocalStorageProduct[];
  product: string;
  quantity: number;
  shippingMethod: string;
  variant: string;
  _id: string;
}

export interface FulfillmentItem {
  item: {
    _id: string;
    product: {
      _id: string;
    };
    variant: {
      _id: string;
    };
  };
}

export interface FulfillmentRefund {}

export interface ClayfulFulfillment {
  status: FullfillmentStatus;
  items: [FulfillmentItem];
  updatedAt: { raw: string };
  tracking: {
    uid: string;
    url: string;
  };
}

export interface Fullfillment {
  id: number;
  fulfillmentId: string;
  customerId: string;
  orderId: string;
  orderItemId: string;
  productId: string;
  variantId: string;
  brandName: string;
  productName: string;
  thumbnail: string;
  variantText: string;
  quantity: number;
}

export enum FullfillmentStatus {
  PENDING = 'pending',
  ARRIVED = 'arrived',
  SHIPPED = 'shipped',
  ARRIVED_AFTER_7DAY = 'arrived_after_7day',
}

export const fullfillmentStatusText = {
  [FullfillmentStatus.PENDING]: '배송 준비중',
  [FullfillmentStatus.ARRIVED]: '배송완료',
  [FullfillmentStatus.SHIPPED]: '배송중',
  [FullfillmentStatus.ARRIVED_AFTER_7DAY]: '배송완료',
};
