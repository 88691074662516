import { parse, stringify } from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { addDays } from 'date-fns';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { Button } from '../../../../../../components/Button';
import { getCampingBookingList } from '../../../../../../api_v2/admin/onda';
import moment from 'moment';

import { Calendar } from '../../Calendar';
import { Link, useLocation } from 'react-router-dom';
import { CustomDrawer } from '../../CustomDrawer';
import { Table } from '../../../../../../components/Table';
import { map } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { api } from '../../../../../../plugins/axios';

const { RangePicker } = DatePicker;

const propertyDescriptionObj: { [key: string]: any } = {
  email: '이메일',
  phone: '전화번호',
  numBookings: '예약정보수',
};

enum BookingStatus {
  canceled = 'canceled',
  confirmed = 'confirmed',
}

export const Users = () => {
  const [downloadStatus, setDownloadStatus] = useState(false);

  const [infoList, setInfoList] = useState<{ [key: string]: any } | null>({});
  const [infoListTotal, setInfoListTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [infoListLoading, setInfoListLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();

  const convertDateForQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const drawerRef = useRef<HTMLDivElement | null>(null);

  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [defaultDates, setDefaultDates] = useState([new Date(), addDays(new Date(), 30)]);

  const [calendarState, setCalendarState] = useState<any>([
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: new Date(parsedSearch?.start_date as string),
          endDate: new Date(parsedSearch?.end_date as string),
          key: 'selection',
        }
      : {
          startDate: defaultDates[0],
          endDate: defaultDates[1],
          key: 'selection',
        },
  ]);

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const [locale, setLocale] = useState<any>('ko');

  const [option, setOption] = useState<any>(
    parsedSearch?.option ? parsedSearch?.option : 'checkout'
  );

  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '80%';
    }
  };

  const [pageLimit, setPageLimit] = useState(20);

  const fetchBookingsByUser = async ({ pageParam = 1 }) => {
    let currentQueryStringObj: { [key: string]: any } = {
      page: pageParam,
      limit: pageLimit,
      filter: {},
    };

    for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
      let key = Object.keys(parsedSearch)[i];
      if (key === 'search_query') {
        currentQueryStringObj.filter['searchQuery'] = parsedSearch[key];
      } else if (key === 'member_reservation') {
        currentQueryStringObj.filter['memberReservation'] = parsedSearch[key];
      } else if (key === 'search_query_type') {
        currentQueryStringObj.filter['searchQueryType'] = parsedSearch[key];
      }
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    setBookingsByUserDataStatus(true);
    try {
      let result = await api.get(`/admin/onda-bookings/get-data/users${currentQueryString}`);
      setBookingsByUserDataStatus(false);
      if ([200].includes(result?.status)) {
        return result;
      } else return null;
    } catch (e) {
      console.log('e', e);
      setBookingsByUserDataStatus(false);
      return null;
    }
  };

  const [bookingsByUserDataStatus, setBookingsByUserDataStatus] = useState(false);

  const { data: bookingsByUserData, fetchNextPage } = useInfiniteQuery<
    AxiosResponse | null,
    AxiosError
  >([`adminOndaCampingBookingsByUserPage`], fetchBookingsByUser, {
    getNextPageParam: (lastPage, allPages) => {
      const maxPage = Math.ceil((lastPage?.data?.data?.count ?? 0) / pageLimit);
      const nextPage = allPages?.length + 1;
      return nextPage <= maxPage ? nextPage : undefined;
    },
    keepPreviousData: true,
  });

  const lastElement = useCallback((target) => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && fetchNextPage) {
          fetchNextPage();
        } else {
        }
      },
      {
        threshold: 1,
      }
    );
    if (target) observer.current.observe(target);
  }, []);

  const handleSearch = (queryObj: any) => {
    const currentQueryString = stringify(queryObj, {
      addQueryPrefix: true,
      encode: false,
    });

    window.location.href = '/admin/v2/camping/bookings/get-data/users' + currentQueryString;
  };

  const handleOptionChange = (e: any) => {
    setOption(e?.target?.value);
  };

  const calCancelAmt = (cancelData: any) => {
    let total = 0;
    if (cancelData) {
      cancelData.map((item: any) => {
        total = total + (item?.amount ?? 0);
      });
    }
    return total;
  };

  const handleMore = () => {
    fetchNextPage();
  };

  const [searchInput, setSearchInput] = useState(parsedSearch?.search_query ?? '');

  const handleSearchInput = (e: any) => {
    setSearchInput(e.target.value);
  };

  const handleSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      const tempQueryObj: { [key: string]: any } = {};
      if ((searchInput as string)?.trim()?.length > 0) {
        tempQueryObj['search_query'] = (searchInput as string)?.trim() ?? '';
      }

      if (memberReservation?.length > 0) {
        tempQueryObj['member_reservation'] = memberReservation;
      }

      if (searchQueryType?.length > 0) {
        tempQueryObj['search_query_type'] = searchQueryType;
      }

      handleSearch(tempQueryObj);
    }
  };

  const handleCampingSearchButton = (evt: any) => {
    const tempQueryObj: { [key: string]: any } = {};

    if ((searchInput as string)?.trim()?.length > 0) {
      tempQueryObj['search_query'] = (searchInput as string)?.trim() ?? '';
    }

    if (memberReservation?.length > 0) {
      tempQueryObj['member_reservation'] = memberReservation;
    }

    if (searchQueryType?.length > 0) {
      tempQueryObj['search_query_type'] = searchQueryType;
    }

    handleSearch(tempQueryObj);
  };

  const [memberReservation, setMemberReservation] = useState<any>(
    parsedSearch?.member_reservation ?? ''
  );

  const handleMemberReservation = (e: any) => {
    setMemberReservation(e?.target?.value);
  };

  const [searchQueryType, setSearchQueryType] = useState<any>(
    parsedSearch?.search_query_type ?? ''
  );

  const handleSearchQueryType = (e: any) => {
    setSearchQueryType(e?.target?.value);
  };

  return (
    <div className="">
      {/* <input type="file" onChange={(e) => handleInputFile(e)} /> */}
      <div className="flex flex-col mb-[15px]">
        <div className="w-full max-w-[750px] p-[15px] border-[1px] rounded-[5px] mb-[15px]">
          <div className="flex w-full mb-[5px]">
            <select
              name="searchQueryType"
              placeholder="검색타입"
              value={searchQueryType as any}
              onChange={(e) => handleSearchQueryType(e)}
            >
              <option value="">전체</option>
              <option value="email">이메일</option>
              <option value="phone">전화번호</option>
            </select>
            <input
              className="flex-1 h-[42px] w-full max-w-[450px] px-[5px] mr-[5px] text-sm bg-white border border-[#ADB5BD]"
              placeholder="검색하기"
              onChange={(e) => handleSearchInput(e)}
              value={searchInput as string}
              onKeyDown={(e) => handleSearchKeyDown(e)}
            />
          </div>
          <div className="flex items-center">
            <p className="mr-[5px]">회원예약여부</p>
            {/* <input
              type="checkbox"
              name="isVisible"
              checked={isVisible}
              onChange={(e) => handleVisibleChange(e)}
            /> */}
            <select
              name="memberReservation"
              placeholder="회원예약여부"
              value={memberReservation as any}
              onChange={(e) => handleMemberReservation(e)}
            >
              <option value="">전체</option>
              <option value="true">Y</option>
              <option value="false">N</option>
            </select>
          </div>
          <div className="flex justify-end">
            <Button
              className="h-[42px] text-sm text-[black] font-[400] border border-[#ADB5BD]"
              onClick={(e) => {
                handleCampingSearchButton(e);
              }}
            >
              검색
            </Button>
          </div>
        </div>

        <div className="flex justify-between mb-[5px]">
          <div></div>
          {/* <Button
            disabled={downloadStatus}
            onClick={() => {
              handleDownload();
            }}
            className="h-10 text-sm text-[black] outlined-gray-600"
          >
            다운로드
          </Button> */}
        </div>

        <div className="flex justify-between">
          <div>예약 유저 (비회원포함) 목록</div>
          <div>
            전체 수:
            {bookingsByUserDataStatus
              ? '...'
              : bookingsByUserData?.pages[0]?.data?.data?.count ?? 0}
            개
          </div>
        </div>
      </div>
      <Table className="absolute">
        <Table.Head className="sticky top-[-25px] bg-[aliceblue]">
          <Table.Row>
            <Table.Th></Table.Th>
            {Object.keys(propertyDescriptionObj).map((key) => (
              <Table.Th key={key}>{propertyDescriptionObj[key]}</Table.Th>
            ))}
            <Table.Th>회원예약 여부</Table.Th>
            <Table.Th></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {bookingsByUserData?.pages && bookingsByUserData?.pages[0]?.data?.data?.items?.length > 0
            ? bookingsByUserData?.pages.map(
                (page, groupInd) =>
                  page?.data?.data?.items &&
                  page?.data?.data?.items.map((item: any, itemInd: number) => (
                    <>
                      <Table.Row key={item?.id}>
                        <Table.Td>{groupInd * pageLimit + (itemInd + 1)}</Table.Td>
                        <Table.Td>{item?.email}</Table.Td>
                        <Table.Td>{item?.phone}</Table.Td>
                        <Table.Td>{item?.numBookings}</Table.Td>
                        <Table.Td>
                          {item?.userId ? (
                            <div>
                              <div>Y</div>
                              <div>(유저 아이디 #: {item?.userId})</div>
                            </div>
                          ) : (
                            'N'
                          )}
                        </Table.Td>
                        <Table.Td>
                          <Link
                            to={
                              `/admin/v2/camping/bookings/get-data/users/detail?email=${item?.email}&phone=${item?.phone}` +
                              `${item?.userId ? `&user_id=${item?.userId}` : ''}`
                            }
                          >
                            자세히 보기
                          </Link>
                        </Table.Td>
                      </Table.Row>
                    </>
                  ))
              )
            : null}

          {/* <div ref={lastElement}></div> */}
        </Table.Body>
        <Button
          className="h-10 w-max text-sm filled-black mt-[5px]"
          onClick={() => handleMore()}
          disabled={bookingsByUserDataStatus}
        >
          {bookingsByUserDataStatus ? <>...</> : <>더보기</>}
        </Button>
      </Table>

      <div>
        <CustomDrawer drawerRef={drawerRef}>
          <div className="side-padding mt-[75px]">
            <Calendar
              locale={locale}
              state={calendarState}
              setState={setCalendarState}
              setChosenDates={setChosenDates}
              // drawerRef={drawerRef}
              confirmButton={false}
            />
          </div>
        </CustomDrawer>
      </div>
    </div>
  );
};
