import { api } from '../../../plugins/axios';
import { CreateNotificationParams } from './types';

export const sendNotificationForAll = async (
  dataToSend: CreateNotificationParams,
  osTypes: string[]
) => {
  try {
    let modifiedOSTypes = osTypes.join(',');
    let result = await api.post(
      `/admin/notification?all=true&os_type=${modifiedOSTypes}`,
      dataToSend
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return result.data;
    } else {
      return {
        successStatus: false,
      };
    }
  } catch (e) {
    console.log('e', e);
  }
};
