import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { CustomDrawer } from './CustomDrawer';

export const TermButton = (props: any) => {
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '80%';
    }
  };
  return (
    <>
      <div className="pr-4" onClick={() => openDrawer()}>
        약관보기
      </div>
      <CustomDrawer drawerRef={drawerRef} confirmButton={false} contentMt={55}>
        <div className="m-5 mt-[40px]">{props.termContent ? parse(props.termContent) : <></>}</div>
      </CustomDrawer>
    </>
  );
};
