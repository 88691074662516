import { result } from 'lodash';
import { api } from '../../../plugins/axios';
import { Product } from '../../../types';
import { stringify } from 'qs';

export const checkBookmarkedProduct = async (productId: string) => {
  try {
    let localStorageWishList = localStorage.getItem('wishList');
    if (localStorageWishList) {
      let parsedLocalStorageWishList = JSON.parse(localStorageWishList);
      if (Array.isArray(parsedLocalStorageWishList)) {
        let found = parsedLocalStorageWishList.find((item) => {
          return item === productId;
        });
        if (found) {
          return true;
        } else return false;
        // let checkFlag = false;
        // for (let i = 0; i < parsedLocalStorageWishList.length; i++) {
        //   if (parsedLocalStorageWishList[i] === productId) {
        //     checkFlag = true;
        //     break;
        //   }
        // }
        // if (checkFlag) {
        //   return true;
        // } else return false;
      } else return false;
    } else {
      return false;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const addBookmarkedProduct = async (productId: string) => {
  try {
    let localStorageWishList = localStorage.getItem('wishList');
    if (localStorageWishList) {
      let parsedLocalStorageWishList = JSON.parse(localStorageWishList);
      if (Array.isArray(parsedLocalStorageWishList)) {
        parsedLocalStorageWishList.unshift(productId);
        localStorage.setItem('wishList', JSON.stringify(parsedLocalStorageWishList));
        return { success: true };
      } else {
        return { success: false, data: 'addBookmarkedProduct error' };
      }
    } else {
      localStorage.setItem('wishList', JSON.stringify([productId]));
      return { success: true };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const removeBookmarkedProduct = async (productId: string) => {
  try {
    let localStorageWishList = localStorage.getItem('wishList');
    if (localStorageWishList) {
      let parsedLocalStorageWishList = JSON.parse(localStorageWishList);
      if (Array.isArray(parsedLocalStorageWishList)) {
        let filtered = parsedLocalStorageWishList.filter((item) => item !== productId);
        localStorage.setItem('wishList', JSON.stringify(filtered));
        return { success: true };
      } else {
        return { success: false, data: 'removeBookmarkedProduct error' };
      }
    } else return { success: false };
  } catch (e) {
    console.log('e', e);
  }
};
