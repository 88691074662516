import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// @ts-ignore
import * as locales from 'react-date-range/dist/locale';

const convertDate = (dateData: any) => {
  const yr = new Date(dateData).getFullYear();
  const mon = new Date(dateData).getMonth() + 1;
  const dt = new Date(dateData).getDate();
  const day = new Date(dateData).getDay();
  const dayList = ['일', '월', '화', '수', '목', '금', '토'];
  const convertedDay = dayList[day] ? '(' + dayList[day] + ')' : '';
  const finalStr = yr + '.' + mon + '.' + dt + convertedDay;
  return finalStr;
};

export const Calendar = (props: any) => {
  const [selectedDates, setSelectedDates] = useState<{ [key: string]: any }>({
    startDate: null,
    endDate: null,
  });

  return (
    <>
      <div className="border-b-2 border-[#EEEEEE]">
        <DateRange
          showMonthAndYearPickers={false}
          onShownDateChange={(item) => {}}
          onChange={(item) => {
            if (item?.selection?.startDate && item?.selection?.endDate) {
              setSelectedDates({
                startDate: item.selection.startDate,
                endDate: item.selection.endDate,
              });
            }
            props.setState([item.selection]);
          }}
          moveRangeOnFirstSelection={false}
          ranges={props.state}
          direction="horizontal"
          locale={locales[props.locale]}
          rangeColors={['#FF6400']}
        />
      </div>
      <div className="grid grid-cols-2 place-items-center mt-3">
        <div className="flex flex-col items-center">
          <div className="text-[#FF6400] text-[15px]">start date</div>
          <div className="text-[20px]">
            {selectedDates?.startDate && convertDate(selectedDates.startDate)}
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-[#FF6400] text-[15px]">end date</div>
          <div className="text-[20px]">
            {selectedDates?.endDate && convertDate(selectedDates.endDate)}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        {
          <>
            {selectedDates?.startDate && selectedDates?.endDate ? null : (
              <div className="mt-5">업로드한 데이터의 기간을 선택해주세요</div>
            )}
          </>
        }
      </div>
    </>
  );
};
