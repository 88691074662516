import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import shave from 'shave';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useInViewport } from 'react-in-viewport';

import Linkify from 'linkify-react';

import './style.css';
import { relative } from 'path';

enum DrawerType {
  POST_COMMENT_REPLY_DETAIL = 'post_comment_reply_detail',
}

interface CardType3 {
  item?: any;
  lastElement?: any;
  handlePostinfoSwiperSlide?: any;
  lowestPricesObj?: any;
  pageInd?: any;
  openDrawer?: any;
  closeDrawer?: any;
  setChosenPostCommentReplyDetail?: any;
}

export const CardType3: FC<CardType3> = ({
  item,
  lastElement,
  handlePostinfoSwiperSlide,
  lowestPricesObj,
  pageInd,
  openDrawer,
  closeDrawer,
  setChosenPostCommentReplyDetail,
}) => {
  const cardObserver = useRef<IntersectionObserver>();
  const cardImgRef = useRef<any>(null);
  const [imgInViewport, setImgInViewport] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  const viewportRef = useRef<any>(null);
  const { inViewport, enterCount, leaveCount } = useInViewport(
    viewportRef as any,
    { threshold: 0.1 },
    { disconnectOnLeave: false }
  );

  const [emptyBoxHeight, setEmptyBoxHeight] = useState('360px');

  const [lowestPricesForGivenPage, setLowestPricesForGivenPage] = useState<any>(null);

  const cardImgContRef = useCallback((target) => {
    if (cardObserver.current) cardObserver.current.disconnect();

    cardObserver.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setImgInViewport(true);
        } else {
        }
      },
      {
        // rootMargin: '400px',
        threshold: 0.1,
      }
    );
    if (target) cardObserver.current.observe(target);
  }, []);

  useEffect(() => {
    const handleEvent = () => {
      let imgEle = cardImgRef?.current?.querySelector('img');
      if (imgEle?.complete == true && imgInViewport == true) {
        setImgLoaded(true);
      }
    };
    handleEvent();
    window.addEventListener('DOMSubtreeModified', handleEvent);
    return () => window.removeEventListener('DOMSubtreeModified', handleEvent);
  }, [imgInViewport, cardImgRef?.current?.querySelector('img')]);

  const handlePostCommentReplyDetail = (item: any) => {
    closeDrawer();
    setChosenPostCommentReplyDetail(item);
    openDrawer(DrawerType.POST_COMMENT_REPLY_DETAIL);
  };

  const contentRef = useRef<any>(null);
  const [showRestContentStatus, setShowRestContentStatus] = useState<any>(false);
  const [contentNumberOfLines, setContentNumberOfLines] = useState<any>(0);

  useEffect(() => {
    const handleScrollEvent = () => {
      // Get a reference to the element you want to check
      const element = contentRef?.current; // Replace with the actual element reference

      if (element) {
        // Get the element's computed style
        const computedStyle = window.getComputedStyle(element);

        // Get the line height in pixels
        const lineHeight = parseFloat(computedStyle.getPropertyValue('line-height'));

        // Get the height of the element
        const elementHeight = element.clientHeight;

        const rect = element.getBoundingClientRect();
        const rectHeight = rect.height;

        // Calculate the number of lines
        const numberOfLines = Math.floor(rectHeight / lineHeight);

        if (numberOfLines >= 3) {
          setContentNumberOfLines(numberOfLines);
        }
      }
    };
    handleScrollEvent();
    window.addEventListener('scroll', handleScrollEvent);
    return () => window.removeEventListener('scroll', handleScrollEvent);
  }, [contentRef?.current]);

  const handleRestContentStatus = () => {
    let cardImgContRefEle = (contentRef as any)?.current?.parentElement?.parentElement;
    if (cardImgContRefEle) {
      if (showRestContentStatus) {
        cardImgContRefEle.style.height = '100px';
        // contentRef.current.style['-webkit-line-clamp'] = 3;
        setShowRestContentStatus(false);
      } else {
        cardImgContRefEle.style.height = 'fit-content';
        // contentRef.current.style.removeProperty('-webkit-line-clamp');
        setShowRestContentStatus(true);
      }
    }
  };

  const [contentTimePassed, setContentTimePassed] = useState<any>(null);

  useEffect(() => {
    if (item) {
      const calculateTimeAgo = (timeData: any) => {
        const textWrittenTime = timeData as any;
        const currentTime = new Date() as any;
        const timeDifference = currentTime - textWrittenTime;

        const secondsPassed = Math.floor(timeDifference / 1000);
        const minutesPassed = Math.floor(secondsPassed / 60);
        const hoursPassed = Math.floor(minutesPassed / 60);
        const daysPassed = Math.floor(hoursPassed / 24);
        const yearsPassed = Math.floor(daysPassed / 365);

        if (yearsPassed > 0) {
          return `${yearsPassed} 년${yearsPassed > 1 ? '' : ''} 전`;
        } else if (daysPassed > 0) {
          return `${daysPassed} 일${daysPassed > 1 ? '' : ''} 전`;
        } else if (hoursPassed > 0) {
          return `${hoursPassed} 시간${hoursPassed > 1 ? '' : ''} 전`;
        } else if (minutesPassed > 0) {
          return `${minutesPassed} 분${minutesPassed > 1 ? '' : ''} 전`;
        } else {
          return `${secondsPassed} 초${secondsPassed > 1 ? '' : ''} 전`;
        }
      };

      const dateString = item.createdAt;
      const parts = dateString.split(/[-T:.]/);
      const year = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Months are 0-indexed
      const day = parseInt(parts[2]);
      const hour = parseInt(parts[3]);
      const minute = parseInt(parts[4]);
      const second = parseInt(parts[5]);
      const millisecond = parseInt(parts[6]);

      const localDate = new Date(year, month, day, hour, minute, second, millisecond);

      // setContentTimePassed(calculateTimeAgo(localDate));
      setContentTimePassed(calculateTimeAgo(new Date(item.createdAt)));
    }
  }, [item]);

  const checkContentLength = (text: any) => {
    try {
      let lineBreakCount = 0;
      for (let i = 0; i < text.length; i++) {
        if (text[i] == '\n') {
          lineBreakCount++;
        }
      }
      if (lineBreakCount > 0) {
        if (lineBreakCount >= 2) {
          return {
            count: lineBreakCount,
            modifiedText: text?.split('\n')[0]?.substring(0, 45) + '...',
          };
        } else {
          if (text?.length >= 50) {
            return { count: lineBreakCount, modifiedText: text?.substring(0, 45) + '...' };
          } else {
            return { count: lineBreakCount, modifiedText: text };
          }
        }
      } else {
        if (text?.length >= 50) {
          return { count: lineBreakCount, modifiedText: text?.substring(0, 45) + '...' };
        } else {
          return { count: lineBreakCount, modifiedText: text };
        }
      }
    } catch (e) {
      return null;
    }
  };

  return (
    <div
      ref={viewportRef}
      className="border border-[#D9D9D9] mb-5 card-type-2"
      style={{ borderRadius: '5px 5px 5px 5px' }}
      /* top-left | top-right | bottom-right | bottom-left */
    >
      {inViewport ? (
        <>
          <div
            ref={cardImgContRef}
            key={item?.id}
            style={{ height: '100px', position: 'relative' }}
            className="p-[15px]"
            // onClick={() => handlePostCommentReplyDetail(item)}
          >
            <div className="flex text-[12px] text-[#878787]">
              <div className="mr-[5px]">
                {item?.user?.username?.substring(0, 10)
                  ? item?.user?.username?.substring(0, 10) + '...'
                  : null}
              </div>
              <div>{contentTimePassed ?? null}</div>
            </div>

            <div className="flex justify-between">
              <pre
                ref={contentRef}
                className="text-[15px]"
                style={{
                  overflow: 'hidden',
                  // textOverflow: 'ellipsis',
                  // display: '-webkit-box',
                  // WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-all',
                }}
              >
                <Linkify>
                  {item?.content
                    ? showRestContentStatus
                      ? item?.content
                      : checkContentLength(item?.content)?.modifiedText ?? null
                    : null}
                </Linkify>

                {/* {item?.content ?? null} */}
              </pre>
            </div>
            <div className="absolute right-[10px] bottom-[10px] flex justify-end mb-[5px]">
              {(checkContentLength(item?.content)?.count ?? 0) >= 2 ? (
                <div
                  onClick={() => {
                    handleRestContentStatus();
                  }}
                >
                  {showRestContentStatus ? '접기' : '더보기'}
                </div>
              ) : (
                <> </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          className="bg-[#dedbd9] blur-[4px]"
          style={{
            height: '100px', //viewportRef?.current?.clientHeight,
            width: '100%',
          }}
        ></div>
      )}
    </div>
  );
};
