import React, { useEffect, useRef, useState } from 'react';
import { default as htmlReactParser, attributesToProps } from 'html-react-parser';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper as JSSwiper } from 'swiper';

import { Button } from '../../../../../components/Button';
import { createCampingEditor } from '../../../../../api_v2/admin/onda';
import { getCampingEditorByType } from '../../../../../api_v2/non_admin/onda';
import { toast } from 'react-toastify';

export default function Main() {
  const [value, setValue] = useState('');
  const [submitStatus, setSubmitStatus] = useState(false);

  const handleSubmit = () => {
    if (window.confirm('등록하시겠습니까?')) {
      setSubmitStatus(true);
      createCampingEditor({ type: 'main', content: value }, '')
        .then((res) => {
          if (res?.success) {
            toast.success('성공적으로 등록하였습니다');
          }
          setSubmitStatus(false);
        })
        .catch((err) => {
          console.log('err', err);
          toast.error('error');
          setSubmitStatus(false);
        });
    }
  };
  const onChangeEditor = (e: any) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    getCampingEditorByType('main', '').then((res) => {
      if (res?.success) {
        setValue(res?.data?.content ?? '');
      }
    });
  }, []);

  useEffect(() => {
    if (value) {
      const parser = new DOMParser();
      const document = parser.parseFromString(value, 'text/html');

      let swiperContainerEles = document.querySelectorAll('.swiper-container');

      if (swiperContainerEles) {
        for (let i = 0; i < swiperContainerEles.length; i++) {
          let slidesPerView = (swiperContainerEles[i] as any)?.dataset?.slidesperview;
          let spaceBetween = (swiperContainerEles[i] as any)?.dataset?.spacebetween;

          let swiper = new JSSwiper('#' + swiperContainerEles[i]?.id, {
            slidesPerView: slidesPerView ?? 2.5,
            spaceBetween: spaceBetween ? parseInt(spaceBetween) : 5,
            slidesOffsetBefore: 10,
            slidesOffsetAfter: 10,
            on: {
              afterInit: function (swiper) {},
            },
          });
        }
      }
    }
  }, [value]);

  return (
    <div className="mt-[50px] grid grid-cols-1 place-items-center">
      {value && (
        <div className="max-w-md mb-[55px] border-[2px]">
          <div>{htmlReactParser(value)}</div>
        </div>
      )}

      <textarea
        rows={30}
        cols={70}
        value={value}
        onChange={(e) => {
          onChangeEditor(e);
        }}
      />
      <Button
        className="place-self-end float-right mt-[10px] button md:max-w-md h-10 text-sm outlined-gray-600 hover:bg-gray-50"
        onClick={() => {
          handleSubmit();
        }}
        disabled={submitStatus}
      >
        {submitStatus ? '등록중...' : '등록'}
      </Button>
    </div>
  );
}
