import { Button } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { parse, stringify } from 'qs';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { api } from '../../../../../plugins/axios';
import { map } from 'lodash';
import { CardType1 } from './components/CardType1';
import { TopTitleBar } from './components/TopTitleBar';
import { CustomDrawer } from './components/CustomDrawer';
import TravelDestDetail from './TravelDestDetail';
import { useLocation } from 'react-router-dom';
import { ReactComponent as IconAngleDoubleTop } from '../../../../../assets/icons/icon-angle-double-top.svg';
import { CardType1V2 } from './components/CardType1V2';
import { CardType1V3 } from './components/CardType1V3';
import { ReactComponent as IconFilter } from '../../../../../assets/icons/icon-filter.svg';
import { Icon } from '../../../../../components/Icon';

enum DrawerType {
  VK_TOUR_DEST_DETAIL = 'vk_tour_dest_detail',
}

export default function FindNearbyWSp() {
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });
  const [position, setPosition] = useState<any>(null);

  useEffect(() => {
    if (parsed?.lat && parsed?.lng) {
      setPosition({ latitude: parsed?.lat, longitude: parsed?.lng });
    } else {
    }
  }, [search]);

  const [distance, setDistance] = useState<any>(
    parsed?.distance ? parseInt(parsed?.distance as any) : 10
  );

  const handleDistanceChange = (event: any) => {
    let newDistance = event.target.value;

    newDistance = parseInt(newDistance);
    if (isNaN(newDistance) || newDistance < 0) {
      newDistance = 0;
    } else if (newDistance > 150) {
      newDistance = 150;
    }

    setDistance(newDistance);
  };

  const queryClient = useQueryClient();

  const handleFindNearbyPlaces = (evt: any) => {
    if (typeof distance == 'number') {
      if (distance == 0) {
        alert('0보다 큰 수를 입력해주세요');
      } else {
        queryClient.resetQueries('findNearByWSp');
        // fetchNextPage();
        const tempQueryObj: { [key: string]: any } = {};

        if ((tourSearchInput as string)?.trim()?.length > 0) {
          tempQueryObj['search_query'] = (tourSearchInput as string)?.trim() ?? '';
        }

        if (position) {
          tempQueryObj['lng'] = position?.longitude ?? 0;
          tempQueryObj['lat'] = position?.latitude ?? 0;
        }

        if (parsed?.placeName) {
          tempQueryObj['placeName'] = parsed?.placeName ?? '';
        }

        if (distance) {
          tempQueryObj['distance'] = distance ?? '';
        }

        const queryString = stringify(tempQueryObj, {
          addQueryPrefix: true,
          encode: false,
          arrayFormat: 'brackets',
        });

        window.location.href = '/community/vk-travel-dests/find-nearby-w-sp' + queryString;
      }
    }
  };

  const [pageLimit, setPageLimitƒ] = useState(20);
  const [initialStatus, setInitialStatus] = useState(true);
  const [loadDataStatus, setLoadDataStatus] = useState(true);

  const fetchPlacesByDistance = async (dataObj: any) => {
    if (position && distance > 0) {
      setLoadDataStatus(true);

      let currentQueryStringObj: { [key: string]: any } = {
        page: dataObj?.pageParam ?? 1,
        limit: pageLimit,
        originLat: position?.latitude,
        originLng: position?.longitude,
        maxDistance: distance,
        sort: {},
      };

      if (parsed?.search_query) {
        currentQueryStringObj['q'] = parsed?.search_query;
      }

      const currentQueryString = stringify(currentQueryStringObj, {
        addQueryPrefix: true,
        encode: false,
        arrayFormat: 'brackets',
      });

      // if (pageParam == 1) {
      //   setInitialLoading(true);
      //   loadingDots(true, 'initial-loading-container');
      // } else {
      //   loadingDots(true, 'more-loading-container');
      // }

      let result = await api.get(`/community/vk-travel-dests/find-nearby${currentQueryString}`);

      if (currentQueryStringObj?.page == 1) {
        setInitialStatus(false);
      }

      setLoadDataStatus(false);

      // if (pageParam == 1) {
      //   setInitialLoading(false);
      //   loadingDots(false, 'initial-loading-container');
      // } else {
      //   loadingDots(false, 'more-loading-container');
      // }

      if ([200].includes(result?.status)) {
        return result;
      } else return null;
    }
  };

  const {
    data: searchResultData,
    fetchNextPage,
    isLoading: isSearchResultDataLoading,
  } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`findNearByWSp`, position],
    fetchPlacesByDistance as any,
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        if (lastPage?.data?.items?.length > 0) {
          return nextPage;
        } else if (lastPage?.data?.items?.length == 0) {
          return undefined;
        } else return allPages?.length;
      },
      refetchOnWindowFocus: false,
    }
  );

  const observer = useRef<IntersectionObserver>();

  const lastElement = useCallback(
    (target) => {
      if (isSearchResultDataLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && fetchNextPage) {
            setLoadDataStatus(true);
            fetchNextPage();
          } else {
          }
        },
        // {
        //   rootMargin: '500px',
        //   threshold: 0.1,
        // }
        {
          threshold: 1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [searchResultData, isSearchResultDataLoading]
  );

  const drawerRef = useRef<HTMLDivElement | null>(null);
  const [drawerType, setDrawerType] = useState(null);
  const openDrawer = (drawerTypeData: any, heightData?: any) => {
    setDrawerType(drawerTypeData);
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `none`;

      (drawerRef.current as any).style['pointer-events'] = 'auto';

      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = heightData ?? '100%';
    }
  };

  const closeDrawer = () => {
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `auto`;

      drawerRef.current.style.width = '0';
      drawerRef.current.style.height = '0';
    }
  };

  const [chosenVKTourDestForDetail, setChosenVKTourDestForDetail] = useState<any>(null);

  const topHeaderRef = useRef<any>(null);
  const detectHeaderRef = useRef<any>(null);
  // testing
  const handleScroll = () => {
    if (topHeaderRef?.current && detectHeaderRef?.current) {
      let mainEle = document.querySelector('main');
      let detectHeaderTop = (detectHeaderRef?.current as any)?.getBoundingClientRect().top;

      if (detectHeaderTop <= 0) {
        if (topHeaderRef.current && mainEle) {
          (topHeaderRef.current as any).style.position = 'fixed';
          (topHeaderRef.current as any).style.top = '0px';
          (topHeaderRef.current as any).style.left = mainEle.style.left;
        }
      } else {
        if (topHeaderRef.current && mainEle) {
          (topHeaderRef.current as any).style.position = '';
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [tourSearchInput, setTourSearchInput] = useState(parsed?.search_query);
  const handleTourSearchInput = (e: any) => {
    setTourSearchInput(e.target.value);
  };

  return (
    <div>
      <TopTitleBar backButton={true} home={false} logo={true} />
      {/* <div className="max-w-md w-screen bg-white side-padding"> */}
      <div className="w-screen sm:w-full md:w-full lg:w-full bg-white">
        <div className="flex justify-center items-center gap-[5px] mb-[5px]"></div>
        {position ? (
          <>
            <div ref={detectHeaderRef}></div>
            <div
              ref={topHeaderRef}
              className="z-[100] pt-[15px] px-[10px] bg-[white] w-full max-w-md"
            >
              <div className="flex justify-center items-center gap-[10px]">
                <p className="font-[700]">출발장소: </p>
                <p>{parsed?.placeName ?? ''}</p>
                <div
                  className="border px-[10px] py-[1px] rounded-[5px] bg-[#a0f6b8] cursor-pointer"
                  onClick={() =>
                    (window.location.href = `/community/vk-travel-dests/choose-sp-for-nearby`)
                  }
                >
                  변경
                </div>
              </div>
              <div className="flex justify-center items-center gap-[10px] mt-[10px]">
                <p className="text-center text-[15px] font-[700]">거리를 입력해주세요 (km)</p>
                <div className="flex gap-[20px] justify-end items-center">
                  <input
                    type="number"
                    id="distanceInput"
                    value={distance}
                    onChange={handleDistanceChange}
                    min="0"
                    step="1"
                    className="border-[#d9d9d9] rounded-[7px] max-w-[50%]"
                  />
                </div>
              </div>

              <div>
                {parsed && (
                  <>
                    <div
                      className="flex flex-row-reverse w-full items-center px-4 h-12 mt-2
                border border-[#d9d9d9] rounded-[7px] bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
                    >
                      <Button
                        className="bg-[#FF6400] text-[#FFFFFF] rounded-[12px]"
                        style={{ height: '80%' }}
                        onClick={(e) => handleFindNearbyPlaces(e)}
                      >
                        검색
                      </Button>
                      {tourSearchInput ? (
                        <>
                          <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                            <Icon.X
                              className="wh-3"
                              onClick={() => {
                                setTourSearchInput('');
                              }}
                            />
                          </div>
                        </>
                      ) : null}
                      <input
                        className="flex-1 text-sm bg-white"
                        placeholder="검색하기"
                        onChange={(e) => handleTourSearchInput(e)}
                        value={tourSearchInput as string}
                        // onKeyDown={(e) => handleSearchKeyDown(e)}
                      />
                    </div>
                    <div className="w-full flex justify-between items-center"></div>
                  </>
                )}
              </div>
            </div>

            {!((searchResultData as any)?.pages[0]?.data?.items?.length > 0) &&
            !isSearchResultDataLoading &&
            !initialStatus ? (
              <>
                <p className="grid place-content-center h-44 gap-[5px]">검색결과가 없습니다</p>
              </>
            ) : searchResultData?.pages && searchResultData?.pages?.length > 0 ? (
              <div className="side-padding">
                {map(searchResultData?.pages ?? [], (page, pageInd) => {
                  return map(page?.data?.items ?? [], (placeObj, placeObjInd: number) => {
                    return (
                      <>
                        <div
                          key={placeObj?.id}
                          ref={
                            searchResultData?.pages?.length - 1 == pageInd &&
                            page?.data?.items?.length - 1 == placeObjInd
                              ? lastElement
                              : null
                          }
                        >
                          {/* <CardType1
                            pageInd={pageInd}
                            item={placeObj}
                            openDrawer={openDrawer}
                            setChosenVKTourDestForDetail={setChosenVKTourDestForDetail}
                          /> */}
                          {/* <CardType1V2
                            pageInd={pageInd}
                            item={placeObj}
                            openDrawer={openDrawer}
                            setChosenVKTourDestForDetail={setChosenVKTourDestForDetail}
                          /> */}
                          <CardType1V3
                            pageInd={pageInd}
                            item={placeObj}
                            openDrawer={openDrawer}
                            setChosenVKTourDestForDetail={setChosenVKTourDestForDetail}
                          />
                        </div>
                      </>
                    );
                  });
                })}
                {/* <div ref={lastElement}></div> */}

                {loadDataStatus ? (
                  <div className="flex justify-center">
                    <div className="w-[23px] h-[23px] border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
                  </div>
                ) : (
                  <></>
                )}
                {/* <div ref={lastElement}></div> */}
              </div>
            ) : null}
          </>
        ) : (
          <>
            <div className="flex justify-center">
              <div className="w-[23px] h-[23px] border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
            </div>
          </>
        )}
      </div>
      <div className="absolute bottom-10 right-0 w-[30px] h-[30px] z-[1000]">
        <div className="flex justify-end max-w-md">
          <div
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="fixed bottom-10 w-[60px] h-[60px] mr-5 rounded-[50px] bg-[#06A54A] text-[white] font-[300] text-[1.5em] 
        flex justify-center items-center cursor-pointer"
          >
            <IconAngleDoubleTop fill="#FFFFFF" className="w-[35px] h-[35px] " />
          </div>
        </div>
      </div>
      <CustomDrawer drawerRef={drawerRef} contentMt={55}>
        <div className="mt-[50px]">
          {drawerType == DrawerType.VK_TOUR_DEST_DETAIL ? (
            <TravelDestDetail item={chosenVKTourDestForDetail} drawerRef={drawerRef} />
          ) : null}
        </div>
      </CustomDrawer>
    </div>
  );
}
