import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TopNavBar } from '../../components/TopNavBar';
import {
  currentDeviceInfoFromDeviceState,
  deviceInfoState,
  deviceInfoTokenState,
  meState,
  modalState,
  tokenState,
} from '../../ridge/ridge';
import { AppleLoginPage } from '../login/AppleLoginPage';

import { isIOS, isAndroid } from 'react-device-detect';
import { logout } from '../../api_v2/non_admin/users';
import { Bridge, Action } from '../../plugins/bridge';
import { getDeviceInfo } from '../../api_v2/non_admin/device-info';
import { ReactComponent as ArrowRight3Icon } from '../../assets/icons/icon-arrow-right3.svg';
import { getProductionByOS } from '../../api_v2/non_admin/production';

enum OSType {
  IOS = 'ios',
  ANDROID = 'android',
}

export const MyPage = () => {
  const [token, setToken] = tokenState.use();
  const [me, setMe] = meState.use();
  const [deviceInfoToken, setDeviceInfoToken] = deviceInfoTokenState.use();
  const [deviceInfo, setDeviceInfo] = deviceInfoState.use();

  const [productionInfoFromDB, setProductionInfoFromDB] = useState<{ [key: string]: any } | null>(
    null
  );
  const [currentDeviceInfoFromDevice, setCurrentDeviceInfoFromDevice] =
    currentDeviceInfoFromDeviceState.use();

  const { search, pathname } = useLocation();

  const _handleEventPushNotification = async () => {
    const onClose = () => modalState.set({ ...modalState.get(), open: false });
    modalState.set({
      title: '시스템 설정으로 이동',
      subtitle: '기기알림 설정을위해 시스템설정으로 이동합니다.',
      open: true,
      onClose,
      onClick: async () => {
        Bridge.postMessage(Action.CHANGE_PUSH_NOTIFICATION_SETTINGS, '');
        onClose();
      },
    });
  };

  useEffect(() => {
    if (deviceInfoToken) {
      getDeviceInfo().then((result) => {
        if (result?.success) {
          if (result?.data) {
            setDeviceInfo(result.data);
          }
        }
      });
    }
  }, [deviceInfoToken]);

  useEffect(() => {
    Bridge.postMessage(Action.GET_CURRENT_DEVICE_INFO, '');
  }, [pathname]);

  useEffect(() => {
    if (currentDeviceInfoFromDevice) {
      getProductionByOS(isAndroid ? OSType.ANDROID : isIOS ? OSType.IOS : '').then((result) => {
        if (result?.success && result?.data) {
          setProductionInfoFromDB(result.data);
        }
      });
    }
  }, [currentDeviceInfoFromDevice]);

  const checkUpdate = (currDataFromDevice: any, prodDataFromDB: any) => {
    if (currDataFromDevice && prodDataFromDB) {
      const { version: currVersion, buildNumber: currBuildNumber } = currDataFromDevice;
      const { version: versionFromDB, buildNumber: buildNumberFromDB } = prodDataFromDB;
      if (currVersion && currBuildNumber && versionFromDB && buildNumberFromDB) {
        if (
          currVersion !== versionFromDB ||
          currBuildNumber.toString() !== buildNumberFromDB.toString()
        ) {
          return true;
        } else return false;
      } else return true;
    } else return true;
  };

  const _handleUpdateApp = () => {
    Bridge.postMessage(
      Action.OPEN_URL,
      isAndroid
        ? 'https://play.google.com/store/apps/details?id=com.pridge'
        : isIOS
        ? 'https://apps.apple.com/kr/app/%ED%94%84%EB%A6%BF%EC%A7%80/id1615311070'
        : ''
    );
  };

  return (
    <>
      <TopNavBar logo={false} nav={false} search={false} backButton={true} homeButton={true} />

      {/* <button
        className="bg-black text-white"
        onClick={() => {
          Bridge.postMessage(Action.CHANGE_PUSH_NOTIFICATION_SETTINGS, '');
        }}
      >
        change push notification settings
      </button> */}
      <div className="side-padding">
        <div className="w-full flex flex-col font-bold">
          {isIOS && (!token || !me) ? (
            <>
              <p className="mb-3">로그인 후 이용해주세요.</p>
              <div className="w-full flex items-center justify-center">
                <AppleLoginPage />
              </div>
            </>
          ) : token && me ? (
            <>
              <p className="mb-3">{me?.email ? me.email : '이메일을 등록해주세요'}</p>
              <button
                onClick={() => {
                  logout();
                }}
                className=" w-full bg-black text-white text-15 font-normal flex items-center justify-center h-14"
              >
                로그아웃
              </button>
            </>
          ) : null}
        </div>
        <div className="mt-5">
          <p className="font-bold text-2xl">푸시 알림 설정</p>
          <p className="mt-2">이벤트 및 할인 혜택 정보를 놓치지 않도록 알려드려요</p>
          <div>
            <div className="flex justify-between mt-5">
              <span className="">이벤트 및 혜택 알림 설정</span>
              {/* <Toggle
                // toggleStatus={marketingAgreementStatus}
                checked={deviceInfo?.eventPushNotification ?? false}
                onClick={() => _handleEventPushNotification()}
                // onChange={(checked) => _handlePushNotification(checked)}
              /> */}
              <button
                // disabled={toggleStatus}
                type="button"
                className={`toggle ${
                  deviceInfo?.eventPushNotification ? 'bg-brand-1' : 'bg-gray-200'
                }`}
                onClick={(e) => {
                  _handleEventPushNotification();
                  // const newValue = !internalChecked;
                  // setInternalChecked(newValue);
                  // onChange?.(newValue);
                  // onClick?.(e);
                }}
                // {...props}
              >
                <span
                  className={`toggle-slider ${
                    deviceInfo?.eventPushNotification ? 'translate-x-5' : 'translate-x-0'
                  }`}
                ></span>
              </button>
            </div>
            <div className="flex justify-between mt-8">
              <span className="">마케팅 수신 동의</span>
              <div className="flex justify-between">
                {deviceInfoToken && deviceInfo?.marketingAgreement ? <>동의함</> : <>동의안함</>}
                <ArrowRight3Icon
                  onClick={() =>
                    Bridge.postMessage(
                      Action.OPEN_URL,
                      'https://cooing-dungeon-253.notion.site/a377245352bf4711b58268a4369da305'
                    )
                  }
                />
              </div>
              {/* <Toggle
                toggleStatus={marketingAgreementStatus}
                checked={marketingAgreement}
                onChange={(checked) => _handlePushNotification(checked)}
              /> */}
            </div>
            <div className={`flex justify-between mt-8`}>
              <div className="flex">
                <div className="mr-2">앱 버전 정보</div>
                <div>
                  {currentDeviceInfoFromDevice?.version ?? ''} (
                  {currentDeviceInfoFromDevice?.buildNumber ?? ''})
                </div>
              </div>

              {!currentDeviceInfoFromDevice ||
              (productionInfoFromDB?.isVisible &&
                checkUpdate(currentDeviceInfoFromDevice, productionInfoFromDB)) ? (
                <div className="text-[#0055FE]" onClick={() => _handleUpdateApp()}>
                  업데이트
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="text-13 text-gray-700 leading-relaxed mt-5">
          주식회사 보딩패스 | 서울 성동구 아차산로7나길 18 성수대선에이팩 905호 | TEL :
          070-7954-6610 | 대표 : 조건형 | 사업자등록번호 : 323-88-02118 | 통신판매업신고번호 :
          2021-서울관악-1778 | 개인정보관리책임 : 조건형
        </div>
        <div className="flex flex-wrap w-max text-gray-600 mt-3">
          {[
            {
              text: '개인정보 수집 및 이용',
              pathname: 'https://cooing-dungeon-253.notion.site/f1c015d0484f4ca182be9e61c95e05d4',
            },
            {
              text: '마케팅 수신 약관',
              pathname: 'https://cooing-dungeon-253.notion.site/a377245352bf4711b58268a4369da305',
            },
          ].map((b) => (
            <>
              <button
                onClick={() => {
                  Bridge.postMessage(Action.OPEN_URL, b.pathname);
                }}
                className="text-12 text-left mr-3 mb-2 underline"
              >
                {b.text}
              </button>
            </>
          ))}
        </div>
      </div>
      {/* <Section className="side-padding">
        {me ? (
          <>
            <h1>{me.name.full ? me.name.full : me.alias}</h1>
            <h4 className="font-normal text-gray-600">{me?.email}</h4>
          </>
        ) : (
          <>
            <h1>로그인해주세요</h1>
            <Button to={'/login'} text="로그인" className="filled-black w-full mt-4 h-14" />
            <img src={signupImg1} alt="" className="w-screen mt-6" />
          </>
        )}

        {authenticated && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            <button
              onClick={() => history.push('/my/coupon')}
              className="bg-gray-50 grid place-content-center py-5 text-center"
            >
              <span className="text-gray-600 text-13">보유 쿠폰</span>
              {typeof couponCount === 'number' && <h2>{couponCount}장</h2>}
            </button>
            <button
              onClick={() => history.push('/my/reviews')}
              className="bg-gray-50 grid place-content-center py-5 text-center"
            >
              <span className="text-gray-600 text-13">리뷰쓰기</span>
              <h2>{fullfillments?.pages[0].data.total}건</h2>
            </button>
          </div>
        )}
      </Section>

      <div className="bg-gray-50 w-full h-3 mt-6 mb-3" />

      <Section className="side-padding space-y-2 pb-28">
        {me &&
          [
            { text: '주문 배송 내역', pathname: '/my/order' },
            { text: '리뷰 관리', pathname: '/my/reviews' },
            { text: '내 정보 수정', pathname: '/my/edit' },
            {
              text: '배송지 관리',
              pathname: `/my/shipping${stringify({ from: 'my' }, { addQueryPrefix: true })}`,
            },
          ].map((b) => (
            <Button
              className="border-b w-full flex justify-between items-center cursor-pointer px-0 font-normal"
              to={b.pathname}
            >
              <div>{b.text}</div>
              <Icon.ChevronRight />
            </Button>
          ))}
        <Button
          className="border-b w-full flex justify-between items-center cursor-pointer px-0 font-normal"
          to="/my/terms-of-use"
        >
          <div>이용 안내</div>
          <Icon.ChevronRight />
        </Button>
        <div className="text-13 text-gray-700 leading-relaxed pt-2">
          주식회사 보딩패스 | 서울 성동구 아차산로7나길 18 성수대선에이팩 905호 | TEL :
          070-7954-6610 | 대표 : 조건형 | 사업자등록번호 : 323-88-02118 | 통신판매업신고번호 :
          2021-서울관악-1778 | 개인정보관리책임 : 조건형
        </div>
        <div className="flex flex-wrap w-max text-gray-600">
          {[
            {
              text: '개인정보 수집 및 이용',
              pathname: 'https://cooing-dungeon-253.notion.site/75e42f2c74d04c928b8b30c0a35432f0',
            },
            {
              text: '서비스 이용약관',
              pathname: 'https://cooing-dungeon-253.notion.site/ca032014158a492d81bae048623d1632',
            },
            {
              text: '마케팅 수신 약관',
              pathname: 'https://cooing-dungeon-253.notion.site/0964e059eab349159878ca4d0c8424f6',
            },
          ].map((b) => (
            <button
              onClick={() => {
                window.open(b.pathname, '_blank');
              }}
              className="text-12 text-left mr-3 mb-2 underline"
            >
              {b.text}
            </button>
          ))}
        </div>
      </Section> */}
    </>
  );
};

const EmailNotExist = () => <Link to="/my/edit">이메일 정보 없음</Link>;
