import { Link, useLocation } from 'react-router-dom';
import { Card } from '../../../../../components/Card';
import { Pagination } from '../../../../../components/Pagination';
import { Table } from '../../../../../components/Table';
import { AdminH1 } from '../../../../components/AdminH1';
import { Button } from '../../../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../../../plugins/moment';

import { map } from 'lodash';
import { useQueryString } from '../../../../../hooks/hooks';
import { useEffect, useState } from 'react';

import { parse, stringify } from 'qs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Icon } from '../../../../../components/Icon';
import { getTDCAccommodations } from '../../../../../api_v2/admin/community/tdc-accommodations';

export const TDCAccommodationList = () => {
  const queryString = useQueryString();
  const [currAccomms, setCurrAccomms] = useState<{ [key: string]: any }[]>([]);
  const [totalAccommsCount, setTotalAccommsCount] = useState<number>(0);
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    let path = `?page=${parsed?.page ?? 1}&limit=${parsed?.limit ?? 10}`;
    if (parsed?.q) {
      path = path + `&q=${parsed?.q}`;
    }
    getTDCAccommodations(path).then((resultData: any) => {
      if (resultData?.success) {
        if (resultData.data) {
          if (resultData.data[0]) {
            setCurrAccomms(resultData.data[0]);
            setTotalAccommsCount(resultData.data[1]);
          }
        }
      }
    });
  }, [search]);

  const handleSearch = async () => {
    try {
      let path = `?page=${1}&limit=${10}`;
      if (tdcAccommSearchInput && typeof tdcAccommSearchInput == 'string') {
        path = path + `&q=${tdcAccommSearchInput?.trim() ?? ''}`;
      }
      window.location.href = path;
    } catch (e) {}
  };

  const [tdcAccommSearchInput, setTdcAccommSearchInput] = useState(parsed?.q ?? '');

  const handleTDCAccommSearchInput = (e: any) => {
    setTdcAccommSearchInput(e.target.value);
  };

  const handleTDCAccommSearchButton = (evt: any) => {
    handleSearch();
  };

  const handleTDCAccommSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>트립닷컴숙소리스트 관리</AdminH1>
        <div className="flex gap-[5px]">
          <Button
            text="숙소 추가"
            className="outlined-black text-sm"
            to="/admin/community/tdc-accommodations/add"
          />
        </div>
      </div>

      {parsed && (
        <>
          <div
            className="flex flex-row-reverse w-full max-w-[500px] items-center px-4 h-12 mt-2
                border border-[black] bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
          >
            <Button
              className="bg-[#FF6400] text-[#FFFFFF]"
              style={{ height: '80%' }}
              onClick={(e) => handleTDCAccommSearchButton(e)}
            >
              검색
            </Button>
            {tdcAccommSearchInput ? (
              <>
                <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                  <Icon.X
                    className="wh-3"
                    onClick={() => {
                      setTdcAccommSearchInput('');
                    }}
                  />
                </div>
              </>
            ) : null}
            <input
              className="flex-1 text-sm bg-white"
              placeholder="검색하기"
              onChange={(e: any) => handleTDCAccommSearchInput(e)}
              value={tdcAccommSearchInput as string}
              onKeyDown={(e) => handleTDCAccommSearchKeyDown(e)}
            />
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center gap-[10px]"></div>
          </div>
        </>
      )}

      <div className="flex justify-end items-center">
        <div className="flex space-x-2 items-center">
          <div>전체숙소 수</div>
          <div className="text-green-500">{totalAccommsCount}</div>
        </div>
        <div>개</div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>id</Table.Th>
              <Table.Th></Table.Th>
              <Table.Th>adId</Table.Th>
              <Table.Th>siteId</Table.Th>
              <Table.Th>name</Table.Th>
              <Table.Th>link</Table.Th>
              <Table.Th>mainImageUrl</Table.Th>
              <Table.Th>maxDiscountRate</Table.Th>
              <Table.Th>addr</Table.Th>
              <Table.Th>city</Table.Th>
              <Table.Th>shortAddr</Table.Th>
              <Table.Th>createdAt</Table.Th>
              <Table.Th>updatedAt</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(currAccomms, (accomm) => (
              <Table.Row key={accomm.id}>
                <Table.Td>{accomm.id}</Table.Td>
                <Table.Td>
                  <a
                    href={`/admin/community/tdc-accommodations/${accomm.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    수정
                  </a>
                </Table.Td>
                <Table.Td>
                  <>{accomm.adId}</>
                </Table.Td>
                <Table.Td>{accomm.siteId}</Table.Td>
                <Table.Td>{accomm?.name}</Table.Td>
                <Table.Td>
                  <a href={accomm?.link} target="_blank" rel="noreferrer">
                    {accomm?.link}
                  </a>
                </Table.Td>
                <Table.Td>
                  <a href={accomm?.mainImageUrl} target="_blank" rel="noreferrer">
                    {accomm?.mainImageUrl}
                  </a>
                </Table.Td>
                <Table.Td>{accomm.maxDiscountRate}</Table.Td>
                <Table.Td>{accomm.addr}</Table.Td>
                <Table.Td>{accomm.city}</Table.Td>
                <Table.Td>{accomm.shortAddr}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(accomm.createdAt.toLocaleString(), MomentFormat.YYYYMMDDHmm)}
                </Table.Td>
                <Table.Td>
                  {utcToLocalFormat(accomm.updatedAt.toLocaleString(), MomentFormat.YYYYMMDDHmm)}
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Nav
            basePath="/admin/community/tdc-accommodations"
            total={totalAccommsCount}
            q={parsed?.q ?? ''}
          />
        </Pagination>
      </Card>
    </>
  );
};
