export const cities = [
  '양양군',
  '철원군',
  '영월군',
  '양구군',
  '태백시',
  '화천군',
  '횡성군',
  '평창군',
  '고성군',
  '삼척시',
  '홍천군',
  '동해시',
  '강릉시',
  '정선군',
  '속초시',
  '원주시',
  '춘천시',
  '인제군',
  '이천시',
  '여주시',
  '성남시',
  '안성시',
  '용인시',
  '남양주시',
  '고양시',
  '부천시',
  '군포시',
  '김포시',
  '안산시',
  '포천시',
  '양평군',
  '구리시',
  '가평군',
  '광주시',
  '수원시',
  '평택시',
  '안양시',
  '동두천시',
  '의왕시',
  '의정부시',
  '하남시',
  '과천시',
  '광명시',
  '양주시',
  '화성시',
  '파주시',
  '시흥시',
  '연천군',
  '오산시',
  '양산시',
  '산청군',
  '김해시',
  '합천군',
  '창원시',
  '남해군',
  '진주시',
  '함양군',
  '창녕군',
  '거제시',
  '하동군',
  '함안군',
  '거창군',
  '의령군',
  '통영시',
  '사천시',
  '밀양시',
  '청송군',
  '안동시',
  '상주시',
  '포항시',
  '군위군',
  '봉화군',
  '칠곡군',
  '성주군',
  '청도군',
  '구미시',
  '문경시',
  '울릉군',
  '울진군',
  '경산시',
  '영덕군',
  '경주시',
  '영천시',
  '예천군',
  '의성군',
  '영주시',
  '김천시',
  '광산구',
  '달서구',
  '달성군',
  '남구',
  '수성구',
  '유성구',
  '대덕구',
  '동래구',
  '기장군',
  '수영구',
  '연제구',
  '해운대구',
  '부산진구',
  '사하구',
  '사상구',
  '영도구',
  '금정구',
  '도봉구',
  '영등포구',
  '구로구',
  '성동구',
  '강남구',
  '강동구',
  '서초구',
  '금천구',
  '양천구',
  '서대문구',
  '강북구',
  '중랑구',
  '동대문구',
  '성북구',
  '용산구',
  '마포구',
  '노원구',
  '관악구',
  '송파구',
  '은평구',
  '동작구',
  '종로구',
  '강서구',
  '광진구',
  '동구',
  '북구',
  '울주군',
  '부평구',
  '서구',
  '연수구',
  '계양구',
  '옹진군',
  '남동구',
  '미추홀구',
  '중구',
  '강화군',
  '무안군',
  '순천시',
  '구례군',
  '보성군',
  '목포시',
  '완도군',
  '강진군',
  '영광군',
  '고흥군',
  '장성군',
  '나주시',
  '함평군',
  '해남군',
  '곡성군',
  '영암군',
  '여수시',
  '화순군',
  '진도군',
  '광양시',
  '담양군',
  '신안군',
  '장흥군',
  '전주시',
  '부안군',
  '임실군',
  '순창군',
  '김제시',
  '군산시',
  '완주군',
  '정읍시',
  '장수군',
  '고창군',
  '무주군',
  '남원시',
  '진안군',
  '익산시',
  '제주시',
  '서귀포시',
  '보령시',
  '천안시',
  '홍성군',
  '논산시',
  '서산시',
  '서천군',
  '청양군',
  '예산군',
  '금산군',
  '아산시',
  '당진시',
  '계룡시',
  '태안군',
  '부여군',
  '공주시',
  '음성군',
  '증평군',
  '충주시',
  '청주시',
  '진천군',
  '영동군',
  '단양군',
  '보은군',
  '옥천군',
  '제천시',
  '괴산군',
];

export const regions = [
  '강원',
  '경기',
  '경남',
  '경북',
  '광주',
  '대구',
  '대전',
  '부산',
  '서울',
  '울산',
  '인천',
  '전남',
  '전북',
  '제주',
  '충남',
  '충북',
  '세종',
];

export const regionsObj: { [key: string]: any } = {
  강원: [
    '양양군',
    '철원군',
    '영월군',
    '양구군',
    '태백시',
    '화천군',
    '횡성군',
    '평창군',
    '고성군',
    '삼척시',
    '홍천군',
    '동해시',
    '강릉시',
    '정선군',
    '속초시',
    '원주시',
    '춘천시',
    '인제군',
  ],
  경기: [
    '이천시',
    '여주시',
    '성남시',
    '안성시',
    '용인시',
    '남양주시',
    '고양시',
    '부천시',
    '군포시',
    '김포시',
    '안산시',
    '포천시',
    '양평군',
    '구리시',
    '가평군',
    '광주시',
    '수원시',
    '평택시',
    '안양시',
    '동두천시',
    '의왕시',
    '의정부시',
    '하남시',
    '과천시',
    '광명시',
    '양주시',
    '화성시',
    '파주시',
    '시흥시',
    '연천군',
    '오산시',
  ],
  경남: [
    '양산시',
    '산청군',
    '김해시',
    '합천군',
    '창원시',
    '남해군',
    '진주시',
    '함양군',
    '창녕군',
    '거제시',
    '하동군',
    '함안군',
    '거창군',
    '의령군',
    '통영시',
    '사천시',
    '밀양시',
  ],
  경북: [
    '청송군',
    '안동시',
    '상주시',
    '포항시',
    '군위군',
    '봉화군',
    '칠곡군',
    '성주군',
    '청도군',
    '구미시',
    '문경시',
    '울릉군',
    '울진군',
    '경산시',
    '영덕군',
    '경주시',
    '영천시',
    '예천군',
    '의성군',
    '영주시',
    '김천시',
  ],
  광주: ['광산구'],
  대구: ['달서구', '달성군', '남구', '수성구'],
  대전: ['유성구', '대덕구'],
  부산: [
    '동래구',
    '기장군',
    '수영구',
    '연제구',
    '해운대구',
    '부산진구',
    '사하구',
    '사상구',
    '영도구',
    '금정구',
  ],
  서울: [
    '도봉구',
    '영등포구',
    '구로구',
    '성동구',
    '강남구',
    '강동구',
    '서초구',
    '금천구',
    '양천구',
    '서대문구',
    '강북구',
    '중랑구',
    '동대문구',
    '성북구',
    '용산구',
    '마포구',
    '노원구',
    '관악구',
    '송파구',
    '은평구',
    '동작구',
    '종로구',
    '강서구',
    '광진구',
  ],
  울산: ['동구', '북구', '울주군'],
  인천: ['부평구', '서구', '연수구', '계양구', '옹진군', '남동구', '미추홀구', '중구', '강화군'],
  전남: [
    '무안군',
    '순천시',
    '구례군',
    '보성군',
    '목포시',
    '완도군',
    '강진군',
    '영광군',
    '고흥군',
    '장성군',
    '나주시',
    '함평군',
    '해남군',
    '곡성군',
    '영암군',
    '여수시',
    '화순군',
    '진도군',
    '광양시',
    '담양군',
    '신안군',
    '장흥군',
  ],
  전북: [
    '전주시',
    '부안군',
    '임실군',
    '순창군',
    '김제시',
    '군산시',
    '완주군',
    '정읍시',
    '장수군',
    '고창군',
    '무주군',
    '남원시',
    '진안군',
    '익산시',
  ],
  제주: ['제주시', '서귀포시'],
  충남: [
    '보령시',
    '천안시',
    '홍성군',
    '논산시',
    '서산시',
    '서천군',
    '청양군',
    '예산군',
    '금산군',
    '아산시',
    '당진시',
    '계룡시',
    '태안군',
    '부여군',
    '공주시',
  ],
  충북: [
    '음성군',
    '증평군',
    '충주시',
    '청주시',
    '진천군',
    '영동군',
    '단양군',
    '보은군',
    '옥천군',
    '제천시',
    '괴산군',
  ],
  세종: [],
};

export const regionsObj2: { [key: string]: any } = {
  서울: [
    '종로구',
    '중구',
    '용산구',
    '성동구',
    '광진구',
    '동대문구',
    '중랑구',
    '성북구',
    '강북구',
    '도봉구',
    '노원구',
    '은평구',
    '서대문구',
    '마포구',
    '양천구',
    '강서구',
    '구로구',
    '금천구',
    '영등포구',
    '동작구',
    '관악구',
    '서초구',
    '강남구',
    '송파구',
    '강동구',
  ],
  부산: [
    '중구',
    '서구',
    '동구',
    '영도구',
    '부산진구',
    '동래구',
    '남구',
    '북구',
    '해운대구',
    '사하구',
    '금정구',
    '강서구',
    '연제구',
    '수영구',
    '사상구',
    '기장군',
  ],
  대구: ['중구', '동구', '서구', '남구', '북구', '수성구', '달서구', '달성군'],
  인천: [
    '중구',
    '중구영종출장소',
    '중구용유출장소',
    '동구',
    '미추홀구',
    '연수구',
    '남동구',
    '부평구',
    '계양구',
    '서구',
    '서구검단출장소',
    '강화군',
    '옹진군',
  ],
  광주: ['동구', '서구', '남구', '북구', '광산구'],
  대전: ['동구', '중구', '서구', '유성구', '대덕구'],
  울산: ['중구', '남구', '동구', '북구', '울주군'],
  세종: [],
  경기: [
    '수원시',

    '성남시',

    '의정부시',
    '안양시',

    '부천시',
    '광명시',
    '평택시',
    '송탄출장소',
    '안중출장소',
    '동두천시',
    '안산시',

    '고양시',

    '과천시',
    '구리시',
    '남양주시',
    '풍양출장소',
    '오산시',
    '시흥시',
    '군포시',
    '의왕시',
    '하남시',
    '용인시',

    '파주시',
    '이천시',
    '안성시',
    '김포시',
    '화성시',
    '화성시동부출장소',
    '화성시동탄출장소',
    '광주시',
    '양주시',
    '포천시',
    '여주시',
    '연천군',
    '가평군',
    '양평군',
  ],
  강원: [
    '춘천시',
    '원주시',
    '강릉시',
    '동해시',
    '태백시',
    '속초시',
    '삼척시',
    '홍천군',
    '횡성군',
    '영월군',
    '평창군',
    '정선군',
    '철원군',
    '화천군',
    '양구군',
    '인제군',
    '고성군',
    '양양군',
  ],
  충북: [
    '청주시',
    '제천시',
    '보은군',
    '옥천군',
    '영동군',
    '증평군',
    '진천군',
    '괴산군',
    '음성군',
    '단양군',
  ],
  충남: [
    '천안시',
    '공주시',
    '보령시',
    '아산시',
    '서산시',
    '논산시',
    '계룡시',
    '당진시',
    '금산군',
    '부여군',
    '서천군',
    '청양군',
    '홍성군',
    '예산군',
    '태안군',
  ],
  전북: [
    '전주시',

    '전주시효자출장소',
    '군산시',
    '익산시',
    '익산시함열출장소',
    '정읍시',
    '남원시',
    '김제시',
    '완주군',
    '진안군',
    '무주군',
    '장수군',
    '임실군',
    '순창군',
    '고창군',
    '부안군',
  ],
  전남: [
    '목포시',
    '여수시',
    '순천시',
    '나주시',
    '광양시',
    '담양군',
    '곡성군',
    '구례군',
    '고흥군',
    '보성군',
    '화순군',
    '장흥군',
    '강진군',
    '해남군',
    '영암군',
    '무안군',
    '함평군',
    '영광군',
    '장성군',
    '완도군',
    '진도군',
    '신안군',
  ],
  경북: [
    '포항시',

    '경주시',
    '김천시',
    '안동시',
    '구미시',
    '영주시',
    '영천시',
    '상주시',
    '문경시',
    '경산시',
    '군위군',
    '의성군',
    '청송군',
    '영양군',
    '영덕군',
    '청도군',
    '고령군',
    '성주군',
    '칠곡군',
    '예천군',
    '봉화군',
    '울진군',
    '울릉군',
  ],
  경남: [
    '창원시',

    '진주시',
    '통영시',
    '사천시',
    '사천남양출장소',
    '김해시',
    '장유출장소',
    '밀양시',
    '거제시',
    '양산시',
    '양산시웅상출장소',
    '의령군',
    '함안군',
    '창녕군',
    '고성군',
    '남해군',
    '하동군',
    '산청군',
    '함양군',
    '거창군',
    '합천군',
  ],
  제주: ['제주시', '서귀포시'],
};

export const regionsObj3: { [key: string]: any } = {
  강원: [
    '양양군',
    '철원군',
    '영월군',
    '양구군',
    '태백시',
    '화천군',
    '횡성군',
    '평창군',
    '고성군',
    '삼척시',
    '홍천군',
    '동해시',
    '강릉시',
    '정선군',
    '속초시',
    '원주시',
    '춘천시',
    '인제군',
  ],
  경기: [
    '이천시',
    '여주시',
    '성남시',
    '안성시',
    '용인시',
    '남양주시',
    '고양시',
    '부천시',
    '군포시',
    '김포시',
    '안산시',
    '포천시',
    '양평군',
    '구리시',
    '가평군',
    '광주시',
    '수원시',
    '평택시',
    '안양시',
    '동두천시',
    '의왕시',
    '의정부시',
    '하남시',
    '과천시',
    '광명시',
    '양주시',
    '화성시',
    '파주시',
    '시흥시',
    '연천군',
    '오산시',
    '송탄출장소',
    '안중출장소',
    '풍양출장소',
    '화성시동부출장소',
    '화성시동탄출장소',
  ],
  경남: [
    '양산시',
    '산청군',
    '김해시',
    '합천군',
    '창원시',
    '남해군',
    '진주시',
    '함양군',
    '창녕군',
    '거제시',
    '하동군',
    '함안군',
    '거창군',
    '의령군',
    '통영시',
    '사천시',
    '밀양시',
    '사천남양출장소',
    '장유출장소',
    '양산시웅상출장소',
    '고성군',
  ],
  경북: [
    '청송군',
    '안동시',
    '상주시',
    '포항시',
    '군위군',
    '봉화군',
    '칠곡군',
    '성주군',
    '청도군',
    '구미시',
    '문경시',
    '울릉군',
    '울진군',
    '경산시',
    '영덕군',
    '경주시',
    '영천시',
    '예천군',
    '의성군',
    '영주시',
    '김천시',
    '영양군',
    '고령군',
  ],
  광주: ['광산구', '동구', '서구', '남구', '북구'],
  대구: ['달서구', '달성군', '남구', '수성구', '중구', '동구', '서구', '북구'],
  대전: ['유성구', '대덕구', '동구', '중구', '서구'],
  부산: [
    '동래구',
    '기장군',
    '수영구',
    '연제구',
    '해운대구',
    '부산진구',
    '사하구',
    '사상구',
    '영도구',
    '금정구',
    '중구',
    '서구',
    '동구',
    '남구',
    '북구',
    '강서구',
  ],
  서울: [
    '도봉구',
    '영등포구',
    '구로구',
    '성동구',
    '강남구',
    '강동구',
    '서초구',
    '금천구',
    '양천구',
    '서대문구',
    '강북구',
    '중랑구',
    '동대문구',
    '성북구',
    '용산구',
    '마포구',
    '노원구',
    '관악구',
    '송파구',
    '은평구',
    '동작구',
    '종로구',
    '강서구',
    '광진구',
    '중구',
  ],
  울산: ['동구', '북구', '울주군', '중구', '남구'],
  인천: [
    '부평구',
    '서구',
    '연수구',
    '계양구',
    '옹진군',
    '남동구',
    '미추홀구',
    '중구',
    '강화군',
    '중구영종출장소',
    '중구용유출장소',
    '동구',
    '서구검단출장소',
  ],
  전남: [
    '무안군',
    '순천시',
    '구례군',
    '보성군',
    '목포시',
    '완도군',
    '강진군',
    '영광군',
    '고흥군',
    '장성군',
    '나주시',
    '함평군',
    '해남군',
    '곡성군',
    '영암군',
    '여수시',
    '화순군',
    '진도군',
    '광양시',
    '담양군',
    '신안군',
    '장흥군',
  ],
  전북: [
    '전주시',
    '부안군',
    '임실군',
    '순창군',
    '김제시',
    '군산시',
    '완주군',
    '정읍시',
    '장수군',
    '고창군',
    '무주군',
    '남원시',
    '진안군',
    '익산시',
    '전주시효자출장소',
    '익산시함열출장소',
  ],
  제주: ['제주시', '서귀포시'],
  충남: [
    '보령시',
    '천안시',
    '홍성군',
    '논산시',
    '서산시',
    '서천군',
    '청양군',
    '예산군',
    '금산군',
    '아산시',
    '당진시',
    '계룡시',
    '태안군',
    '부여군',
    '공주시',
  ],
  충북: [
    '음성군',
    '증평군',
    '충주시',
    '청주시',
    '진천군',
    '영동군',
    '단양군',
    '보은군',
    '옥천군',
    '제천시',
    '괴산군',
  ],
  세종: [],
};

export const campingTypes = ['오토캠핑', '글램핑', '카라반', '펜션', '키즈캠핑', '애견캠핑'];

export const facilities = [
  '화장실',
  '샤워장',
  '개수대',
  '매점',
  '와이파이',
  '카페',
  '수영장',
  '바베큐장',
  '개별화장실',
  '개별샤워실',
  '전기차충전소',
];

export const addFacilities = [
  '트레일러진입',
  '카라반진입',
  '트램펄린',
  '장비대여',
  '반려동물',
  '산책로',
  '동물체험',
  '놀이시설',
  '찜질방',
  '짚라인',
  '장비보관',
  '농장체험',
  '썰매장',
  '온수수영장',
];

export const surroundings = [
  '체험활동',
  '골프',
  '스키',
  'MTB',
  '해수욕장',
  '물놀이',
  '수상레져',
  '낚시',
  '등산',
  '갯벌',
];

export const calendarYears = [2022, 2023, 2024, 2025];
