import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { AdminH1 } from '../../components/AdminH1';
import { Button } from '../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { useExhibitions } from '../../../hooks/exhibition';
import { useQueryString } from '../../../hooks/hooks';
import { map } from 'lodash';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { getExhibitions } from '../../../api_v2/admin/exhibitions';
import { toast } from 'react-toastify';
import { getCampingInfoList } from '../../../api_v2/admin/camping';
import { parse, stringify } from 'qs';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import { regionsObj, regionsObj2 } from './constants';

export const CampingList = () => {
  // const [queryString, setQueryString] = useState<string>(useQueryString({ sort: {} }));
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [campingList, setCampingList] = useState<any[]>([]);
  const [campingListTotal, setCampingListTotal] = useState<number>(0);

  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    const searchObject = {
      page: '1',
      limit: '10',
      sort: { id: 'DESC' },
      ...parsed,
    };
    getCampingInfoList(stringify(searchObject, { addQueryPrefix: true, encode: false }) ?? '').then(
      (res: any) => {
        if (res?.success) {
          setCampingListTotal(res?.data?.total ?? 0);
          setCampingList(res?.data?.items ?? []);
        } else {
        }
      }
    );
  }, [search]);

  // const queryString = stringify(
  //   {
  //     page: pageParam,
  //     limit: 10,
  //     sort: {},
  //     filter: {
  //       // collections: { ilike: selectCollection },
  //       // available: true,
  //       // censoredAt: 'null',
  //       // isBestWeekProduct: true,
  //     },
  //   },
  //   { addQueryPrefix: true, encode: false }
  // );

  // const refreshExhibitions = (qs: string) => {
  //   getExhibitions(qs).then((resultData) => {
  //     if (resultData?.success) {
  //       if (resultData.data) {
  //         setExhibitions(resultData.data.items);
  //         setCampingSitesCount(resultData.data.total);
  //       }
  //     } else {
  //       toast.error('Something went wrong getting exhibitions');
  //     }
  //   });
  // };

  // useEffect(() => {
  //   refreshExhibitions(queryString);
  // }, [queryString]);

  // if (!exhibitions) {
  //   return null;
  // }

  // const onEditWeight = () => {
  //   refreshExhibitions(queryString);
  // };

  // useEffect(() => {
  //   getCampingInfoList(queryString).then((res: any) => {
  //     if (res?.success) {
  //       setCampingListTotal(res?.data?.total ?? 0);
  //       setCampingList(res?.data?.items ?? []);
  //     } else {
  //     }
  //   });
  //   // setCampingList((prev) => {
  //   //   return [...prev, { id: 1, name: 'test', createdAt: new Date() }];
  //   // });
  // }, [queryString]);

  const handleInputFile = async (evt: any) => {
    let excelFile = evt.target.files[0];
    let reader = new FileReader();
    reader.readAsArrayBuffer(excelFile);
    reader.onload = function (event: any) {
      let tempObj: { [key: string]: any } = {};
      let result = event.target.result;
      const wb = XLSX.read(result, { type: 'buffer' });
      wb.SheetNames.forEach((sn) => {
        let data: any = XLSX.utils.sheet_to_json(wb.Sheets[sn]);

        for (let i = 0; i < data.length; i++) {
          if (data[i] && data[i]['__EMPTY']) {
            let tempSplit = data[i]['__EMPTY']?.split(' ');

            let tempSplitLen = tempSplit?.length ?? 0;
            if (tempSplitLen == 1) {
            } else if (tempSplitLen >= 2) {
              let firstItem = tempSplit[0];
              let secondItem = tempSplit[1];

              if (!tempObj.hasOwnProperty(firstItem)) {
                tempObj[firstItem] = [];
              } else {
                tempObj[firstItem] = [...tempObj[firstItem], secondItem];
              }
            }
          }
        }
      });
    };
  };

  useEffect(() => {
    // let tempObj: { [key: string]: any } = {};
    // let repeatedFunc = (objData: any) => {
    //   for (let i = 0; i < Object.keys(objData).length; i++) {
    //     let key = Object.keys(objData)[i];
    //     if (tempObj.hasOwnProperty(key)) {
    //       tempObj[key] = _.uniq([...tempObj[key], ...objData[key]]);
    //     } else {
    //       tempObj[key] = [...objData[key]];
    //     }
    //   }
    // };
    // repeatedFunc(regionsObj);
    // repeatedFunc(regionsObj2);
  }, []);

  return (
    <>
      <div className="flex justify-between items-center">
        {/* <input
          type="file"
          onChange={(e) => {
            handleInputFile(e);
          }}
        /> */}
        <AdminH1>캠핑장 목록</AdminH1>
        <Button text="캠핑장 추가" className="outlined-black text-sm" to="/admin/camping/add" />
      </div>
      <div className="flex justify-end items-center">
        <div className="flex space-x-2 items-center">
          <div>전체 캠핑장 수</div>
          <div className="text-green-500">{campingListTotal}</div>
        </div>
        <div>개</div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>생성일</Table.Th>
              <Table.Th>캠핑장명</Table.Th>
              <Table.Th>캠핑장 정보</Table.Th>
              <Table.Th>구역 정보</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(campingList, (campingInfo) => (
              <Table.Row key={campingInfo.id}>
                <Table.Td>{campingInfo.id}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    campingInfo.created_at.toLocaleString(),
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td>{campingInfo?.name}</Table.Td>
                <Table.Td className="">
                  <Link
                    className="font-normal text-blue-500"
                    to={`/admin/camping/${campingInfo.id}`}
                  >
                    수정/삭제
                  </Link>
                </Table.Td>
                <Table.Td className="">
                  <Link
                    className="font-normal text-blue-500"
                    to={`/admin/camping/${campingInfo.id}/zones`}
                  >
                    추가/수정/삭제
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={campingList?.length ?? 0} total={campingListTotal} />
          <Pagination.Nav basePath="/admin/camping" total={campingListTotal} />
        </Pagination>
      </Card>
    </>
  );
};
