import axios from 'axios';
import { api } from '../../../plugins/axios';
import { CreateDeviceInfoParams, UpdateDeviceInfoParams } from './type';

export const getDeviceInfo = async () => {
  try {
    let result = await api.get(`/device-info`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const createDeviceInfo = async (createDeviceInfoParams: CreateDeviceInfoParams) => {
  try {
    let result = await api.post(`/device-info`, createDeviceInfoParams);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const editDeviceInfo = async (updateDeviceInfoParams: UpdateDeviceInfoParams) => {
  try {
    let result = await api.patch(`/device-info`, updateDeviceInfoParams);

    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};
