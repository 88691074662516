import React, { FC, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Icon } from '../../components/Icon';
import { Sidebar } from '../../components/Sidebar';

interface AccordionProps {
  title: string;
  dataType: string;
  data: { [key: string]: any }[];
}

export const Accordion: FC<AccordionProps> = ({ title, dataType, data }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => setOpen(!open)}
        className="flex justify-between border-b pb-4 cursor-pointer"
      >
        <h4>{title}</h4>
        <Icon.ChevronDown
          className={`${open ? 'rotate-180' : ''} transition-transform`}
        />
      </div>
      {open && (
        <div className={`text-14 ${open && 'pb-6'} whitespace-pre-line`}>
          {dataType == 'MENU' ? (
            <>
              {data &&
                data.map((menuItemObj: any) => (
                  <Sidebar.Menu.Item
                    key={menuItemObj?.text + menuItemObj?.to}
                    text={`${menuItemObj?.text}`}
                    to={`${menuItemObj?.to}`}
                  />
                ))}
            </>
          ) : null}
        </div>
      )}
    </>
  );
};
