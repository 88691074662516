import React from 'react';
import ChatBalloon from '../../../../assets/img/icon-chat-balloon.png';

export interface PopupType1Props {
  open: boolean;
  onClose?: () => void;
  onClick?: () => void;
  title?: string;
  subtitle?: string;
  subtitleColor?: string;
}

export const PopupType1: React.FC<PopupType1Props> = ({
  open,
  onClose,
  onClick,
  title,
  subtitle,
  subtitleColor,
}) => {
  return (
    <>
      {open ? (
        <>
          <div className="w-full max-w-md p-[5px] fixed top-[0px] z-[10000000] bg-[#FFEADC]">
            <div className="flex justify-end">
              <div
                className="w-[35px] h-[35px] flex justify-center items-center cursor-pointer"
                onClick={onClose}
              >
                X
              </div>
            </div>
            <div className="grid grid-cols-10 gap-[2px] px-[10px]">
              <div
                className="col-span-1 flex justify-center items-center cursor-pointer"
                onClick={onClick}
              >
                <div className="flex justify-center items-center max-w-[30px] max-h-[30px] rounded bg-[#FEE500] p-[7px]">
                  <img className="w-full h-full max-w-[30px] max-h-[30px]" src={ChatBalloon} />
                </div>
              </div>
              <div className="col-span-7 px-[5px]" onClick={onClick}>
                <p className="cursor-pointer">국내여행, 숙소 정보 공유</p>
                <p className="font-[700] text-[23px] cursor-pointer">카카오 단톡방 바로가기</p>
              </div>
            </div>
            <div className="h-[15px]"></div>
          </div>
        </>
      ) : null}
    </>
  );
};
