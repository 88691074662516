import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { default as htmlReactParser, attributesToProps } from 'html-react-parser';
import { getCampingBooking, refundCampingBooking } from '../../../../api_v2/admin/onda';
import { CustomDrawer } from './CustomDrawer';
import { Section } from '../../../../components/Section';
import { getCampingRefundPolicyBeforeReservationV2 } from '../../../../api_v2/non_admin/onda';
import { toast } from 'react-toastify';
import { Button } from '../../../../components/Button';

enum PaymentMethod {
  CARD = 'card',
  VBANK = 'vbank',
}

const paymentMethodObj: { [key: string]: any } = {
  card: '신용카드',
  vbank: '무통장 입금',
  point: '포인트결제',
};

const paymentStatusObj: { [key: string]: any } = {
  ready: '가상계좌 발급 완료',
  paid: '결제 성공',
  cancelled: '취소',
  fail: '결제 실패',
};

const bookingStatusObj: { [key: string]: any } = {
  waiting: '결제 대기중',
  ready: '입금 대기중',
  pending: '예약 요청중(입금완료)',
  confirmed: '확정',
  cancelled: '취소',
  canceled: '취소', // data from onda db
  failed: '실패',
};

const canceledByObj: { [key: string]: any } = {
  user: '고객',
  system: 'system',
};

export default function BookingDetail() {
  const { id } = useParams<{ id: string }>();
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const [reservationData, setReservationData] = useState<any>(null);
  useEffect(() => {
    getCampingBooking(id, '').then((res) => {
      if (res?.success && res?.data) {
        setReservationData(res?.data);
      }
    });
  }, [id]);

  const convertDate = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const [campingRefundPolicyBeforeReservation, setCampingRefundPolicyBeforeReservation] =
    useState<any>(null);

  useEffect(() => {
    if (reservationData?.ondaData?.rateplans[0]) {
      // getCampingRefundPolicyBeforeReservationV2(
      //   reservationData?.ondaData?.property_id,
      //   reservationData?.ondaData?.rateplans[0]?.roomtype_id,
      //   reservationData?.ondaData?.rateplans[0]?.rateplan_id,
      //   `?checkin=${convertDate(reservationData?.ondaData?.checkin)}&checkout=${convertDate(
      //     reservationData?.ondaData?.checkout
      //   )}`
      // ).then((res) => {
      //   if (res?.success && res?.data) {
      //     if (res?.data?.refundPolicyBeforeReservation) {
      //       setCampingRefundPolicyBeforeReservation(res?.data?.refundPolicyBeforeReservation);
      //     }
      //   }
      // });
      if (reservationData?.ondaData?.rateplans[0]?.refund_policy) {
        setCampingRefundPolicyBeforeReservation({
          refund_policy: reservationData?.ondaData?.rateplans[0]?.refund_policy,
        });
      }
    }
  }, [reservationData]);

  const modifyCampingRefundPolicyBeforeReservation = () => {
    let refundTypeObj: { [key: string]: any } = {
      nights: '연박 예약 (전체 예약 요금에 대해 체크인 날짜를 기준으로 취소환불정책을 일괄 적용)',
    };

    let refundPolicyObj;
    if (campingRefundPolicyBeforeReservation) {
      refundPolicyObj = campingRefundPolicyBeforeReservation;
    }

    if (refundPolicyObj) {
      let refundPolicy = '';
      if (refundPolicyObj?.refund_policy) {
        refundPolicy =
          refundPolicy +
          `<div style="display: grid; grid-template-columns: auto auto; border: 1px solid; padding-bottom: 3px">
           <div style=""></div><div style="font-weight: 600;">환불요금</div>
           <div style="grid-column: span 2; border-bottom: 1px solid; margin-bottom: 3px"></div>
          `;

        refundPolicyObj?.refund_policy.forEach((item: any) => {
          refundPolicy =
            refundPolicy +
            `
            <div style="margin-left: 3px">${
              item?.until ? new Date(item.until)?.toLocaleString() : ''
            }</div>
            <div style="">
              ${item?.refund_amount?.toLocaleString()} (${item?.percent}%)
            </div>
            `;
        });

        refundPolicy = refundPolicy + '</div>';
      }
      return `
      <div style="margin-top: 75px">
        <div style="margin-bottom: 5px">
          <div style="font-weight: 600; font-size: 18px">취환불 적용 타입:</div>
          <div>${refundTypeObj[refundPolicyObj?.refund_type ?? ''] ?? ''}</div>
        </div>
        <div style="margin-bottom: 5px">
          <div style="font-weight: 600; font-size: 18px">확정 취환불정책:</div>
          ${refundPolicy}
        </div>
      </div>
      `;
    } else return '';
  };

  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '80%';
    }
  };

  const [refundAmount, setRefundAmount] = useState<number>(0);

  const handleRefundAmount = (evt: any) => {
    setRefundAmount(parseInt(evt.target.value));
  };
  // Need to change name
  const [refundReason, setRefundReason] = useState('');

  const handleRefundReason = (evt: any) => {
    setRefundReason(evt.target.value);
  };

  const [handleRefundStatus, setHandleRefundStatus] = useState(false);

  const handleRefund = async (refundTypeStr: any) => {
    let refundTypeObj: { [key: string]: any } = {
      CANCEL: '예약 취소 및 환불',
      CANCEL_ONLY: '예약 취소',
      REFUND_ONLY: '환불',
    };
    if (window.confirm(`${refundTypeObj[refundTypeStr]} 하시겠습니까`)) {
      if (refundTypeStr == 'CANCEL_ONLY') {
        if (refundAmount < 0) {
          toast.error('환불 금액은 최소 0 이상입니다');
        } else if (refundReason?.trim()?.length === 0) {
          toast.error('환불/취소 사유를 입력해주세요');
        } else {
          let dataToSend: { [key: string]: any } = {
            impUid: reservationData?.paymentInfoFromIamportDB?.impUid,
            merchantUid: reservationData?.paymentInfoFromIamportDB?.merchantUid,
            reason: refundReason,
            amount: refundAmount,
          };
          setHandleRefundStatus(true);
          let refundCampingBookingRes = await refundCampingBooking(
            reservationData?.ondaData?.channel_booking_number,
            dataToSend,
            `?refund_type=${refundTypeStr?.toLowerCase()}`
          );
          setHandleRefundStatus(false);
          if (
            refundCampingBookingRes?.success == true &&
            refundCampingBookingRes?.data?.success == true
          ) {
            toast.success(`성공적으로 ${refundTypeObj[refundTypeStr]} 하였습니다`);
            window.location.href = `/admin/v2/camping/bookings/${id}`;
          } else {
            toast.error('Something went wrong: ' + refundCampingBookingRes?.message);
            window.location.href = `/admin/v2/camping/bookings/${id}`;
          }
        }
      } else {
        if (refundAmount <= 0) {
          toast.error('0보다 큰 환불 금액을 입력해주세요');
        } else if (refundReason?.trim()?.length === 0) {
          toast.error('환불/취소 사유를 입력해주세요');
        } else {
          if (typeof refundAmount === 'number') {
            let remainingRefundable = reservationData?.amount ?? 0;
            if (reservationData?.paymentInfoFromIamportDB?.cancelHistory) {
              let canceledAmount = 0;
              reservationData?.paymentInfoFromIamportDB?.cancelHistory.map((chObj: any) => {
                canceledAmount = canceledAmount + chObj?.amount ?? 0;
              });
              remainingRefundable = remainingRefundable - canceledAmount;
              if (remainingRefundable > 0 && remainingRefundable >= refundAmount) {
                let dataToSend: { [key: string]: any } = {
                  impUid: reservationData?.paymentInfoFromIamportDB?.impUid,
                  merchantUid: reservationData?.paymentInfoFromIamportDB?.merchantUid,
                  reason: refundReason,
                  amount: refundAmount,
                  checksum: remainingRefundable,
                };
                setHandleRefundStatus(true);
                let refundCampingBookingRes = await refundCampingBooking(
                  reservationData?.ondaData?.channel_booking_number,
                  dataToSend,
                  `?refund_type=${refundTypeStr?.toLowerCase()}`
                );
                setHandleRefundStatus(false);
                // .then((res: any) => {

                //   if (res?.data?.success == true) {
                //     toast.success(`성공적으로 ${refundTypeObj[refundTypeStr]} 하였습니다`);

                //   } else {
                //     toast.error('Something went wrong');
                //   }
                // })
                // .catch((e) => {
                //   console.log('e', e);
                //   toast.error('Something went wrong');
                // });

                if (
                  refundCampingBookingRes?.success == true &&
                  refundCampingBookingRes?.data?.success == true
                ) {
                  toast.success(`성공적으로 ${refundTypeObj[refundTypeStr]} 하였습니다`);
                  window.location.href = `/admin/v2/camping/bookings/${id}`;
                } else {
                  toast.error('Something went wrong: ' + refundCampingBookingRes?.message);
                  window.location.href = `/admin/v2/camping/bookings/${id}`;
                }
              } else {
                toast.error('환불 가능한 잔액이 부족합니다');
              }
            }
          }
        }
      }
    }
  };

  const calculateTotalCanceledAmt = (dataArr: any) => {
    let finalAmt = 0;
    if (dataArr) {
      dataArr.map((chObj: any) => {
        finalAmt = finalAmt + chObj?.amount ?? 0;
      });
    }
    return finalAmt;
  };

  return (
    <div>
      {reservationData ? (
        <>
          <div className="side-padding">
            <div className="">
              <div className="font-[700] text-[15px] mb-3">예약 정보</div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">이름</div>
                <div className="col-span-4">{reservationData?.ondaData?.booker?.name}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">연락처</div>
                <div className="col-span-4">{reservationData?.ondaData?.booker?.phone}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">예약 상태</div>
                <div className="col-span-4 font-[700] text-[#FF6400]">
                  {reservationData?.ondaData?.status
                    ? bookingStatusObj[reservationData?.ondaData?.status]
                    : null}
                </div>
              </div>
              {reservationData?.canceledBy && (
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">예약 취소자</div>
                  <div className="col-span-4">{canceledByObj[reservationData?.canceledBy]}</div>
                </div>
              )}
              {reservationData?.canceledReason && (
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">예약 취소사유</div>
                  <div className="col-span-4">{reservationData?.canceledReason}</div>
                </div>
              )}
            </div>
            <div className="bg-gray-100 w-full border my-3" />
            <div className="">
              <div className="font-[700] text-[15px] mb-3">예약 상품 정보</div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">예약번호</div>
                <div className="col-span-4">
                  {reservationData?.ondaData?.channel_booking_number}
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">숙소</div>
                <div className="col-span-4">{reservationData?.ondaData?.property_name}</div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">객실</div>
                <div className="col-span-4">
                  {reservationData?.ondaData?.rateplans[0] &&
                    reservationData?.ondaData?.rateplans[0]?.roomtype_name}
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">패키지</div>
                <div className="col-span-4">
                  {reservationData?.ondaData?.rateplans[0] &&
                    reservationData?.ondaData?.rateplans[0]?.rateplan_name}
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">일정</div>
                <div className="col-span-4">
                  {reservationData?.ondaData?.checkin && reservationData?.ondaData?.checkout
                    ? convertDate(reservationData?.ondaData?.checkin) +
                      ' ~ ' +
                      convertDate(reservationData?.ondaData?.checkout)
                    : null}
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">예약인원</div>
                <div className="col-span-4">
                  <div>
                    {(reservationData?.ondaData?.rateplans[0] &&
                      reservationData?.ondaData?.rateplans[0]?.number_of_guest?.adult) ??
                      0}
                    명
                  </div>
                  {/* <div>
                    미성년:{' '}
                    {(reservationData?.ondaData?.rateplans[0] &&
                      reservationData?.ondaData?.rateplans[0]?.number_of_guest?.child) ??
                      0}
                    명
                  </div> */}
                  {/* <div>청소년: {reservationData?.people_info?.minor ?? 0}명</div>
              <div>아동: {reservationData?.people_info?.child ?? 0}명</div> */}
                </div>
              </div>
              {/* <div className="grid grid-cols-5">
            <div className="text-[#5C5C5C] text-[13px] place-self-start">예약차량</div>
            <div className="col-span-4">{reservationData?.car_info?.length ?? 0}대</div>
          </div> */}
            </div>
            <div className="w-full border my-3" />
            <div className="">
              <div className="font-[700] text-[15px] mb-3">결제 정보</div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">총 결제금액</div>
                <div className="col-span-4 text-[#FF6400] font-[700]">
                  {reservationData?.paymentInfoFromIamportDB?.amount?.toLocaleString() ?? 0}원
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">결제수단</div>
                <div className="col-span-4">
                  {reservationData?.paymentInfoFromIamportDB?.payMethod
                    ? paymentMethodObj[reservationData?.paymentInfoFromIamportDB?.payMethod]
                    : null}
                </div>
              </div>
              {PaymentMethod?.VBANK === reservationData?.paymentInfoFromIamportDB?.payMethod ? (
                <>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">은행명</div>
                    <div className="col-span-4">
                      {reservationData?.paymentInfoFromIamportDB?.vbankName}
                    </div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">입금계좌번호</div>
                    <div className="col-span-4">
                      {reservationData?.paymentInfoFromIamportDB?.vbankNum}
                    </div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">입금 마감일</div>
                    <div className="col-span-4">
                      {reservationData?.paymentInfoFromIamportDB?.vbankDate
                        ? new Date(
                            reservationData?.paymentInfoFromIamportDB?.vbankDate * 1000
                          )?.toLocaleString()
                        : null}
                    </div>
                  </div>
                </>
              ) : PaymentMethod?.CARD === reservationData?.paymentInfoFromIamportDB?.payMethod ? (
                <></>
              ) : null}
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">결제 상태</div>
                <div className="col-span-4 font-[700] text-[#FF6400]">
                  {reservationData?.paymentInfoFromIamportDB?.status === 'cancelled'
                    ? reservationData?.paymentInfoFromIamportDB?.cancelHistory &&
                      (calculateTotalCanceledAmt(
                        reservationData?.paymentInfoFromIamportDB?.cancelHistory
                      ) ?? 0) > 0
                      ? '환불'
                      : paymentStatusObj[reservationData?.paymentInfoFromIamportDB?.status]
                    : reservationData?.paymentInfoFromIamportDB?.status
                    ? paymentStatusObj[reservationData?.paymentInfoFromIamportDB?.status]
                    : null}
                </div>
              </div>
            </div>
            {/* reservationData?.paymentInfoFromIamportDB?.cancelHistory &&
                    (
                      calculateTotalCanceledAmt(
                        reservationData?.paymentInfoFromIamportDB?.cancelHistory
                      ) ?? 0
                    ).toLocaleString() */}
            <div className="w-full border my-3" />
            <div className="">
              <div className="font-[700] text-[15px] mb-3">환불</div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">
                  <button
                    onClick={() => openDrawer()}
                    className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                border border-[#D0D0D0]
                flex-1 flex justify-center items-center p-[5px]"
                  >
                    예약 환불 규정
                  </button>
                </div>
                <div className="col-span-4 text-[#FF6400] font-[700]"></div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">총 환불된 금액</div>
                <div className="col-span-2 flex flex-col">
                  {reservationData?.paymentInfoFromIamportDB?.cancelHistory &&
                    (
                      calculateTotalCanceledAmt(
                        reservationData?.paymentInfoFromIamportDB?.cancelHistory
                      ) ?? 0
                    ).toLocaleString()}
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">환불 기록</div>
                <div className="col-span-2 flex flex-col  ">
                  {reservationData?.paymentInfoFromIamportDB?.cancelHistory?.length > 0 ? (
                    reservationData?.paymentInfoFromIamportDB?.cancelHistory.map((chObj: any) => (
                      <div
                        className="flex flex-col border pl-[5px] py-[5px] mb-[2px]"
                        key={chObj?.cancelled_at}
                      >
                        <div>
                          시각:
                          {chObj?.cancelled_at
                            ? new Date(chObj?.cancelled_at * 1000)?.toLocaleString()
                            : null}
                        </div>
                        <div>사유: {chObj?.reason}</div>
                        <div>금액: {chObj?.amount?.toLocaleString()}</div>
                        <div>
                          취소에 대한 매출전표 확인 URL:{' '}
                          <a target="_blank" rel="noreferrer" href={`${chObj?.receipt_url ?? ''}`}>
                            확인하기
                          </a>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>없음</>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">환불 금액</div>
                <div className="col-span-4 font-[700]">
                  <input
                    type="number"
                    className={`my-2 rounded bg-[#FFFFFF] text-[#000000]  
                      border border-[#D0D0D0]
                       flex justify-center items-center`}
                    min={1}
                    name="refundAmount"
                    value={refundAmount}
                    onChange={(e) => handleRefundAmount(e)}
                    onInput={(e: any) => {
                      let rounded = Math.round(e.target.value);
                      if (rounded < 0) {
                        rounded = 0;
                      }
                      e.target.value = rounded;
                    }}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="grid grid-cols-5">
                <div className="text-[#5C5C5C] text-[13px] place-self-start">사유</div>
                <div className="col-span-4 font-[700]">
                  <textarea
                    className={`my-2 rounded bg-[#FFFFFF] text-[#000000]  
                      border border-[#D0D0D0]
                      flex-1 flex justify-center items-center h-full`}
                    value={refundReason}
                    onChange={(e) => handleRefundReason(e)}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="grid grid-cols-5 my-2">
                <div className="text-[#5C5C5C] text-[13px] place-self-start"></div>
                <div className="col-span-4 flex font-[700]">
                  <Button
                    onClick={() => handleRefund('CANCEL')}
                    className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                border border-[#D0D0D0]
                 flex justify-center items-center mr-[5px] p-[5px]"
                    disabled={handleRefundStatus}
                  >
                    {handleRefundStatus ? '...' : '예약 취소 및 환불'}
                  </Button>
                  <Button
                    onClick={() => handleRefund('CANCEL_ONLY')}
                    className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                border border-[#D0D0D0]
                 flex justify-center items-center mr-[5px] p-[5px]"
                    disabled={handleRefundStatus}
                  >
                    {handleRefundStatus ? '...' : '예약 취소 ONLY'}
                  </Button>
                  <Button
                    onClick={() => handleRefund('REFUND_ONLY')}
                    className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                border border-[#D0D0D0]
                  flex justify-center items-center p-[5px]"
                    disabled={handleRefundStatus}
                  >
                    {handleRefundStatus ? '...' : '환불 ONLY'}
                  </Button>
                </div>
              </div>
              <p className="text-[red]">
                *예약 취소 ONLY 버튼 클릭전 0이상의 환불된 금액을 입력해주세요
              </p>
            </div>

            {/* <div className="bg-gray-100 w-full border my-3" />
            <div className="mb-4 ">
              <div className={`bg-white z-20 w-full flex space-x-2 px-4 h-16`}>
                <button
                  onClick={() => openDrawer()}
                  className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                border border-[#D0D0D0]
                flex-1 flex justify-center items-center"
                >
                  예약 환불 규정
                </button>
              </div>
            </div>
            <div className="grid grid-cols-5">
              <div className="text-[#5C5C5C] text-[13px] place-self-start">환불 금액:</div>
              <div className="col-span-4 font-[700] text-[#FF6400]">
                <input
                  type="number"
                  className={`my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                  border border-[#D0D0D0]
                   flex justify-center items-center h-full w-[100px]`}
                  min={1}
                  name="refundAmount"
                  value={refundAmount}
                  onChange={(e) => handleRefundAmount(e)}
                  onInput={(e: any) => {
                    let rounded = Math.round(e.target.value);
                    if (rounded < 0) {
                      rounded = 0;
                    }
                    e.target.value = rounded;
                  }}
                  placeholder=""
                />
              </div>
            </div>
            <div className="grid grid-cols-5">
              <div className="text-[#5C5C5C] text-[13px] place-self-start">사유: </div>
              <div className="col-span-4 font-[700] text-[#FF6400]">
                <input
                  className={`my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                  border border-[#D0D0D0]
                  flex-1 flex justify-center items-center h-full`}
                  placeholder=""
                />
              </div>
            </div>

            <div className={`w-full flex items-center space-x-2 h-12`}>
              <Button
                onClick={() => handleRefund('CANCEL')}
                className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                border border-[#D0D0D0]
                flex-1 flex justify-center items-center h-full"
              >
                예약 취소
              </Button>
              <Button
                onClick={() => handleRefund('REFUND_ONLY')}
                className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                border border-[#D0D0D0]
                flex-1 flex justify-center items-center h-full"
              >
                환불 ONLY
              </Button>
            </div> */}
          </div>
        </>
      ) : null}

      <div>
        <CustomDrawer drawerRef={drawerRef} confirmButton={false}>
          <div className="mt-[75px] side-padding">
            {campingRefundPolicyBeforeReservation &&
              htmlReactParser(modifyCampingRefundPolicyBeforeReservation() ?? '')}
          </div>
        </CustomDrawer>
      </div>
    </div>
  );
}
