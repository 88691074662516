import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { map, trim, uniq } from 'lodash';
import { parse, stringify } from 'qs';
import { addDays, previousMonday } from 'date-fns';
import dayjs from 'dayjs';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { v4 as uuidv4 } from 'uuid';

import { Calendar } from './Calendar';
import { TopTitleBar } from './components/TopTitleBar';
import { Section } from '../../../../../components/Section';
import { Icon } from '../../../../../components/Icon';

import { CustomDrawer } from './CustomDrawer';

import { ReactComponent as IconArrowDown } from '../../../../../assets/icons/icon-arrow-down.svg';
import { ReactComponent as IconCalendar } from '../../../../../assets/icons/icon-calendar.svg';
import { ReactComponent as IconAngleDoubleTop } from '../../../../../assets/icons/icon-angle-double-top.svg';

import { campingTypes, regions, regions2 } from './constants';
import { Button } from '../../../../../components/Button';
import {
  getCampingInfoListV2,
  getPropertiesLowestPrices,
} from '../../../../../api_v2/non_admin/onda';
import { useInfiniteQuery } from 'react-query';

import './style.css';
import { CardType1 } from './components/CardType1';
import { AxiosError, AxiosResponse } from 'axios';
import { Paginated } from '../../../../../types';
import { api } from '../../../../../plugins/axios';
import { toast } from 'react-toastify';

const RepeatedCard = (dataObj: any, lastElementRef: any, handleCampingInfoSwiperSlide: any) => {
  const parseStr = (strData: any) => {
    try {
      let parsed = JSON.parse(strData);
      return parsed;
    } catch (e) {
      console.log('e', e);
      return null;
    }
  };

  const getTags = (dataObj: any) => {
    try {
      let tempArr: any[] = [];
      for (let i = 0; i < Object.keys(dataObj).length; i++) {
        let key = Object.keys(dataObj)[i];
        tempArr = [...tempArr, ...dataObj[key]];
      }
      return tempArr;
    } catch (e) {
      console.log('e', e);
      return [];
    }
  };

  const getClassifications = (classArr: any) => {
    let finalStr = '';
    for (let i = 0; i < classArr.length; i++) {
      if (i == 0) {
        finalStr = classArr[i];
      } else {
        finalStr = finalStr + ' | ' + classArr[i];
      }
    }
    return finalStr;
  };

  return (
    <div
      ref={lastElementRef ?? null}
      className="border border-[#D9D9D9] mb-5"
      style={{ borderRadius: '5px 5px 5px 5px' }}
      /* top-left | top-right | bottom-right | bottom-left */
    >
      <div
        key={dataObj?.id}
        onClick={() => handleCampingInfoSwiperSlide(dataObj)}
        className="cursor-pointer relative"
      >
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          // autoplay={{
          //   delay: 3000,
          // }}
          loop={false}
          className="mySwiper"
          style={{ borderRadius: '5px 5px 0px 0px' }}
          onPaginationRender={(swiper, paginationEl) => {
            if (paginationEl) {
              paginationEl.style.position = 'absolute';
              paginationEl.style.bottom = '0';
              (paginationEl as any).style['padding-bottom'] = '35px';
            }
          }}
        >
          {dataObj?.images &&
            dataObj.images.map((imgObj: any, index2: any) => (
              <div key={imgObj?.original}>
                <SwiperSlide className="h-52 w-auto">
                  <img className="w-full h-full" src={imgObj?.original} />
                </SwiperSlide>
              </div>
            ))}
        </Swiper>

        <div className="side-padding mb-3">
          <div className="flex justify-between">
            <div className="text-[#6F6F6F] text-[12px]">
              {/* {dataObj?.address && parseStr(dataObj.address)?.region} */}
              <div>{dataObj?.classifications && getClassifications(dataObj?.classifications)}</div>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="font-[700] text-[15px]">
              {dataObj?.name
                ? dataObj?.name.length <= 11
                  ? dataObj?.name
                  : dataObj?.name?.substring(0, 11) + '...'
                : null}
            </div>
            <div className="font-[700] text-[15px] text-[#FF6400]">
              {dataObj?.lowerPrice >= 0 ? dataObj?.lowerPrice?.toLocaleString() + '원~' : <></>}
            </div>
          </div>
          <div className="text-[#6F6F6F] text-[12px]">{dataObj?.address?.address1}</div>
          <div className="mt-2">
            {
              <div className="flex flex-wrap">
                {dataObj?.tags &&
                  Array.isArray(getTags(dataObj.tags)) &&
                  getTags(dataObj?.tags)?.map((tag: any) => (
                    <div
                      className="flex justify-center items-center 
                  border border-[#D0D0D0] rounded p-1 text-[10px] w-fit mr-1 mb-1"
                    >
                      {tag}
                    </div>
                  ))}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

enum DropdownType {
  REGION = 'region',
  CAMPING_TYPE = 'camping_type',
}

export const SearchResultPage = () => {
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const { search } = useLocation();

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [campingSearchInput, setCampingSearchInput] = useState(parsedSearch?.search_query);

  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [calendarState, setCalendarState] = useState<any>([
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: new Date(parsedSearch?.start_date as string),
          endDate: new Date(parsedSearch?.end_date as string),
          key: 'selection',
        }
      : {
          startDate: null,
          endDate: new Date(''),
          key: 'selection',
        },
  ]);

  const [locale, setLocale] = useState<any>('ko');

  const [selectedDropdownCampingType, setSelectedDropdownCampingType] = useState<string>(
    parsedSearch?.camping_type as string
  );

  const [selectedDropdownRegionOption, setSelectedDropdownRegionOption] = useState<string>(
    parsedSearch?.region as string
  );

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const [queryString, setQueryString] = useState('');
  const [queryStringObj, setQueryStringObj] = useState<{ [key: string]: any } | null>(null);
  const [campingInfoListTotal, setCampingInfoListTotal] = useState(0);

  const observer = useRef<IntersectionObserver>();

  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const [campingInfoList, setCampingInfoList] = useState<{ [key: string]: any } | null>({});
  const [page, setPage] = useState(1);
  const [campingInfoListLoading, setCampingInfoListLoading] = useState(false);

  const handleDropdown = (e: any, dropdownType: string) => {
    if (dropdownType == DropdownType.CAMPING_TYPE) {
      setSelectedDropdownCampingType(e.value);
    } else if (dropdownType == DropdownType.REGION) {
      setSelectedDropdownRegionOption(e.value);
    }
  };

  const handleCampingSearchInput = (e: any) => {
    setCampingSearchInput(e.target.value);
  };

  const convertDateForQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const lastAutoGenId = useRef(null);
  let [pageLimit, setPageLimit] = useState(10);

  const fetchProperties = async ({ pageParam = 1 }) => {
    let currentQueryStringObj: { [key: string]: any } = {
      page: pageParam,
      limit: pageLimit,
      filter: { status: true, isVisible: true },
      sort: { id: 'DESC' },
    };

    for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
      let key = Object.keys(parsedSearch)[i];
      if (key === 'camping_type') {
        currentQueryStringObj.filter['classifications'] = parsedSearch[key];
      } else if (key === 'province') {
        currentQueryStringObj.filter['province'] = parsedSearch[key];
      } else if (key === 'city') {
        currentQueryStringObj.filter['city'] = parsedSearch[key];
      } else if (key === 'search_query') {
        currentQueryStringObj.filter['word'] = parsedSearch[key];
      }
    }

    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      currentQueryStringObj.filter['checkin'] = convertDateForQS(parsedSearch?.start_date);
      currentQueryStringObj.filter['checkout'] = convertDateForQS(parsedSearch?.end_date);
    }

    if (pageParam - 2 < 0) {
      // pageParam == 1
    } else {
      // if (lastAutoGenId.current) {
      //   let prevInd = pageParam - 2;
      //   let lastPage = searchResultData?.pages[prevInd];
      //   let lastItem = lastPage?.data?.items[lastPage?.data?.items?.length - 1];

      //   currentQueryStringObj.lastAutoGenId = lastItem?.auto_generated_id;
      // }

      // pageParam >= 2
      let prevInd = pageParam - 2;
      if ((searchResultData as any)?.pages[prevInd]?.data?.items?.length >= 0) {
        let lastPage = searchResultData?.pages[prevInd];
        let lastItem = lastPage?.data?.items[lastPage?.data?.items?.length - 1];

        currentQueryStringObj.lastAutoGenId = lastItem?.auto_generated_id;
      } else return;
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
      arrayFormat: 'brackets',
    });

    if (pageParam == 1) {
      setInitialLoading(true);
      loadingDots(true, 'initial-loading-container');
    } else {
      loadingDots(true, 'more-loading-container');
    }

    let result = await api.get(`/properties${currentQueryString}`);

    if (pageParam == 1) {
      setInitialLoading(false);
      loadingDots(false, 'initial-loading-container');
    } else {
      loadingDots(false, 'more-loading-container');
    }

    if ([200].includes(result?.status)) {
      if (result?.data?.items && parsedSearch?.start_date && parsedSearch?.end_date) {
        let propIds = result?.data?.items?.map((item: any) => {
          return item?.id ?? '';
        });

        let getPropertiesLowestPricesObj: { [key: string]: any } = {
          propertyId: propIds,
        };

        if (parsedSearch?.start_date && parsedSearch?.end_date) {
          getPropertiesLowestPricesObj.checkin = convertDateForQS(parsedSearch?.start_date);
          getPropertiesLowestPricesObj.checkout = convertDateForQS(parsedSearch?.end_date);
        }

        const getPropertiesLowestPricesQueryString = stringify(getPropertiesLowestPricesObj, {
          addQueryPrefix: true,
          encode: false,
          arrayFormat: 'brackets',
        });

        if (getPropertiesLowestPricesQueryString) {
          return {
            ...result,
            data: {
              ...result?.data,
              lowestPrices: getPropertiesLowestPrices(getPropertiesLowestPricesQueryString ?? ''),
            },
          };
        }
      } else return result;
    } else return null;
  };

  const {
    data: searchResultData,
    fetchNextPage,
    isLoading: isSearchResultDataLoading,
  } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`ondaBookingSearchResultPage`],
    fetchProperties as any,
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        if (lastPage?.data?.items?.length > 0) {
          return nextPage;
        } else if (lastPage?.data?.items?.length == 0) {
          return undefined;
        } else return allPages?.length;
      },
      keepPreviousData: true,
      staleTime: 3600000,
      cacheTime: 3600000,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (searchResultData) {
      if (searchResultData?.pages && searchResultData?.pages?.length > 0) {
        let lastPage = searchResultData?.pages[searchResultData?.pages?.length - 1];
        if (lastPage?.data?.items?.length > 0) {
          let lastItem = lastPage?.data?.items[lastPage?.data?.items?.length - 1];
          lastAutoGenId.current = lastItem?.auto_generated_id;
        }
      }
    }
  }, [searchResultData]);

  const lastElement = useCallback(
    (target) => {
      if (isSearchResultDataLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && fetchNextPage) {
            // loadingDots(true, 'more-loading-container');
            // loadingDots(false, 'more-loading-container');

            fetchNextPage();
          } else {
          }
        },
        {
          rootMargin: '1000px',
          threshold: 0.1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [isSearchResultDataLoading]
  );

  const handleCampingInfoSwiperSlide = (campingInfoObj: any) => {
    history.push(`/v2/camping/campinginfo/${campingInfoObj.id}`);
  };

  const openDrawer = (heightData?: any) => {
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `none`;

      (drawerRef.current as any).style['pointer-events'] = 'auto';

      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = heightData ?? '80%';
    }
  };

  const [handleSearchQS, setHandleSearchQS] = useState<any | null>('');

  const handleSearch = (queryStrObj: { [key: string]: any }) => {
    const queryString = stringify(queryStrObj, {
      addQueryPrefix: true,
      encode: false,
      arrayFormat: 'brackets',
    });
    if (queryString == handleSearchQS) {
    } else {
      // setInitialLoading(true);
      // setPage(1);
      // setCampingInfoList({});
    }
    setHandleSearchQS(queryString);
    // history.push('/v2/camping/search/result' + queryString);
    window.location.href = '/v2/camping/search/result' + queryString;
  };

  // prob2
  const handleCampingSearchButton = (evt: any) => {
    const tempQueryObj: { [key: string]: any } = {
      // search_query: (campingSearchInput as string)?.trim() ?? '',
      // camping_type: selectedDropdownCampingType ?? '',
      // filter: { status: true, isVisible: true },
    };

    if ((campingSearchInput as string)?.trim()?.length > 0) {
      tempQueryObj['search_query'] = (campingSearchInput as string)?.trim() ?? '';
    }

    if (selectedDropdownCampingType) {
      tempQueryObj['camping_type'] = selectedDropdownCampingType ?? '';
    }

    // if (selectedDropdownRegionOption) {
    //   tempQueryObj.region = selectedDropdownRegionOption;
    // }

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'province') {
          tempQueryObj['province'] = parsedSearch[key];
        } else if (key === 'city') {
          tempQueryObj['city'] = parsedSearch[key];
        }
      }
    }

    if (chosenDates?.startDate && chosenDates?.endDate) {
      tempQueryObj.start_date = chosenDates?.startDate;
      tempQueryObj.end_date = chosenDates?.endDate;
    }

    handleSearch(tempQueryObj);
  };

  // prob3
  const handleSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      const tempQueryObj: { [key: string]: any } = {
        // search_query: (campingSearchInput as string)?.trim() ?? '',
        // camping_type: selectedDropdownCampingType ?? '',
        // filter: { status: true, isVisible: true },
      };
      if ((campingSearchInput as string)?.trim()?.length > 0) {
        tempQueryObj['search_query'] = (campingSearchInput as string)?.trim() ?? '';
      }

      if (selectedDropdownCampingType) {
        tempQueryObj['camping_type'] = selectedDropdownCampingType ?? '';
      }

      // if (selectedDropdownRegionOption) {
      //   tempQueryObj.region = selectedDropdownRegionOption;
      // }

      if (parsedSearch) {
        for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
          let key = Object.keys(parsedSearch)[i];
          if (key === 'province') {
            tempQueryObj['province'] = parsedSearch[key];
          } else if (key === 'city') {
            tempQueryObj['city'] = parsedSearch[key];
          }
        }
      }

      if (chosenDates?.startDate && chosenDates?.endDate) {
        tempQueryObj.start_date = chosenDates?.startDate;
        tempQueryObj.end_date = chosenDates?.endDate;
      }

      handleSearch(tempQueryObj);
    }
  };

  enum TabType {
    CAMPING_TYPE = 'CAMPING_TYPE',
    CALENDAR = 'CALENDAR',
    REGION = 'REGION',
  }

  const TabTypeObj: { [key: string]: any } = {
    CAMPING_TYPE: '전체',
    CALENDAR: '일정',
    REGION: '전지역',
  };

  const tabs = [TabType?.CALENDAR, TabType?.CAMPING_TYPE, TabType?.REGION];

  const [chosenTab, setChosenTab] = useState<any | null>(null);
  const [chosenRegion, setChosenRegion] = useState<any | null>(parsedSearch['region'] ?? null);
  const [chosenCampingType, setChosenCampingType] = useState<any | null>(
    parsedSearch['camping_type'] ?? null
  );

  const handleChosenTab = (tabData: any) => {
    setChosenTab(tabData);

    openDrawer(
      tabData == TabType?.CAMPING_TYPE ? '45%' : tabData == TabType?.REGION ? '100%' : null
    );
  };

  const closeDrawer = () => {
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `auto`;

      drawerRef.current.style.width = '0';
      drawerRef.current.style.height = '0';
    }
  };

  const handleRegion = (regionData: any) => {
    setChosenRegion(regionData);
    closeDrawer();
    let tempQueryStringObj: { [key: string]: any } = {};

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'search_query') {
          tempQueryStringObj['search_query'] = parsedSearch[key];
        } else if (key === 'camping_type') {
          tempQueryStringObj['camping_type'] = parsedSearch[key];
        }
      }
    }

    if (regionData) {
      tempQueryStringObj['region'] = regionData;
    }

    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      tempQueryStringObj['start_date'] = parsedSearch?.start_date;
      tempQueryStringObj['end_date'] = parsedSearch?.end_date;
    }

    handleSearch(tempQueryStringObj);
  };

  const handleCampingType = (campingTypeData: any) => {
    setChosenCampingType(campingTypeData);
    closeDrawer();
    let tempQueryStringObj: { [key: string]: any } = {};

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'search_query') {
          tempQueryStringObj['search_query'] = parsedSearch[key];
        } else if (key === 'province') {
          tempQueryStringObj['province'] = parsedSearch[key];
        } else if (key === 'city') {
          tempQueryStringObj['city'] = parsedSearch[key];
        }
      }
    }

    if (campingTypeData) {
      tempQueryStringObj['camping_type'] = campingTypeData;
    }

    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      tempQueryStringObj['start_date'] = parsedSearch?.start_date;
      tempQueryStringObj['end_date'] = parsedSearch?.end_date;
    }

    handleSearch(tempQueryStringObj);
  };

  const handleDateFunc = (startDateData: any, endDateData: any) => {
    let tempQueryStringObj: { [key: string]: any } = {};

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'search_query') {
          tempQueryStringObj['search_query'] = parsedSearch[key];
        } else if (key === 'camping_type') {
          tempQueryStringObj['camping_type'] = parsedSearch[key];
        } else if (key === 'province') {
          tempQueryStringObj['province'] = parsedSearch[key];
        } else if (key === 'city') {
          tempQueryStringObj['city'] = parsedSearch[key];
        }
      }
    }

    if (startDateData && endDateData) {
      tempQueryStringObj['start_date'] = startDateData;
      tempQueryStringObj['end_date'] = endDateData;
    }

    handleSearch(tempQueryStringObj);
  };

  const handleRefreshButton = () => {
    closeDrawer();
    let tempQueryStringObj: { [key: string]: any } = {};

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'search_query') {
          tempQueryStringObj['search_query'] = parsedSearch[key];
        } else if (key === 'camping_type') {
          tempQueryStringObj['camping_type'] = parsedSearch[key];
        } else if (key === 'province') {
          tempQueryStringObj['province'] = parsedSearch[key];
        } else if (key === 'city') {
          tempQueryStringObj['city'] = parsedSearch[key];
        }
      }
    }

    // if (startDateData && endDateData) {
    //   tempQueryStringObj['start_date'] = startDateData;
    //   tempQueryStringObj['end_date'] = endDateData;
    // }

    setCalendarState([
      {
        startDate: null,
        endDate: new Date(''),
        key: 'selection',
      },
    ]);

    setChosenDates({ startDate: null, endDate: null });

    handleSearch(tempQueryStringObj);
  };

  const filterWrapRef = useRef<any | null>(null);
  const filterRef = useRef<any | null>(null);

  useEffect(() => {
    const handleScrollEvent = () => {
      if (filterRef?.current?.getBoundingClientRect()?.top <= 0) {
        filterRef.current.style.position = 'fixed';
        filterRef.current.style.top = 0;
      } else {
        // filterWrapRef.current.style.position = '';
        // filterWrapRef.current.style.top = '';
      }
      if (document.documentElement.scrollTop == 0) {
        filterRef.current.style.position = '';
        filterRef.current.style.top = '';
      }
    };
    window.addEventListener('scroll', handleScrollEvent);
    return () => window.removeEventListener('scroll', handleScrollEvent);
  }, []);

  const loadingDots = (statusBool: boolean, className: string) => {
    let loadingContEle = document.querySelector('.' + className);
    if (loadingContEle) {
      if (statusBool) {
        (loadingContEle as any).style.display = 'flex';
      } else {
        (loadingContEle as any).style.display = 'none';
      }
    }
  };

  const [listCitiesStatus, setListCitiesStatus] = useState<any>(false);
  const [drawerNextRegionCityObj, setDrawerNextRegionCityObj] = useState<any>(null);

  const listCities = (province: any) => {
    setListCitiesStatus(true);
    let modifiedCities: { [key: string]: any } = {};
    (regions2 as any)[province].map((city: any) => {
      modifiedCities[city] = { selected: false };
    });
    let tempData = { province: province, cities: modifiedCities };

    setDrawerNextRegionCityObj(tempData);
  };

  const [selectAllForCitiesStatus, setSelectAllForCitiesStatus] = useState(false);

  const handleSelectedCities = (city: string) => {
    let modifiedCities: { [key: string]: any } = {};
    if (city == '전체') {
      Object.keys(drawerNextRegionCityObj.cities).map((city) => {
        modifiedCities[city] = { selected: !selectAllForCitiesStatus };
      });
      setSelectAllForCitiesStatus(!selectAllForCitiesStatus);
    } else {
      modifiedCities = {
        ...drawerNextRegionCityObj.cities,
        [city]: { selected: !drawerNextRegionCityObj.cities[city].selected },
      };
    }
    setDrawerNextRegionCityObj((prev: any) => {
      return {
        ...prev,
        cities: modifiedCities,
      };
    });
  };

  const defaultSelectedCities = () => {
    let modifiedCities: { [key: string]: any } = {};
    Object.keys(drawerNextRegionCityObj.cities).map((city) => {
      modifiedCities[city] = { selected: false };
    });
    setDrawerNextRegionCityObj((prev: any) => {
      return {
        ...prev,
        cities: modifiedCities,
      };
    });
  };

  const finishSelectedCities = () => {
    let selectedCityExist = false;
    for (let i = 0; i < Object.keys(drawerNextRegionCityObj.cities).length; i++) {
      let city = Object.keys(drawerNextRegionCityObj.cities)[i];
      if (drawerNextRegionCityObj.cities[city].selected == true) {
        selectedCityExist = true;
        break;
      }
    }
    if (selectedCityExist) {
      // setChosenRegion(regionData);
      closeDrawer();
      let tempQueryStringObj: { [key: string]: any } = {};

      if (parsedSearch) {
        for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
          let key = Object.keys(parsedSearch)[i];
          if (key === 'search_query') {
            tempQueryStringObj['search_query'] = parsedSearch[key];
          } else if (key === 'camping_type') {
            tempQueryStringObj['camping_type'] = parsedSearch[key];
          }
        }
      }

      tempQueryStringObj['province'] = drawerNextRegionCityObj.province;
      let selectedCities: string[] = [];
      Object.keys(drawerNextRegionCityObj.cities).forEach((city) => {
        if (drawerNextRegionCityObj.cities[city].selected == true) {
          selectedCities.push(city);
        }
      });
      tempQueryStringObj['city'] = selectedCities;

      if (parsedSearch?.start_date && parsedSearch?.end_date) {
        tempQueryStringObj['start_date'] = parsedSearch?.start_date;
        tempQueryStringObj['end_date'] = parsedSearch?.end_date;
      }

      handleSearch(tempQueryStringObj);
    } else {
      toast.error('지역을 선택해주세요');
    }
  };

  const selectAllProvinces = () => {
    closeDrawer();
    let tempQueryStringObj: { [key: string]: any } = {};

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'search_query') {
          tempQueryStringObj['search_query'] = parsedSearch[key];
        } else if (key === 'camping_type') {
          tempQueryStringObj['camping_type'] = parsedSearch[key];
        }
      }
    }

    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      tempQueryStringObj['start_date'] = parsedSearch?.start_date;
      tempQueryStringObj['end_date'] = parsedSearch?.end_date;
    }

    handleSearch(tempQueryStringObj);
  };

  const [lowestPricesObj, setLowestPricesObj] = useState<{ [key: string]: any }>({});

  const handleLowestPricesObj = async (page: any, pageInd: any) => {
    setLowestPricesObj((prev) => {
      return { ...prev, [pageInd]: page?.data?.lowestPrices };
    });
  };

  return (
    <>
      <TopTitleBar logo={true} home={false} />
      {/* <div className="max-w-md w-screen bg-white side-padding"> */}
      <div className="w-screen sm:w-full md:w-full lg:w-full side-padding bg-white">
        {/* <div ref={tabsRef} className={`fixed bg-[white] top-[132px] w-full max-w-md`}> */}
        <div ref={filterWrapRef} className="absolute right-0 w-full max-w-md bg-[white] z-[2]">
          <div
            ref={filterRef}
            className="flex flex-col justify-center w-full max-w-md side-padding bg-[white]"
          >
            {parsedSearch && (
              <>
                <div
                  className="flex flex-row-reverse w-full items-center px-4 h-12 mt-2
                border border-[#FF6400] rounded bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
                >
                  <div className="flex justify-center items-center">{/* <Icon.Search /> */}</div>
                  <Button
                    className="bg-[#FF6400] text-[#FFFFFF] rounded-[12px]"
                    style={{ height: '80%' }}
                    onClick={(e) => handleCampingSearchButton(e)}
                  >
                    검색
                  </Button>
                  {campingSearchInput ? (
                    <>
                      <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                        <Icon.X
                          className="wh-3"
                          onClick={() => {
                            setCampingSearchInput('');
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                  <input
                    className="flex-1 text-sm bg-white"
                    placeholder="검색하기"
                    onChange={(e) => handleCampingSearchInput(e)}
                    value={campingSearchInput as string}
                    onKeyDown={(e) => handleSearchKeyDown(e)}
                  />
                </div>

                <div className="w-full" style={{ width: '100%' }}>
                  <Swiper
                    className={`mySwiper w-full pt-4 z-[1]`}
                    slidesPerView="auto"
                    spaceBetween={6}
                    slidesOffsetBefore={0}
                    slidesOffsetAfter={0}
                    freeMode={true}
                  >
                    {tabs.map((item) => (
                      <SwiperSlide className="w-auto">
                        <Button
                          className="border-2 rounded-[12px] bg-[white]"
                          onClick={(e) => {
                            handleChosenTab(item);
                          }}
                          key={item}
                          // className={`${
                          //   item === chosenTab ? 'border-b-4 border-black text-black-50' : 'bg-white'
                          // } w-max font-semibold text-13 px-3 h-10`}
                        >
                          {item === TabType?.REGION ? (
                            parsedSearch['city'] ? (
                              <div className="flex items-center w-fit">
                                <div className="mr-2">
                                  {Array.isArray(parsedSearch['city'])
                                    ? parsedSearch['city'].length >= 3
                                      ? parsedSearch['city'].slice(0, 2).join(', ') + '...'
                                      : parsedSearch['city'].join(', ')
                                    : ''}
                                </div>
                                <IconArrowDown className="w-[10px]" />
                              </div>
                            ) : (
                              <div className="flex items-center w-fit">
                                <div className="mr-2">{TabTypeObj[item]}</div>
                                <IconArrowDown className="w-[10px]" />
                              </div>
                            )
                          ) : item === TabType?.CAMPING_TYPE ? (
                            chosenCampingType ? (
                              <div className="flex items-center w-fit">
                                <div className="mr-2">
                                  {chosenCampingType == '캠핑' ? '캠핑장' : chosenCampingType}
                                </div>
                                <IconArrowDown className="w-[10px]" />
                              </div>
                            ) : (
                              <div className="flex items-center w-fit">
                                <div className="mr-2">{TabTypeObj[item]}</div>
                                <IconArrowDown className="w-[10px]" />
                              </div>
                            )
                          ) : item === TabType?.CALENDAR ? (
                            <div className="flex items-center w-fit">
                              <div className="mr-2">
                                {chosenDates?.startDate && chosenDates?.endDate ? (
                                  <>
                                    {dayjs(chosenDates?.startDate).format('MM-DD') +
                                      ' ~ ' +
                                      dayjs(chosenDates?.endDate).format('MM-DD')}
                                  </>
                                ) : (
                                  TabTypeObj[item]
                                )}
                              </div>
                              <IconCalendar />
                            </div>
                          ) : (
                            TabTypeObj[item]
                          )}
                        </Button>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </>
            )}
          </div>
        </div>

        <Section className="mt-[170px] bg-[white]">
          {initialLoading == true && (
            <div className="initial-loading-container flex justify-center mb-[20px] hidden">
              <div className="loading-dots-type1"></div>
            </div>
          )}

          {!((searchResultData as any)?.pages[0]?.data?.items?.length > 0) &&
          !isSearchResultDataLoading ? (
            <>
              <p className="grid place-content-center h-44 gap-[5px]">검색결과가 없습니다</p>
            </>
          ) : searchResultData?.pages && searchResultData?.pages?.length > 0 ? (
            <>
              {map(searchResultData?.pages ?? [], (page, pageInd) => {
                if (!lowestPricesObj.hasOwnProperty(pageInd)) {
                  handleLowestPricesObj(page, pageInd);
                }
                return map(page?.data?.items ?? [], (propertyObj, propertyObjInd: number) => {
                  return (
                    <>
                      <div
                        key={propertyObj?.id}
                        ref={
                          searchResultData?.pages?.length - 1 == pageInd &&
                          page?.data?.items?.length - 1 == propertyObjInd
                            ? lastElement
                            : null
                        }
                      >
                        <CardType1
                          lowestPricesObj={lowestPricesObj}
                          pageInd={pageInd}
                          item={propertyObj}
                          handleCampingInfoSwiperSlide={handleCampingInfoSwiperSlide}
                        />
                      </div>
                    </>
                  );
                });
              })}
              {/* <div ref={lastElement}></div> */}
            </>
          ) : null}

          <div className="more-loading-container flex justify-center mb-[20px] hidden">
            <div className="loading-dots-type1"></div>
          </div>
        </Section>
      </div>
      <div>
        <CustomDrawer
          drawerRef={drawerRef}
          refreshButton={chosenTab === TabType?.CALENDAR ? true : false}
          handleRefreshButton={handleRefreshButton}
          contentMt={55}
        >
          {chosenTab === TabType?.CALENDAR ? (
            <div className="mt-[50px]">
              <Calendar
                locale={locale}
                state={calendarState}
                setState={setCalendarState}
                setChosenDates={setChosenDates}
                drawerRef={drawerRef}
                confirmButton={chosenTab === TabType?.CALENDAR ? true : false}
                handleDate={true}
                handleDateFunc={handleDateFunc}
              />
            </div>
          ) : chosenTab === TabType?.CAMPING_TYPE ? (
            <>
              <div className="h-full w-full grid grid-cols-1 place-items-center bg-white mt-[50px]">
                {['', '펜션', '카라반', '글램핑', '캠핑'].map((campingType) => (
                  <div
                    className="h-full w-full flex justify-center p-4 border-b-2 cursor-pointer"
                    onClick={() => {
                      handleCampingType(campingType);
                    }}
                  >
                    {campingType?.length > 0
                      ? campingType == '캠핑'
                        ? '캠핑장'
                        : campingType
                      : '전체'}
                  </div>
                ))}
              </div>
            </>
          ) : chosenTab === TabType?.REGION ? (
            <>
              {!listCitiesStatus && parsedSearch['city'] && Array.isArray(parsedSearch['city']) ? (
                <div className="mt-[70px] mx-[15px] mb-[10px]">
                  <p className="font-[700]">선택한 지역</p>
                  <div className="flex flex-wrap">
                    {parsedSearch['city'].map((city) => (
                      <div className="border text-center p-[2px] bg-[#FF6400] text-[white] mr-[2px] mb-[2px]">
                        {city}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div
                className={`h-full w-full grid grid-cols-1 auto-rows-fr place-items-center bg-white$ ${
                  parsedSearch['city'] ? '' : 'mt-[70px]'
                } mb-[70px]`}
              >
                {/* {regions.map((region, index) => (
                  <div
                    className={`w-full flex justify-center p-4 border-b-2 ${
                      index == 0 ? '' : ''
                    } cursor-pointer`}
                    onClick={() => {
                      handleRegion(region);
                    }}
                  >
                    {region?.length > 0 ? region : '전체'}
                  </div>
                ))} */}
                {!listCitiesStatus && (
                  <>
                    <div
                      className="border-[1px] w-[90%] flex justify-center py-[14px] my-[5px]"
                      onClick={() => {
                        selectAllProvinces();
                      }}
                    >
                      전체
                    </div>
                    {Object.keys(regions2).map((key, index) => (
                      <div
                        key={key}
                        className="border-[1px] w-[90%] flex justify-center py-[14px] my-[5px]"
                        onClick={() => listCities(key)}
                      >
                        {key}
                      </div>
                    ))}
                  </>
                )}

                {listCitiesStatus && (
                  <>
                    {drawerNextRegionCityObj && (
                      <div>
                        <div className="grid grid-cols-3 gap-[18px] mt-[80px]">
                          <div
                            className="col-span-3 border-[1px] p-[10px] text-center"
                            onClick={() => handleSelectedCities('전체')}
                          >
                            전체
                          </div>
                          {drawerNextRegionCityObj?.cities &&
                            Object.keys(drawerNextRegionCityObj.cities).map((city: any) => (
                              <div
                                key={city}
                                className={`border-[1px] p-[10px] text-center ${
                                  drawerNextRegionCityObj.cities[city].selected
                                    ? 'bg-[#FF6400] text-[white]'
                                    : 'bg-[white] text-[black]'
                                }`}
                                onClick={() => handleSelectedCities(city)}
                              >
                                {city}
                              </div>
                            ))}
                        </div>
                        <div className="w-full border my-3" />
                        <div className="grid grid-cols-2 gap-[18px] mt-[20px]">
                          {/* <div
                            className="border-[1px] p-[10px] text-center"
                            onClick={() => {
                              defaultSelectedCities();
                            }}
                          >
                            초기화
                          </div> */}

                          <div
                            className="border-[1px] p-[10px] text-center"
                            onClick={() => {
                              setListCitiesStatus(false);
                            }}
                          >
                            뒤로
                          </div>
                          <div
                            className="border-[1px] p-[10px] text-center"
                            onClick={() => {
                              finishSelectedCities();
                            }}
                          >
                            검색
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          ) : null}
        </CustomDrawer>
      </div>
      <div className="absolute bottom-10 right-0 w-[30px] h-[30px] z-[1000]">
        <div className="flex justify-end max-w-md">
          <div
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="fixed bottom-10 w-[60px] h-[60px] mr-5 rounded-[50px] bg-[#06A54A] text-[white] font-[300] text-[1.5em] 
        flex justify-center items-center cursor-pointer"
          >
            <IconAngleDoubleTop fill="#FFFFFF" className="w-[35px] h-[35px]" />
          </div>
        </div>
      </div>
    </>
  );
};
