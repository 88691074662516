import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { TopTitleBar } from '../../components/TopTitleBar';
import { ILoginCustomer } from '../../actions/customer';
import { NaverLogin } from '../../components/Login/NaverLogin';
import { useAuth } from '../../hooks/authentication';
import { genController } from '../../hooks/authentication/types/statusCode';
import { authTypes } from '../../hooks/authentication/types/3rdAuth';
import { toast } from 'react-toastify';
import { ReactComponent as AppleLogo } from '../../assets/svg/apple-logo.svg';
import { Action, Bridge } from '../../plugins/bridge';
import { isIOS } from 'react-device-detect';
import { AppleLoginPage } from './AppleLoginPage';

interface FormValues {
  email: string;
  password: string;
}

const authURLs = {
  [authTypes.kakao]: `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_DEVELOPERS_JAVASCRIPT_KEY}&redirect_uri=${window.location.origin}/login/callback/kakao&response_type=code&prompt=login`,
  [authTypes.apple]: `/login/apple`,
};

export const LoginPage = () => {
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const [isLoading, setIsLoading] = useState(false);

  const work = async (_data: any) => {
    setIsLoading(true);
    const argument: ILoginCustomer = {
      email: _data.email,
      password: _data.password,
    };
    const { status, alertErrMsg } = await login(argument);
    const controller = genController(
      [
        [[400], alertErrMsg],
        [[401], alertErrMsg],
        [[404], alertErrMsg],
      ],
      () => {
        toast.error(
          '로그인중 예상치 못한 에러가 발생했어요' +
            '\n' +
            '일시적인 오류일 수 있으니 잠시 후 다시 시도해주세요'
        );
      }
    );
    controller(status);
    setIsLoading(false);
  };

  return (
    <>
      <TopTitleBar title="로그인" />
      <div className="side-padding mt-8">
        <h1>로그인해주세요</h1>
        <form
          className="flex flex-col space-y-4 mt-8"
          onSubmit={handleSubmit((data) => work(data))}
        >
          {/* <TextField
            label="이메일*"
            type="email"
            placeholder="이메일을 입력해주세요."
            helper={errors.email?.message}
            {...register('email', { required: '이메일을 입력해주세요' })}
          />

          <TextField
            label="비밀번호*"
            type="password"
            placeholder="비밀번호를 입력해주세요."
            helper={errors.password?.message}
            {...register('password', { required: '비밀번호를 입력해주세요' })}
          />

          <div className="flex flex-col space-y-3 pt-8 pb-4">
            <Button
              text="로그인"
              className="filled-gray-900 h-14"
              disabled={isLoading}
            />
          </div>

          <div className="text-13 text-gray-600 mx-auto">
            <Button to="/passwords/find" className="font-normal">
              비밀번호 찾기
            </Button>
            |
            <Button to="/signup" className="mx-4 px-0 font-normal">
              회원가입
            </Button>
            |
            <Button to="/non-member" className="font-normal">
              비회원 주문조회
            </Button>
          </div> */}
        </form>
        <div className="flex flex-col space-y-3 pt-8 pb-4">
          {/* <a href={authURLs[authTypes.kakao]}>
              <Button
                type="button"
                text="카카오로 시작하기"
                data-type={authTypes.kakao}
                className="bg-[#FAE300] h-14 w-full"
              />
            </a>
            <NaverLogin callbackUrl={''} /> */}
          {/* {isIOS && (
            <Button
              onClick={() => Bridge.postMessage(Action.SET_APPLE_TOKEN, '')}
              className=" w-full bg-black text-white text-15 font-normal flex items-center justify-center h-14"
            >
              <AppleLogo />
              <span className="pl-1"> Apple로 로그인</span>
            </Button>
          )} */}
          {/* <a href={authURLs[authTypes.apple]}>
            <Button
              // onClick={() => Bridge.postMessage(Action.SET_APPLE_TOKEN, '')}
              className=" w-full bg-black text-white text-15 font-normal flex items-center justify-center h-14"
            >
              <AppleLogo />
              <span className="pl-1"> Apple로 로그인</span>
            </Button>
          </a> */}
          {/* {isIOS && <AppleLoginPage />} */}
          <AppleLoginPage />
        </div>
      </div>
    </>
  );
};
