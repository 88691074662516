import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';

import { toast } from 'react-toastify';

import { Button } from '../../../../components/Button';

import { authUserTokenObjState } from '../../../../ridge/ridge';

import {
  editCommunityPost,
  editCommunityPostComment,
  uploadImages,
} from '../../../../api_v2/non_admin/community';

export default function PostCommentEdit(props: any) {
  const authUserTokenObj = authUserTokenObjState.useValue();

  const [content, setContent] = useState<any>('');
  const [editPostCommentStatus, setEditPostCommentStatus] = useState<any>(false);

  const handleEditPostComment = async () => {
    try {
      setEditPostCommentStatus(true);

      let editCommunityPostCommentRes = await editCommunityPostComment({
        id: props?.item?.id,
        postId: props.item?.postId,
        content: content?.trim(),
      });

      if (editCommunityPostCommentRes?.success == true) {
        props.refetchMyPagePostsComments();
        toast.success('성공적으로 수정하였습니다.');
        props?.closeDrawer();
      } else {
        toast.error('오류가 발생하였습니다.');
      }

      setEditPostCommentStatus(false);
    } catch (e) {}
  };

  const handleContentChange = (e: any) => {
    setContent(e?.target?.value);
  };

  useEffect(() => {
    if (props?.item) {
      setContent(props?.item?.content ?? '');
    }
  }, [props?.item]);

  return (
    <div>
      <textarea
        className="w-full mb-[5px] rounded-[10px] resize-none border-[#dcdcde]"
        style={{ height: '30vh', maxHeight: '300px' }}
        placeholder=""
        value={content}
        onChange={(e) => {
          handleContentChange(e);
        }}
      />
      <Button
        disabled={editPostCommentStatus}
        onClick={() => {
          handleEditPostComment();
        }}
        className="w-full h-[50px] mb-[25px] rounded font-bold text-[#FFFFFF] bg-[#FF6400] flex justify-center items-center"
      >
        {editPostCommentStatus ? '수정중...' : '수정'}
      </Button>
    </div>
  );
}
