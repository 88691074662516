import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createPopularKeywords } from '../../../api/popular-keyword';
import { createPopularKeyword } from '../../../api_v2/admin/popularKeywords';
import { CreatePopularKeywordParams } from '../../../api_v2/admin/popularKeywords/type';

import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';

export const SearchWordAdd = () => {
  const history = useHistory();
  const [formState, setFormState] = useState<CreatePopularKeywordParams>({
    weight: 0,
    keyword: '',
  });
  const [createPopularKeywordStatus, setCreatePopularKeywordStatus] = useState(false);

  const _handleCreatePopularKeyword = async () => {
    if (!(formState.weight >= 0)) {
      toast.error('0이상 숫자를 입력해주세요');
    } else {
      let redTextFlag = false;
      for (let i = 0; i < Object.keys(formState).length; i++) {
        let key = Object.keys(formState)[i];
        if (formState[key as keyof CreatePopularKeywordParams]) {
          let inputValue = formState[key as keyof CreatePopularKeywordParams]!.toString().trim();
          if (inputValue.length === 0) {
            redTextFlag = true;
            break;
          }
        }
      }
      if (redTextFlag) {
        toast.error('모든항목을 작성해주세요');
      } else {
        setCreatePopularKeywordStatus(true);
        let createPopularKeywordResult = await createPopularKeyword({
          ...formState,
        });
        if (createPopularKeywordResult) {
          toast.success('성공적으로 인기 검색어를 등록하였습니다');
          setCreatePopularKeywordStatus(false);
        } else {
          toast.error('Something went wrong creating a popular keyword');
        }
      }
    }
  };

  const _onChangeText = (e: any) => {
    const { name, value } = e.target;
    setFormState((prev) => {
      return { ...prev, [name]: name === 'weight' ? parseInt(value) : value };
    });
  };

  return (
    <>
      <AdminH1>인기 검색어 추가</AdminH1>

      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <TextField
            label="진열순서 (높을수록 우선 노출됩니다)"
            type="number"
            name="weight"
            value={formState.weight}
            onChange={_onChangeText}
          />
          <TextField
            label="* 키워드 명"
            name="keyword"
            value={formState.keyword}
            onChange={_onChangeText}
          />
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="취소"
            className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
            to="/admin/word"
            disabled={createPopularKeywordStatus}
          />
          <Button
            onClick={() => _handleCreatePopularKeyword()}
            text="추가하기"
            className="h-10 text-sm filled-black"
            disabled={createPopularKeywordStatus}
          />
        </div>
      </Card>
    </>
  );
};
