import { isEmpty, map, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Icon } from '../components/Icon';
import { Search } from '../components/Search';
import { SearchWordCard } from '../components/SearchWordCard';
import { TopTitleBar } from '../components/TopTitleBar';
import { usePopularKeywords } from '../hooks/popular-keyword';
import {
  getItemInLocalStorage,
  LOCAL_STORAGE_KEY,
  removeItemInLocalStorage,
  setItemInLocalStorage,
} from '../localstroage';
import { useHistory } from 'react-router';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { toast } from 'react-toastify';
import {
  getPopularKeywords,
  getRecentKeywordsLocalStorage,
  removeRecentKeywordsLocalStorage,
  setRecentKeywordsLocalStorage,
} from '../api_v2/non_admin/search';
import { PopularKeyword } from '../hooks/popular-keyword/type';
import _ from 'lodash';

export const SearchPage = () => {
  const history = useHistory();

  const [recentKeywords, setRecentKeywords] = useState<string[]>([]);

  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const [popularKeywords, setPopularKeywords] = useState<PopularKeyword[]>([]);

  useEffect(() => {
    getRecentKeywordsLocalStorage().then((resultData) => {
      if (resultData && Array.isArray(resultData)) {
        setRecentKeywords(resultData);
      }
    });
  }, []);

  useEffect(() => {
    getPopularKeywords().then((resultData) => {
      if (resultData?.success) {
        setPopularKeywords(_.sortBy(resultData.data, 'weight').reverse());
      } else {
        toast.error('Something went wrong getting popular keywords.');
      }
    });
  }, []);

  const _onSearchKeyword = () => {
    if (searchKeyword.trim().length > 0) {
      let modifiedKeywords = [];
      if (recentKeywords) {
        modifiedKeywords = [searchKeyword, ...recentKeywords];
      } else {
        modifiedKeywords = [searchKeyword];
      }
      modifiedKeywords = uniq(modifiedKeywords);
      setRecentKeywords(modifiedKeywords);
      history.push(`/search/result?searchKeyword=${searchKeyword}`);
      setRecentKeywordsLocalStorage(modifiedKeywords);
    } else {
      toast.error('검색어를 입력해주세요');
    }
  };

  const _handleRemoveAllRecentKeywordsLocalStorage = async () => {
    setRecentKeywords([]);
    let resultRemoveRecentKeywordsLocalStorage = await removeRecentKeywordsLocalStorage();
    if (resultRemoveRecentKeywordsLocalStorage) {
      toast.success('최근검색어를 전체삭제하였습니다');
    }
  };

  return (
    <>
      <TopTitleBar title="검색" home={false} />
      <div className="p-4 space-y-7">
        <Search
          placeholder="검색어를 입력해주세요."
          value={searchKeyword}
          onSearch={_onSearchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />

        <div>
          <div className="flex justify-between items-center">
            <h2>최근 검색어</h2>
            <button onClick={async () => _handleRemoveAllRecentKeywordsLocalStorage()}>
              <p>전체삭제</p>
            </button>
          </div>
          <Swiper
            className="mySwiper w-screen max-w-md mt-4 -mx-4 px-4"
            slidesPerView="auto"
            spaceBetween={6}
            slidesOffsetBefore={0}
            slidesOffsetAfter={0}
            freeMode={true}
          >
            {map(recentKeywords.slice(0, 10), (recentKeyword, index) => (
              <SwiperSlide
                className="flex space-x-2 items-center px-3 py-1 bg-gray-100 cursor-pointer w-auto"
                key={index}
              >
                <button
                  onClick={() => {
                    history.push(`/search/result?searchKeyword=${recentKeyword}`);
                  }}
                >
                  {recentKeyword}
                </button>
                <button
                  onClick={() => {
                    const newRecentKeywords = recentKeywords.filter((_, i) => i !== index);
                    setRecentKeywords(newRecentKeywords);
                    setRecentKeywordsLocalStorage(newRecentKeywords);
                  }}
                >
                  <Icon.X className="wh-4" />
                </button>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div>
          <h2>인기 검색어</h2>
          {map(popularKeywords, (keyword, index) => (
            <div
              onClick={() => {
                history.push(`/search/result?searchKeyword=${keyword.keyword}`);
              }}
            >
              <SearchWordCard key={keyword.id} title={keyword.keyword} index={index + 1} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
