import { ComponentType, FC, useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { useAuth } from '../hooks/authentication';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom'

export interface AuthRouteProps {
  path: string | string[];
  component: ComponentType;
  redirectTo?: string;
  guestOnly?: boolean;
  guestOnlyRedirectTo?: string;
}

export const AuthRoute: FC<AuthRouteProps> = ({
  path,
  component: Component,
  redirectTo = '/login',
  guestOnly = false,
  guestOnlyRedirectTo = '/',
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [
    redirect_path
  ] = [
    params.get('redirect_path')
  ]
  const { authenticated } = useAuth();

  useEffect(() => {
    if (redirect_path && authenticated) {
      history.push(redirect_path)
    }
  }, [authenticated])

  return (
    <Route
      path={path}
      render={() => {
        if (guestOnly && authenticated) {
          return <Redirect to={guestOnlyRedirectTo} />;
        }
        if (!guestOnly && !authenticated) {
          return <Redirect to={redirectTo} />;
        }
        return <Component />;
      }}
    />
  );
};
