import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AuthRoute } from '../components/AuthRoute';
import { LoginPage } from './login/LoginPage';
import { AppleLoginPage } from './login/AppleLoginPage';
import { HomePage } from './HomePage';
import { BottomNavBar } from '../components/BottomNavBar';
import { BookmarkPage } from './BookmarkPage';
import { Section } from '../components/Section';
import { ReactComponent as Logo } from '../assets/svg/rectangle_logo.svg';
import { ProductsPage } from './products/ProductsPage';
import { ProductPage } from './products/ProductPage';
import { BestPage } from './BestPage';
import { SalePage } from './SalePage';
import { EventsPage } from './EventsPage';
import { EventsDetailPage } from './EventsDetailPage';
import { NMOrdersPage } from './non-member/NMOrdersPage';
import { NMOrdersSuccessPage } from './non-member/NMOrdersSuccessPage';
import { NMPage } from './non-member/NMPage';
import { NMOrderHistoryPage } from './non-member/NMOrderHistoryPage';
import { BrandsPage } from './BrandsPage';
import { NewBrandsPage } from './NewBrandsPage';
import { SearchPage } from './SearchPage';
import { SearchResultPage } from './SearchResultPage';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import ChannelService from '../ChannelService';
import { useBanners } from '../hooks/banner';
import { stringify } from 'qs';
import { BannerType } from '../hooks/banner/type';
import { map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { historyState } from '../ridge/ridge';

import { DrawsPage } from './draws/DrawsPage';
import { DrawDetailPage } from './draws/DrawDetailPage';
import { ProductPageLink } from './products/ProductPageLink';
import { MyPage } from './my/MyPage';
import { BottomNavBar2 } from '../components/BottomNavBar2';
import { ProgressBar } from '../components/ProgressBar';

// Camping
import { MainPage as CampingMainPage } from './dayout-project-1/camping/MainPage';
import { SearchResultPage as CampingSearchResultPage } from './dayout-project-1/camping/booking/SearchResultPage';
import { CampingPage } from './dayout-project-1/camping/booking/CampingPage';
import { CampingZonePage } from './dayout-project-1/camping/booking/CampingZonePage';
import { BookingPage } from './dayout-project-1/camping/booking/BookingPage';
import { BookingResultPage } from './dayout-project-1/camping/booking/BookingResultPage';
import { ReservationCheckWONPage } from './dayout-project-1/camping/booking/ReservationCheckWONPage';
import { ReservationsPage } from './dayout-project-1/camping/booking/Reservations';
import { ReservationDetailPage } from './dayout-project-1/camping/booking/ReservationDetailPage';
import { ReservationCheckWPNPage } from './dayout-project-1/camping/booking/ReservationCheckWPNPage';
import { ConveyanceMainPage } from './dayout-project-1/camping/conveyance/ConveyanceMainPage';
import { AddConveyanceInfo } from './dayout-project-1/camping/conveyance/AddConveyanceInfo';
import { ConveyancePage } from './dayout-project-1/camping/conveyance/ConveyancePage';
import LoginPage2 from './dayout-project-1/camping/LoginPage2';
import { MyPage as Mypage2 } from './dayout-project-1/camping/MyPage';
import MyConveyanceInfoList from './dayout-project-1/camping/conveyance/MyConveyanceInfoList';
import { EditConveyanceInfoForMember } from './dayout-project-1/camping/conveyance/EditConveyanceInfoForMember';

import { ProtectedRoute } from './dayout-project-1/camping/components/ProtectedRoute';
import { PublicRoute } from './dayout-project-1/camping/components/PublicRoute';

// Onda camping
import { MainPage as OndaCampingMainPage } from './dayout-project-1/camping/onda/MainPage';
import { SearchResultPage as OndaCampingSearchResultPage } from './dayout-project-1/camping/onda/booking/SearchResultPage';
import { CampingPage as OndaCampingPage } from './dayout-project-1/camping/onda/booking/CampingPage';
import { CampingRoomTypePage as OndaCampingRoomTypePage } from './dayout-project-1/camping/onda/booking/CampingRoomTypePage';
import { BookingPage as OndaBookingPage } from './dayout-project-1/camping/onda/booking/BookingPage';
import { BookingResultPage as OndaBookingResultPage } from './dayout-project-1/camping/onda/booking/BookingResultPage';
import { ReservationCheckWONPage as OndaReservationCheckWONPage } from './dayout-project-1/camping/onda/booking/ReservationCheckWONPage';
import { ReservationsPage as OndaReservationsPage } from './dayout-project-1/camping/onda/booking/Reservations';
import { ReservationDetailPage as OndaReservationDetailPage } from './dayout-project-1/camping/onda/booking/ReservationDetailPage';
import { AddConveyanceInfo as OndaAddConveyanceInfo } from './dayout-project-1/camping/onda/conveyance/AddConveyanceInfo';
import { EditConveyanceInfoForMember as OndaEditConveyanceInfoForMember } from './dayout-project-1/camping/onda/conveyance/EditConveyanceInfoForMember';
import { ConveyancePage as OndaConveyancePage } from './dayout-project-1/camping/onda/conveyance/ConveyancePage';
import { default as OndaMyConveyanceInfoList } from './dayout-project-1/camping/onda/conveyance/MyConveyanceInfoList';
import { MyPage as OndaMypage } from './dayout-project-1/camping/onda/MyPage';
import { default as OndaLoginPage } from './dayout-project-1/camping/onda/LoginPage';
import { FaqPage as OndaFaqPage } from './dayout-project-1/camping/onda/FaqPage';
import { RequestResetPasswordPage as OndaRequestResetPasswordPage } from './dayout-project-1/camping/onda/booking/RequestResetPasswordPage';
import { ResetPasswordPage as OndaResetPasswordPage } from './dayout-project-1/camping/onda/booking/ResetPasswordPage';

import { ProtectedRoute as OndaProtectedRoute } from './dayout-project-1/camping/onda/components/ProtectedRoute';
import { PublicRoute as OndaPublicRoute } from './dayout-project-1/camping/onda/components/PublicRoute';

// Community
import { default as CommunityVKTourDestsFindNearby } from './boardingpass-project/community/visitkorea/tour-dest/FindNearby';
import { default as CommunityVKTourDestsChooseSpForNearby } from './boardingpass-project/community/visitkorea/tour-dest/ChooseSpForNearby';
import { default as CommunityVKTourDestsFindNearbyWSp } from './boardingpass-project/community/visitkorea/tour-dest/FindNearbyWSp';
import { default as CommunityVKTourDestDetail } from './boardingpass-project/community/visitkorea/tour-dest/TourDestDetail';

import { default as CommunityGcDestsFindNearby } from './boardingpass-project/community/visitkorea/gc-dest/FindNearby';
import { default as CommunityGcDestsChooseSpForNearby } from './boardingpass-project/community/visitkorea/gc-dest/ChooseSpForNearby';
import { default as CommunityGcDestsFindNearbyWSp } from './boardingpass-project/community/visitkorea/gc-dest/FindNearbyWSp';
import { default as CommunityGcDestDetail } from './boardingpass-project/community/visitkorea/gc-dest/GcDestDetail';

import { default as CommunityVKTravelDestsChooseSpForNearby } from './boardingpass-project/community/visitkorea/travel-dest/ChooseSpForNearby';
import { default as CommunityVKTravelDestsFindNearbyWSp } from './boardingpass-project/community/visitkorea/travel-dest/FindNearbyWSp';
import { default as CommunityVKTravelDestDetail } from './boardingpass-project/community/visitkorea/travel-dest/TravelDestDetail';

import { default as CommunityVKLeportsChooseSpForNearby } from './boardingpass-project/community/visitkorea/leport/ChooseSpForNearby';
import { default as CommunityVKLeportsFindNearbyWSp } from './boardingpass-project/community/visitkorea/leport/FindNearbyWSp';
import { default as CommunityVKLeportDetail } from './boardingpass-project/community/visitkorea/leport/LeportDetail';

import { Posts as CommunityPosts } from './boardingpass-project/community/post/Posts';
import { Post as CommunityPost } from './boardingpass-project/community/post/Post';
import { LoginPage as CommunityLoginPage } from './boardingpass-project/community/LoginPage';
import { MyPage as CommunityMyPage } from './boardingpass-project/community/my-page/MyPage';
import { MyPosts as CommunityMyPosts } from './boardingpass-project/community/my-page/post/MyPosts';
import { MyPostsComments as CommunityMyPostsComments } from './boardingpass-project/community/my-page/post/comment/MyPostsComments';

import { default as CommunityAccomodationsWDiscount } from './boardingpass-project/community/accomodations-w-discount/AccomodationsWDiscount';

import { default as CommunityJourneyLog } from './boardingpass-project/community/journey-log/JourneyLog';

import { default as CommunityBookmarkMain } from './boardingpass-project/community/bookmark/BookmarkMain';

import { default as CommunityAiImageSearchVG2ICTextBasedVkTourDests } from './boardingpass-project/community/ai/image-search/vit-gpt2-image-captioning/text-based/vk-tour-dests/ImageSearch';

import { default as CommunityGaCommunityPostsPopularMain } from './boardingpass-project/community/ga/community-posts/popular/Main';

import { ProtectedRoute as CommunityProtectedRoute } from './boardingpass-project/community/components/ProtectedRoute';
import { PublicRoute as CommunityPublicRoute } from './boardingpass-project/community/components/PublicRoute';

import { default as KakaoCallbackPage } from './KakaoCallbackPage';

SwiperCore.use([Pagination, Autoplay]);

export const Router = () => {
  const history = useHistory();
  ChannelService.boot({
    pluginKey: process.env.REACT_APP_CANNELIO_KEY,
    mobileMessengerMode: 'iframe',
    zIndex: 100,
    hideChannelButtonOnBoot: true,
    customLauncherSelector: '.channelIo-button',
  });

  const { pathname } = useLocation();
  const [bottomNavBarFilterList, setBottomNavBarFilterList] = useState([
    '/auth/applelogin',
    '/login',
    '/mypage',
    '/bookmark',
    '/search/result',
    '/search',
    '/home',
    '/',
  ]);

  useEffect(() => {
    historyState.set(history);
  }, []);

  return (
    <>
      <div className="grid md:grid-cols-2 mx-auto">
        <Section className="hidden md:flex justify-center mx-auto">
          {/* <div className="fixed h-screen flex flex-col justify-center space-y-4 xl:-mr-60">
            <div className="flex items-center space-x-3">
              <Logo className="wh-16" />
              <p className="text-brand-2 text-lg font-semibold">
                나에게 맞는 캠핑 취향을
                <br />
                찾아주는 공간, 피칭
              </p>
            </div>
            <Section className="wh-80">
              <Swiper
                pagination={true}
                autoplay={{
                  delay: 3000,
                }}
                className="mySwiper "
                loop={true}
              >
                {map(leftBannerData, (banner) => (
                  <SwiperSlide key={String(banner.id)}>
                    <div
                      className={`relative wh-80 rounded-md overflow-hidden ${
                        banner.url && 'cursor-pointer'
                      }`}
                      onClick={() => window.open(banner.url, '_parent')}
                    >
                      <img src={banner.thumbnail} alt="" className="img absolute" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Section>
          </div> */}
        </Section>

        <div className="min-h-screen flex flex-col flex-1 max-w-md shadow-lg md:mx-0 mx-auto w-full">
          <main className="relative flex-1 flex flex-col">
            <Switch>
              {/* <AuthRoute
                path="/passwords/reset"
                component={PasswordsResetPage}
                guestOnly
              />
              <AuthRoute
                path="/passwords/sent"
                component={PasswordsSentPage}
                guestOnly
              />
              <AuthRoute
                path="/passwords/find"
                component={PasswordsFindPage}
                guestOnly
              />
              <Route path="/signup" component={SignupPage} />
              <AuthRoute
                path="/login/callback/kakao"
                component={KakaoAuth}
                guestOnly
              />
              <AuthRoute
                path="/login/callback/naver"
                component={NaverAuth}
                guestOnly
              /> */}
              {/* <AuthRoute
                path="/auth/appletest"
                component={AppleLoginPage}
                guestOnly
              /> */}
              <AuthRoute path="/auth/applelogin" component={MyPage} guestOnly />
              <AuthRoute path="/login" component={LoginPage} guestOnly />
              {/* <Route path="/my/delete" component={DeleteMyPage} />
              <Route path="/my/terms-of-use" component={TermsOfUsePage} />
              <AuthRoute
                path="/my/change-password"
                component={ChangePasswordPage}
              />
              <Route path="/my/shipping/edit" component={MyShippingEditPage} />
              <Route path="/my/shipping/add" component={MyShippingAddPage} />
              <AuthRoute path="/my/shipping" component={MyShippingPage} />
              <AuthRoute path="/my/edit" component={EditMyPage} />
              <Route
                path="/my/reviews/:id/create"
                component={MyReviewsCreatePage}
              />
              <Route
                path="/my/reviews/:reviewId/edit"
                component={MyReviewsUpdatePage}
              />
              <AuthRoute
                path="/my/reviews"
                component={MyReviewsPage}
                redirectTo="/login?redirect_path=/my/reviews"
              />
              <Route
                path="/my/order/:orderId/orderItem/:orderItemId/exchange"
                component={ExchangePage}
              />
              <Route
                path="/my/order/:orderId/orderItem/:orderItemId/return"
                component={ReturnPage}
              />
              <Route
                path="/my/order/:orderId/orderItem/:orderItemId/inquiry"
                component={InquiryPage}
              />
              <Route path="/my/order/:orderId" component={OrderDetailsPage} />
              <AuthRoute
                path="/my/order"
                component={MyOrderHistoryPage}
                redirectTo="/login?redirect_path=/my/order"
              />
              <Route
                path="/my/inquiries/:inquiryId"
                component={MyInquiriesDetailPage}
              />
              <Route path="/my/inquiries" component={MyInquiriesPage} />
              <AuthRoute
                path="/my/coupon"
                component={CouponPage}
                redirectTo="/login?redirect_path=/my/coupon"
              />
              <Route path="/mypage" component={MyPage} />
              <Route
                path="/products/:productId/reviews/:reviewId"
                component={ReviewPage}
              />
              <Route
                path="/products/:productId/reviews"
                component={ReviewsPage}
              /> */}
              {/* <Route path="/progress" exact component={ProgressBar} /> */}
              <Route path="/mypage" exact component={MyPage} />
              <Route path="/products/:productId" exact component={ProductPage} />
              {/* <Route path="/products" component={ProductsPage} /> */}
              <Route
                path="/productpagelink/:linkType/:productId"
                exact
                component={ProductPageLink}
              />
              {/* <Route path="/non-member/order/:id" component={NMOrderHistoryPage} />
              <Route path="/non-member/orders/success/:orderId" component={NMOrdersSuccessPage} />
              <Route path="/non-member/orders" component={NMOrdersPage} />
              <Route path="/non-member" component={NMPage} />
              <Route path="/brands/:id" component={BrandsPage} />
              <Route path="/brands" component={NewBrandsPage} /> */}
              <Route path="/bookmark" component={BookmarkPage} />
              <Route path="/events/:id" component={EventsDetailPage} />
              <Route path="/events" component={EventsPage} />
              {/* <Route path="/sale" component={SalePage} />
              <Route path="/best" component={BestPage} /> */}
              <Route path="/draws/:id" component={DrawDetailPage} />
              <Route path="/draws" component={DrawsPage} />
              <Route path="/search/result" component={SearchResultPage} />
              <Route path="/search" component={SearchPage} />
              <Route path="/home" component={HomePage} exact />
              {/* Camping */}
              {/* <Route path="/camping" component={CampingMainPage} exact />
              <Route path="/camping/search/result" component={CampingSearchResultPage} />
              <Route path="/camping/campinginfo/:campingId" component={CampingPage} exact />
              <Route
                path="/camping/campinginfo/:campingId/zones/:zoneId"
                component={CampingZonePage}
                exact
              />
              <Route
                path="/camping/campinginfo/:campingId/zones/:zoneId/sites/:siteId/booking"
                component={BookingPage}
                exact
              />
              <Route
                path="/camping/campinginfo/:campingId/zones/:zoneId/sites/:siteId/booking/result"
                component={BookingResultPage}
                exact
              />
              <Route
                path="/camping/reservation/check-w-on"
                component={ReservationCheckWONPage}
                exact
              />
              <Route
                path="/camping/reservation/check-w-pn"
                component={ReservationCheckWPNPage}
                exact
              />
              <Route path="/camping/reservation/history" component={ReservationsPage} exact />
              <Route
                path="/camping/reservation/history/:reservationId"
                component={ReservationDetailPage}
                exact
              />
              <PublicRoute
                path="/camping/conveyance/addconveyanceinfo"
                component={AddConveyanceInfo}
              />
              <ProtectedRoute
                path="/camping/conveyance/conveyanceinfo/:conveyanceId/edit/member"
                component={EditConveyanceInfoForMember}
              />
              <PublicRoute
                path="/camping/conveyance/conveyanceinfo/:conveyanceId"
                component={ConveyancePage}
              />
              <ProtectedRoute
                path="/camping/conveyance/my-conveyanceinfo-list"
                component={MyConveyanceInfoList}
              />

              <PublicRoute path="/login-v2" component={LoginPage2} guestOnly={true} />
              <ProtectedRoute path="/camping/mypage" component={Mypage2} />
              <Route path="/camping">
                <Redirect to="/camping?chosen_tab=booking" />
              </Route> */}
              {/* Onda Camping */}
              <Route
                path="/v2/camping"
                component={() => <OndaCampingMainPage popupType1={true} />}
                exact
              />
              <Route path="/v2/camping/search/result" component={OndaCampingSearchResultPage} />
              <Route
                path="/v2/camping/campinginfo/:campingId"
                component={() => <OndaCampingPage popupType1={true} />}
                exact
              />
              <Route
                path="/v2/camping/campinginfo/:campingId/roomtypes/:roomTypeId"
                component={OndaCampingRoomTypePage}
                exact
              />
              <Route
                path="/v2/camping/campinginfo/:campingId/roomtypes/:roomTypeId/rateplans/:ratePlanId/booking"
                component={OndaBookingPage}
                exact
              />
              <Route
                path="/v2/camping/campinginfo/:campingId/roomtypes/:roomTypeId/rateplans/:ratePlanId/booking/result"
                component={OndaBookingResultPage}
                exact
              />
              <Route
                path="/v2/camping/reservation/check-w-on"
                component={OndaReservationCheckWONPage}
                exact
              />
              <Route
                path="/v2/camping/reservation/request-reset-password/nonmember"
                component={OndaRequestResetPasswordPage}
                exact
              />
              <Route
                path="/v2/camping/reservation/reset/password/nonmember/:channelBookingNo/:uniqueId/:encrypted"
                component={OndaResetPasswordPage}
                exact
              />
              <OndaProtectedRoute
                path="/v2/camping/reservation/history"
                component={OndaReservationsPage}
                exact
              />
              <Route
                path="/v2/camping/reservation/history/:reservationId"
                component={OndaReservationDetailPage}
                exact
              />
              <OndaPublicRoute
                path="/v2/camping/conveyance/addconveyanceinfo"
                component={OndaAddConveyanceInfo}
              />
              <OndaProtectedRoute
                path="/v2/camping/conveyance/conveyanceinfo/:conveyanceId/edit/member"
                component={OndaEditConveyanceInfoForMember}
              />
              <OndaPublicRoute
                path="/v2/camping/conveyance/conveyanceinfo/:conveyanceId"
                component={OndaConveyancePage}
              />
              <OndaProtectedRoute
                path="/v2/camping/conveyance/my-conveyanceinfo-list"
                component={OndaMyConveyanceInfoList}
              />
              <OndaPublicRoute path="/v2/login" component={OndaLoginPage} guestOnly={true} />
              <OndaProtectedRoute path="/v2/camping/mypage" component={OndaMypage} />
              <Route path="/v2/camping/faq" component={OndaFaqPage} />
              <Route path="/camping">
                <Redirect to="/v2/camping" />
              </Route>
              <Route path="/v2/camping">
                <Redirect to="/v2/camping?chosen_tab=booking" />
              </Route>

              {/* Boardingpass community  */}
              <Route
                path="/community/vk-tour-dests/find-nearby"
                component={() => <CommunityVKTourDestsFindNearby />}
                exact
              />
              <Route
                path="/community/vk-tour-dests/choose-sp-for-nearby"
                component={() => <CommunityVKTourDestsChooseSpForNearby />}
                exact
              />
              <Route
                path="/community/vk-tour-dests/find-nearby-w-sp"
                component={() => <CommunityVKTourDestsFindNearbyWSp />}
                exact
              />

              <Route
                path="/community/vk-tour-dests/:vkTourDestId"
                component={() => <CommunityVKTourDestDetail />}
                exact
              />
              <Route
                path="/community/vk-travel-dests/choose-sp-for-nearby"
                component={() => <CommunityVKTravelDestsChooseSpForNearby />}
                exact
              />
              <Route
                path="/community/vk-travel-dests/find-nearby-w-sp"
                component={() => <CommunityVKTravelDestsFindNearbyWSp />}
                exact
              />
              <Route
                path="/community/vk-gc-dests/choose-sp-for-nearby"
                component={() => <CommunityGcDestsChooseSpForNearby />}
                exact
              />
              <Route
                path="/community/vk-gc-dests/find-nearby-w-sp"
                component={() => <CommunityGcDestsFindNearbyWSp />}
                exact
              />
              <Route
                path="/community/vk-leports/choose-sp-for-nearby"
                component={() => <CommunityVKLeportsChooseSpForNearby />}
                exact
              />
              <Route
                path="/community/vk-leports/find-nearby-w-sp"
                component={() => <CommunityVKLeportsFindNearbyWSp />}
                exact
              />
              <Route path="/community/posts" component={() => <CommunityPosts />} exact />
              <Route path="/community/posts/:postId" component={() => <CommunityPost />} exact />
              <CommunityPublicRoute
                path="/community/login"
                component={CommunityLoginPage}
                guestOnly={true}
              />
              <CommunityProtectedRoute
                path="/community/mypage/posts"
                component={CommunityMyPosts}
              />
              <CommunityProtectedRoute
                path="/community/mypage/posts-comments"
                component={CommunityMyPostsComments}
              />
              <CommunityProtectedRoute path="/community/mypage" component={CommunityMyPage} />
              <Route
                path="/community/accomms-w-disc"
                component={() => <CommunityAccomodationsWDiscount />}
                exact
              />
              <Route
                path="/community/journey-log"
                component={() => <CommunityJourneyLog />}
                exact
              />
              <Route path="/community/bookmark" component={() => <CommunityBookmarkMain />} exact />

              <CommunityProtectedRoute
                path="/community/ga/community-posts/popular"
                component={() => <CommunityGaCommunityPostsPopularMain />}
                exact
              />

              <CommunityProtectedRoute
                path="/community/image-search/vg2ic/tb/vk-tour-dests"
                component={() => <CommunityAiImageSearchVG2ICTextBasedVkTourDests />}
                exact
              />

              <Route path="/community">
                <Redirect to="/community/posts" />
              </Route>
              <Route
                path="/Api/Member/Oauth2ClientCallback/kakao/"
                component={KakaoCallbackPage}
                exact
              />
              <Route path="/">
                <Redirect to="/community" />
              </Route>
            </Switch>
          </main>

          {bottomNavBarFilterList.includes(pathname) && <BottomNavBar />}
        </div>
      </div>
    </>
  );
};
