import { result } from 'lodash';
import { api } from '../../../plugins/axios';
import { Product } from '../../../types';
import { stringify } from 'qs';

export const getRecentKeywordsLocalStorage = async () => {
  try {
    let localStorageRecentKeywords = localStorage.getItem('recentKeyWords');
    if (localStorageRecentKeywords) {
      let parsedLocalStorageRecentKeywords = JSON.parse(localStorageRecentKeywords);
      if (Array.isArray(parsedLocalStorageRecentKeywords)) {
        return parsedLocalStorageRecentKeywords;
      } else {
        return [];
      }
    } else {
      return [];
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const setRecentKeywordsLocalStorage = async (modifiedKeywords: string[]) => {
  try {
    localStorage.setItem('recentKeyWords', JSON.stringify(modifiedKeywords));
  } catch (e) {
    console.log('e', e);
  }
};

export const removeRecentKeywordsLocalStorage = async () => {
  try {
    localStorage.removeItem('recentKeyWords');
    return true;
  } catch (e) {
    console.log('e', e);
  }
};

export const getPopularKeywords = async () => {
  try {
    let apiResult = await api.get('/popular-keywords');
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(apiResult.status)) {
      return { success: true, data: apiResult.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.error('e', e);
  }
};
