import React, { useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

import { toast } from 'react-toastify';
import { Button } from '../../../../../../../../../components/Button';
import { Icon } from '../../../../../../../../../components/Icon';
import { api } from '../../../../../../../../../plugins/axios';

import { pipeline, env } from '@xenova/transformers';
import axios from 'axios';

import {
  faSortUp,
  faSortDown,
  faSortAmountAsc,
  faSortAmountDown,
  faSortAmountDesc,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { apiForTempSol } from '../../../../../../../../../tempSolution/apiForTempSol';

import { env as envOnnx } from 'onnxruntime-web';

envOnnx.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web/dist/';
envOnnx.wasm.numThreads = 1;

env.backends.onnx.wasm.numThreads = 1;

env.allowLocalModels = false;
env.useBrowserCache = false;

const Modal = ({
  visible,
  onClose,
  step1Status,
  step1Progress,

  requestSrcs,

  cancelProcessRef,
}: any) => {
  return (
    <div className={`modal fixed inset-0 z-50 ${visible ? '' : 'hidden'}`}>
      <div className="modal-overlay bg-gray-900 opacity-50 absolute inset-0"></div>
      <div className="modal-content relative z-[100] bg-white w-64 mx-auto mt-20 p-6 rounded-lg shadow-lg">
        <div className="flex justify-content items-center gap-[10px]">
          <p style={{ color: step1Status ? 'black' : 'gray' }}>step1</p>
        </div>
        <div className="flex gap-[5px] justify-center items-center">
          <p className="text-center">
            {step1Status == true ? (step1Progress ?? 0)?.toFixed(2) : null}%
          </p>
          <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
        </div>

        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-center">
            <div className="w-64 bg-gray-200 rounded-full">
              <div
                className="rounded-full bg-blue-500"
                style={{
                  width: `${step1Status == true ? step1Progress : null}%`,
                  height: '8px',
                }}
              ></div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <button
              className="border py-[3px] px-[3px]"
              onClick={() => {
                try {
                  cancelProcessRef.current = true;

                  requestSrcs.forEach((item: any) => {
                    if (item) {
                      item.cancel('Component unmounted');
                    }
                  });
                } catch (e) {}
              }}
            >
              cancel process
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ImageSearch = () => {
  const [modalVisible, setModalVisible] = useState(false);
  let [step1Progress, setStep1Progress] = useState(0);
  let [step1Status, setStep1Status] = useState(false);

  let [step2Progress, setStep2Progress] = useState(0);
  let [step2Status, setStep2Status] = useState(false);

  const [cancelTokens, setCancelTokens] = useState<any>([]);

  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });
  const [searchInput, setSearchInput] = useState<any>(parsed?.q ?? '');

  const [vkTourDests, setVkTourDests] = useState<any>(null);

  const source1Ref = useRef<any>(null);
  const source2Ref = useRef<any>(null);

  const [cancelProcessBtn, setCancelProcessBtn] = useState(false);

  const cancelProcessRef = useRef<any>(false);

  const [continueProcessData, setContinueProcessData] = useState<any>(null);

  const [error1, setError1] = useState<any>(null);

  const [scoreValue, setScoreValue] = useState<any>(0.3);

  const handleScoreChange = (event: any) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue !== '') {
      setScoreValue(inputValue);
    }
  };

  const handleSearchInput = (e: any) => {
    try {
      setSearchInput(e.target.value);
    } catch (e) {}
  };

  const handleTBSearch = async () => {
    try {
      if (searchInput?.trim()?.length <= 0) {
        toast.warn('검색어를 입력해 주세요');
      } else {
        setError1(null);

        cancelProcessRef.current = false;

        setModalVisible(true);

        setStep1Status(true);
        setStep1Progress(0);

        setStep2Progress(0);

        let lsData = localStorage.getItem('b121bf66-3a76-4eb9-9911-88b05d9e8e23');

        let translatedText = '';
        source1Ref.current = axios.CancelToken.source();

        let continueProcessDataCopy = null as any;

        const repeat1 = () => {
          try {
            let newData = {
              comp: [],
              chosen: [],
              createdAt: new Date(),
              translatedSearched: translatedText,
            };
            continueProcessDataCopy = newData;
            setContinueProcessData(newData);
          } catch (e) {}
        };

        if (lsData) {
          if (parsed?.continueProcess == 'y') {
            let parsedLsData = JSON.parse(lsData);
            let key = Object.keys(parsedLsData)[0];
            if (key == searchInput?.trim()) {
              continueProcessDataCopy = parsedLsData[key];
              setContinueProcessData(parsedLsData[key]);
              translatedText = parsedLsData[key]?.translatedSearched;
            } else {
              let translatedDataRes = await api.post(
                '/admin/ai/util/translate',
                {
                  dataToTranslate: searchInput?.trim(),
                  toLanguage: 'en',
                },
                { cancelToken: source1Ref.current.token }
              );

              translatedText = translatedDataRes?.data?.translatedText;

              repeat1();
            }
          } else {
            let translatedDataRes = await api.post(
              '/admin/ai/util/translate',
              {
                dataToTranslate: searchInput?.trim(),
                toLanguage: 'en',
              },
              { cancelToken: source1Ref.current.token }
            );

            translatedText = translatedDataRes?.data?.translatedText;

            repeat1();
          }
        } else {
          if (parsed?.continueProcess == 'y') {
            window.location.href =
              '/admin/ai/image-search/vit-gpt2-image-captioning/text-based/vk-tour-dests';
          } else {
            let translatedDataRes = await api.post(
              '/admin/ai/util/translate',
              {
                dataToTranslate: searchInput?.trim(),
                toLanguage: 'en',
              },
              { cancelToken: source1Ref.current.token }
            );

            translatedText = translatedDataRes?.data?.translatedText;

            repeat1();
          }
        }

        source2Ref.current = axios.CancelToken.source();

        if (translatedText) {
          let vkTourDestsToComp;
          if (vkTourDests?.length > 0) {
            vkTourDestsToComp = vkTourDests;
          } else {
            let vkTourDestsRes = await api.get(
              '/admin/ai/community/image-search/vg2ic/text-based/vk-tour-dests/get-vk-tour-dests',
              { cancelToken: source2Ref.current.token }
            );
            if (
              vkTourDestsRes?.data?.vkTourDests &&
              vkTourDestsRes?.data?.vkTourDests[0] &&
              vkTourDestsRes?.data?.vkTourDests[0]?.length > 0
            ) {
              setVkTourDests(vkTourDestsRes?.data?.vkTourDests[0]);
              vkTourDestsToComp = vkTourDestsRes?.data?.vkTourDests[0];
            }
          }
          if (vkTourDestsToComp?.length > 0) {
            let filterComp;

            if (continueProcessDataCopy && continueProcessDataCopy?.comp) {
              filterComp = vkTourDestsToComp?.filter(
                (item: any) => !continueProcessDataCopy?.comp?.includes(item?.id)
              );
              filterComp = pickRandomItems(filterComp, filterComp?.length);
            } else {
              filterComp = pickRandomItems(vkTourDestsToComp, vkTourDestsToComp?.length);
            }

            loop1: for (let i = 0; i < filterComp?.length; i++) {
              if (cancelProcessRef.current == true) {
                break loop1;
              }

              setStep1Progress((i / filterComp?.length) * 100);

              console.log('step1 progress', (i / filterComp?.length) * 100 + '%');
              let itemToComp = filterComp[i];
              if (itemToComp?.images?.length > 0 && itemToComp?.images[0]?.descEng) {
                let simiRes = await findSimilarByallMiniLML6v2(
                  translatedText,
                  itemToComp?.images[0]?.descEng
                );

                console.log('simiRes', simiRes);
                if ((simiRes as any) >= 0) {
                  if ((simiRes as any) >= scoreValue) {
                    if (continueProcessDataCopy) {
                      let modfiedData = {
                        ...continueProcessDataCopy,
                        comp: [...(continueProcessDataCopy?.comp as any), itemToComp?.id],
                        chosen: [
                          ...(continueProcessDataCopy?.chosen as any),
                          {
                            vkTourDestId: itemToComp?.id,
                            score: simiRes,
                          },
                        ],
                      };
                      continueProcessDataCopy = modfiedData;
                      setContinueProcessData(modfiedData);
                    } else {
                      let newData = {
                        comp: [itemToComp?.id],
                        chosen: [
                          {
                            vkTourDestId: itemToComp?.id,
                            score: simiRes,
                          },
                        ],
                        createdAt: new Date(),
                        translatedSearched: translatedText,
                      };
                      continueProcessDataCopy = newData;
                      setContinueProcessData(newData);
                    }
                  } else {
                    if (continueProcessDataCopy) {
                      let modfiedData = {
                        ...continueProcessDataCopy,
                        comp: [...(continueProcessDataCopy?.comp as any), itemToComp?.id],
                      };
                      continueProcessDataCopy = modfiedData;
                      setContinueProcessData(modfiedData);
                    } else {
                      let newData = {
                        comp: [itemToComp?.id],
                        chosen: [],
                        createdAt: new Date(),
                        translatedSearched: translatedText,
                      };
                      continueProcessDataCopy = newData;
                      setContinueProcessData(newData);
                    }
                  }
                } else if ((simiRes as any) < 0) {
                  if (continueProcessDataCopy) {
                    let modfiedData = {
                      ...continueProcessDataCopy,
                      comp: [...(continueProcessDataCopy?.comp as any), itemToComp?.id],
                    };
                    continueProcessDataCopy = modfiedData;
                    setContinueProcessData(modfiedData);
                  } else {
                    let newData = {
                      comp: [itemToComp?.id],
                      chosen: [],
                      createdAt: new Date(),
                      translatedSearched: translatedText,
                    };
                    continueProcessDataCopy = newData;
                    setContinueProcessData(newData);
                  }
                } else {
                  // toast.error(`${JSON.stringify(simiRes)}`);
                  toast.error(`세션이 종료되었습니다. 계속 진행하시겠습니까?`);

                  setError1('세션이 종료되었습니다. 계속 진행하시겠습니까?');
                  break loop1;
                }
              }
            }
          } else {
            console.log('no vkTourDestsToComp');
          }
        } else {
          console.log('no translatedText');
        }

        if (continueProcessDataCopy) {
          localStorage.setItem(
            'b121bf66-3a76-4eb9-9911-88b05d9e8e23',
            JSON.stringify({ [searchInput?.trim()]: continueProcessDataCopy })
          );
        }
        setModalVisible(false);
      }
    } catch (e) {
      console.log('e', e);
      setModalVisible(false);
    }
  };

  useEffect(() => {
    if (parsed?.continueProcess == 'y') {
      handleTBSearch();
    }
  }, [search]);

  useEffect(() => {
    return () => {
      [source1Ref.current, source2Ref.current].forEach((item) => {
        if (item) {
          item.cancel('Component unmounted');
        }
      });
    };
  }, []);

  const handleTBSearchButton = (evt: any) => {
    handleTBSearch();
  };

  const handleTBSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      handleTBSearch();
    }
  };

  const pickRandomItems = (list: any, numItems: any) => {
    const shuffled = list.sort(() => Math.random() - 0.5);
    return shuffled.slice(0, numItems);
  };

  const findSimilarByallMiniLML6v2 = async (targetData: any, compData: any) => {
    try {
      console.log('findSimilarByallMiniLML6v2');
      // Create a feature-extraction pipeline with the specified model
      let extractor = await pipeline('feature-extraction', 'Xenova/all-MiniLM-L6-v2');

      // Function to compute cosine similarity between two vectors
      const cosineSimilarity = (vectorA: any, vectorB: any) => {
        const dotProduct = vectorA.reduce(
          (acc: any, val: any, i: any) => acc + val * vectorB[i],
          0
        );
        const magnitudeA = Math.sqrt(vectorA.reduce((acc: any, val: any) => acc + val * val, 0));
        const magnitudeB = Math.sqrt(vectorB.reduce((acc: any, val: any) => acc + val * val, 0));
        return dotProduct / (magnitudeA * magnitudeB);
      };

      // Function to compute similarity between two sentences
      const computeSimilarity = async (sentence1: any, sentence2: any) => {
        // Compute sentence embeddings for both sentences
        const embeddings = await extractor([sentence1, sentence2], {
          pooling: 'mean',
          normalize: true,
        });

        // Calculate cosine similarity between the embeddings
        const similarityScore = await cosineSimilarity(
          embeddings.tolist()[0],
          embeddings.tolist()[1]
        );
        return similarityScore;
      };

      let score = await computeSimilarity(targetData, compData);
      return score;
    } catch (e) {
      return e;
    }
  };

  // const [activeTab, setActiveTab] = useState(0);

  // const tabs = [{ label: '텍스트로 검색' }, { label: '이미지로 검색' }];

  const findVkTourDestDetail = (dataObj: any) => {
    try {
      if (vkTourDests?.length > 0) {
        let foundData = vkTourDests.find((item: any) => item?.id == dataObj?.vkTourDestId);

        return { ...foundData, score: dataObj?.score };
      } else return null;
    } catch (e) {
      return null;
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    if (continueProcessData?.chosen?.length > 0) {
      setTotalPages(Math.ceil((continueProcessData?.chosen?.length ?? 0) / itemsPerPage));

      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = Math.min(
        startIndex + itemsPerPage,
        continueProcessData?.chosen?.length ?? 0
      );

      setCurrentItems(continueProcessData?.chosen?.slice(startIndex, endIndex));
    }
  }, [currentPage, continueProcessData]);

  const goToPage = (page: any) => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // });
    try {
      setCurrentPage(page);
      const element = document.querySelector('.cd7d819f-f5d9-49c6-a2fb-155f90a08db9');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    } catch (e) {}
  };

  const [sortDataStatus, setSortDataStatus] = useState(false);

  const [currSortOption, setCurrSortOption] = useState<any>(null);

  const sortData = (propType: any, sortType: any) => {
    try {
      setSortDataStatus(true);

      if (continueProcessData?.chosen?.length > 0) {
        if (propType == 'score') {
          if (sortType == 'asc') {
            setCurrSortOption({ propType: 'score', sortType: 'asc' });
            const ascendingSorted = continueProcessData?.chosen.sort(
              (a: any, b: any) => a.score - b.score
            );

            setContinueProcessData((prev: any) => {
              return { ...prev, chosen: ascendingSorted };
            });
          } else {
            setCurrSortOption({ propType: 'score', sortType: 'desc' });

            const descendingSorted = continueProcessData?.chosen.sort(
              (a: any, b: any) => b.score - a.score
            );

            setContinueProcessData((prev: any) => {
              return { ...prev, chosen: descendingSorted };
            });
          }
        }
      }
      setSortDataStatus(false);
    } catch (e) {
      console.log('e', e);
      setSortDataStatus(false);
    }
  };

  return (
    <div>
      <div className="w-screen sm:w-full md:w-full lg:w-full side-padding bg-white">
        <Modal
          visible={modalVisible}
          step1Status={step1Status}
          step1Progress={step1Progress}
          // step3Status={step3Status}
          // step3Progress={step3Progress}
          requestSrcs={[source1Ref.current, source2Ref.current]}
          cancelProcessRef={cancelProcessRef}
        />
        <>
          <>
            <div className="cd7d819f-f5d9-49c6-a2fb-155f90a08db9">
              <div>
                <p className="my-[3px]">Set score for similarity {'>='}</p>
                <input
                  className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
                  type="text"
                  value={scoreValue}
                  onChange={handleScoreChange}
                  placeholder="Enter numbers only"
                />
              </div>
              <div
                className="flex flex-row-reverse w-full items-center px-4 h-12 mt-2
              border border-[#FF6400] rounded bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
              >
                <Button
                  className="bg-[#FF6400] text-[#FFFFFF] rounded-[12px]"
                  style={{ height: '80%' }}
                  onClick={(e) => handleTBSearchButton(e)}
                >
                  이미지 검색
                </Button>
                {searchInput ? (
                  <>
                    <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                      <Icon.X
                        className="wh-3"
                        onClick={() => {
                          setSearchInput('');
                        }}
                      />
                    </div>
                  </>
                ) : null}
                <input
                  className="flex-1 text-sm bg-white"
                  placeholder="이미지 검색하기"
                  onChange={(e: any) => handleSearchInput(e)}
                  value={searchInput as string}
                  onKeyDown={(e) => handleTBSearchKeyDown(e)}
                />
              </div>
            </div>

            {error1 && (
              <div className="w-full flex gap-[5px] justify-end items-center">
                <p className="text-[red]">{error1}</p>
                <Button
                  className="border rounded-[2px]"
                  onClick={() => {
                    try {
                      window.location.href = `/admin/ai/image-search/vit-gpt2-image-captioning/text-based/vk-tour-dests?continueProcess=y&q=${
                        searchInput?.trim() ?? ''
                      }`;
                    } catch (e) {}
                  }}
                >
                  계속 진행
                </Button>
                <Button
                  className="border rounded-[2px]"
                  onClick={() => {
                    try {
                      window.location.href =
                        '/admin/ai/image-search/vit-gpt2-image-captioning/text-based/vk-tour-dests';
                    } catch (e) {}
                  }}
                >
                  재시작
                </Button>
              </div>
            )}
            <div className="flex gap-[5px]">
              <div>
                <div className="font-[700] text-center">Score</div>
                <div className="flex gap-[15px]">
                  <div
                    className="cursor-pointer p-[5px]"
                    onClick={() => {
                      sortData('score', 'asc');
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSortAmountAsc}
                      color={
                        currSortOption?.propType == 'score' && currSortOption?.sortType == 'asc'
                          ? 'red'
                          : 'black'
                      }
                      className="leading-6"
                    />
                  </div>
                  <div
                    className="cursor-pointer p-[5px]"
                    onClick={() => {
                      sortData('score', 'desc');
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSortAmountDesc}
                      color={
                        currSortOption?.propType == 'score' && currSortOption?.sortType == 'desc'
                          ? 'red'
                          : 'black'
                      }
                      className="leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  try {
                    localStorage.removeItem('b121bf66-3a76-4eb9-9911-88b05d9e8e23');
                    toast.success('cleared old data from ls');
                  } catch (e) {}
                }}
                className="bg-[#FF6B6B] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
              >
                clear old data from ls
              </button>
            </div>

            {sortDataStatus ? (
              <div className="flex justify-center">
                <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
              </div>
            ) : (
              <>
                {currentItems?.length > 0 && vkTourDests?.length > 0 && (
                  <>
                    <ul className="mt-[5px]">
                      {currentItems.map((item: any, index) => (
                        <li key={item?.vkTourDestId} className="border py-[5px] px-[5px] mb-[5px]">
                          {findVkTourDestDetail(item) ? (
                            <div className="grid grid-cols-8 gap-10">
                              {/* <div >{findVkTourDestDetail(item)?.id}</div> */}
                              <div className="col-span-5">
                                <div className="font-[700]">
                                  Score:{' '}
                                  {findVkTourDestDetail(item)?.score &&
                                    findVkTourDestDetail(item)?.score?.toFixed(2)}
                                </div>
                                <div className="font-[700]">
                                  {findVkTourDestDetail(item)?.title}
                                </div>
                                <div>{findVkTourDestDetail(item)?.overview}</div>
                              </div>

                              <div className="col-span-3 max-w-[300px] w-full flex justify-center items-center">
                                {findVkTourDestDetail(item)?.images?.length > 0 && (
                                  <img
                                    className="w-full"
                                    src={`${findVkTourDestDetail(item)?.images[0]?.url ?? ''}`}
                                  />
                                )}
                              </div>
                            </div>
                          ) : null}
                        </li>
                      ))}
                    </ul>
                    <div className="flex justify-between mt-4">
                      <button
                        onClick={() => goToPage(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`py-2 px-4 rounded-md ${
                          currentPage === 1
                            ? 'bg-gray-300 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-600 text-white'
                        } transition-colors duration-300`}
                      >
                        Previous Page
                      </button>
                      <span className="text-lg">
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        onClick={() => {
                          goToPage(currentPage + 1);
                        }}
                        disabled={currentPage === totalPages}
                        className={`py-2 px-4 rounded-md ${
                          currentPage === totalPages
                            ? 'bg-gray-300 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-600 text-white'
                        } transition-colors duration-300`}
                      >
                        Next Page
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        </>
      </div>
    </div>
  );
};

export default ImageSearch;
