import _ from 'lodash';
import React, { FC, HTMLAttributes, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { getPopularKeywords } from '../api_v2/non_admin/search/index';
import { PopularKeyword } from '../hooks/popular-keyword/type';
import { NewBrandsPage } from '../pages/NewBrandsPage';

export const KeyWord = () => {
  const history = useHistory();
  const [keywordIdx, setKeywordIdx] = useState<number>(0);

  const [popularKeywords, setPopularKeywords] = useState<PopularKeyword[]>([]);

  const [popularKeywordsLen, setPopularKeywordsLen] = useState<number>(0);

  useEffect(() => {
    getPopularKeywords().then((resultData) => {
      if (resultData?.success) {
        setPopularKeywords(_.sortBy(resultData.data, 'weight').reverse());
        const len = resultData.data.length;
        setPopularKeywordsLen(resultData.data.length);
        const interval = setInterval(() => {
          if (len > 0) {
            setKeywordIdx((ki) => (ki + 1) % len);
          }
        }, 5000);
        return () => clearInterval(interval);
      } else {
        // toast.error('Something went wrong getting popular keywords.');
      }
    });
  }, []);

  const keywordShow = function (keyword: string) {
    if (keyword.length < 10) {
      return keyword;
    }
    return keyword.substring(0, 9) + '...';
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (lenPopularKeywords > 0) {
  //       setKeywordIdx((ki) => (ki + 1) % lenPopularKeywords);
  //     }
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, [keywordIdx]);

  return (
    <div className="flex justify-between items-center">
      <div>
        <span className="inline-block px-2 py-1 rounded-md text-white bg-brand-1 font-semibold">
          인기 검색어
        </span>
        <div className="inline-block keyword-box h-6 text-left mx-5">
          {popularKeywords && popularKeywords.length > 0 && (
            <a href={`/search/result?searchKeyword=${popularKeywords[keywordIdx].keyword}`}>
              <div className="inline-block mr-2">{keywordIdx + 1}</div>
              <div className="inline-block break-words">
                <b>{keywordShow(popularKeywords[keywordIdx].keyword)}</b>
              </div>
            </a>
          )}
        </div>
      </div>
      <button onClick={() => history.push('/search')} className="text-gray-500 text-14">
        더보기 +
      </button>
    </div>
  );
};
