import { api } from '../../../plugins/axios';
import { CreateNotificationParams } from './types';

export const getProductionByOS = async (os: string, queryString?: string) => {
  let result = await api.get(`/production?os=${os}`);
  let possibleStatus = [200, 201];
  if (possibleStatus.includes(result.status)) {
    return { success: true, data: result.data };
  } else {
    return { success: false };
  }
};
