import { useEffect, useState, useRef, useCallback } from 'react';
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import { parse, stringify } from 'qs';

import { toast } from 'react-toastify';

import { default as htmlReactParser, attributesToProps } from 'html-react-parser';

import { Section } from '../../../../../components/Section';
import { TopTitleBar } from './components/TopTitleBar';

import { CustomDrawer } from './CustomDrawer';

import { Form, Input, Button as AntdButton } from 'antd';
import { checkBooking } from '../../../../../api_v2/non_admin/booking';
import {
  cancelReservationForNonmemberV2,
  checkBookingV2,
  getCampingRefundPolicyBeforeReservationV2,
  requestResetBookingPasswordForNonmeberV2,
  resetBookingPasswordForNonmeberV2,
} from '../../../../../api_v2/non_admin/onda';
import dayjs from 'dayjs';
import { Button } from '../../../../../components/Button';
import { modalState } from '../../../../../ridge/ridge';

// testing
SwiperCore.use([Pagination, Autoplay]);

const erroMsgObj: { [key: string]: any } = {
  'Used link': '유효하지 않은 링크입니다.',
  'A expiry date is not valid': '유효하지 않은 링크입니다.',
  'A reservation does not exist': '예약정보가 존재하지않습니다',
};

export const ResetPasswordPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [form] = Form.useForm();
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const { channelBookingNo, uniqueId, encrypted } = useParams<{
    channelBookingNo: string;
    uniqueId: string;
    encrypted: string;
  }>();
  const [campingSite, setCampingSite] = useState<any>(null);
  const [campingSiteZone, setCampingSiteZone] = useState<any>(null);

  const [reservationData, setReservationData] = useState<any>(null);

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const { imp_uid, merchant_uid, imp_success, error_msg } = parsedSearch;

  const [drawerType, setDrawerType] = useState<any>(null);
  const [cancelReason, setCancelReason] = useState<string>('');

  const [resetStatus, setResetStatus] = useState<boolean>(false);

  const onFinish = (values: any) => {
    if (values['password']?.trim()?.length > 0) {
      setResetStatus(true);
      resetBookingPasswordForNonmeberV2({
        channelBookingNo,
        uniqueId,
        encrypted,
        password: values['password']?.trim(),
      })
        .then((res) => {
          if (res?.success == true && res?.data?.message == 'successfully reset password') {
            toast.success('비밀번호 변경이 완료되었습니다.');
          } else if (res?.success == false && res?.message) {
            toast.error(erroMsgObj[res?.message] ?? 'error');
          }
          // else if (res?.success == false) {
          //   toast.error('error');
          // }
          setResetStatus(false);
        })
        .catch((e) => {
          console.log('e', e);
          setResetStatus(false);
        });
    } else if (values['password']?.trim()?.length == 0) {
      toast.error('비밀번호를 확인해주세요.');
    }
  };

  return (
    <>
      <TopTitleBar logo={true} home={false} />
      <>
        <div className="max-w-md w-screen side-padding bg-[#FFFFFF]">
          <Section className="max-w-md py-3 mt-[100px] bg-[#FFFFFF]">
            <div className="flex flex-col justify-center items-center">
              <div className="text-[18px] text-center">
                <p>새로운 비밀번호를 입력해주세요</p>
              </div>
            </div>
          </Section>
          <Section className="max-w-md py-3 mt-[40px] bg-[#FFFFFF]">
            <Form form={form} name="dynamic_rule" onFinish={onFinish}>
              <div className="grid grid-cols-3 mb-3">
                <Form.Item
                  className="col-span-3 m-0"
                  name="password"
                  rules={[{ required: true, message: '비밀번호를 입력해주세요' }]}
                >
                  <Input.Password style={{ maxHeight: '35px' }} />
                </Form.Item>
              </div>

              <div className={`bg-white z-20 w-full max-w-md flex space-x-2 px-4 mt-2 h-16`}>
                <Form.Item noStyle>
                  <AntdButton
                    // type="text"
                    id="booking-page-button"
                    className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                    flex-1 flex justify-center items-center"
                    htmlType="submit"
                    disabled={resetStatus}
                  >
                    {resetStatus ? (
                      <>
                        <div className="loading-dots-type1"></div>
                      </>
                    ) : (
                      '재설정 하기'
                    )}
                  </AntdButton>
                </Form.Item>
              </div>
            </Form>
          </Section>
          <Link
            className="flex justify-center items-center text-[#5986f7]"
            to="/v2/camping/reservation/check-w-on"
            // id="booking-page-button"
            // className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
            //         flex-1 flex justify-center items-center"
          >
            예약 조회
          </Link>
        </div>
      </>
    </>
  );
};
