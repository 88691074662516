import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ProductCard } from '../components/ProductCard';
import { TopNavBar } from '../components/TopNavBar';

import { modalState } from '../ridge/ridge';
import { removeBookmarkedProduct } from '../api_v2/non_admin/bookmarks';

import { getProducts } from '../api_v2/non_admin/products';

export enum FilterType {
  TYPE_0 = 'PRODUCT_WISHLIST',
  TYPE_1 = 'PRODUCTS',
}

export const BookmarkPage = () => {
  const [productsPage, setProductsPage] = useState<number>(1);
  const [products, setProducts] = useState<{ [key: string]: any }>({});
  const [productsCount, setProductsCount] = useState(0);
  const [productsLoading, setProductsLoading] = useState(false);

  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    let localStorageWishList = localStorage.getItem('wishList');
    if (localStorageWishList) {
      let parsedLocalStorageWishList = JSON.parse(localStorageWishList);
      if (Array.isArray(parsedLocalStorageWishList) && parsedLocalStorageWishList.length > 0) {
        setProductsCount(parsedLocalStorageWishList.length);
        let slicedParsedLocalStorageWishList = parsedLocalStorageWishList.slice(
          (productsPage - 1) * 5,
          (productsPage - 1) * 5 + 5
        );
        // let productsQueryString = stringify(
        //   {
        //     page: productsPage,
        //     limit: 5,
        //     filter: {
        //       productId: parsedLocalStorageWishList,
        //     },
        //     sort: { id: 'DESC' },
        //   },
        //   { addQueryPrefix: true, encode: false }
        // );
        if (slicedParsedLocalStorageWishList.length > 0) {
          setProductsLoading(true);

          getProducts(slicedParsedLocalStorageWishList.join(',')).then((resultData) => {
            // getProducts(productsQueryString).then((resultData) => {
            if (resultData?.success) {
              // setProducts((prev) => {
              //   return { ...prev, [productsPage]: resultData.data };
              // });
              // setProductsCount(resultData.data.total);
              Promise.all([
                setProducts((prev) => {
                  return { ...prev, [productsPage]: resultData.data };
                }),
                // setProductsCount(resultData.data.total),
              ]).then(() => {
                setProductsLoading(false);
              });
            } else {
              // toast.error('Something went wrong getting products');
            }
          });
        }
      } else {
        console.log('BookmarkPage error');
      }
    }
  }, [productsPage]);

  const _productsLength = (currObj: any) => {
    let baseNumber = 0;
    Object.keys(currObj).forEach((key) => {
      baseNumber = baseNumber + currObj[key].length;
    });
    return baseNumber;
  };

  const lastElement = useCallback(
    (target) => {
      if (productsLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            _productsLength(products) < productsCount &&
            !productsLoading
          ) {
            setProductsPage((prev) => prev + 1);
          } else {
          }
        },
        {
          threshold: 1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [productsLoading, products, productsCount]
  );

  const filterFunc = (receivedObj: any, setState: any, productId: string) => {
    let parentInd: number;
    let childInd: number;
    let changeIndices = (firstInd: number, secondInd: number) => {
      parentInd = firstInd;
      childInd = secondInd;
    };

    loop1: for (let i = 0; i < Object.keys(receivedObj).length; i++) {
      let key = Object.keys(receivedObj)[i];
      for (let j = 0; j < receivedObj[key].length; j++) {
        if (receivedObj[key][j].productId === productId) {
          changeIndices(i, j);
          break loop1;
        }
      }
    }
    setState((prev: any) => {
      return {
        ...prev,
        [parentInd + 1]: receivedObj[parentInd + 1].filter(
          (item: any, index: number) => index !== childInd
        ),
      };
    });
  };

  const removeBookmarked = async (productId: string) => {
    const onClose = () => modalState.set({ ...modalState.get(), open: false });
    modalState.set({
      title: '보관함 삭제',
      subtitle: '해당 상품을 보관함에서 삭제하시겠습니까?',
      open: true,
      onClose,
      onClick: async () => {
        let removeBookmarkedProductResult = await removeBookmarkedProduct(productId);
        if (removeBookmarkedProductResult?.success) {
          [{ obj: products, setState: setProducts }].forEach((item) => {
            filterFunc(item.obj, item.setState, productId);
          });
        }
        onClose();
      },
    });
  };

  return (
    <div className="bottom-padding">
      <TopNavBar logo={false} nav={false} search={false} title="찜한 상품" />
      <div className="grid grid-cols-2 gap-4 side-padding">
        {Object.keys(products).length > 0 &&
          Object.keys(products).map(
            (group, groupInd) =>
              products[group] &&
              products[group].map((product: any, productInd: number) => (
                <>
                  {Object.keys(products).length === groupInd + 1 &&
                  products[group].length === productInd + 1 ? (
                    <div ref={lastElement}>
                      <ProductCard
                        key={product['id']}
                        product={product}
                        to={`/products/${product['productId']}`}
                        className="shadow-none"
                        bookmark={true}
                        removeBookmarked={removeBookmarked}
                        bookMarkedInfo={{ groupInd, productInd }}
                      />
                    </div>
                  ) : (
                    <>
                      <ProductCard
                        key={product['id']}
                        product={product}
                        to={`/products/${product['productId']}`}
                        className="shadow-none"
                        bookmark={true}
                        removeBookmarked={removeBookmarked}
                        bookMarkedInfo={{ groupInd, productInd }}
                      />
                    </>
                  )}
                </>
              ))
          )}
      </div>
    </div>
  );
};
