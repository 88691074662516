import { Button, Form, Input, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { parse, stringify } from 'qs';

import { Select as AntdSelect, Switch as AntdSwitch } from 'antd';
import 'antd/dist/antd.css';

import { Section } from '../../../../../components/Section';
import { Calendar } from './Calendar';
import { Icon } from '../../../../../components/Icon';

import {
  getConveyanceInfo,
  getMyConveyanceInfo,
  updateConveyanceInfo,
  updateMyConveyanceInfo,
} from '../../../../../api_v2/non_admin/conveyance/index';

import './style.css';
import { CustomDrawer } from './CustomDrawer';
import { AddGocampingInfo } from './AddGocampingInfo';
import { TopTitleBar } from './components/TopTitleBar';
import { authUserTokenObjState, meState } from '../../../../../ridge/ridge';

const { Option: AntdOption } = AntdSelect;

enum ConveyancePriceType {
  REGULAR = 'REGULAR',
  DISCOUNTED = 'DISCOUNTED',
  PREMIUM = 'PREMIUM',
}

const conveyancePriceTypeObj: { [key: string]: any } = {
  REGULAR: '정가',
  DISCOUNTED: '할인',
  PREMIUM: '프리미엄',
};

const conveyancePriceTypeObjForApi: { [key: string]: any } = {
  REGULAR: 'regular',
  DISCOUNTED: 'discount',
  PREMIUM: 'premium',
};

const conveyancePriceTypeObjFromApi: { [key: string]: any } = {
  regular: ConveyancePriceType.REGULAR,
  discount: ConveyancePriceType.DISCOUNTED,
  premium: ConveyancePriceType.PREMIUM,
};

export const EditConveyanceInfoForMember = (props: any) => {
  const me = meState.useValue();
  const authUserTokenObj = authUserTokenObjState.useValue();

  const { conveyanceId } = useParams<{ conveyanceId: string }>();

  const drawerRef = useRef<HTMLDivElement | null>(null);
  const [form] = Form.useForm();
  const history = useHistory();

  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [conveyanceInfo, setConveyanceInfo] = useState<{ [key: string]: any } | null>(null);

  const [calendarState, setCalendarState] = useState<any>([
    conveyanceInfo?.checkinDate && conveyanceInfo?.checkoutDate
      ? {
          startDate: new Date(conveyanceInfo?.checkinDate as string),
          endDate: new Date(conveyanceInfo?.checkoutDate as string),
          key: 'selection',
        }
      : {
          startDate: null,
          endDate: new Date(''),
          key: 'selection',
        },
  ]);

  const [locale, setLocale] = useState<any>('ko');

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const [editConveyanceInfoStatus, setEditConveyanceInfoStatus] = useState<any>(false);

  const [chosenGocampingInfo, setChosenGocampingInfo] = useState<any | null>(null);

  const [conveyancePriceType, setConveyancePriceType] = useState(ConveyancePriceType.REGULAR);
  const conveyancePriceTypes: any[] = [
    ConveyancePriceType.REGULAR,
    ConveyancePriceType.DISCOUNTED,
    ConveyancePriceType.PREMIUM,
  ];

  const handleConveyancePriceType = (conveyancePriceTypeStr: any) => {
    setConveyancePriceType(conveyancePriceTypeStr);
  };

  useEffect(() => {
    getMyConveyanceInfo(conveyanceId, '').then((res) => {
      if (res?.success == true && res?.data) {
        setConveyanceInfo(res?.data ?? null);
      }
    });
  }, []);

  useEffect(() => {
    if (conveyanceInfo) {
      const {
        checkinDate,
        checkoutDate,
        description,
        email,
        // gocampingName,
        // gocampingRegion,
        nickname,
        status,
        tel,
        transferAmount,
        gocamping,
        zoneName,
        siteName,
        amountTag,
      } = conveyanceInfo;

      setCalendarState([
        checkinDate && checkoutDate
          ? {
              startDate: new Date(checkinDate as string),
              endDate: new Date(checkoutDate as string),
              key: 'selection',
            }
          : {
              startDate: null,
              endDate: new Date(''),
              key: 'selection',
            },
      ]);

      if (gocamping) {
        setChosenGocampingInfo(gocamping);
      }

      if (amountTag) {
        setConveyancePriceType(conveyancePriceTypeObjFromApi[amountTag]);
      }

      let prevDataObj: { [key: string]: any } = {
        price: transferAmount,

        nickname: nickname,
        email: email,
        phone: tel,
        'other-info': description,
        'conveyanceinfo-switch1': status,
        'zone-name': zoneName,
        'site-name': siteName,
      };

      form.setFieldsValue(prevDataObj);
    }
  }, [conveyanceInfo]);

  const checkPhoneNumb = (_: any, value: any) => {
    let flag = true;
    if (value?.trim().length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (isNaN(parseInt(value[i]))) {
          flag = false;
          break;
        }
      }
    } else {
      flag = false;
    }

    if (flag) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('전화번호를 입력해주세요. 예) 01012345678'));
  };

  const convertDateForQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const onFinish = async (values: any) => {
    if (calendarState[0]?.startDate && calendarState[0]?.endDate) {
      if (typeof values?.price !== 'number') {
        toast.error('양도비용을 입력해주세요');
      } else if (!chosenGocampingInfo || !chosenGocampingInfo?.id) {
        toast.error('캠핑장을 선택해주세요');
      } else if (!conveyancePriceType) {
        toast.error('양도비용 타입을 선택해주세요');
      } else {
        let checkRequiredObj: { [key: string]: any } = {
          nickname: '닉네임',
          email: '이메일',
          phone: '전화번호',
          // 'campinginfo-name': '캠핑장명',
        };
        let checkRequiredProblem = false;
        for (let i = 0; i < Object.keys(checkRequiredObj).length; i++) {
          let key = Object.keys(checkRequiredObj)[i];
          if (values[key]?.trim()?.length > 0) {
            continue;
          } else {
            checkRequiredProblem = true;
            toast.error(checkRequiredObj[key] + '을/를 입력해주세요');
            break;
          }
        }
        if (!checkRequiredProblem) {
          setEditConveyanceInfoStatus(true);
          const { nickname, email, phone, price } = values;

          let dataToSend: { [key: string]: any } = {
            nickname: nickname ?? '',
            transferAmount: price,
            checkinDate: convertDateForQS(calendarState[0]?.startDate),
            checkoutDate: convertDateForQS(calendarState[0]?.endDate),
            tel: phone ?? '',
            email: email ?? '',
            description: values['other-info'] ?? '',
            status: values['conveyanceinfo-switch1'],
            // password: props.chosenPassword,
            zoneName: values['zone-name'] ?? '',
            siteName: values['site-name'] ?? '',
            gocampingId: chosenGocampingInfo?.id ?? null,
            amountTag: conveyancePriceTypeObjForApi[conveyancePriceType],
          };

          if (chosenGocampingInfo) {
            dataToSend.gocampingId = chosenGocampingInfo?.id ?? null;
          }

          if (authUserTokenObj && me?.userInfo?.user_id == conveyanceInfo?.userId) {
            let updateConveyanceInfoRes = await updateMyConveyanceInfo(
              conveyanceInfo?.id,
              dataToSend
            );

            if (updateConveyanceInfoRes?.success == true) {
              form.resetFields();
              toast.success('성공적으로 양도정보를 수정하였습니다.');
              // props.setCheckPasswordStatus(false);
              history.push(`/v2/camping/conveyance/conveyanceinfo/${conveyanceInfo?.id}`);
            } else if (
              updateConveyanceInfoRes?.success == false &&
              updateConveyanceInfoRes?.message
            ) {
              toast.error(updateConveyanceInfoRes?.message ?? '');
            }
          }
        }
        setEditConveyanceInfoStatus(false);
      }
    } else {
      toast('일정을 선택해주세요');
    }
  };

  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '100%';
    }
  };

  return (
    <>
      <div>
        <TopTitleBar userAuthIcon={true} logo={true} home={false} />
        {authUserTokenObj && me?.userInfo?.user_id == conveyanceInfo?.userId ? (
          <>
            <div className="bg-gray-100 w-full h-2" />
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <p className="side-padding font-[700] text-[23px]">양도 정보를 수정해주세요</p>
            </Section>
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <div className="side-padding">
                <div className="font-[700] text-[15px]">일정을 선택해주세요</div>
                <Calendar
                  locale={locale}
                  state={calendarState}
                  setState={setCalendarState}
                  setChosenDates={setChosenDates}
                  confirmButton={false}
                  // drawerRef={drawerRef}
                />
              </div>
            </Section>
            <div className="bg-gray-100 w-full h-2" />
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <Form form={form} name="dynamic_rule" onFinish={onFinish}>
                <div className="side-padding mb-3">
                  <div className="flex items-center mb-3">
                    <div className="font-[700] text-[15px] mr-3">양도정보를 입력해주세요</div>
                    <p className="text-[#FF6400] text-[12px]">*필수입력</p>
                  </div>

                  <div className="grid grid-cols-3 mb-1">
                    <div className="text-[13px]">
                      양도 완료<span className="text-[#FF6400]">*</span>
                    </div>
                    <Form.Item
                      className="col-span-2 m-0"
                      name="conveyanceinfo-switch1"
                      valuePropName="checked"
                    >
                      <AntdSwitch />
                    </Form.Item>
                  </div>

                  {/* <div className="grid grid-cols-3 mb-1">
                <div className="text-[13px]">
                  캠핑장명<span className="text-[#FF6400]">*</span>
                </div>

                <Form.Item
                  className="col-span-2 m-0"
                  name="campinginfo-name"
                  rules={[
                    {
                      required: true,
                      message: '캠핑장명을 입력해주세요',
                    },
                  ]}
                >
                  <Input style={{ maxHeight: '30px' }} />
                </Form.Item>
              </div> */}

                  <div className="grid grid-cols-3 mb-1 place-items-start">
                    <div
                      className="text-[13px] border p-1 mr-2 flex justify-center cursor-pointer"
                      onClick={() => openDrawer()}
                    >
                      캠핑장선택<span className="text-[#FF6400]">*</span>
                    </div>
                    <div className="place-self-stretch flex items-center">
                      {chosenGocampingInfo ? <>{chosenGocampingInfo?.facilityName}</> : null}
                    </div>
                  </div>
                  <div className="w-[70%]">
                    {/* <div className="grid grid-cols-1 mb-1"> */}
                    <div className="col-span-1 grid grid-cols-3 gap-2 w-full mb-1">
                      {conveyancePriceTypes.map((cpt) => (
                        <>
                          <div
                            key={cpt}
                            onClick={() => handleConveyancePriceType(cpt)}
                            className={`cursor-pointer border flex justify-center items-center ${
                              conveyancePriceType == cpt
                                ? 'bg-[#FF6400] text-[white]'
                                : 'bg-[white] text-[black]'
                            }`}
                          >
                            {conveyancePriceTypeObj[cpt]}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="grid grid-cols-3 mb-1">
                    <div className="text-[13px]">
                      양도비용<span className="text-[#FF6400]">*</span>
                    </div>
                    <Form.Item
                      className="col-span-2 m-0"
                      name="price"
                      rules={[{ required: true, message: '양도비용을 입력해주세요' }]}
                    >
                      <InputNumber min={0} style={{ maxHeight: '30px' }} />
                    </Form.Item>
                  </div>
                  <div className="grid grid-cols-3 mb-1">
                    <div className="text-[13px]">구역명</div>
                    <Form.Item
                      className="col-span-2 m-0"
                      name="zone-name"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: '닉네임을 입력해주세요',
                      //   },
                      // ]}
                    >
                      <Input style={{ maxHeight: '30px' }} />
                    </Form.Item>
                  </div>
                  <div className="grid grid-cols-3 mb-1">
                    <div className="text-[13px]">사이트명</div>
                    <Form.Item
                      className="col-span-2 m-0"
                      name="site-name"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: '닉네임을 입력해주세요',
                      //   },
                      // ]}
                    >
                      <Input style={{ maxHeight: '30px' }} />
                    </Form.Item>
                  </div>
                </div>

                <div className="bg-gray-100 w-full h-2" />
                <div className="side-padding">
                  <div className="flex items-center my-3">
                    <div className="font-[700] text-[15px] mr-3">기본정보를 입력해주세요</div>
                    <p className="text-[#FF6400] text-[12px]">*필수입력</p>
                  </div>
                  <div className="grid grid-cols-3 mb-1">
                    <div className="text-[13px]">
                      닉네임<span className="text-[#FF6400]">*</span>
                    </div>
                    <Form.Item
                      className="col-span-2 m-0"
                      name="nickname"
                      rules={[
                        {
                          required: true,
                          message: '닉네임을 입력해주세요',
                        },
                      ]}
                    >
                      <Input style={{ maxHeight: '30px' }} />
                    </Form.Item>
                  </div>
                  <div className="grid grid-cols-3 mb-1">
                    <div className="text-[13px]">
                      이메일<span className="text-[#FF6400]">*</span>
                    </div>
                    <Form.Item
                      className="col-span-2 m-0"
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: '이메일을 입력해주세요',
                        },
                        {
                          required: true,
                          message: '이메일을 입력해주세요',
                        },
                      ]}
                    >
                      <Input style={{ maxHeight: '30px' }} />
                    </Form.Item>
                  </div>
                  <div className="grid grid-cols-3 mb-1">
                    <div className="text-[13px]">
                      전화번호<span className="text-[#FF6400]">*</span>
                    </div>
                    <Form.Item
                      className="col-span-2 m-0"
                      name="phone"
                      rules={[
                        // {
                        //   required: true,
                        //   message: '전화번호를 입력해주세요',
                        // },
                        {
                          validator: checkPhoneNumb,
                          message: '전화번호를 입력해주세요. 예) 01012345678',
                        },
                      ]}
                    >
                      <Input style={{ maxHeight: '30px' }} placeholder="예) 01012345678" />
                    </Form.Item>
                  </div>

                  <div className="grid grid-cols-3">
                    <div className="text-[13px]">
                      특이사항<span className="text-[#FF6400]"></span>
                    </div>
                    <Form.Item className="col-span-2 m-0" name="other-info">
                      <TextArea rows={5} />
                    </Form.Item>
                  </div>
                </div>

                <div
                  className={`bg-white z-20 w-full max-w-md 
                flex space-x-2 px-4 mt-5 h-16 flex justify-center items-center`}
                >
                  <Form.Item noStyle>
                    <Button
                      // type="text"
                      id="booking-page-button"
                      className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                    flex-1 flex justify-center items-center"
                      htmlType="submit"
                      disabled={editConveyanceInfoStatus}
                    >
                      수정
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Section>
          </>
        ) : (
          <div className="flex justify-center items-center h-screen">권한이 없습니다</div>
        )}
      </div>
      <div className="absolute bottom-0 left-0">
        <CustomDrawer drawerRef={drawerRef} confirmButton={false}>
          <div className="mt-[70px]">
            <AddGocampingInfo
              setChosenGocampingInfo={setChosenGocampingInfo}
              drawerRef={drawerRef}
            />
          </div>
        </CustomDrawer>
      </div>
    </>
  );
};
