import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../../components/Button';
import { Card } from '../../../../../../components/Card';
import { Select } from '../../../../../../components/Select';
import { TextField } from '../../../../../../components/TextField';
import { AdminH1 } from '../../../../../components/AdminH1';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { map } from 'lodash';

import { Toggle } from '../../../../../../components/Toggle';
import {
  deleteTDCAccommCampaign,
  getTDCAccommCampaign,
  getTDCAccommodations,
  updateTDCAccommCampaign,
} from '../../../../../../api_v2/admin/community/tdc-accommodations';
import { randomUUID } from 'crypto';
import { parse } from 'qs';
import { Icon } from '../../../../../../components/Icon';
// import { BannerText, BannerType } from './repeatCode';

export const TDCAccommCampaignDetail = () => {
  const history = useHistory();
  const { campaignId } = useParams<{ campaignId: string }>();

  const [formState, setFormState] = useState<any>({
    isVisible: false,

    source: null,
    name: null,
    link: null,
    endDate: null,
  });

  const [updateBannerStatus, setUpdateBannerStatus] = useState<boolean>(false);
  const [deleteBannerStatus, setDeleteBannerStatus] = useState<boolean>(false);

  const [tdcAccommCampaign, setTdcAccommCampaign] = useState<any>(null);

  const [caJoinList, setCaJoinList] = useState<any>([]);
  const [deleteCaJoinList, setDeleteCaJoinList] = useState<any>([]);

  useEffect(() => {
    getTDCAccommCampaign(+campaignId).then((resultData: any) => {
      if (resultData?.success) {
        if (resultData?.data) {
          const { isVisible, source, name, link, endDate, accommCampaignToAccomms } =
            resultData.data;
          setTdcAccommCampaign(resultData?.data ?? null);

          if (accommCampaignToAccomms?.length > 0) {
            let tempList = accommCampaignToAccomms?.map((joinObj: any) => {
              return {
                accommCampaignToAccommId: joinObj?.id,
                accommId: joinObj?.accomm?.id,
                accommName: joinObj?.accomm?.name,
              };
            });
            setCaJoinList((prev: any) => {
              return [...prev, ...tempList];
            });
          }

          setFormState({
            isVisible,

            source,
            name,
            link,
            endDate: formatDatetime(endDate),
          });
        }
      }
    });
  }, []);

  const _onChangeText = (e: any) => {
    const { name, value } = e.target;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: name === 'weight' ? parseInt(value) : value,
    }));
  };

  const _onChangeType = (e: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      type: e.target.value,
    }));
  };

  const _handleUpdateButton = async () => {
    setUpdateBannerStatus(true);

    let updateBannerResult = await updateTDCAccommCampaign(parseInt(campaignId), {
      ...formState,
      caJoinList,
      deleteCaJoinList,
    });
    if (updateBannerResult) {
      toast.success('성공적으로 수정하였습니다');
      setUpdateBannerStatus(false);
    } else {
      toast.error('Something went wrong updating a banner');
    }
    // }
  };

  const _handleDeleteButton = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      setDeleteBannerStatus(true);
      let deleteBannerStatus = await deleteTDCAccommCampaign(parseInt(campaignId));
      if (deleteBannerStatus) {
        toast.success('성공적으로 삭제하였습니다');
        setDeleteBannerStatus(false);
        history.push('/admin/community/tdc-accommodation/campaigns');
      } else {
        toast.error('Something went wrong deleting a banner');
      }
    }
  };

  const formatDatetime = (inputDateString: any) => {
    try {
      const inputDate = new Date(inputDateString);
      const formattedString = inputDate.toISOString().slice(0, -5);

      return formattedString;
    } catch (e) {}
  };

  const addObject = (objData: any) => {
    try {
      let found = caJoinList.find((item: any) => item?.accommId == objData?.id);

      if (!found) {
        setCaJoinList((prevList: any) => [
          ...prevList,
          { accommId: objData?.id, accommName: objData?.name },
        ]);
      }
    } catch (e) {}
  };

  const deleteObject = (dataObj: any) => {
    if (dataObj?.accommCampaignToAccommId) {
      setDeleteCaJoinList((prev: any) => {
        return [...prev, dataObj];
      });
    }

    setCaJoinList((prevList: any) =>
      prevList.filter((obj: any) => obj.accommId !== dataObj?.accommId)
    );
  };

  const { search } = useLocation();

  const [currAccomms, setCurrAccomms] = useState([]);

  const handleSearch = async () => {
    try {
      // let path = `?page=${1}&limit=${10}`;
      // if (tdcAccommSearchInput && typeof tdcAccommSearchInput == 'string') {
      //   path = path + `&q=${tdcAccommSearchInput?.trim() ?? ''}`;
      // }
      // window.location.href = path;
      let path = `?page=${1}&limit=${20}`;
      if (tdcAccommSearchInput && typeof tdcAccommSearchInput == 'string') {
        path = path + `&q=${tdcAccommSearchInput?.trim() ?? ''}`;
      }
      getTDCAccommodations(path).then((resultData: any) => {
        if (resultData?.success) {
          if (resultData.data) {
            if (resultData.data[0]) {
              setCurrAccomms(resultData.data[0]);
              // setTotalAccommsCount(resultData.data[1]);
            }
          }
        }
      });
    } catch (e) {}
  };

  const [tdcAccommSearchInput, setTdcAccommSearchInput] = useState('');

  const handleTDCAccommSearchInput = (e: any) => {
    setTdcAccommSearchInput(e.target.value);
  };

  const handleTDCAccommSearchButton = (evt: any) => {
    handleSearch();
  };

  const handleTDCAccommSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      handleSearch();
    }
  };

  const handleResetAccommSearchResult = () => {
    try {
      setCurrAccomms([]);
    } catch (e) {}
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>트립닷컴숙소캠페인 상세</AdminH1>
      </div>
      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-start-1 col-span-2">
            {['id', 'uniqueId'].map((item) => (
              <div className="flex space-x-3">
                <p className="font-[700]"> {item}:</p>
                <p>{tdcAccommCampaign && tdcAccommCampaign[item]}</p>
              </div>
            ))}
            <div className="flex space-x-3">
              <p className="font-[700]">진열</p>
              <Toggle
                checked={formState.isVisible}
                onChange={(e) =>
                  setFormState((prevState: any) => ({
                    ...prevState,
                    isVisible: e,
                  }))
                }
              />
            </div>

            {['source', 'name', 'link', 'endDate'].map((item) => (
              <div key={item}>
                <p className="font-[700]">{item + ':'}</p>
                <TextField name={item} value={formState[item]} onChange={_onChangeText} />
                {item == 'endDate' ? <p>예: 2023-05-15T02:05:05</p> : <></>}
              </div>
            ))}
          </div>
          <div className="col-start-1 col-span-2">
            <div className="flex gap-[10px] items-center mb-[10px]">
              <h2>숙소 리스트</h2>
            </div>
            <div
              className="flex flex-row-reverse w-full max-w-[500px] items-center px-4 h-12 mt-2
                border border-[black] bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
            >
              <Button
                className="bg-[#FF6400] text-[#FFFFFF]"
                style={{ height: '80%' }}
                onClick={(e) => handleTDCAccommSearchButton(e)}
              >
                검색
              </Button>
              {tdcAccommSearchInput ? (
                <>
                  <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                    <Icon.X
                      className="wh-3"
                      onClick={() => {
                        setTdcAccommSearchInput('');
                      }}
                    />
                  </div>
                </>
              ) : null}
              <input
                className="flex-1 text-sm bg-white"
                placeholder="검색하기"
                onChange={(e: any) => handleTDCAccommSearchInput(e)}
                value={tdcAccommSearchInput as string}
                onKeyDown={(e) => handleTDCAccommSearchKeyDown(e)}
              />
            </div>
            <div className="mb-[20px]">
              {currAccomms?.length > 0 ? (
                <>
                  <div
                    className="border-[1px] p-[10px] w-fit flex justify-end mb-[5px] cursor-pointer"
                    onClick={() => {
                      handleResetAccommSearchResult();
                    }}
                  >
                    검색결과리셋
                  </div>
                  {currAccomms?.map((accommObj: any) => (
                    <div
                      key={accommObj?.id}
                      className="flex justify-between border-[1px] p-[15px] mb-[5px]"
                    >
                      <div>
                        <p>accommId: {accommObj?.id}</p>
                        <p>accommName: {accommObj?.name}</p>
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          addObject(accommObj);
                        }}
                      >
                        add to list
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </div>

            <div className="w-full flex justify-between items-center">
              <div className="flex items-center gap-[10px]"></div>
            </div>

            <div className="flex gap-[10px] flex-wrap">
              {caJoinList.map((obj: any) => (
                <div key={obj.accommId} className="flex gap-[5px] border-[1px] p-[10px]">
                  <div>
                    <p>accommCampaignToAccommId: {obj?.accommCampaignToAccommId ?? '------'}</p>
                    <p>accommId: {obj.accommId}</p>
                    <p>accommName: {obj.accommName}</p>
                  </div>
                  <button onClick={() => deleteObject(obj)}>X</button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            onClick={() => _handleDeleteButton()}
            text="삭제하기"
            className="h-10 text-sm outlined-red-600 hover:bg-gray-50"
            disabled={updateBannerStatus || deleteBannerStatus}
          />
          <Button
            text="수정하기"
            className="h-10 text-sm -black"
            onClick={() => _handleUpdateButton()}
            disabled={updateBannerStatus || deleteBannerStatus}
          />
        </div>
      </Card>
    </>
  );
};
