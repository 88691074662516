import { Button } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TopTitleBar } from './components/TopTitleBar';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

declare global {
  interface Window {
    kakao: any;
  }
}

export default function ChooseSpForNearby() {
  const [chosenStartingPlace, setChosenStartingPlace] = useState(null);
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (process.env.REACT_APP_KAKAO_DEVELOPERS_JAVASCRIPT_KEY) {
      if (window?.Kakao?.isInitialized() == false) {
        window?.Kakao?.init(process.env.REACT_APP_KAKAO_DEVELOPERS_JAVASCRIPT_KEY);
        console.log('kakao init');
      }

      let kakaoMapScriptEle = document.createElement('script') as any;
      kakaoMapScriptEle.type = 'text/javascript';
      kakaoMapScriptEle.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_DEVELOPERS_JAVASCRIPT_KEY}&autoload=false`;

      document.head.appendChild(kakaoMapScriptEle);

      let kakaoMapServicesScriptEle = document.createElement('script') as any;
      kakaoMapServicesScriptEle.type = 'text/javascript';
      kakaoMapServicesScriptEle.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_DEVELOPERS_JAVASCRIPT_KEY}&libraries=services&autoload=false`;

      document.head.appendChild(kakaoMapServicesScriptEle);

      const loadKakaoMapScript = () => {
        window.kakao.maps.load(() => {
          let kakaoMapContainer = document.querySelector('.kakaoMap');
          let options = {
            center: new window.kakao.maps.LatLng(33.450701, 126.570667),
            level: 3,
          };
          let map = new window.kakao.maps.Map(kakaoMapContainer, options);
          let ps = new window.kakao.maps.services.Places();
          let infowindow = new window.kakao.maps.InfoWindow({ zIndex: 1 });
          const searchPlaces = () => {
            let keyword = (document.getElementById('searchPlaceInput') as any).value;
            if (!keyword.replace(/^\s+|\s+$/g, '')) {
              alert('키워드를 입력해주세요!');
              return false;
            }
            ps.keywordSearch(keyword, placesSearchCB);
          };
          let searchButton = document.querySelector('.search-button') as any;
          searchButton.onclick = () => {
            searchPlaces();
          };
          let searchPlaceInputEle = document.getElementById('searchPlaceInput') as any;
          if (searchPlaceInputEle) {
            searchPlaceInputEle.addEventListener('keydown', (evt: any) => {
              try {
                if (evt.key === 'Enter') {
                  searchPlaces();
                }
              } catch (e) {}
            });
          }
          function placesSearchCB(data: any, status: any, pagination: any) {
            if (status === window.kakao.maps.services.Status.OK) {
              displayPlaces(data);

              displayPagination(pagination);
            } else if (status === window.kakao.maps.services.Status.ZERO_RESULT) {
              alert('검색 결과가 존재하지 않습니다.');
              return;
            } else if (status === window.kakao.maps.services.Status.ERROR) {
              alert('검색 결과 중 오류가 발생했습니다.');
              return;
            }
          }
          function displayPlaces(places: any) {
            let listEl = document.querySelector('.kakaoMenuWrap .placesList') as any,
              menuEl = document.querySelector('.kakaoMenuWrap') as any,
              fragment = document.createDocumentFragment(),
              bounds = new window.kakao.maps.LatLngBounds(),
              listStr = '';

            removeAllChildNods(listEl);

            for (let i = 0; i < places.length; i++) {
              let placePosition = new window.kakao.maps.LatLng(places[i].y, places[i].x),
                itemEl = getListItem(i, places[i]);

              bounds.extend(placePosition);

              fragment.appendChild(itemEl);
            }

            listEl.appendChild(fragment);
            menuEl.scrollTop = 0;

            map.setBounds(bounds);
          }

          function getListItem(index: any, places: any) {
            let el = document.createElement('li'),
              itemStr =
                '<span class="markerbg marker_' +
                (index + 1) +
                '"></span>' +
                '<div class="info" style="border: 1px solid #d9d9d9; padding: 10px; border-radius: 10px; margin-bottom: 12px; background-color: #f4fffc; cursor: pointer">' +
                '   <p style="font-size: 16px; font-weight: 700">' +
                places.place_name +
                '</p>';

            if (places.road_address_name) {
              itemStr +=
                '    <span>' +
                places.road_address_name +
                '</span>' +
                '   <div class="jibun gray">' +
                '(' +
                places.address_name +
                ')' +
                '</div>';
            } else {
              itemStr += '    <span>' + places.address_name + '</span>';
            }

            itemStr += '  <div class="tel">' + places.phone + '</div>' + '</div>';

            el.innerHTML = itemStr;
            el.className = 'item';
            el.onclick = () => {
              setChosenStartingPlace(places);

              window.location.href = `/community/vk-leports/find-nearby-w-sp?type=${
                parsed?.type ?? ''
              }&placeName=${places?.place_name}&lng=${places?.x}&lat=${places?.y}`;
            };
            return el;
          }

          function removeAllChildNods(el: any) {
            while (el.hasChildNodes()) {
              el.removeChild(el.lastChild);
            }
          }

          function displayPagination(pagination: any) {
            let paginationEl = document.querySelector('.kakaoMenuWrap .pagination') as any,
              fragment = document.createDocumentFragment(),
              i;

            while (paginationEl.hasChildNodes()) {
              paginationEl.removeChild(paginationEl.lastChild);
            }

            for (i = 1; i <= pagination.last; i++) {
              let el = document.createElement('div') as any;

              el.innerHTML = i;
              el.style.border = '1px solid';
              el.style['border-radius'] = '5px';
              el.style['padding'] = '10px';
              el.style['cursor'] = 'pointer';
              el.style['font-weight'] = '900';

              if (i === pagination.current) {
                window.scrollTo(0, 0);
                el.className = 'on';
                el.style['background-color'] = '#8d6ac8';
                el.style['color'] = 'white';
              } else {
                el.style['background-color'] = '';
                el.style['color'] = 'black';
                el.onclick = (function (i) {
                  return function () {
                    window.scrollTo(0, 0);
                    pagination.gotoPage(i);
                  };
                })(i);
              }

              fragment.appendChild(el);
            }
            paginationEl.appendChild(fragment);
          }
        });
      };

      kakaoMapScriptEle.addEventListener('load', loadKakaoMapScript);
    } else {
      console.log('no kakao js key');
    }
  }, [window?.kakao]);

  const [searchPlaceValue, setSearchPlaceValue] = useState('');

  const handleSearchPlaceChange = (e: any) => {
    setSearchPlaceValue(e?.target?.value);
  };

  return (
    <div>
      <TopTitleBar backButton={true} home={false} logo={true} />
      {/* <div className="max-w-md w-screen bg-white side-padding"> */}
      <div className="w-screen sm:w-full md:w-full lg:w-full side-padding bg-white">
        <div
          className="kakaoMap"
          style={{ width: '500px', height: '400px', display: 'none' }}
        ></div>
        <div className="kakaoMenuWrap bg_white">
          <p className="text-center text-[15px] font-[700] mb-[5px]">
            출발장소를 검색 후 선택해주세요
          </p>
          <div className="flex gap-[20px] justify-center items-center mb-[25px]">
            <input
              id="searchPlaceInput"
              value={searchPlaceValue}
              onChange={handleSearchPlaceChange}
              className="border-[#d9d9d9] rounded-[7px] px-[10px] max-w-[80%]"
              style={{ border: '1px solid #d9d9d9', height: '50px', width: '200px' }}
              placeholder="출발장소를 검색해주세요"
            />
            <Button className="search-button rounded-[7px]">검색</Button>
          </div>

          <ul className="placesList"></ul>
          <div className="pagination flex justify-center items-center gap-[20px] my-[20px]"></div>
        </div>
      </div>
      {/* <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div> */}
    </div>
  );
}
