import { toast } from 'react-toastify';
import { api } from '../../../plugins/axios';
import { authUserTokenObjState } from '../../../ridge/ridge';
import { logout } from '../../non_admin/users';

export const loginByKakaoAuthorization = async (dataObj: any) => {
  const { accessCode, v2, projectForLogin } = dataObj;
  const resetProcess = () => {
    logout();
    window.location.href =
      projectForLogin == 'community' ? '/community' : v2 == true ? '/v2/camping' : '/camping';
  };
  let redirectUrl = localStorage.getItem('redirectPageUrl');
  localStorage.removeItem('redirectPageUrl');
  try {
    if (accessCode) {
      // let result = await api.get(apiForTempSol + `/auth/kakaoCallback?code=${accessCode}`);
      let result = await api.get(
        `/auth/kakaoCallback?code=${accessCode}&project=${
          projectForLogin == 'community' ? 'community' : ''
        }`
      );

      if (
        [200, 201].includes(result?.status) &&
        // result?.data[0]?.userInfo &&
        result?.headers?.authorization
      ) {
        authUserTokenObjState.set({
          // userInfo: result?.data[0]?.userInfo,
          loginType: 'kakao',
          token: result?.headers?.authorization,
        });

        if (redirectUrl == '/community/journey-log') {
          let saveCommunityJourneyLog = localStorage.getItem('saveCommunityJourneyLog');
          localStorage.removeItem('saveCommunityJourneyLog');
          let saveCommunityJourneyLogRes = await api.patch(
            '/community/journey-log',
            saveCommunityJourneyLog ? JSON.parse(saveCommunityJourneyLog) : {}
          );
          if ([200, 201].includes(saveCommunityJourneyLogRes?.status)) {
          } else {
            toast.error('오류가 발생하였습니다');
          }
        }

        window.location.href = redirectUrl
          ? redirectUrl
          : projectForLogin == 'community'
          ? '/community'
          : v2
          ? '/v2/camping'
          : '/camping';
      } else if ([200, 201].includes(result?.status) && result?.data?.message == 'user is null') {
        logout();
        window.location.href =
          projectForLogin == 'community' ? '/community/login' : v2 ? '/v2/login' : '/login-v2';
      } else {
        resetProcess();
      }
    } else {
      console.log('unknown error: no access code');
      resetProcess();
    }
  } catch (e: any) {
    console.log('e', e);
    resetProcess();
  }
};
