import { useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { Toggle } from '../../../components/Toggle';
import { Search } from '../../../components/Search';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { useHistory, useParams } from 'react-router-dom';
import useDebounce from '../../../hooks/common';
// import { DEBOUNCE_THRESHOLD_MS } from './constants';
import { find, map, uniq } from 'lodash';
import { format } from 'date-fns';
import FroalaEditor from './components/FroalaEditor';
import { stringify } from 'qs';
import { useProducts } from '../../../hooks/product';
import ExhibitionProductDnd from './components/ExhibitionProductDnd';
import { useMutation } from 'react-query';

import { toast } from 'react-toastify';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button as AntdButton,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select as AntdSelect,
  Slider,
  Switch as AntdSwitch,
  Upload,
  Input,
  TimePicker,
} from 'antd';
import 'antd/dist/antd.css';
import type { UploadFile } from 'antd/es/upload/interface';

import { uploadImage } from '../../../api/photo';
import { CreateExhibitionParams, ExhibitionParams } from '../../../api_v2/admin/exhibitions/types';
import { getProduct, getProducts } from '../../../api_v2/admin/products';
import { createExhibition } from '../../../api_v2/admin/exhibitions';
import { ImagesUpload } from './components/ImagesUpload';
import { api } from '../../../plugins/axios';

import './style.css';
import { Calendar } from './components/Calendar';
import { AntdCalendar } from './components/AntdCalendar';
import { createCampingZoneSite } from '../../../api_v2/admin/camping';

const { TextArea } = Input;

const { Option: AntdOption } = AntdSelect;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 50 },
};

export const CampingZoneSiteAdd = () => {
  const history = useHistory();
  const { campingId, zoneId } = useParams<{ campingId: string; zoneId: string }>();

  const editorContentRef = useRef('');
  const [editorContent, setEditorContent] = useState('');
  const [calendarState, setCalendarState] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [locale, setLocale] = useState<any>('ko');

  // const [editorImagesObj, setEditorImagesObj] = useState<{ [key: string]: any }>({});

  const editorImagesObjRef = useRef<{ [key: string]: any }>({});

  const [calMatchingObj, setCalMatchingObj] = useState<{ [key: string]: any }>({
    // 2022: {
    //   9: { 5: { available: 2, additionalFee: 1000 }, 6: { available: 4, additionalFee: 3000 } },
    //   10: { 1: { available: 3, additionalFee: 3000 }, 28: { available: 6, additionalFee: 6000 } },
    // },
  });
  const [changedCalObj, setChangedCalObj] = useState<{ [key: string]: any }>({});

  const [campingZoneSiteAddStatus, setCampingZoneSiteAddStatus] = useState(false);

  const onFinish = async (values: any) => {
    setCampingZoneSiteAddStatus(true);
    let dataToSend = {
      campingId: parseInt(campingId),
      campingZoneId: parseInt(zoneId),
      name: values['site-name'],
      siteWidth: 0,
      siteDepth: 0,
      status: true,
      isVisible: values['campinginfo-switch1'] == undefined ? false : values['campinginfo-switch1'],
    };

    let createCampingZoneSiteResult = await createCampingZoneSite(
      parseInt(campingId),
      parseInt(zoneId),
      dataToSend
    );
    // setCampingZoneSiteAddStatus(true);
    if (createCampingZoneSiteResult) {
      toast.success('성공적으로 캠핑장 사이트를 등록하였습니다.');
    }
    setCampingZoneSiteAddStatus(false);
  };

  const normFile = (e: any) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // is_visible

  return (
    <>
      <AdminH1>캠핑장 사이트 추가</AdminH1>
      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:px-8">
          <Form name="validate_other" {...formItemLayout} onFinish={onFinish}>
            <div>
              <Form.Item name="campinginfo-switch1" valuePropName="checked" label="진열상태">
                <AntdSwitch />
              </Form.Item>
              <Form.Item
                name="site-name"
                label="사이트 명"
                rules={[{ required: true, message: '사이트 명' }]}
              >
                <Input placeholder="사이트 명" />
              </Form.Item>
            </div>

            <Form.Item wrapperCol={{ offset: 18 }}>
              <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
                {campingZoneSiteAddStatus ? (
                  <div>사이트 등록중...</div>
                ) : (
                  <>
                    <Button
                      text="취소"
                      className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
                      to={`/admin/camping/${campingId}/zones/${zoneId}/sites`}
                      disabled={campingZoneSiteAddStatus}
                    />
                    <AntdButton
                      className="add-submit-button button md:max-w-md h-10 text-sm filled-black"
                      htmlType="submit"
                      disabled={campingZoneSiteAddStatus}
                    >
                      추가하기
                    </AntdButton>
                  </>
                )}
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </>
  );
};
