import { useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { Toggle } from '../../../components/Toggle';
import { Search } from '../../../components/Search';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { useHistory } from 'react-router-dom';
import useDebounce from '../../../hooks/common';
// import { DEBOUNCE_THRESHOLD_MS } from './constants';
import { find, map, uniq } from 'lodash';
import { format } from 'date-fns';
import FroalaEditor from './components/FroalaEditor';
import { stringify } from 'qs';
import { useProducts } from '../../../hooks/product';
import ExhibitionProductDnd from './components/ExhibitionProductDnd';
import { useMutation } from 'react-query';

import { toast } from 'react-toastify';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button as AntdButton,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select as AntdSelect,
  Slider,
  Switch as AntdSwitch,
  Upload,
  Input,
  TimePicker,
  DatePicker,
} from 'antd';
import 'antd/dist/antd.css';
import './style.css';

import { v4 as uuidv4 } from 'uuid';

import { uploadImage } from '../../../api/photo';
import { CreateExhibitionParams, ExhibitionParams } from '../../../api_v2/admin/exhibitions/types';
import { getProduct, getProducts } from '../../../api_v2/admin/products';
import { createExhibition } from '../../../api_v2/admin/exhibitions';
import { ImagesUpload } from './components/ImagesUpload';
import { api } from '../../../plugins/axios';
import {
  regions,
  cities,
  campingTypes,
  facilities,
  addFacilities,
  surroundings,
  regionsObj,
  regionsObj3,
} from './constants';
import { createCampingInfo } from '../../../api_v2/admin/camping';

const { Option: AntdOption } = AntdSelect;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 50 },
};

const timePickerFormat = 'HH:mm';

export const CampingAdd = () => {
  const history = useHistory();
  const form = Form.useForm()[0];
  const [campingAddStatus, setCampingAddStatus] = useState(false);

  // const editorContentRef = useRef('');
  const [editorDetailDesc, setEditorDetailDesc] = useState('');
  const [editorOperPolicy, setEditorOperPolicy] = useState('');
  const [editorCancelRefundPolicy, setEditorCancelRefundPolicy] = useState('');

  // const [searchProductKeyword, setSearchProductKeyword] = useState('');
  // const [searchProducts, setSearchProducts] = useState([]);
  // const [editorImagesObj, setEditorImagesObj] = useState<{ [key: string]: any }>({});

  const editorDetailDescImagesObjRef = useRef<{ [key: string]: any }>({});
  const editorOperPolicyImagesObjRef = useRef<{ [key: string]: any }>({});
  const editorCancelRefundPolicyImagesObjRef = useRef<{ [key: string]: any }>({});

  // useEffect(() => {
  //   if (!!searchProductKeyword && searchProductKeyword.length !== 0) {
  //     const searchProductQueryString = stringify(
  //       {
  //         limit: 100,
  //         filter: {
  //           word: searchProductKeyword,
  //         },
  //       },
  //       { addQueryPrefix: true, encode: false }
  //     );
  //     getProducts(searchProductQueryString).then((resultData) => {
  //       if (resultData?.success) {
  //         setSearchProducts(resultData.data.items);
  //       }
  //     });
  //   } else {
  //     setSearchProducts([]);
  //   }
  // }, [searchProductKeyword]);

  const onFinish = async (values: any) => {
    const mainImages = values['main-images'];
    if (!checkNumb(values.phone)) {
      toast.error('전화번호를 입력해주세요. 예) 01012345678');
    } else {
      setCampingAddStatus(true);
      let mainImagesPromisesResult: any[] = [];
      if (mainImages && mainImages.length > 0) {
        let mainImagesPromises = mainImages.map(async (mainImageObj: any) => {
          const headers = {
            headers: {
              accept: '*/*',
              'Content-Type': 'multipart/form-data',
            },
          };
          const mainImageFormData = new FormData();
          mainImageFormData.append('file', mainImageObj.originFileObj);
          // 'https://stage-api.pridge.kr/photo'
          let mainImagePhotoRes = await api.post('/photo', mainImageFormData, headers);
          return mainImagePhotoRes?.data?.url ?? '';
        });

        mainImagesPromisesResult = await Promise.all(mainImagesPromises);
      }
      const modifyEditorContent = async (imagesRef: any, editorContent: any) => {
        let editorImagesPromises = Object.keys(imagesRef.current).map(async (editorImageKey) => {
          const headers = {
            headers: {
              accept: '*/*',
              'Content-Type': 'multipart/form-data',
            },
          };
          const imageFormData = new FormData();
          imageFormData.append('file', imagesRef.current[editorImageKey]);
          let photoRes = await api.post('/photo', imageFormData, headers);
          return {
            editorImageKey,
            res: photoRes,
          };
        });
        let editorImagesPromisesResult = await Promise.all(editorImagesPromises);
        let docEditorContent = new DOMParser().parseFromString(editorContent, 'text/html');
        let editorImgElements = docEditorContent.querySelectorAll('img');
        for (let i = 0; i < editorImgElements.length; i++) {
          let tempSrcArr = editorImgElements[i].src.split('/');
          let tempUrlName = tempSrcArr.pop();
          let foundItemObj = editorImagesPromisesResult.find(
            (itemObj) => itemObj?.editorImageKey === tempUrlName
          );
          editorImgElements[i].src = foundItemObj?.res?.data?.url ?? '';
        }
        return docEditorContent.documentElement.innerHTML;
      };
      let modifiedEditorDetailDescContent = await modifyEditorContent(
        editorDetailDescImagesObjRef,
        editorDetailDesc
      );
      let modifiedEditorOperPolicyContent = await modifyEditorContent(
        editorOperPolicyImagesObjRef,
        editorOperPolicy
      );

      let modifiedEditorCancelRefundPolicyContent = await modifyEditorContent(
        editorCancelRefundPolicyImagesObjRef,
        editorCancelRefundPolicy
      );

      const openingHoursCheckIn = values['opening-hours-checkin']?.format('HH:mm');
      const openingHoursCheckOut = values['opening-hours-checkout']?.format('HH:mm');
      const mannerHoursStart = values['manner-hours-start']?.format('HH:mm');
      const mannerHoursEnd = values['manner-hours-end']?.format('HH:mm');

      let createCampingInfoResult = await createCampingInfo({
        // code: uuidv4(),
        name: values['campinginfo-name'] ?? '',
        isVisible:
          values['campinginfo-switch1'] !== undefined ? values['campinginfo-switch1'] : false,
        status: values['campinginfo-switch2'] !== undefined ? values['campinginfo-switch2'] : false,
        retailPrice: values?.price ?? 0,
        // fee: values?.price * (values?.commission * 0.01) ?? 0,
        fee: values?.commission ?? 0,
        address: {
          city: values?.cities ?? '',
          region: values?.regions ?? '',
          address1: values?.address ?? '',
          address2: values['detail-address'] ?? '',
          zipcode: '',
          location: {
            latitude: '',
            longitude: '',
          },
        },
        phone: values?.phone ?? '',
        checkin: openingHoursCheckIn ?? '',
        checkout: openingHoursCheckOut ?? '',
        mannerStartTime: mannerHoursStart ?? '',
        mannerEndTime: mannerHoursEnd ?? '',
        tags: {
          campingTypes: values['campinginfo-types'] ?? [],
          facilities: values['facilities'] ?? [],
          addFacilities: values['add-facilities'] ?? [],
          surroundings: values['surroundings'] ?? [],
        },
        description: modifiedEditorDetailDescContent ?? '',
        policy: modifiedEditorOperPolicyContent ?? '',
        refundPolicy: modifiedEditorCancelRefundPolicyContent ?? '',
        images: mainImagesPromisesResult ?? [],
      });

      if (createCampingInfoResult) {
        toast.success('성공적으로 캠핑장을 등록하였습니다.');
      }
      setCampingAddStatus(false);
    }
  };

  const normFile = (e: any) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const checkNumb = (checkStr: string) => {
    let flag = true;
    for (let i = 0; i < checkStr.length; i++) {
      if (isNaN(parseInt(checkStr[i]))) {
        flag = false;
        break;
      }
    }
    return flag;
  };

  const [chosenRegion, setChosenRegion] = useState<any | null>(null);
  const [chosenCity, setChosenCity] = useState<any | null>(null);

  const handleRegions = (regionData: any) => {
    setChosenRegion(regionData);
    setChosenCity(null);
  };

  const handleCities = (cityData: any) => {
    setChosenRegion(cityData);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>캠핑장 추가</AdminH1>
      </div>

      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:px-8">
          <Form form={form} name="validate_other" {...formItemLayout} onFinish={onFinish}>
            <div>
              <Form.Item>
                <h3>상태구분</h3>
              </Form.Item>
              <Form.Item name="campinginfo-switch1" valuePropName="checked" label="진열상태">
                <AntdSwitch />
              </Form.Item>
              <Form.Item name="campinginfo-switch2" valuePropName="checked" label="판매상태">
                <AntdSwitch />
              </Form.Item>
              <Form.Item>
                <h3>기본 정보</h3>
              </Form.Item>
              <Form.Item
                name="campinginfo-name"
                label="캠핑장명"
                rules={[{ required: true, message: '캠핑장명' }]}
              >
                <Input placeholder="캠핑장명" />
              </Form.Item>
              <Form.Item
                name="campinginfo-types"
                label="분류"
                // rules={[{ type: 'array', required: true, message: '분류' }]}
              >
                <AntdSelect mode="multiple" placeholder="분류">
                  {campingTypes.map((item) => {
                    return (
                      <AntdOption key={item} value={item}>
                        {item}
                      </AntdOption>
                    );
                  })}
                </AntdSelect>
              </Form.Item>
              <Form.Item
                name="facilities"
                label="기본시설"
                // rules={[{ type: 'array', required: true, message: '기본시설' }]}
              >
                <AntdSelect mode="multiple" placeholder="기본시설">
                  {facilities.map((item) => {
                    return (
                      <AntdOption key={item} value={item}>
                        {item}
                      </AntdOption>
                    );
                  })}
                </AntdSelect>
              </Form.Item>
              <Form.Item
                name="add-facilities"
                label="부가시설"
                // rules={[{ type: 'array', required: true, message: '부가시설' }]}
              >
                <AntdSelect mode="multiple" placeholder="부가시설">
                  {addFacilities.map((item) => {
                    return (
                      <AntdOption key={item} value={item}>
                        {item}
                      </AntdOption>
                    );
                  })}
                </AntdSelect>
              </Form.Item>
              <Form.Item
                name="surroundings"
                label="환경"
                // rules={[{ type: 'array', required: true, message: '환경' }]}
              >
                <AntdSelect mode="multiple" placeholder="환경">
                  {surroundings.map((item) => {
                    return (
                      <AntdOption key={item} value={item}>
                        {item}
                      </AntdOption>
                    );
                  })}
                </AntdSelect>
              </Form.Item>
              <Form.Item name="regions" label="지역" rules={[{ required: true, message: '지역' }]}>
                <AntdSelect
                  placeholder="지역"
                  onChange={(e) => handleRegions(e)}
                  value={chosenRegion}
                >
                  {regions.map((item) => (
                    <AntdOption key={item} value={item}>
                      {item}
                    </AntdOption>
                  ))}
                </AntdSelect>
              </Form.Item>
              <Form.Item
                name="cities"
                label="상세지역"
                // rules={[{ required: true, message: '상세지역' }]}
              >
                <AntdSelect
                  placeholder="상세지역"
                  onChange={(e) => handleCities(e)}
                  value={chosenCity}
                >
                  {((chosenRegion ? regionsObj3[chosenRegion] : []) ?? []).map((item: any) => (
                    <AntdOption key={item} value={item}>
                      {item}
                    </AntdOption>
                  ))}
                </AntdSelect>
              </Form.Item>
              <Form.Item
                name="address"
                label="영업장주소"
                rules={[{ required: true, message: '영업장 주소' }]}
              >
                <Input placeholder="영업장주소" />
              </Form.Item>
              <Form.Item
                name="detail-address"
                label="상세주소"
                // rules={[{ required: true, message: '상세주소' }]}
              >
                <Input placeholder="상세주소" />
              </Form.Item>
              <Form.Item
                name="phone"
                label="대표 전화번호"
                rules={[{ required: true, message: '대표 전화번호' }]}
              >
                <Input style={{ width: '100%' }} placeholder="예) 01012345678" />
              </Form.Item>
              <Form.Item
                name="price"
                label="대표 예약가"
                rules={[{ required: true, message: '대표 예약가' }]}
              >
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item
                label="수수료율 (%)"
                name="commission"
                // noStyle
                rules={[{ required: true, message: '수수료율' }]}
              >
                <InputNumber min={0} max={100} />
              </Form.Item>

              {/* 
              <Form.Item name="code" label="코드">
                <Input placeholder="코드" />
              </Form.Item> */}
              <Form.Item name="detail-description" label="상세설명">
                <FroalaEditor
                  defaultValue={''}
                  onChange={(detailDescModel) => {
                    setEditorDetailDesc(detailDescModel);
                  }}
                  editorImagesObjRef={editorDetailDescImagesObjRef}
                />
              </Form.Item>
              <Form.Item label="이용시간">
                <div className="flex">
                  <Form.Item
                    name="opening-hours-checkin"
                    label="체크인"
                    className="mr-3"
                    rules={[{ required: true, message: '체크인' }]}
                  >
                    <TimePicker format={timePickerFormat} />
                  </Form.Item>
                  <Form.Item
                    name="opening-hours-checkout"
                    label="체크아웃"
                    rules={[{ required: true, message: '체크아웃' }]}
                  >
                    <TimePicker format={timePickerFormat} />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item label="매너타임">
                <div className="flex">
                  <Form.Item name="manner-hours-start" label="Start" className="mr-3">
                    <TimePicker format={timePickerFormat} />
                  </Form.Item>
                  <Form.Item name="manner-hours-end" label="End">
                    <TimePicker format={timePickerFormat} />
                  </Form.Item>
                </div>
              </Form.Item>

              {/* <Form.Item name="opening-hours" label="이용시간(체크인, 체크아웃)">
                <TimePicker.RangePicker format={timePickerFormat} />
              </Form.Item>
              <Form.Item name="manner-hours" label="매너타임">
                <TimePicker.RangePicker format={timePickerFormat} />
              </Form.Item> */}
              <Form.Item name="campinginfo-operation-policy" label="캠핑장 운영정책">
                <FroalaEditor
                  defaultValue={''}
                  onChange={(operPolicyModel) => {
                    setEditorOperPolicy(operPolicyModel);
                  }}
                  editorImagesObjRef={editorOperPolicyImagesObjRef}
                />
              </Form.Item>
              <Form.Item name="campinginfo-cancel-refund-policy" label="캠핑장 취소/환불정책">
                <FroalaEditor
                  defaultValue={''}
                  onChange={(cancelRefundPolicyModel) => {
                    setEditorCancelRefundPolicy(cancelRefundPolicyModel);
                  }}
                  editorImagesObjRef={editorCancelRefundPolicyImagesObjRef}
                />
              </Form.Item>
            </div>

            <Form.Item label="메인 이미지 정보">
              <Form.Item
                name="main-images"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
              >
                <Upload.Dragger listType="picture" name="files" action="/upload.do">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 18 }}>
              <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
                {campingAddStatus ? (
                  <div>캠핑장 등록중...</div>
                ) : (
                  <>
                    <Button
                      text="취소"
                      className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
                      to="/admin/camping"
                      disabled={campingAddStatus}
                    />
                    <AntdButton
                      className="add-submit-button button md:max-w-md h-10 text-sm filled-black"
                      htmlType="submit"
                      disabled={campingAddStatus}
                    >
                      추가하기
                    </AntdButton>
                  </>
                )}
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </>
  );
};
