import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import { default as htmlReactParser, attributesToProps } from 'html-react-parser';

import Linkify from 'linkify-react';

import { ReactComponent as IconNoPicture } from '../../../../../../../../assets/icons/icon-no-picture.svg';
import { ReactComponent as IconEnlarge } from '../../../../../../../../assets/icons/icon-enlarge.svg';
import { Section } from '../../../../../../../../components/Section';

const categoryNameObj: { [key: string]: any } = {
  domesticTravel: '국내여행',
  internationalTravel: '해외여행',
  domesticFestival: '국내축제',
  internationalFestival: '해외축제',
  etc: '기타',
};

const CommunityPostDetail = ({ item }: any) => {
  const [communityPost, setCommunityPost] = useState<any>(null);

  const [isEnlargeOpen, setIsEnlargeOpen] = useState(false);

  const openEnlargeModal = () => {
    setIsEnlargeOpen(true);
  };

  const closeEnlargeModal = () => {
    try {
      let spEle = document?.querySelector('.mySwiper') as any;
      if (spEle) {
        spEle?.swiper?.slideTo(currentEnlargeImageIndex);
      }
      setIsEnlargeOpen(false);
    } catch (e) {}
  };

  const [currentEnlargeImageIndex, setCurrentEnlargeImageIndex] = useState(0);

  const nextEnlargeImage = () => {
    if (currentEnlargeImageIndex < communityPost?.images.length - 1) {
      setCurrentEnlargeImageIndex(currentEnlargeImageIndex + 1);
    }
  };

  const prevEnlargeImage = () => {
    if (currentEnlargeImageIndex > 0) {
      setCurrentEnlargeImageIndex(currentEnlargeImageIndex - 1);
    }
  };

  useEffect(() => {
    setCommunityPost(item);
  }, [item]);

  const [contentTimePassed, setContentTimePassed] = useState<any>(null);

  useEffect(() => {
    if (communityPost) {
      const calculateTimeAgo = (timeData: any) => {
        const textWrittenTime = timeData as any;
        const currentTime = new Date() as any;
        const timeDifference = currentTime - textWrittenTime;

        const secondsPassed = Math.floor(timeDifference / 1000);
        const minutesPassed = Math.floor(secondsPassed / 60);
        const hoursPassed = Math.floor(minutesPassed / 60);
        const daysPassed = Math.floor(hoursPassed / 24);
        const yearsPassed = Math.floor(daysPassed / 365);

        if (yearsPassed > 0) {
          return `${yearsPassed} 년${yearsPassed > 1 ? '' : ''} 전`;
        } else if (daysPassed > 0) {
          return `${daysPassed} 일${daysPassed > 1 ? '' : ''} 전`;
        } else if (hoursPassed > 0) {
          return `${hoursPassed} 시간${hoursPassed > 1 ? '' : ''} 전`;
        } else if (minutesPassed > 0) {
          return `${minutesPassed} 분${minutesPassed > 1 ? '' : ''} 전`;
        } else {
          return `${secondsPassed} 초${secondsPassed > 1 ? '' : ''} 전`;
        }
      };

      const dateString = communityPost.createdAt;
      const parts = dateString.split(/[-T:.]/);
      const year = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Months are 0-indexed
      const day = parseInt(parts[2]);
      const hour = parseInt(parts[3]);
      const minute = parseInt(parts[4]);
      const second = parseInt(parts[5]);
      const millisecond = parseInt(parts[6]);

      const localDate = new Date(year, month, day, hour, minute, second, millisecond);

      // setContentTimePassed(calculateTimeAgo(localDate));
      setContentTimePassed(calculateTimeAgo(new Date(communityPost.createdAt)));
    }
  }, [communityPost]);

  const [parsedContent, setParsedContent] = useState<any>(null);

  const [contentAccorButtonStatus, setContentAccorButtonStatus] = useState<boolean>(false);
  const [contentExpandStatus, setContentExpandStatus] = useState<boolean>(false);

  const [loadingProgress, setLoadingProgress] = useState<any>({});

  useEffect(() => {
    try {
      let communityPostContentEle = document.querySelector('#communityPostContent');
      if (communityPost?.content && parsedContent) {
        if (communityPostContentEle) {
          const height = (communityPostContentEle as any)?.offsetHeight;
          if (height >= 300) {
            (communityPostContentEle as any).style.height = '300px';
            (communityPostContentEle as any).style.overflow = 'hidden';
            (communityPostContentEle as any).style['border-bottom-radius'] = '7px';
            setContentAccorButtonStatus(true);
          }
        }
      }
    } catch (e) {}
  }, [communityPost?.content, parsedContent]);

  useEffect(() => {
    try {
      if (Object.keys(loadingProgress)?.length > 0) {
        for (let i = 0; i < Object.keys(loadingProgress)?.length; i++) {
          let key = Object.keys(loadingProgress)[i] as any;
          if (loadingProgress[key].progress == 100 && !loadingProgress[key].applied) {
            let imgEle = document.querySelector(`#image-${key}`) as any;
            let loadEle = document.querySelector(`#loader-${key}`) as any;
            imgEle.style.display = 'block';
            loadEle.style.display = 'none';
            loadingProgress[key].applied = true;
          }
        }
      }
    } catch (e) {}
  }, [loadingProgress]);

  useEffect(() => {
    try {
      if (communityPost?.content) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(communityPost?.content, 'text/html') as any;

        const loadImage = (image: any, index: any, loader: any) => {
          const img = new Image();
          img.src = image.src;

          img.onload = () => {
            setLoadingProgress((prevProgress: any) => ({
              ...prevProgress,
              [index]: { progress: 100, applied: false },
            }));
          };

          img.onerror = () => {
            console.error(`Error loading image ${index + 1}`);
          };
        };

        const images = Array.from(doc.querySelectorAll('img'));

        images.forEach((image: any, index: any) => {
          image.style.display = 'none';
          image.id = `image-${index}`;
          const loader = document.createElement('div');
          loader.id = `loader-${index}`;
          loader.style.width = '20px';
          loader.style.height = '20px';
          loader.style.display = 'flex';
          loader.style.justifyContent = 'center';
          loader.style.alignItems = 'center';
          loader.style.background =
            'linear-gradient(45deg, #eee 25%, transparent 25%, transparent 50%, #eee 50%, #eee 75%, transparent 75%, transparent)';
          loader.style.backgroundSize = '20px 20px';
          loader.style.animation = 'spin 1s linear infinite';

          image.parentNode.insertBefore(loader, image);
          loadImage(image, index, loader);
        });

        if (doc?.documentElement?.outerHTML) {
          setParsedContent(doc.documentElement.outerHTML);
        }
      }
    } catch (e) {}
  }, [communityPost?.content]);

  const changeImgObjectFit = (slideImage: any) => {
    try {
      if (slideImage) {
        const imgElement = slideImage as any;
        const width = imgElement?.naturalWidth as any;
        const height = imgElement?.naturalHeight as any;

        if (imgElement && width >= 0 && height > 0) {
          // Calculate the aspect ratio
          const aspectRatio = width / height;

          // Set the object-fit property based on the aspect ratio
          if (aspectRatio > 1) {
            // Landscape image
            imgElement.style['object-fit'] = 'cover'; // Or any other object-fit value you prefer
          } else {
            // Portrait image or square image
            imgElement.style['object-fit'] = 'contain'; // Or any other object-fit value you prefer
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const handleContentExpand = async () => {
    try {
      let communityPostContentEle = document.querySelector('#communityPostContent');
      if (communityPost?.content) {
        if (communityPostContentEle) {
          if (!contentExpandStatus) {
            (communityPostContentEle as any).style.height = '100%';
            (communityPostContentEle as any).style.overflow = 'hidden';
          } else {
            (communityPostContentEle as any).style.height = '300px';
            (communityPostContentEle as any).style.overflow = 'hidden';
          }
        }
      }

      setContentExpandStatus(!contentExpandStatus);
    } catch (e) {}
  };

  return (
    <>
      <div className="w-full h-full bg-[#F6F6F6]">
        {!communityPost && (
          <>
            <div className="flex justify-center bg-[white]">
              <div className="initial-loading-container-for-campinginfo text-[50px]">
                <span className="loading-dot">.</span>
                <span className="loading-dot">.</span>
                <span className="loading-dot">.</span>
              </div>
            </div>
          </>
        )}

        {communityPost && (
          <>
            <Section className="bg-white">
              {communityPost?.images?.length > 0 ? (
                <Swiper
                  className="mySwiper bg-white"
                  pagination={false}
                  // autoplay={{
                  //   delay: 3000,
                  // }}
                  onAfterInit={(swiper) => {
                    try {
                      if (swiper?.el?.style) {
                        (swiper.el.style as any)['padding-bottom'] = '0px';
                        (swiper.el.style as any)['height'] = '300px';
                      }
                      setCurrentEnlargeImageIndex(swiper?.activeIndex ?? 0);
                    } catch (e) {}
                  }}
                  onPaginationRender={(swiper, paginationEl) => {
                    try {
                      if (paginationEl) {
                        paginationEl.style.position = 'absolute';
                        paginationEl.style.bottom = '0';
                        (paginationEl as any).style['padding-bottom'] = '15px';
                      }
                    } catch (e) {}
                  }}
                  onSlideChange={(swiper) => {
                    try {
                      setCurrentEnlargeImageIndex(swiper?.activeIndex ?? 0);
                    } catch (e) {}
                  }}
                >
                  {communityPost?.images &&
                    communityPost?.images.map((imgObj: any, index2: any) => (
                      <div key={imgObj?.url + index2}>
                        <SwiperSlide className="w-full h-full">
                          <img
                            ref={(el) => {
                              // if (el) {
                              //   changeImgObjectFit(el);
                              // }
                            }}
                            className="object-cover w-full h-full max-h-[500px]"
                            src={imgObj.url}
                            // src={chooseImage(imgObj) ?? ''}
                          />
                        </SwiperSlide>
                      </div>
                    ))}
                  <div
                    className="absolute bottom-[5px] right-[10px] w-fit z-[1500] px-[8px] py-[1px] text-[white] rounded-[10px]"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                  >
                    {(currentEnlargeImageIndex ?? 0) + 1} / {communityPost?.images?.length}
                  </div>
                </Swiper>
              ) : (
                <div className="h-[230px] w-full flex justify-center items-center">
                  <IconNoPicture width={'60px'} height={'60px'} />
                </div>
              )}
              {communityPost?.images?.length > 0 ? (
                <button
                  className="
                absolute top-[75px] right-[10px]
                p-[5px]
                rounded-[5px]            
                z-[1000]"
                  onClick={openEnlargeModal}
                  style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                >
                  <IconEnlarge width={'20px'} height={'20px'} />
                </button>
              ) : null}

              {isEnlargeOpen && (
                <div className="absolute top-0 left-0 w-full h-screen flex items-center justify-center z-[2000]">
                  {/* Dark Overlay */}
                  <div
                    className="fixed top-0 left-0 w-full h-full bg-black opacity-50"
                    onClick={closeEnlargeModal}
                  ></div>
                  <div
                    className="modal-content max-w-md w-full h-[80vh] z-50"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {communityPost?.images?.length > 0 ? (
                      <>
                        <div className="image-slider w-full">
                          <button
                            onClick={prevEnlargeImage}
                            className="
                            absolute top-[50%] left-[20px] 
                            bg-[white] opacity-[.9] 
                            w-[30px] h-[30px]
                            rounded-[17px]
                            text-[black]"
                          >
                            {'<'}
                          </button>
                          <img
                            className="w-full object-cover"
                            src={communityPost?.images[currentEnlargeImageIndex]?.url}
                            alt={`Image ${currentEnlargeImageIndex}`}
                          />
                          <button
                            onClick={nextEnlargeImage}
                            className="
                            absolute top-[50%] right-[20px]
                            bg-[white] opacity-[.9] 
                            w-[30px] h-[30px]
                            rounded-[17px]
                            text-[black]
                            "
                          >
                            {'>'}
                          </button>
                          <button
                            className="
                            absolute top-[20px] right-[10px]                                 
                            p-[5px]
                            w-[30px] h-[30px]
                            text-white font-bold rounded-[15px]"
                            onClick={closeEnlargeModal}
                            style={{ backgroundColor: 'rgba(255, 100, 0)' }}
                          >
                            X
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="h-[230px] w-full flex justify-center items-center">
                        <IconNoPicture width={'60px'} height={'60px'} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Section>

            <Section className="max-w-md side-padding pb-[20px] py-3 bg-[#FFFFFF]">
              <div className="flex flex-wrap mt-3">
                {communityPost?.categories &&
                  communityPost?.categories.map((category: any) => (
                    <div
                      className="flex justify-center items-center 
              border border-[#D0D0D0] rounded p-1 text-[10px] w-fit mr-1 mb-1"
                    >
                      {categoryNameObj[category]}
                    </div>
                  ))}
              </div>
            </Section>

            <Section className="max-w-md side-padding pt-[20px] py-3 bg-[#FFFFFF]">
              <div className="">
                <pre
                  id="communityPostContent"
                  className="relative text-[15px] mb-3 break-all whitespace-pre-wrap"
                >
                  {/* <Linkify>{communityPost?.content}</Linkify> */}
                  <Linkify>
                    {/* {communityPost?.content && htmlReactParser(communityPost?.content ?? '')} */}
                    {parsedContent && htmlReactParser(parsedContent ?? '')}
                  </Linkify>
                  {contentAccorButtonStatus && (
                    <>
                      {contentExpandStatus ? (
                        <></>
                      ) : (
                        <>
                          <div className="absolute bottom-0 left-0 w-full h-[80px] bg-gradient-to-b from-transparent to-black opacity-30"></div>
                        </>
                      )}
                    </>
                  )}
                </pre>
                {contentAccorButtonStatus && (
                  <div className="flex justify-center w-full my-[3px]">
                    <button
                      className="border-[2px] border-[black] px-[20px] py-[2px] rounded-[8px] font-[700]"
                      onClick={() => {
                        handleContentExpand();
                      }}
                    >
                      {contentExpandStatus ? <>접기</> : <>더보기</>}
                    </button>
                  </div>
                )}
              </div>

              <div className="flex text-[12px] text-[#878787]">
                <div className="mr-[5px]">
                  {communityPost?.user?.username?.substring(0, 10)
                    ? communityPost?.user?.username?.substring(0, 10) + '...'
                    : null}
                </div>
                <div>{contentTimePassed ?? null}</div>
              </div>
            </Section>
          </>
        )}
      </div>
    </>
  );
};

export default CommunityPostDetail;
