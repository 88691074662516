import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../../../../components/Button';

import { ReactComponent as IconSend } from '../../../../assets/icons/icon-send.svg';
import { createCommunityPostCommentReply } from '../../../../api_v2/non_admin/community';
import { toast } from 'react-toastify';
import Linkify from 'linkify-react';
import { api } from '../../../../plugins/axios';
import { stringify } from 'qs';
import { useInfiniteQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CardType2 } from './components/CardType2';
import { map } from 'lodash';
import { CustomDrawer } from './components/CustomDrawer';
import { CardType3 } from './components/CardType3';
import { authUserTokenObjState } from '../../../../ridge/ridge';

enum DrawerType {
  POST_COMMENT_REPLY_DETAIL = 'post_comment_reply_detail',
}

export default function PostComment(props: any) {
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const [drawerType, setDrawerType] = useState<any>(null);
  const [content, setContent] = useState<any>('');
  const [communityPostCommentReply, setCommunityPostCommentReply] = useState<any>('');

  const [addCommunityPostCommentReplyStatus, setAddCommunityPostCommentReplyStatus] =
    useState<any>(false);

  useEffect(() => {
    if (props?.item) {
      setContent(props?.item?.content ?? '');
    }
  }, [props?.item]);

  const handleCommunityPostCommentReplyChange = (e: any) => {
    setCommunityPostCommentReply(e.target.value);
  };

  const handleCommunityPostCommentReply = async () => {
    try {
      setAddCommunityPostCommentReplyStatus(true);
      if (communityPostCommentReply.trim()) {
        if (window.confirm('댓글을 등록하시겠습니까?')) {
          let createCommunityPostCommentReplyRes = await createCommunityPostCommentReply(
            props?.item.postId,
            props?.item.id,
            {
              content: communityPostCommentReply.trim(),
            }
          );
          if (createCommunityPostCommentReplyRes?.success == true) {
            refetchCommunityPostCommentReplies();
            toast.success('성공적으로 등록하였습니다');
          } else {
            toast.error('오류가 발생하였습니다');
          }
        }
      } else {
        window.confirm('댓글을 작성해주세요');
      }
      setAddCommunityPostCommentReplyStatus(false);
    } catch (e) {}
  };

  const [pageLimit, setPageLimit] = useState(5);

  const fetchCommunityPostCommentReplies = async ({ pageParam = 1 }) => {
    let currentQueryStringObj: { [key: string]: any } = {
      page: pageParam,
      limit: pageLimit,
      filter: { status: true, isVisible: true },
      sort: { createdAt: 'DESC' },
    };

    // if (parsedSearch?.start_date && parsedSearch?.end_date) {
    //   currentQueryStringObj.filter['checkin'] = convertDateForQS(parsedSearch?.start_date);
    //   currentQueryStringObj.filter['checkout'] = convertDateForQS(parsedSearch?.end_date);
    // }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
      arrayFormat: 'brackets',
    });

    // if (pageParam == 1) {
    //   setInitialLoading(true);
    //   loadingDots(true, 'initial-loading-container');
    // } else {
    //   loadingDots(true, 'more-loading-container');
    // }

    let result = await api.get(
      `/community/posts/${props?.item.postId}/comments/${props?.item.id}/replies` +
        currentQueryString
    );

    // if (pageParam == 1) {
    //   setInitialLoading(false);
    //   loadingDots(false, 'initial-loading-container');
    // } else {
    //   loadingDots(false, 'more-loading-container');
    // }

    if ([200].includes(result?.status)) {
      return result;
    } else return null;
  };

  const {
    data: communityPostCommentRepliesData,
    fetchNextPage,
    isLoading: isCommunityPostCommentRepliesDataLoading,
    refetch: refetchCommunityPostCommentReplies,
  } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`communityPostCommentRepliesPage`, props?.item],
    fetchCommunityPostCommentReplies as any,
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        if (lastPage?.data?.items?.length > 0) {
          return nextPage;
        } else if (lastPage?.data?.items?.length == 0) {
          return undefined;
        } else return allPages?.length;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const observer = useRef<IntersectionObserver>();

  const lastElement = useCallback(
    (target) => {
      if (isCommunityPostCommentRepliesDataLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && fetchNextPage) {
            fetchNextPage();
          } else {
          }
        },
        {
          rootMargin: '500px',
          threshold: 0.1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [isCommunityPostCommentRepliesDataLoading]
  );

  const openDrawer = (drawerTypeData: any, heightData?: any) => {
    setDrawerType(drawerTypeData);
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `none`;

      (drawerRef.current as any).style['pointer-events'] = 'auto';

      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = heightData ?? '100%';
    }
  };

  const closeDrawer = () => {
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `auto`;

      drawerRef.current.style.width = '0';
      drawerRef.current.style.height = '0';
    }
  };

  const [chosenPostCommentReplyDetail, setChosenPostCommentReplyDetail] = useState<any>(null);

  const [contentTimePassed, setContentTimePassed] = useState<any>(null);

  useEffect(() => {
    if (props?.item) {
      const calculateTimeAgo = (timeData: any) => {
        const textWrittenTime = timeData as any;
        const currentTime = new Date() as any;
        const timeDifference = currentTime - textWrittenTime;

        const secondsPassed = Math.floor(timeDifference / 1000);
        const minutesPassed = Math.floor(secondsPassed / 60);
        const hoursPassed = Math.floor(minutesPassed / 60);
        const daysPassed = Math.floor(hoursPassed / 24);
        const yearsPassed = Math.floor(daysPassed / 365);

        if (yearsPassed > 0) {
          return `${yearsPassed} 년${yearsPassed > 1 ? '' : ''} 전`;
        } else if (daysPassed > 0) {
          return `${daysPassed} 일${daysPassed > 1 ? '' : ''} 전`;
        } else if (hoursPassed > 0) {
          return `${hoursPassed} 시간${hoursPassed > 1 ? '' : ''} 전`;
        } else if (minutesPassed > 0) {
          return `${minutesPassed} 분${minutesPassed > 1 ? '' : ''} 전`;
        } else {
          return `${secondsPassed} 초${secondsPassed > 1 ? '' : ''} 전`;
        }
      };

      const dateString = props?.item.createdAt;
      const parts = dateString.split(/[-T:.]/);
      const year = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Months are 0-indexed
      const day = parseInt(parts[2]);
      const hour = parseInt(parts[3]);
      const minute = parseInt(parts[4]);
      const second = parseInt(parts[5]);
      const millisecond = parseInt(parts[6]);

      const localDate = new Date(year, month, day, hour, minute, second, millisecond);

      // setContentTimePassed(calculateTimeAgo(localDate));
      setContentTimePassed(calculateTimeAgo(new Date(props?.item.createdAt)));
    }
  }, [props?.item]);

  const authUserTokenObj = authUserTokenObjState.useValue();

  return (
    <div className="">
      <pre className="w-full mb-[5px] whitespace-pre-wrap break-all">
        <Linkify>{content}</Linkify>
      </pre>
      <div className="flex text-[12px] text-[#878787]">
        <div className="mr-[5px]">
          {props?.item?.user?.username?.substring(0, 10)
            ? props?.item?.user?.username?.substring(0, 10) + '...'
            : null}
        </div>
        <div>{contentTimePassed ?? null}</div>
      </div>
      <div className="flex justify-between my-[5px]">
        <div className="font-[700] text-[#FF6400] text-[13px]">
          댓글 ({(communityPostCommentRepliesData as any)?.pages[0]?.data?.total ?? 0})
        </div>
      </div>
      <div className="sticky top-[0px] z-30 rounded-[10px] mt-[10px] w-full bg-[#dcdcde]">
        <div className="flex items-center p-[5px]">
          <textarea
            className="w-full mr-[4px] rounded-[10px] resize-none border-[#dcdcde]"
            style={{ maxHeight: '300px' }}
            placeholder="댓글을 작성해주세요"
            value={communityPostCommentReply}
            onChange={(e) => {
              handleCommunityPostCommentReplyChange(e);
            }}
          />

          {addCommunityPostCommentReplyStatus ? (
            <div className="w-[13px] h-[13px] border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
          ) : (
            <>
              <Button
                disabled={addCommunityPostCommentReplyStatus}
                className="px-[0px]"
                onClick={() => {
                  if (authUserTokenObj) {
                    handleCommunityPostCommentReply();
                  } else {
                    window.confirm('로그인후 등록해주세요.');
                  }
                }}
              >
                <IconSend width={'25px'} height={'25px'} />
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="mt-[10px]">
        {!((communityPostCommentRepliesData as any)?.pages[0]?.data?.items?.length > 0) &&
        !isCommunityPostCommentRepliesDataLoading ? (
          <>
            <p className="grid place-content-center h-44 gap-[5px]"></p>
          </>
        ) : communityPostCommentRepliesData?.pages &&
          communityPostCommentRepliesData?.pages?.length > 0 ? (
          <>
            {map(communityPostCommentRepliesData?.pages ?? [], (page, pageInd) => {
              return map(page?.data?.items ?? [], (postCommentObj, postCommentObjInd: number) => {
                return (
                  <>
                    <div
                      key={postCommentObj?.id}
                      ref={
                        communityPostCommentRepliesData?.pages?.length - 1 == pageInd &&
                        page?.data?.items?.length - 1 == postCommentObjInd
                          ? lastElement
                          : null
                      }
                    >
                      <CardType3
                        pageInd={pageInd}
                        item={postCommentObj}
                        closeDrawer={closeDrawer}
                        openDrawer={openDrawer}
                        setChosenPostCommentReplyDetail={setChosenPostCommentReplyDetail}
                        //   refetchMyPagePostsComments={refetchMyPagePostsComments}
                      />
                    </div>
                  </>
                );
              });
            })}
            {/* <div ref={lastElement}></div> */}
          </>
        ) : null}
        <CustomDrawer drawerRef={drawerRef} contentMt={55}>
          <div className="mt-[50px] p-[15px]">
            {drawerType == DrawerType.POST_COMMENT_REPLY_DETAIL ? (
              <>
                <>{chosenPostCommentReplyDetail.content}</>
              </>
            ) : null}
          </div>
        </CustomDrawer>
      </div>
    </div>
  );
}
