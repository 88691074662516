import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../../../../components/Icon';
import { ReactComponent as Home } from '../../../../../assets/svg/home.svg';

import LogoDayoutPNG3 from '../../../../../assets/img/icon-logo-dayout3.png';
import LoginPNG from '../../../../../assets/img/icon-login.png';
import IconCafe24MyPNG from '../../../../../assets/img/icon-cafe24-my.png';

import { meState, modalState } from '../../../../../ridge/ridge';

type hideContents = {
  leftBtn: boolean;
};

interface TopTitleBarProps {
  title?: string;
  home?: boolean;
  button?: JSX.Element;
  hideContents?: hideContents;
  logo?: boolean;
  backButton?: boolean;
}

export const TopTitleBar: FC<TopTitleBarProps> = ({
  title,
  home = false,
  button,
  hideContents,
  logo = false,
  backButton = true,
}) => {
  const history = useHistory();

  const me = meState.useValue();

  const modalTypes = (modalType: string) => {
    if (modalType == 'LOGIN') {
      history.push('/community/login');
    } else if (modalType == 'MY_PAGE') {
      const onClose = () => modalState.set({ ...modalState.get(), open: false });
      modalState.set({
        title: '마이페이지로 이동하시겠습니까?',
        subtitle: '',
        open: true,
        onClose,
        onClick: async () => {
          window.location.href = '/community/mypage';
          onClose();
        },
      });
    }
  };

  const handleUserAuthIcon = (loginStatus: string) => {
    if (loginStatus == 'LOGGED_IN') {
      modalTypes('MY_PAGE');
    } else if (loginStatus == 'LOGGED_OUT') {
      modalTypes('LOGIN');
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="grid grid-cols-6 place-items-center">
          {home && (
            <button
              className="place-self-start"
              onClick={() => {
                history.push('/community');
              }}
            >
              <Home className="wh-6" />
            </button>
          )}
          {backButton && (
            <button
              // className="absolute left-4 top-4"
              className="place-self-center"
              onClick={() => {
                history.goBack();
              }}
            >
              <Icon.ChevronLeft />
            </button>
          )}

          {logo && (
            <>
              <div
                className="col-start-3 col-end-5 w-[100px] cursor-pointer"
                onClick={() => {
                  window.location.href = '/community';
                }}
              >
                <img src={LogoDayoutPNG3} className="w-full" />
              </div>
            </>
          )}
          <div className="col-start-6 col-end-6">
            {me?.userInfo ? (
              <div
                onClick={() => handleUserAuthIcon('LOGGED_IN')}
                className="rounded-[50px] w-[30px] h-[30px] border-[1px] 
              font-[300] text-[1.0em] flex justify-center items-center cursor-pointer"
              >
                {me?.userInfo?.name ? me?.userInfo?.name[0] : null}
              </div>
            ) : (
              <div
                className="w-[25px] flex flex-col justify-center items-center cursor-pointer"
                onClick={() => handleUserAuthIcon('LOGGED_OUT')}
              >
                <img src={IconCafe24MyPNG} className="w-full" />
                <div className="text-[9px]">로그인</div>
              </div>
            )}
          </div>
        </div>
        {/* {!hideContents?.leftBtn && (
          <button
            className="absolute left-4 top-4"
            onClick={() => {
              history.goBack();
            }}
          >
            <Icon.ChevronLeft />
          </button>
        )}
        {logo && (
          <LogoDayout
            className="cursor-pointer"
            onClick={() => {
              window.location.href = '/community';
            }}
          />
        )}
        <p className="text-center mx-auto">{title}</p>
        {button && button}
        {home && (
          <button
            className="absolute right-4 top-4"
            onClick={() => {
              history.push('/home');
            }}
          >
            <Home className="wh-6" />
          </button>
        )} */}
      </div>
    </>
  );
};
