import React, { useEffect } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as BookmarkIcon } from '../assets/icons/icon-bookmark.svg';
import { ReactComponent as StoreIcon } from '../assets/icons/icon-store.svg';
import { ReactComponent as MyIcon } from '../assets/icons/icon-my.svg';
import { ReactComponent as Home2Icon } from '../assets/icons/icon-home2.svg';
import { ReactComponent as Bookmark2Icon } from '../assets/icons/icon-bookmark2.svg';
import { ReactComponent as InquiryIcon } from '../assets/icons/icon-inquiry.svg';
import { Button } from './Button';
import { HandIcon } from '@heroicons/react/outline';
import { useAuth } from '../hooks/authentication';
import { toast } from 'react-toastify';

export const BottomNavBar = () => {
  const { pathname } = useLocation();

  const history = useHistory();
  const { token, authenticated } = useAuth();
  const homeActive =
    pathname === '/home' ||
    pathname === '/products' ||
    pathname === '/best' ||
    pathname === '/sale' ||
    pathname === '/events';

  const bookmarkActive = pathname === '/bookmark';
  const myActive = pathname === '/mypage';

  const handleBookmarkPage = () => {
    if (authenticated) {
      history.push('/bookmark');
    } else {
      toast.error('로그인후 보관함을 이용할수있습니다.');
      history.push('/mypage');
    }
  };

  return (
    <>
      <div className="fixed bottom-0 w-full max-w-md bg-white grid grid-cols-3 pb-4 z-10 border-t border-gray-150">
        <div className="grid place-content-center">
          <div className={`${homeActive && 'bg-brand-1'}  w-full h-0.5 mx-auto`} />
          <Button
            onClick={(e) => {
              if (homeActive) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              } else {
                e.preventDefault();
                history.push('/home');
              }
            }}
            className="px-4 mt-4"
          >
            <Home2Icon className={`mx-auto ${homeActive ? 'text-brand-1 fill-current' : ''}`} />

            <span className={`${homeActive && 'text-brand-1'} text-10`}>홈</span>
          </Button>
        </div>

        <div className="grid place-content-center">
          <div className={`${bookmarkActive && 'bg-brand-1'}  w-full h-0.5 mx-auto`} />

          <Button to="/bookmark" className="px-4 mt-4">
            <Bookmark2Icon
              className={`mx-auto ${bookmarkActive ? 'text-brand-1 fill-current' : ''}`}
            />

            <span className={`${bookmarkActive && 'text-brand-1'} text-10`}>찜하기</span>
          </Button>
        </div>

        <div className="grid place-content-center">
          <div className={`${myActive && 'bg-brand-1'}  w-full h-0.5 mx-auto`} />

          <Button to="/mypage" className="px-4 mt-4">
            <MyIcon className={`mx-auto ${myActive ? 'text-brand-1 fill-current' : ''}`} />

            <span className={`${myActive && 'text-brand-1'} text-10`}>마이페이지</span>
          </Button>
        </div>
      </div>
    </>
  );
};
