import { parse, stringify } from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { addDays } from 'date-fns';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { Button } from '../../../../../components/Button';
import { getCampingBookingList } from '../../../../../api_v2/admin/onda';
import moment from 'moment';

import { Calendar } from '../Calendar';
import { Link, useLocation } from 'react-router-dom';
import { CustomDrawer } from '../CustomDrawer';
import { Table } from '../../../../../components/Table';
import { map } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { api } from '../../../../../plugins/axios';
import { toast } from 'react-toastify';

const { RangePicker } = DatePicker;

const propertyDescriptionObj: { [key: string]: any } = {
  impUid: 'impUid',
  paymentStatus: '결제 상태',
  merchantUid: '판매사 예약번호',
  bookingNo: '온다 예약번호',
  resStatus: '예약 상태',
};

enum BookingStatus {
  canceled = 'canceled',
  confirmed = 'confirmed',
}

export const Payments = () => {
  const [downloadStatus, setDownloadStatus] = useState(false);

  const [infoList, setInfoList] = useState<{ [key: string]: any } | null>({});
  const [infoListTotal, setInfoListTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [infoListLoading, setInfoListLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();

  const convertDateForQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const drawerRef = useRef<HTMLDivElement | null>(null);

  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [defaultDates, setDefaultDates] = useState([addDays(new Date(), -31), new Date()]);

  const [calendarState, setCalendarState] = useState<any>([
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: new Date(parsedSearch?.start_date as string),
          endDate: new Date(parsedSearch?.end_date as string),
          key: 'selection',
        }
      : {
          startDate: defaultDates[0],
          endDate: defaultDates[1],
          key: 'selection',
        },
  ]);

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const [locale, setLocale] = useState<any>('ko');

  const [option, setOption] = useState<any>(parsedSearch?.option ? parsedSearch?.option : 'paid');

  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '80%';
    }
  };

  const [pageLimit, setPageLimit] = useState(20);

  const fetchIamportPayments = async ({ pageParam = 1 }) => {
    let currentQueryStringObj: { [key: string]: any } = {
      offset: (pageParam - 1) * pageLimit,
      page: pageParam,
      limit: pageLimit,
      option: option,
      from: convertDateForQS(defaultDates[0]),
      to: convertDateForQS(defaultDates[1]),
    };

    for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
      let key = Object.keys(parsedSearch)[i];
      if (key === 'option') {
        currentQueryStringObj['option'] = parsedSearch[key];
      }
    }

    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      currentQueryStringObj['from'] = convertDateForQS(parsedSearch?.start_date);
      currentQueryStringObj['to'] = convertDateForQS(parsedSearch?.end_date);
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });
    setPaymentsDataStatus(true);
    try {
      let result = await api.get(`/admin/onda-payments/iamport/payments` + currentQueryString);
      setPaymentsDataStatus(false);
      if ([200].includes(result?.status)) {
        return result;
      } else {
        return null;
      }
    } catch (e: any) {
      toast.error(`${e?.response?.data?.message ?? 'error'}`);
      setPaymentsDataStatus(false);
      return null;
    }
  };

  const [paymentsDataStatus, setPaymentsDataStatus] = useState(false);

  const {
    data: paymentsData,
    fetchNextPage,
    isLoading: isPaymentsDataLoading,
  } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`adminOndaCampingIamportPaymentsPage`],
    fetchIamportPayments,
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;

        if (lastPage?.data?.items?.length > 0) {
          return nextPage;
        } else return undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleSearch = () => {
    let currentQueryStringObj: { [key: string]: any } = {
      option: option,
      start_date: convertDateForQS(defaultDates[0]),
      end_date: convertDateForQS(defaultDates[1]),
    };

    if (calendarState[0]?.startDate && calendarState[0]?.endDate) {
      currentQueryStringObj['start_date'] = convertDateForQS(calendarState[0]?.startDate);
      currentQueryStringObj['end_date'] = convertDateForQS(calendarState[0]?.endDate);
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    window.location.href = '/admin/v2/camping/iamport/payments' + currentQueryString;
  };

  const handleOptionChange = (e: any) => {
    setOption(e?.target?.value);
  };

  const handleMore = () => {
    fetchNextPage();
  };

  return (
    <div className="">
      {/* <input type="file" onChange={(e) => handleInputFile(e)} /> */}
      <div className="flex flex-col mb-[15px]">
        <div className="flex justify-between mb-[5px]">
          <div className="flex items-center">
            {calendarState[0]?.startDate && calendarState[0]?.endDate ? (
              <div className="mr-[5px]">
                {convertDateForQS(calendarState[0]?.startDate) +
                  ' ~ ' +
                  convertDateForQS(calendarState[0]?.endDate)}
              </div>
            ) : null}
            <Button
              onClick={() => {
                openDrawer();
              }}
              className="h-[42px] text-sm text-[black] font-[400] border border-[#ADB5BD] mr-[5px]"
            >
              기간설정
            </Button>
            <div>
              <select
                id="options"
                name="options"
                placeholder="choose an option"
                value={option}
                onChange={(e) => handleOptionChange(e)}
              >
                <option value="paid">paid</option>
                {/* <option value="checkin">checkin</option>
                <option value="confirmed">confirmed</option>
                <option value="canceled">canceled</option> */}
              </select>
            </div>
          </div>

          <Button
            className="h-[42px] text-sm text-[black] font-[400] border border-[#ADB5BD]"
            onClick={() => {
              handleSearch();
            }}
          >
            검색
          </Button>
        </div>

        <div className="flex justify-between mb-[5px]">
          <p className="text-[#f2303d]">*검색기간은 최대 90일까지입니다</p>
        </div>

        <div className="flex justify-between">
          <div>아임포트 결제 내역</div>
          <div>전체 수: {paymentsData?.pages[0]?.data?.total}개</div>
        </div>
      </div>
      <Table className="absolute">
        <Table.Head className="sticky top-[-25px] bg-[aliceblue]">
          <Table.Row>
            <Table.Th></Table.Th>
            {Object.keys(propertyDescriptionObj).map((key) => (
              <Table.Th key={key}>{propertyDescriptionObj[key]}</Table.Th>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {paymentsData?.pages && paymentsData?.pages[0]?.data?.items?.length > 0
            ? paymentsData?.pages.map((page, groupInd) =>
                page?.data?.items?.map((item: any, itemInd: number) => (
                  <>
                    <Table.Row key={item?.impUid}>
                      <Table.Td>{groupInd * pageLimit + (itemInd + 1)}</Table.Td>
                      <Table.Td>{item?.impUid}</Table.Td>
                      <Table.Td>{item?.paymentStatus}</Table.Td>
                      <Table.Td>{item?.merchantUid}</Table.Td>
                      <Table.Td>
                        {item?.ondaResData?.bookingNo ? (
                          <div>{item?.ondaResData?.bookingNo}</div>
                        ) : (
                          <div className="text-[#f2303d]">정보 없음</div>
                        )}
                      </Table.Td>
                      <Table.Td>
                        <p
                          className={`${
                            item?.ondaResData?.status == BookingStatus.confirmed
                              ? 'text-[#1ff018]'
                              : item?.ondaResData?.status == BookingStatus.canceled
                              ? 'text-[#f2303d]'
                              : ''
                          }`}
                        >
                          {item?.ondaResData?.status ? (
                            <div>{item?.ondaResData?.status}</div>
                          ) : (
                            <div className="text-[#f2303d]">정보 없음</div>
                          )}
                        </p>
                      </Table.Td>
                    </Table.Row>
                  </>
                ))
              )
            : null}
          <Button
            className="h-10 text-sm filled-black mt-[5px]"
            onClick={() => handleMore()}
            disabled={paymentsDataStatus}
          >
            {paymentsDataStatus ? <>...</> : <>더보기</>}
          </Button>
        </Table.Body>
      </Table>

      <div>
        <CustomDrawer drawerRef={drawerRef}>
          <div className="side-padding mt-[75px]">
            <Calendar
              locale={locale}
              state={calendarState}
              setState={setCalendarState}
              setChosenDates={setChosenDates}
              // drawerRef={drawerRef}
              confirmButton={false}
            />
          </div>
        </CustomDrawer>
      </div>
    </div>
  );
};
