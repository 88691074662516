import { useCallback, useEffect, useRef, useState } from 'react';
import { map, trim, uniq } from 'lodash';
import { parse, stringify } from 'qs';
import { useLocation } from 'react-router';

import { ProductCard } from '../components/ProductCard';
import { Search } from '../components/Search';
import { TopTitleBar } from '../components/TopTitleBar';
import { PAGE_LIMIT } from './products/constants';
import { api } from '../plugins/axios';
import { PRODUCTS_PATH } from '../paths/products';
import { useInfiniteQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { Product, Paginated } from '../types';
import { Section } from '../components/Section';
import { getItemInLocalStorage, LOCAL_STORAGE_KEY, setItemInLocalStorage } from '../localstroage';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { usePrevious } from '../hooks/common';
import {
  getRecentKeywordsLocalStorage,
  setRecentKeywordsLocalStorage,
} from '../api_v2/non_admin/search';
import { getProducts } from '../api_v2/non_admin/products';

export const SearchResultPage = () => {
  const history = useHistory();
  const lastRef = useRef<HTMLDivElement>(null);

  const { search } = useLocation();
  const { searchKeyword } = parse(search, { ignoreQueryPrefix: true });
  const prevSearchKeyword = usePrevious(searchKeyword);

  const [keyword, setKeyword] = useState<string>(searchKeyword?.toString() ?? '');
  const [recentKeywords, setRecentKeywords] = useState<string[]>([]);

  const [productsPage, setProductsPage] = useState<number>(1);
  const [products, setProducts] = useState<{ [key: string]: any }>({});
  const [productsCount, setProductsCount] = useState(0);
  const [productsLoading, setProductsLoading] = useState(false);

  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    getRecentKeywordsLocalStorage().then((resultData) => {
      if (resultData && Array.isArray(resultData)) {
        setRecentKeywords(resultData);
      }
    });
  }, []);

  // Initialize search input value to get param
  useEffect(() => {
    if (prevSearchKeyword !== searchKeyword) {
      setKeyword(searchKeyword.toString() ?? '');
    }
  }, [prevSearchKeyword, searchKeyword]);

  const _onSearchKeyword = () => {
    if (keyword.trim().length > 0) {
      let modifiedKeywords = [];
      if (recentKeywords) {
        modifiedKeywords = [keyword, ...recentKeywords];
      } else {
        modifiedKeywords = [keyword];
      }
      modifiedKeywords = uniq(modifiedKeywords);

      setProductsPage(1);
      setProducts({});
      setProductsCount(0);
      setProductsLoading(false);

      history.push(`/search/result?searchKeyword=${keyword}`);
      setRecentKeywords(modifiedKeywords);
      setRecentKeywordsLocalStorage(modifiedKeywords);
    } else {
      toast.error('검색어를 입력해주세요');
    }
  };

  useEffect(() => {
    const productsQueryString = stringify(
      {
        page: productsPage,
        limit: PAGE_LIMIT,
        sort: {
          // id: 'DESC',
          discountRate: 'DESC',
        },
        filter: {
          ...(searchKeyword?.length !== 0 && {
            word: searchKeyword,
            // $or: [
            //   { productName: { ilike: searchKeyword } },
            //   { brandName: { ilike: searchKeyword } },
            // ],
          }),
          isVisible: true,
          // available: true,
          // censoredAt: 'null',
        },
      },
      { addQueryPrefix: true, encode: false }
    );
    setProductsLoading(true);
    getProducts(productsQueryString).then((resultData) => {
      if (resultData?.success) {
        setProducts((prev) => {
          return { ...prev, [productsPage]: resultData.data.items };
        });
        setProductsCount(resultData.data.total);
        Promise.all([
          setProducts((prev) => {
            return { ...prev, [productsPage]: resultData.data.items };
          }),
          setProductsCount(resultData.data.total),
        ]).then(() => {
          setProductsLoading(false);
        });
      } else {
        // toast.error('Something went wrong getting products');
      }
    });
  }, [productsPage, searchKeyword]);

  const _productsLength = (currObj: any) => {
    let baseNumber = 0;
    Object.keys(currObj).forEach((key) => {
      baseNumber = baseNumber + currObj[key].length;
    });
    return baseNumber;
  };

  const lastElement = useCallback(
    (target) => {
      if (productsLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            _productsLength(products) < productsCount &&
            !productsLoading
          ) {
            setProductsPage((prev) => prev + 1);
          } else {
          }
        },
        {
          threshold: 1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [productsLoading, products, productsCount]
  );

  return (
    <>
      <TopTitleBar title="검색결과" />
      <div className="p-4 space-y-7">
        <Search
          placeholder="검색어를 입력해주세요."
          value={keyword}
          onSearch={_onSearchKeyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <h2>검색결과 {productsCount}개</h2>
      </div>
      {productsCount === 0 ? (
        <div className="text-center space-y-2 mt-20">
          <h2>검색 결과가 없습니다.</h2>
          <p className="text-sm">
            검색어가 올바르게 입력되었는지 확인해보세요.
            <br />
            또는 다른 필터를 선택하여 검색해보세요.
          </p>
        </div>
      ) : (
        <Section className="mt-4 bottom-padding">
          <div className="grid grid-cols-2 gap-3">
            {Object.keys(products).length > 0 &&
              Object.keys(products).map(
                (group, groupInd) =>
                  products[group] &&
                  products[group].map((product: any, productInd: number) => (
                    <>
                      {Object.keys(products).length === groupInd + 1 &&
                      products[group].length === productInd + 1 ? (
                        <div ref={lastElement}>
                          <ProductCard
                            key={product['id']}
                            product={product}
                            to={`/products/${product['productId']}`}
                            className="shadow-none"
                          />
                        </div>
                      ) : (
                        <>
                          <ProductCard
                            key={product['id']}
                            product={product}
                            to={`/products/${product['productId']}`}
                            className="shadow-none"
                          />
                        </>
                      )}
                    </>
                  ))
              )}
          </div>
        </Section>
      )}
    </>
  );
};
