import { newRidgeState } from 'react-ridge-state';
import { ClayfulCustomerShipping } from '../hooks/customer/type';
import {
  getItemInLocalStorage,
  LOCAL_STORAGE_KEY,
  removeItemInLocalStorage,
  setItemInLocalStorage,
} from '../localstroage';
import { getCartCount } from '../actions/carts';
import { get } from 'lodash';
import { NormalModalProps } from '../components/Modal/NormalModal';
import { FilterType } from '../pages/products/ProductsPage';
import { getUser, logout } from '../api_v2/non_admin/users';
import { getUserAdmin, logoutAdmin } from '../api_v2/admin/users';
import { Action, Bridge } from '../plugins/bridge';
import { PopupType1Props } from '../pages/dayout-project-1/camping/components/PopupType1';

export const historyState = newRidgeState<any>(null);
export const productFilterState = newRidgeState<string>(FilterType.WEIGHT);
export const meState = newRidgeState<{ [key: string]: any } | null>(null);
export const adminMeState = newRidgeState<{ [key: string]: any } | null>(null);

export const modalState = newRidgeState<NormalModalProps>({
  open: false,
  title: '',
  subtitle: '',
  onClose: () => {},
  onClick: () => {},
});

export const popupType1State = newRidgeState<PopupType1Props>({
  open: false,
  title: '',
  subtitle: '',
  onClose: () => {},
  onClick: () => {},
});

export const userObjState = newRidgeState<{ [key: string]: any } | null>(null, {
  onSet: async (newState: any) => {
    if (newState) {
    }
  },
});

export const authUserTokenObjState = newRidgeState<{ [key: string]: any } | null>(null, {
  onSet: async (newState: any) => {
    if (newState) {
      const { loginType, token } = newState;
      if (loginType && token) {
        localStorage.setItem('authUserTokenObj', JSON.stringify({ loginType, token }));
        let adminPage = window.location?.pathname?.startsWith('/admin');
        if (adminPage == true) {
        } else {
          let getUserRes = await getUser();
          if (getUserRes?.data) {
            userObjState.set(getUserRes?.data);
          }
        }
      } else {
        logout();
      }
    }
  },
});

export const tokenState = newRidgeState<string | null>(null, {
  onSet: async (newState) => {
    // if (newState) {
    //   removeItemInLocalStorage(LOCAL_STORAGE_KEY.ADMIN_TOKEN);
    //   localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, newState);
    //   let userInfoResult = await getUser();
    //   if (userInfoResult?.success) {
    //     meState.set(userInfoResult.data);
    //   } else {
    //     removeItemInLocalStorage(LOCAL_STORAGE_KEY.TOKEN);
    //     meState.reset();
    //     tokenState.reset();
    //   }
    // } else {
    // }
  },
});

export const adminTokenState = newRidgeState<string | null>(null, {
  onSet: async (newState: any) => {
    if (newState) {
      localStorage.setItem(LOCAL_STORAGE_KEY.ADMIN_TOKEN, newState);
      let adminPage = window.location?.pathname?.startsWith('/admin');
      if (adminPage == true) {
        let getUserAdminRes = await getUserAdmin();
        if (getUserAdminRes?.success && getUserAdminRes?.data) {
          meState.set(getUserAdminRes.data);
        } else {
          logoutAdmin();
        }
      }
    }
  },
});

// Device info from DB
export const deviceInfoState = newRidgeState<{ [key: string]: any } | null>(null, {
  onSet: async (newState) => {
    if (newState) {
    } else {
    }
  },
});

export const deviceInfoTokenState = newRidgeState<string | null>(null, {
  onSet: async (newState) => {
    if (newState) {
      localStorage.setItem(LOCAL_STORAGE_KEY.DEVICE_INFO_TOKEN, newState);
    } else {
    }
  },
});

export const currentDeviceInfoFromDeviceState = newRidgeState<{ [key: string]: any } | null>(null, {
  onSet: async (newState) => {
    if (newState) {
    } else {
    }
  },
});

export const productionInfoFromDBState = newRidgeState<{ [key: string]: any } | null>(null, {
  onSet: async (newState) => {
    if (newState) {
    } else {
    }
  },
});

export const scrollState = newRidgeState<{ [key: string]: any }>(
  {},
  {
    onSet: async (newState) => {
      if (newState) {
      } else {
      }
    },
  }
);

export const nonUserTokenState = newRidgeState<string | null>(null, {
  onSet: async (newState) => {
    if (newState) {
      localStorage.setItem(LOCAL_STORAGE_KEY.NON_MEMBER_TOKEN, newState);
    } else {
      removeItemInLocalStorage(LOCAL_STORAGE_KEY.NON_MEMBER_TOKEN);
    }
  },
});

export const resetMe = () => tokenState.set(tokenState.get());

export const kakaoTokenState = newRidgeState<string | null>(null, {
  onSet: (newState) => {
    if (newState) {
      localStorage.setItem(LOCAL_STORAGE_KEY.KAKAO_ACCESS_TOKEN, newState);
    } else {
      removeItemInLocalStorage(LOCAL_STORAGE_KEY.KAKAO_ACCESS_TOKEN);
    }
  },
});

export const osState = newRidgeState<string>('');
export const resetPasswordEmailState = newRidgeState<string>('');

// order
export const orderAddressState = newRidgeState<ClayfulCustomerShipping | null>(null);
export const orderRequestState = newRidgeState<string>('');

// nonMember order

export const nonMemberOrderAddressState = newRidgeState<ClayfulCustomerShipping | null>(null, {
  onSet: (newState) => {
    if (newState) {
      setItemInLocalStorage(LOCAL_STORAGE_KEY.NON_MEMBER_ADDRESS, newState);
    }
  },
});

/**
 * Cart count
 */
export const cartCountState = newRidgeState<number | null>(null);
export const updateCartCount = async (cartData?: any[]) => {
  let count = null;

  if (localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN)) {
    const { status, data } = await getCartCount();
    if (status == 200) {
      count = get(data?.count, 'raw');
    } else {
      console.error('Cart count cannot initialized'); // api 연동 후 주석 해제
    }
  } else {
    const nonMemberCartItems = cartData
      ? cartData
      : getItemInLocalStorage(LOCAL_STORAGE_KEY.CART_ITEM);
    if (nonMemberCartItems && Array.isArray(nonMemberCartItems)) {
      count = nonMemberCartItems.length;
    } else {
      console.error('Cart count cannot initialized'); // api 연동 후 주석 해제
    }
  }

  if (typeof count === 'number') {
    cartCountState.set(count);
  } else {
    console.error('Received cart count data is not typeof `number`'); // api 연동 후 주석 해제
  }
};

function setInitialState() {
  const adminToken = localStorage.getItem(LOCAL_STORAGE_KEY.ADMIN_TOKEN);
  const deviceInfoToken = localStorage.getItem(LOCAL_STORAGE_KEY.DEVICE_INFO_TOKEN);
  const authUserTokenObj = localStorage.getItem('authUserTokenObj');
  // const fcmToken = localStorage.getItem(LOCAL_STORAGE_KEY.FCM_TOKEN);
  // const nonMemberToken = localStorage.getItem(LOCAL_STORAGE_KEY.NON_MEMBER_TOKEN);
  // const kakaoAccessToken = localStorage.getItem(LOCAL_STORAGE_KEY.KAKAO_ACCESS_TOKEN);
  // const nonMemberAddress = getItemInLocalStorage(LOCAL_STORAGE_KEY.NON_MEMBER_ADDRESS);
  // updateCartCount();

  adminTokenState.set(adminToken);
  deviceInfoTokenState.set(deviceInfoToken);
  if (authUserTokenObj && JSON.parse(authUserTokenObj)) {
    let parsed = JSON.parse(authUserTokenObj);
    authUserTokenObjState.set(parsed);
  }
  // fcmTokenState.set(fcmToken);
  // nonUserTokenState.set(nonMemberToken);
  // kakaoTokenState.set(kakaoAccessToken);
  // nonMemberAddress && nonMemberOrderAddressState.set(nonMemberAddress);
}

setInitialState();
