import { parse, stringify } from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import { addDays } from 'date-fns';
import { DatePicker, Space } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { api } from '../../../../../plugins/axios';

import { Button } from '../../../../../components/Button';
import { Table } from '../../../../../components/Table';

const { RangePicker } = DatePicker;

const propertyHeadObj: { [key: string]: any } = {
  id: 'ID',
  name: '숙소명',
  status: '상태 (온다)',
  isVisible: '노출 여부 (관리자)',
};

export const Properties = () => {
  const drawerRef = useRef<HTMLDivElement | null>(null);

  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [defaultDates, setDefaultDates] = useState([new Date(), addDays(new Date(), 30)]);

  const [calendarState, setCalendarState] = useState<any>([
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: new Date(parsedSearch?.start_date as string),
          endDate: new Date(parsedSearch?.end_date as string),
          key: 'selection',
        }
      : {
          startDate: defaultDates[0],
          endDate: defaultDates[1],
          key: 'selection',
        },
  ]);

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const [locale, setLocale] = useState<any>('ko');

  const [pageLimit, setPageLimit] = useState(50);

  const [isVisible, setIsVisible] = useState(
    typeof parsedSearch?.is_visible == 'string' && parsedSearch?.is_visible?.length > 0
      ? parsedSearch?.is_visible
      : ''
  );

  const [moreItemsStatus, setMoreItemsStatus] = useState(true);

  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '80%';
    }
  };

  const fetchProperties = async ({ pageParam = 1 }) => {
    let currentQueryStringObj: { [key: string]: any } = {
      offset: (pageParam - 1) * pageLimit,
      limit: pageLimit,
      filter: { status: true },
      sort: { id: 'DESC' },
    };

    for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
      let key = Object.keys(parsedSearch)[i];
      if (key === 'search_query') {
        currentQueryStringObj.filter['word'] = parsedSearch[key];
      } else if (key === 'is_visible') {
        currentQueryStringObj.filter['isVisible'] = parsedSearch[key];
      }
    }

    if (pageParam - 2 < 0) {
      // pageParam == 1
    } else {
      // pageParam >= 2
      let prevInd = pageParam - 2;
      if ((propertiesData as any)?.pages[prevInd]?.data?.items?.length >= 0) {
        let lastPage = propertiesData?.pages[prevInd] as any;
        let lastItem = lastPage?.data?.items[lastPage?.data?.items?.length - 1];

        currentQueryStringObj.lastAutoGenId = lastItem?.auto_generated_id;
      } else return;
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    try {
      let result = await api.get(`/admin/onda-properties${currentQueryString}`);

      if ([200].includes(result?.status)) {
        if (result?.data?.items?.length == 0) {
          setMoreItemsStatus(false);
        }
        return result;
      } else {
        return null;
      }
    } catch (e) {
      console.log('e', e);
      toast.error('error');
      return null;
    }
  };

  const {
    data: propertiesData,
    fetchNextPage,
    isLoading: propertiesDataIsLoading,
  } = useInfiniteQuery<any, AxiosError>([`adminOndaCampingPropertiesPage`], fetchProperties, {
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages?.length + 1;
      if (lastPage?.data?.items?.length > 0) {
        return nextPage;
      } else if (lastPage?.data?.items?.length == 0) {
        return undefined;
      } else return allPages?.length;
    },
    keepPreviousData: true,
    // staleTime: 3600000,
    // cacheTime: 3600000,
    // refetchOnWindowFocus: false,
  });

  const [handleSearchQS, setHandleSearchQS] = useState<any | null>('');

  const handleSearch = (queryStrObj: { [key: string]: any }) => {
    const queryString = stringify(queryStrObj, {
      addQueryPrefix: true,
      encode: false,
      arrayFormat: 'brackets',
    });
    if (queryString == handleSearchQS) {
    } else {
    }
    setHandleSearchQS(queryString);

    window.location.href = '/admin/v2/camping/properties' + queryString;
  };

  const handleMore = () => {
    fetchNextPage();
  };

  const [campingSearchInput, setCampingSearchInput] = useState(parsedSearch?.search_query ?? '');

  const handleCampingSearchInput = (e: any) => {
    setCampingSearchInput(e.target.value);
  };

  const handleSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      const tempQueryObj: { [key: string]: any } = {};
      if ((campingSearchInput as string)?.trim()?.length > 0) {
        tempQueryObj['search_query'] = (campingSearchInput as string)?.trim() ?? '';
      }
      if (typeof isVisible == 'string' && isVisible?.length > 0) {
        tempQueryObj['is_visible'] = isVisible;
      }

      handleSearch(tempQueryObj);
    }
  };

  const handleCampingSearchButton = (evt: any) => {
    const tempQueryObj: { [key: string]: any } = {};

    if ((campingSearchInput as string)?.trim()?.length > 0) {
      tempQueryObj['search_query'] = (campingSearchInput as string)?.trim() ?? '';
    }

    if (typeof isVisible == 'string' && isVisible?.length > 0) {
      tempQueryObj['is_visible'] = isVisible;
    }

    handleSearch(tempQueryObj);
  };

  const handleVisibleChange = (e: any) => {
    setIsVisible(e?.target?.value);
  };

  return (
    <div>
      <div className="flex flex-col mb-[15px]">
        <div className="w-full max-w-[750px] p-[15px] border-[1px] rounded-[5px] mb-[15px]">
          <div className="w-full mb-[5px]">
            <input
              className="flex-1 h-[42px] w-full max-w-[450px] px-[5px] mr-[5px] text-sm bg-white border border-[#ADB5BD]"
              placeholder="검색하기"
              onChange={(e) => handleCampingSearchInput(e)}
              value={campingSearchInput as string}
              onKeyDown={(e) => handleSearchKeyDown(e)}
            />
          </div>
          <div className="flex items-center">
            <p className="mr-[5px]">노출 여부 (관리자)</p>
            {/* <input
              type="checkbox"
              name="isVisible"
              checked={isVisible}
              onChange={(e) => handleVisibleChange(e)}
            /> */}
            <select
              name="isVisible"
              placeholder="노출여부"
              value={isVisible as any}
              onChange={(e) => handleVisibleChange(e)}
            >
              <option value="">전체</option>
              <option value="true">Y</option>
              <option value="false">N</option>
            </select>
          </div>
          <div className="flex justify-end">
            <Button
              className="h-[42px] text-sm text-[black] font-[400] border border-[#ADB5BD]"
              onClick={(e) => {
                handleCampingSearchButton(e);
              }}
            >
              검색
            </Button>
          </div>
        </div>
        <div className="flex justify-between">
          <div>숙소 목록</div>
        </div>
      </div>
      <Table className="absolute">
        <Table.Head className="sticky top-[-25px] bg-[aliceblue]">
          <Table.Row>
            <Table.Th></Table.Th>
            {Object.keys(propertyHeadObj).map((key) => (
              <Table.Th key={key}>{propertyHeadObj[key]}</Table.Th>
            ))}
            <Table.Th></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {propertiesData?.pages[0]?.data?.items?.length > 0 ? (
            propertiesData?.pages.map(
              (page, groupInd) =>
                page?.data?.items &&
                page?.data?.items.map((item: any, itemInd: number) => (
                  <>
                    <Table.Row key={item?.id}>
                      <Table.Td>{groupInd * pageLimit + (itemInd + 1)}</Table.Td>
                      <Table.Td>{item?.id}</Table.Td>
                      <Table.Td>{item?.name}</Table.Td>
                      <Table.Td>{item?.status}</Table.Td>
                      <Table.Td>{item?.is_visible == 1 ? 'Y' : 'N'}</Table.Td>
                      <Table.Td>
                        <Link to={`/admin/v2/camping/properties/${item?.id}`}>자세히 보기</Link>
                      </Table.Td>
                    </Table.Row>
                  </>
                ))
            )
          ) : propertiesData?.pages[0]?.data?.items?.length == 0 ? (
            <Table.Row>
              <td colSpan={6}>
                <p className="flex justify-center p-[10px]">숙소가 존재하지않습니다</p>
              </td>
            </Table.Row>
          ) : null}

          {/* <div ref={lastElement}></div> */}
        </Table.Body>
        {moreItemsStatus ? (
          <Button
            disabled={propertiesDataIsLoading}
            className="h-10 w-max text-sm filled-black mt-[5px]"
            onClick={() => handleMore()}
          >
            {propertiesDataIsLoading ? '...' : '더보기'}
          </Button>
        ) : (
          <Table.Row>
            <td colSpan={2} className="pt-[25px]">
              <p className="font-[700]">마지막 페이지입니다</p>
            </td>
          </Table.Row>
        )}
      </Table>
    </div>
  );
};
