import React, { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';

const BannerCreateHtmlData = () => {
  const [fileData, setFileData] = useState<any>(null);

  const onDrop = (acceptedFiles: any) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const modifiedData = jsonData.map((row: any) => [...row]);

      setFileData(modifiedData);
    };

    reader.readAsArrayBuffer(file);
  };

  const generateHtmlString = () => {
    if (!fileData || fileData.length === 0) {
      return null;
    }

    const htmlString = fileData
      .slice(1)
      .map((row: any, rowIndex: any) => {
        const div = document.createElement('div');
        div.className = 'banner';

        row.forEach((cell: any, cellIndex: any) => {
          const p = document.createElement('p');

          let chosenColList = [
            'name',
            'link',
            'mainImageUrl',
            'maxDiscountRate',
            'addr',
            'city',
            'shortAddr',
          ];
          if (chosenColList?.includes(fileData[0][cellIndex])) {
            p.className = fileData[0][cellIndex];
            p.textContent = cell;
            div.appendChild(p);
          }
        });

        return div.outerHTML;
      })
      .join('\n');

    return htmlString;
  };

  const generateHtml = () => {
    if (!fileData || fileData.length === 0) {
      return null;
    }

    return fileData.slice(1).map((row: any, rowIndex: any) => (
      <div key={rowIndex} className="banner">
        {row.map((cell: any, cellIndex: any) => (
          <p key={cellIndex} className={fileData[0][cellIndex]}>
            {cell}
          </p>
        ))}
      </div>
    ));
  };

  const copyToClipboard = () => {
    let htmlString = generateHtmlString();

    navigator.clipboard.writeText(htmlString).then(() => {
      alert('HTML copied to clipboard!');
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.xlsx, .xls' as any,
  });

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag and drop an Excel file here, or click to select one</p>
      </div>

      {fileData && (
        <div>
          <h2>Modified Data</h2>
          {generateHtml()}
          <button onClick={copyToClipboard}>Copy HTML to Clipboard</button>
        </div>
      )}
    </div>
  );
};

const dropzoneStyles: any = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default BannerCreateHtmlData;
