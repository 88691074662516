import { stringify } from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../../../../../../components/Button';

import { getCafe24ProductDetailVideoDataList } from '../../../../../../../api_v2/admin/cafe24';

export const Videos = () => {
  const [infoList, setInfoList] = useState<{ [key: string]: any } | null>({});
  const [infoListTotal, setInfoListTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [infoListLoading, setInfoListLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();

  // prob1
  useEffect(() => {
    let currentQueryStringObj: { [key: string]: any } = {
      page: page,
      limit: pageLimit,
    };

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    setInfoListLoading(true);

    getCafe24ProductDetailVideoDataList(currentQueryString).then((res) => {
      if (res?.success == true && res?.data) {
        setInfoList((prev) => {
          return { ...prev, [page]: res?.data?.items };
        });
        if (typeof res?.data?.total == 'number') {
          setInfoListTotal(res?.data?.total);
        }
        setPage(page + 1);
      }
      setInfoListLoading(false);
    });
  }, []);

  const calculateCurrListLen = (objData: any) => {
    let finalCount = 0;
    for (let i = 0; i < Object.keys(objData).length; i++) {
      let key = Object.keys(objData)[i];
      finalCount = finalCount + objData[key]?.length ?? 0;
    }
    return finalCount;
  };

  const lastElement = useCallback(
    (target) => {
      if (infoListLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            infoList &&
            calculateCurrListLen(infoList) < infoListTotal &&
            !infoListLoading
          ) {
            let currentQueryStringObj: { [key: string]: any } = {
              page: page,
              limit: pageLimit,
            };

            const currentQueryString = stringify(currentQueryStringObj, {
              addQueryPrefix: true,
              encode: false,
            });

            setInfoListLoading(true);

            getCafe24ProductDetailVideoDataList(currentQueryString).then((res) => {
              if (res?.success == true && res?.data) {
                setInfoList((prev) => {
                  return { ...prev, [page]: res?.data?.items };
                });
                if (typeof res?.data?.total == 'number') {
                  setInfoListTotal(res?.data?.total);
                }
                setPage(page + 1);
              }
              setInfoListLoading(false);
            });
          } else {
          }
        },
        {
          threshold: 1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [infoListLoading, infoList, infoListTotal]
  );

  const getProductList = (productListData: any[]) => {
    let finalStr = '';
    if (productListData?.length > 0) {
      productListData.forEach((productObj, productObjIndx) => {
        let addItem = productObjIndx == 0 ? productObj?.productNo : `, ${productObj?.productNo}`;

        finalStr = finalStr + addItem;
      });
    }
    return finalStr;
  };

  return (
    <div>
      <div className="flex justify-end mb-[15px]">
        <Button
          onClick={() => {
            window.location.href = '/admin/cafe24/dayout/commerce/product-detail/videos/add';
          }}
          className="h-10 text-sm text-[black] outlined-gray-600"
        >
          Add
        </Button>
      </div>
      <div className="flex flex-col mb-[15px]">
        <div className="flex justify-between mb-[5px]"></div>
        <div className="flex justify-between">
          <div>상품 상세 페이지 동영상 리스트</div>
          <div>전체 수: {infoListTotal}개</div>
        </div>
      </div>

      {infoListTotal > 0 ? (
        <div className="grid grid-cols-8 gap-1">
          <div></div>
          <div>그룹명</div>
          <div>동영상 고유번호</div>
          <div>동영상 제목</div>
          <div></div>
          <div>동영상 노출 상품 리스트</div>
          <div>createdAt</div>
          <div></div>
          <div className="col-span-8 border-b"></div>
          {/* {cafe24UserDataCollection1List?.map((item) => (
            <div className="col-span-2 grid grid-cols-2">
              <div className="">{item?.memberId}</div>
              <div>{item?.cellphone}</div>
            </div>
          ))} */}
          {infoList && Object.keys(infoList as any)?.length > 0
            ? Object.keys(infoList as any).map(
                (page, groupInd) =>
                  infoList[page] &&
                  infoList[page].map((item: any, itemInd: number) => (
                    <>
                      {Object.keys(infoList as any)?.length == groupInd + 1 &&
                      infoList[page].length == itemInd + 1 ? (
                        <>
                          <div
                            ref={lastElement}
                            key={item?.id}
                            className="col-span-8 grid grid-cols-8"
                          >
                            <div>{groupInd * pageLimit + (itemInd + 1)}</div>
                            <div>{item?.groupName}</div>
                            <div>{item?.uniqueId}</div>
                            <div>{item?.name}</div>
                            <div className="flex justify-center">
                              <a href={item?.ytVideoUrl} target="blank">
                                동영상 보기
                              </a>
                            </div>
                            <div>
                              {item?.products?.length > 0 && getProductList(item?.products)}
                            </div>
                            <div>{item?.createdAt && new Date(item?.createdAt).toString()}</div>
                            <div className="flex justify-center">
                              <Link
                                to={`/admin/cafe24/dayout/commerce/product-detail/videos/${item?.id}`}
                              >
                                수정
                              </Link>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div ref={null} key={item?.id} className="col-span-8 grid grid-cols-8">
                            <div>{groupInd * pageLimit + (itemInd + 1)}</div>
                            <div>{item?.groupName}</div>
                            <div>{item?.uniqueId}</div>
                            <div>{item?.name}</div>
                            <div className="flex justify-center">
                              <a href={item?.ytVideoUrl} target="blank">
                                동영상 보기
                              </a>
                            </div>
                            <div>
                              {item?.products?.length > 0 && getProductList(item?.products)}
                            </div>
                            <div>{item?.createdAt && new Date(item?.createdAt).toString()}</div>
                            <div className="flex justify-center">
                              <Link
                                to={`/admin/cafe24/dayout/commerce/product-detail/videos/${item?.id}`}
                              >
                                수정
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))
              )
            : null}
        </div>
      ) : infoListTotal == 0 ? (
        <p className="flex justify-center">동영상을 추가해주세요</p>
      ) : (
        <p className="flex justify-center">Error</p>
      )}
    </div>
  );
};
