import { useEffect, useState } from 'react';
import { ProductCard } from '../components/ProductCard';
import { Section } from '../components/Section';
import { TopNavBar } from '../components/TopNavBar';
import { map, sortBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { useExhibition } from '../hooks/exhibition';
import useCountDown from 'react-countdown-hook';
import { INTERVAL } from './constants';
import { useKoreanTime } from '../hooks/common';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { getExhibition } from '../api_v2/non_admin/exhibitions';
export const EventsDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const [timeLeft, { start }] = useCountDown(0, INTERVAL);
  const { day, hour, minute, second } = useKoreanTime(timeLeft);

  const [exhibition, setExhibition] = useState<{ [key: string]: any } | null>(null);

  useEffect(() => {
    getExhibition(+id).then((resultData) => {
      if (resultData?.success) {
        setExhibition(resultData.data);
      }
    });
  }, []);

  if (!exhibition) {
    return null;
  }

  return (
    <>
      <TopNavBar nav={false} logo={false} homeButton backButton />
      {exhibition && (
        <>
          <FroalaEditorView model={exhibition.content} />
          {/* <div className="side-padding mt-12">
            <h2>{exhibition.title}</h2>
          </div> */}

          {/* {exhibitionData.endTime && (
        <div className="relative">
          <div className="bg-gray-100 w-full h-20 text-center pt-6">
            <h4 className="">기획전 종료까지 남은 시간</h4>
          </div>

          <h2 className="absolute top-14 bg-gray-800 px-8 h-16 text-white left-12 right-12 grid place-content-center">
            {`${day}일 ${hour} : ${minute} : ${second}`}
          </h2>
        </div>
      )} */}
          <Section className="mt-10">
            <div className="grid grid-cols-2 gap-3 px-3">
              {map(sortBy(exhibition.exhibitionProductMaps, 'order'), (exhibitionProductMap) => (
                <ProductCard
                  product={exhibitionProductMap.product}
                  className="shadow-none"
                  to={`/products/${exhibitionProductMap.product.productId}`}
                />
              ))}
            </div>
          </Section>
        </>
      )}
    </>
  );
};
