import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Admin } from './admin/Admin';

import { Router } from './pages/Router';
import 'react-toastify/dist/ReactToastify.css';
import { AdminLoginPage } from './admin/pages/AdminLoginPage';
import { NormalModal } from './components/Modal/NormalModal';
import { modalState } from './ridge/ridge';
import { AdminRoute } from './components/AdminRoute';
import { Action, Bridge } from './plugins/bridge';

// import 'antd/dist/antd.less';

/**
 * For social login SDK
 */
declare global {
  interface Window {
    Kakao: any;
    naver: any;
  }
}

function App() {
  const modal = modalState.useValue();

  // useEffect(() => {
  //   window.Kakao.init(process.env.REACT_APP_KAKAO_DEVELOPERS_JAVASCRIPT_KEY);
  // }, []);

  // for every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      Bridge.postMessage(Action.SET_FCM_TOKEN, '');
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (window?.Kakao?.isInitialized() == false) {
      if (process.env.REACT_APP_KAKAO_DEVELOPERS_JAVASCRIPT_KEY) {
        window?.Kakao?.init(process.env.REACT_APP_KAKAO_DEVELOPERS_JAVASCRIPT_KEY);
        // console.log('kakao init');
      } else {
        console.log('no kakao js key');
      }
    }
  }, []);

  return (
    <>
      {/* <ScrollToTop /> */}
      <Switch>
        <Route path="/admin/login" component={AdminLoginPage} />
        <AdminRoute path="/admin" component={Admin} />
        <Route path="/" component={Router} />
      </Switch>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={3}
      />
      {modal && <NormalModal {...modal} />}
    </>
  );
}

export default App;
