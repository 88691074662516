export const campingTypesDummyData = [
  { name: '오토캠핑', icon: '오토캠핑아이콘' },
  { name: '글램핑', icon: '글램핑아이콘' },
  { name: '카라반', icon: '카라반아이콘' },
  { name: '펜션', icon: '펜션아이콘' },
  { name: '아이동반', icon: '키즈캠핑아이콘' },
  { name: '애견동반', icon: '애견캠핑아이콘' },
];

export const dropdownCampingTypesDummyData = ['오토캠핑', '카라반', '펜션', '아이동반', '애견동반'];

export const dropdownRegionOptionsDummyData = [
  '서울특별시',
  '부산광역시',
  '인천광역시',
  '대구광역시',
  '대전광역시',
  '광주광역시',
  '울산광역시',
  '세종특별자치시',
  '경기도',
  '강원도',
  '충청북도',
  '충청남도',
  '전라북도',
  '전라남도',
  '경상북도',
  '경상남도',
  '제주특별자치도',
];

export const campingDummyData = [
  {
    id: 1,
    type: '오토캠핑',
    name: '경기 오토캠핑장',
    region: '경기도',
    tags: ['A', 'B', 'C'],
    price: 50000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 2,
    type: '오토캠핑',
    name: '김씨네 오토 캠핑장',
    region: '경기도',
    tags: ['A'],
    price: 24000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 3,
    type: '오토캠핑',
    name: '남부 오토장',
    region: '경상남도',
    tags: ['C'],
    price: 30000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 4,
    type: '오토캠핑',
    name: '우리 캠핑',
    region: '서울특별시',
    tags: ['B'],
    price: 440000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 5,
    type: '오토캠핑',
    name: '서울 캠핑파티',
    region: '서울특별시',
    tags: ['A', 'B', 'C'],
    price: 10000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 6,
    type: '오토캠핑',
    name: '캠핑 시랑',
    region: '부산광역시',
    tags: ['A', 'B', 'C'],
    price: 2000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 7,
    type: '오토캠핑',
    name: '캠핑 맛집',
    region: '전라북도',
    tags: ['A', 'B', 'C'],
    price: 1000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  { id: 8, type: '글램핑', name: '글램핑 그룹', region: '경기도', tags: ['A'], price: 100 },
  {
    id: 9,
    type: '글램핑',
    name: '경상 캠핑장',
    region: '경상남도',
    tags: ['B', 'C'],
    price: 1110000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 10,
    type: '펜션',
    name: '서울 펜션',
    region: '서울특별시',
    tags: ['A', 'B', 'C'],
    price: 80000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  { id: 11, type: '아이동반', name: '키즈 캠핑', region: '서울특별시', tags: ['C'], price: 6000 },
  {
    id: 12,
    type: '아이동반',
    name: '가족 여행',
    region: '경기도',
    tags: ['A', 'B', 'C'],
    price: 1000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 13,
    type: '아이동반',
    name: '부산 어묵 캠핑',
    region: '부산광역시',
    tags: ['A', 'B', 'C'],
    price: 666000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 14,
    type: '아이동반',
    name: '전라도 캠핑장',
    region: '전라북도',
    tags: ['A', 'B', 'C'],
    price: 900,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 15,
    type: '아이동반',
    name: '모두 여행',
    region: '경상남도',
    tags: ['A', 'B', 'C'],
    price: 12000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 16,
    type: '애견동반',
    name: '반려견과 함께 하는 여행',
    region: '경기도',
    tags: ['A', 'B', 'C'],
    price: 500,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 17,
    type: '애견동반',
    name: '애견 캠핑',
    region: '전라북도',
    tags: ['A', 'B', 'C'],
    price: 90000,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
  {
    id: 18,
    type: '애견동반',
    name: 'Busan 캠핑장',
    region: '부산광역시',
    tags: ['A', 'B', 'C'],
    price: 100,
    phone: '000-0000-0000',
    address: 'address info',
    description: 'description info',
    facility: 'facility info',
    additionalFacility: 'additional facility info',
    mannerTime: 'manner time info',
    policy: 'policy info',
    zones: [
      {
        id: 1,
        zone: 'A',
        opening_hours: 'opening hours info',
        campnic: true,
        longterm_camping: false,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: true,
        camping_vehicles: false,
      },
      {
        id: 2,
        zone: 'B',
        opening_hours: 'opening hours info',
        campnic: false,
        longterm_camping: true,
        min_people: 'min number of people info',
        additional_people: 'additional number of people info',
        max_people: 'max number of people info',
        max_car: 'max number of cars info',
        trailer: false,
        camping_vehicles: true,
      },
    ],
  },
];

export const campingImagesDummyData = [
  'https://upload.wikimedia.org/wikipedia/commons/a/ae/White_Fiat_Ducato_Campervan_2006.jpg',
  'https://upload.wikimedia.org/wikipedia/commons/7/73/Tent_camping_along_the_Sulayr_trail_in_La_Taha%2C_Sierra_Nevada_National_Park_%28DSCF5147%29.jpg',
];
