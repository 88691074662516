import { FC } from 'react';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';

import { ReactComponent as Home2Icon } from '../assets/icons/icon-home2.svg';
import { ReactComponent as X2Icon } from '../assets/icons/icon-x2.svg';
import { ReactComponent as ArrowLeft2Icon } from '../assets/icons/icon-arrow-left2.svg';

import { useHistory, useLocation } from 'react-router-dom';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { Action, Bridge } from '../plugins/bridge';

interface TopNavBarProps {
  nav?: boolean;
  logo?: boolean;
  title?: string;
  backButton?: boolean;
  homeButton?: boolean;
  xButton?: boolean;
  search?: boolean;
  cart?: boolean;
}

const OVER_NUM = 100;
const MASKING_CHAR = 'N';

export const TopNavBar: FC<TopNavBarProps> = ({
  nav = true,
  logo = true,
  backButton,
  homeButton,
  xButton,
  search = true,
  cart = true,
  title,
}) => {
  const { pathname } = useLocation();
  const { push, goBack } = useHistory();
  const history = useHistory();

  const _handleGoBack = (e: any) => {
    goBack();
    // Bridge.postMessage(Action.WEBVIEW_GO_BACK, '');
    // if (isAndroid) {
    //   // goBack();
    // } else if (isIOS) {
    //   Bridge.postMessage(Action.WEBVIEW_GO_BACK, '');
    // }
  };

  return (
    <div className="sticky top-0 z-30">
      <nav className="relative p-4 flex justify-between items-center z-30 bg-white w-full md:max-w-md">
        {logo && (
          <button onClick={() => push('/home')}>
            <Logo />
          </button>
        )}
        {backButton && (
          <div className="flex items-center space-x-6">
            <button onClick={(e) => _handleGoBack(e)} className="flex space-x-2 items-center">
              <ArrowLeft2Icon className="wh-6" />
            </button>
          </div>
        )}
        {homeButton && (
          <button
            className="flex"
            onClick={() => {
              push('/home');
            }}
          >
            <Home2Icon className="wh-6" />
          </button>
        )}
        {title && (
          <h1 className="absolute-left">
            <pre>{title}</pre>
          </h1>
        )}

        {xButton && (
          <button className="" onClick={goBack}>
            <X2Icon className="wh-6" />
          </button>
        )}
        {/* {logo ? (
          <button onClick={() => push('/home')}>
            <Logo />
          </button>
        ) : backButton ? (
          <div className="flex items-center space-x-6">
            <button onClick={goBack} className="flex space-x-2 items-center">
              <Icon.ChevronLeft />
            </button>
          </div>
        ) : homeButton ? (
          <button
            className=""
            onClick={() => {
              push('/home');
            }}
          >
            <Home className="wh-6" />
          </button>
        ) : xButton ? (
          <button
            className=""
            // onClick={() => {
            //   push('/home');
            // }}
          >
            <X className="wh-6" />
          </button>
        ) : (
          <>
            <div className="" />
          </>
        )} */}

        {/* <div className="flex items-center space-x-4">
          {search && (
            <>
              <button className="relative" onClick={() => push('/search')}>
                <SearchIcon />
              </button>
            </>
          )}
          {cart && (
            <button onClick={() => push('/cart')} className="relative">
              <CartIcon />
              {typeof cartCount === 'number' && cartCount > 0 && (
                <div className="absolute z-10 top-0 right-0 bg-black text-white text-center p-1 w-5 h-5 inline-block rounded-full text-14 p-0">
                  {OVER_NUM <= cartCount ? MASKING_CHAR : cartCount}
                </div>
              )}
            </button>
          )}
        </div> */}
      </nav>

      {/* {nav && (
        <Swiper
          className="mySwiper side-padding md:max-w-md bg-white z-30 py-2 mx-0 w-full border-b border-gray-100"
          slidesPerView="auto"
          spaceBetween={22}
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          freeMode={true}
        >
          {tabs.map((tab) => (
            <SwiperSlide className="w-auto">
              <NavLink
                to={tab.pathname}
                className={`w-max py-2  ${
                  pathname.startsWith(tab.pathname)
                    ? 'text-gray-900 font-semibold border-b-[2.5px] border-brand-1'
                    : 'text-gray-500 font-light'
                }`}
              >
                {tab.name}
              </NavLink>
            </SwiperSlide>
          ))}
        </Swiper>
      )} */}
    </div>
  );
};
