import { api } from '../../../plugins/axios';
import { authUserTokenObjState, meState, tokenState } from '../../../ridge/ridge';
import { Role } from '../../../types';

export const getUser = async () => {
  const resetProcess = () => {
    logout();
    // window.location.href = '/camping';
  };
  try {
    let result = await api.get('/auth/user');

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result.status)) {
      if (result?.data?.userInfo || result?.data?.role == Role.ADMIN) {
        meState.set(result?.data);
        return { success: true, data: result.data };
      } else {
        resetProcess();
        return { success: false };
      }
    } else {
      resetProcess();
      return { success: false };
    }
  } catch (e: any) {
    // need to fix prob: successful response gets caught in catch
    if (e?.response) {
      resetProcess();
    } else {
    }

    console.log('e', e);
  }
};

export const loginApple = async (token: string) => {
  try {
    tokenState.set(token);
    // window.location.href = '/home';
  } catch (e) {
    console.log('e', e);
  }
};

export const logout = async () => {
  try {
    localStorage.removeItem('authUserTokenObj');

    authUserTokenObjState.reset();
    meState.reset();
  } catch (e) {
    console.log('e', e);
  }
};
