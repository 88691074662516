import { ReactComponent as CampingMainPageIcon1 } from '../../../../../assets/icons/icon-camping-2.svg';
import { ReactComponent as CampingMainPageIcon2 } from '../../../../../assets/icons/icon-glamping.svg';
import { ReactComponent as CampingMainPageIcon3 } from '../../../../../assets/icons/icon-caravan.svg';
import { ReactComponent as CampingMainPageIcon4 } from '../../../../../assets/icons/icon-pension.svg';
import { ReactComponent as CampingMainPageIcon5 } from '../../../../../assets/icons/camping-mainpage-icon5.svg';
import { ReactComponent as CampingMainPageIcon6 } from '../../../../../assets/icons/camping-mainpage-icon6.svg';

export const campingTypes = ['', '오토캠핑', '글램핑', '카라반', '펜션', '아이동반', '애견동반'];

export const campingTypesObj = [
  { name: '오토캠핑', icon: CampingMainPageIcon1 },
  { name: '글램핑', icon: CampingMainPageIcon2 },
  { name: '카라반', icon: CampingMainPageIcon3 },
  { name: '펜션', icon: CampingMainPageIcon4 },
  { name: '키즈캠핑', icon: CampingMainPageIcon5 },
  { name: '애견캠핑', icon: CampingMainPageIcon6 },
];

export const campingTypesObj2 = [
  { name: '캠핑', icon: CampingMainPageIcon1 },
  { name: '글램핑', icon: CampingMainPageIcon2 },
  { name: '카라반', icon: CampingMainPageIcon3 },
  { name: '펜션', icon: CampingMainPageIcon4 },
];

export const regions = [
  '',
  '강원',
  '경기',
  '경남',
  '경북',
  '광주',
  '대구',
  '대전',
  '부산',
  '서울',
  '울산',
  '인천',
  '전남',
  '전북',
  '제주',
  '충남',
  '충북',
  '세종',
];

export const regions2 = {
  강원: [
    '양양군',
    '철원군',
    '영월군',
    '양구군',
    '태백시',
    '화천군',
    '횡성군',
    '평창군',
    '고성군',
    '삼척시',
    '홍천군',
    '동해시',
    '강릉시',
    '정선군',
    '속초시',
    '원주시',
    '춘천시',
    '인제군',
  ],
  경기: [
    '이천시',
    '여주시',
    '성남시',
    '안성시',
    '용인시',
    '남양주시',
    '고양시',
    '부천시',
    '군포시',
    '김포시',
    '안산시',
    '포천시',
    '양평군',
    '구리시',
    '가평군',
    '광주시',
    '수원시',
    '평택시',
    '안양시',
    '동두천시',
    '의왕시',
    '의정부시',
    '하남시',
    '과천시',
    '광명시',
    '양주시',
    '화성시',
    '파주시',
    '시흥시',
    '연천군',
    '오산시',
  ],
  경남: [
    '양산시',
    '산청군',
    '김해시',
    '합천군',
    '창원시',
    '남해군',
    '진주시',
    '함양군',
    '창녕군',
    '거제시',
    '하동군',
    '함안군',
    '거창군',
    '의령군',
    '통영시',
    '사천시',
    '밀양시',
  ],
  경북: [
    '청송군',
    '안동시',
    '상주시',
    '포항시',
    '군위군',
    '봉화군',
    '칠곡군',
    '성주군',
    '청도군',
    '구미시',
    '문경시',
    '울릉군',
    '울진군',
    '경산시',
    '영덕군',
    '경주시',
    '영천시',
    '예천군',
    '의성군',
    '영주시',
    '김천시',
  ],
  광주: ['광산구'],
  대구: ['달서구', '달성군', '남구', '수성구'],
  대전: ['유성구', '대덕구'],
  부산: [
    '동래구',
    '기장군',
    '수영구',
    '연제구',
    '해운대구',
    '부산진구',
    '사하구',
    '사상구',
    '영도구',
    '금정구',
  ],
  서울: [
    '도봉구',
    '영등포구',
    '구로구',
    '성동구',
    '강남구',
    '강동구',
    '서초구',
    '금천구',
    '양천구',
    '서대문구',
    '강북구',
    '중랑구',
    '동대문구',
    '성북구',
    '용산구',
    '마포구',
    '노원구',
    '관악구',
    '송파구',
    '은평구',
    '동작구',
    '종로구',
    '강서구',
    '광진구',
  ],
  울산: ['동구', '북구', '울주군'],
  인천: ['부평구', '서구', '연수구', '계양구', '옹진군', '남동구', '미추홀구', '중구', '강화군'],
  전남: [
    '무안군',
    '순천시',
    '구례군',
    '보성군',
    '목포시',
    '완도군',
    '강진군',
    '영광군',
    '고흥군',
    '장성군',
    '나주시',
    '함평군',
    '해남군',
    '곡성군',
    '영암군',
    '여수시',
    '화순군',
    '진도군',
    '광양시',
    '담양군',
    '신안군',
    '장흥군',
  ],
  전북: [
    '전주시',
    '부안군',
    '임실군',
    '순창군',
    '김제시',
    '군산시',
    '완주군',
    '정읍시',
    '장수군',
    '고창군',
    '무주군',
    '남원시',
    '진안군',
    '익산시',
  ],
  제주: ['제주시', '서귀포시'],
  충남: [
    '보령시',
    '천안시',
    '홍성군',
    '논산시',
    '서산시',
    '서천군',
    '청양군',
    '예산군',
    '금산군',
    '아산시',
    '당진시',
    '계룡시',
    '태안군',
    '부여군',
    '공주시',
  ],
  충북: [
    '음성군',
    '증평군',
    '충주시',
    '청주시',
    '진천군',
    '영동군',
    '단양군',
    '보은군',
    '옥천군',
    '제천시',
    '괴산군',
  ],
  세종: ['세종시'],
};
