import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';

import { v4 as uuidv4 } from 'uuid';

import { Button } from '../../../../components/Button';

import { authUserTokenObjState } from '../../../../ridge/ridge';

import { ReactComponent as IconTrashImg } from '../../../../assets/icons/icon-trash.svg';
import { ReactComponent as IconUpdateImg } from '../../../../assets/icons/icon-update.svg';
import { ReactComponent as IconImageUploadImg } from '../../../../assets/icons/icon-image-upload.svg';
import { Icon } from '../../../../components/Icon';

import { createCommunityPost, uploadImages } from '../../../../api_v2/non_admin/community';
import { toast } from 'react-toastify';

const categoryNameObj: { [key: string]: any } = {
  domesticTravel: '국내여행',
  internationalTravel: '해외여행',
  domesticFestival: '국내축제',
  internationalFestival: '해외축제',
  etc: '기타',
};

enum CategoryType {
  DOMESTIC_TRAVEL = 'domesticTravel',
  INTERNATIONAL_TRAVEL = 'internationalTravel',
  DOMESTIC_FESTIVAL = 'domesticFestival',
  INTERNATIONAL_FESTIVAL = 'internationalFestival',
  ETC = 'etc',
}

const postSortNameObj: { [key: string]: any } = {
  recent: '최신순',
  oldest: '오래된순',
  mostCommented: '댓글순',
};

enum PostSortType {
  RECENT = 'recent',
  OLDEST = 'oldest',
  MOST_COMMENTED = 'mostCommented',
}

export default function PostFilter(props: any) {
  const authUserTokenObj = authUserTokenObjState.useValue();
  const [openCate, setOpenCate] = useState(true);

  const [categories, setCategories] = useState<any>({
    [CategoryType.DOMESTIC_TRAVEL]: false,
    [CategoryType.INTERNATIONAL_TRAVEL]: false,
    [CategoryType.DOMESTIC_FESTIVAL]: false,
    [CategoryType.INTERNATIONAL_FESTIVAL]: false,
    [CategoryType.ETC]: false,
  });
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [searchPostStatus, setSearchPostStatus] = useState<any>(false);

  const [openSort, setOpenSort] = useState(true);

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;

    setCategories({
      ...categories,
      [name]: checked,
    });
  };

  useEffect(() => {
    const newSelectedCategories = Object.keys(categories).filter(
      (category: any) => categories[category]
    );
    setSelectedCategories(newSelectedCategories);
  }, [categories]);

  useEffect(() => {
    let copiedCategories = { ...categories };
    if (props?.chosenCategories?.length > 0) {
      for (let i = 0; i < props.chosenCategories.length; i++) {
        let chosenCate = props.chosenCategories[i];
        copiedCategories[chosenCate] = true;
      }
      setCategories((prev: any) => {
        return { ...prev, ...copiedCategories };
      });
    }
  }, [props?.chosenCategories]);

  function SortOptions(passedObj: any) {
    const handleOptionChange = (event: any) => {
      const selectedOption = event.target.value;
      passedObj?.onSortChange(selectedOption);
    };

    return (
      <div className="flex">
        {Object.keys(postSortNameObj).map((postSortKey) => (
          <label key={postSortKey} className="mr-[5px]">
            <input
              className="mr-[5px]"
              type="radio"
              value={postSortKey}
              checked={passedObj?.selectedOption === postSortKey}
              onChange={handleOptionChange}
            />
            {postSortNameObj[postSortKey]}
          </label>
        ))}
      </div>
    );
  }

  const handleSortChange = (selectedOption: any) => {
    props?.setSortedBy(selectedOption);
  };

  return (
    <div>
      <div className="mb-[10px]">
        <div
          onClick={() => setOpenCate(!openCate)}
          className="flex justify-between pb-4 cursor-pointer"
        >
          <h4>카테고리</h4>
          <Icon.ChevronDown
            className={`${openCate ? 'rotate-180' : ''} transition-transform z-[0]`}
          />
        </div>
        {openCate && (
          <div className="flex flex-wrap">
            {Object.keys(categories).map((category) => (
              <>
                <label className="mr-[7px] my-[5px]">
                  <input
                    className="mr-[7px]"
                    type="checkbox"
                    name={category}
                    checked={categories[category]}
                    onChange={handleCheckboxChange}
                  />
                  {categoryNameObj[category]}
                </label>
              </>
            ))}
          </div>
        )}
        <div
          onClick={() => setOpenSort(!openSort)}
          className="flex justify-between py-4 cursor-pointer"
        >
          <h4>정렬</h4>
          <Icon.ChevronDown
            className={`${openSort ? 'rotate-180' : ''} transition-transform z-[0]`}
          />
        </div>
        {openSort ? (
          <>
            <div>
              <SortOptions selectedOption={props?.sortedBy} onSortChange={handleSortChange} />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <Button
        onClick={() => {
          props.handleFilterSearch({ categories: selectedCategories, sort: props?.sortedBy });
        }}
        className="w-full h-[50px] mb-[25px] rounded font-bold text-[#FFFFFF] bg-[#FF6400] flex justify-center items-center"
      >
        {searchPostStatus ? '검색중...' : '검색'}
      </Button>
    </div>
  );
}
