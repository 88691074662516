import React, { FC, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';

import { Button } from '../../components/Button';
import { Icon } from '../../components/Icon';
import { ProductCard } from '../../components/ProductCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { Markup } from 'interweave';

interface ProductInfoSectionProps {
  product?: any;
  productInfoRef?: any;
  productsSameCategoryBest?: any[];
}

export const ProductInfoSection: FC<ProductInfoSectionProps> = ({
  product: currProduct,
  productInfoRef,
  productsSameCategoryBest,
}) => {
  const detailRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  // const [sameCategoryBestProducts, setSameCategoryBestProducts] = useState<
  //   { [key: string]: any }[]
  // >([]);

  // useEffect(() => {
  //   if (productsSameCategoryBest) {
  //     setSameCategoryBestProducts(
  //       productsSameCategoryBest.reduce(function (filtered, product) {
  //         if (product.productId != currProduct._id) {
  //           filtered.push(product);
  //         }
  //         return filtered;
  //       }, [])
  //     );
  //   }
  // }, [productsSameCategoryBest]);

  // const [productDescription, setProductDescription] = useState<any>([]);

  // useEffect(() => {
  //   if (currProduct?.description) {
  //     let parser = new DOMParser();
  //     let parsedHtml = parser.parseFromString(
  //       currProduct?.description,
  //       'text/html'
  //     ).querySelectorAll('img, p, .ta-insert-video');
  //     // ).querySelectorAll('img.se-image-resource, p.se-text-paragraph, .ta-insert-video');
  //     // let productDetailElements = Array.from(parsedHtml.getElementByTagName('body').item(0)?.children ?? []);
  //     setProductDescription(parsedHtml);
  //   }
  // }, [currProduct?.description]);

  // // Append product detail elements
  // useEffect(() => {
  //   if (detailRef && productDescription) {
  //     productDescription.forEach((item: any) => {
  //       detailRef.current?.appendChild(item)
  //     })
  //   }
  // }, [detailRef, productDescription])

  return (
    <div ref={productInfoRef}>
      <h2>상세 정보</h2>

      <div className={`${open ? ' h-fit' : 'h-72'} relative overflow-hidden pt-4 -mx-4`}>
        {!open && (
          <div className="bottom-0 z-10 absolute bg-gradient-to-t from-black/10 to bg-transparent w-full h-1/2" />
        )}
        {currProduct?.content && <Markup content={currProduct?.content} />}

        {/* <div className="bg-gray-100 w-full">{parse(currProduct?.content)}</div> */}
        {/* <div ref={detailRef} className="bg-gray-100 w-full overflow-auto"></div> */}
      </div>

      <Button
        onClick={() => setOpen(!open)}
        className="flex space-x-2 items-center justify-center w-full border-b border-t"
      >
        <p> {open ? '상세 접기' : '상세 더보기'}</p>
        <Icon.ChevronDown className={`${open ? 'rotate-180' : ''} transition-transform`} />
      </Button>

      {/* <div className="space-y-2 mt-6">
        {sameCategoryBestProducts && sameCategoryBestProducts.length > 0 && (
          <>
            <h2>동일 카테고리 내 BEST</h2>
            <Swiper
              className="mySwiper pb-12"
              slidesPerView={1.8}
              spaceBetween={20}
            >
              {sameCategoryBestProducts.map((product: any) => (
                <SwiperSlide className="w-55vw md:w-60" key={product['_id']}>
                  <ProductCard product={product} hasPadding={false} />
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}
      </div> */}
    </div>
  );
};
