import { useEffect, useState, useRef, useCallback } from 'react';
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import { parse, stringify } from 'qs';

import { toast } from 'react-toastify';

import { default as htmlReactParser, attributesToProps } from 'html-react-parser';

import { Section } from '../../../../../components/Section';
import { TopTitleBar } from './components/TopTitleBar';

import { CustomDrawer } from './CustomDrawer';

import { Form, Input, Button as AntdButton } from 'antd';
import { checkBooking } from '../../../../../api_v2/non_admin/booking';
import {
  cancelReservationForNonmemberV2,
  checkBookingV2,
  getCampingRefundPolicyBeforeReservationV2,
  requestResetBookingPasswordForNonmeberV2,
} from '../../../../../api_v2/non_admin/onda';
import dayjs from 'dayjs';
import { Button } from '../../../../../components/Button';
import { modalState } from '../../../../../ridge/ridge';

// testing
SwiperCore.use([Pagination, Autoplay]);

const erroMsgObj: { [key: string]: any } = {
  'A reservation does not exist': '예약번호 혹은 이메일을 다시 확인해주세요.',
  'Already canceled': '이미 취소된 예약입니다',
};

export const RequestResetPasswordPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [form] = Form.useForm();
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const { campingId, zoneId } = useParams<{
    campingId: string;
    zoneId: string;
  }>();
  const [campingSite, setCampingSite] = useState<any>(null);
  const [campingSiteZone, setCampingSiteZone] = useState<any>(null);

  const [reservationData, setReservationData] = useState<any>(null);

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const { imp_uid, merchant_uid, imp_success, error_msg } = parsedSearch;

  const [drawerType, setDrawerType] = useState<any>(null);
  const [cancelReason, setCancelReason] = useState<string>('');

  const [requestResetStatus, setRequestResetStatus] = useState<boolean>(false);

  const onFinish = (values: any) => {
    if (values['order-number']?.trim()?.length > 0 && values['email']?.trim()?.length > 0) {
      setRequestResetStatus(true);
      requestResetBookingPasswordForNonmeberV2({
        channelBookingNo: values['order-number']?.trim(),
        email: values['email']?.trim(),
      })
        .then((res) => {
          if (res?.success == true && res?.data?.message == 'successfully sent email w/ link') {
            toast.success('입력한 이메일로 비밀번호 재설정 링크가 전송되었습니다.');
          } else if (res?.success == false && res?.message) {
            toast.error(erroMsgObj[res?.message] ?? 'error');
          } else if (res?.success == false) {
            toast.error('error');
          }
          setRequestResetStatus(false);
        })
        .catch((e) => {
          console.log('e', e);
          setRequestResetStatus(false);
        });
    } else if (values['order-number']?.trim()?.length == 0) {
      toast.error('예약번호를 확인해주세요.');
    } else if (values['email']?.trim()?.length == 0) {
      toast.error('이메일을 확인해주세요.');
    }
  };

  return (
    <>
      <TopTitleBar logo={true} home={false} />
      <>
        <div className="max-w-md w-screen side-padding bg-[#FFFFFF]">
          <Section className="max-w-md py-3 mt-[100px] bg-[#FFFFFF]">
            <div className="flex flex-col justify-center items-center">
              <div className="text-[18px] text-center">
                <p>예약 비밀번호 재설정을 위해</p>
                <p>
                  <span className="font-[700]">예약 번호</span>와
                  <span className="font-[700]"> 예약 시 입력한 이메일</span>을 입력해주세요.
                </p>
              </div>
            </div>
          </Section>
          <Section className="max-w-md py-3 mt-[40px] bg-[#FFFFFF]">
            <Form form={form} name="dynamic_rule" onFinish={onFinish}>
              <div className="grid grid-cols-3 mb-3">
                <Form.Item
                  className="col-span-3 m-0"
                  name="order-number"
                  rules={[
                    {
                      required: true,
                      message: '예약번호를 입력해주세요',
                    },
                  ]}
                >
                  <Input style={{ maxHeight: '35px' }} placeholder="예약번호를 입력해주세요" />
                </Form.Item>
              </div>
              <div className="grid grid-cols-3 mb-3">
                <Form.Item
                  className="col-span-3 m-0"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: '이메일을 입력해주세요',
                    },
                    {
                      required: true,
                      message: '이메일을 입력해주세요',
                    },
                  ]}
                >
                  <Input style={{ maxHeight: '35px' }} placeholder="이메일을 입력해주세요" />
                </Form.Item>
              </div>

              <div className={`bg-white z-20 w-full max-w-md flex space-x-2 px-4 mt-2 h-16`}>
                <Form.Item noStyle>
                  <AntdButton
                    // type="text"
                    id="booking-page-button"
                    className="my-2 rounded font-bold text-[#FFFFFF] bg-[#FF6400]
                    flex-1 flex justify-center items-center"
                    htmlType="submit"
                    disabled={requestResetStatus}
                  >
                    {requestResetStatus ? (
                      <>
                        <div className="loading-dots-type1"></div>
                      </>
                    ) : (
                      '이메일로 비밀번호 찾기'
                    )}
                  </AntdButton>
                </Form.Item>
              </div>
            </Form>
          </Section>
        </div>
      </>
    </>
  );
};
