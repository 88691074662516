import { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Icon } from '../../components/Icon';
import { Section } from '../../components/Section';
import { TopNavBar } from '../../components/TopNavBar';

import { ProductInfoSection } from './ProductInfoSection';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import { currentDeviceInfoFromDeviceState } from '../../ridge/ridge';

import { toast } from 'react-toastify';
import { Product } from '../../types';

import { getProduct } from '../../api_v2/non_admin/products';
import { productPageLinkState, productScrapState } from '../../ridge/products';
import {
  addBookmarkedProduct,
  checkBookmarkedProduct,
  removeBookmarkedProduct,
} from '../../api_v2/non_admin/bookmarks';
import { ReactComponent as ActiveScrapIcon2 } from '../../assets/icons/scrap-active2.svg';
import { ReactComponent as ScrapIcon2 } from '../../assets/icons/scrap2.svg';

import useCountDown from 'react-countdown-hook';
import { Action, Bridge } from '../../plugins/bridge';
import { isAndroid, isIOS } from 'react-device-detect';

SwiperCore.use([Pagination, Autoplay]);

enum SectionTarget {
  TYPE_0 = '상품리뷰',
  TYPE_1 = '상세정보',
  TYPE_2 = '안내/문의',
}

const initialTime = 2000;
const interval = 100;

export const ProductPage = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const reviewSection = useRef<any>();
  const productInfoSection = useRef<any>();
  const inquirySection = useRef<any>();
  const { productId } = useParams<{ productId: string }>();

  const [product, setProduct] = useState<Product | null>(null);

  const [selectedTab, setSelectedTab] = useState<SectionTarget>(SectionTarget.TYPE_0);

  // const [scrap, setScrap] = useState(false);

  const [scrap, setScrap] = productScrapState.use();

  const [productPageLink, setProductPageLink] = productPageLinkState.use();

  const [bookmarkedProductStatus, setBookmarkedProductStatus] = useState(false);

  const [currentDeviceInfoFromDevice, setCurrentDeviceInfoFromDevice] =
    currentDeviceInfoFromDeviceState.use();

  const [progressBarStatus, setProgressBarStatus] = useState(false);
  const [timeLeft, { start, pause, resume, reset }] = useCountDown(initialTime, interval);

  const externalUrl = useRef<string | null>(null);
  const externalUrlType = useRef<string | null>(null);
  const redirectName = useRef<string | null>(null);

  const _checkUrl = (urlStr: string) => {
    let url;
    try {
      url = new URL(urlStr);
    } catch (e) {
      console.log('checkUrl error: ', e);
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  };

  useEffect(() => {
    checkBookmarkedProduct(productId).then((resultData) => {
      if (resultData) {
        setScrap(true);
      } else {
        setScrap(false);
      }
    });
  }, []);

  const handleBookmarkedProduct = async () => {
    setBookmarkedProductStatus(true);
    if (scrap) {
      let removeBookmarkedProductResult = await removeBookmarkedProduct(productId);
      if (removeBookmarkedProductResult?.success) {
        toast.success('성공적으로 찜하기에서 삭제하였습니다.');
        setBookmarkedProductStatus(false);
        setScrap(false);
      } else {
        setBookmarkedProductStatus(false);
      }
    } else {
      let addBookmarkedProductResult = await addBookmarkedProduct(productId);
      if (addBookmarkedProductResult?.success) {
        toast.success('찜하기에서 확인해주세요.');
        setBookmarkedProductStatus(false);
        setScrap(true);
      } else {
        setBookmarkedProductStatus(false);
        toast.error(addBookmarkedProductResult?.data);
      }
    }
  };

  // const { data: returnedBanners, status: returnedBannersStatus } = useQuery<any, any>(
  //   ['HOME_PAGE_BANNERS'],
  //   getBanners
  // );

  // useEffect(() => {
  //   if (returnedBanners?.success && returnedBanners?.data) {
  //     setAllBanners(_.sortBy(returnedBanners.data, 'weight').reverse());
  //   }
  // }, [returnedBanners]);

  useEffect(() => {
    getProduct(productId).then((resultData) => {
      if (resultData?.success) {
        if (resultData?.data && resultData.data.length > 0) {
          setProduct(resultData.data[0]);
        }
      }
    });
  }, []);

  const handleScroll = (scrollTarget: SectionTarget) => {
    let tempTarget: any;
    setSelectedTab(scrollTarget);
    switch (scrollTarget) {
      case SectionTarget.TYPE_0:
        tempTarget = reviewSection;
        break;
      case SectionTarget.TYPE_1:
        tempTarget = productInfoSection;
        break;
      case SectionTarget.TYPE_2:
        tempTarget = inquirySection;
        break;
      default:
        console.log(`Something went wrong.`);
    }
    const yOffset = -150;
    const calculatedTop =
      tempTarget.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: calculatedTop, behavior: 'smooth' });
  };

  useEffect(() => {
    Bridge.postMessage(Action.GET_CURRENT_DEVICE_INFO, '');
  }, []);

  const _handleRedirect = (typeData: string, urlData: string) => {
    externalUrl.current = urlData;
    externalUrlType.current = typeData;
    setProgressBarStatus(true);
    start();
  };

  useEffect(() => {
    if (progressBarStatus && externalUrl?.current && externalUrlType?.current && timeLeft === 0) {
      let toAppFunc = () => {
        checkBookmarkedProduct(productId).then((resultData) => {
          let scrapStatus = false;
          if (resultData) {
            scrapStatus = true;
          } else {
            scrapStatus = false;
          }
          setTimeout(() => {
            Bridge.postMessage(
              Action.OPEN_MODAL_WITH_URL,
              JSON.stringify({
                url: externalUrl.current,
                productId,
                scrapStatus,
              })
            );
            setProgressBarStatus(false);
          }, 1000);
        });
      };

      let toWebFunc = () => {
        setTimeout(() => {
          history.push(`/productpagelink/${externalUrlType?.current}/${productId}`);
          setProgressBarStatus(false);
        }, 1000);
      };

      let checkVersion = (arr: any[], firstN: number, secondN: number, thirdN: number) => {
        if (parseInt(arr[0]) > firstN) {
          return true;
        } else if (parseInt(arr[0]) < firstN) {
          return false;
        } else {
          if (parseInt(arr[1]) > secondN) {
            return true;
          } else if (parseInt(arr[1]) < secondN) {
            return false;
          } else {
            if (parseInt(arr[2]) > thirdN) {
              return true;
            } else if (parseInt(arr[2]) < thirdN) {
              return false;
            } else {
              return true;
            }
          }
        }
      };

      if (currentDeviceInfoFromDevice) {
        const { version } = currentDeviceInfoFromDevice;
        // with version
        if (version) {
          let versionArr = version.split('.');

          if (isAndroid) {
            // new version android
            if (checkVersion(versionArr, 1, 0, 2)) {
              toAppFunc();
            } else {
              // prev version android
              toWebFunc();
            }
          } else if (isIOS) {
            // new version android
            if (checkVersion(versionArr, 1, 0, 3)) {
              toAppFunc();
            } else {
              // prev version android
              toWebFunc();
            }
          }
        }
      }
      // no version
      else {
        toWebFunc();
      }
    }
  }, [timeLeft, progressBarStatus, externalUrl?.current, externalUrlType?.current]);

  return (
    <>
      {progressBarStatus ? (
        <div>
          <div className="flex justify-center items-center h-screen">
            <div className="w-full px-16">
              <p className="text-[#2168F5] mb-4">{redirectName.current && redirectName.current}</p>
              <div className="bg-[#E5E8EB] rounded-[50px]">
                <div
                  style={{
                    width: ((initialTime - timeLeft) / initialTime) * 100 + '%',
                    height: '5px',
                    background: '#2168F5',
                    borderRadius: '50px',
                  }}
                />
              </div>
            </div>
            <div className="fixed bottom-0 pb-20 px-8 w-full max-w-md text-[#868E96]">
              프릿지는 상품 판매에 직접 관여하지 않으며, 상품의 주문, 배송, 환불의 의무와 책임은
              해당 스토어에 있습니다.
            </div>
          </div>
        </div>
      ) : (
        <>
          <TopNavBar nav={false} logo={false} title="" backButton homeButton />
          {product && (
            <>
              <div className="max-w-md w-screen bg-gray-100">
                <Section>
                  <Swiper
                    pagination={true}
                    autoplay={{
                      delay: 3000,
                    }}
                    className="mySwiper bg-white"
                  >
                    <SwiperSlide>
                      <div className="bg-gray-100 aspect-w-1 aspect-h-1">
                        <img
                          src={product?.thumbnail}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </Section>

                <div className="px-4 pb-4 bg-white">
                  <span className="text-gray-600 text-xs">{product?.brandName}</span>
                  <span className="truncate-2-lines text-base">{product?.productName}</span>
                  <div className="flex justify-start">
                    <h1 className="text-brand-1">
                      {product?.price != product?.discountPrice
                        ? (
                            ((product?.price - product?.discountPrice) / product?.price) *
                            100
                          ).toFixed(0) + '%\u00a0'
                        : null}
                    </h1>

                    <div className="flex space-x-2 items-center justify-start">
                      {product?.price != product?.discountPrice ? (
                        <>
                          <h1 className="">{product?.discountPrice.toLocaleString()}원</h1>
                          <span className="text-sm text-gray-400 line-through">
                            {product?.price.toLocaleString()}원
                          </span>
                        </>
                      ) : (
                        <>
                          <h1 className="">
                            {product?.price && product?.price.toLocaleString()}원
                          </h1>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {((product?.priceMap && product.priceMap[0].name) || product?.usedSellerLink) && (
                  <div className="my-2 flex flex-col p-4 bg-white">
                    <h2>가격비교</h2>
                    {product?.priceMap &&
                      product.priceMap[0].name &&
                      product.priceMap.map((priceElem: any) => (
                        <div className="flex justify-between mt-4">
                          <div>
                            <span className="text-md">{priceElem?.name}</span>
                            <span className="ml-1 bg-gray-50 border-black rounded-sm p-0.5 border text-xs inline-block">
                              새 상품
                            </span>
                          </div>
                          <button
                            className="flex justify-end w-56"
                            onClick={() => {
                              setProductPageLink(priceElem?.link);

                              redirectName.current = '판매처 이동중';
                              // _handleRedirect(`/productpagelink/officialLink/${product.productId}`);
                              if (priceElem?.link) {
                                _handleRedirect('officialLink', priceElem?.link);
                              }
                            }}
                          >
                            <span className="text-base text-gray-600">공식 판매가</span>
                            <div className="w-7/12 flex justify-end">
                              <h3>{priceElem?.price.toLocaleString()}원</h3>
                              <Icon.ChevronRight />
                            </div>
                          </button>
                        </div>
                      ))}
                    {product?.usedSellerName && (
                      <div className="flex justify-between mt-4">
                        <div>
                          <span className="text-md">{product?.usedSellerName}</span>
                          <div className="ml-2 bg-blue-50 border-blue-500 rounded-sm p-0.5 border text-xs inline-block text-blue-500">
                            중고
                          </div>
                        </div>
                        <button
                          className="flex justify-end w-56"
                          onClick={() => {
                            setProductPageLink(product?.usedSellerLink);

                            redirectName.current = '중고판매처 이동중';
                            // _handleRedirect(`/productpagelink/usedSellerLink/${product.productId}`);
                            if (product?.usedSellerLink) {
                              _handleRedirect('usedSellerLink', product?.usedSellerLink);
                            }
                          }}
                        >
                          <span className="text-base text-gray-600">중고 판매가</span>
                          <div className="w-7/12 flex justify-end">
                            <h3>{product?.usedPrice.toLocaleString()}원</h3>
                            <Icon.ChevronRight />
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <div className="mt-2 flex flex-col side-padding space-y-8 bg-white p-4">
                  {/* <ReviewSection product={product} reviewRef={reviewSection} /> */}
                  <ProductInfoSection productInfoRef={productInfoSection} product={product} />
                  {/* <InquirySection inquiryRef={inquirySection} product={product} /> */}
                </div>
                <div className="pb-20" />
              </div>
              <div
                className={`fixed bottom-0 bg-white z-20 w-full max-w-md flex space-x-2 px-4 h-16`}
              >
                <button
                  disabled={bookmarkedProductStatus}
                  className="px-8"
                  onClick={() => handleBookmarkedProduct()}
                >
                  {scrap ? <ActiveScrapIcon2 /> : <ScrapIcon2 />}
                </button>

                <button
                  onClick={() => {
                    setProductPageLink(product?.referenceLink);
                    redirectName.current = '최저가 이동중';
                    // _handleRedirect(`/productpagelink/referenceLink/${product.productId}`);
                    if (product?.referenceLink) {
                      _handleRedirect('referenceLink', product?.referenceLink);
                    }
                  }}
                  className="my-2 rounded bg-black text-gray-50 font-bold flex-1 flex justify-center items-center"
                >
                  최저가 사이트로 이동
                </button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
