import { Link, useLocation } from 'react-router-dom';
import { Card } from '../../../../../components/Card';
import { Pagination } from '../../../../../components/Pagination';
import { Table } from '../../../../../components/Table';
import { AdminH1 } from '../../../../components/AdminH1';
import { Button } from '../../../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../../../plugins/moment';
import { useAdminBanner } from '../../../../../hooks/banner';
import { map } from 'lodash';
import { useQueryString } from '../../../../../hooks/hooks';
import { useEffect, useState } from 'react';
import { getCommunityBanners } from '../../../../../api_v2/admin/community';
import { parse, stringify } from 'qs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Icon } from '../../../../../components/Icon';

export const BannerList = () => {
  const queryString = useQueryString();
  const [currBanners, setCurrBanners] = useState<{ [key: string]: any }[]>([]);
  const [totalBannersCount, setTotalBannersCount] = useState<number>(0);
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    getCommunityBanners(`?page=${parsed?.page ?? 1}&limit=${parsed?.limit ?? 10}`).then(
      (resultData: any) => {
        if (resultData?.success) {
          if (resultData.data) {
            if (resultData.data[0]) {
              let bannerListData = resultData.data[0];
              if (bannerListData?.length > 0) {
                let modifiedBanners = bannerListData.map((bannerObj: any) => {
                  let htmlStrData = bannerObj?.data;
                  let addObj: any = {};
                  if (htmlStrData) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(htmlStrData, 'text/html');

                    const bannerContainerEle = doc.querySelector('.bannersContainer') as any;

                    addObj['end'] = bannerContainerEle?.dataset?.end;
                    addObj['event'] = bannerContainerEle?.dataset?.event;
                    addObj['renamedEvent'] = bannerContainerEle?.dataset?.renamedEvent;
                    addObj['url'] = bannerContainerEle?.dataset?.url;

                    let bannerEles = bannerContainerEle.querySelectorAll('.banner');

                    addObj['numbOfItems'] = bannerEles?.length ?? 0;
                  }

                  return { ...bannerObj, ...addObj };
                });

                setCurrBanners(modifiedBanners ?? resultData.data[0]);
                setTotalBannersCount(resultData.data[1]);
              }
            }
          }
        }
      }
    );
  }, [search]);

  const [eventProdSearchInput, setEventProdSearchInput] = useState('');
  const handleEventProdSearchInput = (e: any) => {
    setEventProdSearchInput(e.target.value);
  };

  const [searchedEventProd, setSearchedEventProd] = useState<any>(null);

  const handleSearch = async () => {
    try {
      setSearchedEventProd(null);
      if (eventProdSearchInput?.trim()?.length > 0) {
        getCommunityBanners(
          `?page=${parsed?.page ?? 1}&limit=${parsed?.limit ?? 10}&q=${
            eventProdSearchInput?.trim() ?? ''
          }`
        ).then((resultData: any) => {
          if (resultData?.success) {
            if (resultData.data) {
              if (resultData.data[0]) {
                let bannerListData = resultData.data[0];
                if (bannerListData?.length > 0) {
                  let bannerData = bannerListData[0];

                  let htmlStrData = bannerData?.data;
                  let modifiedObj: any = {};
                  if (htmlStrData) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(htmlStrData, 'text/html');

                    const bannerContainerEle = doc.querySelector('.bannersContainer') as any;

                    modifiedObj['end'] = bannerContainerEle?.dataset?.end;
                    modifiedObj['event'] = bannerContainerEle?.dataset?.event;
                    modifiedObj['renamedEvent'] = bannerContainerEle?.dataset?.renamedEvent;
                    modifiedObj['url'] = bannerContainerEle?.dataset?.url;

                    let bannerEles = bannerContainerEle.querySelectorAll('.banner');

                    let tempList = [];
                    for (let i = 0; i < bannerEles?.length; i++) {
                      let bannerEle = bannerEles[i];
                      let tempObj = {
                        city: (bannerEle?.querySelector('.city') as HTMLElement)?.innerText,
                        shortAddr: (bannerEle?.querySelector('.shortAddr') as HTMLElement)
                          ?.innerHTML,
                        name: (bannerEle?.querySelector('.name') as HTMLElement)?.innerHTML,
                        maxDiscountRate: (
                          bannerEle?.querySelector('.maxDiscountRate') as HTMLElement
                        )?.innerHTML,
                        mainImageUrl: (bannerEle?.querySelector('.mainImageUrl') as HTMLElement)
                          ?.innerHTML,
                        link: (bannerEle?.querySelector('.link') as HTMLElement)?.innerHTML,
                      };
                      tempList.push(tempObj);
                    }

                    let foundItem = tempList.find((item) =>
                      item?.name?.includes(eventProdSearchInput?.trim())
                    );
                    setSearchedEventProd({ ...modifiedObj, ...foundItem });
                  }

                  // setCurrBanners(modifiedBanners ?? resultData.data[0]);
                  // setTotalBannersCount(resultData.data[1]);
                }
              }
            }
          }
        });
      } else {
        toast.error('상품명을 입력해주세요');
      }
    } catch (e) {}
  };

  const handleEventProdSearchButton = (evt: any) => {
    handleSearch();
  };

  const handleSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      handleSearch();
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>배너 관리</AdminH1>
        <div className="flex gap-[5px]">
          <Button
            text="배너 추가"
            className="outlined-black text-sm"
            to="/admin/community/banners/add"
          />
          <Button
            text="배너 html data 생성"
            className="outlined-black text-sm"
            to="/admin/community/banners/create-html-data"
          />
        </div>
      </div>

      {/* <button
        onClick={openModal}
        className="w-[100px] bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        상품 검색
      </button> */}

      {isModalOpen && (
        <div className="fixed inset-0 overflow-y-auto flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-800 opacity-50" onClick={closeModal}></div>
          <div className="relative modal-overlay">
            <div className="modal bg-white p-4 rounded shadow-md">
              <button className="absolute top-0 right-0 p-2 cursor-pointer" onClick={closeModal}>
                X
              </button>
              <h2 className="text-2xl font-bold my-[30px]">
                <div>
                  <div
                    className="flex flex-row-reverse w-[30%] min-w-[400px] items-center px-4 h-12 mt-2
                border border-[black] bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
                  >
                    <Button
                      className="bg-[#FF6400] text-[#FFFFFF]"
                      style={{ height: '80%' }}
                      onClick={(e) => handleEventProdSearchButton(e)}
                    >
                      검색
                    </Button>
                    {eventProdSearchInput ? (
                      <>
                        <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                          <Icon.X
                            className="wh-3"
                            onClick={() => {
                              setEventProdSearchInput('');
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                    <input
                      className="flex-1 text-sm bg-white"
                      placeholder="상품 검색하기"
                      onChange={(e) => handleEventProdSearchInput(e)}
                      value={eventProdSearchInput as string}
                      onKeyDown={(e) => handleSearchKeyDown(e)}
                    />
                  </div>
                </div>
                <div>{searchedEventProd && <>{searchedEventProd?.name}</>}</div>
              </h2>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-end items-center">
        <div className="flex space-x-2 items-center">
          <div>전체배너 수</div>
          <div className="text-green-500">{totalBannersCount}</div>
        </div>
        <div>개</div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>Unique id</Table.Th>
              <Table.Th>이벤트</Table.Th>
              <Table.Th>Renamed event</Table.Th>
              <Table.Th>상품수</Table.Th>
              <Table.Th>기간</Table.Th>
              <Table.Th>노출</Table.Th>
              <Table.Th>링크</Table.Th>
              <Table.Th>생성일</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(currBanners, (banner) => (
              <Table.Row key={banner.id}>
                <Table.Td>{banner.id}</Table.Td>
                <CopyToClipboard
                  text={banner.uniqueId as string}
                  onCopy={() => {
                    toast.success('Copied');
                  }}
                >
                  <Table.Td>
                    <>{banner.uniqueId}</>
                  </Table.Td>
                </CopyToClipboard>
                <Table.Td>{banner.event}</Table.Td>
                <Table.Td>{banner?.renamedEvent}</Table.Td>
                <Table.Td>{banner?.numbOfItems}</Table.Td>
                <Table.Td>{banner.end}</Table.Td>
                <Table.Td>{banner?.isVisible ? 'Y' : 'N'}</Table.Td>
                <Table.Td>
                  <a
                    onClick={() => {
                      window.open(banner?.url, '_blank');
                    }}
                  >
                    {banner.url}
                  </a>
                </Table.Td>
                <Table.Td>
                  {utcToLocalFormat(banner.createdAt.toLocaleString(), MomentFormat.YYYYMMDDHmm)}
                </Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-blue-500"
                    to={`/admin/community/banners/${banner.id}`}
                  >
                    수정하기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          {/* <Pagination.Label
            count={bannerData.items.length}
            total={bannerData.total}
          /> */}
          <Pagination.Nav basePath="/admin/community/banners" total={totalBannersCount} />
        </Pagination>
      </Card>
    </>
  );
};
