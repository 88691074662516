import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EventProductsMore } from './EventProductsMore';
import { Icon } from './Icon';
import { ProductCard } from './ProductCard';
import { ProductItemWrap } from './ProductItemWrap';

interface EventProductsProps {
  productFamilies: any[];
  event: any;
}

export const EventProducts: FC<EventProductsProps> = ({ productFamilies, event }) => {
  const history = useHistory();
  let familyAllLength = 0;
  productFamilies.forEach((family: any[]) => {
    familyAllLength += family.length;
  });

  return (
    <>
      <div className="flex flex-row items-start justify-between">
        <h2 className="mb-4">{event.title}</h2>
        <button
          onClick={() => history.push(`/events/${event.id}`)}
          className="text-gray-500 text-14 mr-4"
        >
          더보기 +
        </button>
      </div>
      <div className="relative">
        <Swiper
          pagination={true}
          className="mySwiper w-full pb-12"
          slidesPerView={1.1}
          spaceBetween={20}
        >
          {productFamilies.map((family: any[], idxFamily: number) => (
            <SwiperSlide className="w-55vw md:w-60 grid grid-cols-2 gap-4" key={idxFamily}>
              {family.map((prd: any, idxPrd: number) => (
                <ProductCard
                  product={prd.product}
                  to={`/products/${prd.product.productId}`}
                  hasPadding={false}
                />
              ))}
              {idxFamily === productFamilies.length - 1 && family.length % 4 > 0 && (
                <EventProductsMore event={event} />
              )}
            </SwiperSlide>
          ))}
          {familyAllLength % 4 === 0 && (
            <SwiperSlide className="w-55vw md:w-60 grid grid-cols-2 gap-4">
              <EventProductsMore event={event} />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </>
  );
};
