import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../components/Button';
import { Card } from '../../../../../components/Card';
import { Select } from '../../../../../components/Select';
import { TextField } from '../../../../../components/TextField';
import { AdminH1 } from '../../../../components/AdminH1';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

// import FroalaEditor from './../components/FroalaEditor';
import { Toggle } from '../../../../../components/Toggle';
import {
  createTDCAccommCampaign,
  deleteTDCAccommCampaign,
  getTDCAccommCampaign,
  getTDCAccommodations,
  updateTDCAccommCampaign,
} from '../../../../../api_v2/admin/community/tdc-accommodations';

import { Icon } from '../../../../../components/Icon';
import { getVkTourDests } from '../../../../../api_v2/admin/community/visitkorea/tour-dest';
import CardType1 from './CardType1';
// import { BannerText, BannerType } from './repeatCode';

export const CreatePostHtmlV1_0 = () => {
  const [selectedVkTourDests, setSelectedVkTourDests] = useState<any>([]);
  const [deleteSelectedVkTourDests, setDeleteSelectedVkTourDests] = useState<any>([]);

  const addObject = (objData: any) => {
    try {
      let found = selectedVkTourDests.find((item: any) => item?.id == objData?.id);

      if (!found) {
        setSelectedVkTourDests((prevList: any) => [...prevList, { ...objData }]);
      }
    } catch (e) {}
  };

  const deleteObject = (dataObj: any) => {
    setSelectedVkTourDests((prevList: any) =>
      prevList.filter((obj: any) => obj.id !== dataObj?.id)
    );
  };

  const [currVkTourDests, setCurrVkTourDests] = useState([]);

  const handleSearch = async () => {
    try {
      let path = `?page=${1}&limit=${20}`;
      if (vkTourDestSearchInput && typeof vkTourDestSearchInput == 'string') {
        path = path + `&q=${vkTourDestSearchInput?.trim() ?? ''}`;
      }
      getVkTourDests(path).then((resultData: any) => {
        if (resultData?.success) {
          if (resultData?.data?.items) {
            setCurrVkTourDests(resultData?.data?.items);
            // setTotalAccommsCount(resultData.data[1]);
          }
        }
      });
    } catch (e) {}
  };

  const [vkTourDestSearchInput, setVkTourDestSearchInput] = useState('');

  const handleVkTourDestSearchInput = (e: any) => {
    setVkTourDestSearchInput(e.target.value);
  };

  const handleVkTourDestSearchButton = (evt: any) => {
    handleSearch();
  };

  const handleVkTourDestSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      handleSearch();
    }
  };

  const handleResetVkTourDestSearchResult = () => {
    try {
      setCurrVkTourDests([]);
    } catch (e) {}
  };

  const [generatedHtml, setGeneratedHtml] = useState<any>(null);

  const generateHtml = (selectedListData: any) => {
    try {
      const tempHtml = selectedListData
        .map((item: any, index: any) => {
          if (item?.selectedImages[0]?.url) {
            return `
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 10px;
              "
            >
              <img
                src="${item.selectedImages[0]?.url}"
                style="width: 100%; max-height: 160px; object-fit: cover"
              />
              <p>${item.title}</p>
            </div>
            <div style="width: 100%; height: 7px"><br /></div>
            `;
          } else {
            return `
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 10px;
              "
            >
              <p>${item.title}</p>
            </div>
            <div style="width: 100%; height: 7px"><br /></div>
            `;
          }
        })
        .join('');

      let titleEle = `<p style="margin-bottom: 15px; font-weight: bold"></p>`;

      setGeneratedHtml(titleEle + tempHtml);
    } catch (e) {
      console.log('e', e);
    }
  };

  const copyHtml = () => {
    const el = document.createElement('textarea');
    el.value = generatedHtml;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.success('Copied!');
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>CreatePostHtmlV1_0</AdminH1>
      </div>
      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-start-1 col-span-2">
            <div className="flex gap-[10px] items-center mb-[10px]">
              <h2>vk tour dest 리스트</h2>
            </div>
            <div
              className="flex flex-row-reverse w-full max-w-[500px] items-center px-4 h-12 mt-2
                border border-[black] bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
            >
              <Button
                className="bg-[#FF6400] text-[#FFFFFF]"
                style={{ height: '80%' }}
                onClick={(e) => handleVkTourDestSearchButton(e)}
              >
                검색
              </Button>
              {vkTourDestSearchInput ? (
                <>
                  <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                    <Icon.X
                      className="wh-3"
                      onClick={() => {
                        setVkTourDestSearchInput('');
                      }}
                    />
                  </div>
                </>
              ) : null}
              <input
                className="flex-1 text-sm bg-white"
                placeholder="검색하기"
                onChange={(e: any) => handleVkTourDestSearchInput(e)}
                value={vkTourDestSearchInput as string}
                onKeyDown={(e) => handleVkTourDestSearchKeyDown(e)}
              />
            </div>
            <div className="mb-[20px]">
              {currVkTourDests?.length > 0 ? (
                <>
                  <div className="flex justify-between items-center">
                    <p className="font-[700]">검색 결과</p>
                    <div
                      className="border-[1px] p-[10px] w-fit flex justify-end mb-[5px] cursor-pointer"
                      onClick={() => {
                        handleResetVkTourDestSearchResult();
                      }}
                    >
                      검색결과리셋
                    </div>
                  </div>
                  <div className="w-[100%] h-[1px] bg-[black] my-[15px]"></div>

                  {currVkTourDests?.map((vkTourDestObj: any) => (
                    <div
                      key={vkTourDestObj?.id}
                      className="flex flex-col justify-between border-[1px] p-[15px] mb-[5px]"
                    >
                      <CardType1 item={vkTourDestObj} addObject={addObject} />
                    </div>
                  ))}
                </>
              ) : null}
            </div>

            <div className="w-full flex justify-between items-center">
              <div className="flex items-center gap-[10px]"></div>
            </div>

            {selectedVkTourDests?.length > 0 && (
              <>
                <div className="flex justify-between items-center">
                  <p className="font-[700]">선택한 vk tour dests</p>
                  <div className="flex gap-[10px]">
                    <div
                      className="border-[1px] p-[10px] w-fit flex justify-end mb-[5px] cursor-pointer"
                      onClick={() => {
                        if (window.confirm('리셋 하시겠습니까?')) {
                          setSelectedVkTourDests([]);
                        }
                      }}
                    >
                      선택한 vk tour dests 리셋
                    </div>
                    <div
                      className="border-[1px] p-[10px] w-fit flex justify-end mb-[5px] cursor-pointer"
                      onClick={() => {
                        generateHtml(selectedVkTourDests);
                      }}
                    >
                      Generate html
                    </div>
                  </div>
                </div>
                <div className="w-[100%] h-[1px] bg-[black] my-[15px]"></div>
              </>
            )}
            <div className="flex gap-[10px] flex-wrap">
              {selectedVkTourDests.map((obj: any) => (
                <div key={obj.id} className="border-[1px] p-[10px]">
                  <div className="flex justify-between items-center">
                    <div>
                      <p>ID: {obj?.id ?? '------'}</p>
                      <p>Title: {obj.title}</p>
                    </div>
                    <button onClick={() => deleteObject(obj)}>X</button>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {obj?.selectedImages.map((sImgObj: any, index: any) => (
                      <img
                        key={sImgObj?.url}
                        src={sImgObj?.url}
                        alt={`Image ${index + 1}`}
                        style={{
                          width: '100px',
                          height: '100px',
                          margin: '5px',
                          cursor: 'pointer',
                          objectFit: 'cover',
                        }}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div>
              {selectedVkTourDests?.length > 0 && generatedHtml && (
                <>
                  <div className="flex justify-between items-center my-[15px]">
                    <p className="font-[700]">generatedHtml</p>
                    <div
                      className="border-[1px] p-[10px] w-fit flex justify-end mb-[5px] cursor-pointer"
                      onClick={() => {
                        copyHtml();
                      }}
                    >
                      Copy html
                    </div>
                  </div>
                  <div className="w-[100%] h-[1px] bg-[black] my-[15px]"></div>
                  <div>{generatedHtml}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
