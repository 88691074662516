import React, { useEffect, useReducer, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { default as htmlReactParser } from 'html-react-parser';
import { ReactComponent as IconNoPicture } from '../../../../../assets/icons/icon-no-picture.svg';
import { parse, stringify } from 'qs';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { api } from '../../../../../plugins/axios';
import { Icon } from '../../../../../components/Icon';
import { toast } from 'react-toastify';

import { pipeline, env, cat } from '@xenova/transformers';
import { TopTitleBar } from './components/TopTitleBar';

import { env as envOnnx } from 'onnxruntime-web';

envOnnx.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web/dist/';
envOnnx.wasm.numThreads = 1;

env.backends.onnx.wasm.numThreads = 1;

env.allowLocalModels = false;
env.useBrowserCache = false;

export default function TourDestDetail(props: any) {
  const { vkTourDestId } = useParams<{ vkTourDestId: string }>();

  const [currentIndexForImg, setCurrentIndexForImg] = useState(0);

  const [vkTourDest, setVkTourDest] = useState<any>(null);
  const [getVkTourDestStatus, setGetVkTourDestStatus] = useState<any>(false);

  useEffect(() => {
    setGetVkTourDestStatus(true);

    api
      .get(`/community/vk-tour-dests/data/detail/${vkTourDestId}`)
      .then((res: any) => {
        setVkTourDest(res?.data ?? null);
        setGetVkTourDestStatus(false);
      })
      .catch((e: any) => {
        setGetVkTourDestStatus(false);
      });
  }, []);

  const nextImage = () => {
    setCurrentIndexForImg((prevIndex) => (prevIndex === images?.length - 1 ? 0 : prevIndex + 1));
  };

  const prevImage = () => {
    setCurrentIndexForImg((prevIndex) => (prevIndex === 0 ? images?.length - 1 : prevIndex - 1));
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {},
  });

  const handleAddress = (addressData: any) => {
    let searchQ = '';
    let add1 = addressData?.addr1 ?? '';
    let add2 = (add1 ? ' ' : '') + (addressData?.addr2 ?? '');
    let pc = (add1 || add2 ? ' ' : '') + (addressData?.zipCode ?? '');
    searchQ = searchQ + add1 + add2 + pc;
    let queryObj = { q: searchQ };
    window.open(
      `https://map.kakao.com/${stringify(queryObj, {
        addQueryPrefix: true,
        encode: false,
      })}`
    );
  };

  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    setImages(vkTourDest?.images ?? []);
    setCurrentIndexForImg(0);
  }, [vkTourDest]);

  const changeImgObjectFit = (slideImage: any) => {
    try {
      if (slideImage) {
        const imgElement = slideImage as any;
        const width = imgElement?.naturalWidth as any;
        const height = imgElement?.naturalHeight as any;

        if (imgElement && width >= 0 && height > 0) {
          const aspectRatio = width / height;
          if (aspectRatio > 1) {
            imgElement.style['object-fit'] = 'cover';
          } else {
            imgElement.style['object-fit'] = 'contain';
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const [loadedImages, setLoadedImages] = useState<any>([]);

  const handleImageLoad = (url: any) => {
    if (loadedImages?.includes(url)) {
    } else {
      setLoadedImages((prev: any) => {
        return [...prev, url];
      });
    }
  };

  useEffect(() => {
    // Function to check and respond to style changes
    const handleStyleChanges = () => {
      let generalDrawerEle = document.querySelector('#general-drawer') as any;
      if (generalDrawerEle?.style?.height == '100%') {
      } else if (generalDrawerEle?.style?.height == '0px') {
      }
    };

    // Attach a MutationObserver to monitor changes in the element's attributes (which include style changes)
    const observer = new MutationObserver(handleStyleChanges);

    // Start observing the element
    if (document.querySelector('#general-drawer')) {
      observer.observe(document.querySelector('#general-drawer') as any, {
        attributes: true, // Watch for attribute changes
        attributeFilter: ['style'], // Specify the 'style' attribute to watch
      });
    }

    // Cleanup when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  const [vkTourDestSearchInput, setVkTourDestSearchInput] = useState('');

  const handleVkTourDestSearchInput = (e: any) => {
    setVkTourDestSearchInput(e.target.value);
  };

  const [searchResult, setSearchResult] = useState<any>(null);
  const [searchStatus, setSearchStatus] = useState<any>(false);

  const [searchDate, setSearchDate] = useState<any>(null);

  const handleVkTourDestSearchButton = async () => {
    try {
      setSearchError(null);

      setSearchDate(new Date());
      setSearchStatus(true);

      if (vkTourDestSearchInput?.trim()?.length > 0) {
        let translateRes = await api.post('/community/vk-tour-dests/util/translate', {
          dataToTranslate: vkTourDestSearchInput?.trim(),
          toLanguage: 'en',
        });

        if (translateRes?.data?.translatedText) {
          let bertQARes = await bertQA(
            translateRes?.data?.translatedText,
            vkTourDest?.translatedOverview
          );

          if (bertQARes && (bertQARes as any)?.answer) {
            let translateRes2 = await api.post('/community/vk-tour-dests/util/translate', {
              dataToTranslate: (bertQARes as any)?.answer?.trim(),
              toLanguage: 'ko',
            });

            if (translateRes2?.data?.translatedText) {
              setSearchResult(translateRes2?.data?.translatedText);
            }
          } else {
            setSearchError('bertQA: an error occured');
            throw Error('bertQA: an error occured');
          }
        }
      } else {
        // toast.warning('검색내용을 입력해주세요!');
        // toast.info('검색내용을 입력해주세요!');
        // toast.warn('검색내용을 입력해주세요!');
        toast.dark('검색내용을 입력해주세요!');
      }
      setSearchStatus(false);
    } catch (e) {
      setSearchError('bertQA: an error occured');

      setSearchStatus(false);
    }
  };

  const failedAttemptsFromBingTranslateApi = [];
  const bertQA = async (questionData: any, contextData: any, maxRetries = 3) => {
    try {
      console.log('bertQA');

      let retries = 0;
      console.log('try', retries + 1);

      while (retries < maxRetries) {
        try {
          const answerer = await pipeline(
            'question-answering',
            'Xenova/distilbert-base-uncased-distilled-squad'
          );
          const response = await answerer(questionData, contextData);

          if (response) {
            return response;
          } else {
            console.error(`Request failed`);
          }
        } catch (error) {
          console.error('Error during request:', error);
        }

        retries += 1;

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }

      console.log('bertQA: could not work after 3 attemps');

      return { message: 'could not work after 3 attemps' };
    } catch (e) {
      console.log('bertQA e: ', e);
      return e;
    }
  };

  const [searchError, setSearchError] = useState<any>(null);

  const handleSearchKeyDown = async (evt: any) => {
    try {
      if (evt.key === 'Enter') {
        setSearchError(null);

        setSearchDate(new Date());
        setSearchStatus(true);

        if (vkTourDestSearchInput?.trim()?.length > 0) {
          let translateRes = await api.post('/community/vk-tour-dests/util/translate', {
            dataToTranslate: vkTourDestSearchInput?.trim(),
            toLanguage: 'en',
          });

          if (translateRes?.data?.translatedText) {
            let bertQARes = await bertQA(
              translateRes?.data?.translatedText,
              vkTourDest?.translatedOverview
            );

            if (bertQARes && (bertQARes as any)?.answer) {
              let translateRes2 = await api.post('/community/vk-tour-dests/util/translate', {
                dataToTranslate: (bertQARes as any)?.answer?.trim(),
                toLanguage: 'ko',
              });

              if (translateRes2?.data?.translatedText) {
                setSearchResult(translateRes2?.data?.translatedText);
              }
            } else {
              setSearchError('bertQA: an error occured');
              throw Error('bertQA: an error occured');
            }
          }
        } else {
          // toast.warning('검색내용을 입력해주세요!');
          // toast.info('검색내용을 입력해주세요!');
          // toast.warn('검색내용을 입력해주세요!');
          toast.dark('검색내용을 입력해주세요!');
        }
        setSearchStatus(false);
      }
    } catch (e: any) {
      setSearchError('bertQA: an error occured');

      setSearchStatus(false);
    }
  };
  const [searchAreaStatus, setSearchAreaStatus] = useState<any>(false);
  const modifySearchArea = async (showStatus: any) => {
    try {
      let scEle = document.querySelector('.search-container');
      if (showStatus) {
        setSearchAreaStatus(true);

        if (scEle) {
          (scEle as any).style.display = 'block';
          let sbEle = document.querySelector('.fe3b1b16-22c1-4d6d-bf7c-11b396761e84');
          if (sbEle) {
            (sbEle as any).style.display = 'none';
          }
        }
      } else {
        setSearchAreaStatus(false);

        if (scEle) {
          (scEle as any).style.display = 'none';
          let sbEle = document.querySelector('.fe3b1b16-22c1-4d6d-bf7c-11b396761e84');
          if (sbEle) {
            (sbEle as any).style.display = 'block';
          }
        }
      }
    } catch (e) {}
  };

  return (
    <div>
      <TopTitleBar logo={true} />
      <div className="max-w-md w-full bg-[#FFFFFF]">
        {getVkTourDestStatus ? (
          <>
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-[18px] w-[18px] border-t-2 border-b-2 border-gray-900"></div>
            </div>
          </>
        ) : (
          <>
            <div className="max-h-[300px] w-full flex justify-center items-center mb-[15px] relative">
              {images?.length > 0 ? (
                <>
                  <div {...handlers} className="h-[300px] w-full">
                    <>
                      <img
                        src={images[currentIndexForImg]?.url}
                        className="object-contain h-[300px] w-full"
                        ref={(el) => {
                          if (el) {
                            changeImgObjectFit(el);
                          }
                        }}
                        onLoad={() => handleImageLoad(images[currentIndexForImg]?.url)}
                      />

                      <button
                        onClick={prevImage}
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                        className="absolute top-[50%] left-[0px] p-[20px] text-[white] text-[20px] font-[900]"
                      >
                        &larr;
                      </button>
                      <button
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                        onClick={nextImage}
                        className="absolute top-[50%] right-[0px] p-[20px] text-[white] text-[20px] font-[900]"
                      >
                        &rarr;
                      </button>
                      {loadedImages.includes(images[currentIndexForImg]?.url) ? (
                        <></>
                      ) : (
                        <>
                          <div className="absolute top-[0px] animate-pulse w-full h-[300px] bg-gradient-to-br from-blue-200 via-purple-300 to-red-200"></div>
                        </>
                      )}
                    </>
                  </div>
                  <div
                    className="absolute bottom-[5px] right-[10px] w-fit z-[1500] px-[8px] py-[1px] text-[white] rounded-[10px]"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                  >
                    {(currentIndexForImg ?? 0) + 1} / {images?.length ?? 0}
                  </div>
                </>
              ) : (
                <div className="h-[300px] w-full flex justify-center items-center">
                  <IconNoPicture width={'60px'} height={'60px'} />
                </div>
              )}
            </div>
            <div className="side-padding mb-3">
              <div className="flex justify-between">
                {/* <div className="text-[12px]">
              {(vkTourDest?.distance?.toFixed(2) ?? 0) + 'km' ?? null}
            </div> */}
              </div>
              <div className="flex justify-between">
                <div className="font-[700] text-[15px]">
                  {vkTourDest?.title ? vkTourDest?.title : null}
                </div>
              </div>
              <div className="text-[#6F6F6F] text-[12px]" onClick={() => {}}>
                {vkTourDest?.addr1}
              </div>

              <div className="flex gap-[9px] justify-end w-full">
                <div
                  onClick={() => {
                    handleAddress({ addr1: vkTourDest?.addr1 });
                  }}
                  className="w-fit mb-[8px] text-[white] rounded-[12px] border bg-[#1cca40] px-[10px] cursor-pointer"
                >
                  지도
                </div>
                <button
                  className="fe3b1b16-22c1-4d6d-bf7c-11b396761e84 w-fit mb-[8px] text-[white] rounded-[12px] border bg-[#FF6400] px-[10px] cursor-pointer"
                  onClick={(e) => {
                    try {
                      modifySearchArea(true);
                    } catch (e) {}
                  }}
                >
                  검색
                </button>
              </div>
              <div className="hidden search-container w-full max-w-md bg-[white]">
                <div
                  className="relative flex w-full items-center px-4 h-12 mt-2
                border border-[#FF6400] rounded bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
                >
                  {vkTourDestSearchInput ? (
                    <>
                      <div className="absolute right-0 flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                        <Icon.X
                          className="wh-3"
                          onClick={() => {
                            setVkTourDestSearchInput('');
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                  <input
                    className="w-full text-sm bg-white"
                    placeholder="검색하기"
                    onChange={(e) => handleVkTourDestSearchInput(e)}
                    value={vkTourDestSearchInput as string}
                    onKeyDown={(e) => handleSearchKeyDown(e)}
                  />
                </div>

                <div className="flex gap-[15px] mb-[10px] justify-end">
                  <button
                    className="w-fit h-fit bg-[#FF6400] text-[#FFFFFF] px-[5px] py-[5px]"
                    onClick={() => handleVkTourDestSearchButton()}
                  >
                    검색
                  </button>
                  <button
                    className="w-fit h-full bg-[#FF6400] text-[#FFFFFF] px-[5px] py-[5px]"
                    onClick={(e) => {
                      try {
                        window.location.reload();
                      } catch (e) {}
                    }}
                  >
                    재시작
                  </button>
                  <button
                    className="w-fit h-full bg-[black] text-[#FFFFFF] px-[10px] py-[5px]"
                    onClick={(e) => {
                      try {
                        modifySearchArea(false);
                      } catch (e) {}
                    }}
                  >
                    x
                  </button>
                </div>
              </div>
              {searchAreaStatus == true ? (
                <>
                  {searchStatus ? (
                    <>
                      <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-[18px] w-[18px] border-t-2 border-b-2 border-gray-900"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      {searchError ? (
                        <div
                          className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-[2px] mt-[3px]"
                          role="alert"
                        >
                          <p className="text-red-600 font-bold">
                            에러: 죄송합니다. 오류가 발생하여 다시 시작해주십시오.
                          </p>
                        </div>
                      ) : searchDate && searchResult ? (
                        <>
                          <div className="border rounded-[4px] p-[8px]">
                            <div style={{ display: 'inline' }} className="font-[700] w-fit">
                              {searchResult && searchResult}
                            </div>
                            <div
                              className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-[2px] mt-[3px]"
                              role="alert"
                            >
                              <div className="flex gap-[6px]">
                                <p className="font-bold">주의:</p>
                                <p>기술적 문제로 인해 결과가 정확하지 않을 수 있습니다.</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : searchDate && !searchResult ? (
                        <>
                          <div
                            className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-[2px] mt-[3px]"
                            role="alert"
                          >
                            <p className="text-red-600 font-bold">
                              죄송합니다. 결과를 찾을 수 없습니다. 다른 검색어를 시도해주세요.
                            </p>
                          </div>
                        </>
                      ) : null}
                    </>
                  )}
                </>
              ) : null}

              <div className="mt-[8px] text-[13px]">
                <pre className="w-full mb-[5px] whitespace-pre-wrap break-all">
                  {vkTourDest?.overview ? htmlReactParser(vkTourDest?.overview) : null}
                </pre>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
