import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import { map, trim, uniq } from 'lodash';
import { parse, stringify } from 'qs';
import { addDays, previousMonday } from 'date-fns';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { AxiosError, AxiosResponse } from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { v4 as uuidv4 } from 'uuid';

import { TopTitleBar } from '../../components/TopTitleBar';
import { Section } from '../../../../../../components/Section';
import { Icon } from '../../../../../../components/Icon';
import { CustomDrawer } from '../../components/CustomDrawer';
import { Button } from '../../../../../../components/Button';
import { CardType2 } from '../../components/CardType2';
import PostCommentEdit from '../../../post/PostCommentEdit';

import { ReactComponent as IconAngleDoubleTop } from '../../../../../../assets/icons/icon-angle-double-top.svg';

import { api } from '../../../../../../plugins/axios';

import { authUserTokenObjState } from '../../../../../../ridge/ridge';

enum DrawerType {
  EDIT_POST_COMMENT = 'edit_post_comment',
}

export const MyPostsComments = () => {
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const authUserTokenObj = authUserTokenObjState.useValue();

  const history = useHistory();
  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [postSearchInput, setPostSearchInput] = useState(parsedSearch?.search_query);

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const observer = useRef<IntersectionObserver>();

  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [chosenCategories, setChosenCategories] = useState<any>(parsedSearch['category']);
  const [drawerType, setDrawerType] = useState<any>(null);

  const handlePostSearchInput = (e: any) => {
    setPostSearchInput(e.target.value);
  };

  const convertDateForQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  let [pageLimit, setPageLimit] = useState(5);

  const fetchMyPostsComments = async ({ pageParam = 1 }) => {
    let currentQueryStringObj: { [key: string]: any } = {
      page: pageParam,
      limit: pageLimit,
      filter: { status: true, isVisible: true },
      sort: { createdAt: 'DESC' },
    };

    for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
      let key = Object.keys(parsedSearch)[i];
      if (key === 'search_query') {
        currentQueryStringObj.filter['searchQuery'] = parsedSearch[key];
      } else if (key === 'category') {
        currentQueryStringObj.filter['category'] = parsedSearch[key];
      }
    }

    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      currentQueryStringObj.filter['checkin'] = convertDateForQS(parsedSearch?.start_date);
      currentQueryStringObj.filter['checkout'] = convertDateForQS(parsedSearch?.end_date);
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
      arrayFormat: 'brackets',
    });

    if (pageParam == 1) {
      setInitialLoading(true);
      loadingDots(true, 'initial-loading-container');
    } else {
      loadingDots(true, 'more-loading-container');
    }

    let result = await api.get(`/community/mypage/posts-comments${currentQueryString}`);

    if (pageParam == 1) {
      setInitialLoading(false);
      loadingDots(false, 'initial-loading-container');
    } else {
      loadingDots(false, 'more-loading-container');
    }

    if ([200].includes(result?.status)) {
      return result;
    } else return null;
  };

  const {
    data: searchResultData,
    fetchNextPage,
    isLoading: isSearchResultDataLoading,
    refetch: refetchMyPagePostsComments,
  } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`communityMyPagePostsCommentsPage`],
    fetchMyPostsComments as any,
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        if (lastPage?.data?.items?.length > 0) {
          return nextPage;
        } else if (lastPage?.data?.items?.length == 0) {
          return undefined;
        } else return allPages?.length;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const lastElement = useCallback(
    (target) => {
      if (isSearchResultDataLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && fetchNextPage) {
            fetchNextPage();
          } else {
          }
        },
        {
          rootMargin: '500px',
          threshold: 0.1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [isSearchResultDataLoading]
  );

  const handlePostinfoSwiperSlide = (postInfoObj: any) => {
    history.push(`/community/posts/${postInfoObj.id}`);
  };

  const openDrawer = (drawerTypeData: any, heightData?: any) => {
    setDrawerType(drawerTypeData);
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `none`;

      (drawerRef.current as any).style['pointer-events'] = 'auto';

      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = heightData ?? '100%';
    }
  };

  const [handleSearchQS, setHandleSearchQS] = useState<any | null>('');

  const handleSearch = (queryStrObj: { [key: string]: any }) => {
    const queryString = stringify(queryStrObj, {
      addQueryPrefix: true,
      encode: false,
      arrayFormat: 'brackets',
    });
    if (queryString == handleSearchQS) {
    } else {
      // setInitialLoading(true);
      // setPage(1);
      // setCampingInfoList({});
    }
    setHandleSearchQS(queryString);
    // history.push('/v2/camping/search/result' + queryString);
    window.location.href = '/community/posts' + queryString;
  };

  // prob2
  const handlePostSearchButton = (evt: any) => {
    const tempQueryObj: { [key: string]: any } = {};

    if ((postSearchInput as string)?.trim()?.length > 0) {
      tempQueryObj['search_query'] = (postSearchInput as string)?.trim() ?? '';
    }

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'province') {
          tempQueryObj['province'] = parsedSearch[key];
        } else if (key === 'city') {
          tempQueryObj['city'] = parsedSearch[key];
        }
      }
    }

    if (chosenDates?.startDate && chosenDates?.endDate) {
      tempQueryObj.start_date = chosenDates?.startDate;
      tempQueryObj.end_date = chosenDates?.endDate;
    }

    handleSearch(tempQueryObj);
  };

  // prob3
  const handleSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      const tempQueryObj: { [key: string]: any } = {};
      if ((postSearchInput as string)?.trim()?.length > 0) {
        tempQueryObj['search_query'] = (postSearchInput as string)?.trim() ?? '';
      }

      if (parsedSearch) {
        for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
          let key = Object.keys(parsedSearch)[i];
          if (key === 'province') {
            tempQueryObj['province'] = parsedSearch[key];
          } else if (key === 'city') {
            tempQueryObj['city'] = parsedSearch[key];
          }
        }
      }

      if (chosenDates?.startDate && chosenDates?.endDate) {
        tempQueryObj.start_date = chosenDates?.startDate;
        tempQueryObj.end_date = chosenDates?.endDate;
      }

      handleSearch(tempQueryObj);
    }
  };

  const handleFilterSearch = (categories: any) => {
    const tempQueryObj: { [key: string]: any } = {};
    if ((postSearchInput as string)?.trim()?.length > 0) {
      tempQueryObj['search_query'] = (postSearchInput as string)?.trim() ?? '';
    }

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'province') {
          tempQueryObj['province'] = parsedSearch[key];
        } else if (key === 'city') {
          tempQueryObj['city'] = parsedSearch[key];
        }
      }
    }

    if (categories?.length > 0) {
      tempQueryObj['category'] = categories;
    }

    if (chosenDates?.startDate && chosenDates?.endDate) {
      tempQueryObj.start_date = chosenDates?.startDate;
      tempQueryObj.end_date = chosenDates?.endDate;
    }

    handleSearch(tempQueryObj);
  };

  const closeDrawer = () => {
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `auto`;

      drawerRef.current.style.width = '0';
      drawerRef.current.style.height = '0';
    }
  };

  const filterWrapRef = useRef<any | null>(null);
  const filterRef = useRef<any | null>(null);

  useEffect(() => {
    const handleScrollEvent = () => {
      if (filterRef?.current?.getBoundingClientRect()?.top <= 0) {
        filterRef.current.style.position = 'fixed';
        filterRef.current.style.top = 0;
      } else {
        // filterWrapRef.current.style.position = '';
        // filterWrapRef.current.style.top = '';
      }
      if (document.documentElement.scrollTop == 0) {
        filterRef.current.style.position = '';
        filterRef.current.style.top = '';
      }
    };
    window.addEventListener('scroll', handleScrollEvent);
    return () => window.removeEventListener('scroll', handleScrollEvent);
  }, []);

  const loadingDots = (statusBool: boolean, className: string) => {
    let loadingContEle = document.querySelector('.' + className);
    if (loadingContEle) {
      if (statusBool) {
        (loadingContEle as any).style.display = 'flex';
      } else {
        (loadingContEle as any).style.display = 'none';
      }
    }
  };

  const [chosenEditPostComment, setChosenEditPostComment] = useState<any>(null);

  return (
    <>
      <TopTitleBar backButton={true} logo={true} home={false} />
      {/* <div className="max-w-md w-screen bg-white side-padding"> */}
      <div className="w-screen sm:w-full md:w-full lg:w-full side-padding bg-white">
        {/* <div ref={tabsRef} className={`fixed bg-[white] top-[132px] w-full max-w-md`}> */}

        <Section className="mt-[50px] bg-[white]">
          {initialLoading == true && (
            <div className="initial-loading-container flex justify-center mb-[20px] hidden">
              <div className="loading-dots-type1"></div>
            </div>
          )}

          {!((searchResultData as any)?.pages[0]?.data?.items?.length > 0) &&
          !isSearchResultDataLoading ? (
            <>
              <p className="grid place-content-center h-44 gap-[5px]"></p>
            </>
          ) : searchResultData?.pages && searchResultData?.pages?.length > 0 ? (
            <>
              {map(searchResultData?.pages ?? [], (page, pageInd) => {
                return map(page?.data?.items ?? [], (postCommentObj, postCommentObjInd: number) => {
                  return (
                    <>
                      <div
                        key={postCommentObj?.id}
                        ref={
                          searchResultData?.pages?.length - 1 == pageInd &&
                          page?.data?.items?.length - 1 == postCommentObjInd
                            ? lastElement
                            : null
                        }
                      >
                        <CardType2
                          pageInd={pageInd}
                          item={postCommentObj}
                          closeDrawer={closeDrawer}
                          openDrawer={openDrawer}
                          DrawerType={DrawerType}
                          setChosenEditPostComment={setChosenEditPostComment}
                          refetchMyPagePostsComments={refetchMyPagePostsComments}
                        />
                      </div>
                    </>
                  );
                });
              })}
              {/* <div ref={lastElement}></div> */}
            </>
          ) : null}

          <div className="more-loading-container flex justify-center mb-[20px] hidden">
            <div className="loading-dots-type1"></div>
          </div>
        </Section>
      </div>

      <div className="absolute bottom-10 right-0 w-[30px] h-[30px] z-[1000]">
        <div className="flex justify-end max-w-md">
          <div
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="fixed bottom-10 w-[60px] h-[60px] mr-5 rounded-[50px] bg-[#06A54A] text-[white] font-[300] text-[1.5em] 
        flex justify-center items-center cursor-pointer"
          >
            <IconAngleDoubleTop fill="#FFFFFF" className="w-[35px] h-[35px]" />
          </div>
        </div>
      </div>
      <CustomDrawer drawerRef={drawerRef} contentMt={55}>
        <div className="mt-[50px] p-[15px]">
          {drawerType == DrawerType.EDIT_POST_COMMENT ? (
            <PostCommentEdit
              item={chosenEditPostComment}
              refetchMyPagePostsComments={refetchMyPagePostsComments}
              closeDrawer={closeDrawer}
            />
          ) : null}
        </div>
      </CustomDrawer>
    </>
  );
};
