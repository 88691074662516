import React, { useEffect, useRef, useState } from 'react';
import { TopTitleBar } from './components/TopTitleBar';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { Button } from '../../../../components/Button';
import { authUserTokenObjState } from '../../../../ridge/ridge';
import { api } from '../../../../plugins/axios';
import Posts from './post/Posts';

export default function BookmarkMain() {
  enum TabType {
    POST = 'POST',
  }

  const TabTypeObj: { [key: string]: any } = {
    POST: '포스트',
  };

  const tabs = [TabType?.POST];

  const filterWrapRef = useRef<any | null>(null);
  const filterRef = useRef<any | null>(null);

  useEffect(() => {
    try {
      const handleScrollEvent = () => {
        try {
          if (filterRef?.current?.getBoundingClientRect()?.top <= 0) {
            filterRef.current.style.position = 'fixed';
            filterRef.current.style.top = 0;
          } else {
            // filterWrapRef.current.style.position = '';
            // filterWrapRef.current.style.top = '';
          }
          if (document.documentElement.scrollTop == 0) {
            filterRef.current.style.position = '';
            filterRef.current.style.top = '';
          }
        } catch (e) {}
      };
      window.addEventListener('scroll', handleScrollEvent);
      return () => window.removeEventListener('scroll', handleScrollEvent);
    } catch (e) {}
  }, []);

  const authUserTokenObj = authUserTokenObjState.useValue();

  //   const [getCommunityBookmarkForPostStatus, setGetCommunityBookmarkForPostStatus] =
  //     useState<boolean>(false);

  //   const [communityBookmarkForPost, setCommunityBookmarkForPost] = useState<any>(null);

  //   const getCommunityBookmarkForPost = async () => {
  //     try {
  //       let communityBookmarkResp = await api.get('/community/bookmark?type=communityPost');

  //       if ([200, 201].includes(communityBookmarkResp?.status)) {
  //         setCommunityBookmarkForPost(communityBookmarkResp?.data?.communityPosts);
  //       }
  //       setGetCommunityBookmarkForPostStatus(false);
  //     } catch (e) {
  //       setGetCommunityBookmarkForPostStatus(false);
  //       return null;
  //     }
  //   };

  //   useEffect(() => {
  //     if (authUserTokenObj?.token) {
  //       setGetCommunityBookmarkForPostStatus(true);
  //       getCommunityBookmarkForPost();
  //     }
  //   }, [authUserTokenObj?.token]);
  const [chosenTab, setChosenTab] = useState<any>(TabType?.POST);
  const handleChosenTab = (item: any) => {
    try {
    } catch (e) {}
  };

  return (
    <div>
      <TopTitleBar logo={true} />
      <div className="w-screen sm:w-full md:w-full lg:w-full side-padding bg-white">
        <div ref={filterWrapRef} className="absolute right-0 w-full max-w-md bg-[white] z-[3]">
          <div
            ref={filterRef}
            className="flex flex-col justify-center w-full max-w-md side-padding bg-[white]"
          >
            <>
              <div className="w-full" style={{ width: '100%' }}>
                <Swiper
                  className={`mySwiper w-full pt-4 z-[1]`}
                  slidesPerView="auto"
                  spaceBetween={6}
                  slidesOffsetBefore={0}
                  slidesOffsetAfter={0}
                  freeMode={true}
                >
                  {tabs.map((item) => (
                    <SwiperSlide className="w-auto">
                      <Button
                        className={`border-2 rounded-[12px] ${
                          chosenTab == item
                            ? 'bg-[#FF6400] text-[white]'
                            : 'bg-[white] text-[black]'
                        }`}
                        onClick={(e) => {
                          handleChosenTab(item);
                        }}
                        key={item}
                      >
                        {TabTypeObj[item]}
                      </Button>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </>
          </div>
        </div>
        <div className="pt-[100px]">
          {authUserTokenObj?.token ? (
            <>
              {chosenTab == TabType?.POST ? (
                <>
                  <Posts />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <p className="font-[700] text-center">로그인 후 이용 가능합니다</p>
          )}
        </div>
      </div>
    </div>
  );
}
