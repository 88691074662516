import { ComponentType, FC, useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { authUserTokenObjState } from '../../../../../ridge/ridge';

export interface PublicRouteProps {
  path: string | string[];
  component: any; //ComponentType;
  redirectTo?: string;
  exact?: boolean;
  guestOnly?: boolean;
}

export const PublicRoute: FC<PublicRouteProps> = ({
  path,
  component: Component,
  redirectTo,
  exact = true,
  guestOnly = false,
}) => {
  const authUserTokenObj = authUserTokenObjState.useValue();

  return (
    <Route
      path={path}
      render={() => {
        if (guestOnly && authUserTokenObj) {
          return <Redirect to="/v2/camping" />;
        }
        return <Component />;
      }}
      exact={true}
    />
  );
};
