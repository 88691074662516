import { stringify } from 'qs';
import { api } from '../../../plugins/axios';
import imageCompression from 'browser-image-compression';
import { map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const createCommunityBanner = async (createCommunityBannerParams: {
  [key: string]: any;
}) => {
  try {
    let result = await api.post('/admin/community/banners', createCommunityBannerParams);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const updateCreateBanner = async (
  id: number,
  updateCreateBannerParams: {
    [key: string]: any;
  }
) => {
  try {
    let result = await api.patch(`/admin/community/banners/${id}`, updateCreateBannerParams);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteCreateBanner = async (id: number) => {
  try {
    let result = await api.delete(`/admin/community/banners/${id}`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const getCommunityBanners = async (queryString: string) => {
  try {
    let result = await api.get('/admin/community/banners' + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCommunityBanner = async (id: any) => {
  try {
    let result = await api.get(`/admin/community/banners/${id}`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};
