import { useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { Toggle } from '../../../components/Toggle';
import { Search } from '../../../components/Search';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { useHistory } from 'react-router-dom';
import useDebounce from '../../../hooks/common';
// import { DEBOUNCE_THRESHOLD_MS } from './constants';
import { find, map, uniq } from 'lodash';
import { format } from 'date-fns';
import FroalaEditor from '../../components/FroalaEditor';
import { stringify } from 'qs';
import { useProducts } from '../../../hooks/product';
// import ExhibitionProductDnd from './components/ExhibitionProductDnd';
import { useMutation } from 'react-query';

import { toast } from 'react-toastify';
import { uploadImage } from '../../../api/photo';
import { CreateExhibitionParams, ExhibitionParams } from '../../../api_v2/admin/exhibitions/types';
import { getProduct, getProducts } from '../../../api_v2/admin/products';
import { createExhibition } from '../../../api_v2/admin/exhibitions';
import { createProduction } from '../../../api_v2/admin/production';

export enum OSType {
  IOS = 'ios',
  ANDROID = 'android',
}

export const ProductionAdd = () => {
  const history = useHistory();

  const [formState, setFormState] = useState<{ [key: string]: any }>({
    os: null,
    version: '',
    buildNumber: 0,
    isVisible: false,
  });

  const editorContentRef = useRef('');

  const [searchProductKeyword, setSearchProductKeyword] = useState('');

  const [searchProducts, setSearchProducts] = useState([]);

  const [createProductionStatus, setCreateProductionStatus] = useState<boolean>(false);

  const _onChangeText = (e: any) => {
    const { name, value } = e.target;
    setFormState((prev) => {
      return {
        ...prev,
        [name]: name === 'buildNumber' ? parseInt(value) : value,
      };
    });
  };

  const _onChangeType = (e: any) => {
    setFormState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const _handleSubmitButton = async () => {
    if (!formState.os) {
      toast.error('OS를 선택해주세요.');
      return;
    } else if (!(formState.buildNumber >= 0)) {
      toast.error('0이상 숫자를 입력해주세요');
    } else {
      let redTextFlag = false;
      for (let i = 0; i < Object.keys(formState).length; i++) {
        let key = Object.keys(formState)[i];
        if (formState[key] != undefined) {
          let inputValue = formState[key]!.toString().trim();
          if (inputValue.length === 0) {
            redTextFlag = true;
            break;
          }
        }
      }
      if (redTextFlag) {
        toast.error('모든항목을 작성해주세요');
      } else {
        setCreateProductionStatus(true);
        let createProductionResult = await createProduction({
          ...formState,
        });
        if (createProductionResult) {
          toast.success('성공적으로 프로덕션을 등록하였습니다');
          setCreateProductionStatus(false);
        } else {
          toast.error('Something went wrong creating a production');
        }
      }
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>프로덕션 추가</AdminH1>
      </div>
      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-start-1 col-span-1"></div>
          <div className="col-start-1 col-span-1">
            <Select name="os" label="OS" onChange={_onChangeType}>
              <option disabled selected hidden>
                OS를 선택해주세요
              </option>
              {map(Object.values(OSType), (type) => (
                <option key={type} value={type} selected={type === formState.os}>
                  {type}
                </option>
              ))}
            </Select>
          </div>
          <div className="col-start-1 col-span-1">
            <TextField
              label="버전"
              name="version"
              value={formState.version}
              onChange={_onChangeText}
            />
            <TextField
              label="빌드넘버"
              type="number"
              name="buildNumber"
              value={formState.buildNumber}
              onChange={_onChangeText}
            />
            <div className="flex justify-between mt-4 mb-4">
              <div className="flex items-center space-x-3">
                <p className="text-sm">노출</p>
                <Toggle
                  checked={formState.isVisible}
                  onChange={(e) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      isVisible: e,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="취소"
            className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
            to="/admin/production"
            disabled={createProductionStatus}
          />
          <Button
            onClick={() => _handleSubmitButton()}
            text="추가하기"
            className="h-10 text-sm filled-black"
            disabled={createProductionStatus}
          />
        </div>
      </Card>
    </>
  );
};
