import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useInfiniteQuery, useQuery } from 'react-query';
import { map, trim, uniq } from 'lodash';
import { parse, stringify } from 'qs';
import { addDays, previousMonday } from 'date-fns';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { AxiosError, AxiosResponse } from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { v4 as uuidv4 } from 'uuid';

import { TopTitleBar } from '../../../components/TopTitleBar';
import { Section } from '../../../../../../components/Section';
import { Icon } from '../../../../../../components/Icon';

import { Button } from '../../../../../../components/Button';

import { ReactComponent as IconAngleDoubleTop } from '../../../../../../assets/icons/icon-angle-double-top.svg';
import { ReactComponent as IconFilter } from '../../../../../../assets/icons/icon-filter.svg';

import { api } from '../../../../../../plugins/axios';

import { authUserTokenObjState, userObjState } from '../../../../../../ridge/ridge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faImage } from '@fortawesome/free-solid-svg-icons';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { CardType1 } from './components/CardType1';
import { CardType2 } from './components/CardType2';

const Main = () => {
  const authUserTokenObj = authUserTokenObjState.useValue();

  const history = useHistory();
  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const observer = useRef<IntersectionObserver>();

  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const [pageLimit, setPageLimit] = useState(5);
  const [currPage, setCurrPage] = useState<number>(1);

  const [randomVkTourDests, setRandomVkTourDests] = useState<any>({});

  const testRef = useRef<any>(null);

  const fetchRandomVkTourDests = async (dataObj: any) => {
    try {
      let excludeIds = [] as any;
      if (randomVkTourDestResultData?.pages && randomVkTourDestResultData?.pages?.length > 0) {
        let pagesData = randomVkTourDestResultData?.pages;
        for (let i = 0; i < pagesData?.length; i++) {
          if (pagesData[i]?.data?.randomVkTourDests?.length > 0) {
            let ids = pagesData[i]?.data?.randomVkTourDests?.map((item: any) => {
              return item?.id;
            });
            excludeIds = [...excludeIds, ...ids];
          }
        }
      }

      let result = await api.post(
        `/ai/community/ga/community-posts/popular/get-random-vk-tour-dests?page=${
          dataObj?.pageParam ?? 1
        }`,
        {
          excludeIds: excludeIds ?? [],
        }
      );

      if ([200, 201]?.includes(result?.status)) {
        return { label: testRef.current, ...result };
      } else if ([401]?.includes(result?.status)) {
        window.location.href = '/community/login';
      } else {
        return null;
      }
    } catch (e: any) {
      if (e?.response?.status == 401) {
        window.location.href = '/community/login';
      }

      return null;
    }
  };

  const {
    data: randomVkTourDestResultData,
    fetchNextPage: fetchRandomVkTourDestResultDataNextPage,
    isLoading: isRandomVkTourDestResultDataLoading,
  } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`a00457db-a5c5-42a5-b729-6e44b4379e7f`],
    fetchRandomVkTourDests as any,
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;

        if (lastPage?.data?.randomVkTourDests?.length > 0) {
          return nextPage;
        } else if (lastPage?.data?.randomVkTourDests?.length == 0) {
          return undefined;
        } else return allPages?.length;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const fetchCommunityPosts = async ({ pageParam = 1 }) => {
    try {
      if (pageParam % 2 == 0) {
      } else {
        testRef.current = pageParam ?? 1;
        fetchRandomVkTourDestResultDataNextPage();
      }
      let currentQueryStringObj: { [key: string]: any } = {
        page: pageParam,
        limit: pageLimit,
      };

      const currentQueryString = stringify(currentQueryStringObj, {
        addQueryPrefix: true,
        encode: false,
        arrayFormat: 'brackets',
      });

      if (pageParam == 1) {
        setInitialLoading(true);
        loadingDots(true, 'initial-loading-container');
      } else {
        loadingDots(true, 'more-loading-container');
      }

      let result = await api.get(`/ai/community/ga/community-posts/popular${currentQueryString}`);

      if (pageParam == 1) {
        setInitialLoading(false);
        loadingDots(false, 'initial-loading-container');
      } else {
        loadingDots(false, 'more-loading-container');
      }

      if ([200, 201].includes(result?.status)) {
        return result;
      } else if ([401]?.includes(result?.status)) {
        window.location.href = '/community/login';
      } else return null;
    } catch (e: any) {
      if (e?.response?.status == 401) {
        window.location.href = '/community/login';
      }

      return null;
    }
  };

  const {
    data: searchResultData,
    fetchNextPage,
    isLoading: isSearchResultDataLoading,
    refetch: refetchPosts,
  } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`f47ac10b-58cc-4372-a567-0e02b2c3d479`],
    fetchCommunityPosts as any,
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        if (lastPage?.data?.items?.length > 0) {
          return nextPage;
        } else if (lastPage?.data?.items?.length == 0) {
          return undefined;
        } else return allPages?.length;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const lastElement = useCallback(
    (target) => {
      if (isSearchResultDataLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && fetchNextPage) {
            fetchNextPage();
          } else {
          }
        },
        // {
        //   rootMargin: '500px',
        //   threshold: 0.1,
        // }
        {
          threshold: 1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [isSearchResultDataLoading]
  );

  const handlePostinfoSwiperSlide = (postInfoObj: any) => {
    try {
      api
        .patch('/ai/community/ga/user/collection1', {
          type: 'communityPost',
          id: postInfoObj?.id,
        })
        .then((res) => {})
        .catch((e) => {});

      history.push(`/community/posts/${postInfoObj.id}`);
    } catch (e) {}
  };

  const handleRandomVkTourDestInfoSwiperSlide = (randomVkTourDestInfoObj: any) => {
    try {
      api
        .patch('/ai/community/ga/user/collection1', {
          type: 'vkTourDest',
          id: randomVkTourDestInfoObj?.id,
        })
        .then((res) => {})
        .catch((e) => {});
      history.push(`/community/vk-tour-dests/${randomVkTourDestInfoObj.id}`);
    } catch (e) {}
  };

  const loadingDots = (statusBool: boolean, className: string) => {
    try {
      let loadingContEle = document.querySelector('.' + className);
      if (loadingContEle) {
        if (statusBool) {
          (loadingContEle as any).style.display = 'flex';
        } else {
          (loadingContEle as any).style.display = 'none';
        }
      }
    } catch (e) {}
  };

  const userObj = userObjState.useValue();

  const findDataWLabel = (listData: any, labelData: any) => {
    try {
      if (listData?.length > 0) {
        let foundItem = listData.find(
          (item: any) => item?.label?.toString() == labelData?.toString()
        );

        return foundItem;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  return (
    <>
      <TopTitleBar backButton={true} logo={true} home={false} />
      <div className="w-screen sm:w-full md:w-full lg:w-full side-padding bg-white">
        <Section style={{ marginTop: '10px' }} className="bg-[white]">
          {initialLoading == true && (
            <div className="initial-loading-container flex justify-center mb-[20px] hidden">
              <div className="loading-dots-type1"></div>
            </div>
          )}

          <>
            {!((searchResultData as any)?.pages[0]?.data?.items?.length > 0) &&
            !isSearchResultDataLoading ? (
              <>
                <p className="grid place-content-center h-44 gap-[5px]">검색결과가 없습니다</p>
              </>
            ) : searchResultData?.pages && searchResultData?.pages?.length > 0 ? (
              <>
                {map(searchResultData?.pages ?? [], (page, pageInd) => {
                  return map(page?.data?.items ?? [], (itemObj, itemObjInd: number) => {
                    return (
                      <>
                        <div>
                          {(pageInd + 1) % 2 == 0 ? (
                            <>
                              <>
                                {randomVkTourDestResultData?.pages &&
                                findDataWLabel(randomVkTourDestResultData?.pages, pageInd) &&
                                findDataWLabel(randomVkTourDestResultData?.pages, pageInd)?.data
                                  ?.randomVkTourDests &&
                                findDataWLabel(randomVkTourDestResultData?.pages, pageInd)?.data
                                  ?.randomVkTourDests[itemObjInd] ? (
                                  <>
                                    {' '}
                                    <CardType2
                                      pageInd={pageInd}
                                      item={
                                        findDataWLabel(randomVkTourDestResultData?.pages, pageInd)
                                          ?.data?.randomVkTourDests[itemObjInd]
                                      }
                                      handleRandomVkTourDestInfoSwiperSlide={
                                        handleRandomVkTourDestInfoSwiperSlide
                                      }
                                      refetchPosts={refetchPosts}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <CardType2
                                      pageInd={pageInd}
                                      item={null}
                                      handleRandomVkTourDestInfoSwiperSlide={
                                        handleRandomVkTourDestInfoSwiperSlide
                                      }
                                      refetchPosts={refetchPosts}
                                    />
                                  </>
                                )}
                              </>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          className="relative"
                          key={itemObj?.id}
                          ref={
                            searchResultData?.pages?.length - 1 == pageInd &&
                            page?.data?.items?.length - 1 == itemObjInd
                              ? lastElement
                              : null
                          }
                        >
                          <div className="z-[1000] absolute top-[5px] left-[5px] flex items-center justify-center w-12 h-12 rounded-full bg-purple-500 text-white text-lg font-bold">
                            {pageInd * pageLimit + itemObjInd + 1}
                          </div>
                          <CardType1
                            pageInd={pageInd}
                            item={itemObj}
                            handlePostinfoSwiperSlide={handlePostinfoSwiperSlide}
                            refetchPosts={refetchPosts}
                          />
                        </div>
                      </>
                    );
                  });
                })}
                {/* <div ref={lastElement}></div> */}
              </>
            ) : null}
          </>

          <div className="more-loading-container flex justify-center mb-[20px] hidden">
            <div className="loading-dots-type1"></div>
          </div>
        </Section>
      </div>

      <div className="absolute bottom-10 right-0 w-[30px] h-[30px] z-[1000]">
        <div className="flex justify-end max-w-md">
          <div
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="fixed bottom-10 w-[60px] h-[60px] mr-5 rounded-[50px] bg-[#06A54A] text-[white] font-[300] text-[1.5em] 
        flex justify-center items-center cursor-pointer"
          >
            <IconAngleDoubleTop fill="#FFFFFF" className="w-[35px] h-[35px]" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
