import { useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { Toggle } from '../../../components/Toggle';
import { Search } from '../../../components/Search';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { useHistory } from 'react-router-dom';
import useDebounce from '../../../hooks/common';
import { DEBOUNCE_THRESHOLD_MS } from './constants';
import { find, map, uniq } from 'lodash';
import { format } from 'date-fns';
import FroalaEditor from '../../components/FroalaEditor';
import { stringify } from 'qs';
import { useProducts } from '../../../hooks/product';
import ExhibitionProductDnd from './components/ExhibitionProductDnd';
import { useMutation } from 'react-query';

import { toast } from 'react-toastify';
import { uploadImage } from '../../../api/photo';
import { CreateExhibitionParams, ExhibitionParams } from '../../../api_v2/admin/exhibitions/types';
import { getProduct, getProducts } from '../../../api_v2/admin/products';
import { createExhibition } from '../../../api_v2/admin/exhibitions';

export const DisplayAdd = () => {
  const history = useHistory();

  const [formState, setFormState] = useState<{ [key: string]: any }>({
    type: null,
    title: '',
    // thumbnail: '',
    weight: 0,
    isVisible: false,
    // endTime: undefined,
    content: '',
    products: [],
  });

  const editorContentRef = useRef('');
  const [displayPhoto, setDisplayPhoto] = useState<File | null | undefined>();

  const [searchProductKeyword, setSearchProductKeyword] = useState('');

  const [searchProducts, setSearchProducts] = useState([]);

  const [exhibitionStatus, setCreateExhibitionStatus] = useState<boolean>(false);

  useEffect(() => {
    if (!!searchProductKeyword && searchProductKeyword.length !== 0) {
      const searchProductQueryString = stringify(
        {
          limit: 100,
          filter: {
            word: searchProductKeyword,
          },
        },
        { addQueryPrefix: true, encode: false }
      );
      getProducts(searchProductQueryString).then((resultData) => {
        if (resultData?.success) {
          setSearchProducts(resultData.data.items);
        }
      });
    } else {
      setSearchProducts([]);
    }
  }, [searchProductKeyword]);

  const _onChangeText = (e: any) => {
    const { name, value } = e.target;
    setFormState((prev) => {
      return {
        ...prev,
        [name]: name === 'weight' ? parseInt(value) : value,
      };
    });
  };

  const _onChangeType = (e: any) => {
    setFormState((prev) => ({
      ...prev,
      type: e.target.value,
    }));
  };

  const _onChangeDetailList = (e: any) => {
    let chosenProductId = e.target.value;
    if (!chosenProductId) {
      return;
    }
    const findProduct = find(
      searchProducts,
      (product: any) => product.productId === chosenProductId
    );
    if (findProduct) {
      setFormState((prevState) => ({
        ...prevState,
        products: uniq([...prevState.products, findProduct]),
      }));
    }
  };

  const _onChangeProductKeyword = (e: any) => {
    setSearchProductKeyword(e.target.value);
  };

  const _handleSubmitButton = async () => {
    if (!formState.type) {
      toast.error('타입을 선택해주세요.');
      return;
    } else if (!(formState.weight >= 0)) {
      toast.error('0이상 숫자를 입력해주세요');
    } else {
      let redTextFlag = false;
      for (let i = 0; i < Object.keys(formState).length; i++) {
        let key = Object.keys(formState)[i];
        if (!['thumbnail', 'products'].includes(key)) {
          if (formState[key as keyof CreateExhibitionParams]) {
            let inputValue = formState[key as keyof CreateExhibitionParams]!.toString().trim();
            if (inputValue.length === 0) {
              redTextFlag = true;
              break;
            }
          }
        }
      }
      if (redTextFlag) {
        toast.error('모든항목을 작성해주세요');
      } else {
        setCreateExhibitionStatus(true);
        let createExhibitionResult = await createExhibition({
          ...formState,
          content: editorContentRef.current,
        });
        if (createExhibitionResult) {
          toast.success('성공적으로 기획을 등록하였습니다');
          setCreateExhibitionStatus(false);
        } else {
          toast.error('Something went wrong creating a exhibition');
        }
      }
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>기획전 추가</AdminH1>
      </div>

      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-start-1 col-span-1">
            <div className="font-bold text-lg mb-4">기본 정보</div>
            <div className="flex items-center space-x-3">
              <p>기획전 숨김 / 노출</p>
              <Toggle
                checked={formState.isVisible}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    isVisible: e,
                  }))
                }
              />
            </div>
          </div>
          <div className="col-start-1 col-span-1">
            <Select label="타입" onChange={_onChangeType}>
              <option disabled selected hidden>
                타입을 선택해주세요
              </option>
              {map(Object.values(ExhibitionType), (type) => (
                <option key={type} value={type} selected={type === formState.type}>
                  {ExhibitionText[type]}
                </option>
              ))}
            </Select>
          </div>
          <div className="col-start-1 col-span-1">
            <TextField label="제목" name="title" value={formState.title} onChange={_onChangeText} />
            <TextField
              label="진열순서 (높을수록 우선 노출됩니다)"
              type="number"
              name="weight"
              value={formState.weight}
              onChange={_onChangeText}
            />
          </div>

          {/* <TextField
            label="부제목"
            name="subTitle"
            value={formState.subTitle}
            onChange={_onChangeText}
          />
          <TextField
            label="타이머 설정(마감 날짜)"
            type="date"
            name="endTime"
            value={
              formState.endTime &&
              format(new Date(formState.endTime), 'yyyy-MM-dd')
            }
            onChange={_onChangeText}
          /> */}
          {/* <div className="col-start-1">
            <p className="label">*썸네일 업로드</p>
            <label htmlFor="displayPhoto">
              <div className="relative aspect-1 rounded-md border-2 border-grey-5">
                {displayPhoto || formState.thumbnail ? (
                  <>
                    <img
                      className="absolute w-full h-full rounded object-cover"
                      src={
                        displayPhoto
                          ? URL.createObjectURL(displayPhoto)
                          : formState.thumbnail
                      }
                      alt=""
                    />
                    <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-3 left-3">
                      사진 수정하기
                    </div>
                  </>
                ) : (
                  <div className="absolute w-full h-full rounded object-cover bg-white">
                    <div className="flex flex-col justify-center items-center space-y-1 w-full h-full">
                      <FileUploadImg />
                      <div className="text-sm text-gray-500 text-center pt-1">
                        이미지를 업로드해주세요.
                        <br />
                        (최대 1장)
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </label>
            <input
              type="file"
              id="displayPhoto"
              className="hidden"
              accept="image/*"
              onChange={(e) => {
                e.target.validity.valid &&
                  setDisplayPhoto(e.target.files?.item(0));
              }}
            />
          </div> */}
          <div className="md:col-span-2 col-start-1">
            <p className="label">내용(Editor)</p>
            <FroalaEditor
              defaultValue={formState.content}
              onChange={(model) => {
                editorContentRef.current = model;
              }}
            />
          </div>

          <div className="flex flex-col space-y-4 w-full col-span-2 overflow-x-auto">
            <div className="font-bold text-lg mb-4">노출 상품 리스트</div>
            <div className="flex flex-col col-span-1">
              <Search
                placeholder="상품을 검색해주세요."
                value={searchProductKeyword}
                onChange={_onChangeProductKeyword}
                // list="products"
              />
              <div>검색결과: {searchProducts && searchProducts.length}개</div>
              {searchProducts && searchProducts.length > 0 ? (
                <Select label="노출 상품 선택" onChange={_onChangeDetailList}>
                  <option disabled selected hidden>
                    상품을 선택해주세요
                  </option>
                  {map(searchProducts, (product: any) => (
                    <option key={product.productId} value={product.productId}>
                      {product.productName}
                    </option>
                  ))}
                </Select>
              ) : null}
            </div>

            <div className="flex flex-col">
              <p>선택한 상품 리스트</p>
              <ExhibitionProductDnd exhibitionState={formState} setExhibitionState={setFormState} />
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="취소"
            className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
            to="/admin/display"
          />
          <Button
            onClick={() => _handleSubmitButton()}
            text="추가하기"
            className="h-10 text-sm filled-black"
          />
        </div>
      </Card>
    </>
  );
};
