import { api } from '../../../plugins/axios';

export const getProduct = async (productId: string, queryString?: string) => {
  queryString = queryString ? queryString : '';
  let result = await api.get(`/products/${productId}` + queryString);
  let possibleStatus = [200, 201];

  if (possibleStatus.includes(result.status)) {
    return { success: true, data: result.data };
  } else {
    return { success: false };
  }
};

export const getProducts = async (queryString: string) => {
  try {
    let result = await api.get('/products/' + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getProductPageLink = async (url: string) => {
  let result = await api.get(`/products/productpagelink?url=${url}`);
  let possibleStatus = [200, 201];

  if (possibleStatus.includes(result.status)) {
    return { success: true, data: result.data };
  } else {
    return { success: false };
  }
};
