import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../components/Button';
import { Card } from '../../../../../components/Card';
import { Select } from '../../../../../components/Select';
import { TextField } from '../../../../../components/TextField';
import { AdminH1 } from '../../../../components/AdminH1';

import { useHistory, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { map } from 'lodash';

import {
  deleteCreateBanner,
  getCommunityBanner,
  updateCreateBanner,
} from '../../../../../api_v2/admin/community';
import FroalaEditor from './components/FroalaEditor';
import { Toggle } from '../../../../../components/Toggle';
import { BannerText, BannerType } from './repeatCode';

export const BannerDetail = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [formState, setFormState] = useState<any>({
    type: null,
    isVisible: false,
    data: '',
  });

  const [updateBannerStatus, setUpdateBannerStatus] = useState<boolean>(false);
  const [deleteBannerStatus, setDeleteBannerStatus] = useState<boolean>(false);

  useEffect(() => {
    getCommunityBanner(+id).then((resultData) => {
      if (resultData?.success) {
        if (resultData?.data) {
          const { type, isVisible, data } = resultData.data;
          setFormState({
            type,
            isVisible,
            data,
          });
        }
      }
    });
  }, []);

  const _onChangeText = (e: any) => {
    const { name, value } = e.target;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: name === 'weight' ? parseInt(value) : value,
    }));
  };

  const _onChangeType = (e: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      type: e.target.value,
    }));
  };

  const _handleUpdateButton = async () => {
    // if (!formState.type) {
    //   toast.error('노출 위치를 선택해주세요');
    // } else {
    setUpdateBannerStatus(true);

    let updateBannerResult = await updateCreateBanner(parseInt(id), {
      ...formState,
    });
    if (updateBannerResult) {
      toast.success('성공적으로 배너를 수정하였습니다');
      setUpdateBannerStatus(false);
    } else {
      toast.error('Something went wrong updating a banner');
    }
    // }
  };

  const _handleDeleteButton = async () => {
    setDeleteBannerStatus(true);
    let deleteBannerStatus = await deleteCreateBanner(parseInt(id));
    if (deleteBannerStatus) {
      toast.success('성공적으로 배너를 삭제하였습니다');
      setDeleteBannerStatus(false);
      history.push('/admin/community/banners');
    } else {
      toast.error('Something went wrong deleting a banner');
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>배너 상세</AdminH1>
      </div>
      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-start-1 col-span-2">
            <div className="flex justify-between">
              <div className="flex space-x-3">
                <p>진열</p>
                <Toggle
                  checked={formState.isVisible}
                  onChange={(e) =>
                    setFormState((prevState: any) => ({
                      ...prevState,
                      isVisible: e,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          {/* <Select label="노출 위치 선택" onChange={_onChangeType} value={formState?.type}>
            <option disabled selected hidden>
              노출 위치를 선택해주세요
            </option>
            {map(Object.values(BannerType), (banner) => (
              <option key={banner} value={banner}>
                {BannerText[banner]}
              </option>
            ))}
          </Select> */}
          <div className="col-start-1 col-span-2">
            <FroalaEditor
              defaultValue={formState?.data ?? ''}
              onChange={(dataModel) => {
                setFormState((prevState: any) => ({
                  ...prevState,
                  data: dataModel,
                }));
              }}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            onClick={() => _handleDeleteButton()}
            text="삭제하기"
            className="h-10 text-sm outlined-red-600 hover:bg-gray-50"
            disabled={updateBannerStatus || deleteBannerStatus}
          />
          <Button
            text="수정하기"
            className="h-10 text-sm -black"
            onClick={() => _handleUpdateButton()}
            disabled={updateBannerStatus || deleteBannerStatus}
          />
        </div>
      </Card>
    </>
  );
};
