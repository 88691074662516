import { Link } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { AdminH1 } from '../../components/AdminH1';
import { Button } from '../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { useAdminBanner } from '../../../hooks/banner';
import { map } from 'lodash';
import { useQueryString } from '../../../hooks/hooks';
import { useEffect, useState } from 'react';
import { getBanners } from '../../../api_v2/admin/banners';
import { BannerWeightEditor } from './components/BannerWeightEditor';

export const BannerList = () => {
  const queryString = useQueryString();
  const [currBanners, setCurrBanners] = useState<{ [key: string]: any }[]>([]);
  const [totalBannersCount, setTotalBannersCount] = useState<number>(0);

  const refreshBanners = (qs: string) => {
    getBanners(qs).then((resultData) => {
      if (resultData?.success) {
        if (resultData.data) {
          setCurrBanners(resultData.data.items);
          setTotalBannersCount(resultData.data.total);
        }
      }
    });
  };

  const onEditWeight = () => {
    refreshBanners(queryString);
  };

  useEffect(() => {
    refreshBanners(queryString);
  }, [queryString]);

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>배너 관리</AdminH1>
        <Button text="배너 추가" className="outlined-black text-sm" to="/admin/banner/add" />
      </div>
      <div className="flex justify-end items-center">
        <div className="flex space-x-2 items-center">
          <div>전체배너 수</div>
          <div className="text-green-500">{totalBannersCount}</div>
        </div>
        <div>개</div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>생성일</Table.Th>
              <Table.Th>위치</Table.Th>
              <Table.Th>weight(순서)</Table.Th>
              <Table.Th>제목</Table.Th>
              <Table.Th>부제목</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(currBanners, (banner) => (
              <Table.Row key={banner.id}>
                <Table.Td>{banner.id}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(banner.createdAt.toLocaleString(), MomentFormat.YYYYMMDDHmm)}
                </Table.Td>
                <Table.Td>{banner.type}</Table.Td>
                <Table.Td>
                  <BannerWeightEditor
                    weight={banner.weight}
                    banner={banner}
                    onEdit={onEditWeight}
                  />
                </Table.Td>
                <Table.Td>{banner.title}</Table.Td>
                <Table.Td>{banner.subTitle}</Table.Td>
                <Table.Td className="text-right">
                  <Link className="font-normal text-blue-500" to={`/admin/banner/${banner.id}`}>
                    수정하기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          {/* <Pagination.Label
            count={bannerData.items.length}
            total={bannerData.total}
          /> */}
          <Pagination.Nav basePath="/admin/banner" total={totalBannersCount} />
        </Pagination>
      </Card>
    </>
  );
};
