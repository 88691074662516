import { Button, Form, Input, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useHistory, useLocation } from 'react-router-dom';

import { parse, stringify } from 'qs';

import { Select as AntdSelect } from 'antd';
import 'antd/dist/antd.css';

import { Section } from '../../../../../components/Section';
import { Calendar } from './Calendar';
import { Icon } from '../../../../../components/Icon';

import {
  createConveyanceInfo,
  getGocampingInfoList,
} from '../../../../../api_v2/non_admin/conveyance/index';

const { Option: AntdOption } = AntdSelect;

const RepeatedCard = (dataObj: any, lastElementRef: any, handleGocampingInfo: any) => {
  const parseStr = (strData: any) => {
    try {
      let parsed = JSON.parse(strData);
      return parsed;
    } catch (e) {
      console.log('e', e);
      return null;
    }
  };

  const getTags = (dataObj: any) => {
    try {
      let tempArr: any[] = [];
      for (let i = 0; i < Object.keys(dataObj).length; i++) {
        let key = Object.keys(dataObj)[i];
        tempArr = [...tempArr, ...dataObj[key]];
      }
      return tempArr;
    } catch (e) {
      console.log('e', e);
      return [];
    }
  };

  return (
    <div
      onClick={() => handleGocampingInfo(dataObj)}
      ref={lastElementRef ?? null}
      className="grid grid-cols-1 border-2 rounded-[12px]
      mb-3 p-5 bg-[white] cursor-pointer"
      // style={{ borderRadius: '5px 5px 5px 5px' }}
      /* top-left | top-right | bottom-right | bottom-left */
    >
      <div className="grid grid-cols-5 gap-1">
        {dataObj?.firstImageUrl ? (
          <div className="text-[#5C5C5C] text-[13px] max-w-[100%] max-h-[50px]">
            <img className="h-full rounded-[8px]" src={dataObj?.firstImageUrl} />
          </div>
        ) : null}

        <div className="self-center col-span-4">
          <div className="flex font-[600] text-[#6F6F6F] text-[14px]">
            <div className="mr-1">{dataObj?.doName}</div>
            <div>{dataObj?.sigunguName}</div>
          </div>
          <div className="font-[700] text-[19px]">{dataObj?.facilityName}</div>
        </div>
      </div>
      <div className="font-[600] text-[#6F6F6F] text-[14px]">{dataObj?.gocampingRegion}</div>
      <div className="flex justify-between">
        <div className="font-[700] text-[19px]">{dataObj?.gocampingName}</div>
        {dataObj?.checkinDate ? (
          <div className={`flex justify-between`}>
            <div className="font-[700] text-[19px] text-[#FF6400] mr-2">
              {/* {dataObj?.checkinDate ? getDay(dataObj?.checkinDate) : null} */}
            </div>
            <div className="text-[#ebedeb] text-[19px] mr-2">|</div>
            <div className="font-[500] text-[19px] text-[#FF6400]">
              {/* {dataObj?.status == true
                ? '양도 완료'
                : calculateDDay(dataObj?.checkinDate)?.status == true
                ? 'D-' + calculateDDay(dataObj?.checkinDate)?.days
                : null} */}
            </div>
          </div>
        ) : null}
      </div>
      <div className="text-[#6F6F6F] text-[12px]">
        {dataObj?.checkinDate && dataObj?.checkoutDate
          ? dataObj?.checkinDate + ' ~ ' + dataObj?.checkoutDate
          : null}
      </div>
    </div>
  );
};

export const AddGocampingInfo = (props: any) => {
  const observer = useRef<IntersectionObserver>();

  const drawerRef = useRef<HTMLDivElement | null>(null);
  const [form] = Form.useForm();
  const history = useHistory();

  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [calendarState, setCalendarState] = useState<any>([
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: new Date(parsedSearch?.start_date as string),
          endDate: new Date(parsedSearch?.end_date as string),
          key: 'selection',
        }
      : {
          startDate: null,
          endDate: new Date(''),
          key: 'selection',
        },
  ]);

  const [locale, setLocale] = useState<any>('ko');

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const [addConveyanceInfoStatus, setAddConveyanceInfoStatus] = useState<any>(false);

  function closeDrawer() {
    if (props.drawerRef.current) {
      props.drawerRef.current.style.width = '0';
      props.drawerRef.current.style.height = '0';
    }
  }

  const checkPhoneNumb = (_: any, value: any) => {
    let flag = true;
    if (value?.trim().length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (isNaN(parseInt(value[i]))) {
          flag = false;
          break;
        }
      }
    } else {
      flag = false;
    }

    if (flag) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('전화번호를 입력해주세요. 예) 01012345678'));
  };

  const convertDateForQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const onFinish = async (values: any) => {
    if (calendarState[0]?.startDate && calendarState[0]?.endDate) {
      if (typeof values?.price !== 'number') {
        toast.error('양도비용을 입력해주세요');
      } else {
        let checkRequiredObj: { [key: string]: any } = {
          nickname: '닉네임',
          email: '이메일',
          phone: '전화번호',
          password: '비밀번호',
          'campinginfo-name': '캠핑장명',
        };
        let checkRequiredProblem = false;
        for (let i = 0; i < Object.keys(checkRequiredObj).length; i++) {
          let key = Object.keys(checkRequiredObj)[i];
          if (values[key]?.trim()?.length > 0) {
            continue;
          } else {
            checkRequiredProblem = true;
            toast.error(checkRequiredObj[key] + '을/를 입력해주세요');
            break;
          }
        }
        if (!checkRequiredProblem) {
          setAddConveyanceInfoStatus(true);
          const { nickname, email, phone, regions, price, password } = values;

          let createConveyanceInfoRes = await createConveyanceInfo({
            gocampingName: values['campinginfo-name'] ?? '',
            gocampingRegion: regions ?? '',
            nickname: nickname ?? '',
            transferAmount: price,
            checkinDate: convertDateForQS(calendarState[0]?.startDate),
            checkoutDate: convertDateForQS(calendarState[0]?.endDate),
            tel: phone ?? '',
            email: email ?? '',
            description: values['other-info'] ?? '',
            password: password,
            status: false,
          });

          if (createConveyanceInfoRes) {
            form.resetFields();
            toast.success('성공적으로 양도정보를 등록하였습니다.');
            history.goBack();
          } else {
          }
        }
        setAddConveyanceInfoStatus(false);
      }
    } else {
      toast('일정을 선택해주세요');
    }
  };

  const [gocampingInfoList, setGocampingInfoList] = useState<{ [key: string]: any } | null>({});
  const [page, setPage] = useState(1);
  const [gocampingInfoListLoading, setGocampingInfoListLoading] = useState(false);
  const [gocampingInfoListTotal, setGocampingInfoListTotal] = useState(0);

  useEffect(() => {
    let currentQueryStringObj: { [key: string]: any } = {
      page: page,
      limit: 10,
      filter: {},
      sort: { created_at: 'DESC' },
    };

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    setGocampingInfoListLoading(true);
    getGocampingInfoList(currentQueryString).then((res) => {
      setGocampingInfoList((prev) => {
        return { ...prev, [page]: res?.items };
      });
      setGocampingInfoListTotal(res?.total);
      setPage(page + 1);
      setGocampingInfoListLoading(false);
    });
  }, []);

  const calculateCurrListLen = (objData: any) => {
    let finalCount = 0;
    for (let i = 0; i < Object.keys(objData).length; i++) {
      let key = Object.keys(objData)[i];
      finalCount = finalCount + objData[key].length;
    }
    return finalCount;
  };

  const lastElement = useCallback(
    (target) => {
      if (gocampingInfoListLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            gocampingInfoList &&
            calculateCurrListLen(gocampingInfoList) < gocampingInfoListTotal &&
            !gocampingInfoListLoading
          ) {
            // fetchNextPage();
            let currentQueryStringObj: { [key: string]: any } = {
              page: page,
              limit: 10,
              filter: {},
              sort: { created_at: 'DESC' },
            };

            const currentQueryString = stringify(currentQueryStringObj, {
              addQueryPrefix: true,
              encode: false,
            });

            setGocampingInfoListLoading(true);

            getGocampingInfoList(currentQueryString).then((res) => {
              setGocampingInfoList((prev) => {
                return { ...prev, [page]: res?.items };
              });
              setGocampingInfoListTotal(res?.total);
              setPage(page + 1);
              setGocampingInfoListLoading(false);
            });
          } else {
          }
        },
        {
          threshold: 1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [gocampingInfoListLoading, gocampingInfoList, gocampingInfoListTotal]
  );
  const [gocampingSearchInput, setGocampingSearchInput] = useState<any | null>('');

  const handleGocampingSearchInput = (e: any) => {
    setGocampingSearchInput(e.target.value);
  };

  const handleGocampingSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      let currentQueryStringObj: { [key: string]: any } = {
        page: 1,
        limit: 10,
        filter: {},
        sort: { created_at: 'DESC' },
      };

      if ((gocampingSearchInput as string)?.trim()?.length > 0) {
        currentQueryStringObj.filter['word'] = (gocampingSearchInput as string)?.trim();
      }

      const currentQueryString = stringify(currentQueryStringObj, {
        addQueryPrefix: true,
        encode: false,
      });

      setGocampingInfoListLoading(true);
      getGocampingInfoList(currentQueryString).then((res) => {
        setGocampingInfoList({ [page]: res?.items });
        setGocampingInfoListTotal(res?.total);
        setPage(page + 1);
        setGocampingInfoListLoading(false);
      });
    }
  };

  const handleGocampingSearchButton = (evt: any) => {
    let currentQueryStringObj: { [key: string]: any } = {
      page: 1,
      limit: 10,
      filter: {},
      sort: { created_at: 'DESC' },
    };

    if ((gocampingSearchInput as string)?.trim()?.length > 0) {
      currentQueryStringObj.filter['word'] = (gocampingSearchInput as string)?.trim();
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    setGocampingInfoListLoading(true);
    getGocampingInfoList(currentQueryString).then((res) => {
      setGocampingInfoList({ [page]: res?.items });
      setGocampingInfoListTotal(res?.total);
      setPage(page + 1);
      setGocampingInfoListLoading(false);
    });
  };

  const handleGocampingInfo = (gocampingInfoData: any) => {
    props.setChosenGocampingInfo(gocampingInfoData);
    closeDrawer();
  };

  return (
    <>
      <div>
        <div className="side-padding mt-2 relative">
          <div
            className="flex flex-row-reverse items-center 
        px-4 h-12 border border-[#FF6400] rounded bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
          >
            <div className="flex justify-center items-center">{/* <Icon.Search /> */}</div>
            <Button
              className="bg-[#FF6400] text-[#FFFFFF] rounded-[12px]"
              style={{ height: '80%' }}
              onClick={(e) => handleGocampingSearchButton(e)}
            >
              검색
            </Button>
            <input
              className="flex-1 text-sm bg-white"
              placeholder="검색하기"
              onChange={(e) => handleGocampingSearchInput(e)}
              value={gocampingSearchInput}
              onKeyDown={(e) => handleGocampingSearchKeyDown(e)}
            />
          </div>
        </div>
        <Section className="max-w-md py-3 bg-[#FFFFFF] side-padding">
          {gocampingInfoList && Object.keys(gocampingInfoList as any)?.length === 0 ? (
            <>
              <p className="grid place-content-center h-44">검색결과가 없습니다</p>
            </>
          ) : gocampingInfoList && Object.keys(gocampingInfoList as any)?.length > 0 ? (
            Object.keys(gocampingInfoList as any).map(
              (page, groupInd) =>
                gocampingInfoList[page] &&
                gocampingInfoList[page].map((item: any, itemInd: number) => (
                  <>
                    {Object.keys(gocampingInfoList as any)?.length == groupInd + 1 &&
                    gocampingInfoList[page].length == itemInd + 1 ? (
                      <>
                        <div key={item?.id}>
                          {RepeatedCard(item, lastElement, handleGocampingInfo)}
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={item?.id}>{RepeatedCard(item, null, handleGocampingInfo)}</div>
                      </>
                    )}
                  </>
                ))
            )
          ) : null}
        </Section>
      </div>
    </>
  );
};
