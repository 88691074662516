import { Link, useLocation } from 'react-router-dom';
import { Card } from '../../../../../../components/Card';
import { Pagination } from '../../../../../../components/Pagination';
import { Table } from '../../../../../../components/Table';
import { AdminH1 } from '../../../../../components/AdminH1';
import { Button } from '../../../../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../../../../plugins/moment';

import { map } from 'lodash';
import { useQueryString } from '../../../../../../hooks/hooks';
import { useEffect, useState } from 'react';

import { parse, stringify } from 'qs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Icon } from '../../../../../../components/Icon';
import { getTDCAccommCampaigns } from '../../../../../../api_v2/admin/community/tdc-accommodations';

export const TDCAccommCampaignList = () => {
  const queryString = useQueryString();
  const [currCampaigns, setCurrCampaigns] = useState<{ [key: string]: any }[]>([]);
  const [totalCampaignsCount, setTotalCampaignsCount] = useState<number>(0);
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    let path = `?page=${parsed?.page ?? 1}&limit=${parsed?.limit ?? 10}`;
    if (parsed?.q) {
      path = path + `&q=${parsed?.q}`;
    }
    getTDCAccommCampaigns(path).then((resultData: any) => {
      if (resultData?.success) {
        if (resultData.data) {
          if (resultData.data[0]) {
            setCurrCampaigns(resultData.data[0]);
            setTotalCampaignsCount(resultData.data[1]);
          }
        }
      }
    });
  }, [search]);

  const handleSearch = async () => {
    try {
      let path = `?page=${1}&limit=${10}`;
      if (tdcCampaignSearchInput && typeof tdcCampaignSearchInput == 'string') {
        path = path + `&q=${tdcCampaignSearchInput?.trim() ?? ''}`;
      }
      window.location.href = path;
    } catch (e) {}
  };

  const [tdcCampaignSearchInput, setTdcCampaignSearchInput] = useState(parsed?.q ?? '');

  const handleTdcCampaignSearchInput = (e: any) => {
    setTdcCampaignSearchInput(e.target.value);
  };

  const handleTDCCampaignSearchButton = (evt: any) => {
    handleSearch();
  };

  const handleTDCCampaignSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>트립닷컴숙소캠페인리스트 관리</AdminH1>
        <div className="flex gap-[5px]">
          <Button
            text="켐페인 추가"
            className="outlined-black text-sm"
            to="/admin/community/tdc-accommodation/campaigns/add"
          />
        </div>
      </div>
      {parsed && (
        <>
          <div
            className="flex flex-row-reverse w-full max-w-[500px] items-center px-4 h-12 mt-2
                border border-[black] bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
          >
            <Button
              className="bg-[#FF6400] text-[#FFFFFF]"
              style={{ height: '80%' }}
              onClick={(e) => handleTDCCampaignSearchButton(e)}
            >
              검색
            </Button>
            {tdcCampaignSearchInput ? (
              <>
                <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                  <Icon.X
                    className="wh-3"
                    onClick={() => {
                      setTdcCampaignSearchInput('');
                    }}
                  />
                </div>
              </>
            ) : null}
            <input
              className="flex-1 text-sm bg-white"
              placeholder="검색하기"
              onChange={(e: any) => handleTdcCampaignSearchInput(e)}
              value={tdcCampaignSearchInput as string}
              onKeyDown={(e) => handleTDCCampaignSearchKeyDown(e)}
            />
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center gap-[10px]"></div>
          </div>
        </>
      )}
      <div className="flex justify-end items-center">
        <div className="flex space-x-2 items-center">
          <div>전체켐페인 수</div>
          <div className="text-green-500">{totalCampaignsCount}</div>
        </div>
        <div>개</div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>id</Table.Th>
              <Table.Th>uniqueId</Table.Th>
              <Table.Th>source</Table.Th>
              <Table.Th>name</Table.Th>
              <Table.Th>link</Table.Th>
              <Table.Th>endDate</Table.Th>
              <Table.Th>isVisible</Table.Th>
              <Table.Th>accommCampaignToAccomms</Table.Th>
              <Table.Th></Table.Th>
              <Table.Th>createdAt</Table.Th>
              <Table.Th>updatedAt</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(currCampaigns, (cp) => (
              <Table.Row key={cp.id}>
                <Table.Td>{cp.id}</Table.Td>
                <Table.Td>{cp.uniqueId}</Table.Td>
                <Table.Td>{cp.source}</Table.Td>
                <Table.Td>{cp?.name}</Table.Td>
                <Table.Td>
                  <a href={cp?.link} target="_blank" rel="noreferrer">
                    {cp?.link}
                  </a>
                </Table.Td>
                <Table.Td>{cp.endDate}</Table.Td>
                <Table.Td>{cp.isVisible ? 'Y' : 'N'}</Table.Td>
                <Table.Td>{cp?.accommCampaignToAccomms?.length ?? 0}</Table.Td>
                <Table.Td>
                  <a
                    href={`/admin/community/tdc-accommodation/campaigns/${cp.id}`}
                    rel="noreferrer"
                  >
                    수정
                  </a>
                </Table.Td>
                <Table.Td>
                  {utcToLocalFormat(cp.createdAt.toLocaleString(), MomentFormat.YYYYMMDDHmm)}
                </Table.Td>
                <Table.Td>
                  {utcToLocalFormat(cp.updatedAt.toLocaleString(), MomentFormat.YYYYMMDDHmm)}
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Nav
            basePath="/admin/community/tdc-accommodation/campaigns"
            total={totalCampaignsCount}
            q={parsed?.q ?? ''}
          />
        </Pagination>
      </Card>
    </>
  );
};
