import { Product } from '../../types';

export enum ExhibitionType {
  NORMAL = 'normal',
  BRAND = 'brand',
  Main = 'main',
}

export const ExhibitionText = {
  [ExhibitionType.NORMAL]: '일반',
  [ExhibitionType.BRAND]: '브랜드',
  [ExhibitionType.Main]: '메인',
};

export interface ExhibitionProductMap {
  id: number;
  exhibitionId: number;
  productProductId: string;
  productId: number;
  product: Product;
}

export interface Exhibition {
  id: number;
  type: ExhibitionType;
  title: string;
  subTitle: string;
  thumbnail: string;
  weight: number;
  createdAt: string;
  isVisible: boolean;
  endTime: string;
  content: string;
  exhibitionProductMaps: ExhibitionProductMap[];
}
