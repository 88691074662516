import { useEffect, useRef, useState } from 'react';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/third_party/embedly.min.css';
import Froala from 'react-froala-wysiwyg';
import { api } from '../../../../../../plugins/axios';

type FroalaEditorProps = {
  defaultValue?: string;
  onChange: (model: any) => void;
};

export default function FroalaEditor(props: FroalaEditorProps) {
  const ref = useRef({ editor: null });
  const [isFroalaInitialized, setIsFroalaInitialized] = useState(false);
  const [editor, setEditor] = useState<any>(undefined);
  const [model, setModel] = useState<string>('');

  const handleModelChange = (model: any) => {
    setModel(model);
    props.onChange(model);
  };

  useEffect(() => {
    props.defaultValue ? setModel(props.defaultValue) : setModel('<p>&nbsp;</p>');
  }, [props.defaultValue]);

  useEffect(() => {
    setEditor(ref.current.editor);
    editor && setIsFroalaInitialized(true);
  }, [ref.current]);

  useEffect(() => {
    if (isFroalaInitialized) {
      editor.html.set(model);
    }
  }, [isFroalaInitialized]);

  return (
    <Froala
      model={model}
      onModelChange={handleModelChange}
      tag="textarea"
      config={{
        key: 'eHE5C-11E3H3E2J2B5C3C3td1CGHNOa1TNSPH1e1J1VLPUUCVd1FC-22C4A3C3D2D4G2G2C3A2A2==',
        height: '1000px',
        width: '100%',
        // imageUpload: true,
        imageDefaultAlign: 'center',
        imageDefaultDisplay: 'block',
        imageMaxSize: 5 * 1024 * 1024,
        imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
        // videoUpload: true,
        videoDefaultAlign: 'center',
        videoDefaultDisplay: 'block',
        videoAllowedTypes: ['mp4'],
        codeViewKeepActiveButtons: ['selectAll'],
        events: {
          // 'image.beforeUpload': function (images: File[]) {
          //   //Before image is uploaded
          //   const data = new FormData();
          //   data.append('file', images[0]);
          //   const headers = {
          //     headers: {
          //       accept: '*/*',
          //       'Content-Type': 'multipart/form-data',
          //     },
          //   };
          //   api
          //     .post('/photo', data, headers)
          //     .then((res) => {
          //       // @ts-ignore
          //       this.image.insert(res.data.url, null, null, this.image.get());
          //     })
          //     .catch((err) => {
          //       console.log(err);
          //     });
          //   return false;
          // },
          // 'video.beforeUpload': function (images: File[]) {
          //   //Before image is uploaded
          //   const data = new FormData();
          //   data.append('file', images[0]);
          //   const headers = {
          //     headers: {
          //       accept: '*/*',
          //       'Content-Type': 'multipart/form-data',
          //     },
          //   };
          //   api
          //     .post('/photo', data, headers)
          //     .then((res) => {
          //       // @ts-ignore
          //       this.video.insert(res.data.url, null, null, this.video.get());
          //     })
          //     .catch((err) => {
          //       console.log(err);
          //     });
          //   return false;
          // },
        },
        attribution: false,
        placeholderText: '...',
        toolbarButtons: {
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'inlineClass',
              'inlineStyle',
              'clearFormatting',
            ],
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ],
          },
          moreRich: {
            buttons: [
              // 'insertVideo',
              'insertLink',
              // 'insertImage',
              'emoticons',
              'insertTable',
              'fontAwesome',
              'specialCharacters',
              'embedly',
              // 'insertFile',
              // 'insertHR',
            ],
          },
          moreMisc: {
            buttons: [
              'undo',
              'redo',
              // 'fullscreen',
              // 'print',
              // 'getPDF',
              'spellChecker',
              'selectAll',
              'html',
              'help',
              'insertHTML',
            ],
            align: 'right',
            buttonsVisible: 2,
          },
        },
        pluginsEnabled: [
          'codeView',
          'table',
          'spell',
          'quote',
          'save',
          'quickInsert',
          'paragraphFormat',
          'paragraphStyle',
          'help',
          'draggable',
          'align',
          'link',
          'lists',
          // 'file',
          // 'image',
          'emoticons',
          'url',
          // 'video',
          'embedly',
          'colors',
          'entities',
          'inlineClass',
          'inlineStyle',
          // 'imageTUI',
        ],
      }}
    />
  );
}
