import React, { useState } from 'react';
import { TopTitleBar } from '../../components/TopTitleBar';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { useMutation } from 'react-query';
import { api } from '../../plugins/axios';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { adminTokenState } from '../../ridge/ridge';
import { LOCAL_STORAGE_KEY } from '../../localstroage';

export const AdminLoginPage = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const loginMutation = useMutation<AxiosResponse, AxiosError>(
    () =>
      api.post(`/auth/login`, {
        email,
        password,
      }),
    {
      onError: (e: AxiosError) => {
        console.log(e);
        toast.error('로그인에 실패했습니다.');
      },
      onSuccess: (response) => {
        if (response?.data?.token) {
          adminTokenState.set(response.data.token);
          toast.success('로그인에 성공했습니다.');
          history.replace(`/admin`);
        }
      },
    }
  );

  return (
    <div className="max-w-md mx-auto relative">
      <TopTitleBar title="관리자 로그인" home={false} />
      <div className="side-padding mt-8">
        <h1>로그인해주세요</h1>
        <div className="flex flex-col space-y-4 mt-8">
          <TextField
            label="이메일*"
            type="email"
            placeholder="이메일을 입력해주세요."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            label="비밀번호*"
            type="password"
            placeholder="비밀번호를 입력해주세요."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div className="flex flex-col space-y-3 pt-8 pb-4">
            <Button
              onClick={() => loginMutation.mutate()}
              text="로그인"
              className="filled-gray-900 h-14"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
