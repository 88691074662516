import React, { useEffect, useState } from 'react';
import Version1 from './Type1';
import Version2 from './Type2';
import Type3 from './Type3';
import { TopTitleBar } from '../components/TopTitleBar';

export default function JourneyLog() {
  const tabs = [
    // { title: 'Map1', content: <Version1 /> },
    // { title: 'Map2', content: <Version2 /> },
    { title: '국내 여행 기록', content: <Type3 /> },
  ];
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="flex flex-col">
      <TopTitleBar logo={true} />
      {/* Tab Buttons */}
      <div className="flex justify-center py-[20px] w-full w-max-md">
        {tabs.map((tab: any, index: any) => (
          <button
            key={index}
            className={`px-4 py-2 ${
              index === activeTab ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="mt-4">{tabs[activeTab].content}</div>
    </div>
  );
}
