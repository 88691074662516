import { stringify } from 'qs';
import { api } from '../../../plugins/axios';
import { apiForTempSol } from '../../../tempSolution/apiForTempSol';

export const createConveyanceInfo = async (createConveyanceInfoParams: any) => {
  try {
    // let result = await api.post(apiForTempSol + '/transfers', createConveyanceInfoParams);
    let result = await api.post('/transfers', createConveyanceInfoParams);

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const updateConveyanceInfo = async (id: number, updateConveyanceInfoParams: any) => {
  try {
    // let result = await api.patch(apiForTempSol + `/transfers/${id}`, updateConveyanceInfoParams);
    let result = await api.patch(`/transfers/${id}`, updateConveyanceInfoParams);

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      if (result?.data?.message) {
        return { success: false, message: result?.data?.message };
      } else {
        return { success: true };
      }
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getConveyanceInfoList = async (queryString: any) => {
  try {
    // let result = await api.get(apiForTempSol + '/transfers' + queryString);
    let result = await api.get('/transfers' + queryString);

    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return result.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getConveyanceInfo = async (id: any, queryString?: string) => {
  try {
    // let result = await api.get(apiForTempSol + `/transfers/${id}` + queryString);
    let result = await api.get(`/transfers/${id}` + queryString);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const checkPasswordToEditConveyanceInfo = async (id: any, checkPasswordParams: any) => {
  try {
    // let result = await api.post(apiForTempSol + `/transfers/${id}`, checkPasswordParams);
    let result = await api.post(`/transfers/${id}`, checkPasswordParams);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      return result.data;
    } else {
      return result.data;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getGocampingInfoList = async (queryString: any) => {
  try {
    // let result = await api.get(apiForTempSol + '/gocampings' + queryString);
    let result = await api.get('/gocampings' + queryString);

    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return result.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getMyConveyanceInfoList = async (queryString: any) => {
  try {
    // let result = await api.get(apiForTempSol + '/users/transfers' + queryString);
    let result = await api.get('/users/transfers' + queryString);

    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result?.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getMyConveyanceInfo = async (id: any, queryString: any) => {
  try {
    // let result = await api.get(apiForTempSol + `/users/transfers/${id}` + queryString);
    let result = await api.get(`/users/transfers/${id}` + queryString);

    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result?.status)) {
      return { success: true, data: result?.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const updateMyConveyanceInfo = async (id: number, updateConveyanceInfoParams: any) => {
  try {
    // let result = await api.patch(
    //   apiForTempSol + `/users/transfers/${id}`,
    //   updateConveyanceInfoParams
    // );
    let result = await api.patch(`/users/transfers/${id}`, updateConveyanceInfoParams);

    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result?.status)) {
      if (result?.data?.message) {
        return { success: false, message: result?.data?.message };
      } else {
        return { success: true };
      }
    }
  } catch (e) {
    console.log('e', e);
  }
};
