import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { deleteBanner, getBanner, updateBanner } from '../../../../api_v2/admin/banners';
import { Icon } from '../../../../components/Icon';
import { TextField } from '../../../../components/TextField';
import { UpdateBannerParams } from '../../../../api_v2/admin/banners/types';
import { toast } from 'react-toastify';
interface BannerWeightEditorProps {
  weight: number;
  banner: any;
  onEdit(): void;
}

export const BannerWeightEditor: FC<BannerWeightEditorProps> = ({ weight, banner, onEdit }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [weightState, setWeightState] = useState<number>(weight);
  const [formState, setFormState] = useState<UpdateBannerParams>({
    type: banner.type,
    title: banner.title,
    thumbnail: banner.thumbnail,
    url: banner.url,
    weight: banner.weight,
  });

  const _onChangeText = (e: any) => {
    const { value } = e.target;
    setWeightState(value);
  };

  const onSubmit = async () => {
    formState.weight = weightState;
    let updatePopularKeywordResult = await updateBanner(banner.id, {
      ...formState,
      thumbnail: banner.thumbnail,
    });
    if (updatePopularKeywordResult) {
      toast.success('성공적으로 진열 순서를 수정했습니다.');
      setEditing(false);
      onEdit();
    } else {
      toast.error('Something wwent wrong updating weight.');
    }
  };

  const cancelEdit = () => {
    setEditing(false);
    setWeightState(weight);
  };

  return (
    <>
      {editing ? (
        <div>
          <TextField
            className="inline-block"
            type="number"
            name="weight"
            value={weightState}
            onChange={_onChangeText}
          />
          <button className="border-gray-300 bg-white" onClick={onSubmit}>
            <FontAwesomeIcon icon={faCheck} className="leading-6" />
          </button>
          <button className="border-gray-300 bg-white rounded-r-md" onClick={cancelEdit}>
            <Icon.X />
          </button>
        </div>
      ) : (
        <a
          href="#"
          onClick={() => {
            setEditing(true);
          }}
        >
          {weight}
        </a>
      )}
    </>
  );
};
