import { FC, useEffect, useState } from 'react';
import { Icon } from '../../../../components/Icon';
import { TextField } from '../../../../components/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ExhibitionParams } from '../../../../api_v2/admin/exhibitions/types';
import { updateExhibition } from '../../../../api_v2/admin/exhibitions';
import { toast } from 'react-toastify';
import { chain } from 'lodash';

interface WeightEditorProps {
  weight: number;
  exhibition: any;
  onEdit(): void;
}

export const WeightEditor: FC<WeightEditorProps> = ({ weight, exhibition, onEdit }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [weightState, setWeightState] = useState<number>(weight);

  const [formState, setFormState] = useState<ExhibitionParams>({
    id: exhibition.id,
    type: exhibition.type,
    title: exhibition.title,
    weight: exhibition.weight,
    isVisible: exhibition.isVisible,
    content: exhibition.content,
    products: chain(exhibition.exhibitionProductMaps).sortBy('order').map('product').value(),
  });

  const _onChangeText = (e: any) => {
    const { value } = e.target;
    setWeightState(value);
  };

  const cancelEdit = () => {
    setEditing(false);
    setWeightState(weight);
  };

  const onSubmit = async () => {
    formState.weight = weightState;
    let updateExhibitionResult = await updateExhibition(+exhibition.id, {
      ...formState,
      id: +exhibition.id,
      content: exhibition.content,
    });
    if (updateExhibitionResult) {
      toast.success('성공적으로 진열 순서를 수정했습니다.');
      setEditing(false);
      onEdit();
    } else {
      toast.error('Something wwent wrong updating weight.');
    }
  };

  return (
    <>
      {editing ? (
        <div>
          <TextField
            className="inline-block"
            type="number"
            name="weight"
            value={weightState}
            onChange={_onChangeText}
          />
          <button className="border-gray-300 bg-white" onClick={onSubmit}>
            <FontAwesomeIcon icon={faCheck} className="leading-6" />
          </button>
          <button className="border-gray-300 bg-white rounded-r-md" onClick={cancelEdit}>
            <Icon.X />
          </button>
        </div>
      ) : (
        <a
          href="#"
          onClick={() => {
            setEditing(true);
          }}
        >
          {weight}
        </a>
      )}
    </>
  );
};
