import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import LazyLoad from 'react-lazyload';

import { useInViewport } from 'react-in-viewport';

import { ReactComponent as IconNoPicture } from '../../../../../../assets/icons/icon-no-picture.svg';
import { ReactComponent as IconComment } from '../../../../../../assets/icons/icon-comment.svg';
import { stringify } from 'qs';

enum DrawerType {
  VK_TOUR_DEST_DETAIL = 'vk_tour_dest_detail',
}

interface CardType1V3 {
  item?: any;
  lastElement?: any;
  handlePostinfoSwiperSlide?: any;
  lowestPricesObj?: any;
  pageInd?: any;
  openDrawer?: any;
  setChosenVKTourDestForDetail?: any;
}

export const CardType1V3: FC<CardType1V3> = ({
  item,
  lastElement,
  handlePostinfoSwiperSlide,
  lowestPricesObj,
  pageInd,
  openDrawer,
  setChosenVKTourDestForDetail,
}) => {
  const cardObserver = useRef<IntersectionObserver>();
  const cardImgRef = useRef<any>(null);
  const [imgInViewport, setImgInViewport] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  const viewportRef = useRef<any>(null);
  const { inViewport, enterCount, leaveCount } = useInViewport(
    viewportRef as any,
    { threshold: 0.1 },
    { disconnectOnLeave: false }
  );

  const cardImgContRef = useCallback((target) => {
    if (cardObserver.current) cardObserver.current.disconnect();

    cardObserver.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setImgInViewport(true);
        } else {
        }
      },
      {
        // rootMargin: '400px',
        threshold: 0.1,
      }
    );

    if (target) cardObserver.current.observe(target);
  }, []);

  useEffect(() => {
    const handleEvent = () => {
      let imgEle = cardImgRef?.current?.querySelector('img');
      if (imgEle?.complete == true && imgInViewport == true) {
        setImgLoaded(true);
      }
    };
    handleEvent();
    window.addEventListener('DOMSubtreeModified', handleEvent);
    return () => window.removeEventListener('DOMSubtreeModified', handleEvent);
  }, [imgInViewport, cardImgRef?.current?.querySelector('img')]);

  const changeImgObjectFit = (slideImage: any) => {
    try {
      if (slideImage) {
        const imgElement = slideImage as any;
        const width = imgElement?.naturalWidth as any;
        const height = imgElement?.naturalHeight as any;
        if (imgElement && width >= 0 && height > 0) {
          const aspectRatio = width / height;
          if (aspectRatio > 1) {
            imgElement.style['object-fit'] = 'cover';
          } else {
            imgElement.style['object-fit'] = 'contain';
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleVKTourDest = (vKTourDest: any) => {
    try {
      window.location.href = `/community/vk-tour-dests/${vKTourDest?.id}`;
      // setChosenVKTourDestForDetail(vKTourDest);
      // openDrawer(DrawerType?.VK_TOUR_DEST_DETAIL);
    } catch (e) {}
  };

  const [loadedImages, setLoadedImages] = useState<any>([]);

  const handleImageLoad = (url: any) => {
    if (loadedImages?.includes(url)) {
    } else {
      setLoadedImages((prev: any) => {
        return [...prev, url];
      });
    }
  };

  return (
    <>
      <div
        className="border border-[#D9D9D9] mb-5 card-type-1 relative"
        onClick={() => handleVKTourDest(item)}
      >
        {item?.images?.length > 0 ? (
          <>
            <>
              <LazyLoad>
                <img
                  ref={(el) => {
                    if (el) {
                      changeImgObjectFit(el);
                    }
                  }}
                  className="w-full h-[230px] object-cover"
                  src={item?.images[0]?.url}
                  onLoad={() => handleImageLoad(item?.images[0]?.url)}
                />
              </LazyLoad>
            </>
            {loadedImages.includes(item?.images[0]?.url) ? (
              <></>
            ) : (
              <>
                <div className="absolute top-[0px] animate-pulse w-full h-[230px] bg-gradient-to-br from-blue-200 via-purple-300 to-red-200"></div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="h-[230px] w-full flex justify-center items-center">
              <IconNoPicture width={'60px'} height={'60px'} />
            </div>
          </>
        )}
        <div className="side-padding my-3">
          <div className="flex justify-between">
            <div className="text-[12px]">{(item?.distance?.toFixed(2) ?? 0) + 'km' ?? null}</div>
          </div>
          <div className="flex justify-between">
            <div className="font-[700] text-[15px]">
              {item?.title
                ? item?.title.length <= 11
                  ? item?.title
                  : item?.title?.substring(0, 11) + '...'
                : null}
            </div>
          </div>
          <div className="text-[#6F6F6F] text-[12px]">{item?.addr1}</div>
          <div className="mt-[5px] text-[13px]">
            {item?.overview
              ? item?.overview.length <= 80
                ? item?.overview
                : item?.overview?.substring(0, 80) + '...'
              : null}
          </div>
        </div>
      </div>
    </>
  );
};
