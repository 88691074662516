import React, { useEffect, useRef, useState } from 'react';
import { getDayoutCommerceNewProductsPriceList } from '../../../../../api_v2/admin/dayout/commerce';
import { Link, useLocation } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { Button } from '../../../../../components/Button';
import { toast } from 'react-toastify';
import { isNumber } from 'lodash';
import axios from 'axios';
import { api } from '../../../../../plugins/axios';
import { dummyNewProductsPriceList } from './constants';

export default function PriceList() {
  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [newProductsPriceListStatus, setNewProductsPriceListStatus] = useState(false);

  const cancelTokenRef = useRef();

  const [newProductsPriceList, setNewProductsPriceList] = useState([]);

  useEffect(() => {
    // setNewProductsPriceList(dummyNewProductsPriceList as any);
    if (cancelTokenRef?.current) {
      (cancelTokenRef as any).current.cancel('Canceled api request due to new request.');
      setNewProductsPriceListStatus(false);
    }

    let currentQueryStringObj: { [key: string]: any } = {
      filter: {},
    };

    for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
      let key = Object.keys(parsedSearch)[i];
      if (key === 'search_query') {
        currentQueryStringObj['searchTerm'] = parsedSearch[key];
      } else if (key === 'min_price') {
        currentQueryStringObj['minPrice'] = parsedSearch[key];
      } else if (key === 'max_price') {
        currentQueryStringObj['maxPrice'] = parsedSearch[key];
      }
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    if (
      currentQueryStringObj?.searchTerm?.trim() &&
      currentQueryStringObj?.searchTerm?.trim()?.length > 0 &&
      parseFloat(currentQueryStringObj?.minPrice) > 0 &&
      parseFloat(currentQueryStringObj?.maxPrice) > 0
    ) {
      setNewProductsPriceListStatus(true);
      (cancelTokenRef as any).current = axios.CancelToken.source();
      api
        .get(`/admin/production/price-list` + currentQueryString, {
          cancelToken: (cancelTokenRef as any).current.token,
        })
        .then((res) => {
          setNewProductsPriceList(res?.data?.priceData ?? []);
          setNewProductsPriceListStatus(false);
        })
        .catch((e) => {
          console.log('e', e);
          setNewProductsPriceList([]);
          setNewProductsPriceListStatus(false);
        });
    } else {
    }
  }, []);

  const handleSearch = (queryObj: any) => {
    if (queryObj['search_query']?.trim()?.length <= 0 || !queryObj['search_query']) {
      toast.error('검색어를 입력해주세요');
    } else if (parseFloat(queryObj.min_price) <= 0 || parseFloat(queryObj.max_price) <= 0) {
      toast.error('0이 아닌숫자를 입력해주세요');
    } else {
      if (cancelTokenRef?.current) {
        (cancelTokenRef as any).current.cancel('Canceled api request due to new request.');
        setNewProductsPriceListStatus(false);
      }
      const currentQueryString = stringify(queryObj, {
        addQueryPrefix: true,
        encode: false,
      });

      window.location.href = '/admin/dayout/commerce/new-products/price-list' + currentQueryString;
    }
  };

  const [searchInput, setSearchInput] = useState(parsedSearch?.search_query ?? '');

  const handleSearchInput = (e: any) => {
    setSearchInput(e.target.value);
  };

  const handleSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      const tempQueryObj: { [key: string]: any } = {};
      if ((searchInput as string)?.trim()?.length > 0) {
        tempQueryObj['search_query'] = (searchInput as string)?.trim() ?? '';
      }

      tempQueryObj['min_price'] = priceObj?.minPrice ?? 0;
      tempQueryObj['max_price'] = priceObj?.maxPrice ?? 0;

      handleSearch(tempQueryObj);
    }
  };

  const handleCampingSearchButton = (evt: any) => {
    const tempQueryObj: { [key: string]: any } = {};

    if ((searchInput as string)?.trim()?.length > 0) {
      tempQueryObj['search_query'] = (searchInput as string)?.trim() ?? '';
    }

    tempQueryObj['min_price'] = priceObj?.minPrice ?? 0;
    tempQueryObj['max_price'] = priceObj?.maxPrice ?? 0;

    handleSearch(tempQueryObj);
  };

  const [priceObj, setPriceObj] = useState<{ [key: string]: any }>({
    minPrice: parsedSearch?.min_price ? parseFloat(parsedSearch?.min_price as string) : 0,
    maxPrice: parsedSearch?.max_price ? parseFloat(parsedSearch?.max_price as string) : 0,
  });

  const handlePriceChange = async (changeType: string, changedData: any) => {
    if (parseFloat(changedData) >= 0) {
      setPriceObj((prev) => {
        return { ...prev, [changeType]: parseFloat(changedData) };
      });
    } else {
      setPriceObj((prev) => {
        return { ...prev, [changeType]: 0 };
      });
    }
  };

  const [sortCurrencyObj, setSortCurrencyObj] = useState<{ [key: string]: any } | null>(null);

  const handleSortCurrency = (currencyType: any, sortType: any) => {
    setSortCurrencyObj({ currencyType, sortType });
    let copyList = [...newProductsPriceList];
    copyList.sort((a: any, b: any) => {
      if (sortType == 'ASC') {
        return a.priceObj[currencyType] - b.priceObj[currencyType];
      } else {
        return b.priceObj[currencyType] - a.priceObj[currencyType];
      }
    });
    setNewProductsPriceList(copyList);
  };

  const checkSortCurrency = (currencyType: any, sortType: any) => {
    try {
      if (currencyType == sortCurrencyObj?.currencyType && sortType == sortCurrencyObj?.sortType) {
        return true;
      } else return false;
    } catch (e) {
      console.log('e', e);
      return null;
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-[15px]">
        <div className="w-full max-w-[750px] p-[15px] border-[1px] rounded-[5px] mb-[15px]">
          <div className="flex w-full mb-[5px]">
            <input
              className="flex-1 h-[42px] w-full max-w-[450px] px-[5px] mr-[5px] text-sm bg-white border border-[#ADB5BD]"
              placeholder="검색하기"
              onChange={(e) => handleSearchInput(e)}
              value={searchInput as string}
              onKeyDown={(e) => handleSearchKeyDown(e)}
            />
          </div>
          <div className="flex w-full mb-[5px]">
            <div className="mr-[5px]">
              <span className="mr-[5px]">Min</span>
              <input
                type="number"
                className={`border border-[#D0D0D0] min-h-[30px] max-h-[35px] text-[13px] min-w-[150px] max-w-[200px]`}
                min={0}
                step={1}
                name="minPrice"
                value={priceObj?.minPrice}
                onChange={(e) => handlePriceChange('minPrice', e.target.value)}
                onInput={(e: any) => {}}
                placeholder=""
              />
            </div>
            <div className="mr-[5px]">
              <span className="mr-[5px]">Max</span>
              <input
                type="number"
                className={`border border-[#D0D0D0] min-h-[30px] max-h-[35px] text-[13px] min-w-[150px] max-w-[200px]`}
                min={0}
                step={1}
                name="maxPrice"
                value={priceObj?.maxPrice}
                onChange={(e) => handlePriceChange('maxPrice', e.target.value)}
                onInput={(e: any) => {}}
                placeholder=""
              />
            </div>
            <div>($)</div>
          </div>
          <div className="flex justify-end">
            <Button
              className="h-[42px] text-sm text-[black] font-[400] border border-[#ADB5BD]"
              onClick={(e) => {
                handleCampingSearchButton(e);
              }}
            >
              검색
            </Button>
          </div>
        </div>
        <div>
          {newProductsPriceListStatus ? (
            <div className="flex justify-center bg-[white] pt-[100px]">
              <div className="loading-container text-[50px]">
                <span className="loading-dot">.</span>
                <span className="loading-dot">.</span>
                <span className="loading-dot">.</span>
              </div>
            </div>
          ) : newProductsPriceList?.length == 0 ? (
            <div className="flex justify-center">가격 정보가 없습니다.</div>
          ) : (
            <div className="grid grid-cols-6 mt-[20px]">
              <div></div>
              <div>URL</div>
              <div className="text-center flex justify-center items-center">
                <div className="mr-[10px]">$</div>
                <div className="cursor-pointer">
                  <div
                    className={`${checkSortCurrency('$', 'ASC') ? 'font-[700]' : ''}`}
                    onClick={() => handleSortCurrency('$', 'ASC')}
                  >
                    ASC
                  </div>
                  <div
                    className={`${checkSortCurrency('$', 'DESC') ? 'font-[700]' : ''}`}
                    onClick={() => handleSortCurrency('$', 'DESC')}
                  >
                    DESC
                  </div>
                </div>
              </div>
              <div className="text-center flex justify-center items-center">
                <div className="mr-[10px]">€</div>
                <div className="cursor-pointer">
                  <div
                    className={`${checkSortCurrency('€', 'ASC') ? 'font-[700]' : ''}`}
                    onClick={() => handleSortCurrency('€', 'ASC')}
                  >
                    ASC
                  </div>
                  <div
                    className={`${checkSortCurrency('€', 'DESC') ? 'font-[700]' : ''}`}
                    onClick={() => handleSortCurrency('€', 'DESC')}
                  >
                    DESC
                  </div>
                </div>
              </div>
              <div className="text-center flex justify-center items-center">
                <div className="mr-[10px]">£</div>
                <div className="cursor-pointer">
                  <div
                    className={`${checkSortCurrency('£', 'ASC') ? 'font-[700]' : ''}`}
                    onClick={() => handleSortCurrency('£', 'ASC')}
                  >
                    ASC
                  </div>
                  <div
                    className={`${checkSortCurrency('£', 'DESC') ? 'font-[700]' : ''}`}
                    onClick={() => handleSortCurrency('£', 'DESC')}
                  >
                    DESC
                  </div>
                </div>
              </div>
              <div className="text-center flex justify-center items-center">
                <div className="mr-[10px]">¥</div>
                <div className="cursor-pointer">
                  <div
                    className={`${checkSortCurrency('¥', 'ASC') ? 'font-[700]' : ''}`}
                    onClick={() => handleSortCurrency('¥', 'ASC')}
                  >
                    ASC
                  </div>
                  <div
                    className={`${checkSortCurrency('¥', 'DESC') ? 'font-[700]' : ''}`}
                    onClick={() => handleSortCurrency('¥', 'DESC')}
                  >
                    DESC
                  </div>
                </div>
              </div>
              <div className="col-span-6 border-b-[1px]"></div>
              {newProductsPriceList.map((priceData: any, index) => (
                <>
                  <div>{index + 1}</div>
                  <div>
                    <a
                      href={priceData?.url}
                      target="_blank"
                      rel="noreferrer"
                      className="break-words"
                    >
                      {priceData?.url}
                    </a>
                  </div>
                  <div className="text-center">{priceData?.priceObj['$'] ?? '-'}</div>
                  <div className="text-center">{priceData?.priceObj['€'] ?? '-'}</div>
                  <div className="text-center">{priceData?.priceObj['£'] ?? '-'}</div>
                  <div className="text-center">{priceData?.priceObj['¥'] ?? '-'}</div>
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
