import { api } from '../../../plugins/axios';

export const uploadImage = async (file: File) => {
  try {
    const formFile = new FormData();
    formFile.append('file', file);
    let result = await api.post('/photo', formFile, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data.url };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};
