/**
 * 로그인 인증 방식 종류
 */
export enum authTypes {
  kakao = 'kakao',
  naver = 'naver',
  apple = 'apple',
}

export enum thirdAuthAction {
  login = 'login',
  register = 'register',
}

export const authTypes_kor: {
  [authType: string]: string,
} = {
  [authTypes.kakao]: '카카오',
  [authTypes.naver]: '네이버',
  [authTypes.apple]: '사과',
}

enum thirdAuthErrors {
  duplicated = "duplicated", // duplicated id or email
  invalid = "invalid", // invalid number (tel)
  social_app_required = "social-app-required", // social login not setted (clayful)
  unretrievable_social_profile = "unretrievable-social-profile", // cannot get profile from 3rd party
}

export const getErrorMessage = (errorCode: string) => {
  if (!errorCode) {
    return null;
  }

  if (thirdAuthErrors.duplicated === errorCode.split('-')[0]) {
    return `해당 이메일 혹은 아이디는 이미 가입되었어요` + `\n` +
      `이전에 가입하신 계정으로 로그인 해주세요`;
  }

  if (thirdAuthErrors.invalid === errorCode.split('-')[0]) {
    return `전화번호 정보가 잘못되어 가입할 수 없어요`;
  }

  if (thirdAuthErrors.social_app_required === errorCode) {
    console.error('[3rdAuth] getErrorMessage - thirdAuthErrors.social_app_required error')
    return `해당 로그인은 지원하지 않아요` + '\n' +
      `ERROR: (social_app_required)`;
  }
  if (thirdAuthErrors.unretrievable_social_profile === errorCode) {
    console.error('[3rdAuth] getErrorMessage - thirdAuthErrors.unretrievable_social_profile error')
    return `정보 제공자로부터 올바른 정보를 받지 못했어요` + `\n` +
      `정보 제공자의 일시적인 오류일 수 있으니 잠시 후 다시 시도해보세요`;
  }
}