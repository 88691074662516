import { useEffect, useState, useRef, useCallback } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import { parse, stringify } from 'qs';
import { toast } from 'react-toastify';

import { default as htmlReactParser, attributesToProps } from 'html-react-parser';

import { Section } from '../../../../../components/Section';
import { TopTitleBar } from './components/TopTitleBar';

import { CustomDrawer } from './CustomDrawer';

import { createPayment } from '../../../../../api_v2/non_admin/payment';
import { paymentTokenState as campingBookingPaymentTokenState } from '../../../../../ridge/dayout-project-1/camping/booking';
import {
  checkPaymentV2,
  getCampingRefundPolicyBeforeReservationV2,
} from '../../../../../api_v2/non_admin/onda';
import CopyPNG from '../../../../../assets/img/icon-copy.png';
import { ReactComponent as CopySVG } from '../../../../../assets/icons/icon-copy.svg';

import dayjs from 'dayjs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '../../../../../components/Button';
import { modalState } from '../../../../../ridge/ridge';

SwiperCore.use([Pagination, Autoplay]);

enum PaymentMethod {
  CARD = 'card',
  VBANK = 'vbank',
}

const paymentMethodObj: { [key: string]: any } = {
  card: '신용카드',
  vbank: '무통장 입금',
  point: '포인트결제',
};

const paymentStatusObj: { [key: string]: any } = {
  ready: '가상계좌 발급 완료',
  paid: '결제 성공',
  cancelled: '취소',
  fail: '결제 실패',
};

const bookingStatusObj: { [key: string]: any } = {
  waiting: '결제 대기중',
  ready: '입금 대기중',
  pending: '예약 요청중(입금완료)',
  confirmed: '확정',
  cancelled: '취소',
  canceled: '취소', // data from onda db
  failed: '실패',
};

export const BookingResultPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const { campingId, zoneId } = useParams<{
    campingId: string;
    zoneId: string;
    siteId: string;
  }>();
  const [campingSite, setCampingSite] = useState<any>(null);
  const [campingSiteZone, setCampingSiteZone] = useState<any>(null);

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const { imp_uid, merchant_uid, imp_success, error_msg } = parsedSearch;

  useEffect(() => {
    // let foundCampingObj = campingDummyData.find((campingObj: any) => {
    //   if (campingId == campingObj?.id) {
    //     return true;
    //   } else return false;
    // });
    // if (foundCampingObj && foundCampingObj?.zones) {
    //   setCampingSite(foundCampingObj);
    //   let foundZone = foundCampingObj?.zones.find((zoneInfo: any) => {
    //     if (zoneInfo?.id == zoneId) {
    //       return true;
    //     } else return false;
    //   });
    //   if (foundZone) {
    //     setCampingSiteZone(foundZone);
    //   }
    // }
    setCampingSite({});
    setCampingSiteZone({});
  }, []);

  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '80%';
    }
  };

  useEffect(() => {
    // localStorage.removeItem('tokenCampingBooking');
    if (error_msg) {
      const onClose = () => {
        modalState.set({ ...modalState.get(), open: false });
        if (localStorage.getItem('bookingPageUrlForErrorCampingBooking')) {
          window.location.href =
            (localStorage.getItem('bookingPageUrlForErrorCampingBooking') as string) ??
            '/v2/camping';
          localStorage.removeItem('bookingPageUrlForErrorCampingBooking');
        }
      };
      modalState.set({
        title: typeof error_msg == 'string' ? error_msg : 'error',
        subtitle: '',
        open: true,
        onClose,
        onClick: async () => {
          onClose();
        },
      });
    }

    // if (error_msg === '[결제포기] 사용자가 결제를 취소하셨습니다') {
    //   if (localStorage.getItem('bookingPageUrlForErrorCampingBooking')) {
    //     window.location.href = localStorage.getItem(
    //       'bookingPageUrlForErrorCampingBooking'
    //     ) as string;
    //   }
    // }
  }, [error_msg]);

  const [reservationData, setReservationData] = useState<any>(null);
  const [getPaymentStatus, setGetPaymentStatus] = useState<any>(false);
  const [initialGetPaymentStatus, setInitialGetPaymentStatus] = useState<any>(false);

  const repeatedGetPaymentDataFunc = async (initial: boolean) => {
    if (
      imp_success === 'true' &&
      (merchant_uid as string)?.trim()?.length > 0 &&
      (imp_uid as string)?.trim()?.length > 0
    ) {
      campingBookingPaymentTokenState.set(localStorage.getItem('tokenCampingBooking') ?? null);
      let localStorageReservationInfoCampingBooking = localStorage.getItem(
        'reservationInfoCampingBooking'
      );
      let reservationInfoCampingBooking;
      if (localStorageReservationInfoCampingBooking) {
        let parsed = JSON.parse(localStorageReservationInfoCampingBooking);
        reservationInfoCampingBooking = parsed ?? null;
      }
      setGetPaymentStatus(true);
      loadingDots(true, 'loading-container');

      initial && setInitialGetPaymentStatus(true);

      checkPaymentV2({
        merchantUid: merchant_uid,
        impUid: imp_uid,
        impSuccess: true,
        // campingPaymentToken: localStorage.getItem('tokenCampingBooking'),
        ondaData: reservationInfoCampingBooking,
      })
        .then((res) => {
          // res undefined 401
          // res {success: true, data: {…}}
          // message "가상계좌 발급 성공"

          if (res?.success == true && res?.data) {
            if (res?.data?.message === '재고 부족') {
              // console.log('res?.data?.message', res?.data?.message);
            } else {
              setReservationData(res.data);
            }
          } else if (res?.success == false && res?.error) {
            // console.log('false', res);
          } else if (res == undefined) {
            console.log('e: ', 'undefined');
          }

          loadingDots(false, 'loading-container');
          setGetPaymentStatus(false);

          initial && setInitialGetPaymentStatus(false);
        })
        .catch((err) => {
          console.log('err', err);
          loadingDots(false, 'loading-container');
          setGetPaymentStatus(false);

          initial && setInitialGetPaymentStatus(false);
        });
    }
  };

  useEffect(() => {
    repeatedGetPaymentDataFunc(true);
  }, [imp_success]);

  const getPaymentData = async () => {
    repeatedGetPaymentDataFunc(false);
  };

  const loadingDots = (statusBool: boolean, className: string) => {
    let loadingContEle = document.querySelector('.' + className);
    if (loadingContEle) {
      if (statusBool) {
        (loadingContEle as any).style.display = 'block';
      } else {
        (loadingContEle as any).style.display = 'none';
      }
    }
  };

  const convertDate = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const [campingRefundPolicyBeforeReservation, setCampingRefundPolicyBeforeReservation] =
    useState<any>(null);

  useEffect(() => {
    if (reservationData) {
      getCampingRefundPolicyBeforeReservationV2(
        reservationData?.ondaData?.property_id,
        reservationData?.ondaData?.rateplans[0]?.roomtype_id,
        reservationData?.ondaData?.rateplans[0]?.rateplan_id,
        `?checkin=${dayjs(reservationData?.ondaData?.checkin).format(
          'YYYY-MM-DD'
        )}&checkout=${dayjs(reservationData?.ondaData?.checkout).format('YYYY-MM-DD')}`
      ).then((res) => {
        if (res?.success && res?.data) {
          if (res?.data?.refundPolicyBeforeReservation) {
            setCampingRefundPolicyBeforeReservation(res?.data?.refundPolicyBeforeReservation);
          }
        }
      });
    }
  }, [reservationData]);

  const modifyCampingRefundPolicyBeforeReservation = () => {
    let refundTypeObj: { [key: string]: any } = {
      nights: '연박 예약 (전체 예약 요금에 대해 체크인 날짜를 기준으로 취소환불정책을 일괄 적용)',
    };

    let refundPolicyObj;
    if (campingRefundPolicyBeforeReservation) {
      refundPolicyObj = campingRefundPolicyBeforeReservation;
    }
    // #E9ECEF
    if (refundPolicyObj) {
      let refundPolicy = '';
      if (refundPolicyObj?.refund_policy) {
        refundPolicy =
          refundPolicy +
          `<div style="display: grid; grid-template-columns: auto auto; padding-bottom: 3px">
           <div style="border-top: 1px solid #E9ECEF;"></div><div style="border-top: 1px solid #E9ECEF; font-weight: 600; text-align: center; padding-top: 8px; padding-bottom: 8px">환불요금</div>
           <div style="grid-column: span 2; border-bottom: 1px solid #E9ECEF; margin-bottom: 3px"></div>
          `;

        refundPolicyObj?.refund_policy.forEach((item: any) => {
          refundPolicy =
            refundPolicy +
            `
            <div style="border-bottom: 1px solid #E9ECEF; margin-left: 3px; padding-top: 8px; padding-bottom: 8px; text-align: center;">${
              item?.until ? new Date(item.until)?.toLocaleString() : ''
            }</div>
            <div style="border-bottom: 1px solid #E9ECEF; padding-top: 8px; padding-bottom: 8px; text-align: center;">
              ${item?.refund_amount?.toLocaleString()} (${item?.percent}%)
            </div>
            `;
        });

        refundPolicy = refundPolicy + '</div>';
      }
      return `
      <div style="margin-top: 75px">
        <div style="margin-bottom: 5px">
          <div style="font-weight: 600; font-size: 18px">취환불 적용 타입:</div>
          <div>${refundTypeObj[refundPolicyObj?.refund_type ?? ''] ?? ''}</div>
        </div>
        <div style="margin-bottom: 5px">
          <div style="font-weight: 600; font-size: 18px; margin-bottom: 5px">확정 취환불정책:</div>
          ${refundPolicy}
        </div>
      </div>
      `;
    } else return '';
  };

  return (
    <>
      <TopTitleBar logo={true} home={true} backButton={false} />
      <div className="max-w-md w-screen side-padding bg-[#FFFFFF]">
        {imp_success === 'true' ? (
          <>
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <div className="flex flex-col justify-center items-center">
                <div className="text-[#FF6400] text-[18px] font-[700]">
                  예약 신청이 완료되었습니다.
                </div>
                <div className="text-[12px]">무통장 입금은 입금완료시 예약 신청이 완료됩니다.</div>
                <div className="text-[12px] text-center">
                  비회원 고객분들은 예약 조회를 위해 예약번호와 비밀번호가 필요하므로 기억해두시길
                  바랍니다.
                </div>
              </div>
            </Section>
          </>
        ) : imp_success === 'false' ? (
          <>
            <Section className="max-w-md py-3 bg-[#FFFFFF]">
              <div className="flex flex-col justify-center items-center">
                <div className="text-[#FF6400] text-[18px] font-[700]">
                  {error_msg ?? '결제에러'}
                </div>
                {/* <div className="text-[12px]">{error_msg}</div> */}
              </div>
            </Section>
          </>
        ) : null}
        <div className="flex justify-center">
          {imp_success === 'true' && !initialGetPaymentStatus && !reservationData ? (
            <Button
              disabled={getPaymentStatus}
              className="h-10 text-sm text-[black] outlined-black mr-[5px]"
              onClick={() => getPaymentData()}
            >
              {getPaymentStatus ? '예약정보 가져오는중...' : '예약정보 가져오기'}
            </Button>
          ) : (
            <></>
          )}
          {imp_success === 'true' && !initialGetPaymentStatus && (
            <>
              <Button
                className="h-10 text-sm text-[black] outlined-black"
                onClick={() => {
                  window.location.href = '/v2/camping/reservation/check-w-on';
                }}
              >
                예약 조회
              </Button>
            </>
          )}
        </div>
        <>
          {imp_success === 'true' && reservationData ? (
            <>
              <div className="bg-gray-100 w-full border my-3" />
              <Section className="max-w-md py-3 bg-[#FFFFFF]">
                <div className="font-[700] text-[15px] mb-3">예약 정보</div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">이름</div>
                  <div className="col-span-4">{reservationData?.ondaData?.booker?.name}</div>
                </div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">연락처</div>
                  <div className="col-span-4">{reservationData?.ondaData?.booker?.phone}</div>
                </div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">예약 상태</div>
                  <div className="col-span-4 font-[700] text-[#FF6400]">
                    {reservationData?.ondaData?.status
                      ? bookingStatusObj[reservationData?.ondaData?.status]
                      : null}
                  </div>
                </div>
              </Section>
              <div className="bg-gray-100 w-full border my-3" />
              <Section className="max-w-md py-3 bg-[#FFFFFF]">
                <div className="font-[700] text-[15px] mb-3">예약 상품 정보</div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">예약번호</div>
                  <CopyToClipboard
                    text={reservationData?.ondaData?.channel_booking_number as string}
                    onCopy={() => {
                      toast.success('예약번호가 복사되었습니다');
                    }}
                  >
                    <div className="col-span-4 flex items-center">
                      <p className="mr-2">
                        {reservationData?.ondaData?.channel_booking_number ?? ''}
                      </p>
                      {reservationData?.ondaData?.channel_booking_number ? (
                        <>
                          <div className="w-[15px] h-[15px] mr-2">
                            <CopySVG className="w-full h-full" fill="#0ddb44" />
                          </div>
                          <p className="text-[#0ddb44]">복사</p>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </CopyToClipboard>
                </div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">숙소</div>
                  <div className="col-span-4">{reservationData?.ondaData?.property_name}</div>
                </div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">객실</div>
                  <div className="col-span-4">
                    {reservationData?.ondaData?.rateplans[0]?.roomtype_name}
                  </div>
                </div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">패키지</div>
                  <div className="col-span-4">
                    {reservationData?.ondaData?.rateplans[0]?.rateplan_name}
                  </div>
                </div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">일정</div>
                  <div className="col-span-4">
                    {reservationData?.ondaData?.checkin && reservationData?.ondaData?.checkout
                      ? dayjs(reservationData?.ondaData?.checkin).format('YYYY-MM-DD') +
                        ' ~ ' +
                        dayjs(reservationData?.ondaData?.checkout).format('YYYY-MM-DD')
                      : null}
                  </div>
                </div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">예약인원</div>
                  <div className="col-span-4">
                    <div>
                      {reservationData?.ondaData?.rateplans[0]?.number_of_guest?.adult ?? 0}명
                    </div>
                    {/* <div>
                      미성년: {reservationData?.ondaData?.rateplans[0]?.number_of_guest?.child ?? 0}
                      명
                    </div> */}
                    {/* <div>청소년: {reservationData?.bookingInfo?.people_info?.minor ?? 0}명</div>
                    <div>아동: {reservationData?.bookingInfo?.people_info?.child ?? 0}명</div> */}
                  </div>
                </div>
                {/* <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">예약차량</div>
                  <div className="col-span-4">
                    {reservationData?.bookingInfo?.car_info?.length ?? 0}대
                  </div>
                </div> */}
              </Section>
              <div className="bg-gray-100 w-full border my-3" />
              <Section className="max-w-md py-3 bg-[#FFFFFF]">
                <div className="font-[700] text-[15px] mb-3">결제 정보</div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">총 결제금액</div>
                  <div className="col-span-4 text-[#FF6400] font-[700]">
                    {reservationData?.ondaData?.paymentInfo?.amount?.toLocaleString()}원
                  </div>
                </div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">결제수단</div>
                  <div className="col-span-4">
                    {reservationData?.ondaData?.paymentInfo?.payMethod
                      ? paymentMethodObj[reservationData?.ondaData?.paymentInfo?.payMethod]
                      : null}
                  </div>
                </div>
                {PaymentMethod?.VBANK === reservationData?.ondaData?.paymentInfo?.payMethod ? (
                  <>
                    <div className="grid grid-cols-5">
                      <div className="text-[#5C5C5C] text-[13px] place-self-start">은행명</div>
                      <div className="col-span-4">
                        {reservationData?.ondaData?.paymentInfo?.vbankName}
                      </div>
                    </div>
                    <div className="grid grid-cols-5">
                      <div className="text-[#5C5C5C] text-[13px] place-self-start">
                        입금 계좌번호
                      </div>
                      <div className="col-span-4">
                        {reservationData?.ondaData?.paymentInfo?.vbankNum}
                      </div>
                    </div>
                    <div className="grid grid-cols-5">
                      <div className="text-[#5C5C5C] text-[13px] place-self-start">입금 마감일</div>
                      <div className="col-span-4">
                        {reservationData?.ondaData?.paymentInfo?.vbankDate
                          ? new Date(
                              reservationData?.ondaData?.paymentInfo?.vbankDate * 1000
                            )?.toLocaleString()
                          : null}
                      </div>
                    </div>
                  </>
                ) : PaymentMethod?.CARD === reservationData?.ondaData?.paymentInfo?.payMethod ? (
                  <></>
                ) : null}

                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">현재 상태</div>
                  <div className="col-span-4 font-[700] text-[#FF6400]">
                    {reservationData?.ondaData?.paymentInfo?.status
                      ? paymentStatusObj[reservationData?.ondaData?.paymentInfo?.status]
                      : null}
                  </div>
                </div>
              </Section>
              <div className="bg-gray-100 w-full border my-3" />
              <Section className="mb-4 max-w-md">
                <div className={`bg-white z-20 w-full max-w-md flex space-x-2 px-4 h-16`}>
                  <button
                    onClick={() => openDrawer()}
                    className="my-2 rounded bg-[#FFFFFF] text-[#000000] font-bold 
                    border border-[#D0D0D0]
                    flex-1 flex justify-center items-center"
                  >
                    예약 환불 규정
                  </button>
                </div>
              </Section>
            </>
          ) : imp_success === 'true' && !reservationData ? (
            <>
              <div className="bg-gray-100 w-full border my-3" />
              <Section className="max-w-md py-3 bg-[#FFFFFF]">
                <div className="font-[700] text-[15px] mb-3">예약 정보</div>
                <div className="grid grid-cols-5">
                  <div className="text-[#5C5C5C] text-[13px] place-self-start">예약번호</div>
                  <CopyToClipboard
                    text={reservationData?.ondaData?.channel_booking_number as string}
                    onCopy={() => {
                      toast.success('예약번호가 복사되었습니다');
                    }}
                  >
                    <div className="col-span-3 flex items-center">
                      <p className="mr-2">
                        {reservationData?.ondaData?.channel_booking_number ?? ''}
                      </p>
                      {reservationData?.ondaData?.channel_booking_number ? (
                        <>
                          <div className="w-[15px] h-[15px] mr-2">
                            <CopySVG className="w-full h-full" fill="#0ddb44" />
                          </div>
                          <p className="text-[#0ddb44]">복사</p>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </CopyToClipboard>
                </div>
              </Section>
            </>
          ) : imp_success === 'false' ? (
            <></>
          ) : null}
          {imp_success === 'true' && getPaymentStatus && (
            <div className="flex justify-center">
              <div className="loading-container text-[50px] ">
                <span className="loading-dot">.</span>
                <span className="loading-dot">.</span>
                <span className="loading-dot">.</span>
              </div>
            </div>
          )}
        </>
      </div>

      <div>
        <CustomDrawer drawerRef={drawerRef} confirmButton={false}>
          <div className="mt-[75px] side-padding">
            {campingRefundPolicyBeforeReservation &&
              htmlReactParser(modifyCampingRefundPolicyBeforeReservation() ?? '')}
          </div>
        </CustomDrawer>
      </div>
    </>
  );
};
