import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useInfiniteQuery, useQuery } from 'react-query';
import { map, trim, uniq } from 'lodash';
import { parse, stringify } from 'qs';
import { addDays, previousMonday } from 'date-fns';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { AxiosError, AxiosResponse } from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { v4 as uuidv4 } from 'uuid';

import { TopTitleBar } from './components/TopTitleBar';
import { Section } from '../../../../components/Section';
import { Icon } from '../../../../components/Icon';
import { CardType1 } from './components/CardType1';
import { CustomDrawer } from './components/CustomDrawer';
import { Button } from '../../../../components/Button';
import PostAdd from './PostAdd';
import PostFilter from './PostFilter';

import { ReactComponent as IconAngleDoubleTop } from '../../../../assets/icons/icon-angle-double-top.svg';
import { ReactComponent as IconFilter } from '../../../../assets/icons/icon-filter.svg';

import { api } from '../../../../plugins/axios';

import { authUserTokenObjState, userObjState } from '../../../../ridge/ridge';
import BannerType1 from './components/BannerType1';
import { getCommunityBanners } from '../../../../api_v2/non_admin/community';
import { getTDCAccommCampaigns } from '../../../../api_v2/non_admin/community/tdc-accommodations';
// import { getBanners } from '../../../../api_v2/non_admin/community';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faImage } from '@fortawesome/free-solid-svg-icons';
import { faImage } from '@fortawesome/free-regular-svg-icons';

import './components/style.css';
import RecommendVKTourDestsV2 from './RecommendVKTourDestsV2';
import RecommendVKTourDest from './RecommendVKTourDest';

enum DrawerType {
  ADD_POST = 'add_post',
  FIND_NEARBY_VK_TOUR_PLACES = 'find_nearby_vk_tour_places',
  FIND_NEARBY_VK_GC_PLACES = 'find_nearby_vk_gc_places',
  FIND_NEARBY_PLACES = 'find_nearby_places',
  FILTER = 'filter',
  SEARCH_HISTORY_COMMUNITY_POSTS_RECOMMEND_VK_TOUR_DESTS = 'search_history_community_posts_recommend_vk_tour_dests',
}

const postSortNameObj: { [key: string]: any } = {
  recent: '최신순',
  oldest: '오래된순',
  mostCommented: '댓글순',
};

enum PostSortType {
  RECENT = 'recent',
  OLDEST = 'oldest',
  MOST_COMMENTED = 'mostCommented',
}

export const Posts = () => {
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const authUserTokenObj = authUserTokenObjState.useValue();

  const history = useHistory();
  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [postSearchInput, setPostSearchInput] = useState(parsedSearch?.search_query);

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const observer = useRef<IntersectionObserver>();

  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [chosenCategories, setChosenCategories] = useState<any>(parsedSearch['category']);
  const [drawerType, setDrawerType] = useState<any>(null);

  const [sortedBy, setSortedBy] = useState(parsedSearch['sort'] ?? PostSortType.RECENT);

  const handlePostSearchInput = (e: any) => {
    setPostSearchInput(e.target.value);
  };

  const convertDateForQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  let [pageLimit, setPageLimit] = useState(5);

  const fetchCommunityPosts = async ({ pageParam = 1 }) => {
    let currentQueryStringObj: { [key: string]: any } = {
      page: pageParam,
      limit: pageLimit,
      filter: { status: true, isVisible: true },
      sort: {},
    };

    for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
      let key = Object.keys(parsedSearch)[i];
      if (key === 'search_query') {
        currentQueryStringObj.filter['searchQuery'] = parsedSearch[key];
      } else if (key === 'category') {
        currentQueryStringObj.filter['category'] = parsedSearch[key];
      } else if (key === 'sort') {
        if (sortedBy == PostSortType?.RECENT) {
          currentQueryStringObj.sort['createdAt'] = 'DESC';
        } else if (sortedBy == PostSortType?.OLDEST) {
          currentQueryStringObj.sort['createdAt'] = 'ASC';
        } else if (sortedBy == PostSortType?.MOST_COMMENTED) {
          currentQueryStringObj.sort['commentsCount'] = 'DESC';
        }
      }
    }

    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      currentQueryStringObj.filter['checkin'] = convertDateForQS(parsedSearch?.start_date);
      currentQueryStringObj.filter['checkout'] = convertDateForQS(parsedSearch?.end_date);
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
      arrayFormat: 'brackets',
    });

    if (pageParam == 1) {
      setInitialLoading(true);
      loadingDots(true, 'initial-loading-container');
    } else {
      loadingDots(true, 'more-loading-container');
    }

    let result = await api.get(`/community/posts${currentQueryString}`);

    if (pageParam == 1) {
      setInitialLoading(false);
      loadingDots(false, 'initial-loading-container');
    } else {
      loadingDots(false, 'more-loading-container');
    }

    if ([200].includes(result?.status)) {
      return result;
    } else return null;
  };

  const {
    data: searchResultData,
    fetchNextPage,
    isLoading: isSearchResultDataLoading,
    refetch: refetchPosts,
  } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`communityPostsSearchResultPage`],
    fetchCommunityPosts as any,
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages?.length + 1;
        if (lastPage?.data?.items?.length > 0) {
          return nextPage;
        } else if (lastPage?.data?.items?.length == 0) {
          return undefined;
        } else return allPages?.length;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const lastElement = useCallback(
    (target) => {
      if (isSearchResultDataLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && fetchNextPage) {
            fetchNextPage();
          } else {
          }
        },
        // {
        //   rootMargin: '500px',
        //   threshold: 0.1,
        // }
        {
          threshold: 1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [isSearchResultDataLoading]
  );

  const handlePostinfoSwiperSlide = (postInfoObj: any) => {
    try {
      history.push(`/community/posts/${postInfoObj.id}`);
    } catch (e) {}
  };

  const openDrawer = (drawerTypeData: any, heightData?: any) => {
    setDrawerType(drawerTypeData);
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `none`;

      (drawerRef.current as any).style['pointer-events'] = 'auto';

      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = heightData ?? '100%';
    }
  };

  const [handleSearchQS, setHandleSearchQS] = useState<any | null>('');

  const [lastRequestTimeSHCP, setLastRequestTimeSHCP] = useState(0);
  const [requestCountSHCP, setRequestCountSHCP] = useState(0);

  const debounceDelaySHCP = 2500;

  const processSearchHistoryCommunityPost = (queryStrObj: any) => {
    try {
      let uid = uuidv4();
      const currentTime = Date.now();
      let trackSearchHistoryCommunityPost: { [key: string]: any } = {
        currentTime,
        lastRequestTimeSHCP,
        comp: currentTime - lastRequestTimeSHCP > debounceDelaySHCP,
      };
      // modifySHCP(queryStrObj, uid);
      if (requestCountSHCP == 0) {
        trackSearchHistoryCommunityPost.requestCount = requestCountSHCP;
        // localStorage.setItem(
        //   'api_request_data_SHCP_' + uid,
        //   JSON.stringify(trackSearchHistoryCommunityPost)
        // );

        modifySHCP(queryStrObj, uid);

        setLastRequestTimeSHCP(currentTime);
        setRequestCountSHCP((prev: any) => {
          return prev + 1;
        });
      } else {
        if (currentTime - lastRequestTimeSHCP > debounceDelaySHCP) {
          trackSearchHistoryCommunityPost.requestCount = requestCountSHCP;
          modifySHCP(queryStrObj, uid);
          setLastRequestTimeSHCP(currentTime);
          setRequestCountSHCP((prev: any) => {
            return prev + 1;
          });
        }
      }
    } catch (e) {}
  };

  const modifySHCP = async (queryStrObj: any, uid?: any) => {
    try {
      await api.patch('/community/search-history/post', {
        content: queryStrObj?.search_query,
      });
    } catch (error) {
    } finally {
    }
  };

  const handleSearch = async (queryStrObj: { [key: string]: any }) => {
    try {
      if (queryStrObj?.search_query && authUserTokenObj?.token) {
        processSearchHistoryCommunityPost(queryStrObj);
      } else {
      }

      const queryString = stringify(queryStrObj, {
        addQueryPrefix: true,
        encode: false,
        arrayFormat: 'brackets',
      });
      if (queryString == handleSearchQS) {
      } else {
        // setInitialLoading(true);
        // setPage(1);
        // setCampingInfoList({});
      }
      setHandleSearchQS(queryString);
      // history.push('/v2/camping/search/result' + queryString);
      window.location.href = '/community/posts' + queryString;
    } catch (e) {}
  };

  // prob2
  const handlePostSearchButton = (evt: any) => {
    const tempQueryObj: { [key: string]: any } = {};

    if ((postSearchInput as string)?.trim()?.length > 0) {
      tempQueryObj['search_query'] = (postSearchInput as string)?.trim() ?? '';
    }

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'province') {
          tempQueryObj['province'] = parsedSearch[key];
        } else if (key === 'city') {
          tempQueryObj['city'] = parsedSearch[key];
        } else if (key === 'sort') {
          tempQueryObj.sort = parsedSearch[key];
        } else if (key === 'category') {
          tempQueryObj.category = parsedSearch[key];
        }
      }
    }

    if (chosenDates?.startDate && chosenDates?.endDate) {
      tempQueryObj.start_date = chosenDates?.startDate;
      tempQueryObj.end_date = chosenDates?.endDate;
    }

    handleSearch(tempQueryObj);
  };

  // prob3
  const handleSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      const tempQueryObj: { [key: string]: any } = {};
      if ((postSearchInput as string)?.trim()?.length > 0) {
        tempQueryObj['search_query'] = (postSearchInput as string)?.trim() ?? '';
      }

      if (parsedSearch) {
        for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
          let key = Object.keys(parsedSearch)[i];
          if (key === 'province') {
            tempQueryObj['province'] = parsedSearch[key];
          } else if (key === 'city') {
            tempQueryObj['city'] = parsedSearch[key];
          } else if (key === 'sort') {
            tempQueryObj.sort = parsedSearch[key];
          } else if (key === 'category') {
            tempQueryObj.category = parsedSearch[key];
          }
        }
      }

      if (chosenDates?.startDate && chosenDates?.endDate) {
        tempQueryObj.start_date = chosenDates?.startDate;
        tempQueryObj.end_date = chosenDates?.endDate;
      }

      handleSearch(tempQueryObj);
    }
  };

  const handleFilterSearch = (dataObj: any) => {
    const tempQueryObj: { [key: string]: any } = {};
    if ((postSearchInput as string)?.trim()?.length > 0) {
      tempQueryObj['search_query'] = (postSearchInput as string)?.trim() ?? '';
    }

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'province') {
          tempQueryObj['province'] = parsedSearch[key];
        } else if (key === 'city') {
          tempQueryObj['city'] = parsedSearch[key];
        }
      }
    }

    if (dataObj?.categories?.length > 0) {
      tempQueryObj['category'] = dataObj?.categories;
    }

    if (dataObj?.sort?.length > 0) {
      tempQueryObj['sort'] = dataObj?.sort;
    }

    if (chosenDates?.startDate && chosenDates?.endDate) {
      tempQueryObj.start_date = chosenDates?.startDate;
      tempQueryObj.end_date = chosenDates?.endDate;
    }

    handleSearch(tempQueryObj);
  };

  const closeDrawer = () => {
    if (drawerRef.current) {
      let bodyEle = document.querySelector('body') as any;
      bodyEle.style['pointer-events'] = `auto`;

      drawerRef.current.style.width = '0';
      drawerRef.current.style.height = '0';
    }
  };

  const filterWrapRef = useRef<any | null>(null);
  const filterRef = useRef<any | null>(null);

  useEffect(() => {
    try {
      const handleScrollEvent = () => {
        try {
          if (filterRef?.current?.getBoundingClientRect()?.top <= 0) {
            filterRef.current.style.position = 'fixed';
            filterRef.current.style.top = 0;
          } else {
            // filterWrapRef.current.style.position = '';
            // filterWrapRef.current.style.top = '';
          }
          if (document.documentElement.scrollTop == 0) {
            filterRef.current.style.position = '';
            filterRef.current.style.top = '';
          }
        } catch (e) {}
      };
      window.addEventListener('scroll', handleScrollEvent);
      return () => window.removeEventListener('scroll', handleScrollEvent);
    } catch (e) {}
  }, []);

  const loadingDots = (statusBool: boolean, className: string) => {
    let loadingContEle = document.querySelector('.' + className);
    if (loadingContEle) {
      if (statusBool) {
        (loadingContEle as any).style.display = 'flex';
      } else {
        (loadingContEle as any).style.display = 'none';
      }
    }
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [accomBannerList, setAccomBannerList] = useState<any>([]);

  useEffect(() => {
    getTDCAccommCampaigns('?isVisible=true').then((res: any) => {
      if (res?.success == true) {
        if (res?.data[0]?.length > 0) {
          let modifiedBannerList: any = [];
          for (let i = 0; i < res?.data[0]?.length; i++) {
            let joinList = res?.data[0][i]?.accommCampaignToAccomms;
            let eventUniqueId = res?.data[0][i]?.uniqueId;
            let eventIsVisible = res?.data[0][i]?.isVisible;
            if (eventIsVisible) {
              if (joinList?.length > 0) {
                if (res?.data[0][i]?.endDate) {
                  const targetDate = dayjs(res?.data[0][i]?.endDate);

                  const remainingTime = targetDate.diff(dayjs(), 's');

                  if (remainingTime > 0) {
                    let tempList = [];
                    for (let i = 0; i < joinList?.length; i++) {
                      let accomm = joinList[i]?.accomm;
                      let tempObj: any = {
                        shortAddr: accomm?.shortAddr,
                        city: accomm?.city,
                        name: accomm?.name,
                        maxDiscountRate: accomm?.maxDiscountRate,
                        mainImageUrl: accomm?.mainImageUrl,
                        link: accomm?.link,
                      };
                      if (eventUniqueId) {
                        tempObj.eventUniqueId = eventUniqueId;
                      }
                      tempList.push(tempObj);
                    }
                    modifiedBannerList = [...modifiedBannerList, ...tempList];
                  }
                }
              }
            }
          }

          setAccomBannerList((prev: any) => {
            return [...prev, ...modifiedBannerList];
          });
        }
      }
    });
  }, []);

  const [isFDVisible, setIsFDVisible] = useState(true);

  useEffect(() => {
    // Toggle visibility every 1000ms (1 second)
    const intervalId = setInterval(() => {
      setIsFDVisible((prev) => !prev);
    }, 500);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const styleSheet = document.styleSheets[0];

    // Add keyframes
    styleSheet.insertRule(
      `
      @keyframes bounce {
        to {
          top: -20px;
          
        }
      }
    `,
      styleSheet.cssRules.length
    );

    // Add animation class
    styleSheet.insertRule(
      `
      .animate-bounce {
        position: relative;
        display: inline-block;
        animation: bounce 0.3s infinite alternate;
        font-size: 20px;
        
        
      }
    `,
      styleSheet.cssRules.length
    );
  }, []);

  useEffect(() => {
    const keyframes = `
      @keyframes flip {
        0% {
          transform: perspective(400px) rotateY(0);
        }
        50% {
          transform: perspective(400px) rotateY(180deg);
        }
        100% {
          transform: perspective(400px) rotateY(0);
        }
      }
    `;

    const styleSheet = document.styleSheets[0];
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

    return () => {
      styleSheet.deleteRule(styleSheet.cssRules.length - 1);
    };
  }, []);

  const userObj = userObjState.useValue();

  const [shcpRecommVkTourDests, setShcpRecommVkTourDests] = useState<any>(null);

  useEffect(() => {
    try {
      if (authUserTokenObj?.token && userObj?.userInfo?.user_id) {
        api
          .get(`/community/search-history/post/check/member?userId=${userObj?.userInfo?.user_id}`)
          .then((res) => {
            if (res?.data?.recommVkTourDests) {
              setShcpRecommVkTourDests(res.data?.recommVkTourDests ?? null);
            }
          })
          .catch((e: any) => {});
      }
    } catch (e) {}
  }, [authUserTokenObj?.token, userObj]);

  const [chosenRecommendVkTourDest, setChosenRecommendVkTourDest] = useState<any>(null);

  return (
    <>
      <TopTitleBar backButton={false} logo={true} home={false} />
      {/* <div className="max-w-md w-screen bg-white side-padding"> */}
      <div className="w-screen sm:w-full md:w-full lg:w-full side-padding bg-white">
        {/* <div ref={tabsRef} className={`fixed bg-[white] top-[132px] w-full max-w-md`}> */}
        <div ref={filterWrapRef} className="absolute right-0 w-full max-w-md bg-[white] z-[3]">
          <div
            ref={filterRef}
            className="flex flex-col justify-center w-full max-w-md side-padding bg-[white] pb-[7px]"
          >
            {parsedSearch && (
              <>
                <div className="flex gap-[5px] items-center">
                  <div
                    className="flex flex-row-reverse w-full items-center px-4 h-12 mt-2
                border border-[#FF6400] rounded bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
                  >
                    <Button
                      className="bg-[#FF6400] text-[#FFFFFF] rounded-[12px]"
                      style={{ height: '80%' }}
                      onClick={(e) => handlePostSearchButton(e)}
                    >
                      검색
                    </Button>
                    {postSearchInput ? (
                      <>
                        <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                          <Icon.X
                            className="wh-3"
                            onClick={() => {
                              setPostSearchInput('');
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                    <input
                      className="flex-1 text-sm bg-white"
                      placeholder="검색하기"
                      onChange={(e) => handlePostSearchInput(e)}
                      value={postSearchInput as string}
                      onKeyDown={(e) => handleSearchKeyDown(e)}
                    />
                  </div>
                  <div
                    className="flex items-center px-3 mb-2 border rounded h-12"
                    onClick={() => {
                      closeDrawer();
                      openDrawer(DrawerType.FILTER);
                    }}
                  >
                    <div className="w-max">필터</div>
                    {/* <IconFilter width={'30px'} height={'30px'} className="float-right" /> */}
                  </div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <div className="flex items-center gap-[13px]">
                    <div
                      onClick={() => {
                        closeDrawer();
                        openDrawer(DrawerType?.FIND_NEARBY_PLACES, '50%');
                      }}
                      className="h-[30px] flex items-center border py-[2px] px-[6px] rounded-[4px] bg-[#fdffc1] cursor-pointer"
                    >
                      주변검색
                    </div>

                    <div
                      onClick={() => {
                        window.location.href = `/community/bookmark`;
                      }}
                      className="h-[30px] flex items-center cursor-pointer  
                      text-[black] font-bold py-[2px] 
                      px-[6px] 
                      rounded-[4px]  
                      border   
                      "
                      style={
                        {
                          // animation: 'flip 8s linear infinite',
                        }
                      }
                    >
                      관심 목록
                    </div>

                    <div
                      className=""
                      onClick={() => {
                        window.location.href = `/community/journey-log`;
                      }}
                    >
                      <h1 className="animate-bounce cursor-pointer">
                        <span className="text-[#ff65b6]">여</span>
                        <span className="text-[#ff65b6] mr-[2px]">행</span>
                        <span>기</span>
                        <span>록</span>
                        <div
                          className="absolute top-[2px] right-[-10px]"
                          style={{
                            height: '8px',
                            width: '8px',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            opacity: isFDVisible ? 1 : 0,
                            transition: 'opacity 0.3s ease-in-out',
                          }}
                        ></div>
                      </h1>
                    </div>

                    <div
                      onClick={() => {
                        window.location.href = `/community/ga/community-posts/popular`;
                      }}
                      className="h-[30px] flex items-center cursor-pointer text-[#ffbd03] font-bold py-[2px] px-[6px] rounded-[4px] border bg-[#002244]"
                    >
                      인기글
                    </div>

                    {/* <div
                      onClick={() => {
                        window.location.href = `/community/accomms-w-disc`;
                      }}
                      className="h-[30px] relative flex gap-[5px] items-center border py-[2px] pl-[6px] pr-[10px] rounded-[4px] bg-[#fdffc1] cursor-pointer"
                    >
                      <p>
                        <span className="font-[700] text-[16px] text-[#922bae] mr-[5px]">할인</span>
                        숙소
                      </p>
                      <div
                        className="absolute top-[2px] right-[2px]"
                        style={{
                          height: '8px',
                          width: '8px',
                          backgroundColor: 'red',
                          borderRadius: '50%',
                          opacity: isFDVisible ? 1 : 0,
                          transition: 'opacity 0.3s ease-in-out',
                        }}
                      ></div>
                    </div> */}
                  </div>
                  <div className="flex items-center gap-[15px]">
                    {/* {authUserTokenObj?.token && (
                      <div
                        className="px-[12px] py-[2px] cursor-pointer"
                        onClick={() => {
                          window.location.href = '/community/image-search/vg2ic/tb/vk-tour-dests';
                        }}
                      >
                        <FontAwesomeIcon icon={faImage} size={'lg'} />
                      </div>
                    )} */}
                    {/* <div
                      className="flex items-center"
                      onClick={() => {
                        closeDrawer();
                        openDrawer(DrawerType.FILTER);
                      }}
                    >
                      <div>필터</div>
                      <IconFilter width={'30px'} height={'30px'} className="float-right" />
                    </div> */}
                  </div>
                </div>
                <div className="">
                  {shcpRecommVkTourDests?.length > 0 && (
                    <RecommendVKTourDestsV2
                      recommVkTourDests={shcpRecommVkTourDests}
                      openDrawer={openDrawer}
                      setChosenRecommendVkTourDest={setChosenRecommendVkTourDest}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <Section
          style={{ marginTop: shcpRecommVkTourDests?.length > 0 ? '180px' : '150px' }}
          className="bg-[white]"
        >
          {initialLoading == true && (
            <div className="initial-loading-container flex justify-center mb-[20px] hidden">
              <div className="loading-dots-type1"></div>
            </div>
          )}

          <>
            {!((searchResultData as any)?.pages[0]?.data?.items?.length > 0) &&
            !isSearchResultDataLoading ? (
              <>
                <p className="grid place-content-center h-44 gap-[5px]">검색결과가 없습니다</p>
              </>
            ) : searchResultData?.pages && searchResultData?.pages?.length > 0 ? (
              <>
                {map(searchResultData?.pages ?? [], (page, pageInd) => {
                  return map(page?.data?.items ?? [], (postObj, postObjInd: number) => {
                    return (
                      <>
                        <div
                          key={postObj?.id}
                          ref={
                            searchResultData?.pages?.length - 1 == pageInd &&
                            page?.data?.items?.length - 1 == postObjInd
                              ? lastElement
                              : null
                          }
                        >
                          <CardType1
                            pageInd={pageInd}
                            item={postObj}
                            handlePostinfoSwiperSlide={handlePostinfoSwiperSlide}
                            refetchPosts={refetchPosts}
                            // communityBookmarkForPost={communityBookmarkForPost}
                            // getCommunityBookmarkForPostStatus={getCommunityBookmarkForPostStatus}
                            // bookmarkUsable={bookmarkUsable}
                          />
                        </div>
                      </>
                    );
                  });
                })}
                {/* <div ref={lastElement}></div> */}
              </>
            ) : null}
          </>

          <div className="more-loading-container flex justify-center mb-[20px] hidden">
            <div className="loading-dots-type1"></div>
          </div>
        </Section>
      </div>

      <div className="absolute bottom-[110px] right-[0px] w-[30px] h-[30px] z-[1000]">
        <div className="flex justify-end max-w-md">
          <div
            onClick={() => {
              if (authUserTokenObj) {
                closeDrawer();
                openDrawer(DrawerType.ADD_POST);
              } else {
                window.confirm('로그인후 등록해주세요.');
              }
            }}
            className="fixed bottom-[110px] w-[60px] h-[60px] mr-5 rounded-[60px] bg-[#06a54a] text-[white] font-[300] 
        flex justify-center items-center text-4xl cursor-pointer pb-0.5 pl-[1px]"
          >
            +
          </div>
        </div>
      </div>
      <div className="absolute bottom-10 right-0 w-[30px] h-[30px] z-[1000]">
        <div className="flex justify-end max-w-md">
          <div
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="fixed bottom-10 w-[60px] h-[60px] mr-5 rounded-[50px] bg-[#06A54A] text-[white] font-[300] text-[1.5em] 
        flex justify-center items-center cursor-pointer"
          >
            <IconAngleDoubleTop fill="#FFFFFF" className="w-[35px] h-[35px]" />
          </div>
        </div>
      </div>

      {accomBannerList?.length > 0 && (
        <>
          <div className="absolute bottom-0 w-full max-w-md h-[90px] z-[999]">
            <div className="max-w-md">
              <div
                onClick={() => {}}
                className="fixed bottom-0 w-full max-w-md h-[90px] bg-[white] text-[white] font-[300] text-[1.5em] 
          cursor-pointer"
              >
                <BannerType1 items={accomBannerList} />
              </div>
            </div>
          </div>
        </>
      )}

      <CustomDrawer drawerRef={drawerRef} contentMt={55}>
        <div className="mt-[50px] p-[15px]">
          {drawerType == DrawerType.ADD_POST ? (
            <PostAdd from={'Posts'} refetchPosts={refetchPosts} closeDrawer={closeDrawer} />
          ) : drawerType == DrawerType.FILTER ? (
            <PostFilter
              handleFilterSearch={handleFilterSearch}
              chosenCategories={chosenCategories}
              sortedBy={sortedBy}
              setSortedBy={setSortedBy}
            />
          ) : drawerType == DrawerType?.FIND_NEARBY_PLACES ? (
            <>
              <div className="flex justify-center items-center gap-[25px] mt-[10px]">
                {/* <div
                  className="cursor-pointer border rounded-[4px] bg-[#efe3f8] py-[7px] px-[10px]"
                  onClick={() => (window.location.href = `/community/vk-tour-dests/find-nearby`)}
                >
                  내주변
                </div> */}
                <div
                  className="cursor-pointer border rounded-[4px] bg-[#efe3f8] py-[7px] px-[10px]"
                  onClick={() =>
                    (window.location.href = `/community/vk-tour-dests/choose-sp-for-nearby`)
                  }
                >
                  관광지
                </div>
                <div
                  className="cursor-pointer border rounded-[4px] bg-[#efe3f8] py-[7px] px-[10px]"
                  onClick={() =>
                    (window.location.href = `/community/vk-gc-dests/choose-sp-for-nearby`)
                  }
                >
                  캠핑장
                </div>
                {/* <div
                  className="cursor-pointer border rounded-[4px] bg-[#efe3f8] py-[7px] px-[10px]"
                  onClick={() =>
                    (window.location.href = `/community/vk-leports/choose-sp-for-nearby?type=fs`)
                  }
                >
                  민물/바다 낚시터
                </div> */}
                <div
                  className="cursor-pointer border rounded-[4px] bg-[#efe3f8] py-[7px] px-[10px]"
                  onClick={() =>
                    (window.location.href = `/community/vk-leports/choose-sp-for-nearby`)
                  }
                >
                  레포츠
                </div>
              </div>
            </>
          ) : drawerType == DrawerType.SEARCH_HISTORY_COMMUNITY_POSTS_RECOMMEND_VK_TOUR_DESTS ? (
            <>
              <RecommendVKTourDest item={chosenRecommendVkTourDest} />
            </>
          ) : null}
        </div>
      </CustomDrawer>
    </>
  );
};
