import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCampingProperty, updateCampingProperty } from '../../../../../api_v2/admin/onda';
import { Button } from '../../../../../components/Button';
import { toast } from 'react-toastify';

export default function PropertyDetail() {
  const { id } = useParams<{ id: string }>();

  const [propertyData, setPropertyData] = useState<any>(null);

  useEffect(() => {
    getCampingProperty(id, '').then((res) => {
      if (res?.success && res?.data) {
        setPropertyData(res?.data);
      }
    });
  }, [id]);

  useEffect(() => {
    if (propertyData) {
      setIsVisible(propertyData?.is_visible);
    }
  }, [propertyData]);

  const [isVisible, setIsVisible] = useState(false);
  const [editStatus, setEditStatus] = useState(false);

  const handleVisibleChange = (e: any) => {
    setIsVisible(e?.target?.checked);
  };

  const handleEditButton = () => {
    let updateData = {
      is_visible: isVisible,
    };

    setEditStatus(true);
    updateCampingProperty(id, updateData)
      .then((res) => {
        if (res?.success == true && res?.data) {
          setPropertyData(res?.data);
          toast.success('성공적으로 수정하였습니다');
        }
        setEditStatus(false);
      })
      .catch((e) => {
        toast.error('error');
        setEditStatus(false);
      });
  };

  return (
    <div>
      {propertyData ? (
        <>
          <div className="side-padding">
            <div className="font-[700] text-[15px] mb-3">숙소 정보</div>
            <div className="grid grid-cols-5">
              <div className="text-[#5C5C5C] text-[13px] place-self-start">ID</div>
              <div className="col-span-4">{propertyData.id}</div>
            </div>
            <div className="grid grid-cols-5">
              <div className="text-[#5C5C5C] text-[13px] place-self-start">숙소명</div>
              <div className="col-span-4">{propertyData.name}</div>
            </div>
            <div className="grid grid-cols-5">
              <div className="text-[#5C5C5C] text-[13px] place-self-start">상태 (온다)</div>
              <div className="col-span-4">{propertyData.status}</div>
            </div>
            <div className="grid grid-cols-5">
              <div className="text-[#5C5C5C] text-[13px] place-self-start">노출 상태</div>
              <div className="col-span-4 font-[700] text-[#FF6400]">
                <input
                  type="checkbox"
                  name="isVisible"
                  checked={isVisible}
                  onChange={(e) => handleVisibleChange(e)}
                />
              </div>
            </div>

            <div className="bg-gray-100 w-full border my-3" />
          </div>
          <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
            <Button
              disabled={editStatus}
              text="취소"
              className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
              to="/admin/v2/camping/properties"
            />
            <Button
              disabled={editStatus}
              onClick={() => handleEditButton()}
              text={editStatus ? '...' : '수정'}
              className="h-10 text-sm filled-black"
            />
          </div>
        </>
      ) : null}
    </div>
  );
}
