export const LOCAL_STORAGE_KEY = {
  RECENT_KEYWORDS: 'recentKeywords',
  BUY_NOW: '__buynowItems__',
  CART_ITEM: '__cartItems__',
  NON_MEMBER_BUY_NOW: 'non_member_buy_now',
  NON_MEMBER_ADDRESS: 'non_member_address',
  NON_MEMBER_TOKEN: 'non_member_token',
  KAKAO_ACCESS_TOKEN: 'kakao_access_token',
  TOKEN: 'token',
  ADMIN_TOKEN: 'admin_token',
  DEVICE_INFO_TOKEN: 'device_info_token',
  FCM_TOKEN: 'fcm_token',
};

function IsJsonString(str: any) {
  try {
    var json = JSON.parse(str);
    return typeof json === 'object';
  } catch (e) {
    return false;
  }
}

export const getItemInLocalStorage = (key: string) => {
  const item = localStorage?.getItem(key);
  if (item && IsJsonString(item)) {
    return JSON.parse(item);
  } else {
    return null;
  }
};

export const setItemInLocalStorage = (key: string, value: any) => {
  localStorage?.setItem(key, JSON.stringify(value));
};

export const removeItemInLocalStorage = (key: string) => {
  localStorage?.removeItem(key);
};
