import { useEffect, useState, useRef, useCallback } from 'react';
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import { parse, stringify } from 'qs';

import { Section } from '../../../../../components/Section';
import { TopTitleBar } from './components/TopTitleBar';
import { campingDummyData, campingImagesDummyData } from './dummayData';

import { toast } from 'react-toastify';
import { authUserTokenObjState, meState } from '../../../../../ridge/ridge';
import { getBookings } from '../../../../../api_v2/non_admin/booking';
import { getBookingsV2 } from '../../../../../api_v2/non_admin/onda';

SwiperCore.use([Pagination, Autoplay]);

const bookingStatusObj: { [key: string]: any } = {
  waiting: '결제 대기중',
  ready: '입금 대기중',
  pending: '예약 요청중(입금완료)',
  confirmed: '확정',
  cancelled: '취소',
  canceled: '취소', // data from onda db
  failed: '실패',
};

export const ReservationsPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const { campingId, zoneId } = useParams<{
    campingId: string;
    zoneId: string;
  }>();
  const [campingSite, setCampingSite] = useState<any>(null);
  const [campingSiteZone, setCampingSiteZone] = useState<any>(null);

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    let foundCampingObj = campingDummyData.find((campingObj: any) => {
      if (campingId == campingObj?.id) {
        return true;
      } else return false;
    });
    if (foundCampingObj && foundCampingObj?.zones) {
      setCampingSite(foundCampingObj);
      let foundZone = foundCampingObj?.zones.find((zoneInfo: any) => {
        if (zoneInfo?.id == zoneId) {
          return true;
        } else return false;
      });
      if (foundZone) {
        setCampingSiteZone(foundZone);
      }
    }
  }, []);

  const resHistoryDummy = [
    {
      orderNumber: 'test',
      campingName: 'test',
      zoneName: 'test',
      time: 'test',
      totalPrice: '1000000',
    },
    {
      orderNumber: 'test',
      campingName: 'test',
      zoneName: 'test',
      time: 'test',
      totalPrice: '1000000',
    },
    {
      orderNumber: 'test',
      campingName: 'test',
      zoneName: 'test',
      time: 'test',
      totalPrice: '1000000',
    },
  ];

  const handleResDetail = (resData: any) => {
    history.push(`/v2/camping/reservation/history/${resData?.id}`);
  };

  const me = meState.useValue();
  const authUserTokenObj = authUserTokenObjState.useValue();

  const [bookings, setBookings] = useState<any[]>([]);

  useEffect(() => {
    if (authUserTokenObj && me) {
      getBookingsV2('').then((res) => {
        if (res?.success == true && res?.data) {
          setBookings(res?.data ?? []);
        }
      });
    }
  }, [authUserTokenObj, me]);

  return (
    <>
      <TopTitleBar logo={true} home={false} />
      <>
        {
          <>
            <div className="max-w-md w-screen side-padding bg-[#FFFFFF]">
              <Section className="max-w-md py-3 bg-[#FFFFFF]">
                <div className="font-[700] text-[15px]">예약 조회</div>
              </Section>
              <Section className="max-w-md py-3 bg-[#FFFFFF]">
                {bookings?.length > 0 &&
                  bookings?.map((reservation) => (
                    <>
                      {reservation?.status != 'waiting' && (
                        <div className="mb-4">
                          <div className="grid grid-cols-5 border-y py-2 mb-2">
                            <div className="font-[700] text-[13px] place-self-start">예약번호</div>
                            <div className="col-span-3">{reservation?.booking_no}</div>
                            <div
                              className="place-self-end text-[#5C5C5C] cursor-pointer"
                              onClick={() => handleResDetail(reservation)}
                            >
                              자세히
                            </div>
                          </div>
                          <div className="grid grid-cols-5">
                            <div className="text-[#5C5C5C] text-[13px] place-self-start">
                              예약 상태
                            </div>
                            <div className="col-span-4 font-[700] text-[16px] text-[#FF6400]">
                              {reservation?.status && bookingStatusObj[reservation?.status]}
                            </div>
                          </div>
                          <div className="grid grid-cols-5">
                            <div className="text-[#5C5C5C] text-[13px] place-self-start">숙소</div>
                            <div className="col-span-4">{reservation?.property_name}</div>
                          </div>
                          <div className="grid grid-cols-5">
                            <div className="text-[#5C5C5C] text-[13px] place-self-start">객실</div>
                            <div className="col-span-4">{reservation?.roomtype_name}</div>
                          </div>
                          <div className="grid grid-cols-5">
                            <div className="text-[#5C5C5C] text-[13px] place-self-start">
                              패키지
                            </div>
                            <div className="col-span-4">{reservation?.rateplan_name}</div>
                          </div>
                          <div className="grid grid-cols-5">
                            <div className="text-[#5C5C5C] text-[13px] place-self-start">일정</div>
                            <div className="col-span-4">
                              {reservation?.checkin &&
                                reservation?.checkout &&
                                reservation?.checkin + ' ~ ' + reservation?.checkout}
                            </div>
                          </div>
                          <div className="grid grid-cols-5 mt-3">
                            <div className="text-[#5C5C5C] text-[13px] place-self-start">
                              총 결제금액
                            </div>
                            <div className="col-span-4 font-[700] text-[16px] text-[#FF6400]">
                              {parseInt(reservation?.amount)?.toLocaleString() + '원'}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
              </Section>
              <Link
                className="flex justify-center items-center text-[#5986f7]"
                to="/v2/camping/reservation/check-w-on"
              >
                비회원 조회
              </Link>
            </div>
          </>
        }
      </>
    </>
  );
};
