import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { parse, stringify } from 'qs';

import _ from 'lodash';
import { sortBy } from 'lodash';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay, Scrollbar, Navigation } from 'swiper';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { Select as AntdSelect } from 'antd';
import 'antd/dist/antd.css';

import { v4 as uuidv4 } from 'uuid';

import { regionsForSearch } from './constants';
import { Button } from '../../../../../components/Button';
import { Section } from '../../../../../components/Section';
import { CustomDrawer } from './CustomDrawer';
import { ReactComponent as IconArrowDown } from '../../../../../assets/icons/icon-arrow-down.svg';
import { ReactComponent as IconCalendar } from '../../../../../assets/icons/icon-schedule.svg';

import { campingTypesObj } from '../booking/constants';

import { getBanners } from '../../../../../api_v2/non_admin/banners';

import './style.css';
import { Calendar } from './Calendar';

import { useInfiniteQuery } from 'react-query';
import { getConveyanceInfoList } from '../../../../../api_v2/non_admin/conveyance';
import { Icon } from '../../../../../components/Icon';
import { BottomNavBar } from '../components/BottomNavBar';

SwiperCore.use([Pagination, Autoplay, Navigation, Scrollbar]);

const { Option: AntdOption } = AntdSelect;

const regionsForConveyanceMainPage = [
  '',
  '수도권',
  '충청도',
  '강원도',
  '전라북도',
  '전라남도',
  '경상북도',
  '부산/경남',
  '제주',
];

const conveyancePriceTypeObjFromApi: { [key: string]: any } = {
  regular: '정가',
  discount: '할인',
  premium: '프리미엄',
};

const RepeatedCard = (
  dataObj: any,
  lastElementRef: any,
  handleConveyanceDetail: any,
  getDay: any,
  calculateDDay: any
) => {
  return (
    <div
      onClick={() => handleConveyanceDetail(dataObj?.id)}
      ref={lastElementRef ?? null}
      className={`grid grid-cols-1 border-2 rounded-[12px]
      mb-3 ${
        dataObj?.gocamping?.firstImageUrl ? 'py-5 px-3' : 'py-5 px-3'
      } bg-[white] cursor-pointer`}
      // style={{ borderRadius: '5px 5px 5px 5px' }}
      /* top-left | top-right | bottom-right | bottom-left */
    >
      <div className="flex items-center">
        {dataObj?.gocamping?.firstImageUrl ? (
          <div className="mr-2">
            <img
              className="w-[100px] h-[60px] rounded-[8px]"
              src={dataObj?.gocamping?.firstImageUrl}
            />
          </div>
        ) : null}

        <div className="w-full">
          <div className="font-[600] text-[#6F6F6F] text-[0.8rem] flex">
            <div className="mr-2">{dataObj?.gocamping?.doName}</div>
            <div>{dataObj?.gocamping?.sigunguName}</div>
          </div>
          <div className="flex justify-between">
            <div className="font-[700] text-[1rem]">
              {dataObj?.gocamping?.facilityName?.length >= 15
                ? dataObj?.gocamping?.facilityName?.substring(0, 15) + '...'
                : dataObj?.gocamping?.facilityName}
            </div>
            {dataObj?.checkinDate ? (
              <div className={`flex justify-between items-center`}>
                <div className="font-[700] text-[0.8rem] text-[#FF6400] mr-[2px]">
                  {dataObj?.checkinDate ? getDay(dataObj?.checkinDate) : null}
                </div>
                <div className="text-[#ebedeb] text-[1rem] mr-[2px]">
                  {dataObj?.status == true
                    ? '|'
                    : calculateDDay(dataObj?.checkinDate)?.status == true
                    ? '|'
                    : null}
                </div>
                <div className="font-[500] text-[13px] text-[#FF6400]">
                  {dataObj?.status == true
                    ? '양도완료'
                    : calculateDDay(dataObj?.checkinDate)?.status == true
                    ? 'D-' + calculateDDay(dataObj?.checkinDate)?.days
                    : null}
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex justify-between">
            <div className="text-[#6F6F6F] text-[12px]">
              {dataObj?.checkinDate && dataObj?.checkoutDate
                ? dataObj?.checkinDate + ' ~ ' + dataObj?.checkoutDate
                : null}
            </div>
          </div>
          <div className="text-[12px] flex items-center">
            <div className="mr-2">
              {dataObj?.transferAmount ? dataObj?.transferAmount?.toLocaleString() + '원' : null}
            </div>

            {dataObj?.amountTag && (
              <div className="bg-[#06a54a] text-white px-2 py-1 rounded-[5px]">
                {conveyancePriceTypeObjFromApi[dataObj?.amountTag]}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ConveyanceMainPage = (props: any) => {
  let swiperRef = useRef<any>(null);
  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const history = useHistory();
  const [conveyanceSearchInput, setConveyanceSearchInput] = useState<any | null>(
    parsedSearch['search_query'] ?? ''
  );

  const drawerRef = useRef<HTMLDivElement | null>(null);

  const [selectedDropdownRegionOption, setSelectedDropdownRegionOption] = useState<string>(
    parsedSearch?.region as string
  );

  const [calendarState, setCalendarState] = useState<any>([
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: new Date(parsedSearch?.start_date as string),
          endDate: new Date(parsedSearch?.end_date as string),
          key: 'selection',
        }
      : {
          startDate: null,
          endDate: new Date(''),
          key: 'selection',
        },
  ]);

  const [locale, setLocale] = useState<any>('ko');

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const handleConveyanceSearchInput = (e: any) => {
    setConveyanceSearchInput(e.target.value);
  };

  const [handleSearchQS, setHandleSearchQS] = useState<any | null>('?chosen_tab=conveyance');

  const handleSearch = (queryStrObj: { [key: string]: any }) => {
    const queryString = stringify(queryStrObj, { addQueryPrefix: true, encode: false });

    if (queryString == handleSearchQS) {
    } else {
      setPage(1);
      setConveyanceInfoList({});
    }
    setHandleSearchQS(queryString);

    window.location.href = '/v2/camping' + queryString;
  };

  const [mainTopCampingBanners, setMainTopCampingBanners] = useState([]);

  // useEffect(() => {
  //   let tempObj = {
  //     where: { type: 'mainTopCamping' },
  //   };
  //   getBanners(stringify(tempObj, { addQueryPrefix: true, encode: false })).then((res) => {
  //     if (res?.success && res?.data) {
  //       setMainTopCampingBanners(res.data);
  //     }
  //   });
  // }, []);

  const handleConveyanceSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      let tempQueryStringObj: { [key: string]: any } = {
        chosen_tab: 'conveyance',
      };

      if ((conveyanceSearchInput as string)?.trim()?.length > 0) {
        tempQueryStringObj['search_query'] = (conveyanceSearchInput as string)?.trim();
      }

      if (parsedSearch) {
        for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
          let key = Object.keys(parsedSearch)[i];
          if (key === 'region') {
            tempQueryStringObj['region'] = parsedSearch[key];
          }
        }
      }

      if (parsedSearch?.start_date && parsedSearch?.end_date) {
        tempQueryStringObj['start_date'] = parsedSearch?.start_date;
        tempQueryStringObj['end_date'] = parsedSearch?.end_date;
      }

      handleSearch(tempQueryStringObj);
    }
  };

  const handleConveyanceSearchButton = (evt: any) => {
    let tempQueryStringObj: { [key: string]: any } = {
      chosen_tab: 'conveyance',
    };

    if ((conveyanceSearchInput as string)?.trim()?.length > 0) {
      tempQueryStringObj['search_query'] = (conveyanceSearchInput as string)?.trim();
    }

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'region') {
          tempQueryStringObj['region'] = parsedSearch[key];
        }
      }
    }

    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      tempQueryStringObj['start_date'] = parsedSearch?.start_date;
      tempQueryStringObj['end_date'] = parsedSearch?.end_date;
    }

    handleSearch(tempQueryStringObj);
  };

  enum TabType {
    CALENDAR = 'CALENDAR',
    REGION = 'REGION',
  }

  const TabTypeObj: { [key: string]: any } = {
    CALENDAR: '일정',
    REGION: '지역',
  };

  const [chosenTab, setChosenTab] = useState<any | null>(null);
  // const [chosenTabContent, setChosenTabContent] = useState<any | null>(null);
  const [chosenRegion, setChosenRegion] = useState<any | null>(parsedSearch['region'] ?? null);

  const tabs = [TabType?.CALENDAR, TabType?.REGION];

  const [queryString, setQueryString] = useState('');
  const [queryStringObj, setQueryStringObj] = useState<{ [key: string]: any } | null>(null);
  const [conveyanceInfoListTotal, setConveyanceInfoListTotal] = useState(0);

  const observer = useRef<IntersectionObserver>();

  const handleChosenTab = (tabData: any) => {
    setChosenTab(tabData);
    // setSelectedDropdownRegionOption(tabData);
    openDrawer();
  };

  const handleDropdown = (e: any, data: any) => {};

  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '100%';
    }
  };

  const closeDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '0';
      drawerRef.current.style.height = '0';
    }
  };

  const handleRegion = (regionData: any) => {
    setChosenRegion(regionData);
    closeDrawer();
    let tempQueryStringObj: { [key: string]: any } = {
      chosen_tab: 'conveyance',
    };

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'search_query') {
          tempQueryStringObj['search_query'] = parsedSearch[key];
        }
      }
    }

    if (regionData) {
      tempQueryStringObj['region'] = regionData;
    }

    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      tempQueryStringObj['start_date'] = parsedSearch?.start_date;
      tempQueryStringObj['end_date'] = parsedSearch?.end_date;
    }

    handleSearch(tempQueryStringObj);
  };

  const handleAddConveyanceInfoButton = () => {
    history.push('/v2/camping/conveyance/addconveyanceinfo');
  };

  const handleConveyanceDetail = (conveyanceDetailData: any) => {
    history.push(`/v2/camping/conveyance/conveyanceinfo/${conveyanceDetailData}`);
  };

  const calculateDDay = (endDate: any) => {
    let today = new Date();
    let yr = new Date(today).getFullYear();
    let mon = new Date(today).getMonth() + 1;
    let dt = new Date(today).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    let todayDate = yr + '-' + modifiedMon + '-' + modifiedDt;
    let days = [];

    if (new Date(todayDate) <= new Date(endDate)) {
      let date = new Date(todayDate);
      while (date <= new Date(endDate)) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return {
        status: true,
        days: typeof days?.length == 'number' && days?.length - 1 >= 0 ? days?.length - 1 : 0,
      };
    } else return { status: false };
  };

  const getDaysInMonth = (month: any, year: any) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      let dateHolder = new Date(date);
      let yr = dateHolder.getFullYear();
      let mon = dateHolder.getMonth() + 1;
      let dt = dateHolder.getDate();
      let modifiedMon = mon < 10 ? '0' + mon : mon;
      let modifiedDt = dt < 10 ? '0' + dt : dt;

      days.push(yr + '-' + modifiedMon + '-' + modifiedDt);
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  let getDay = (dateData: any) => {
    const daysArr = ['일', '월', '화', '수', '목', '금', '토'];
    let getDayRes = new Date(dateData)?.getDay();
    if (typeof getDayRes == 'number') {
      return daysArr[getDayRes];
    } else return null;
  };

  // const {
  //   data: returnedConveyanceInfoList,
  //   isLoading,
  //   isFetching,
  //   fetchNextPage,
  //   hasNextPage,
  //   error,
  // } = useInfiniteQuery<any, any>(['CONVEYANCE_MAIN_PAGE', queryStringObj], getConveyanceInfoList, {
  //   getNextPageParam: (lastPage, pages) => {
  //     // if (pages.length < Math.ceil(reviewsCount / PAGE_LIMIT))

  //     if (pages.length < Math.ceil(conveyanceInfoListTotal / 10)) return pages.length + 1;
  //     return false;
  //   },
  //   keepPreviousData: true,
  //   enabled: queryString?.trim()?.length > 0 ? true : false,
  // });

  // useEffect(() => {
  //   if (returnedConveyanceInfoList && returnedConveyanceInfoList?.pages[0]?.total >= 0) {
  //     setConveyanceInfoListTotal(returnedConveyanceInfoList.pages[0].total);
  //   }
  // }, [returnedConveyanceInfoList]);

  const convertDateForQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const [conveyanceInfoList, setConveyanceInfoList] = useState<{ [key: string]: any } | null>({});
  const [page, setPage] = useState(1);
  const [conveyanceInfoListLoading, setConveyanceInfoListLoading] = useState(false);

  useEffect(() => {
    if (search) {
      let currentQueryStringObj: { [key: string]: any } = {
        page: page,
        limit: 10,
        filter: {},
        sort: { created_at: 'DESC' },
      };

      if (parsedSearch) {
        for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
          let key = Object.keys(parsedSearch)[i];
          if (key === 'region') {
          } else if (key === 'search_query') {
            currentQueryStringObj.filter['word'] = parsedSearch[key];
          }
        }
      }

      if (parsedSearch?.start_date && parsedSearch?.end_date) {
        currentQueryStringObj.filter['checkinDate'] = convertDateForQS(parsedSearch?.start_date);
        currentQueryStringObj.filter['checkoutDate'] = convertDateForQS(parsedSearch?.end_date);
      }

      let currentQueryString = stringify(currentQueryStringObj, {
        addQueryPrefix: true,
        encode: false,
      });

      const regionStrGenerator = (arr: any[]) => {
        let finalStr = '';
        for (let i = 0; i < arr.length; i++) {
          if (i == 0) {
            finalStr = `filter[doName]=${arr[i]}`;
          } else if (i >= 1) {
            finalStr = finalStr + `&filter[doName]=${arr[i]}`;
          }
        }
        return finalStr;
      };

      if (parsedSearch['region']) {
        let regionStr = '';
        if (parsedSearch['region'] == '수도권') {
          regionStr = regionStrGenerator(['서울시', '경기도', '인천시']);
        } else if (parsedSearch['region'] == '충청도') {
          regionStr = regionStrGenerator(['충청남도', '충청북도', '세종시', '대전시']);
        } else if (parsedSearch['region'] == '부산/경남') {
          regionStr = regionStrGenerator(['부산시', '경상남도', '울산시']);
        } else if (parsedSearch['region'] == '경상북도') {
          regionStr = regionStrGenerator(['경상북도', '대구시']);
        } else if (parsedSearch['region'] == '전라남도') {
          regionStr = regionStrGenerator(['전라남도', '광주시']);
        } else if (parsedSearch['region'] == '제주') {
          regionStr = regionStrGenerator(['제주도']);
        } else {
          regionStr = regionStrGenerator([parsedSearch['region']]);
        }

        if (currentQueryString) {
          currentQueryString = currentQueryString + '&' + regionStr;
        } else {
          currentQueryString = '?' + regionStr;
        }
      }

      setConveyanceInfoListLoading(true);

      getConveyanceInfoList(currentQueryString).then((res) => {
        setConveyanceInfoList((prev) => {
          return { ...prev, [page]: res?.items };
        });
        setConveyanceInfoListTotal(res?.total);
        setPage(page + 1);
        setConveyanceInfoListLoading(false);
      });

      setQueryString(currentQueryString);
      setQueryStringObj(currentQueryStringObj);
    }
  }, [search]);

  const calculateCurrListLen = (objData: any) => {
    let finalCount = 0;
    for (let i = 0; i < Object.keys(objData).length; i++) {
      let key = Object.keys(objData)[i];
      finalCount = finalCount + (objData[key]?.length ?? 0);
    }
    return finalCount;
  };

  const lastElement = useCallback(
    (target) => {
      if (conveyanceInfoListLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (
            entries[0].isIntersecting &&
            conveyanceInfoList &&
            calculateCurrListLen(conveyanceInfoList) < conveyanceInfoListTotal &&
            !conveyanceInfoListLoading
          ) {
            // fetchNextPage();
            let currentQueryStringObj: { [key: string]: any } = {
              page: page,
              limit: 10,
              filter: {},
              sort: { created_at: 'DESC' },
            };

            if (parsedSearch) {
              for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
                let key = Object.keys(parsedSearch)[i];
                if (key === 'region') {
                  if (parsedSearch[key] == '수도권') {
                    currentQueryStringObj.filter['doName'] = ['서울시', '경기도', '인천시'];
                  } else if (parsedSearch[key] == '충청도') {
                    currentQueryStringObj.filter['doName'] = [
                      '충청남도',
                      '충청북도',
                      '세종시',
                      '대전시',
                    ];
                  } else if (parsedSearch[key] == '부산/경남') {
                    currentQueryStringObj.filter['doName'] = ['부산시', '경상남도', '울산시'];
                  } else if (parsedSearch[key] == '경상북도') {
                    currentQueryStringObj.filter['doName'] = ['경상북도', '대구시'];
                  } else if (parsedSearch[key] == '전라남도') {
                    currentQueryStringObj.filter['doName'] = ['전라남도', '광주시'];
                  } else if (parsedSearch[key] == '제주') {
                    currentQueryStringObj.filter['doName'] = ['제주도'];
                  } else {
                    currentQueryStringObj.filter['doName'] = parsedSearch[key];
                  }
                } else if (key === 'search_query') {
                  currentQueryStringObj.filter['word'] = parsedSearch[key];
                }
              }
            }

            if (parsedSearch?.start_date && parsedSearch?.end_date) {
              currentQueryStringObj.filter['checkinDate'] = convertDateForQS(
                parsedSearch?.start_date
              );
              currentQueryStringObj.filter['checkoutDate'] = convertDateForQS(
                parsedSearch?.end_date
              );
            }

            let currentQueryString = stringify(currentQueryStringObj, {
              addQueryPrefix: true,
              encode: false,
            });

            const regionStrGenerator = (arr: any[]) => {
              let finalStr = '';
              for (let i = 0; i < arr.length; i++) {
                if (i == 0) {
                  finalStr = `filter[doName]=${arr[i]}`;
                } else if (i >= 1) {
                  finalStr = finalStr + `&filter[doName]=${arr[i]}`;
                }
              }
              return finalStr;
            };

            if (parsedSearch['region']) {
              let regionStr = '';
              if (parsedSearch['region'] == '수도권') {
                regionStr = regionStrGenerator(['서울시', '경기도', '인천시']);
              } else if (parsedSearch['region'] == '충청도') {
                regionStr = regionStrGenerator(['충청남도', '충청북도', '세종시', '대전시']);
              } else if (parsedSearch['region'] == '부산/경남') {
                regionStr = regionStrGenerator(['부산시', '경상남도', '울산시']);
              } else if (parsedSearch['region'] == '경상북도') {
                regionStr = regionStrGenerator(['경상북도', '대구시']);
              } else if (parsedSearch['region'] == '전라남도') {
                regionStr = regionStrGenerator(['전라남도', '광주시']);
              } else if (parsedSearch['region'] == '제주') {
                regionStr = regionStrGenerator(['제주도']);
              } else {
                regionStr = regionStrGenerator([parsedSearch['region']]);
              }

              if (currentQueryString) {
                currentQueryString = currentQueryString + '&' + regionStr;
              } else {
                currentQueryString = '?' + regionStr;
              }
            }

            setConveyanceInfoListLoading(true);

            getConveyanceInfoList(currentQueryString).then((res) => {
              setConveyanceInfoList((prev) => {
                return { ...prev, [page]: res?.items };
              });
              setConveyanceInfoListTotal(res?.total);
              setPage(page + 1);
              setConveyanceInfoListLoading(false);
            });
          } else {
          }
        },
        {
          threshold: 1,
        }
      );
      if (target) observer.current.observe(target);
    },
    [conveyanceInfoListLoading, conveyanceInfoList, conveyanceInfoListTotal]
  );

  const handleDateFunc = (startDateData: any, endDateData: any) => {
    let tempQueryStringObj: { [key: string]: any } = {
      chosen_tab: 'conveyance',
    };

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'region') {
          tempQueryStringObj['region'] = parsedSearch[key];
        } else if (key === 'search_query') {
          tempQueryStringObj['search_query'] = parsedSearch[key];
        }
      }
    }

    if (startDateData && endDateData) {
      tempQueryStringObj['start_date'] = startDateData;
      tempQueryStringObj['end_date'] = endDateData;
    }

    handleSearch(tempQueryStringObj);
  };

  const handleRefreshButton = () => {
    closeDrawer();
    let tempQueryStringObj: { [key: string]: any } = {
      chosen_tab: 'conveyance',
    };

    if (parsedSearch) {
      for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
        let key = Object.keys(parsedSearch)[i];
        if (key === 'region') {
          tempQueryStringObj['region'] = parsedSearch[key];
        } else if (key === 'search_query') {
          tempQueryStringObj['search_query'] = parsedSearch[key];
        }
      }
    }

    // if (startDateData && endDateData) {
    //   tempQueryStringObj['start_date'] = startDateData;
    //   tempQueryStringObj['end_date'] = endDateData;
    // }

    setCalendarState([
      {
        startDate: null,
        endDate: new Date(''),
        key: 'selection',
      },
    ]);

    setChosenDates({ startDate: null, endDate: null });

    handleSearch(tempQueryStringObj);
  };

  const tabsRef = useRef<any | null>(null);

  const [tabsTop, setTabsTop] = useState('70px');
  const [contentMarginTop, setContentMarginTop] = useState('250px');

  const [size, setSize] = useState<any[]>([]);

  useEffect(() => {
    if (props?.mainPageTabsRef?.current) {
      let mainPageTabsRefTop = props?.mainPageTabsRef?.current?.getBoundingClientRect()?.top ?? 0;
      let mainPageTabsRefHeight =
        props?.mainPageTabsRef?.current?.getBoundingClientRect()?.height ?? 0;

      if (mainPageTabsRefTop == 0 || mainPageTabsRefHeight == 0) {
      } else {
        let extra = 0;
        setTabsTop(mainPageTabsRefTop + mainPageTabsRefHeight + extra + 'px');
      }
    }
    if (tabsRef?.current) {
      let tabsRefTop = tabsRef?.current?.getBoundingClientRect()?.top ?? 0;
      let tabsRefHeight = tabsRef?.current?.getBoundingClientRect()?.height ?? 0;

      if (tabsRefTop == 0 || tabsRefHeight == 0) {
      } else {
        let extra = 5;
        setContentMarginTop(tabsRefTop + tabsRefHeight + extra + 'px');
      }
    }
  }, [props?.mainPageTabsRef?.current, tabsRef?.current, size]);

  const [randNumb, setRandNumb] = useState(uuidv4());

  return (
    <>
      <div className="">
        <>
          <div
            ref={tabsRef}
            className={`bg-[white] w-full max-w-md`}
            style={{ position: 'fixed', top: tabsTop }}
          >
            <div className="side-padding pt-[10px]">
              <div
                className="flex flex-row-reverse items-center 
        px-4 h-12 border border-[#FF6400] rounded bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
              >
                <div className="flex justify-center items-center">{/* <Icon.Search /> */}</div>

                <Button
                  className="bg-[#FF6400] text-[#FFFFFF] rounded-[12px]"
                  style={{ height: '80%' }}
                  onClick={(e) => handleConveyanceSearchButton(e)}
                >
                  검색
                </Button>
                {conveyanceSearchInput ? (
                  <>
                    <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                      <Icon.X
                        className="wh-3"
                        onClick={() => {
                          setConveyanceSearchInput('');
                        }}
                      />
                    </div>
                  </>
                ) : null}

                <input
                  className="flex-1 text-sm bg-white"
                  placeholder="캠핑장이름으로 검색하기"
                  onChange={(e) => handleConveyanceSearchInput(e)}
                  value={conveyanceSearchInput}
                  onKeyDown={(e) => handleConveyanceSearchKeyDown(e)}
                />
              </div>
            </div>
            <div className="w-full">
              <>
                <Swiper
                  className={`mySwiper w-full side-padding pt-4 z-[0]`}
                  slidesPerView="auto"
                  loop={false}
                  spaceBetween={5}
                  observer={true}
                  observeParents={true}
                  freeMode={true}
                  // slidesOffsetBefore={0}
                  // slidesOffsetAfter={0}
                  // onSwiper={(d) => {
                  //   console.log('onSwiper', d);
                  // }}
                  // onObserverUpdate={(d) => console.log('onObserverUpdate', d)}
                  // onUpdate={(d) => console.log('onUpdate', d)}
                  // onSlideChange={(d) => console.log('onSlideChange', d)}
                  // init={true}
                  // onResize={(d) => console.log('onResize', d)}
                  // onSlidesGridLengthChange={(d) => console.log('onSlidesGridLengthChange', d)}
                  // onSetTranslate={(d) => console.log('onSetTranslate', d)}
                  // onAfterInit={(d) => console.log('onAfterInit', d)}
                >
                  {tabs?.length &&
                    tabs.map((item) => {
                      return (
                        <SwiperSlide className="w-auto">
                          <Button
                            className="border-2 rounded-[12px] bg-[white]"
                            onClick={(e) => {
                              handleChosenTab(item);
                            }}
                            key={item}
                            // className={`${
                            //   item === chosenTab ? 'border-b-4 border-black text-black-50' : 'bg-white'
                            // } w-max font-semibold text-13 px-3 h-10`}
                          >
                            {item === TabType?.REGION ? (
                              chosenRegion ? (
                                <div className="flex items-center w-fit">
                                  <div className="mr-2">{chosenRegion}</div>
                                  <IconArrowDown className="w-[10px]" />
                                </div>
                              ) : (
                                <div className="flex items-center w-fit">
                                  <div className="mr-2">{TabTypeObj[item]}</div>
                                  <IconArrowDown className="w-[10px]" />
                                </div>
                              )
                            ) : item === TabType?.CALENDAR ? (
                              <div className="flex items-center w-fit">
                                <div className="mr-[3px]">
                                  {chosenDates?.startDate && chosenDates?.endDate ? (
                                    <>{chosenDates?.startDate + ' ~ ' + chosenDates?.endDate}</>
                                  ) : (
                                    TabTypeObj[item]
                                  )}
                                </div>
                                <IconCalendar className="w-[18px] h-[18px]" />
                              </div>
                            ) : (
                              TabTypeObj[item]
                            )}
                          </Button>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </>
            </div>
          </div>
        </>

        <div className={`side-padding pt-[5px]`} style={{ marginTop: contentMarginTop }}>
          {conveyanceInfoList && calculateCurrListLen(conveyanceInfoList) === 0 ? (
            <>
              <p className="grid place-content-center h-44">검색결과가 없습니다</p>
            </>
          ) : conveyanceInfoList && Object.keys(conveyanceInfoList as any)?.length > 0 ? (
            Object.keys(conveyanceInfoList as any).map(
              (page, groupInd) =>
                conveyanceInfoList[page] &&
                conveyanceInfoList[page].map((item: any, itemInd: number) => (
                  <>
                    {Object.keys(conveyanceInfoList as any)?.length == groupInd + 1 &&
                    conveyanceInfoList[page].length == itemInd + 1 ? (
                      <>
                        <div key={item?.id}>
                          {RepeatedCard(
                            item,
                            lastElement,
                            handleConveyanceDetail,
                            getDay,
                            calculateDDay
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div key={item?.id}>
                          {RepeatedCard(item, null, handleConveyanceDetail, getDay, calculateDDay)}
                        </div>
                      </>
                    )}
                  </>
                ))
            )
          ) : null}
        </div>
        <div className="relative w-50">
          <CustomDrawer
            drawerRef={drawerRef}
            refreshButton={chosenTab === TabType?.CALENDAR ? true : false}
            handleRefreshButton={handleRefreshButton}
            // confirmButton={chosenTab === TabType?.CALENDAR ? true : false}
          >
            <div className="h-full w-full">
              {chosenTab === TabType?.CALENDAR ? (
                <div className="mt-[55px]">
                  <Calendar
                    locale={locale}
                    state={calendarState}
                    setState={setCalendarState}
                    chosenDates={chosenDates}
                    setChosenDates={setChosenDates}
                    confirmButton={chosenTab === TabType?.CALENDAR ? true : false}
                    handleDate={true}
                    handleDateFunc={handleDateFunc}
                    drawerRef={drawerRef}
                  />
                </div>
              ) : chosenTab === TabType?.REGION ? (
                <div className="h-full w-full grid grid-cols-1 place-items-center bg-white mt-[55px]">
                  {regionsForConveyanceMainPage.map((region) => (
                    <div
                      className="w-full flex justify-center p-4 border-b-2 cursor-pointer"
                      onClick={() => {
                        handleRegion(region);
                      }}
                    >
                      {region?.length > 0 ? region : '전체'}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </CustomDrawer>
        </div>
        <div className="absolute bottom-10 right-0 w-[30px] h-[30px]">
          <div className="flex justify-end max-w-md">
            <div
              onClick={() => {
                handleAddConveyanceInfoButton();
              }}
              className="fixed bottom-40 w-[60px] h-[60px] mr-5 rounded-[60px] bg-[#06a54a] text-[white] font-[300] 
        flex justify-center items-center text-4xl cursor-pointer pb-0.5 pl-[1px]"
            >
              +
            </div>
          </div>
        </div>
        <BottomNavBar />
      </div>
    </>
  );
};
