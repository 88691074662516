import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { AdminH1 } from '../../components/AdminH1';
import { Button } from '../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { useExhibitions } from '../../../hooks/exhibition';
import { useQueryString } from '../../../hooks/hooks';
import { map } from 'lodash';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { getExhibitions } from '../../../api_v2/admin/exhibitions';
import { toast } from 'react-toastify';
import { WeightEditor } from './components/WeightEditor';

export const DisplayList = () => {
  const [queryString, setQueryString] = useState<string>(
    useQueryString({ sort: { weight: 'DESC' } })
  );
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [exhibitions, setExhibitions] = useState<any[]>([]);
  const [exhibitionsCount, setExhibitionsCount] = useState<number>(0);

  const refreshExhibitions = (qs: string) => {
    getExhibitions(qs).then((resultData) => {
      if (resultData?.success) {
        if (resultData.data) {
          setExhibitions(resultData.data.items);
          setExhibitionsCount(resultData.data.total);
        }
      } else {
        toast.error('Something went wrong getting exhibitions');
      }
    });
  };

  useEffect(() => {
    refreshExhibitions(queryString);
  }, [queryString]);

  if (!exhibitions) {
    return null;
  }

  const onEditWeight = () => {
    refreshExhibitions(queryString);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>기획전 관리</AdminH1>
        <Button text="기획전 추가" className="outlined-black text-sm" to="/admin/display/add" />
      </div>
      <div className="flex justify-end items-center">
        <div className="flex space-x-2 items-center">
          <div>전체기획전 수</div>
          <div className="text-green-500">{exhibitionsCount}</div>
        </div>
        <div>개</div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>생성일</Table.Th>
              <Table.Th>타입</Table.Th>
              <Table.Th>weight(순서)</Table.Th>
              <Table.Th>노출 여부</Table.Th>
              <Table.Th>제목</Table.Th>
              <Table.Th>부제목</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(exhibitions, (exhibition) => (
              <Table.Row key={exhibition.id}>
                <Table.Td>{exhibition.id}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    exhibition.createdAt.toLocaleString(),
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td>{exhibition.type}</Table.Td>
                <Table.Td>
                  <WeightEditor
                    weight={exhibition.weight}
                    exhibition={exhibition}
                    onEdit={onEditWeight}
                  />
                </Table.Td>
                <Table.Td>{exhibition.isVisible ? 'Y' : 'N'}</Table.Td>
                <Table.Td>{exhibition.title}</Table.Td>
                <Table.Td>{exhibition.subTitle}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-blue-500"
                    to={`/admin/display/${exhibition.id}`}
                  >
                    수정하기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          {/* <Pagination.Label
            count={exhibitions.items.length}
            total={exhibitions.total}
          /> */}
          <Pagination.Nav basePath="/admin/display" total={exhibitionsCount} />
        </Pagination>
      </Card>
    </>
  );
};
