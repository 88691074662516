import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as BookmarkIcon } from '../assets/icons/icon-bookmark.svg';
import { ReactComponent as StoreIcon } from '../assets/icons/icon-store.svg';
import { ReactComponent as MyIcon } from '../assets/icons/icon-my.svg';
import { ReactComponent as Home2Icon } from '../assets/icons/icon-home2.svg';
import { ReactComponent as Bookmark2Icon } from '../assets/icons/icon-bookmark2.svg';
import { ReactComponent as InquiryIcon } from '../assets/icons/icon-inquiry.svg';
import { ReactComponent as ArrowRight2Icon } from '../assets/icons/icon-arrow-right2.svg';
import { ReactComponent as ArrowLeft2Icon } from '../assets/icons/icon-arrow-left2.svg';
import { ReactComponent as ActiveScrapIcon2 } from '../assets/icons/scrap-active2.svg';
import { ReactComponent as ScrapIcon2 } from '../assets/icons/scrap2.svg';

import { Button } from './Button';
import { toast } from 'react-toastify';
import {
  addBookmarkedProduct,
  checkBookmarkedProduct,
  removeBookmarkedProduct,
} from '../api_v2/non_admin/bookmarks';

interface BottomNavBar2Props {
  productId: string;
}

export const BottomNavBar2: FC<BottomNavBar2Props> = ({ productId }) => {
  const { goBack, goForward } = useHistory();
  const [scrap, setScrap] = useState(false);
  const [bookmarkedProductStatus, setBookmarkedProductStatus] = useState(false);

  useEffect(() => {
    checkBookmarkedProduct(productId).then((resultData) => {
      if (resultData) {
        setScrap(true);
      } else {
        setScrap(false);
      }
    });
  }, []);

  const handleBookmarkedProduct = async () => {
    setBookmarkedProductStatus(true);
    if (scrap) {
      let removeBookmarkedProductResult = await removeBookmarkedProduct(productId);
      if (removeBookmarkedProductResult?.success) {
        toast.success('성공적으로 찜하기에서 삭제하였습니다.');
        setBookmarkedProductStatus(false);
        setScrap(false);
      } else {
        setBookmarkedProductStatus(false);
      }
    } else {
      let addBookmarkedProductResult = await addBookmarkedProduct(productId);
      if (addBookmarkedProductResult?.success) {
        toast.success('찜하기에서 확인해주세요.');
        setBookmarkedProductStatus(false);
        setScrap(true);
      } else {
        setBookmarkedProductStatus(false);
        toast.error(addBookmarkedProductResult?.data);
      }
    }
  };

  return (
    <>
      <div className="fixed bottom-0 w-full max-w-md bg-white flex flex-row justify-around items-center   z-10 border-t border-gray-150">
        <div>
          <div className={`w-full h-0.5 mx-auto`} />
          <div className="flex flex-row px-4 mt-4">
            <Button onClick={goBack}>
              <ArrowLeft2Icon />
            </Button>
            <Button onClick={goForward}>
              <ArrowRight2Icon />
            </Button>
          </div>
        </div>
        <div>
          <div className={`w-full h-0.5 mx-auto`} />
          <div className="px-4 mt-4">
            <button
              disabled={bookmarkedProductStatus}
              className="px-8"
              onClick={() => handleBookmarkedProduct()}
            >
              {scrap ? <ActiveScrapIcon2 /> : <ScrapIcon2 />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
