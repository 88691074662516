import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { AdminH1 } from '../../components/AdminH1';
import { Button } from '../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { useExhibitions } from '../../../hooks/exhibition';
import { useQueryString } from '../../../hooks/hooks';
import { map } from 'lodash';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { getExhibitions } from '../../../api_v2/admin/exhibitions';
import { toast } from 'react-toastify';
import { getCampingInfo, getCampingZone, getCampingZoneSites } from '../../../api_v2/admin/camping';
import { parse, stringify } from 'qs';

export const CampingZoneSiteList = () => {
  const [queryString, setQueryString] = useState<string>(
    useQueryString({ sort: { weight: 'DESC' } })
  );
  const { campingId, zoneId } = useParams<{ campingId: string; zoneId: string }>();
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [campingZoneSites, setCampingZoneSites] = useState<any[]>([]);
  const [campingZoneSitesTotal, setCampingZoneSitesTotal] = useState<number>(0);

  // const refreshExhibitions = (qs: string) => {
  //   getExhibitions(qs).then((resultData) => {
  //     if (resultData?.success) {
  //       if (resultData.data) {
  //         setExhibitions(resultData.data.items);
  //         setCampingSitesCount(resultData.data.total);
  //       }
  //     } else {
  //       toast.error('Something went wrong getting exhibitions');
  //     }
  //   });
  // };

  // useEffect(() => {
  //   refreshExhibitions(queryString);
  // }, [queryString]);

  // if (!exhibitions) {
  //   return null;
  // }

  // const onEditWeight = () => {
  //   refreshExhibitions(queryString);
  // };

  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    const searchObject = {
      page: '1',
      limit: '10',
      sort: { id: 'DESC' },
      ...parsed,
    };
    getCampingZoneSites(
      parseInt(campingId),
      parseInt(zoneId),
      stringify(searchObject, { addQueryPrefix: true, encode: false }) ?? ''
    ).then((res: any) => {
      if (res?.success && res?.data) {
        // setCampingZoneSitesTotal(res?.data?.total ?? 0);
        setCampingZoneSites(res?.data ?? []);
      } else {
      }
    });
  }, [search]);

  const [campingInfo, setCampingInfo] = useState<any | null>(null);
  const [campingZone, setCampingZone] = useState<any | null>(null);

  useEffect(() => {
    getCampingInfo(campingId, '').then((res) => {
      if (res?.success && res?.data) {
        setCampingInfo(res.data);
      }
    });
    getCampingZone(campingId, zoneId).then((res) => {
      if (res?.success && res?.data) {
        setCampingZone(res.data);
      }
    });
  }, []);

  // useEffect(() => {
  //   setCampingZoneSites((prev) => {
  //     return [...prev, { id: 1, name: 'test site 1', createdAt: new Date() }];
  //   });
  // }, []);

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>캠핑장 사이트 목록</AdminH1>
        <Button
          text="캠핑장 사이트 추가"
          className="outlined-black text-sm"
          to={`/admin/camping/${campingId}/zones/${zoneId}/sites/add`}
        />
      </div>
      <div className="flex justify-between items-center">
        <div>
          <p className="font-[700]">캠핑장명: {campingInfo?.name}</p>
          <p className="font-[700]">캠핑존명: {campingZone?.name}</p>
        </div>

        <div className="flex space-x-2 items-center">
          <div>전체 캠핑장 사이트 수</div>
          <div className="flex">
            <div className="text-green-500">{campingZoneSites?.length ?? 0}</div>
            <div>개</div>
          </div>
        </div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>생성일</Table.Th>
              <Table.Th>사이트 명</Table.Th>
              <Table.Th>사이트 정보</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(campingZoneSites, (campingZoneSite) => (
              <Table.Row key={campingZoneSite.id}>
                <Table.Td>{campingZoneSite.id}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    campingZoneSite.created_at.toLocaleString(),
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td>{campingZoneSite?.name}</Table.Td>
                <Table.Td className="">
                  <Link
                    className="font-normal text-blue-500"
                    to={`/admin/camping/${campingId}/zones/${zoneId}/sites/${campingZoneSite.id}`}
                  >
                    수정/삭제
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          {/* <Pagination.Label
            count={exhibitions.items.length}
            total={exhibitions.total}
          /> */}
          {/* <Pagination.Nav basePath="/admin/campsites" total={campingZoneSitesCount} /> */}
        </Pagination>
      </Card>
    </>
  );
};
