import {
  millisecondsToHours,
  millisecondsToMinutes,
  millisecondsToSeconds,
} from 'date-fns';
import { useEffect, useRef, useState } from 'react';

interface debounceProps<T> {
  value: T;
  delay: number;
}

function useDebounce<T>({ value, delay }: debounceProps<T>): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;

function _padZeroTime(time: number): string {
  return time < 10 ? `0${time}` : `${time}`;
}

export function useKoreanTime(millisecond: number) {
  const timeToHour = millisecondsToHours(millisecond);
  const day = Math.floor(timeToHour / 24);
  const hour = timeToHour % 24;
  const minute = millisecondsToMinutes(millisecond) - day * 24 * 60 - hour * 60;
  const second = millisecondsToSeconds(millisecond) % 60;
  return {
    day: _padZeroTime(day),
    hour: _padZeroTime(hour),
    minute: _padZeroTime(minute),
    second: _padZeroTime(second),
  };
}

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
