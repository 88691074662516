import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { default as htmlReactParser, attributesToProps } from 'html-react-parser';
import { parse, stringify } from 'qs';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { Section } from '../../../../../components/Section';
import { Button } from '../../../../../components/Button';

import { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { api } from '../../../../../plugins/axios';

export default function UserDetail() {
  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [pageLimit, setPageLimit] = useState(20);

  const fetchBookingsByUser = async ({ pageParam = 1 }) => {
    let currentQueryStringObj: { [key: string]: any } = {
      page: pageParam,
      limit: pageLimit,
      filter: {},
    };

    for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
      let key = Object.keys(parsedSearch)[i];
      if (key === 'email') {
        currentQueryStringObj['email'] = parsedSearch[key];
      } else if (key === 'phone') {
        currentQueryStringObj['phone'] = parsedSearch[key];
      } else if (key === 'user_id') {
        currentQueryStringObj['userId'] = parsedSearch[key];
      }
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    setBookingsByUserDataStatus(true);
    try {
      let result = await api.get(`/admin/onda-bookings/get-data/users/detail${currentQueryString}`);
      setBookingsByUserDataStatus(false);
      if ([200].includes(result?.status)) {
        return result;
      } else return null;
    } catch (e) {
      console.log('e', e);
      setBookingsByUserDataStatus(false);
      return null;
    }
  };

  const [bookingsByUserDataStatus, setBookingsByUserDataStatus] = useState(false);

  const { data: bookingsByUserData, fetchNextPage } = useInfiniteQuery<
    AxiosResponse | null,
    AxiosError
  >([`adminOndaCampingUserDetailPage`], fetchBookingsByUser, {
    getNextPageParam: (lastPage, allPages) => {
      const maxPage = Math.ceil((lastPage?.data?.data?.count ?? 0) / pageLimit);
      const nextPage = allPages?.length + 1;
      return nextPage <= maxPage ? nextPage : undefined;
    },
    keepPreviousData: true,
  });

  const handleMore = () => {
    fetchNextPage();
  };

  return (
    <div>
      <div className="flex justify-between mb-[5px]">
        <div>목록</div>
        <div>
          전체 수:
          {bookingsByUserDataStatus
            ? '...'
            : bookingsByUserData?.pages[bookingsByUserData?.pages?.length - 1]?.data?.data?.count ??
              0}
          개
        </div>
      </div>
      {bookingsByUserData?.pages && bookingsByUserData?.pages[0]?.data?.data?.items?.length > 0
        ? bookingsByUserData?.pages.map(
            (page, groupInd) =>
              page?.data?.data?.items &&
              page?.data?.data?.items.map((item: any, itemInd: number) => (
                <div className="border-[1px] p-[10px] mb-[5px]">
                  <div>[{groupInd * pageLimit + (itemInd + 1)}]</div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start"></div>
                    <div className="col-span-4">
                      {item?.created_at
                        ? dayjs(item?.created_at)?.format('YYYY-MM-DD hh:mm:ss')
                        : null}
                    </div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">예약 번호</div>
                    <div className="col-span-4">{item?.booking_no}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">
                      온다 예약 번호
                    </div>
                    <div className="col-span-4">{item?.onda_booking_number}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">일정</div>
                    <div className="col-span-4">{item?.checkin + ' ~ ' + item?.checkout}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">금액</div>
                    <div className="col-span-4">{item?.amount?.toLocaleString()}</div>
                  </div>
                  <div className="grid grid-cols-5">
                    <div className="text-[#5C5C5C] text-[13px] place-self-start">예약 상태</div>
                    <div className="col-span-4">{item?.status}</div>
                  </div>
                </div>
              ))
          )
        : ''}
      <Button
        className="h-10 w-max text-sm filled-black mt-[5px]"
        onClick={() => handleMore()}
        disabled={bookingsByUserDataStatus}
      >
        {bookingsByUserDataStatus ? <>...</> : <>더보기</>}
      </Button>
    </div>
  );
}
