// @ts-ignore
export const currFrontendUrl = window.location.href;
let url = new URL(currFrontendUrl);
let hostname = url.hostname;
export let apiForTempSol: any;
if (hostname.includes('localhost')) {
  apiForTempSol = process.env.REACT_APP_API_URL;
} else if (hostname.includes('46f9-211-49-134-219.ngrok-free.app')) {
  apiForTempSol = 'https://66d2-211-49-134-219.ngrok-free.app';
} else if (hostname.includes('stage.pridge.kr')) {
  apiForTempSol = 'https://stage-api.pridge.kr';
} else if (hostname.includes('pridge.kr')) {
  apiForTempSol = 'https://api.pridge.kr';
}
