import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { Button } from '../../../../../components/Button';
import { toast } from 'react-toastify';
import { createTDCAccommodations } from '../../../../../api_v2/admin/community/tdc-accommodations';

const TDCAccommodationAdd = () => {
  const [fileData, setFileData] = useState<any>(null);
  const [fileName, setFileName] = useState<any>(null);
  const [addStatus, setAddStatus] = useState<boolean>(false);

  const onDrop = (acceptedFiles: any) => {
    try {
      const file = acceptedFiles[0];
      setFileName(file?.name);
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        for (let i = 0; i < workbook.SheetNames?.length; i++) {
          let sn = workbook.SheetNames[i];

          const sheet = workbook.Sheets[sn];

          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          const modifiedData = jsonData.map((row: any) => [...row]);
        }

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const modifiedData = jsonData.map((row: any) => [...row]);

        setFileData(modifiedData);
      };

      reader.readAsArrayBuffer(file);
    } catch (e) {}
  };

  const [finalData, setFinalData] = useState<any>(null);

  useEffect(() => {
    try {
      if (!fileData || fileData.length === 0) {
      } else {
        setAddStatus(true);
        let modifiedData = fileData
          .slice(1)
          .filter((item: any) => item?.length > 0)
          .map((row: any, rowIndex: any) => {
            let rowOjb: any = {};

            row.forEach((cell: any, cellIndex: any) => {
              let chosenColList = [
                'adId',
                'siteId',
                'name',
                'link',
                'mainImageUrl',
                'maxDiscountRate',
                'addr',
                'city',
                'shortAddr',
              ];
              if (chosenColList?.includes(fileData[0][cellIndex])) {
                let modifiedCell = cell;
                if (cell) {
                  modifiedCell = cell?.toString()?.trim();
                }

                rowOjb[fileData[0][cellIndex]] = modifiedCell;
              }
            });

            return rowOjb;
          });

        setFinalData(modifiedData);
        setAddStatus(false);
      }
    } catch (e) {
      console.log('e', e);
      setAddStatus(false);
    }
  }, [fileData]);

  const generateHtmlString = () => {
    if (!fileData || fileData.length === 0) {
      return null;
    }

    const htmlString = fileData
      .slice(1)
      .map((row: any, rowIndex: any) => {
        const div = document.createElement('div');
        div.className = 'banner';

        row.forEach((cell: any, cellIndex: any) => {
          const p = document.createElement('p');

          // adId	siteId	name	link	mainImageUrl	updated	discount	maxDiscountRate	addr	province	city	gu	shortAddr

          let chosenColList = [
            'adId',
            'siteId',
            'name',
            'link',
            'mainImageUrl',
            'maxDiscountRate',
            'addr',
            'city',
            'shortAddr',
          ];
          if (chosenColList?.includes(fileData[0][cellIndex])) {
            p.className = fileData[0][cellIndex];
            p.textContent = cell;
            div.appendChild(p);
          }
        });

        return div.outerHTML;
      })
      .join('\n');

    return htmlString;
  };

  const generateHtml = () => {
    if (!fileData || fileData.length === 0) {
      return null;
    }

    return fileData.slice(1).map((row: any, rowIndex: any) => (
      <div key={rowIndex} className="banner">
        {row.map((cell: any, cellIndex: any) => (
          <p key={cellIndex} className={fileData[0][cellIndex]}>
            {cell}
          </p>
        ))}
      </div>
    ));
  };

  const copyToClipboard = () => {
    let htmlString = generateHtmlString();

    navigator.clipboard.writeText(htmlString).then(() => {
      alert('HTML copied to clipboard!');
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.xlsx, .xls' as any,
  });

  const handleAddButton = async () => {
    try {
      if (finalData) {
        let createTDCAccommodationsRes = await createTDCAccommodations(finalData);
        if (createTDCAccommodationsRes == true) {
          toast.success('successfully added');
        } else {
          toast.error('error');
        }
      } else {
        toast.error('excel 파일을 업로드하세요');
      }
    } catch (e) {}
  };

  return (
    <div>
      {addStatus ? (
        <>
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-blue-500"></div>
          </div>
        </>
      ) : (
        <>
          <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p>Drag and drop an Excel file here, or click to select one</p>
          </div>
        </>
      )}

      {finalData && (
        <div className="flex gap-[10px] items-center justify-end">
          <div>{fileName}</div>
          <Button
            onClick={() => handleAddButton()}
            text="등록하기"
            className="h-10 text-sm border-[1px]
            -600 hover:bg-gray-50 float-right mt-[10px]"
            // disabled={updateBannerStatus || deleteBannerStatus}
          />
          {/* {generateHtml()} */}
          {/* <button onClick={copyToClipboard}>Copy HTML to Clipboard</button> */}
        </div>
      )}
    </div>
  );
};

const dropzoneStyles: any = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default TDCAccommodationAdd;
