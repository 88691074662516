import { AxiosError, AxiosResponse } from 'axios';
import { map, slice, sortBy } from 'lodash';
import { stringify } from 'qs';
import { useEffect, useRef } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { TopNavBar } from '../../components/TopNavBar';
import testImage1 from '../../assets/test/LG 롬앤TV 100원 타임드로우_사이트 모바일.jpg';

export const DrawsPage = () => {
  const lastRef = useRef<HTMLDivElement>(null);
  const { push } = useHistory();

  return (
    <>
      <TopNavBar nav={false} logo={false} backButton={false} homeButton={true} xButton={true} />
      <img src={`${testImage1}`} />
      {/* <div className="flex justify-center">
        <Button
          text="응모하기"
          to={`/draws/:id`}
          // className="h-10 text-sm bg-black"
          className="my-2 w-1/2 rounded bg-black text-gray-50 font-bold"
        />
      </div> */}
      <div className={`fixed bottom-0 bg-white z-20 w-full max-w-md flex space-x-2 px-4 h-16`}>
        <button
          onClick={() => {
            push('/draws/:id');
          }}
          className="my-2 rounded bg-black text-gray-50 font-bold flex-1 flex justify-center items-center"
        >
          응모하기
        </button>
      </div>
    </>
  );
};
