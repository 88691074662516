import React from 'react';

interface SearchWordCardProps {
  title: string;
  index: number;
}

export const SearchWordCard: React.FC<SearchWordCardProps> = ({
  title,
  index,
}) => {
  return (
    <div className="flex items-center space-x-6 py-4 px-3 border-b cursor-pointer">
      <div className=" text-gray-400 font-semibold">{index}</div>
      <h3>{title}</h3>
    </div>
  );
};
