import { useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { AdminH1 } from '../../components/AdminH1';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { Toggle } from '../../../components/Toggle';
import { Search } from '../../../components/Search';
import { ExhibitionText, ExhibitionType } from '../../../hooks/exhibition/type';
import { useHistory, useParams } from 'react-router-dom';
import useDebounce from '../../../hooks/common';
// import { DEBOUNCE_THRESHOLD_MS } from './constants';
import { find, map, uniq } from 'lodash';
import { format } from 'date-fns';
import FroalaEditor from './components/FroalaEditor';
import { stringify } from 'qs';
import { useProducts } from '../../../hooks/product';
import ExhibitionProductDnd from './components/ExhibitionProductDnd';
import { useMutation } from 'react-query';

import { toast } from 'react-toastify';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button as AntdButton,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Radio,
  Rate,
  Row,
  Select as AntdSelect,
  Slider,
  Switch as AntdSwitch,
  Upload,
  Input,
  TimePicker,
} from 'antd';
import 'antd/dist/antd.css';
import type { UploadFile } from 'antd/es/upload/interface';

import { uploadImage } from '../../../api/photo';
import { CreateExhibitionParams, ExhibitionParams } from '../../../api_v2/admin/exhibitions/types';
import { getProduct, getProducts } from '../../../api_v2/admin/products';
import { createExhibition } from '../../../api_v2/admin/exhibitions';
import { ImagesUpload } from './components/ImagesUpload';
import { api } from '../../../plugins/axios';

import './style.css';
import { Calendar } from './components/Calendar';
import { AntdCalendar } from './components/AntdCalendar';
import {
  createCampingZoneSiteInventories,
  getCampingZoneSite,
  getCampingZoneSiteInventories,
  updateCampingZoneSite,
  updateCampingZoneSiteInventories,
} from '../../../api_v2/admin/camping';

const { TextArea } = Input;

const { Option: AntdOption } = AntdSelect;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 50 },
};

export const CampingZoneSiteDetail = () => {
  const campingZoneSiteDetailForm = Form.useForm()[0];
  const history = useHistory();
  const { campingId, zoneId, siteId } = useParams<{
    campingId: string;
    zoneId: string;
    siteId: string;
  }>();

  const editorContentRef = useRef('');
  const [editorContent, setEditorContent] = useState('');
  const [calendarState, setCalendarState] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [locale, setLocale] = useState<any>('ko');

  // const [editorImagesObj, setEditorImagesObj] = useState<{ [key: string]: any }>({});

  const editorImagesObjRef = useRef<{ [key: string]: any }>({});

  // const [calMatchingObj, setCalMatchingObj] = useState<{ [key: string]: any }>({
  //   2022: {
  //     9: { 5: { available: 2, additionalFee: 1000 }, 6: { available: 4, additionalFee: 3000 } },
  //     10: { 1: { available: 3, additionalFee: 3000 }, 28: { available: 6, additionalFee: 6000 } },
  //   },
  // });
  // const [changedCalObj, setChangedCalObj] = useState<{ [key: string]: any }>({});

  const [currInventories, setCurrInventories] = useState<any[]>([]);
  const [modifiedInventories, setModifiedInventories] = useState<any[]>([]);

  const [campingZoneSite, setCampingZoneSite] = useState<{ [key: string]: any } | null>(null);

  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  const [campingZoneSiteUpdateStatus, setCampingZoneSiteUpdateStatus] = useState(false);

  useEffect(() => {
    getCampingZoneSite(parseInt(campingId), parseInt(zoneId), parseInt(siteId)).then((res: any) => {
      if (res?.success && res?.data) {
        setCampingZoneSite(res.data);
      }
    });
  }, []);

  const onFinish = async (values: any) => {
    setCampingZoneSiteUpdateStatus(true);

    let dataToSend = {
      id: parseInt(siteId),
      campingId: parseInt(campingId),
      campingZoneId: parseInt(zoneId),
      name: values['site-name'],
      siteWidth: 0,
      siteDepth: 0,
      status: true,
      isVisible: values['campinginfo-switch1'] == undefined ? false : values['campinginfo-switch1'],
    };

    // setCampingZoneSiteUpdateStatus(true);
    let updateCampingZoneSiteResult = await updateCampingZoneSite(
      parseInt(campingId),
      parseInt(zoneId),
      parseInt(siteId),
      dataToSend
    );

    if (updateCampingZoneSiteResult) {
      toast.success('성공적으로 캠핑장 사이트를 수정하였습니다.');
    }
    setCampingZoneSiteUpdateStatus(false);

    // let { etc } = values;

    // if (values['main-images']) {
    // }

    // if (etc && etc.length > 0) {
    // }

    // let editorImagesPromises = Object.keys(editorImagesObjRef.current).map(
    //   async (editorImageKey) => {
    //     const headers = {
    //       headers: {
    //         accept: '*/*',
    //         'Content-Type': 'multipart/form-data',
    //       },
    //     };
    //     const imageFormData = new FormData();
    //     imageFormData.append('file', editorImagesObjRef.current[editorImageKey]);
    //     let photoRes = await api.post('https://stage-api.pridge.kr/photo', imageFormData, headers);
    //     return {
    //       editorImageKey,
    //       res: photoRes,
    //     };
    //   }
    // );
    // let editorImagesPromisesResult = await Promise.all(editorImagesPromises);
    // let docEditorContent = new DOMParser().parseFromString(editorContent, 'text/html');
    // let editorImgElements = docEditorContent.querySelectorAll('img');
    // for (let i = 0; i < editorImgElements.length; i++) {
    //   let tempSrcArr = editorImgElements[i].src.split('/');
    //   let tempUrlName = tempSrcArr.pop();
    //   let foundItemObj = editorImagesPromisesResult.find(
    //     (itemObj) => itemObj?.editorImageKey === tempUrlName
    //   );
    //   editorImgElements[i].src = foundItemObj?.res?.data?.url ?? '';
    // }
  };

  const normFile = (e: any) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    if (campingZoneSite) {
      let prevDataObj: { [key: string]: any } = {
        'site-name': campingZoneSite?.name ?? '',
        'campinginfo-switch1':
          campingZoneSite?.is_visible == undefined ? false : campingZoneSite?.is_visible,
      };
      campingZoneSiteDetailForm.setFieldsValue(prevDataObj);
    }
  }, [campingZoneSite]);

  const generateNewInventories = (month: any, year: any) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      const yr = new Date(date).getFullYear();
      const mon = new Date(date).getMonth() + 1;
      const dt = new Date(date).getDate();
      days.push({ date: yr + '-' + mon + '-' + dt, extraPrice: 0, status: true });
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const [createInventoriesStatus, setCreateInventoriesStatus] = useState(false);
  const [updateInventoriesStatus, setUpdateInventoriesStatus] = useState(false);

  useEffect(() => {
    setCreateInventoriesStatus(false);
    setUpdateInventoriesStatus(false);
    getCampingZoneSiteInventories(parseInt(siteId), year + '-' + month).then((res) => {
      if (res?.success && res?.data) {
        if (res.data?.length === 0) {
          setCreateInventoriesStatus(true);
          setCurrInventories(generateNewInventories(month - 1, year));
        } else if (res.data?.length > 0) {
          let modified = res.data.map((item: any) => {
            return {
              id: item.id,
              booking_id: item.booking_id,

              date: item.date,
              extraPrice: item.extra_price,
              status: item.status,
            };
          });
          setUpdateInventoriesStatus(true);
          setCurrInventories(modified);
        }
      }
    });
  }, [month, year]);

  const [modifiedCampingInventoriesStatus, setModifiedCampingInventoriesStatus] = useState(false);

  const handleModifiedCampingInventories = async (typeData: string) => {
    setModifiedCampingInventoriesStatus(true);
    if (typeData === 'create_inventories') {
      setCreateInventoriesStatus(false);
      let createResult = await createCampingZoneSiteInventories(parseInt(siteId), {
        campingId: parseInt(campingId),
        campingZoneId: parseInt(zoneId),
        items: currInventories,
      });
      if (createResult) {
        setUpdateInventoriesStatus(true);
        toast.success('성공적으로 재고 및 추가금액을 등록하였습니다');
      } else {
        setCreateInventoriesStatus(true);
      }
    } else if (typeData === 'update_inventories') {
      setUpdateInventoriesStatus(false);
      let updateResult = await updateCampingZoneSiteInventories(parseInt(siteId), {
        campingId: parseInt(campingId),
        campingZoneId: parseInt(zoneId),
        items: currInventories,
      });
      if (updateResult) {
        toast.success('성공적으로 재고 및 추가금액을 수정하였습니다');
      }
      setUpdateInventoriesStatus(true);
    }
    setModifiedCampingInventoriesStatus(false);
  };

  return (
    <>
      <AdminH1>캠핑장 사이트 수정</AdminH1>
      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:px-8">
          <Form
            form={campingZoneSiteDetailForm}
            name="validate_other"
            {...formItemLayout}
            onFinish={onFinish}
          >
            <div>
              <Form.Item name="campinginfo-switch1" valuePropName="checked" label="진열상태">
                <AntdSwitch />
              </Form.Item>
              <Form.Item
                name="site-name"
                label="사이트 명"
                rules={[{ required: true, message: '사이트 명' }]}
              >
                <Input placeholder="사이트 명" />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 18 }}>
                <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
                  {campingZoneSiteUpdateStatus ? (
                    <div>사이트 업데이트중...</div>
                  ) : (
                    <>
                      <AntdButton
                        className="add-submit-button button md:max-w-md h-10 text-sm filled-black"
                        htmlType="submit"
                      >
                        캠핑장 사이트 수정
                      </AntdButton>
                    </>
                  )}
                </div>
              </Form.Item>
            </div>
          </Form>
          <div className="bg-gray-100 w-full border" />
          <Form
            // form={campingZoneSiteDetailForm}
            name="validate_other"
            {...formItemLayout}
            // onFinish={onFinish}
          >
            <Form.Item>
              <AntdCalendar
                stateObj={{
                  // calMatchingObj,
                  // setCalMatchingObj,
                  // changedCalObj,
                  // setChangedCalObj,
                  month,
                  setMonth,
                  year,
                  setYear,

                  currInventories,
                  setCurrInventories,
                  modifiedInventories,
                  setModifiedInventories,

                  generateNewInventories,
                }}
              />
            </Form.Item>
            {/* 
            const [createInventoriesStatus, setCreateInventoriesStatus] = useState(true);
  const [updateInventoriesStatus,  */}
            <Form.Item wrapperCol={{ offset: 18 }}>
              <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
                {modifiedCampingInventoriesStatus ? (
                  <div>
                    {createInventoriesStatus
                      ? '재고/추가금액 등록중...'
                      : updateInventoriesStatus
                      ? '재고/추가금액 수정중...'
                      : null}
                  </div>
                ) : (
                  <>
                    {createInventoriesStatus && (
                      <Button
                        type="button"
                        className="add-submit-button button md:max-w-md h-10 text-sm filled-black"
                        onClick={() => handleModifiedCampingInventories('create_inventories')}
                        disabled={!createInventoriesStatus}
                      >
                        재고/추가금액 등록
                      </Button>
                    )}
                    {updateInventoriesStatus && (
                      <Button
                        type="button"
                        className="add-submit-button button md:max-w-md h-10 text-sm filled-black"
                        onClick={() => handleModifiedCampingInventories('update_inventories')}
                        disabled={!updateInventoriesStatus}
                      >
                        재고/추가금액 수정
                      </Button>
                    )}
                  </>
                )}
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </>
  );
};
