import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deletePopularKeywords, updatePopularKeywords } from '../../../api/popular-keyword';
import { UpdatePopularKeywordsParams } from '../../../api/popular-keyword/type';
import {
  getPopularKeyword,
  updatePopularKeyword,
  deletePopularKeyword,
} from '../../../api_v2/admin/popularKeywords';
import { UpdatePopularKeywordParams } from '../../../api_v2/admin/popularKeywords/type';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { TextField } from '../../../components/TextField';
import { useAdminPopularKeyword } from '../../../hooks/popular-keyword';
import { AdminH1 } from '../../components/AdminH1';

export const SearchWordDetail = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [formState, setFormState] = useState<UpdatePopularKeywordParams>({
    weight: 0,
    keyword: '',
  });

  const [updatePopularKeywordStatus, setUpdatePopularKeywordStatus] = useState<boolean>(false);

  const [deletePopularKeywordStatus, setDeletePopularKeywordStatus] = useState<boolean>(false);

  useEffect(() => {
    getPopularKeyword(parseInt(id)).then((resultData) => {
      if (resultData.success) {
        if (resultData.data) {
          const { weight, keyword } = resultData.data;

          setFormState((prev) => {
            return {
              ...prev,
              weight,
              keyword,
            };
          });
        }
      } else {
        toast.error('Something went wrong getting a popular keyword');
      }
    });
  }, [id]);

  const _onChangeText = (e: any) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: name === 'weight' ? parseInt(value) : value,
    }));
  };

  const _handleUpdateButton = async () => {
    if (!(formState.weight >= 0)) {
      toast.error('0이상 숫자를 입력해주세요');
    } else {
      let redTextFlag = false;
      for (let i = 0; i < Object.keys(formState).length; i++) {
        let key = Object.keys(formState)[i];
        if (formState[key as keyof UpdatePopularKeywordParams]) {
          let inputValue = formState[key as keyof UpdatePopularKeywordParams]!.toString().trim();
          if (inputValue.length === 0) {
            redTextFlag = true;
            break;
          }
        }
      }
      if (redTextFlag) {
        toast.error('모든항목을 작성해주세요');
      } else {
        setUpdatePopularKeywordStatus(true);
        let updatePopularKeywordResult = await updatePopularKeyword(parseInt(id), {
          ...formState,
        });
        if (updatePopularKeywordResult) {
          toast.success('성공적으로 인기 검색어를 수정하였습니다');
          setUpdatePopularKeywordStatus(false);
        } else {
          toast.error('Something went wrong updating a popular keyword');
        }
      }
    }
  };

  const _handleDeleteButton = async () => {
    setDeletePopularKeywordStatus(true);
    let deleteProductStatus = await deletePopularKeyword(parseInt(id));
    if (deleteProductStatus) {
      toast.success('성공적으로 인기 검색어를 삭제하였습니다');
      setDeletePopularKeywordStatus(false);
      history.push('/admin/word');
    } else {
      toast.error('Something went wrong deleting a popular keyword');
    }
  };

  return (
    <>
      <AdminH1>인기 검색어 상세</AdminH1>

      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <TextField
            label="진열 순서 (높을수록 우선 노출됩니다)"
            type="number"
            name="weight"
            value={formState.weight}
            onChange={_onChangeText}
          />
          <TextField
            label="* 키워드 명"
            name="keyword"
            value={formState.keyword}
            onChange={_onChangeText}
          />
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            onClick={() => _handleDeleteButton()}
            text="삭제하기"
            className="h-10 text-sm outlined-red-600 hover:bg-gray-50"
            disabled={updatePopularKeywordStatus || deletePopularKeywordStatus}
          />
          <Button
            onClick={() => _handleUpdateButton()}
            text="수정하기"
            className="h-10 text-sm filled-black"
            disabled={updatePopularKeywordStatus || deletePopularKeywordStatus}
          />
        </div>
      </Card>
    </>
  );
};
