import { stringify } from 'qs';
import { api } from '../../../plugins/axios';
import { apiForTempSol } from '../../../tempSolution/apiForTempSol';

export const getCampingBookingList = async (queryString: any) => {
  try {
    let result = await api.get('/admin/onda-properties/onda-api/bookings' + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCampingBooking = async (id: any, queryString: any) => {
  try {
    let result = await api.get(`/admin/onda-bookings/${id}` + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const refundCampingBooking = async (
  id: any,
  refundCampingBookingParams: any,
  queryString: any
) => {
  try {
    let result = await api.patch(
      `/admin/onda-bookings/${id}/refund` + queryString,
      refundCampingBookingParams
    );
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e: any) {
    console.log('e', e);

    return { success: false, message: e?.response?.data?.message ?? '' };
  }
};

export const createCampingEditor = async (param: any, queryString: any) => {
  try {
    let result = await api.post(`/admin/onda-editors` + queryString, param);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getCampingProperty = async (id: any, queryString: any) => {
  try {
    let result = await api.get(`/admin/onda-properties/${id}` + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
    return null;
  }
};

export const updateCampingProperty = async (id: any, params: any) => {
  try {
    let result = await api.patch(`/admin/onda-properties/${id}`, params);
    let possibleStatus = [200, 201];

    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e: any) {
    console.log('e', e);

    return { success: false, message: e?.response?.data?.message ?? '' };
  }
};

export const getOndaBookingByUser = async (queryString: any) => {
  try {
    let result = await api.get(`/admin/onda-bookings/get-data/users/detail` + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};
