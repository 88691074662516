'use client';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { default as htmlReactParser, attributesToProps } from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { parse, stringify } from 'qs';
import { api } from '../../../../../../../../plugins/axios';
import { pipeline, env } from '@xenova/transformers';
import { toast } from 'react-toastify';

import { env as envOnnx } from 'onnxruntime-web';

envOnnx.wasm.wasmPaths = 'https://cdn.jsdelivr.net/npm/onnxruntime-web/dist/';
envOnnx.wasm.numThreads = 1;

env.backends.onnx.wasm.numThreads = 1;

env.allowLocalModels = false;
env.useBrowserCache = false;

const Modal = ({
  visible,
  onClose,
  step1Status,
  step1Progress,
  step2Status,
  step2Progress,
  step3Status,
  step3Progress,
}: any) => {
  return (
    <div className={`modal fixed inset-0 z-50 ${visible ? '' : 'hidden'}`}>
      <div className="modal-overlay bg-gray-900 opacity-50 absolute inset-0"></div>
      <div className="modal-content relative z-[100] bg-white w-64 mx-auto mt-20 p-6 rounded-lg shadow-lg">
        <div className="flex justify-content items-center gap-[10px]">
          <p style={{ color: step1Status ? 'black' : 'gray' }}>step1</p>
          <p>-</p>
          <p style={{ color: step2Status ? 'black' : 'gray' }}>step2</p>
          <p>-</p>
          <p style={{ color: step3Status ? 'black' : 'gray' }}>step3</p>
        </div>
        <div className="flex gap-[5px] justify-center items-center">
          <p className="text-center">
            {step1Status == true
              ? step1Progress
              : step2Status == true
              ? step2Progress
              : step3Status == true
              ? step3Progress
              : null}
            %
          </p>
          <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
        </div>

        <div className="relative pt-1">
          <div className="flex mb-2 items-center justify-center">
            <div className="w-64 bg-gray-200 rounded-full">
              <div
                className="rounded-full bg-blue-500"
                style={{
                  width: `${
                    step1Status == true ? step1Progress : step2Status == true ? step2Progress : null
                  }%`,
                  height: '8px',
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const VKTourDest = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  const fetchCommunityBookmarkForPost = async (dataObj: any) => {
    try {
      let pageData = parsed?.page ?? 1;
      setIsLoading(true);
      const response = await api.get(
        `/admin/community/bookmark/pagination?type=communityPost&page=${pageData}&limit=${5}`
      );
      setIsLoading(false);
      return response?.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  // const { data: communityBookmarkForPost, isLoading: isCommunityBookmarkForPostLoading } = useQuery(
  //   // to identify each case
  //   `21ec2020-3aea-4069-a2dd-08002b30309d-page${parsed?.page ? parsed?.page : 1}`,
  //   fetchCommunityBookmarkForPost,
  //   {
  //     // enabled: authUserTokenObj?.token ? true : false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // const handleNextPage = () => {
  //   if (communityBookmarkForPost?.items?.length > 0) {
  //     let pageData = (parsed?.page ? parseInt(parsed?.page as any) : 1) + 1;
  //     window.location.href = `/admin/ai/recommendation/all-MiniLM-L6-v2/bookmark/community-post?page=${pageData}`;
  //   }
  // };

  // const handlePrevPage = () => {
  //   if ((parsed?.page as any) > 1) {
  //     let pageData = (parsed?.page ? parseInt(parsed?.page as any) : 1) - 1;
  //     window.location.href = `/admin/ai/recommendation/all-MiniLM-L6-v2/bookmark/community-post?page=${pageData}`;
  //   }
  // };

  const [inputPage, setInputPage] = useState(parsed?.page ?? 1);

  const handleInputChange = (eData: any) => {
    try {
      setInputPage(eData?.target?.value);
    } catch (e) {
      console.log('e', e);
    }
  };

  const handleInputPage = () => {
    try {
      window.location.href = `/admin/ai/recommendation/all-MiniLM-L6-v2/bookmark/community-post?page=${inputPage}`;
    } catch (e) {
      console.log('e', e);
    }
  };

  let [step1Progress, setStep1Progress] = useState(0);
  let [step1Status, setStep1Status] = useState(false);

  let [step2Progress, setStep2Progress] = useState(0);
  let [step2Status, setStep2Status] = useState(false);

  let [bmData, setBmData] = useState<any>(null);
  let [vktdData, setVktdData] = useState<any>(null);

  const [bmCpsData, setBmCpsData] = useState<any>(null);

  const [appliedData, setAppliedData] = useState<any>({});

  const [scoreValue, setScoreValue] = useState<any>(0.3);

  const handleScoreChange = (event: any) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue !== '') {
      setScoreValue(inputValue);
    }
  };

  const [startRangeValue, setStartRangeValue] = useState<any>(parsed?.startRangeValue ?? 1);
  const [endRangeValue, setEndRangeValue] = useState<any>(parsed?.endRangeValue ?? 1);

  const handleRangeChange = (event: any, typeData: any) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) && inputValue !== '') {
      typeData == 'start' ? setStartRangeValue(inputValue) : setEndRangeValue(inputValue);
    }
  };

  const [step3Progress, setStep3Progress] = useState(0);
  const [step3Status, setStep3Status] = useState(false);

  const applyToEntireData = async (typeData: any) => {
    try {
      setModalVisible(true);

      setStep1Status(true);
      setStep1Progress(0);

      setStep2Progress(0);

      setStep3Progress(0);

      if (typeData == 'continue') {
      } else if (typeData == 'restart') {
      }

      let applyToEntireDataStatus = true;
      let currTotal = 0;
      let applyToEntireDataTotal = 0;
      let vkTourDestsToModify1 = [] as any;
      let bmCps = [] as any;

      const applyToEntireDataRes = await api.get(
        `/admin/ai/community/image-process/vit-gpt2-image-captioning/description/vk-tour-dest/get-vk-tour-dests?startCpId=${startRangeValue}&endCpId=${endRangeValue}`
      );

      if (applyToEntireDataRes?.data?.vkTourDests && applyToEntireDataRes?.data?.vkTourDests[0]) {
        vkTourDestsToModify1 = applyToEntireDataRes?.data?.vkTourDests[0];
      }

      setStep1Progress(100);

      setStep1Progress(0);
      setStep1Status(false);

      const checkImageProcessDesc = async (imageListData: any) => {
        try {
          let checkStatus = true;
          for (let i = 0; i < imageListData?.length; i++) {
            let imgObj = imageListData[i];
            if (imgObj?.description) {
            } else {
              checkStatus = false;
            }
          }
          return checkStatus;
        } catch (e) {
          return null;
        }
      };

      setStep2Status(true);

      loop1: for (let i = 0; i < vkTourDestsToModify1?.length; i++) {
        console.log('i progress', (i / vkTourDestsToModify1?.length) * 100 + '%');
        setStep2Progress((i / vkTourDestsToModify1?.length) * 100);

        let vkTourDestObj = vkTourDestsToModify1[i];
        if (vkTourDestObj?.images?.length > 0) {
          let errorStatus1 = false;
          loop2: for (let j = 0; j < vkTourDestObj?.images?.slice(0, 1)?.length; j++) {
            // loop2: for (let j = 0; j < vkTourDestObj?.images?.length; j++) {

            let imgObj = vkTourDestObj?.images[j];

            if (imgObj?.url && imgObj?.base64) {
              if (!imgObj?.descEng) {
                let vitGpt2ImageCaptioningRes = await vitGpt2ImageCaptioning(imgObj);
                console.log('vitGpt2ImageCaptioningRes', vitGpt2ImageCaptioningRes);
                if (
                  (vitGpt2ImageCaptioningRes as any)?.length > 0 &&
                  (vitGpt2ImageCaptioningRes as any)[0] &&
                  vitGpt2ImageCaptioningRes
                ) {
                  console.log('vitGpt2ImageCaptioningRes success');

                  imgObj.descEng = (vitGpt2ImageCaptioningRes as any)[0]?.generated_text;

                  let imgObjModRes = await api.patch(
                    `/admin/ai/community/image-process/vit-gpt2-image-captioning/description/vk-tour-dests?vkTourDestId=${vkTourDestObj?.id}`,
                    { imgObj: { url: imgObj?.url, descEng: imgObj?.descEng } }
                  );
                } else {
                  console.log('vitGpt2ImageCaptioningRes fail');
                  errorStatus1 = true;
                  window.location.href = `/admin/ai/image-process/vit-gpt2-image-captioning/vk-tour-dest?startRangeValue=${
                    vkTourDestObj?.id
                  }&endRangeValue=${parseInt(vkTourDestObj?.id) + 1}&autoStart=y`;
                  break loop1;
                }
              }
            }
          }
          if (!errorStatus1) {
            console.log('Successfully updated');
            console.log('vkTourDestObj?.id', vkTourDestObj?.id);
            if (i === vkTourDestsToModify1?.length - 1) {
              console.log('Last iteration!');
              window.location.href = `/admin/ai/image-process/vit-gpt2-image-captioning/vk-tour-dest?startRangeValue=${
                parseInt(vkTourDestObj?.id) + 1
              }&endRangeValue=${parseInt(vkTourDestObj?.id) + 2}&autoStart=y`;
              break loop1;
            }
          } else {
            console.log('failed to update');
            window.location.href = `/admin/ai/image-process/vit-gpt2-image-captioning/vk-tour-dest?startRangeValue=${
              vkTourDestObj?.id
            }&endRangeValue=${parseInt(vkTourDestObj?.id) + 1}&autoStart=y`;
            break loop1;
          }
          console.log('errorStatus1', errorStatus1);
        } else {
          console.log('no images');
          console.log('vkTourDestObj?.id', vkTourDestObj?.id);
          if (i === vkTourDestsToModify1?.length - 1) {
            console.log('Last iteration!');
            window.location.href = `/admin/ai/image-process/vit-gpt2-image-captioning/vk-tour-dest?startRangeValue=${
              parseInt(vkTourDestObj?.id) + 1
            }&endRangeValue=${parseInt(vkTourDestObj?.id) + 2}&autoStart=y`;
            break loop1;
          }
        }
      }

      //////////////////////////////////////////////////////////

      setStep2Status(false);
      setStep2Progress(0);

      setStep3Status(false);
      setStep3Progress(0);

      setModalVisible(false);

      //  `/admin/ai/recommendation/all-MiniLM-L6-v2/bookmark/community-post/users/{userId}/apply`
    } catch (e) {
      console.log('e', e);
    }
  };

  useEffect(() => {
    if (parsed?.autoStart == 'y') {
      console.log('autoStart');
      applyToEntireData('continue');
    }
  }, [search]);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.keyCode === 27) {
        window.location.href = '/admin/ai/image-process/vit-gpt2-image-captioning/vk-tour-dest';
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const failedAttemptsFromBingTranslateApi = [];
  const bertQA = async (questionData: any, contextData: any, maxRetries = 3) => {
    try {
      console.log('bertQA');

      let retries = 0;
      console.log('try', retries + 1);

      while (retries < maxRetries) {
        try {
          const answerer = await pipeline(
            'question-answering',
            'Xenova/distilbert-base-uncased-distilled-squad'
          );
          const response = await answerer(questionData, contextData);

          if (response) {
            return response;
          } else {
            console.error(`Request failed`);
          }
        } catch (error) {
          console.error('Error during request:', error);
        }

        retries += 1;

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }

      console.log('bertQA: could not work after 3 attemps');

      return null;
    } catch (e) {
      console.log('bertQA e: ', e);
      return null;
    }
  };

  const failedAttemptsFromVitGpt2ImageCaptioning = [];
  const vitGpt2ImageCaptioning = async (imageData: any, maxRetries = 3) => {
    try {
      console.log('vitGpt2ImageCaptioning');

      let retries = 0;
      console.log('try', retries + 1);

      while (retries < maxRetries) {
        try {
          const captioner = await pipeline('image-to-text', 'Xenova/vit-gpt2-image-captioning');

          const response = await captioner(imageData?.base64);

          if (response) {
            return response;
          } else {
            console.error(`Request failed`);
          }
        } catch (error) {
          console.error('Error during request:', error);
        }

        retries += 1;

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }

      console.log('vitGpt2ImageCaptioning: could not work after 3 attemps');

      return { message: 'vitGpt2ImageCaptioning: could not work after 3 attemps' };
    } catch (e) {
      console.log('vitGpt2ImageCaptioning e: ', e);
      return e;
    }
  };

  const convertToBase64 = async (imageUrlData: any) => {
    try {
      const response = await axios.get(imageUrlData, {
        responseType: 'arraybuffer', // Ensure the response is treated as a binary buffer
      });

      const base64 = btoa(
        new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
      );

      // console.log(`data:${response.headers['content-type']};base64,${base64}`);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const findSimilarByallMiniLML6v2 = async (targetData: any, compData: any) => {
    try {
      console.log('findSimilarByallMiniLML6v2');
      // Create a feature-extraction pipeline with the specified model
      let extractor = await pipeline('feature-extraction', 'Xenova/all-MiniLM-L6-v2');

      // Function to compute cosine similarity between two vectors
      const cosineSimilarity = (vectorA: any, vectorB: any) => {
        const dotProduct = vectorA.reduce(
          (acc: any, val: any, i: any) => acc + val * vectorB[i],
          0
        );
        const magnitudeA = Math.sqrt(vectorA.reduce((acc: any, val: any) => acc + val * val, 0));
        const magnitudeB = Math.sqrt(vectorB.reduce((acc: any, val: any) => acc + val * val, 0));
        return dotProduct / (magnitudeA * magnitudeB);
      };

      // Function to compute similarity between two sentences
      const computeSimilarity = async (sentence1: any, sentence2: any) => {
        // Compute sentence embeddings for both sentences
        const embeddings = await extractor([sentence1, sentence2], {
          pooling: 'mean',
          normalize: true,
        });

        // Calculate cosine similarity between the embeddings
        const similarityScore = await cosineSimilarity(
          embeddings.tolist()[0],
          embeddings.tolist()[1]
        );
        return similarityScore;
      };

      let score = await computeSimilarity(targetData, compData);
      return score;
    } catch (e) {
      return e;
    }
  };

  const pickRandomItems = (list: any, numItems: any) => {
    const shuffled = list.sort(() => Math.random() - 0.5);
    return shuffled.slice(0, numItems);
  };

  return (
    <div className="52d70f6b-6dcd-4b91-a592-8e4aaf2e82fc max-w-md">
      <h1 className="mb-[10px]">image_process_vit-gpt2-image-captioning_vk_tour_dest</h1>
      <Modal
        visible={modalVisible}
        step1Status={step1Status}
        step1Progress={step1Progress}
        step2Status={step2Status}
        step2Progress={step2Progress}
        step3Status={step3Status}
        step3Progress={step3Progress}
      />
      <div className="flex gap-[10px]">
        <button
          onClick={() => applyToEntireData('continue')}
          className="bg-[#FF6B6B] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
        >
          Apply to the entire data - continue
        </button>
        <div>
          <p className="my-[3px]">Set range for target data ids</p>
          <div className="flex items-center gap-[10px]">
            <input
              className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
              type="text"
              value={startRangeValue}
              onChange={(e) => handleRangeChange(e, 'start')}
              placeholder="Enter numbers only"
            />
            <div> ~ </div>
            <input
              className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
              type="text"
              value={endRangeValue}
              onChange={(e) => handleRangeChange(e, 'end')}
              placeholder="Enter numbers only"
            />
          </div>
        </div>
      </div>
      {/* {!isCommunityBookmarkForPostLoading ? (
        communityBookmarkForPost?.items?.length > 0 &&
        communityBookmarkForPost?.items?.map((item: any) => (
          <div key={item?.userId} className="border-b border-solid border-gray-300 py-[10px]">
            <div className="gap-[5px] pt-[5px]">
              {item?.userId && <p>userId: {item?.userId}</p>}
              {item?.totalCount >= 0 && <p>totalCount: {item?.totalCount}</p>}
            </div>

            <div className="flex gap-[5px] pt-[5px]">
             
            </div>
            <style>
              {`
         52d70f6b-6dcd-4b91-a592-8e4aaf2e82fc div,
         52d70f6b-6dcd-4b91-a592-8e4aaf2e82fc img,
         52d70f6b-6dcd-4b91-a592-8e4aaf2e82fc video,
         52d70f6b-6dcd-4b91-a592-8e4aaf2e82fc iframe {
           max-width: 100%;
         }
        `}
            </style>
          </div>
        ))
      ) : (
        <div className="border-t-4 border-teal-500 border-solid h-6 w-6 mx-auto my-3 border-gray-800 rounded-full animate-spin"></div>
      )} */}
    </div>
  );
};

export default VKTourDest;
