import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { api } from '../../../../../../../../plugins/axios';
import { Button } from '../../../../../../../../components/Button';
import { Icon } from '../../../../../../../../components/Icon';
import { TopTitleBar } from '../../../../../components/TopTitleBar';
import { toast } from 'react-toastify';

const ImageSearch = () => {
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });
  const [searchInput, setSearchInput] = useState<any>(parsed?.q ?? '');

  const [vkTourDests, setVkTourDests] = useState<any>(null);

  const handleSearchInput = (e: any) => {
    try {
      setSearchInput(e.target.value);
    } catch (e) {}
  };

  const handleTBSearch = async () => {
    try {
      if (searchInput?.trim()?.length <= 0) {
        toast.warn('검색어를 입력해 주세요');
      } else {
        if (vkTourDests?.length > 0) {
          console.log('yes______');
        } else {
          console.log('no______');

          let vkTourDestsRes = await api.get(
            '/ai/community/image-search/vg2ic/text-based/vk-tour-dests/get-vk-tour-dests'
          );
          if (
            vkTourDestsRes?.data?.vkTourDests &&
            vkTourDestsRes?.data?.vkTourDests[0] &&
            vkTourDestsRes?.data?.vkTourDests[0]?.length > 0
          ) {
            setVkTourDests(vkTourDestsRes?.data?.vkTourDests[0]);
          }
        }
      }
    } catch (e) {}
  };

  const handleTBSearchButton = (evt: any) => {
    handleTBSearch();
  };

  const handleTBSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      handleTBSearch();
    }
  };

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [{ label: '텍스트로 검색' }, { label: '이미지로 검색' }];

  const [imageUrl, setImageUrl] = useState<any>(null);

  const handleImageUpload = (event: any) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        setImageUrl(imageUrl);
      }
    } catch (e) {}
  };

  return (
    <div>
      <TopTitleBar logo={true} />
      <div className="w-screen sm:w-full md:w-full lg:w-full side-padding bg-white">
        <div className="tabs">
          <div className="flex gap-[10px] justify-center">
            {tabs.map((tab: any, index: any) => (
              <div
                key={tab?.label}
                className={`px-4 py-2 cursor-pointer ${
                  activeTab === index ? 'bg-[#985ce0] text-white' : 'bg-gray-200 text-gray-700'
                } rounded-tl rounded-tr`}
                onClick={() => setActiveTab(index)}
              >
                {tab.label}
              </div>
            ))}
          </div>
          <div className="p-4 bg-white">
            {activeTab == 0 ? (
              <>
                {parsed && (
                  <>
                    <div
                      className="flex flex-row-reverse w-full items-center px-4 h-12 mt-2
              border border-[#FF6400] rounded bg-white border-gray-200 placeholder-gray-400 text-gray-700 mb-4"
                    >
                      <Button
                        className="bg-[#FF6400] text-[#FFFFFF] rounded-[12px]"
                        style={{ height: '80%' }}
                        onClick={(e) => handleTBSearchButton(e)}
                      >
                        이미지 검색
                      </Button>
                      {searchInput ? (
                        <>
                          <div className="flex justify-center items-center mr-2 wh-5 bg-[#d0d3d9] rounded-[20px]">
                            <Icon.X
                              className="wh-3"
                              onClick={() => {
                                setSearchInput('');
                              }}
                            />
                          </div>
                        </>
                      ) : null}
                      <input
                        className="flex-1 text-sm bg-white"
                        placeholder="이미지 검색하기"
                        onChange={(e: any) => handleSearchInput(e)}
                        value={searchInput as string}
                        onKeyDown={(e) => handleTBSearchKeyDown(e)}
                      />
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <div className="flex items-center gap-[10px]"></div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
                  <label htmlFor="fileInput" className="block text-center mb-4">
                    <span className="text-lg font-semibold text-gray-800">Upload Image</span>
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageUpload}
                    />
                  </label>
                  {imageUrl && (
                    <div className="flex justify-center">
                      <img
                        src={imageUrl}
                        alt="Uploaded Image"
                        className="max-w-full h-auto rounded-lg"
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSearch;
