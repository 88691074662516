import React, { useEffect, useState } from 'react';
import { getMyConveyanceInfoList } from '../../../../../api_v2/non_admin/conveyance';
import { TopTitleBar } from './components/TopTitleBar';

export default function MyConveyanceInfoList() {
  const [myConveyanceInfoList, setMyConveyanceInfoList] = useState<any[]>([]);

  useEffect(() => {
    getMyConveyanceInfoList('').then((res) => {
      if (res?.success == true && res?.data) {
        setMyConveyanceInfoList(res?.data?.items ?? []);
      }
    });
  }, []);

  let getDay = (dateData: any) => {
    const daysArr = ['일', '월', '화', '수', '목', '금', '토'];
    let getDayRes = new Date(dateData)?.getDay();
    if (typeof getDayRes == 'number') {
      return daysArr[getDayRes];
    } else return null;
  };

  const calculateDDay = (endDate: any) => {
    let today = new Date();
    let yr = new Date(today).getFullYear();
    let mon = new Date(today).getMonth() + 1;
    let dt = new Date(today).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    let todayDate = yr + '-' + modifiedMon + '-' + modifiedDt;
    let days = [];

    if (new Date(todayDate) <= new Date(endDate)) {
      let date = new Date(todayDate);
      while (date <= new Date(endDate)) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return {
        status: true,
        days: typeof days?.length == 'number' && days?.length - 1 >= 0 ? days?.length - 1 : 0,
      };
    } else return { status: false };
  };

  const handleConveyanceDetail = (myConveyanceInfoId: any) => {
    window.location.href = `/v2/camping/conveyance/conveyanceInfo/${myConveyanceInfoId}`;
  };

  return (
    <div>
      <TopTitleBar userAuthIcon={false} logo={true} home={false} />
      <div className="max-w-md w-screen side-padding bg-[#FFFFFF]">
        {myConveyanceInfoList?.length > 0 ? (
          <>
            {myConveyanceInfoList.map((myConveyanceInfo) => (
              <div
                onClick={() => handleConveyanceDetail(myConveyanceInfo?.id)}
                className={`grid grid-cols-1 border-2 rounded-[12px]
               mb-3 ${
                 myConveyanceInfo?.gocamping?.firstImageUrl ? 'py-5 px-3' : 'py-5 px-3'
               } bg-[white] cursor-pointer`}
                // style={{ borderRadius: '5px 5px 5px 5px' }}
                /* top-left | top-right | bottom-right | bottom-left */
              >
                <div className="flex items-center">
                  {myConveyanceInfo?.gocamping?.firstImageUrl ? (
                    <div className="mr-2">
                      <img
                        className="w-[100px] h-[60px] rounded-[8px]"
                        src={myConveyanceInfo?.gocamping?.firstImageUrl}
                      />
                    </div>
                  ) : null}

                  <div className="w-full">
                    <div className="font-[600] text-[#6F6F6F] text-[14px] flex">
                      <div className="mr-2">{myConveyanceInfo?.gocamping?.doName}</div>
                      <div>{myConveyanceInfo?.gocamping?.sigunguName}</div>
                    </div>
                    <div className="flex justify-between">
                      <div className="font-[700] text-[15px]">
                        {myConveyanceInfo?.gocamping?.facilityName}
                      </div>
                      {myConveyanceInfo?.checkinDate ? (
                        <div className={`flex justify-between`}>
                          <div className="font-[700] text-[13px] text-[#FF6400] mr-[2px]">
                            {myConveyanceInfo?.checkinDate
                              ? getDay(myConveyanceInfo?.checkinDate)
                              : null}
                          </div>
                          <div className="text-[#ebedeb] text-[13px] mr-[2px]">
                            {myConveyanceInfo?.status == true
                              ? '|'
                              : calculateDDay(myConveyanceInfo?.checkinDate)?.status == true
                              ? '|'
                              : null}
                          </div>
                          <div className="font-[500] text-[13px] text-[#FF6400]">
                            {myConveyanceInfo?.status == true
                              ? '양도완료'
                              : calculateDDay(myConveyanceInfo?.checkinDate)?.status == true
                              ? 'D-' + calculateDDay(myConveyanceInfo?.checkinDate)?.days
                              : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="text-[#6F6F6F] text-[12px]">
                      {myConveyanceInfo?.checkinDate && myConveyanceInfo?.checkoutDate
                        ? myConveyanceInfo?.checkinDate + ' ~ ' + myConveyanceInfo?.checkoutDate
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="flex justify-center items-center h-screen">
            등록된 양도정보가 없습니다.
          </div>
        )}
      </div>
    </div>
  );
}
