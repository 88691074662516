import { filter, map } from 'lodash';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  CreateExhibitionParams,
  ExhibitionParams,
} from '../../../../../api_v2/admin/exhibitions/types';

import { ProductCard } from '../../../../../components/ProductCard';
import { Table } from '../../../../../components/Table';

interface ExhibitionProductDndProps {
  exhibitionState: ExhibitionParams;
  setExhibitionState: React.Dispatch<React.SetStateAction<ExhibitionParams>>;
}

export default function ExhibitionProductDnd({
  exhibitionState,
  setExhibitionState,
}: ExhibitionProductDndProps) {
  const [products, setProducts] = useState<any[]>([]);
  useEffect(() => {
    if (Array.isArray(exhibitionState.products)) {
      let tempHolder = exhibitionState.products as any[];
      setProducts([...tempHolder]);
    }
  }, [exhibitionState.products]);

  const _onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const [removed] = products.splice(source.index, 1);
    products.splice(destination.index, 0, removed);

    setExhibitionState((prevState) => ({
      ...prevState,
      products,
    }));
  };

  return (
    <DragDropContext onDragEnd={_onDragEnd}>
      <Droppable droppableId="products" direction="vertical">
        {(provided) => (
          <div
            className="flex flex-col space-x-4"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {map(exhibitionState.products, (exhibitionProduct, index) => (
              <Draggable
                draggableId={String(exhibitionProduct.id)}
                key={String(exhibitionProduct.id)}
                index={index}
              >
                {(provided) => (
                  <div
                    className="relative"
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    {/* <ProductCard product={exhibitionProduct} /> */}
                    <Table>
                      <Table.Body>
                        <Table.Row>
                          <Table.Td>
                            <img src={exhibitionProduct.thumbnail} />
                          </Table.Td>
                          <Table.Td>{exhibitionProduct.productName}</Table.Td>
                          <Table.Td>{exhibitionProduct.brandName}</Table.Td>
                          <Table.Td>
                            <div className="flex flex-col">
                              <div>{exhibitionProduct.price}</div>
                              <div>{exhibitionProduct.discountPrice}</div>
                            </div>
                          </Table.Td>
                          <Table.Td>
                            <button
                              className="bg-red-500 text-white rounded-full px-3 py-1 p-2"
                              onClick={() => {
                                setExhibitionState((prevState) => {
                                  return {
                                    ...prevState,
                                    products: filter(
                                      prevState.products,
                                      (product) => product.productId !== exhibitionProduct.productId
                                    ),
                                  };
                                });
                              }}
                            >
                              삭제
                            </button>
                          </Table.Td>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
