import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { parse } from 'qs';

import { loginByKakaoAuthorization } from '../api_v2/non_admin/auth';

// current version of KakaoCallbackPage (old versions: V1...)
export default function KakaoCallbackPage() {
  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    // need to modify the code to handle different cases
    let attach = '';
    if (document?.referrer?.includes('v2')) {
      attach = '/v2';
    }

    let projectForLogin = localStorage.getItem(`projectForLogin`);
    localStorage.removeItem(`projectForLogin`);

    if (parsedSearch?.code) {
      loginByKakaoAuthorization({
        accessCode: parsedSearch?.code,
        v2: attach == '/v2' ? true : false,
        projectForLogin,
      });
    } else {
      console.log('kakao code does not exist');
      localStorage.removeItem('redirectPageUrl');
      window.location.href = projectForLogin == 'community' ? '/community' : attach + '/camping';
    }
  }, [search]);

  return (
    <div>
      <div
        className="spinner absolute z-[101]"
        style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div
          className="w-[55px] h-[55px] border-[7px] border-t-0 rounded-full animate-spin"
          style={{ borderColor: 'transparent transparent #FF3D00 transparent' }}
        ></div>
      </div>
    </div>
  );
}
