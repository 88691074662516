export interface CreateNotificationParams {
  title?: string;
  body?: string;
  redirect?: boolean;
  redirectPath?: string;
}

export enum OSType {
  IOS = 'ios',
  ANDROID = 'android',
}
