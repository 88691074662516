import { useEffect, useState, useRef, useCallback } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import { v4 as uuidv4 } from 'uuid';
import { parse, stringify } from 'qs';

import { default as htmlReactParser, attributesToProps } from 'html-react-parser';

import { TopTitleBar } from './components/TopTitleBar';
import { Section } from '../../../../../components/Section';
import { Button } from '../../../../../components/Button';
import { Icon } from '../../../../../components/Icon';
import { campingDummyData, campingImagesDummyData } from './dummayData';
import { Radio } from 'antd';
import { toast } from 'react-toastify';

import {
  getCampingInfo,
  getCampingZone,
  getCampingZoneSiteInventories,
} from '../../../../../api_v2/non_admin/camping';

import {
  getCampingAvailRoomTypeV2,
  getCampingAvailRoomTypesV2,
  getCampingRoomTypeV2,
  getCampingRoomTypesV2,
} from '../../../../../api_v2/non_admin/onda';

import { CustomDrawer } from './CustomDrawer';
import { authUserTokenObjState, meState } from '../../../../../ridge/ridge';

SwiperCore.use([Pagination, Autoplay]);

enum VehicleInfoType {
  RENTED = 'rented',
  TRAILER = 'trailer',
  VEHICLE_REG_PLATE = 'vehicleRegPlate',
}

enum AgeGroup {
  ADULTS = 'ADULTS',
  UNDER_AGE_TYPE1 = 'UNDER_AGE_TYPE1',
  UNDER_AGE_TYPE2 = 'UNDER_AGE_TYPE2',
}

const AgeGroupObj = {
  ADULTS: '성인',
  UNDER_AGE_TYPE1: '청소년 (미성년)',
  UNDER_AGE_TYPE2: '미취학 아동 (미성년)',
};

enum HandleQuantityType {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}

const roomTypeDetailsObj: { [key: string]: any } = {
  room: '전체 방',
  ondolroom: '온돌방',
  bedroom: '침대방',
  livingroom: '거실',
  kitchen: '주방',
  bathroom: '화장실',
};

const roomTypeBedtypeObj: { [key: string]: any } = {
  single_beds: '싱글침대',
  super_single_beds: '슈퍼싱글침대',
  double_beds: '더블침대',
  queen_beds: '퀸침대',
  king_beds: '킹침대',
  sofa_beds: '소파베드',
  air_beds: '에어배드',
};

export const CampingRoomTypePage = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { campingId, roomTypeId, zoneId } = useParams<{
    campingId: string;
    roomTypeId: string;
    zoneId: string;
  }>();
  const [campingInfo, setCampingInfo] = useState<any>(null);
  const [campingZone, setCampingZone] = useState<any>(null);

  const [chosenCampingZoneSite, setChosenCampingZoneSite] = useState<any>(null);

  const [chosenPeople, setChosenPeople] = useState<any>({
    [AgeGroup.ADULTS]: 0,
    [AgeGroup.UNDER_AGE_TYPE1]: 0,
    [AgeGroup.UNDER_AGE_TYPE2]: 0,
  });

  const [addPeople, setAddPeople] = useState<any>({
    [AgeGroup.ADULTS]: 0,
    [AgeGroup.UNDER_AGE_TYPE1]: 0,
    [AgeGroup.UNDER_AGE_TYPE2]: 0,
  });

  const [vehicleInfoList, setVehicleInfoList] = useState<any[]>([]);

  const [underAgeInfoList, setUnderAgeInfoList] = useState<any[]>([]);

  const [initialInventoryData, setInitialInventoryData] = useState<any[]>([]);

  const [campingZoneSites, setCampingZoneSites] = useState<any[]>([]);

  const [campingRoomType, setCampingRoomType] = useState<{ [key: string]: any } | null>(null);
  const [expandCampingRoomTypeIntro, setExpandCampingRoomTypeIntro] = useState<any>(false);

  const [campingRoomTypeRatePlans, setCampingRoomTypeRatePlans] = useState<any[]>([]);
  const [chosenCampingRoomTypeRatePlan, setChosenCampingRoomTypeRatePlan] = useState<any>(null);
  const [expandChosenRatePlan, setExpandChosenRatePlan] = useState<any>(false);

  const [initialLoadingForRoomtype, setInitialLoadingForRoomtype] = useState<boolean>(true);

  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const authUserTokenObj = authUserTokenObjState.useValue();
  const me = meState.useValue();

  const [agreeFeePolicyForAddPeople, setAgreeFeePolicyForAddPeople] = useState(false);

  const handleCampingZoneSite = (siteData: any) => {
    setChosenCampingZoneSite(siteData);
  };

  const handleChosenPeople = (quantityType: string, ageGroupType: string) => {
    let currTotal = 0;
    Object.keys(chosenPeople).map((key) => {
      currTotal = currTotal + chosenPeople[key];
    });

    const repeatFunc = (state: any, setState: any, qType: any) => {
      let modified =
        qType == HandleQuantityType.INCREASE ? state[ageGroupType] + 1 : state[ageGroupType] - 1;

      if (modified < 0) {
        modified = 0;
      }

      setState((prev: any) => {
        return { ...prev, [ageGroupType]: modified };
      });
    };

    if (
      quantityType == HandleQuantityType.INCREASE &&
      currTotal + 1 > campingRoomType?.capacity?.standard
    ) {
      toast.error('기준인원정보를 확인해주세요');
    } else if (quantityType == HandleQuantityType.INCREASE) {
      repeatFunc(chosenPeople, setChosenPeople, HandleQuantityType.INCREASE);
    } else if (quantityType == HandleQuantityType.DECREASE) {
      repeatFunc(chosenPeople, setChosenPeople, HandleQuantityType.DECREASE);
    }
  };

  const addVehicleInfo = () => {
    if (campingZone?.car_num <= vehicleInfoList?.length) {
      toast.error('주차수정보를 확인해주세요');
    } else {
      setVehicleInfoList((prev: any) => {
        return [...prev, { id: uuidv4(), rented: false, trailer: false, vehicleRegPlate: '' }];
      });
    }
  };

  const deleteVehicleInfo = (vehicleInfoId: any) => {
    let tempList = [...vehicleInfoList];
    let filteredList = tempList.filter((vehicleInfo) => vehicleInfo.id !== vehicleInfoId);
    setVehicleInfoList(filteredList);
  };

  const handleVehicleInfoChange = (vehicleInfoId: string, vehicleInfoType: string, evt: any) => {
    let tempList = [...vehicleInfoList];
    let modifiedList = tempList.map((vehicleInfo) => {
      if (vehicleInfo.id == vehicleInfoId) {
        vehicleInfo[vehicleInfoType] =
          vehicleInfoType === VehicleInfoType.RENTED || vehicleInfoType === VehicleInfoType.TRAILER
            ? evt.target.checked
            : evt.target.value;
        return vehicleInfo;
      } else {
        return vehicleInfo;
      }
    });
    setVehicleInfoList(modifiedList);
  };

  const handleBooking = () => {
    let currTotal = 0;
    Object.keys(chosenPeople).map((key) => {
      currTotal = currTotal + chosenPeople[key];
    });

    // let currAddTotal = 0;
    // Object.keys(addPeople).map((key) => {
    //   currAddTotal = currAddTotal + addPeople[key];
    // });
    if (!chosenCampingRoomTypeRatePlan) {
      toast.error('패키지를 선택해주세요');
    } else if (chosenPeople?.ADULTS <= 0) {
      toast.error('최소 성인 1명을 선택해주세요');
    } else if (agreeFeePolicyForAddPeople != true) {
      toast.error('추가요금정보를 확인해주세요');
    } else if (currTotal > campingRoomType?.capacity?.standard) {
      toast.error('기준인원정보를 확인해주세요');
    } else if (currTotal == 0) {
      toast.error('인원정보를 선택해주세요');
    } else {
      // if (chosenPeople?.ADULTS > campingZone?.adult_num) {
      //   toast.error('인원정보(성인)를 확인해주세요');
      // } else if (chosenPeople?.ADULTS === 0) {
      //   toast.error('최소 성인 1명을 선택해주세요');
      // } else if (
      //   chosenPeople?.UNDER_AGE_TYPE1 + chosenPeople?.UNDER_AGE_TYPE2 >
      //   campingZone?.minor_num
      // ) {
      //   toast.error('인원정보(미성년)를 확인해주세요');
      // } else

      let vehicleInfoListFlag = false;

      for (let i = 0; i < vehicleInfoList.length; i++) {
        if (
          !vehicleInfoList[i]?.rented &&
          vehicleInfoList[i]?.vehicleRegPlate?.trim()?.length === 0
        ) {
          toast.error('렌트차를 제외한 차량의 차량번호를 적어주세요');
          vehicleInfoListFlag = true;
          break;
        }
      }

      if (!vehicleInfoListFlag) {
        let numbResult = calculateNumbOfUnderAge(chosenPeople) ?? 0;

        if (underAgeInfoList.length < numbResult) {
          toast.error('미성년자 연령정보를 입력해주세요');
        } else if (underAgeInfoList.length > numbResult) {
          toast.error('선택하신 미성년자보다 더 많은 미성년자 연령정보를 입력하였습니다');
        } else if (underAgeInfoList.length === numbResult) {
          let underAgeInfoListFlag = false;
          for (let i = 0; i < underAgeInfoList.length; i++) {
            if (underAgeInfoList[i].age < 0 || underAgeInfoList[i].age > 17) {
              toast.error('미성년자 연령정보는 0 ~ 17사이의 정수를 입력해주세요');
              underAgeInfoListFlag = true;
              break;
            } else {
            }
          }
          if (!underAgeInfoListFlag) {
            if (parsedSearch?.start_date && parsedSearch?.end_date) {
              localStorage.setItem(
                'vehicleInfoListCampingBooking',
                JSON.stringify(vehicleInfoList)
              );
              localStorage.setItem(
                'underAgeInfoListCampingBooking',
                JSON.stringify(underAgeInfoList)
              );
              localStorage.setItem('chosenPeopleCampingBooking', JSON.stringify(chosenPeople));
              // localStorage.setItem('addPeopleCampingBooking', JSON.stringify(addPeople));
              const queryString = stringify(
                {
                  start_date: parsedSearch?.start_date ?? '',
                  end_date: parsedSearch?.end_date ?? '',
                },
                { addQueryPrefix: true, encode: false }
              );
              if (authUserTokenObj) {
                history.push(
                  `/v2/camping/campinginfo/${campingId}/roomtypes/${roomTypeId}/rateplans/${chosenCampingRoomTypeRatePlan?.rateplan_id}/booking` +
                    queryString
                );
              } else {
                localStorage.setItem(
                  'redirectPageUrl',
                  `/v2/camping/campinginfo/${campingId}/roomtypes/${roomTypeId}/rateplans/${chosenCampingRoomTypeRatePlan?.rateplan_id}/booking` +
                    queryString
                );
                history.push(`/v2/login?prev_page=campingroomtype`);
              }
            } else {
              toast.error('일정을 선택해주세요');
            }
          }
        }
      }
    }
  };

  const convertDateForInventoriesQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();

    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const [getRoomtypeRateplansStatus, setGetRoomtypeRateplansStatus] = useState(false);

  useEffect(() => {
    getCampingRoomTypeV2(campingId, roomTypeId, '')
      .then((res) => {
        if (res?.success && res?.data) {
          if (res?.data?.roomType) {
            if (res?.data?.roomType?.detailData) {
              setCampingRoomType({
                ...res?.data?.roomType?.detailData,
                local_images: res?.data?.roomType?.local_images,
              });
            }
          }
        }

        setInitialLoadingForRoomtype(false);
      })
      .catch((e) => {
        console.log('e', e);
        setInitialLoadingForRoomtype(false);
      });
  }, []);

  useEffect(() => {
    if (parsedSearch?.start_date && parsedSearch?.end_date) {
      let queryStrObj: { [key: string]: any } = {};
      queryStrObj.checkin = convertDateForInventoriesQS(parsedSearch?.start_date);
      queryStrObj.checkout = convertDateForInventoriesQS(parsedSearch?.end_date);
      setGetRoomtypeRateplansStatus(true);
      loadingDots(true);
      getCampingAvailRoomTypeV2(
        campingId,
        roomTypeId,
        stringify(queryStrObj, { addQueryPrefix: true, encode: false })
      )
        .then((res) => {
          if (res?.success && res?.data) {
            if (res?.data?.availRoomTypes) {
              let foundRoomType = res.data.availRoomTypes.find(
                (item: any) => item?.roomtype_id == roomTypeId
              );

              if (foundRoomType?.rateplans?.length > 0) {
                // setCampingRoomTypeRatePlans(
                //   foundRoomType?.rateplans.filter(
                //     (rpObj: any) => rpObj?.vacancyData?.availability == true
                //   )
                // );
                setCampingRoomTypeRatePlans(
                  foundRoomType?.rateplans.filter(
                    (rpObj: any) =>
                      rpObj?.vacancyData?.minVacancy == rpObj?.inventoryData?.minVacancy &&
                      rpObj?.vacancyData?.minVacancy >= 1
                  )
                );
              }
            }
            setGetRoomtypeRateplansStatus(false);
            loadingDots(false);
          } else {
            setCampingRoomTypeRatePlans([]);
            setGetRoomtypeRateplansStatus(false);
            loadingDots(false);
          }
        })
        .catch((e) => {
          console.log('e', e);
          setCampingRoomTypeRatePlans([]);
          setGetRoomtypeRateplansStatus(false);
          loadingDots(false);
        });
    } else {
    }
  }, []);

  const convertMeals = (mealObj: any) => {
    let finalStr = '';
    Object.keys(mealObj).forEach((meal, index) => {
      if (mealObj[meal]) {
        let add = index == 0 ? meal : ', ' + meal;
        finalStr = finalStr + add;
      }
    });

    if (finalStr) return finalStr;
    else return '없음';
  };

  const drawerRef = useRef<HTMLDivElement | null>(null);

  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '80%';
    }
  };

  const loadingDots = (statusBool: boolean) => {
    let loadingContEle = document.querySelector('.loading-container');
    if (loadingContEle) {
      if (statusBool) {
        (loadingContEle as any).style.display = 'block';
      } else {
        (loadingContEle as any).style.display = 'none';
      }
    }
  };

  const calculateNumbOfUnderAge = (objData: any) => {
    return (objData['UNDER_AGE_TYPE1'] ?? 0) + (objData['UNDER_AGE_TYPE2'] ?? 0);
  };

  const deleteUnderAgeInfo = (underAgeInfoId: any) => {
    let tempList = [...underAgeInfoList];
    let filteredList = tempList.filter((underAgeInfo) => underAgeInfo.id !== underAgeInfoId);
    setUnderAgeInfoList(filteredList);
  };

  const handleUnderAgeInfoChange = (underAgeInfoId: string, evt: any) => {
    let tempList = [...underAgeInfoList];
    let modifiedList = tempList.map((underAgeInfo) => {
      if (underAgeInfo.id == underAgeInfoId) {
        underAgeInfo['age'] = parseInt(evt.target.value);
        return underAgeInfo;
      } else {
        return underAgeInfo;
      }
    });
    setUnderAgeInfoList(modifiedList);
  };

  const addUnderAgeInfo = () => {
    let numbResult = calculateNumbOfUnderAge(chosenPeople) ?? 0;
    if (numbResult == 0) {
      toast.error('미성년자 인원을 추가해주세요');
    } else if (numbResult > 0) {
      if (underAgeInfoList.length < numbResult) {
        setUnderAgeInfoList((prev: any) => {
          return [...prev, { id: uuidv4(), age: 0 }];
        });
      } else {
        console.log('over');
      }
    }
  };

  const handleFeePolicyForAddPeople = (evt: any) => {
    setAgreeFeePolicyForAddPeople(evt?.target?.checked);
  };

  const chooseImage = (imgObj: any) => {
    const tempImgObj = {
      _250: '250px',
      _500: '500px',
      _1000: '1000px',
      _original: 'original',
    };
    let final = imgObj[tempImgObj?._1000]
      ? imgObj[tempImgObj?._1000]
      : imgObj[tempImgObj?._500]
      ? imgObj[tempImgObj?._500]
      : imgObj[tempImgObj?._original]
      ? imgObj[tempImgObj?._original]
      : imgObj[tempImgObj?._250];

    return final;
  };

  return (
    <>
      <TopTitleBar logo={true} home={false} />

      {initialLoadingForRoomtype == true ? (
        <>
          <div className="flex justify-center">
            <div className="initial-loading-container text-[50px]">
              <span className="loading-dot">.</span>
              <span className="loading-dot">.</span>
              <span className="loading-dot">.</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="max-w-md w-screen bg-white">
            <Section className="max-w-md">
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                // autoplay={{
                //   delay: 3000,
                // }}
                className="mySwiper bg-white"
                onAfterInit={(swiper) => {
                  if (swiper?.el?.style) {
                    (swiper.el.style as any)['padding-bottom'] = '0px';
                    (swiper.el.style as any)['height'] = '300px';
                  }
                }}
                onPaginationRender={(swiper, paginationEl) => {
                  if (paginationEl) {
                    paginationEl.style.position = 'absolute';
                    paginationEl.style.bottom = '0';
                    (paginationEl as any).style['padding-bottom'] = '15px';
                  }
                }}
              >
                {campingRoomType?.local_images &&
                  campingRoomType.local_images?.map((imgObj: any, index2: any) => (
                    <div key={(chooseImage(imgObj) ?? '') + campingRoomType?.id + index2}>
                      <SwiperSlide className="h-full">
                        <img
                          className="object-cover w-full h-full"
                          src={chooseImage(imgObj) ?? ''}
                        />
                      </SwiperSlide>
                    </div>
                  ))}
              </Swiper>
            </Section>
            <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
              <div className="flex justify-center items-center flex-col">
                {/* <div
                className="flex justify-center items-center 
              border border-[#D0D0D0] rounded p-1 text-[10px] mr-1 w-fit"
              >
                {campingInfo?.type}
              </div> */}
                <div className="font-[600] text-[20px]">
                  {campingRoomType?.name ? campingRoomType.name : null}
                </div>
              </div>
            </Section>
            <div className="bg-gray-100 w-full h-2" />
            <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
              <div className="font-[700] text-[15px] mb-3">세부정보</div>
              <div
                className={`${
                  expandCampingRoomTypeIntro
                    ? 'h-fit'
                    : campingRoomType?.description?.length <= 200
                    ? 'h-8'
                    : 'h-24'
                } relative overflow-hidden pt-1 -mx-4 side-padding`}
              >
                {!expandCampingRoomTypeIntro && (
                  <div className="bottom-0 z-10 absolute bg-gradient-to-t from-black/10 to bg-transparent w-full h-1/2" />
                )}
                <div className="">
                  {/* {campingInfoDesc && htmlReactParser(campingInfoDesc ?? '')} */}
                  <pre className="whitespace-pre-wrap">
                    {htmlReactParser(campingRoomType?.description ?? '')}
                  </pre>
                </div>
              </div>

              <Button
                onClick={() => setExpandCampingRoomTypeIntro(!expandCampingRoomTypeIntro)}
                className="flex space-x-2 items-center justify-center w-full border"
              >
                <p> {expandCampingRoomTypeIntro ? '접기' : '더보기'}</p>
                <Icon.ChevronDown
                  className={`${
                    expandCampingRoomTypeIntro ? 'rotate-180' : ''
                  } transition-transform`}
                />
              </Button>

              <div className="font-[700] text-[15px] my-3">수용 인원</div>
              <div className="mb-3">
                <div className="flex justify-between">
                  <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">기준 인원</div>

                  {campingRoomType?.capacity?.standard >= 0 ? (
                    <>{campingRoomType?.capacity?.standard}명</>
                  ) : null}
                </div>

                <div className="flex justify-between">
                  <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">최대 인원</div>
                  {campingRoomType?.capacity?.max >= 0 ? (
                    <>{campingRoomType?.capacity?.max}명</>
                  ) : null}
                </div>
              </div>

              <div className="bg-gray-100 w-full border mt-3 mb-3" />
              <div className="font-[700] text-[15px] mb-3">시설</div>
              <div className="grid grid-cols-9">
                <div className="col-span-2 text-[#FF6400] text-[13px] mr-1">특성</div>

                {campingRoomType?.tags?.roomtypes && (
                  <div className="col-span-7 flex flex-wrap">
                    {campingRoomType?.tags?.roomtypes.map((tag: any) => (
                      <div className="flex justify-center items-center border border-[#D0D0D0] rounded p-1 text-[10px] mr-1 mb-1">
                        {tag}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="grid grid-cols-9 mt-1">
                <div className="col-span-2 text-[#FF6400] text-[13px] mr-1">전망</div>
                {campingRoomType?.tags?.views && (
                  <div className="col-span-7 flex flex-wrap">
                    {campingRoomType?.tags?.views.map((tag: any) => (
                      <div className="flex justify-center items-center border border-[#D0D0D0] rounded p-1 text-[10px] mr-1 mb-1">
                        {tag}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="grid grid-cols-9 mt-1">
                <div className="col-span-2 text-[#FF6400] text-[13px] mr-1">어메니티</div>

                {campingRoomType?.tags?.amenities && (
                  <div className="col-span-7 flex flex-wrap">
                    {campingRoomType?.tags?.amenities.map((tag: any) => (
                      <div className="flex justify-center items-center border border-[#D0D0D0] rounded p-1 text-[10px] mr-1 mb-1">
                        {tag}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* <div className="bg-gray-100 w-full border mt-3 mb-3" /> */}
              {/* <div className="font-[700] text-[15px] my-3">방 개수</div>
              <div>
                <div className="grid grid-cols-3 gap-2">
                  {campingRoomType?.details &&
                    Object.keys(campingRoomType?.details).map((key: any) => (
                      <div className="flex justify-center items-center border border-[#D0D0D0] rounded p-1 text-[10px]">
                        <div className="mr-2">{roomTypeDetailsObj[key]}:</div>
                        <div>{campingRoomType?.details[key]}</div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="bg-gray-100 w-full border mt-3 mb-3" />
              <div className="font-[700] text-[15px] my-3">침대 개수</div>
              <div>
                <div className="grid grid-cols-3 gap-2">
                  {campingRoomType?.bedtype &&
                    Object.keys(campingRoomType?.bedtype).map((key: any) => (
                      <div className="flex justify-center items-center border border-[#D0D0D0] rounded p-1 text-[10px]">
                        <div className="mr-2">{roomTypeBedtypeObj[key]}:</div>
                        <div>{campingRoomType?.bedtype[key]}</div>
                      </div>
                    ))}
                </div>
              </div> */}
            </Section>
            <div className="bg-gray-100 w-full h-2" />
            <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
              <div className="font-[700] text-[15px] mb-3">패키지 선택</div>
              {getRoomtypeRateplansStatus == true ? (
                <div className="flex justify-center">
                  <div className="loading-container text-[50px]">
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                    <span className="loading-dot">.</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex flex-wrap">
                    {campingRoomTypeRatePlans?.length > 0 ? (
                      campingRoomTypeRatePlans.map((item) => (
                        <div key={item?.rateplan_id} className="mr-1 mb-1">
                          <button
                            className={`flex flex-col justify-center items-center border border-[#D0D0D0] px-6 py-1 ${
                              chosenCampingRoomTypeRatePlan?.rateplan_id == item?.rateplan_id
                                ? 'bg-[#FF6400] text-[white]'
                                : 'bg-[white] text-[black]'
                            }`}
                            // disabled={!item[Object.keys(item)[0]].available}
                            onClick={() => {
                              setChosenCampingRoomTypeRatePlan(item);
                            }}
                          >
                            <div>{item?.rateplan_name}</div>
                            <div>{item?.total?.sale_price?.toLocaleString() + '원'}</div>
                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="text-center w-full">
                        예약 가능한 페키지가 존재하지않습니다
                      </div>
                    )}
                  </div>
                </>
              )}

              {chosenCampingRoomTypeRatePlan && (
                <>
                  <div className="font-[700] text-[15px] my-3">세부정보</div>
                  {chosenCampingRoomTypeRatePlan?.rateplan_description && (
                    <>
                      <div
                        className={`${
                          expandChosenRatePlan ? 'h-fit' : 'h-26'
                        } relative overflow-hidden pt-1 -mx-4 side-padding`}
                      >
                        {!expandChosenRatePlan && (
                          <div className="bottom-0 z-10 absolute bg-gradient-to-t from-black/10 to bg-transparent w-full h-1/2" />
                        )}
                        <div className="">
                          {/* {campingInfoDesc && htmlReactParser(campingInfoDesc ?? '')} */}
                          <pre className="whitespace-pre-wrap">
                            {htmlReactParser(
                              chosenCampingRoomTypeRatePlan?.rateplan_description ?? ''
                            )}
                          </pre>
                        </div>
                      </div>
                      <Button
                        onClick={() => setExpandChosenRatePlan(!expandChosenRatePlan)}
                        className="flex space-x-2 items-center justify-center w-full border"
                      >
                        <p> {expandChosenRatePlan ? '접기' : '더보기'}</p>
                        <Icon.ChevronDown
                          className={`${
                            expandChosenRatePlan ? 'rotate-180' : ''
                          } transition-transform`}
                        />
                      </Button>
                    </>
                  )}

                  <div className="mb-3">
                    <div className="flex justify-between">
                      <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">
                        포함된 식사
                      </div>
                      {chosenCampingRoomTypeRatePlan?.meal && (
                        <>{convertMeals(chosenCampingRoomTypeRatePlan?.meal)}</>
                      )}
                    </div>
                    <div className="flex justify-between">
                      <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">
                        환불 가능 여부
                      </div>
                      {chosenCampingRoomTypeRatePlan?.refundable ? <>가능</> : <>불가능</>}
                    </div>
                    <div className="flex justify-between">
                      <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">요금정보</div>
                    </div>
                    {chosenCampingRoomTypeRatePlan?.nights.map((nightObj: any) => (
                      <>
                        <div className="flex justify-between ml-4">
                          <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">
                            {nightObj?.date}
                          </div>
                          <div className="flex flex-col">
                            <div>{nightObj?.sale_price?.toLocaleString() ?? 0}원</div>
                          </div>
                        </div>
                      </>
                    ))}

                    <div className="flex justify-between">
                      <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">
                        예약 가능 숙박일
                      </div>
                    </div>

                    {chosenCampingRoomTypeRatePlan?.length_of_stay &&
                      Object.keys(chosenCampingRoomTypeRatePlan?.length_of_stay).map((item) => (
                        <div className="flex justify-between ml-4">
                          <div className="text-[#5C5C5C] text-[13px] mr-1 items-center">
                            {{ min: '최소', max: '최대' }[item]}
                          </div>
                          <div className="flex flex-col">
                            <div>
                              {item == 'max'
                                ? chosenCampingRoomTypeRatePlan?.length_of_stay[item] == 0
                                  ? '연박 제한없음'
                                  : chosenCampingRoomTypeRatePlan?.length_of_stay[item] + '일'
                                : chosenCampingRoomTypeRatePlan?.length_of_stay[item] + '일'}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  {/* <Button
                  className="w-full max-w-md h-12 my-2 
                    border-[1px]
                    rounded font-bold text-[black] bg-[white]
                    flex-1 flex justify-center items-center"
                  onClick={() => openDrawer()}
                >
                  취환불 정책
                </Button> */}
                </>
              )}
            </Section>
            <div className="bg-gray-100 w-full h-2" />
            <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
              <div className="font-[700] text-[15px] mb-3">인원 정보</div>

              <div className="font-[700] text-[12px] mb-3">기본</div>
              <div className="ml-4">
                {[
                  { type: AgeGroup.ADULTS },
                  { type: AgeGroup.UNDER_AGE_TYPE1 },
                  { type: AgeGroup.UNDER_AGE_TYPE2 },
                ].map((ageObj) => (
                  <>
                    <div className="flex justify-between items-center" key={ageObj.type}>
                      {AgeGroupObj[ageObj.type]}
                      <div className="flex border bg-gray-100">
                        <button
                          className="wh-7 grid place-content-center"
                          onClick={() =>
                            handleChosenPeople(HandleQuantityType.DECREASE, ageObj.type)
                          }
                          // disabled={handleQuantityStatus}
                        >
                          -
                        </button>
                        <span className="wh-7 grid place-content-center text-13 bg-white border-b border-white">
                          {chosenPeople[ageObj.type]}
                        </span>
                        <button
                          className="wh-7 grid place-content-center"
                          onClick={() =>
                            handleChosenPeople(HandleQuantityType.INCREASE, ageObj.type)
                          }
                          // disabled={handleQuantityStatus}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="bg-gray-100 w-full border my-3" />
                  </>
                ))}
              </div>
              {
                <>
                  <div className="flex justify-between items-center">
                    <div className="font-[700] text-[12px] my-3">미성년 연령 정보</div>
                    <div>
                      <button
                        className={`border px-4 py-1 text-[11px]`}
                        //disabled={true}
                        onClick={() => addUnderAgeInfo()}
                      >
                        추가
                      </button>
                    </div>
                  </div>
                  {underAgeInfoList.map((underAgeInfoObj, underAgeInfoIndex) => (
                    <div key={underAgeInfoObj.id} className="my-3">
                      <div className="flex justify-between">
                        {/* <div className="text-[#FF6400]">미성년 {underAgeInfoIndex + 1}</div> */}
                        <input
                          type="number"
                          className={`border border-[#D0D0D0] min-h-[30px] max-h-[35px] text-[13px] min-w-[60px] max-w-[65px]`}
                          min={1}
                          max={17}
                          step={1}
                          name="underAgeAge"
                          value={underAgeInfoObj?.age}
                          onChange={(e) => handleUnderAgeInfoChange(underAgeInfoObj.id, e)}
                          onInput={(e: any) => {
                            let rounded = Math.round(e.target.value);
                            if (rounded < 0) {
                              rounded = 0;
                            } else if (rounded >= 18) {
                              rounded = 17;
                            }
                            e.target.value = rounded;
                          }}
                          placeholder=""
                        />
                        <button
                          className={`border px-4 py-1 text-[11px]`}
                          //disabled={true}
                          onClick={() => deleteUnderAgeInfo(underAgeInfoObj.id)}
                        >
                          삭제
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              }

              <div className="font-[700] text-[12px] mb-3">추가요금</div>
              <div className="ml-4">
                <div className="p-[15px] rounded text-13 bg-[#FFEADC]">
                  <p className="">
                    - 최대 숙박인원 초과시 입실이 거부될수 있으며, 환불이 불가합니다.
                  </p>
                  <p className="">
                    - 기준 숙박인원 초과시 추가요금이 부과될수 있습니다. (현장결제)
                  </p>
                  <p className="">- 기타부대시설 이용시 추가요금이 부과될수 있습니다. (현장결제)</p>
                </div>
                <div className="mt-[5px]">
                  <input
                    type="checkbox"
                    id="feeForAdditionalPeople"
                    name="feeForAdditionalPeople"
                    className="mr-[5px]"
                    checked={agreeFeePolicyForAddPeople}
                    onChange={(evt) => {
                      handleFeePolicyForAddPeople(evt);
                    }}
                  />
                  <label htmlFor="feeForAdditionalPeople">
                    위 사항을 확인하였으며, 예약을 계속하겠습니다
                  </label>
                </div>
              </div>
            </Section>
            <div className="bg-gray-100 w-full h-2" />
            <Section className="max-w-md side-padding py-3 bg-[#FFFFFF]">
              <div className="flex justify-between items-center mb-5">
                <div className="font-[700] text-[15px]">차량 정보</div>
                <button
                  className={`border px-4 py-1 text-[11px]`}
                  //disabled={true}
                  onClick={() => addVehicleInfo()}
                >
                  추가
                </button>
              </div>

              {vehicleInfoList.map((vehicleInfoObj, vehicleInfoIndex) => (
                <div key={vehicleInfoObj.id}>
                  <div className="flex justify-between">
                    <div className="text-[#FF6400]">차량 {vehicleInfoIndex + 1}</div>
                    <button
                      className={`border px-4 py-1 text-[11px]`}
                      //disabled={true}
                      onClick={() => deleteVehicleInfo(vehicleInfoObj.id)}
                    >
                      삭제
                    </button>
                  </div>
                  <div className="mt-3">
                    <div className="flex justify-between mb-1">
                      <label>렌트 차량입니다</label>
                      <input
                        type="checkbox"
                        name="rented"
                        checked={vehicleInfoObj?.rented}
                        onChange={(e) =>
                          handleVehicleInfoChange(vehicleInfoObj.id, VehicleInfoType.RENTED, e)
                        }
                      />
                    </div>
                    <div className="flex justify-between mb-1">
                      <label>트레일러가 있습니다</label>

                      <input
                        type="checkbox"
                        name="trailer"
                        value={vehicleInfoObj?.trailer}
                        onChange={(e) =>
                          handleVehicleInfoChange(vehicleInfoObj.id, VehicleInfoType.TRAILER, e)
                        }
                      />
                    </div>
                    <div
                      className={`${
                        vehicleInfoObj?.rented ? 'hidden' : 'flex'
                      } justify-between mb-1`}
                    >
                      <label>차량 번호</label>
                      <input
                        className={`border border-[#D0D0D0]`}
                        name="vehicleRegPlate"
                        value={vehicleInfoObj?.vehicleRegPlate}
                        onChange={(e) =>
                          handleVehicleInfoChange(
                            vehicleInfoObj.id,
                            VehicleInfoType.VEHICLE_REG_PLATE,
                            e
                          )
                        }
                        placeholder=" 12가 3456"
                      />
                    </div>
                  </div>

                  <div className="bg-gray-100 w-full border my-3" />
                </div>
              ))}
              <div>
                <p className="text-[#FF6400] text-[12px]">
                  * 손님이 있을 경우 손님의 차량도 모두 등록해주세요.
                </p>
                {/* <p className="text-[#FF6400] text-[12px]">
                  * 트레일러나 캠핑카는 1대만 진입 가능합니다.
                </p> */}
              </div>
            </Section>
            <div className="pb-20" />
          </div>
          <div className={`fixed bottom-0 bg-white z-20 w-full max-w-md flex space-x-2 px-4 h-16`}>
            <button
              onClick={() => {
                handleBooking();
              }}
              className="my-2 rounded bg-black text-[#FFFFFF] bg-[#FF6400] font-bold 
              flex-1 flex justify-center items-center"
            >
              예약하러가기
            </button>
          </div>
        </>
      )}
      <div>
        <CustomDrawer drawerRef={drawerRef}>
          <div className="side-padding mt-[75px]">
            {campingInfo?.policy && htmlReactParser(campingInfo?.policy ?? '')}
          </div>
        </CustomDrawer>
      </div>
    </>
  );
};
