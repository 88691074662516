import React, { FC, InputHTMLAttributes, useEffect, useRef } from 'react';

import { Icon } from './Icon';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch?: (value: string) => void;
}

export const Search: FC<SearchProps> = ({ value, onSearch = (value: string) => {}, ...props }) => {
  const input = useRef<HTMLInputElement>(null);
  const handleSearch = () => {
    onSearch(document.getElementById('search_form')?.getElementsByTagName('input')[0].value || '');
  };

  return (
    <form
      id="search_form"
      className="flex flex-row-reverse w-full items-center px-4 h-12 border rounded-md bg-gray-100 border-gray-200 placeholder-gray-400 text-gray-700"
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      <div className="flex justify-center items-center -ml-2 wh-10">
        <Icon.Search
          onClick={() => {
            handleSearch();
          }}
        />
      </div>
      <input ref={input} className="flex-1 text-sm bg-gray-100" value={value} {...props} />

      {value && (
        <div className="flex justify-center items-center -mr-2 wh-10">
          <Icon.X
            className="wh-4"
            onClick={() => {
              Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
              )?.set?.call(input.current, '');
              input.current?.dispatchEvent(new Event('change', { bubbles: true }));
            }}
          />
        </div>
      )}
    </form>
  );
};
