import { api } from '../../../plugins/axios';

export const getBanner = async (id: number) => {
  try {
    let result = await api.get(`/admin/banners/${id}`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getBanners = async (queryString: string) => {
  try {
    let result = await api.get('/admin/banners' + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const createBanner = async (createBannerParams: { [key: string]: any }) => {
  try {
    let result = await api.post('/admin/banners', createBannerParams);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const updateBanner = async (
  id: number,
  udpateBannerParams: {
    [key: string]: any;
  }
) => {
  try {
    let result = await api.patch(`/admin/banners/${id}`, udpateBannerParams);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteBanner = async (id: number) => {
  let result = await api.delete(`/admin/banners/${id}`);
  let possibleStatus = [200, 201];
  if (possibleStatus.includes(result.status)) {
    return true;
  } else return false;
};
