import { useEffect, useRef, useState } from 'react';

import { Button } from '../../../../../../../components/Button';
import { Card } from '../../../../../../../components/Card';
import { AdminH1 } from '../../../../../../components/AdminH1';

import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import ReactPlayer from 'react-player';

import {
  createCafe24ProductDetailVideoData,
  updateCafe24ProductDetailVideoData,
  uploadCafe24ProductDetailVideo,
} from '../../../../../../../api_v2/admin/cafe24';

enum ProdInfoType {
  VIDEO_VISIBILITY = 'videoVisibility',
  PRODUCT_NO = 'productNo',
}

export const VideoAdd = () => {
  const [productInfoList, setProductInfoList] = useState<any[]>([]);
  const [videoFile, setVideoFile] = useState<any>(null);

  const ytVideoRef = useRef(null);

  const addProdInfo = () => {
    setProductInfoList((prev: any) => {
      return [...prev, { id: uuidv4(), videoVisibility: false, productNo: '' }];
    });
  };

  const deleteProdInfo = (prodInfoId: any) => {
    let tempList = [...productInfoList];
    let filteredList = tempList.filter((prodInfo) => prodInfo.id !== prodInfoId);
    setProductInfoList(filteredList);
  };

  const handleProdInfoChange = (prodInfoId: string, prodInfoType: string, evt: any) => {
    let tempList = [...productInfoList];
    let modifiedList = tempList.map((prodInfo) => {
      if (prodInfo.id == prodInfoId) {
        prodInfo[prodInfoType] =
          prodInfoType === ProdInfoType.VIDEO_VISIBILITY
            ? evt.target.checked
            : evt.target.value?.trim();
        return prodInfo;
      } else {
        return prodInfo;
      }
    });
    setProductInfoList(modifiedList);
  };

  const handleVideoFile = (data: any) => {
    setVideoFile(data);
  };

  const [submitStatus, setSubmitStatus] = useState(false);

  const handleSubmitButton = async () => {
    let productInfoListFlag = false;
    for (let i = 0; i < productInfoList.length; i++) {
      if (productInfoList[i]?.productNo?.trim()?.length == 0) {
        productInfoListFlag = true;
        break;
      }
    }
    if (productInfoListFlag) {
      toast.error('상품번호를 입력해주세요');
    } else {
      setSubmitStatus(true);
      let createData: { [key: string]: any } = {
        uniqueId: uuidv4(),
        products: productInfoList,
        ytVideoId,
        ytVideoUrl,
      };
      if (groupName) {
        createData['groupName'] = groupName;
      }
      createCafe24ProductDetailVideoData(createData, '')
        .then((res2) => {
          if (res2?.success == true) {
            toast.success('성공적으로 동영상 정보를 등록하였습니다');
          }
          setSubmitStatus(false);
        })
        .catch((e) => {
          setSubmitStatus(false);
          toast.error('create video data error');
        });
    }
  };

  useEffect(() => {
    if (videoFile) {
      let videoEle = document.querySelector('#videoAddvideoContainer video');
      if (videoEle) {
        (videoEle as any).src = URL.createObjectURL(videoFile);
      }
    }
  }, [videoFile]);

  const [groupName, setGroupName] = useState<any>(null);

  const handleGroupNameChange = (e: any) => {
    setGroupName(e?.target?.value);
  };

  const [ytVideoId, setYtVideoId] = useState<any>(null);

  const handleYtVideoIdChange = (e: any) => {
    setYtVideoId(e?.target?.value);
  };

  const [ytVideoUrl, setYtVideoUrl] = useState<any>(null);

  const handleYtVideoUrlChange = (e: any) => {
    setYtVideoUrl(e?.target?.value);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>상품 상세 페이지 동영상 추가</AdminH1>
      </div>

      <Card>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-start-1 col-span-2">
            <p className="font-bold text-[red] mb-4">
              * mp4 동영상 선택 및 동영상이 노출되는 상품정보를 추가해주세요
            </p>
            <div className="grid grid-cols-10 mb-4">
              <div className="col-span-2 mr-[5px]">그룹명:</div>
              <div className="col-span-8">
                <input
                  className={`border border-[#D0D0D0] w-full max-w-[450px]`}
                  name="groupName"
                  value={groupName}
                  onChange={(e) => handleGroupNameChange(e)}
                  placeholder=""
                />
              </div>
            </div>
            <div className="grid grid-cols-10 mb-4">
              <div className="col-span-2 mr-[5px]">YouTube 동영상 아이디:</div>
              <div className="col-span-8">
                <input
                  className={`border border-[#D0D0D0] w-full max-w-[450px]`}
                  name="ytVideoId"
                  value={ytVideoId}
                  onChange={(e) => handleYtVideoIdChange(e)}
                  placeholder=""
                />
              </div>
              <p className="col-span-8">
                예: https://youtu.be/<span className="font-[700]">zwmUe-WOaYU</span>
              </p>
            </div>

            <div className="grid grid-cols-10 mb-4">
              <div className="col-span-2 mr-[5px]">YouTube 동영상 주소:</div>
              <div className="col-span-8">
                <input
                  className={`border border-[#D0D0D0] w-full max-w-[450px]`}
                  name="ytVideoUrl"
                  value={ytVideoUrl}
                  onChange={(e) => handleYtVideoUrlChange(e)}
                  placeholder=""
                />
              </div>
              <p className="col-span-8">
                예: <span className="font-[700]">https://youtu.be/zwmUe-WOaYU</span>
              </p>
            </div>
            <div className="grid grid-cols-10 mb-4">
              {ytVideoId ? (
                <iframe
                  ref={ytVideoRef}
                  src={`https://www.youtube.com/embed/${ytVideoId}`}
                  // frameborder={0}
                  allow="encrypted-media"
                  // allowfullscreen
                />
              ) : null}
            </div>

            {/* <div className="grid grid-cols-10">
              <div className="col-span-2 mr-[5px]">새로운 동영상:</div>
              <div className="col-span-8">
                <div className="flex justify-between items-center mb-5">
                  <input
                    type="file"
                    accept="video/mp4"
                    onChange={(e) => {
                      if (e?.target?.files && e?.target?.files[0]) {
                        handleVideoFile(e?.target?.files[0]);
                      }
                    }}
                  />
                </div>
              </div>
            </div> */}
            {/* <div id="videoAddvideoContainer" className="mb-[15px]">
              {videoFile && <video controls></video>}
            </div> */}
          </div>
          <div className="col-start-1 col-span-2">
            <div className="flex justify-end items-center mb-5">
              <button
                className={`border px-4 py-1 text-[11px]`}
                //disabled={true}
                onClick={() => addProdInfo()}
              >
                상품 정보 추가
              </button>
            </div>

            {productInfoList?.length > 0 ? (
              <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5 w-full">
                {productInfoList.map((prodInfoObj, prodInfoIndex) => (
                  <div key={prodInfoObj.id}>
                    <div className="flex justify-between">
                      <div className="text-[#FF6400]">상품 {prodInfoIndex + 1}</div>
                      <button
                        className={`border px-4 py-1 text-[11px]`}
                        //disabled={true}
                        onClick={() => deleteProdInfo(prodInfoObj.id)}
                      >
                        삭제
                      </button>
                    </div>
                    <div className="mt-3">
                      <div className="flex justify-between mb-1">
                        <label>동영상 노출</label>
                        <input
                          type="checkbox"
                          name="videoVisility"
                          checked={prodInfoObj?.videoVisibility}
                          onChange={(e) =>
                            handleProdInfoChange(prodInfoObj.id, ProdInfoType.VIDEO_VISIBILITY, e)
                          }
                        />
                      </div>

                      <div className={`flex justify-between mb-1`}>
                        <label>상품 번호</label>
                        <input
                          className={`border border-[#D0D0D0]`}
                          name="vehicleRegPlate"
                          value={prodInfoObj?.productNo}
                          onChange={(e) =>
                            handleProdInfoChange(prodInfoObj.id, ProdInfoType.PRODUCT_NO, e)
                          }
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="bg-gray-100 w-full border my-3" />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center">
                동영상 노출을 원하는 상품정보를 추가해주세요
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            disabled={submitStatus}
            text={submitStatus ? '...' : '취소'}
            className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
            to="/admin/cafe24/dayout/commerce/product-detail/videos"
          />
          <Button
            disabled={submitStatus}
            text={submitStatus ? '...' : '추가'}
            onClick={() => handleSubmitButton()}
            className="h-10 text-sm filled-black"
          />
        </div>
      </Card>
    </>
  );
};
