import { ComponentType, FC } from 'react';

import { Redirect, Route } from 'react-router';

import { Role } from '../types';
import { adminTokenState, meState } from '../ridge/ridge';

export interface AdminRouteProps {
  path: string | string[];
  component: ComponentType;
}

export const AdminRoute: FC<AdminRouteProps> = ({ path, component: Component }) => {
  const adminToken = adminTokenState.useValue();
  const me = meState.useValue();

  return (
    <Route
      path={path}
      // exact
      render={() => {
        if (!adminToken) return <Redirect to="/admin/login" />;
        // if (status === 'loading') return <></>;
        if (adminToken && me && me.role === Role.ADMIN) {
          return <Component />;
        }

        // return <Redirect to="/admin/users" />;
        // for development
        // return <Component />;
      }}
    />
  );
};
