export const genController = (data: Array<[number[], () => void]>, defaultWork: () => void) => {
  const structuredData: {
    [statusCode: number]: () => void
  } = data.reduce((acc, val) => {
    const [ statusCodes, work ] = val
    statusCodes.forEach(statusCode => acc = {...acc,
      [statusCode]: work
    })
    return acc
  }, {})

  return (statusCode: number) => {
    structuredData[statusCode] ? structuredData[statusCode]() : defaultWork()
  }
}
