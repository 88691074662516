import { AxiosError, AxiosResponse } from 'axios';
import { map, slice, sortBy } from 'lodash';
import { stringify } from 'qs';
import { useEffect, useRef, useState } from 'react';
import Iframe from 'react-iframe';
import { useInfiniteQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { TopNavBar } from '../../components/TopNavBar';

export const DrawDetailPage = () => {
  const topNavBarRef = useRef<any>();

  const [init, setInit] = useState(true);
  useEffect(() => {
    setInit(false);
  }, []);

  return (
    <div className="w-full overflow-y-hidden">
      <div ref={topNavBarRef}>
        <TopNavBar nav={false} logo={false} backButton={false} homeButton={true} />
      </div>
      <div
        style={{
          width: '100%',
          height: `${
            topNavBarRef?.current?.getBoundingClientRect()?.height
              ? window.innerHeight - topNavBarRef?.current?.getBoundingClientRect()?.height + 'px'
              : '0px'
          }`,
        }}
      >
        <Iframe
          url="https://docs.google.com/forms/d/e/1FAIpQLSc6YBJCtDzpODUCM40RwrF00-n7mzOGoQVbvbbw16TCOdeAOA/viewform"
          width="100%"
          height="100%"
          // id="myId"
          // className="myClassname"
          // display="initial"
        />
      </div>
    </div>
  );
};
