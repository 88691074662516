import React, { FC, useEffect } from 'react';
import { scrollState } from '../ridge/ridge';

interface ScrollHandlerProps {
  pathname?: any;
  containerRef?: any;
}

export const ScrollHandler: FC<ScrollHandlerProps> = ({ pathname, containerRef }) => {
  const [scrollManager, setScrollManager] = scrollState.use();

  useEffect(() => {
    if (scrollManager && scrollManager[pathname] && typeof scrollManager[pathname] === 'number') {
      document.documentElement.scrollTop = scrollManager[pathname];
    }
  }, [containerRef?.current, pathname]);

  useEffect(() => {
    const handleScrollEvent = () => {
      if (containerRef?.current?.getBoundingClientRect()?.top) {
        const calculated = Math.abs(containerRef.current.getBoundingClientRect().top);
        setScrollManager({
          ...scrollManager,
          [pathname]: calculated,
        });
      }
    };
    window.addEventListener('scroll', handleScrollEvent);
    return () => window.removeEventListener('scroll', handleScrollEvent);
  }, [pathname]);

  return <></>;
};
