export let regionNameObj = {
  Seoul: '서울특별시',
  Ulsan: '울산광역시',
  Busan: '부산광역시',
  'Chungcheongbuk-do': '충청북도',
  'Chungcheongnam-do': '충청남도',
  Daegu: '대구광역시',
  Daejeon: '대전광역시',
  'Gangwon-do': '강원도',
  'Gyeonggi-do': '경기도',
  'Gyeongsangbuk-do': '경상북도',
  'Gyeongsangnam-do': '경상남도',
  Incheon: '인천광역시',
  Jeju: '제주특별자치도',
  Gwangju: '광주광역시',
  'Jeollabuk-do': '전라북도',
  'Jeollanam-do': '전라남도',
};

export let municipalitityNameObj = {
  Seoul: {
    'Gangseo-Gu': '강서구',
    'Geum-cheon-Gu': '금천구',
    'Guro-Gu': '구로구',
    'Gwanak-Gu': '관악구',
    'Gwang-jin-Gu': '광진구',
    'Jongro-Gu': '종로구',
    'Jung-Gu': '중구',
    'Jungnang-Gu': '중랑구',
    'Mapo-Gu': '마포구',
    'Nowon-Gu': '노원구',
    'Seocho-Gu': '서초구',
    'Seodaemun-Gu': '서대문구',
    'Seongbuk-Gu': '성북구',
    'Seongdong-Gu': '성동구',
    'Songpa-Gu': '송파구',
    'Yangcheon-Gu': '양천구',
    'Yeongdeungpo-Gu': '영등포구',
    'Yongsan-Gu': '용산구',
    'Dobong-Gu': '도봉구',
    'Dong-daemun-Gu': '동대문구',
    'Dongjak-Gu': '동작구',
    'Eun-pyeong-Gu': '은평구',
    'Gandong-Gu': '강동구',
    'Gangbuk-Gu': '강북구',
    'Gangnam-Gu': '강남구',
  },
  Ulsan: {
    'Buk-Gu': '북구',
    'Dong-Gu': '동구',
    'Jung-Gu': '중구',
    'Nam-Gu': '남구',
    'Ulju-Gun': '울주군',
  },
  Busan: {
    'Buk-Gu': '북구',
    'Busanjin-Gu': '부산진구',
    'Dong-Gu': '동구',
    'Dongnae-Gu': '동래구',
    'Gangseo-Gu': '강서구',
    'Geumjeong-Gu': '금정구',
    'Gijang-Gun': '기장군',
    'Haeundae-Gu': '해운대구',
    'Nam-Gu': '남구',
    'Saha-Gu': '사하구',
    'Sasang-Gu': '사상구',
    'Seo-Gu': '서구',
    'Suyeong-Gu': '수영구',
    'Yeongdo-Gu': '영도구',
    'Yeonje-Gu': '연제구',
  },
  'Chungcheongbuk-do': {
    'Boeun-Gun': '보은군',
    'Cheongju-Si': '청주시',
    'Cheongwon-Gun': '청원군',
    'Chungju-Si': '충주시',
    'Danyang-Gun': '단양군',
    'Eumseong-Gun': '음성군',
    'Goesan-Gun': '괴산군',
    'Jecheon-Si': '제천시',
    'Jeungpyeong-Gun': '증평군',
    'Jincheon-Gun': '진천군',
    'Okcheon-Gun': '옥천군',
    'Yeongdong-Gun': '영동군',
  },
  'Chungcheongnam-do': {
    'Asan-Si': '아산시',
    'Boryeong-Si': '보령시',
    'Buyeo-Gun': '부여군',
    'Cheonan-Si': '천안시',
    'Cheongyang-Gun': '청양군',
    'Dangjin-Si': '당진시',
    'Geumsan-Gun': '금산군',
    'Gongju-Si': '공주시',
    'Gyeryong-Si': '계룡시',
    'Hongseong-Gun': '홍성군',
    'Nonsan-Si': '논산시',
    'Seocheon-Gun': '서천군',
    'Seosan-Si': '서산시',
    'Taean-Gun': '태안군',
    'Yeongi-Gun': '연기군',
    'Dangjin-Gun': '당진시',
    'Yesan-Gun': '예산군',
  },
  Daegu: {
    'Buk-Gu': '북구',
    'Dalseo-Gu': '달서구',
    'Dalseong-Gun': '달성군',
    'Dong-Gu': '동구',
    'Jung-Gu': '중구',
    'Nam-Gu': '남구',
    'Seo-Gu': '서구',
    'Suseong-Gu': '수성구',
  },
  Daejeon: {
    'Daedeok-Gu': '대덕구',
    'Dong-Gu': '동구',
    'Jung-Gu': '중구',
    'Seo-Gu': '서구',
    'Yuseong-Gu': '유성구',
  },
  'Gangwon-do': {
    'Cheorwon-Gun': '철원군',
    'Chuncheon-Si': '춘천시',
    'Donghae-Si': '동해시',
    'Gangneung-Si': '강릉시',
    'Goseong-Gun': '고성군',
    'Hoengseong-Gun': '횡성군',
    'Hongcheon-Gun': '홍천군',
    'Hwacheon-Gun': '화천군',
    'Inje-Gun': '인제군',
    'Jeongseon-Gun': '정선군',
    'Pyeongchang-Gun': '평창군',
    'Samcheok-Si': '삼척시',
    'Sokcho-Si': '속초시',
    'Taebaek-Si': '태백시',
    'Wonju-Si': '원주시',
    'Yanggu-Gun': '양구군',
    'Yangyang-Gun': '양양군',
    'Yeongwol-Gun': '영월군',
  },
  Gwangju: {
    'Buk-Gu': '북구',
    'Dong-Gu': '동구',
    'Gwangsan-Gu': '광산구',
  },
  'Gyeonggi-do': {
    'Ansan-Si': '안산시',
    'Ansoeng-Si': '안성시',
    'Anyang-Si': '안양시',
    'Bucheon-Si': '부천시',
    'Dongducheon-Si': '동두천시',
    'Gapyeong-Gun': '가평군',
    'Gimpo-Si': '김포시',
    'Goyang-Si': '고양시',
    'Gunpo-Si': '군포시',
    'Guri-Si': '구리시',
    'Gwacheon-Si': '과천시',
    'Gwangju-Si': '광주시',
    'Gwangmyeong-Si': '광명시',
    'Hanam-Si': '하남시',
    'Hwaseong-Si': '화성시',
    'Icheon-Si': '이천시',
    'Namyangju-Si': '남양주시',
    'Osan-Si': '오산시',
    'Paju-Si': '파주시',
    'Pocheon-Si': '포천시',
    'Pyeongtaek-Si': '평택시',
    'Seongnam-Si': '성남시',
    'Siheung-Si': '시흥시',
    'Suwon-Si': '수원시',
    'Uijeongbu-Si': '의정부시',
    'Uiwang-Si': '의왕시',
    'Yangju-Si': '양주시',
    'Yangpyeong-Gun': '양평군',
    'Yeoju-Gun': '여주시',
    'Yeoncheon-Gun': '연천군',
    'Yongin-Si': '용인시',
  },
  'Gyeongsangbuk-do': {
    'Andong-Si': '안동시',
    'Bonghwa-Gun': '봉화군',
    'Cheongdo-Gun': '청도군',
    'Cheongsong-Gun': '청송군',
    'Chilgok-Gun': '칠곡군',
    'Gimcheon-Si': '김천시',
    'Goryeong-Gun': '고령군',
    'Gumi-Si': '구미시',
    'Gunwi-Gun': '군위군',
    'Gyeongju-Si': '경주시',
    'Gyeongsan-Si': '경산시',
    'Mungyeong-Si': '문경시',
    'Pohang-Si': '포항시',
    'Sangju-Si': '상주시',
    'Seongju-Gun': '성주군',
    'Uiseong-Gun': '의성군',
    'Uljin-Gun': '울진군',
    'Ulleung-Gun': '울릉군',
    'Yecheon-Gun': '예천군',
    'Yeongcheon-Si': '영천시',
    'Yeongdeok-Gun': '영덕군',
    'Yeongju-Si': '영주시',
    'Yeongyang-Gun': '영양군',
  },
  'Gyeongsangnam-do': {
    'Changnyeong-Gun': '창녕군',
    'Changwon-Si': '창원시',
    'Geochang-Gun': '거창군',
    'Geoje-Si': '거제시',
    'Gimhae-Si': '김해시',
    'Goseong-Gun': '고성군',
    'Hadong-Gun': '하동군',
    'Haman-Gun': '함안군',
    'Hamyang-Gun': '함양군',
    'Hapcheon-Gun': '합천군',
    'Jinhae-Si': '진해시',
    'Jinju-Si': '진주시',
    'Masan-Si': '마산시',
    'Miryang-Si': '밀양시',
    'Namhae-Gun': '남해군',
    'Sacheon-Si': '사천시',
    'Sancheong-Gun': '산청군',
    'Tongyeong-Si': '통영시',
    'Uiryeong-Gun': '의령군',
    'Yangsan-Si': '양산시',
  },
  Incheon: {
    'Bupyeong-Gu': '부평구',
    'Dong-Gu': '동구',
    'Ganghwa-Gun': '강화군',
    'Gyeyang-Gu': '계양구',
    'Jung-Gu': '중구',
    'Nam-Gu': '남구',
    'Namdong-Gu': '남동구',
    'Ongjin-Gun': '옹진군',
    'Seo-Gu': '서구',
    'Yeonsu-Gu': '연수구',
  },
  Jeju: {
    'Jeju-Si': '제주시',
    'Seogwipo-Si': '서귀포시',
  },
  'Jeollabuk-do': {
    'Buan-Gun': '부안군',
    'Gimje-Si': '김제시',
    'Gochang-Gun': '고창군',
    'Gunsan-Si': '군산시',
    'Iksan-Si': '익산시',
    'Imsil-Gun': '임실군',
    'Jangsu-Gun': '장수군',
    'Jeongeup-Si': '정읍시',
    'Jeonju-Si': '전주시',
    'Jinan-Gun': '진안군',
    'Muju-Gun': '무주군',
    'Namwon-Si': '남원시',
    'Sunchang-Gun': '순창군',
    'Wanju-Gun': '완주군',
  },
  'Jeollanam-do': {
    'Boseong-Gun': '보성군',
    'Damyang-Gun': '담양군',
    'Gangjin-Gun': '강진군',
    'Goheung-Gun': '고흥군',
    'Gokseong-Gun': '곡성군',
    'Gurye-Gun': '구례군',
    'Gwangyang-Si': '광양시',
    'Haenam-Gun': '해남군',
    'Hampyeong-Gun': '함평군',
    'Hwasun-Gun': '화순군',
    'Jangheung-Gun': '장흥군',
    'Jangseong-Gun': '장성군',
    'Jindo-Gun': '진도군',
    'Mokpo-Si': '목포시',
    'Muan-Gun': '무안군',
    'Naju-Si': '나주시',
    'Sinan-Gun': '신안군',
    'Suncheon-Si': '순천시',
    'Wando-Gun': '완도군',
    'Yeongam-Gun': '영암군',
    'Yeonggwang-Gun': '영광군',
    'Yeosu-Si': '여수시',
  },
};
