import { api } from '../../../../plugins/axios';

export const getTDCAccommodation = async (id: any) => {
  try {
    let result = await api.get(`/admin/community/tdc-accommodations/${id}`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getTDCAccommodations = async (queryString: string) => {
  try {
    let result = await api.get('/admin/community/tdc-accommodations' + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const updateTDCAccommodation = async (
  id: any,
  updateParams: {
    [key: string]: any;
  }
) => {
  try {
    let result = await api.patch(`/admin/community/tdc-accommodations/${id}`, updateParams);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const createTDCAccommodations = async (dataToPost: { [key: string]: any }) => {
  try {
    let result = await api.post('/admin/community/tdc-accommodations', dataToPost);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const getTDCAccommCampaigns = async (queryString: string) => {
  try {
    let result = await api.get('/admin/community/tdc-accommodations/data/campaigns' + queryString);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const getTDCAccommCampaign = async (id: any) => {
  try {
    let result = await api.get(`/admin/community/tdc-accommodations/data/campaigns/${id}`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return { success: true, data: result.data };
    } else {
      return { success: false };
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const createTDCAccommCampaign = async (params: { [key: string]: any }) => {
  try {
    let result = await api.post(`/admin/community/tdc-accommodations/data/campaigns`, params);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const updateTDCAccommCampaign = async (
  id: any,
  updateParams: {
    [key: string]: any;
  }
) => {
  try {
    let result = await api.patch(
      `/admin/community/tdc-accommodations/data/campaigns/${id}`,
      updateParams
    );
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteTDCAccommCampaign = async (id: any) => {
  try {
    let result = await api.delete(`/admin/community/tdc-accommodations/data/campaigns/${id}`);
    let possibleStatus = [200, 201];
    if (possibleStatus.includes(result.status)) {
      return true;
    } else return false;
  } catch (e) {
    console.log('e', e);
  }
};
