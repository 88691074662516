import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { AdminH1 } from '../../components/AdminH1';
import { Button } from '../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { useExhibitions } from '../../../hooks/exhibition';
import { useQueryString } from '../../../hooks_v2/hooks';
import { map } from 'lodash';
import { ExhibitionText } from '../../../hooks/exhibition/type';
import { parse, stringify } from 'qs';
import { api } from '../../../plugins/axios';
import { getProducts } from '../../../api_v2/admin/products';
import { toast } from 'react-toastify';
import { TextField } from '../../../components/TextField';
import { NotificationForAll } from './NotificationForAll';

export const Notification = () => {
  // const queryString = useQueryString();
  // const [currProducts, setCurrentProducts] = useState<{ [key: string]: any }[]>([]);
  // const [totalProductsCount, setTotalProductsCount] = useState<number>(0);

  // useEffect(() => {
  //   getProducts(queryString).then((resultData) => {
  //     if (resultData?.success) {
  //       setCurrentProducts(resultData.data.items);
  //       setTotalProductsCount(resultData.data.total);
  //     } else {
  //       toast.error('Something went wrong getting products');
  //     }
  //   });
  // }, [queryString]);

  // const [tableHeaders, setTableHeaders] = useState([
  //   '진열',
  //   '대표이미지',
  //   '상품명',
  //   '소속',
  //   '가격',
  //   '상품코드',
  // ]);

  // const [testProductsList, setTestProductsList] = useState<any[]>([]);

  // useEffect(() => {
  //   let holder: any[] = [];
  //   for (let i = 0; i < 20; i++) {
  //     holder.push({
  //       isVisible: i % 2 == 0 ? true : false,
  //       image:
  //         'https://upload.wikimedia.org/wikipedia/commons/7/73/Tent_camping_along_the_Sulayr_trail_in_La_Taha%2C_Sierra_Nevada_National_Park_%28DSCF5147%29.jpg',
  //       productName: 'test_product_name',
  //       vendorName: 'test_vendor_name',
  //       brandName: 'test_brand_name',
  //       price: 0,
  //       discountedPrice: 0,
  //       productCode: 'test_code' + i,
  //     });
  //     setTestProductsList(holder);
  //   }
  // }, []);

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>알림</AdminH1>
      </div>
      <NotificationForAll />
    </>
  );
};
