enum authErrors {
  notExisting = "not-existing-customer",
  passwordRequired = "password-required",
  invalidPassword = "invalid-password",
  // NOT_SUPPORTED_LANGUAGE = "not-supported-language",
  validation = "validation",
}

enum validationSources {
  paylaod_password = 'payload.password'
}
const validationSourcesKor: {
  [name: string]: string
} = {
  [validationSources.paylaod_password]: '비밀번호',
}

enum validationTypes {
  string_min = 'string.min',
  string_max = 'string.max',
}
const validationTypesKor: {
  [name: string]: string
} = {
  [validationTypes.string_min]: '최소',
  [validationTypes.string_max]: '최대',
}

export const getErrorMessage = (errorCode: string, validation?: { source: string, type: string}) => {
  if (!errorCode) {
    return null;
  }
  console.log('errorCode :>> ', errorCode);
  console.log('validation :>> ', validation);

  if (errorCode === authErrors.validation && validation) {
    const minMaxNum = validation.type === validationTypes.string_min ? 8 : 1000
    return `${validationSourcesKor[validation.source]}는 ${validationTypesKor[validation.type]} ${minMaxNum}자여야 합니다.`
  }

  // authErrors.notExisting
  if (errorCode === authErrors.notExisting) {
    return `입력하신 이메일은 등록되지 않았어요` + `\n` +
      `이메일을 다시 한 번 확인해주세요`;
  }

  // authErrors.passwordRequired
  if (errorCode === authErrors.passwordRequired) {
    return `비밀번호를 입력해주세요`;
  }

  // authErrors.invalidPassword
  if (errorCode === authErrors.invalidPassword) {
    return `입력하신 비밀번호를 다시 한 번 확인해주세요`;
  }
}