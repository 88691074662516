import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from './Icon';

interface EventsProductsMoreProps {
  event: any;
}

export const EventProductsMore: FC<EventsProductsMoreProps> = ({ event }) => {
  const history = useHistory();

  return (
    <div
      className="relative cursor-pointer"
      onClick={() => {
        history.push(`/events/${event.id}`);
      }}
    >
      <div className="aspect-w-1 aspect-h-1">
        <div className="w-full h-full bg-gray-500 rounded-md align-middle grid items-center">
          <Icon.Plus className="text-white wh-6 text-center mx-auto" />
        </div>
      </div>
    </div>
  );
};
