import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '../../../../../../components/Icon';

import LogoDayoutPNG2 from '../../../../../../assets/img/icon-logo-dayout3.png';

import IconCafe24MyPNG from '../../../../../../assets/img/icon-cafe24-my.png';

import { meState, modalState } from '../../../../../../ridge/ridge';

type hideContents = {
  leftBtn: boolean;
};

interface TopTitleBarProps {
  title?: string;
  home?: boolean;
  button?: JSX.Element;
  hideContents?: hideContents;
  logo?: boolean;
  userAuthIcon?: boolean;
}

export const TopTitleBar: FC<TopTitleBarProps> = ({
  title,
  home = true,
  button,
  hideContents,
  logo = false,
  userAuthIcon,
}) => {
  const history = useHistory();

  const me = meState.useValue();

  const modalTypes = (modalType: string) => {
    if (modalType == 'LOGIN') {
      history.push('/v2/login');
    } else if (modalType == 'MY_PAGE') {
      const onClose = () => modalState.set({ ...modalState.get(), open: false });
      modalState.set({
        title: '마이페이지로 이동하시겠습니까?',
        subtitle: '',
        open: true,
        onClose,
        onClick: async () => {
          window.location.href = '/v2/camping/mypage';
          onClose();
        },
      });
    }
  };

  const handleUserAuthIcon = (loginStatus: string) => {
    if (loginStatus == 'LOGGED_IN') {
      modalTypes('MY_PAGE');
    } else if (loginStatus == 'LOGGED_OUT') {
      modalTypes('LOGIN');
    }
  };

  return (
    <>
      <div className="p-4">
        <div className="grid grid-cols-6 place-items-center">
          {!hideContents?.leftBtn && (
            <button
              // className="absolute left-4 top-4"
              className="place-self-start"
              onClick={() => {
                history.goBack();
              }}
            >
              <Icon.ChevronLeft />
            </button>
          )}
          {logo && (
            <>
              <div
                className="col-span-4 w-[100px] cursor-pointer"
                onClick={() => {
                  window.location.href = '/v2/camping';
                }}
              >
                <img src={LogoDayoutPNG2} className="w-full" />
              </div>
            </>
          )}
          {userAuthIcon == true && (
            <>
              {me?.userInfo ? (
                <div
                  onClick={() => handleUserAuthIcon('LOGGED_IN')}
                  className="rounded-[50px] w-[30px] h-[30px] border-[1px] 
              font-[300] text-[1.0em] flex justify-center items-center cursor-pointer"
                >
                  {me?.userInfo?.name ? me?.userInfo?.name[0] : null}
                </div>
              ) : (
                <div
                  className="w-[25px] flex flex-col justify-center items-center cursor-pointer"
                  onClick={() => handleUserAuthIcon('LOGGED_OUT')}
                >
                  <img src={IconCafe24MyPNG} className="w-full" />
                  <div className="text-[9px]">MY</div>
                </div>
              )}
            </>
          )}
        </div>
        {/* {!hideContents?.leftBtn && (
          <button
            className="absolute left-4 top-4"
            onClick={() => {
              history.goBack();
            }}
          >
            <Icon.ChevronLeft />
          </button>
        )}
        {logo && (
          <LogoDayout
            className="cursor-pointer"
            onClick={() => {
              window.location.href = '/v2/camping';
            }}
          />
        )}
        <p className="text-center mx-auto">{title}</p>
        {button && button}
        {home && (
          <button
            className="absolute right-4 top-4"
            onClick={() => {
              history.push('/home');
            }}
          >
            <Home className="wh-6" />
          </button>
        )} */}
      </div>
    </>
  );
};
