import { parse, stringify } from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { addDays } from 'date-fns';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { Button } from '../../../../../components/Button';
import { getCampingBookingList } from '../../../../../api_v2/admin/onda';
import moment from 'moment';

import { Calendar } from '../Calendar';
import { Link, useLocation } from 'react-router-dom';
import { CustomDrawer } from '../CustomDrawer';
import { Table } from '../../../../../components/Table';
import { map } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery } from 'react-query';
import { api } from '../../../../../plugins/axios';

const { RangePicker } = DatePicker;

const propertyDescriptionObj: { [key: string]: any } = {
  member_id: 'Member ID',
  name: '이름',
  email: '이메일',
  cellphone: '전화번호',
  signup_page_type: '가입페이지',
};

const signupPageTypeObj: { [key: string]: any } = {
  type1: '데이아웃 예약/양도',
  type2: '데이아웃 커머스 (카페24)',
};

enum BookingStatus {
  canceled = 'canceled',
  confirmed = 'confirmed',
}

export const Users = () => {
  const [downloadStatus, setDownloadStatus] = useState(false);

  const [infoList, setInfoList] = useState<{ [key: string]: any } | null>({});
  const [infoListTotal, setInfoListTotal] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [infoListLoading, setInfoListLoading] = useState(false);
  const observer = useRef<IntersectionObserver>();

  const convertDateForQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const drawerRef = useRef<HTMLDivElement | null>(null);

  const { search } = useLocation();
  const parsedSearch = parse(search, { ignoreQueryPrefix: true });

  const [defaultDates, setDefaultDates] = useState([new Date(), addDays(new Date(), 30)]);

  const [calendarState, setCalendarState] = useState<any>([
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: new Date(parsedSearch?.start_date as string),
          endDate: new Date(parsedSearch?.end_date as string),
          key: 'selection',
        }
      : {
          startDate: defaultDates[0],
          endDate: defaultDates[1],
          key: 'selection',
        },
  ]);

  const [chosenDates, setChosenDates] = useState<{ [key: string]: any }>(
    parsedSearch?.start_date && parsedSearch?.end_date
      ? {
          startDate: parsedSearch.start_date,
          endDate: parsedSearch.end_date,
        }
      : { startDate: null, endDate: null }
  );

  const [locale, setLocale] = useState<any>('ko');

  const [option, setOption] = useState<any>(
    parsedSearch?.option ? parsedSearch?.option : 'checkout'
  );

  const openDrawer = () => {
    if (drawerRef.current) {
      drawerRef.current.style.width = '100%';
      drawerRef.current.style.height = '80%';
    }
  };

  const [pageLimit, setPageLimit] = useState(100);

  const fetchUsers = async ({ pageParam = 1 }) => {
    let currentQueryStringObj: { [key: string]: any } = {
      type: 'onda',
      page: pageParam,
      limit: pageLimit,
      filter: {},
    };

    for (let i = 0; i < Object.keys(parsedSearch).length; i++) {
      let key = Object.keys(parsedSearch)[i];
      if (key === 'search_query') {
        currentQueryStringObj.filter['searchQuery'] = parsedSearch[key];
      } else if (key === 'signup_page_type') {
        currentQueryStringObj.filter['signupPageType'] = parsedSearch[key];
      } else if (key === 'search_query_type') {
        currentQueryStringObj.filter['searchQueryType'] = parsedSearch[key];
      }
    }

    const currentQueryString = stringify(currentQueryStringObj, {
      addQueryPrefix: true,
      encode: false,
    });

    setUsersDataStatus(true);
    try {
      let result = await api.get(`/admin/users${currentQueryString}`);
      setUsersDataStatus(false);
      if ([200].includes(result?.status)) {
        return result;
      } else return null;
    } catch (e) {
      console.log('e', e);
      setUsersDataStatus(false);
      return null;
    }
  };

  const [usersDataStatus, setUsersDataStatus] = useState(false);

  const { data: usersData, fetchNextPage } = useInfiniteQuery<AxiosResponse | null, AxiosError>(
    [`adminOndaCampingUsersPage`],
    fetchUsers,
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPage = Math.ceil((lastPage?.data?.data?.count ?? 0) / pageLimit);
        const nextPage = allPages?.length + 1;
        return nextPage <= maxPage ? nextPage : undefined;
      },
      keepPreviousData: true,
    }
  );

  const handleSearch = (queryObj: any) => {
    const currentQueryString = stringify(queryObj, {
      addQueryPrefix: true,
      encode: false,
    });

    window.location.href = '/admin/v2/camping/get-data/users' + currentQueryString;
  };

  const handleMore = () => {
    fetchNextPage();
  };

  const [searchInput, setSearchInput] = useState(parsedSearch?.search_query ?? '');

  const handleSearchInput = (e: any) => {
    setSearchInput(e.target.value);
  };

  const handleSearchKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      const tempQueryObj: { [key: string]: any } = {};
      if ((searchInput as string)?.trim()?.length > 0) {
        tempQueryObj['search_query'] = (searchInput as string)?.trim() ?? '';
      }

      if (signupPageType?.length > 0) {
        tempQueryObj['signup_page_type'] = signupPageType;
      }

      if (searchQueryType?.length > 0) {
        tempQueryObj['search_query_type'] = searchQueryType;
      }

      handleSearch(tempQueryObj);
    }
  };

  const handleCampingSearchButton = (evt: any) => {
    const tempQueryObj: { [key: string]: any } = {};

    if ((searchInput as string)?.trim()?.length > 0) {
      tempQueryObj['search_query'] = (searchInput as string)?.trim() ?? '';
    }

    if (signupPageType?.length > 0) {
      tempQueryObj['signup_page_type'] = signupPageType;
    }

    if (searchQueryType?.length > 0) {
      tempQueryObj['search_query_type'] = searchQueryType;
    }

    handleSearch(tempQueryObj);
  };

  const [signupPageType, setSignupPageType] = useState<any>(parsedSearch?.signup_page_type ?? '');

  const handleSignupPageType = (e: any) => {
    setSignupPageType(e?.target?.value);
  };

  const [searchQueryType, setSearchQueryType] = useState<any>(
    parsedSearch?.search_query_type ?? ''
  );

  const handleSearchQueryType = (e: any) => {
    setSearchQueryType(e?.target?.value);
  };

  return (
    <div className="">
      {/* <input type="file" onChange={(e) => handleInputFile(e)} /> */}
      <div className="flex flex-col mb-[15px]">
        <div className="w-full max-w-[750px] p-[15px] border-[1px] rounded-[5px] mb-[15px]">
          <div className="flex w-full mb-[5px]">
            <select
              name="searchQueryType"
              placeholder="검색타입"
              value={searchQueryType as any}
              onChange={(e) => handleSearchQueryType(e)}
            >
              <option value="">전체</option>
              <option value="name">이름</option>
              <option value="email">이메일</option>
              <option value="cellphone">전화번호</option>
            </select>
            <input
              className="flex-1 h-[42px] w-full max-w-[450px] px-[5px] mr-[5px] text-sm bg-white border border-[#ADB5BD]"
              placeholder="검색하기"
              onChange={(e) => handleSearchInput(e)}
              value={searchInput as string}
              onKeyDown={(e) => handleSearchKeyDown(e)}
            />
          </div>
          <div className="flex items-center">
            <p className="mr-[5px]">가입페이지</p>
            {/* <input
              type="checkbox"
              name="isVisible"
              checked={isVisible}
              onChange={(e) => handleVisibleChange(e)}
            /> */}
            <select
              name="signupPageType"
              placeholder="가입페이지"
              value={signupPageType as any}
              onChange={(e) => handleSignupPageType(e)}
            >
              <option value="">전체</option>
              <option value="type1">데이아웃 예약/양도</option>
              <option value="type2">데이아웃 커머스 (카페24)</option>
            </select>
          </div>
          <div className="flex justify-end">
            <Button
              className="h-[42px] text-sm text-[black] font-[400] border border-[#ADB5BD]"
              onClick={(e) => {
                handleCampingSearchButton(e);
              }}
            >
              검색
            </Button>
          </div>
        </div>

        <div className="flex justify-between mb-[5px]">
          <div></div>
          {/* <Button
            disabled={downloadStatus}
            onClick={() => {
              handleDownload();
            }}
            className="h-10 text-sm text-[black] outlined-gray-600"
          >
            다운로드
          </Button> */}
        </div>

        <div className="flex justify-between">
          <div>전체 유저 (카페24, 온다) 목록</div>
          <div>
            전체 수:
            {usersDataStatus
              ? '...'
              : usersData?.pages[usersData?.pages?.length - 1]?.data?.data?.count ?? 0}
            개
          </div>
        </div>
      </div>
      <Table className="absolute">
        <Table.Head className="sticky top-[-25px] bg-[aliceblue]">
          <Table.Row>
            <Table.Th></Table.Th>
            {Object.keys(propertyDescriptionObj).map((key) => (
              <Table.Th key={key}>{propertyDescriptionObj[key]}</Table.Th>
            ))}
            <Table.Th></Table.Th>
            <Table.Th></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {usersData?.pages && usersData?.pages[0]?.data?.data?.items?.length > 0
            ? usersData?.pages.map(
                (page, groupInd) =>
                  page?.data?.data?.items &&
                  page?.data?.data?.items.map((item: any, itemInd: number) => (
                    <>
                      <Table.Row key={item?.id}>
                        <Table.Td>{groupInd * pageLimit + (itemInd + 1)}</Table.Td>
                        <Table.Td>{item?.member_id}</Table.Td>
                        <Table.Td>{item?.name}</Table.Td>
                        <Table.Td>{item?.email}</Table.Td>
                        <Table.Td>{item?.cellphone}</Table.Td>
                        <Table.Td>
                          {item?.signup_page_type
                            ? signupPageTypeObj[item?.signup_page_type]
                            : '정보없음'}
                        </Table.Td>
                        <Table.Td>
                          {item?.created_date
                            ? dayjs(item?.created_date).format('YYYY-MM-DD hh:mm:ss')
                            : ''}
                        </Table.Td>
                        <Table.Td>
                          {/* <Link to={`/admin/v2/camping/bookings/${item?.channel_booking_number}`}>
                            자세히 보기
                          </Link> */}
                        </Table.Td>
                      </Table.Row>
                    </>
                  ))
              )
            : null}

          {/* <div ref={lastElement}></div> */}
        </Table.Body>
        <Button
          className="h-10 w-max text-sm filled-black mt-[5px]"
          onClick={() => handleMore()}
          disabled={usersDataStatus}
        >
          {usersDataStatus ? <>...</> : <>더보기</>}
        </Button>
      </Table>

      <div>
        <CustomDrawer drawerRef={drawerRef}>
          <div className="side-padding mt-[75px]">
            <Calendar
              locale={locale}
              state={calendarState}
              setState={setCalendarState}
              setChosenDates={setChosenDates}
              // drawerRef={drawerRef}
              confirmButton={false}
            />
          </div>
        </CustomDrawer>
      </div>
    </div>
  );
};
