import { ProductCard } from '../../components/ProductCard';
import { Section } from '../../components/Section';
import { Select } from '../../components/Select';
import { TopNavBar } from '../../components/TopNavBar';
import { useEffect, useRef, useState } from 'react';
import { useCollections } from '../../hooks/collection';
import { stringify } from 'qs';
import { chain, map } from 'lodash';
import { Button } from '../../components/Button';
import { AxiosError, AxiosResponse } from 'axios';
import { Product, Paginated } from '../../types';
import { useInfiniteQuery } from 'react-query';
import { api } from '../../plugins/axios';
import { PRODUCTS_PATH } from '../../paths/products';
import { CLAYFUL_PAGE_LIMIT, PAGE_LIMIT } from './constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { productFilterState } from '../../ridge/ridge';

export enum FilterType {
  WEIGHT = '추천순',
  NEW = '최신순',
  PRICE = '가격 낮은순',
}

export const ProductsPage = () => {
  const lastRef = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = productFilterState.use();
  const [sort, setSort] = useState<any>({
    weight: 'DESC',
  });
  const [selectCollection, setSelectCollection] = useState();
  const { data: rootCollection } = useCollections(
    stringify({
      'search:name': '전체상품',
    })
  );
  const rootCollectionId = rootCollection && rootCollection.length !== 0 && rootCollection[0]._id;

  const [collections, setCollections] = useState<{ [key: string]: any }[]>([]);

  const { data: returnedCollections } = useCollections(
    stringify({
      parent: rootCollectionId,
      limit: CLAYFUL_PAGE_LIMIT,
    }),
    { enabled: !!rootCollectionId }
  );

  useEffect(() => {
    if (rootCollectionId) {
      setSelectCollection(rootCollectionId);
    }
  }, [rootCollectionId]);

  useEffect(() => {
    if (rootCollection && rootCollection.length !== 0 && returnedCollections) {
      setCollections([...returnedCollections, ...rootCollection]);
    }
  }, [rootCollection, returnedCollections]);

  const fetchProducts = ({ pageParam = 1 }) => {
    const queryString = stringify(
      {
        page: pageParam,
        limit: PAGE_LIMIT,
        sort,
        filter: {
          collections: { ilike: selectCollection },
          available: true,
          censoredAt: 'null',
        },
      },
      { addQueryPrefix: true, encode: false }
    );
    return api.get(`${PRODUCTS_PATH.ROOT}${queryString}`);
  };

  const { data: products, fetchNextPage } = useInfiniteQuery<
    AxiosResponse<Paginated<Product>>,
    AxiosError
  >([`${PRODUCTS_PATH.ROOT}`, sort, filter, selectCollection], fetchProducts, {
    getNextPageParam: (lastPage, allPages) => {
      const maxPages = Math.ceil(lastPage.data.total / PAGE_LIMIT);
      const nextPages = allPages.length + 1;
      return nextPages <= maxPages ? nextPages : undefined;
    },
    keepPreviousData: true,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.isIntersecting && fetchNextPage();
        });
      },
      { threshold: 1.0 }
    );
    if (lastRef.current) {
      observer.observe(lastRef.current);
    }
  }, [products]);

  useEffect(() => {
    if (filter === FilterType.NEW) {
      setSort({
        createdAt: 'DESC',
      });
    }
    if (filter === FilterType.WEIGHT) {
      setSort({
        weight: 'DESC',
      });
    }
    if (filter === FilterType.PRICE) {
      setSort({
        discountPrice: 'ASC',
      });
    }
  }, [filter]);

  return (
    <>
      <TopNavBar />

      <Swiper
        className="mySwiper side-padding max-w-md w-screen mt-3"
        slidesPerView="auto"
        spaceBetween={6}
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}
        freeMode={true}
      >
        {chain(collections)
          .map((collection) => (
            <SwiperSlide className="w-auto">
              <Button
                onClick={() => {
                  // if (collection._id === selectCollection) {
                  //   setSelectCollection(undefined);
                  //   return;
                  // }
                  setSelectCollection(collection._id);
                }}
                key={collection._id}
                className={`${
                  collection._id === selectCollection ? 'bg-gray-900 text-gray-50' : 'bg-gray-50'
                } w-max font-semibold text-13 px-3 h-10`}
              >
                {collection.name}
              </Button>
            </SwiperSlide>
          ))
          .reverse()
          .value()}
      </Swiper>

      <div className="flex justify-end items-center side-padding my-2">
        <Select className="text-14 h-10" onChange={(e) => setFilter(e.target.value)}>
          {Object.values(FilterType).map((type: string, index) => (
            <option selected={type === filter} key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
      </div>
      <Section className="bottom-padding">
        <div className="grid grid-cols-2 gap-3">
          {map(products?.pages, (page) =>
            map(page.data.items, (product) => (
              <ProductCard
                key={product.productId}
                // to={`/products/${product.productId}`}
                product={product}
                className="shadow-none"
              />
            ))
          )}
        </div>
        <div ref={lastRef} />
      </Section>
    </>
  );
};
