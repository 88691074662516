import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
// @ts-ignore
import * as locales from 'react-date-range/dist/locale';
import { format } from 'date-fns';
import { Button } from '../../../../components/Button';

const peakExample: { [key: string]: any } = { 2022: { 9: [3, 7, 28] } };
const offPeakExample: { [key: string]: any } = { 2022: { 9: [1, 13, 20] } };
// eg) { 2022: { 9: [3, 7, 28] } }

const checkDate = (dateObj: { [key: string]: any }, givenObj: { [key: string]: any }) => {
  const { yr, mon, dt } = dateObj;
  if (givenObj[yr] && givenObj[yr][mon] && givenObj[yr][mon]?.includes(dt)) {
    return true;
  } else return false;
};

const convertToDateObj = (dateStr: string) => {
  const mon = new Date(dateStr).getMonth() + 1;
  const yr = new Date(dateStr).getFullYear();
  const dt = new Date(dateStr).getDate();
  return { yr, mon, dt };
};

const customDayContent = (day: any) => {
  const dateObj = convertToDateObj(day);
  // if (checkDate(dateObj, peakExample)) {
  //   return (
  //     <div>
  //       <span style={{ color: '#f7564a' }}>{format(day, 'd')}</span>
  //     </div>
  //   );
  // } else if (checkDate(dateObj, offPeakExample)) {
  //   return (
  //     <div>
  //       <span style={{ color: '#d853fc' }}>{format(day, 'd')}</span>
  //     </div>
  //   );
  // }
};

const convertDate = (dateData: any) => {
  const mon = new Date(dateData).getMonth() + 1;
  const dt = new Date(dateData).getDate();
  const day = new Date(dateData).getDay();
  const dayList = ['일', '월', '화', '수', '목', '금', '토'];
  const convertedDay = dayList[day] ? '(' + dayList[day] + ')' : '';
  const finalStr = mon + '.' + dt + convertedDay;
  return finalStr;
};

const createDisabledDates = (month: any, year: any) => {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    if (
      new Date(date).getFullYear() === new Date().getFullYear() &&
      new Date(date).getMonth() + 1 === new Date().getMonth() + 1 &&
      new Date(date).getDate() === new Date().getDate()
    ) {
    } else if (new Date(date) < new Date()) {
      days.push(new Date(date));
    }
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const Calendar = (props: any) => {
  const [selectedDates, setSelectedDates] = useState<{ [key: string]: any }>({
    startDate: null,
    endDate: null,
  });
  // const [disabledDates, setDisabledDates] = useState<any[]>([]);
  const [minDate, setMinDate] = useState<any>(new Date());
  const [maxDate, setMaxDate] = useState<any>(new Date());

  const getDaysInMonth = (month: any, year: any) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  useEffect(() => {
    // let createdDisabledDates = createDisabledDates(new Date().getMonth(), new Date().getFullYear());
    // if (createdDisabledDates) {
    //   setDisabledDates((prev: any) => {
    //     return [...prev, ...createdDisabledDates];
    //   });
    // }
    let mon = new Date().getMonth() + 1;
    let yr = new Date().getFullYear();

    if (mon === 12) {
      yr = yr + 1;
      mon = 0;
    }

    setMaxDate(getDaysInMonth(mon, yr)[getDaysInMonth(mon, yr).length - 1]);
    setMinDate(new Date());
  }, []);

  const convertDateForQS = (dateData: any) => {
    let yr = new Date(dateData).getFullYear();
    let mon = new Date(dateData).getMonth() + 1;
    let dt = new Date(dateData).getDate();
    let modifiedMon = mon < 10 ? '0' + mon : mon;
    let modifiedDt = dt < 10 ? '0' + dt : dt;
    return yr + '-' + modifiedMon + '-' + modifiedDt;
  };

  const handleConfirmDates = () => {
    if (selectedDates?.startDate && selectedDates?.endDate) {
      props.setChosenDates({
        startDate: convertDateForQS(selectedDates.startDate),
        endDate: convertDateForQS(selectedDates.endDate),
      });

      if (props.handleDate == true) {
        props.handleDateFunc(
          convertDateForQS(selectedDates.startDate),
          convertDateForQS(selectedDates.endDate)
        );
      }

      closeDrawer();
    }
  };

  const closeDrawer = () => {
    if (props.drawerRef.current) {
      props.drawerRef.current.style.width = '0';
      props.drawerRef.current.style.height = '0';
    }
  };

  return (
    <>
      <div className="border-b-2 border-[#EEEEEE]">
        <DateRange
          showMonthAndYearPickers={false}
          // showDateDisplay={false}
          // showMonthArrow={false}
          // showPreview={false}
          // editableDateInputs={false}
          onShownDateChange={(item) => {
            // const { yr, mon, dt } = convertToDateObj(item.toLocaleDateString());
            // let createdDisabledDates = createDisabledDates(mon - 1, yr);
            // if (createdDisabledDates) {
            //   setDisabledDates((prev: any) => {
            //     return [...prev, ...createdDisabledDates];
            //   });
            // }
          }}
          onChange={(item) => {
            if (item?.selection?.startDate && item?.selection?.endDate) {
              setSelectedDates({
                startDate: item.selection.startDate,
                endDate: item.selection.endDate,
              });
            }
            props.setState([item.selection]);
          }}
          moveRangeOnFirstSelection={false}
          ranges={props.state}
          direction="horizontal"
          locale={locales[props.locale]}
          // disabledDates={props.disabledDates}
          // minDate={minDate}
          // maxDate={maxDate}
          // dayContentRenderer={customDayContent}
          rangeColors={['#FF6400']}
        />
      </div>
      <div className="grid grid-cols-2 place-items-center mt-3">
        <div className="flex flex-col items-center">
          <div className="text-[#FF6400] text-[15px]">From</div>
          <div className="text-[20px]">
            {props?.state[0]?.startDate && convertDate(props?.state[0]?.startDate)}
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-[#FF6400] text-[15px]">To</div>
          <div className="text-[20px]">
            {props?.state[0]?.endDate && convertDate(props?.state[0]?.endDate)}
          </div>
        </div>
      </div>
      {/* <div className="flex justify-center">
        {props.confirmButton ? (
          <>
            {selectedDates?.startDate && selectedDates?.endDate ? (
              <button
                className="w-full h-16 max-w-md mx-3 mt-5 bg-[#FF6400] text-[white]"
                onClick={() => handleConfirmDates()}
              >
                선택하기
              </button>
            ) : (
              <div className="mt-5">기간을 선택해주세요</div>
            )}
          </>
        ) : (
          <>
            {selectedDates?.startDate && selectedDates?.endDate ? null : (
              <div className="mt-5">기간을 선택해주세요</div>
            )}
          </>
        )}
      </div> */}
    </>
  );
};
