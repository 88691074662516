enum signUpErrors {
  duplicatedFieldPrefix = "duplicated",
  invalidFieldNumberFormatPrefix = "invalid",
  notSupportedLanguage = "not-supported-language",
  validation = "validation",
}

export const FIELD_NAME_KOR: {
  [fieldName: string]: string,
} = {
  name: "이름",
  mobile: "전화번호",
  email: "이메일",
  password: "비밀번호",
}

type meta = {
  validation?: {
    source: string,
    type: string,
  }
}
export const getErrorMessage = (errorCode: string, meta?: meta) => {
  if (!errorCode) {
    return null;
  }

  // signUpErrors.notSupportedLanguage
  if (errorCode === signUpErrors.notSupportedLanguage) {
    return `입력하신 언어는 지원되지 않아요`
  }

  const [ prefix, target ] = errorCode.split('-')
  let targetKOR = FIELD_NAME_KOR[target]

  // signUpErrors.duplicatedFieldPrefix
  if (prefix === signUpErrors.duplicatedFieldPrefix) {
    return `${targetKOR}(이)가 중복되었어요` + `\n` +
      `다른 정보를 입력해 주세요`
  }

  /**
   * signUpErrors.invalidFieldNumberFormatPrefix
   * signUpErrors.validation
   */
  if (prefix === signUpErrors.invalidFieldNumberFormatPrefix ||
    errorCode === signUpErrors.validation) {
    const validationSource = meta?.validation?.source.split('.')[1] // Get validate source (`payload.<source>`)
    if (validationSource) {
      targetKOR = FIELD_NAME_KOR[validationSource] ?? targetKOR // Update targetKOR 
    }
    return `${targetKOR}(이)가 유효하지 않아요` + `\n` +
      `입력하신 정보를 다시 한 번 확인해 주세요`
  }

  console.error('회원가입중 문제가 발생하였습니다.', errorCode, meta)
  return `회원가입중 문제가 발생하였습니다. (${errorCode})`
}