import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { AdminH1 } from '../../components/AdminH1';
import { Button } from '../../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';

import { useQueryString } from '../../../hooks/hooks';
import { map } from 'lodash';
import { toast } from 'react-toastify';
import { getProductionList } from '../../../api_v2/admin/production';

export const ProductionList = () => {
  const queryString = useQueryString({});

  const [productionList, setProductionList] = useState<any[]>([]);
  const [productionListCount, setProductionListCount] = useState<number>(0);

  useEffect(() => {
    getProductionList(queryString).then((resultData) => {
      if (resultData?.success) {
        if (resultData.data) {
          setProductionList(resultData.data.items);
          setProductionListCount(resultData.data.total);
        }
      } else {
        toast.error('Something went wrong getting production list');
      }
    });
  }, [queryString]);

  if (!productionList) {
    return null;
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>프로덕션관리</AdminH1>
        <Button
          text="프로덕션 추가"
          className="outlined-black text-sm"
          to="/admin/production/add"
        />
      </div>
      <div className="flex justify-end items-center">
        <div className="flex space-x-2 items-center">
          <div>전체 Production 수</div>
          <div className="text-green-500">{productionListCount}</div>
        </div>
        <div>개</div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>OS</Table.Th>
              <Table.Th>버전</Table.Th>
              <Table.Th>빌드넘버</Table.Th>
              <Table.Th>노출</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(productionList, (production) => (
              <Table.Row key={production.id}>
                <Table.Td>{production.id}</Table.Td>
                <Table.Td>{production.os}</Table.Td>
                <Table.Td>{production.version}</Table.Td>
                <Table.Td>{production.buildNumber}</Table.Td>
                <Table.Td>{production.isVisible ? 'YES' : 'NO'}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-blue-500"
                    to={`/admin/production/${production.id}`}
                  >
                    수정하기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          {/* <Pagination.Label
            count={exhibitions.items.length}
            total={exhibitions.total}
          /> */}
          <Pagination.Nav basePath="/admin/production" total={productionListCount} />
        </Pagination>
      </Card>
    </>
  );
};
