import { useEffect } from 'react';
import AppleLogin from 'react-apple-login';
// @ts-ignore
import { Button } from '../../components/Button';
import { api } from '../../plugins/axios';
import { ReactComponent as AppleLogo } from '../../assets/svg/apple-logo.svg';
import { tokenState } from '../../ridge/ridge';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parse, stringify } from 'qs';
import { loginApple } from '../../api_v2/non_admin/users';

export const AppleLoginPage = () => {
  const [token, setToken] = tokenState.use();

  const history = useHistory();
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (parsed?.code) {
      api.post('/auth/applelogin', { code: parsed.code }).then((result) => {
        if ([200, 201].includes(result.status)) {
          if (result?.data?.token) {
            loginApple(result.data.token);
            // setToken(result.data.token);
            history.push('/home');
          } else {
            toast.error('Login error');
          }
        } else {
          toast.error('Login error');
          history.push('/mypage');
        }
      });
    }
  }, [parsed?.code]);

  return (
    <>
      <button
        disabled={parsed?.code ? true : false}
        onClick={(e) => {
          e.preventDefault();
          window.location.href = `https://appleid.apple.com/auth/authorize?response_type=code&client_id=${process.env.REACT_APP_APPLE_SERVICE_ID}&redirect_uri=${process.env.REACT_APP_APPLE_REDIRECT_URI}`;
        }}
        className=" w-full bg-black text-white text-15 font-normal flex items-center justify-center h-14"
      >
        <AppleLogo />
        <span className="pl-1"> Apple로 로그인</span>
      </button>
    </>
  );
};
