import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import LazyLoad from 'react-lazyload';

import { useInViewport } from 'react-in-viewport';

import { ReactComponent as IconNoPicture } from '../../../../../../assets/icons/icon-no-picture.svg';
import { ReactComponent as IconComment } from '../../../../../../assets/icons/icon-comment.svg';
import { stringify } from 'qs';

enum DrawerType {
  VK_TOUR_DEST_DETAIL = 'vk_tour_dest_detail',
}

interface CardType1 {
  item?: any;
  lastElement?: any;
  handlePostinfoSwiperSlide?: any;
  lowestPricesObj?: any;
  pageInd?: any;
  openDrawer?: any;
  setChosenVKTourDestForDetail?: any;
}

export const CardType1: FC<CardType1> = ({
  item,
  lastElement,
  handlePostinfoSwiperSlide,
  lowestPricesObj,
  pageInd,
  openDrawer,
  setChosenVKTourDestForDetail,
}) => {
  const cardObserver = useRef<IntersectionObserver>();
  const cardImgRef = useRef<any>(null);
  const [imgInViewport, setImgInViewport] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  const viewportRef = useRef<any>(null);
  const { inViewport, enterCount, leaveCount } = useInViewport(
    viewportRef as any,
    { threshold: 0.1 },
    { disconnectOnLeave: false }
  );

  const cardImgContRef = useCallback((target) => {
    if (cardObserver.current) cardObserver.current.disconnect();

    cardObserver.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setImgInViewport(true);
        } else {
        }
      },
      {
        // rootMargin: '400px',
        threshold: 0.1,
      }
    );

    if (target) cardObserver.current.observe(target);
  }, []);

  useEffect(() => {
    const handleEvent = () => {
      let imgEle = cardImgRef?.current?.querySelector('img');
      if (imgEle?.complete == true && imgInViewport == true) {
        setImgLoaded(true);
      }
    };
    handleEvent();
    window.addEventListener('DOMSubtreeModified', handleEvent);
    return () => window.removeEventListener('DOMSubtreeModified', handleEvent);
  }, [imgInViewport, cardImgRef?.current?.querySelector('img')]);

  const [contentTimePassed, setContentTimePassed] = useState<any>(null);

  const changeImgObjectFit = (slideImage: any) => {
    try {
      if (slideImage) {
        const imgElement = slideImage as any;
        const width = imgElement?.naturalWidth as any;
        const height = imgElement?.naturalHeight as any;
        if (imgElement && width >= 0 && height > 0) {
          const aspectRatio = width / height;
          if (aspectRatio > 1) {
            imgElement.style['object-fit'] = 'cover';
          } else {
            imgElement.style['object-fit'] = 'contain';
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleVKTourDest = (vKTourDest: any) => {
    setChosenVKTourDestForDetail(vKTourDest);
    openDrawer(DrawerType?.VK_TOUR_DEST_DETAIL);
  };

  return (
    <div
      ref={viewportRef}
      className="border border-[#D9D9D9] mb-5 card-type-1"
      style={{ borderRadius: '5px 5px 5px 5px' }}
    >
      {inViewport ? (
        <>
          <div
            ref={cardImgContRef}
            key={item?.id}
            style={{ height: '380px' }}
            onClick={() => handleVKTourDest(item)}
          >
            {item?.images?.length > 0 ? (
              <Swiper
                // autoplay={{
                //   delay: 3000,
                // }}
                loop={false}
                className="mySwiper mb-3 cursor-pointer"
                style={{ borderRadius: '5px 5px 0px 0px' }}
                onAfterInit={(swiper) => {
                  if (swiper?.el?.style) {
                    (swiper.el.style as any)['padding-bottom'] = '0px';
                    (swiper.el.style as any)['height'] = '230px';
                  }
                  setCurrentImageIndex(swiper?.activeIndex ?? 0);
                }}
                onPaginationRender={(swiper, paginationEl) => {
                  if (paginationEl) {
                    paginationEl.style.position = 'absolute';
                    paginationEl.style.bottom = '0';
                    (paginationEl as any).style['padding-bottom'] = '15px';
                  }
                }}
                onSlideChange={(swiper) => {
                  setCurrentImageIndex(swiper?.activeIndex ?? 0);
                }}
              >
                {item?.images?.length > 0 &&
                  item.images?.slice(0, 5).map((imgObj: any, index2: any) => (
                    <div key={imgObj?.url + item?.id + index2}>
                      <SwiperSlide className="h-full w-auto">
                        <div className="w-full h-full" ref={cardImgRef}>
                          {imgInViewport ? (
                            <>
                              <LazyLoad>
                                <img
                                  ref={(el) => {
                                    if (el) {
                                      changeImgObjectFit(el);
                                    }
                                  }}
                                  className="w-full h-[230px] object-cover"
                                  src={imgObj?.url}
                                />
                              </LazyLoad>
                              <div className="animate-pulse w-full h-full bg-gradient-to-br from-blue-200 via-purple-300 to-red-200"></div>
                            </>
                          ) : (
                            <div className="w-full h-full bg-[#dedbd9] blur-[4px]"></div>
                          )}
                        </div>
                      </SwiperSlide>
                    </div>
                  ))}
                {item?.images?.slice(0, 5)?.length > 0 ? (
                  <>
                    <div className="absolute bottom-[10px] w-full h-[8px] flex justify-center z-[2000000] ">
                      <div className="w-full h-full max-w-[100px] bg-[#dfdfdf]">
                        <div
                          className="h-full w-[80%] bg-[#cc5af8]"
                          style={{
                            width: `${
                              ((currentImageIndex + 1) / item?.images?.slice(0, 5)?.length) * 100
                            }%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Swiper>
            ) : (
              <div className="h-[230px] w-full flex justify-center items-center">
                <IconNoPicture width={'60px'} height={'60px'} />
              </div>
            )}
            <div className="side-padding mb-3">
              <div className="flex justify-between">
                <div className="text-[12px]">
                  {(item?.distance?.toFixed(2) ?? 0) + 'km' ?? null}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-[700] text-[15px]">
                  {item?.title
                    ? item?.title.length <= 11
                      ? item?.title
                      : item?.title?.substring(0, 11) + '...'
                    : null}
                </div>
              </div>
              <div className="text-[#6F6F6F] text-[12px]">{item?.addr1}</div>
              <div className="mt-[5px] text-[13px]">
                {item?.outl
                  ? item?.outl.length <= 80
                    ? item?.outl
                    : item?.outl?.substring(0, 80) + '...'
                  : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className="bg-[#dedbd9] blur-[4px]"
          style={{
            height: '380px',
            width: '100%',
          }}
        ></div>
      )}
    </div>
  );
};
