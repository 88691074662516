import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { municipalitityNameObj, regionNameObj } from './constants';
import { authUserTokenObjState } from '../../../../ridge/ridge';
import { toast } from 'react-toastify';
import { api } from '../../../../plugins/axios';

const Accordion = ({
  data,
  isOpen,
  selectedMunicipalData,
  toggleDrawer,
  toggleSelection,
  accordionRef,
  startSpinner,
  showDarkLayer,
}: any) => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (region: any) => {
    setOpenSection(openSection === region ? null : region);
  };

  const findRegionName = (regionData: any) => {
    try {
      return (regionNameObj as any)[regionData] ?? null;
    } catch (e) {
      return null;
    }
  };

  const findMunicipalitityName = (regionData: any, subRegionData: any) => {
    try {
      return (municipalitityNameObj as any)[regionData][subRegionData] ?? null;
    } catch (e) {
      return null;
    }
  };

  const scrollToElement = (elementId: any) => {
    try {
      const element = document.getElementById(elementId);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 100);
      }
    } catch (e) {}
  };

  return (
    <div
      ref={accordionRef}
      className={`accordion z-[100] pb-[15vh] w-64 max-w-md border-l bg-white fixed top-0 right-0 h-full overflow-y-auto ${
        isOpen ? 'visible' : 'invisible'
      }`}
    >
      <div className="w-full flex justify-end">
        <button onClick={toggleDrawer} className="px-[10px] text-[35px] font-[700] cursor-pointer">
          X
        </button>
      </div>

      {Object.keys(data).map((region) => (
        <div id={region} key={region} className="my-4">
          <div
            onClick={() => {
              toggleSection(region);
              scrollToElement(region);
            }}
            className={`${
              openSection === region ? 'bg-blue-200 text-white' : 'bg-gray-200 text-gray-800'
            } p-2 rounded-t-md cursor-pointer transition-colors duration-300 flex justify-between items-center`}
          >
            <span>{findRegionName(region) ?? region}</span>
            <span>{openSection === region ? '-' : '+'}</span>
            {/* Icon to indicate open/closed state */}
          </div>
          {openSection === region && (
            <div className="bg-white p-2 rounded-b-md">
              <ul className="list-none">
                {data[region].map((item: any) => (
                  <li
                    key={region + '-' + item?.NAME_2 + (item?.TYPE_2 ? '-' + item?.TYPE_2 : '')}
                    onClick={() => {
                      try {
                        toggleSelection(region, item);

                        startSpinner();
                        showDarkLayer();
                      } catch (e) {}
                    }}
                    style={{ width: '50%', padding: '7px 13px', textAlign: 'center' }}
                    className={`my-[3px] rounded-[7px] cursor-pointer ${
                      selectedMunicipalData &&
                      selectedMunicipalData[region] &&
                      selectedMunicipalData[region]?.find(
                        (spObj: any) =>
                          spObj?.NAME_2 == item?.NAME_2 && spObj?.TYPE_2 == item?.TYPE_2
                      )
                        ? 'bg-[#fbdbf5] text-[#0c0b0b]' // Selected color and text color
                        : 'border'
                    }`}
                  >
                    {findMunicipalitityName(
                      region,
                      item?.NAME_2 + (item?.TYPE_2 ? '-' + item?.TYPE_2 : '')
                    ) ?? item?.NAME_2 + (item?.TYPE_2 ? '-' + item?.TYPE_2 : '')}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
      <div
        className="dark-layer fixed inset-0 bg-black opacity-50 z-[1000000000]"
        style={{ display: 'none' }}
      ></div>
    </div>
  );
};

export default function Type3() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedMunicipalData, setSelectedMunicipalData] = useState<any>({});
  const accordionRef = useRef<any>(null);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleSelection = (regionData: any, itemData: any) => {
    let found = null;
    if (selectedMunicipalData && selectedMunicipalData[regionData]) {
      found = selectedMunicipalData[regionData]?.find(
        (spObj: any) => spObj?.NAME_2 == itemData?.NAME_2 && spObj?.TYPE_2 == itemData?.TYPE_2
      );
    } else {
      selectedMunicipalData[regionData] = [];
    }

    if (found) {
      setSelectedMunicipalData((prev: any) => {
        return {
          ...prev,
          [regionData]: selectedMunicipalData[regionData]?.filter(
            (selected: any) =>
              !(selected?.NAME_2 == itemData?.NAME_2 && selected?.TYPE_2 == itemData?.TYPE_2)
          ),
        };
      });
    } else {
      setSelectedMunicipalData({
        ...selectedMunicipalData,
        [regionData]: [
          ...selectedMunicipalData[regionData],
          { NAME_2: itemData?.NAME_2, TYPE_2: itemData?.TYPE_2 },
        ],
      });
    }
  };

  const svgRef = useRef<any>();

  function organizeNames(features: any) {
    const hierarchy = {} as any;

    features.forEach((feature: any) => {
      const { NAME_0, NAME_1, NAME_2, TYPE_2 } = feature.properties;

      if (!hierarchy[NAME_0]) {
        hierarchy[NAME_0] = {};
      }

      if (!hierarchy[NAME_0][NAME_1]) {
        hierarchy[NAME_0][NAME_1] = [];
      }

      if (NAME_2) {
        hierarchy[NAME_0][NAME_1].push({ NAME_2, TYPE_2 });
      }
    });

    return hierarchy;
  }

  const [organizedData, setOrganizedData] = useState<any>(null);
  const [totalPlaces, setTotalPlaces] = useState<any>(0);

  const [mapStatus, setMapStatus] = useState<any>(false);

  const [geoData, setGeoData] = useState<any>(null);

  const repeatedFunc1 = () => {
    try {
      const svg = d3.select(svgRef.current);

      const modifySvg = (width: any, height: any, dataForSvg: any) => {
        try {
          const projection = d3.geoMercator().fitSize([width, height], dataForSvg);
          const pathGenerator = d3.geoPath().projection(projection) as any;

          svg.selectAll('*').remove();

          svg
            .selectAll('path')
            .data(dataForSvg.features)
            .enter()
            .append('path')
            .attr('d', pathGenerator)
            .style('fill', (d: any) =>
              selectedMunicipalData &&
              selectedMunicipalData[d.properties.NAME_1] &&
              selectedMunicipalData[d.properties.NAME_1]?.some((spObj: any) => {
                return spObj?.NAME_2 == d.properties.NAME_2 && spObj?.TYPE_2 == d.properties.TYPE_2;
              })
                ? '#25d928' //'#d925a6'
                : 'black'
            );

          // const [longitude, latitude] = [126.881057739257926, 37.47653198242198];
          // const [x, y] = projection([longitude, latitude]) as any;

          // svg
          //   .append('circle')
          //   .attr('cx', x)
          //   .attr('cy', y)
          //   .attr('r', 0.1)
          //   .style('fill', 'red');

          const bounds = pathGenerator.bounds(geoData);
          const dx = bounds[1][0] - bounds[0][0];
          const dy = bounds[1][1] - bounds[0][1];
          const x2 = (bounds[0][0] + bounds[1][0]) / 2;
          const y2 = (bounds[0][1] + bounds[1][1]) / 2;

          svg.attr('transform', `translate(${width / 2 - x2}, ${height / 2 - y2})`);
        } catch (e) {}
      };

      const operation1 = () => {
        const width = svg.node().getBoundingClientRect().width;
        const height = svg.node().getBoundingClientRect().height;

        const url =
          'https://cdn.jsdelivr.net/gh/southkorea/southkorea-maps/gadm/json/skorea-municipalities-geo.json';

        setMapStatus(true);
        if (geoData) {
          if (!organizedData) {
            const organizedHierarchyObject = organizeNames(geoData?.features);
            setOrganizedData(organizedHierarchyObject['South Korea'] ?? null);
          }

          if (totalPlaces <= 0) {
            setTotalPlaces(geoData?.features?.length ?? 0);
          }

          modifySvg(width, height, geoData);

          setTimeout(() => {
            setMapStatus(false);
            hideDarkLayer();
            stopSpinner();
          }, 100);
        } else {
          startSpinner();
          showDarkLayer();

          const getJourneyLog = async () => {
            try {
              return await api.get('/community/journey-log');
            } catch (e) {
              return null;
            }
          };

          d3.json(url).then(async (geojsonData: any) => {
            let journeyLogRes = await getJourneyLog();

            setGeoData(geojsonData ?? null);

            const organizedHierarchyObject = organizeNames(geojsonData?.features);
            setOrganizedData(organizedHierarchyObject['South Korea'] ?? null);
            setTotalPlaces(geojsonData?.features?.length ?? 0);

            modifySvg(width, height, geojsonData);

            if (
              [200, 201].includes(journeyLogRes?.status ?? 0) &&
              journeyLogRes?.data?.municipalData
            ) {
              setSelectedMunicipalData(journeyLogRes?.data?.municipalData ?? {});
            }

            setTimeout(() => {
              setMapStatus(false);
              hideDarkLayer();
              stopSpinner();
            }, 100);
          });
        }
      };

      operation1();
    } catch (e) {}
  };

  useEffect(() => {
    repeatedFunc1();

    // window.addEventListener('resize', resize);

    return () => {
      // window.removeEventListener('resize', resize);
    };
  }, [selectedMunicipalData]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      try {
        let accordBtClicked = (event?.target as any)?.classList.contains('accordion-button');
        let darkLayerEle = document.querySelector('.dark-layer') as any;

        if (darkLayerEle?.style?.display == 'none') {
          if (
            accordionRef.current &&
            !accordionRef.current.contains(event.target as Node) &&
            !accordBtClicked
          ) {
            setDrawerOpen(false);
          } else {
          }
        } else {
        }
      } catch (e) {}
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const startSpinner = () => {
    try {
      let spinnerEle = document.querySelector('.spinner');
      if (spinnerEle) {
        (spinnerEle as any).style.display = 'block';
      }
    } catch (e) {}
  };

  const stopSpinner = () => {
    try {
      let spinnerEle = document.querySelector('.spinner');
      if (spinnerEle) {
        (spinnerEle as any).style.display = 'none';
      }
    } catch (e) {}
  };

  const showDarkLayer = () => {
    try {
      let darkLayerEle = document.querySelector('.dark-layer');
      if (darkLayerEle) {
        (darkLayerEle as any).style.display = 'block';
      }
    } catch (e) {}
  };

  const hideDarkLayer = () => {
    try {
      let darkLayerEle = document.querySelector('.dark-layer');
      if (darkLayerEle) {
        (darkLayerEle as any).style.display = 'none';
      }
    } catch (e) {}
  };

  const widthData = useRef<any>(null);

  useEffect(() => {
    const resize = () => {
      try {
        const svg = d3.select(svgRef.current);
        const width = svg.node().getBoundingClientRect().width;
        const height = svg.node().getBoundingClientRect().height;

        if (widthData?.current == null) {
          widthData.current = width;
        } else {
          if (widthData.current != width) {
            repeatedFunc1();
            widthData.current = width;
          } else {
          }
        }
      } catch (e) {}
    };

    window.addEventListener('resize', resize);

    resize();

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    try {
      const accordionElement = document.querySelector('.accordion');

      if (drawerOpen) {
        if (document.body) {
          document.body.style.pointerEvents = 'none';
          document.body.style.overflow = 'hidden';
        }
        if (accordionElement) {
          (accordionElement as any).style['pointerEvents'] = 'auto';
          let mainEle = document.querySelector('main');
          if (mainEle) {
            const mainRect = mainEle.getBoundingClientRect();
            (accordionElement as any).style['right'] = window.innerWidth - mainRect.right + 'px';
          }
        }
      } else {
        if (document.body) {
          document.body.style.pointerEvents = 'auto';
          document.body.style.overflow = 'auto';
        }
        if (accordionElement) {
          (accordionElement as any).style['pointerEvents'] = 'auto';
        }
      }

      return () => {};
    } catch (e) {}
  }, [drawerOpen]);

  const authUserTokenObj = authUserTokenObjState.useValue();

  const [saveStatus, setSaveStatus] = useState(false);

  const handleSave = async () => {
    try {
      if (authUserTokenObj?.token) {
        if (window.confirm('변경 내용을 저장하시겠습니까?')) {
          setSaveStatus(true);
          startSpinner();

          let saveCommunityJourneyLogRes = await api.patch('/community/journey-log', {
            municipalData: selectedMunicipalData ?? {},
          });

          if (saveCommunityJourneyLogRes?.status == 200) {
            toast.success('저장을 완료하였습니다');
          } else {
            toast.error('오류가 발생하였습니다');
          }

          stopSpinner();
          setSaveStatus(false);
        }
      } else {
        if (window.confirm('로그인 후 이용 가능합니다')) {
          localStorage.setItem(
            'saveCommunityJourneyLog',
            JSON.stringify({ municipalData: selectedMunicipalData })
          );
          localStorage.setItem('redirectPageUrl', '/community/journey-log');
          window.location.href = '/community/login';
        } else {
        }
      }
    } catch (e) {}
  };

  const handleSelectAllMunicipalData = () => {
    try {
      let tempObj = {} as any;

      if (Object.keys(organizedData)?.length > 0) {
        let regions = Object.keys(organizedData);
        for (let i = 0; i < regions?.length; i++) {
          let region = regions[i];
          let subRegions = organizedData[region];
          for (let j = 0; j < subRegions?.length; j++) {
            let sr = subRegions[j];
            if (tempObj[region]) {
              tempObj[region].push({ NAME_2: sr?.NAME_2, TYPE_2: sr?.TYPE_2 });
            } else {
              tempObj[region] = [{ NAME_2: sr?.NAME_2, TYPE_2: sr?.TYPE_2 }];
            }
          }
        }
      }
      setSelectedMunicipalData(tempObj);
    } catch (e) {}
  };

  const calNumMunicipalData = (objData: any) => {
    try {
      let count = 0;
      if (Object.keys(objData ?? {})?.length > 0) {
        let regions = Object.keys(objData);
        for (let i = 0; i < regions?.length; i++) {
          let region = regions[i];
          count = count + objData[region]?.length ?? 0;
        }
        return count;
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  };

  return (
    <div>
      <div className="">
        {!mapStatus && !saveStatus && (
          <div className="flex gap-[15px] justify-center items-center my-[10px]">
            <button
              onClick={toggleDrawer}
              className="accordion-button p-2 px-[15px] bg-gray-200 rounded-[10px] cursor-pointer"
            >
              지역 선택
            </button>
            <button
              onClick={() => {
                handleSave();
              }}
              className="p-2 px-[15px] bg-gray-200 rounded-[10px] cursor-pointer"
            >
              저장
            </button>
            {/* <button
              onClick={() => {
                handleSelectAllMunicipalData();
              }}
              className="p-2 px-[15px] bg-gray-200 rounded-[10px] cursor-pointer"
            >
              전체 선택
            </button>
            <button
              onClick={() => {
                setSelectedMunicipalData({});
              }}
              className="p-2 px-[15px] bg-gray-200 rounded-[10px] cursor-pointer"
            >
              리셋
            </button> */}
          </div>
        )}

        <div
          className="spinner absolute z-[101]"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'none',
          }}
        >
          <div
            className="w-[55px] h-[55px] border-[7px] border-t-0 rounded-full animate-spin"
            style={{ borderColor: 'transparent transparent #FF3D00 transparent' }}
          ></div>
        </div>

        <div className="relative">
          {mapStatus ? (
            <></>
          ) : (
            <>
              <div className="absolute top-[0px] w-full w-max-md flex justify-center">
                {totalPlaces ? (
                  <div className="flex justify-center items-center gap-[8px] w-full">
                    <p>전체 지역 중</p>
                    <p className="text-3xl text-gray-600 font-bold">
                      {(
                        ((calNumMunicipalData(selectedMunicipalData) ?? 0) / totalPlaces) *
                        100
                      )?.toFixed(2) + '%'}
                    </p>
                    <p>를 방문했습니다</p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </>
          )}

          <svg ref={svgRef} width="100%" height="600">
            <g />
          </svg>
        </div>
      </div>
      {organizedData && drawerOpen && (
        <Accordion
          accordionRef={accordionRef}
          data={organizedData}
          isOpen={drawerOpen}
          selectedMunicipalData={selectedMunicipalData}
          toggleDrawer={toggleDrawer}
          toggleSelection={toggleSelection}
          startSpinner={startSpinner}
          showDarkLayer={showDarkLayer}
        />
      )}
    </div>
  );
}
