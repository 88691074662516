import axios from 'axios';
import { adminTokenState, authUserTokenObjState, deviceInfoTokenState } from '../ridge/ridge';
import { paymentTokenState as campingBookingPaymentTokenState } from '../ridge/dayout-project-1/camping/booking';
import { apiForTempSol } from '../tempSolution/apiForTempSol';

const getUrlInfo = (urlData: any) => {
  try {
    const parsedURL = new URL(urlData);
    const pathname = parsedURL.pathname;
    return { pathname };
  } catch (e) {
    return null;
  }
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((_config) => {
  if (apiForTempSol) {
    _config.url = apiForTempSol + _config.url;
  }

  const authUserTokenObj = authUserTokenObjState.get();
  const adminToken = adminTokenState.get();
  const deviceInfoToken = deviceInfoTokenState.get();
  const campingBookingPaymentToken = campingBookingPaymentTokenState.get();

  if (campingBookingPaymentToken) {
    _config.headers['Camping-Booking-Payment-Token'] = campingBookingPaymentToken;
    _config.headers['Onda-Booking-Payment-Token'] = campingBookingPaymentToken;
  }

  // default auth: user (either (non)member or admin)
  let adminPage = window.location?.pathname?.startsWith('/admin');
  if (adminPage == true) {
    _config.headers.Authorization = `Bearer ${adminToken}`;
  } else {
    _config.headers.Authorization = `Bearer ${authUserTokenObj?.token}`;
  }

  // other auth: [device-info]
  let deviceInfoRequest = getUrlInfo(_config?.url)?.pathname?.includes('/device-info');
  if (deviceInfoRequest) {
    _config.headers.Authorization = `Bearer ${deviceInfoToken}`;
  }

  return _config;
});

api.interceptors.response.use(undefined, (error) => {
  console.log('error: ', error);
  if (error?.response?.status === 401) {
  }

  return Promise.reject(error);
});

export { api };
