import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { useSearch } from 'rsuite/esm/Picker';

export default function BannerType1(props: any) {
  const showAddr = (dataObj: any) => {
    try {
      const { shortAddr, city } = dataObj;
      if (shortAddr) {
        return shortAddr;
      } else if (city) {
        return city;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const shuffleArray = (array: any) => {
    try {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    } catch (e) {
      return null;
    }
  };

  const [bannerList, setBannerList] = useState<any>([]);

  useEffect(() => {
    if (props?.items?.length > 0) {
      setBannerList(shuffleArray(props?.items) ?? []);
    }
  }, [props?.items]);

  return (
    <div>
      <>
        {bannerList?.length > 0 ? (
          <Swiper
            pagination={false}
            autoplay={{ delay: 700 }}
            loop={true}
            className="mySwiper cursor-pointer max-w-md h-[90px]"
            onAfterInit={(swiper) => {
              if (swiper?.el?.style) {
                (swiper.el.style as any)['padding-bottom'] = '0px';
              }
            }}
          >
            {bannerList &&
              bannerList.map((itemObj: any, index2: any) => (
                <div>
                  <SwiperSlide className="max-w-md" key={itemObj?.link + index2}>
                    <div
                      className="w-full h-full relative"
                      onClick={() => {
                        let url = window.location.origin + '/community/accomms-w-disc';

                        if (itemObj?.eventUniqueId && itemObj?.name) {
                          url =
                            url +
                            '#' +
                            itemObj?.eventUniqueId +
                            '-' +
                            itemObj?.name.replace(/\s/g, '').trim();
                        }

                        window.location.href = url;

                        // if (itemObj?.link) {
                        //   window.open(itemObj?.link, '_blank');
                        // }
                      }}
                    >
                      <>
                        <img
                          ref={(el) => {
                            if (el) {
                              // changeImgObjectFit(el);
                            }
                          }}
                          className="w-full max-w-md h-full object-cover"
                          src={itemObj?.mainImageUrl ?? ''}
                          style={{}}
                          // src={chooseImage(itemObj) ?? ''}
                        />
                        <div className="absolute top-[10px] left-[10px] flex gap-[4px]">
                          {itemObj?.maxDiscountRate > 0 && (
                            <>
                              <div className="px-[13px] h-fit w-fit rounded-[5px] bg-[#f94c86] text-[white]">
                                {'~' + itemObj?.maxDiscountRate + '%' + ''}
                              </div>
                            </>
                          )}

                          <div className="left-[10px] text-[white]">
                            {/* {itemObj?.shortAddr
                              ? itemObj?.shortAddr
                              : itemObj?.city
                              ? itemObj?.city
                              : ''} */}
                            <p
                              className="px-[10px] "
                              style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                            >
                              {itemObj?.name?.length <= 20
                                ? itemObj?.name
                                : itemObj?.name?.substring(0, 20) + '...'}
                              <p>{showAddr(itemObj)}</p>
                            </p>
                          </div>
                        </div>
                        <div
                          className="absolute bottom-[2px] px-[10px] text-[white] text-[8px]"
                          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                        >
                          해당 게시물은 트립닷컴 제휴마케팅의 일환으로, 이에 따른 일정 수수료를
                          트립닷컴으로 제공받고 있습니다.
                        </div>
                      </>
                    </div>
                  </SwiperSlide>
                </div>
              ))}
          </Swiper>
        ) : (
          <></>
        )}
      </>
    </div>
  );
}
